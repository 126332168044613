/**
 * Ensures exhaustive checks have been made. If a value hasn't been properly narrowed to `never`, this function will produce a TypeScript error.
 *
 * @example
 * enum Fruit {
 *   APPLE,
 *   ORANGE,
 *   PEAR,
 * }
 *
 * function getFruit(fruitType: Fruit): string {
 *   switch (fruitType) {
 *     case Fruit.APPLE: return 'Apple';
 *     case Fruit.ORANGE: return 'Orange';
 *
 *     // Uncommenting the following will fix the error!
 *     // case Fruit.PEAR: return 'Pear';
 *
 *     default: assertNever(fruitType, 'Unknown Fruit');
 *   }
 * }
 */
export function assertNever(shouldBeNever: never, message?: string): never {
  throw new Error(`Never assertion received value: ${String(shouldBeNever)}.${message ? ` ${message}` : ''}`);
}

/**
 * Ensures that exhaustive checks have been made. Similar to `assertNever` but
 * only calls console.warn instead of throwing an error.
 * @see {assertNever}
 */
export function warnNever(shouldBeNever: never, message?: string): void {
  console.warn(`Never warning received value: ${String(shouldBeNever)}.${message ? ` ${message}` : ''}`);
}
