<div class="input-group">
  <div class="search-wrapper">
    <input
      type="search"
      class="di-search form-control ng-trim-ignore"
      [ngClass]="{ loading: isSearching }"
      [ngbTypeahead]="searchOrganizations"
      [focusFirst]="false"
      [resultTemplate]="searchResultTemplate"
      (selectItem)="onChangeOrganization($event.item)"
      [(ngModel)]="organization"
      (ngModelChange)="onChangeOrganizationInline($event)"
      [inputFormatter]="formatTitle"
      [disabled]="!!disabled"
      [required]="!!required"
      [placeholder]="placeholder"
    />

    <i class="fa fa-spinner fa-pulse loading-spinner" *ngIf="isSearching"></i>
  </div>

  <div class="input-group-append">
    <a
      class="btn btn-secondary"
      href="/organization/detail/{{ organization._id }}"
      target="_blank"
      rel="noopener noreferrer"
      aria-label="Open HCO in a new tab"
      *ngIf="organization?._id"
    >
      <i class="fa fa-external-link dirt-link-pointer" aria-hidden="true"></i>
    </a>

    <!-- Can add more buttons to the group if needed -->
    <ng-content></ng-content>
  </div>
</div>

<ng-container *ngIf="!!creationRequestHandler">
  <a href="javascript:void(0)" class="small pull-right pt-1 pr-2" (click)="onRequestOrganization()"> Request HCO </a>

  <br />
</ng-container>

<!-- SearchAutoComplete Result Template-->
<ng-template #searchResultTemplate let-r="result" let-t="term">
  <ng-container>
    <div class="d-flex">
      <div class="di-sac-organization pull-left flex-grow-1">
        <div class="di-sac-name">{{ r.name }}</div>
        <div class="di-sac-fullName">{{ r.fullName }}</div>
        <div class="di-sac-address">{{ r.address || '&mdash;' }}</div>
      </div>

      <div class="pull-right row align-items-end flex-column">
        <div class="di-sac-status pull-left">
          <span class="badge badge-warning" title="Not yet worked by central HCO team" *ngIf="!r.readyForDelivery">
            Unpolished
          </span>
        </div>

        <div class="di-sac-actions pull-right">
          <span class="action-btn" title="Open tree view" (click)="onOpenTree($event, r._id)">
            <i class="fa fa-search" aria-hidden="true"></i>
          </span>
        </div>
      </div>
    </div>
  </ng-container>
</ng-template>
