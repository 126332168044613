<div class="tree-part" [id]="'tree-' + leaf._id">
  <div
    class="self-line"
    [ngClass]="{
      'alien-entry': leaf.isAlien,
      'in-tree-alien-entry': isInTreeAlien(),
      'highlight-row': leaf._id === singleSelectHolder?.id,
      'marked-for-deletion': leaf.isMarkedForDeletion
    }"
    [title]="getLeafHint()"
  >
    <div style="display: flex; flex-direction: row; justify-content: space-between">
      <div class="self-line-left">
        <!-- fa-minus-square-o & fa-blank is a hack to not show anything when the child cannot be expanded. fa-minus-square-o can be replaced with any other icon but not fa-plus or fa-minus. we chose fa-minus-square-o to show a meaningful icon to express that this node cannot be extended which is different from fa-minus -->
        <span
          tabindex="0"
          (click)="doToggleExpanded()"
          (keyup.enter)="doToggleExpanded()"
          class="toggle fa"
          [ngClass]="{
            'fa-minus': expandedMap[leaf._id],
            'fa-plus': !expandedMap[leaf._id] && leaf.children?.length > 0,
            'fa-minus-square-o fa-blank': (leaf.children?.length || 0) === 0
          }"
        ></span>
        <span
          (click)="doSingleSelect()"
          class="alink leaf-title"
          [ngClass]="{ 'single-select': leaf._id === singleSelectHolder?.id }"
          [ngStyle]="{
            color: colorMap[shortDisplayName(leaf)] ?? (leaf.isDraft ? 'orange' : leaf.isUtc ? 'red' : 'black')
          }"
        >
          {{ this.getTitle(leaf) }}
        </span>
      </div>
      <div class="self-line-right">
        <a
          class="url"
          [ngClass]="{ hidden: !leaf.websource }"
          [href]="leaf.websource || '#'"
          rel="noopener noreferrer"
          target="_blank"
          ><span class="fa fa-globe"></span
        ></a>
        <input type="checkbox" [checked]="!!multiSelectMap[leaf._id]" (input)="doToggleMultiselect()" />
        <i
          *ngIf="leaf.isMarkedForLater && currentJob"
          title="This HCO was marked for later"
          class="fa fa-exclamation"
        ></i>
        <i *ngIf="leaf.isMarkedForDeletion" title="This HCO was marked for deletion" class="fa fa-chain-broken"></i>
        <span
          *ngIf="leaf.isRoot && leaf.childrenInProgress?.length > 0"
          class="badge badge-info badge-btn"
          title="Shows the number of descendants that are currently under curation. Click to jump to the next draft child."
          (click)="jumpToOrg.emit({ id: leaf.childrenInProgress[0] })"
        >
          {{ (leaf.childrenInProgress?.length || 0) + (leaf.childrenInProgress.length === 1 ? ' Draft' : ' Drafts') }}
        </span>
        <span
          *ngIf="currentJob && leaf.isRoot && leaf.childrenMarkedForLater?.length > 0"
          class="badge badge-danger badge-btn"
          title="Shows the number of descendants marked for later. Click to jump to the next marked child."
          (click)="jumpToOrg.emit({ id: leaf.childrenMarkedForLater?.[0] })"
        >
          {{
            (leaf.childrenMarkedForLater?.length || 0) +
              (leaf.childrenMarkedForLater?.length === 1 ? ' Mark' : ' Marks')
          }}
        </span>
      </div>
    </div>
  </div>
  <div *ngIf="expandedMap[leaf._id]">
    <div class="child-line" *ngFor="let c of childrenEntries(); trackBy: tracker">
      <dirt-organization-tree-part
        [leaf]="c"
        [parentId]="leaf._id"
        [expandedMap]="expandedMap"
        [internalDuplicatesMap]="internalDuplicatesMap"
        [colorMap]="colorMap"
        [multiSelectMap]="multiSelectMap"
        [singleSelectHolder]="singleSelectHolder"
        [currentJob]="currentJob"
        [showAdditionalTypeInformation]="showAdditionalTypeInformation"
        (toggleExpanded)="toggleExpanded.emit($event)"
        (toggleMultiselect)="toggleMultiselect.emit($event)"
        (changeSingleSelect)="changeSingleSelect.emit($event)"
      ></dirt-organization-tree-part>
    </div>
  </div>
</div>
