import { Component, Input, OnInit, Type, ViewChild, ViewContainerRef } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

export type ConfirmationComponentConfiguration = {
  title?: string;
  content: string | Type<unknown>;
  cancelText?: string;
  confirmText?: string;
};

@Component({
  selector: 'dirt-confirmation-modal',
  templateUrl: './confirmation.component.html',
})
export class ConfirmationComponent implements OnInit {
  @ViewChild('dynamic', { static: true, read: ViewContainerRef })
  private viewRef: ViewContainerRef;

  @Input()
  readonly configuration: ConfirmationComponentConfiguration;

  isStringContent = false;

  constructor(private readonly activeModal: NgbActiveModal) {}

  ngOnInit() {
    if (typeof this.configuration.content !== 'string') {
      this.viewRef.clear();
      this.viewRef.createComponent(this.configuration.content);
    } else {
      this.isStringContent = true;
    }
  }

  onCancel() {
    this.activeModal.close(false);
  }

  onContinueAnyway() {
    this.activeModal.close(true);
  }

  onDismiss() {
    this.activeModal.dismiss();
  }
}
