import { Component, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { tap } from 'rxjs';

import { OrgDuplicate, OrganizationAPI } from '../api.service';
import { Organization, OrganizationAddress } from '../organization';
import { AddressEntity } from '../address-entity';

export const MODAL_OPTIONS = {
  centered: true,
  size: 'lg',
  backdrop: false,
};

type UiState =
  // Default state
  | { type: 'create' }
  // When we have exact duplicates and want to prevent org creation by showing duplicates
  | { type: 'exact-duplicate'; duplicates: OrgDuplicate[] }
  // When we have fuzzy duplicates and want to suggest renaming by showing the user potential duplicates
  | { type: 'fuzzy-duplicate'; duplicates: OrgDuplicate[] };

@Component({
  selector: 'dirt-create-root-dialog',
  templateUrl: './create-root-dialog.component.html',
  styleUrls: ['./create-root-dialog.component.scss'],
})
export class CreateRootDialogComponent implements OnChanges {
  @ViewChild('internalForm')
  internalForm: NgForm;

  isLoading = false;
  state: UiState = { type: 'create' };

  // Model params
  name: string;
  websource: string;
  priority: number;
  address: OrganizationAddress;

  addressInfo?: AddressEntity;

  constructor(private activeModal: NgbActiveModal, private svcOrganization: OrganizationAPI) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.state.type === 'exact-duplicate' && (changes['name'] || changes['websource'])) {
      // Reset errors so the user can type again and correct their inputs
      this.state = { type: 'create' };
    }
  }

  resetState() {
    this.state = { type: 'create' };
  }

  async onClose(): Promise<void> {
    this.activeModal.close();
  }

  async onCreate(force: boolean): Promise<void> {
    this.isLoading = true;

    const org = new Organization();
    org.isRoot = true;
    org.name = this.name;
    org.websource = this.websource;
    org.priority = this.priority;
    org.addresses = [this.address];

    if (this.state.type === 'fuzzy-duplicate') {
      // user needs to confirm an additional layer of dialog to continue
      if (!window.confirm('Are you sure you want to create this organization?')) {
        this.isLoading = false;
        return;
      }
    }

    this.svcOrganization
      .create(org, force)
      .pipe(tap(() => (this.isLoading = false)))
      .subscribe((res) => {
        switch (res.type) {
          case 'success':
            this.activeModal.close(res.res);
            break;
          case 'exact-duplicate':
            this.state = { type: 'exact-duplicate', duplicates: res.duplicates };
            break;
          case 'fuzzy-duplicate':
            this.state = { type: 'fuzzy-duplicate', duplicates: res.duplicates };
            break;
          default:
            break;
        }
        this.isLoading = false;
      });
  }

  // onOpenNewAddressDialog() {
  //   this.addressToCreate = {} as AddressEntity;
  //   this.addressModalInstance = this.svcModal.open(this.addAddressModal, { size: 'xl' });
  // }

  onAddAddress(address: AddressEntity): void {
    if (!address) {
      return;
    }

    this.addressInfo = address;
    this.address = { addressId: address._id };
  }

  onRemoveAddress(): void {
    this.addressInfo = null;
    this.address = null;
  }
}
