import { ActivatedRoute, Router } from '@angular/router';
import { capitalize } from 'lodash';
import { concatMap, debounceTime, distinctUntilChanged, take, takeUntil, tap } from 'rxjs/operators';
import { forkJoin, from, Observable, Subject } from 'rxjs';
import { format } from 'date-fns';
import { FormControl } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OnInit, Component, OnDestroy, ViewChild } from '@angular/core';

import { ACL } from '../../shared/acl/acl.service';
import { BulkDelegate } from '../../common/bulk/bulk.delegate';
import { BulkModalComponent, BULK_MODAL_OPTIONS } from '../../common/bulk/component/bulk-modal/bulk-modal.component';
import { BulkType } from '../../common/bulk/shared/bulk-types';
import { Committee } from '../shared/committee';
import { CommitteeAPI } from '../shared/committee-api.service';
import { CommitteeMetaStatus } from '../shared/constant/meta-status.enum';
import { Filter, FiltersComponent, FilterType } from '../../shared/components/filters/filters.component';
import { JobsAPI } from '../../jobs/shared/api.service';
import { ValueAPI } from '../../shared/services/value/value-api.service';
import { ValueType } from '../../shared/enum/value-type.enum';
import { AccountAPI } from '../../account/shared/account-api.service';
import { CommitteeStatus } from '../shared/constant/status.enum';

@Component({
  selector: 'dirt-committee-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class CommitteeListComponent implements OnInit, OnDestroy {
  committees: Committee[];

  isLoading: boolean;

  // Pagination settings
  total = { count: 0 };
  pagingPage = 1;
  pagingLimit = 100;
  pagingSkip = 0;

  // Sorting / Filtering settings
  searchCtrl: FormControl = new FormControl('');
  filter: { [key: string]: any };
  filters: Filter[] = [];
  filterBarConfig = {
    country: {
      label: 'Country',
      format: (value: string) => {
        return this.countries.get(value) || value;
      },
    },
    '_meta.status': {
      label: 'Status',
      format(value: string) {
        return capitalize(value).replace(/_/g, ' ');
      },
    },
    createdAt: {
      label: 'Created at',
      format: (value: { $gte: Date; $lte: Date }) => {
        return `${format(value.$gte, 'yyyy/MM/dd')} - ${format(value.$lte, 'yyyy/MM/dd')}`;
      },
    },
    updatedAt: {
      label: 'Modified at',
      format: (value: { $gte: Date; $lte: Date }) => {
        return `${format(value.$gte, 'yyyy/MM/dd')} - ${format(value.$lte, 'yyyy/MM/dd')}`;
      },
    },
    topic: {
      label: 'Topic',
      format: (value: string) => {
        return capitalize(value).replace(/_/g, ' ');
      },
    },
    therapeuticAreas: {
      label: 'Therapeutic Area',
      format: (value: string) => {
        return capitalize(value).replace(/_/g, ' ');
      },
    },
    status: {
      label: 'Active Status',
      format: (value: string) => {
        return capitalize(value).replace(/_/g, ' ');
      },
    },
  };

  bulkListDelegate: BulkDelegate = {
    title: 'Bulk Import',
    type: BulkType.LIST,
    specificStartFct: this.handleBulkListUpload.bind(this),
    specificNotifyReloadFct: this.doLoad.bind(this),
  };

  jobBulkListDelegate: BulkDelegate = {
    title: 'Bulk Import Job',
    type: BulkType.LIST,
    specificStartFct: this.handleBulkListJobUpload.bind(this),
    specificNotifyReloadFct: this.doLoad.bind(this),
  };

  isPriorityEditable: boolean;

  searchType = 'COMMITTEE';

  private searchTerm: string;

  private sort = '-_id';

  @ViewChild(FiltersComponent, { static: false })
  private filterRef: FiltersComponent;

  private countries: Map<string, string> = new Map();

  private destroy$: Subject<boolean> = new Subject();

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly svcCommittee: CommitteeAPI,
    private readonly svcACL: ACL,
    private readonly svcModal: NgbModal,
    private readonly svcValue: ValueAPI,
    private readonly svcJob: JobsAPI,
    private readonly svcAccount: AccountAPI
  ) {}

  ngOnInit(): void {
    this.filters.push(
      {
        title: 'Status',
        key: '_meta.status',
        type: FilterType.MULTI_VALUE,
        values: Object.keys(CommitteeMetaStatus)
          .sort()
          .map((key) => ({ title: CommitteeMetaStatus[key], displayValue: CommitteeMetaStatus[key] })),
      },
      {
        title: 'Active Status',
        key: 'status',
        type: FilterType.SINGLE_VALUE,
        values: Object.keys(CommitteeStatus)
          .sort()
          .map((key) => ({ title: CommitteeStatus[key].toUpperCase(), displayValue: CommitteeStatus[key] })),
      },
      {
        title: 'Created',
        key: 'createdAt',
        type: FilterType.DATE_RANGE,
      },
      {
        title: 'Modified',
        key: 'updatedAt',
        type: FilterType.DATE_RANGE,
      }
    );

    forkJoin({
      countries: this.svcValue.find(ValueType.Country, Number.MAX_SAFE_INTEGER, 0, '+title'),
      therapeuticAreas: this.svcValue.find(ValueType.CommitteeTherapeuticArea, Number.MAX_SAFE_INTEGER, 0, '+title'),
      topics: this.svcValue.find(ValueType.CommitteeTopic, Number.MAX_SAFE_INTEGER, 0, '+title'),
      params: this.route.queryParams.pipe(take(1)),
    }).subscribe(({ countries, therapeuticAreas, topics, params }) => {
      const countryFilter = {
        title: 'Countries',
        key: 'country',
        type: FilterType.MULTI_VALUE_FILTERABLE,
        values: countries.map((value) => ({ title: value.code as string, displayValue: value.title })),
      };

      const therapeuticAreasFilter = {
        title: 'Therapeutic Area',
        key: 'therapeuticAreas',
        type: FilterType.MULTI_VALUE_FILTERABLE,
        values: therapeuticAreas.map((value) => ({ title: value.code as string, displayValue: value.title })),
      };

      const topicFilter = {
        title: 'Topic',
        key: 'topic',
        type: FilterType.MULTI_VALUE_FILTERABLE,
        values: topics.map((value) => ({ title: value.code as string, displayValue: value.title })),
      };

      this.filters = [...this.filters, countryFilter, therapeuticAreasFilter, topicFilter]; // DO break reference when creating a filter from an Observable/Promise (otherwise change detection won't run)

      this.countries = new Map(countries.map((value) => [value.code as string, value.title]));

      if (params.searchTerm) {
        this.searchTerm = params.searchTerm;
        this.searchCtrl.setValue(this.searchTerm, { emitEvent: false }); // restore search term
      }
      if (params.sortField) {
        this.sort = params.sortField;
      }

      this.doLoad();
    });

    this.searchCtrl.valueChanges
      .pipe(takeUntil(this.destroy$), debounceTime(400), distinctUntilChanged())
      .subscribe((term) => {
        this.searchTerm = term;
        this.doLoad();
      });

    this.isPriorityEditable = this.svcACL.hasCredential('committee.update.prop.priority');
  }

  ngOnDestroy(): void {
    this.destroy$.next(false);
    this.destroy$.complete();
  }

  /** just avoid useless rendering if we can */
  trackById(_: number, committee: Committee): string {
    return committee.id;
  }

  getPage(page: number): void {
    this.pagingPage = page;
    this.pagingSkip = (this.pagingPage - 1) * this.pagingLimit;
    this.getCommittees();
  }

  onSort(field: string): void {
    this.sort = field;

    this.storeFiltersAsQueryParams();
    this.getCommittees();
  }

  onFilter(filter: { [key: string]: any }): void {
    this.filter = filter;
    this.doLoad();
  }

  onDelete(id: string): void {
    if (!this.svcACL.hasCredential('committee.delete')) {
      return;
    }

    if (!window.confirm('Do you want to remove this entry?')) {
      return;
    }

    this.svcCommittee.deleteById(id).subscribe(() => {
      this.getCommittees();
      this.getCommitteesCount();
    });
  }

  onFilterClear(): void {
    this.filterRef.doClear();
  }

  onFilterValueRemove(item: { key: string; value: any }): void {
    this.filterRef.removeValue(item.key, item.value);
  }

  onOpenBulkListDialog(): void {
    const modal = this.svcModal.open(BulkModalComponent, BULK_MODAL_OPTIONS);
    modal.componentInstance.delegate = this.bulkListDelegate;
  }

  onSetPriority(committee: Committee, priority: number): void {
    if (!priority || (committee.priority && committee.priority === priority)) {
      return;
    }

    this.svcCommittee.update(committee._id, { priority } as Committee).subscribe((updatedCommittee) => {
      const committee = this.committees.find((committee) => committee._id === updatedCommittee._id);
      if (!committee) {
        return;
      }

      Object.assign(committee, updatedCommittee);
    });
  }

  onExport(): void {
    this.determinePreFilter()
      .pipe(
        concatMap((preFilter) =>
          preFilter
            ? this.svcCommittee.downloadCommitteesFile({ ...(this.filter || {}), ...preFilter })
            : this.svcCommittee.downloadCommitteesFile(this.filter, this.searchTerm)
        )
      )
      .subscribe((blob) => {
        const dlDummyLink = document.createElement('a');

        dlDummyLink.setAttribute('download', `committee_list_${new Date().toLocaleString()}.xlsx`);
        dlDummyLink.setAttribute('href', URL.createObjectURL(blob));
        dlDummyLink.click();
        dlDummyLink.parentElement.removeChild(dlDummyLink);
      });
  }

  onOpenBulkListJobDialog(): void {
    const modal = this.svcModal.open(BulkModalComponent, BULK_MODAL_OPTIONS);
    modal.componentInstance.delegate = this.jobBulkListDelegate;
  }

  onSearchTypeChange(type: string): void {
    this.searchType = type;
    if (this.searchTerm) {
      this.doLoad();
    }
  }

  navigateTo(id: string): void {
    if (!id) {
      return;
    }

    this.router.navigate(['/committee/detail', id]);
  }

  private doLoad(): void {
    this.storeFiltersAsQueryParams();
    this.resetPagination();
    this.getCommittees();
    this.getCommitteesCount();
  }

  private storeFiltersAsQueryParams(): void {
    if (this.searchTerm || this.sort) {
      const filters = {
        ...(this.searchTerm && { searchTerm: this.searchTerm }),
        ...(this.sort && { sortField: this.sort }),
      };
      this.router.navigate([], { queryParams: filters });
    }
  }

  private resetPagination(): void {
    this.pagingPage = 1;
    this.pagingSkip = 0;
  }

  private getCommittees(): void {
    this.isLoading = true;

    let observable = this.determinePreFilter().pipe(
      concatMap((preFilter) =>
        preFilter
          ? this.svcCommittee.find(null, this.pagingLimit, this.pagingSkip, this.sort, {
              ...(this.filter || {}),
              ...preFilter,
            })
          : this.svcCommittee.find(this.searchTerm, this.pagingLimit, this.pagingSkip, this.sort, this.filter)
      )
    );

    observable.pipe(tap(() => (this.isLoading = false))).subscribe((committees) => {
      this.committees = committees;
    });
  }

  private getCommitteesCount(): void {
    let observable = this.determinePreFilter().pipe(
      concatMap((preFilter) =>
        preFilter
          ? this.svcCommittee.count(null, { ...(this.filter || {}), ...preFilter })
          : this.svcCommittee.count(this.searchTerm, this.filter)
      )
    );

    observable.subscribe((count) => {
      this.total = count;
    });
  }

  private determinePreFilter(): Observable<any | undefined> {
    if (this.searchType === 'ACCOUNT' && this.searchTerm) {
      return this.svcAccount.find(this.searchTerm, this.pagingLimit, this.pagingSkip).pipe(
        concatMap((accounts) => {
          return from([{ 'connections.connectionId': accounts.map((a) => a.id) }]);
        })
      );
    } else {
      return from([undefined]);
    }
  }

  private handleBulkListUpload(file: File, secondEyes: string): Observable<string> {
    return this.svcCommittee.startBulkListUpload(file, secondEyes);
  }

  private handleBulkListJobUpload(file: File, secondEyes: string, opts: any): Observable<string> {
    return this.svcJob.startBulkListCommitteeJobUpload(file, secondEyes);
  }

  compactString(str?: string) {
    if (!str) {
      return str;
    }
    const abbrevMatch = /([A-Z]{2,5})/.exec(str);
    if (abbrevMatch && !str.startsWith(abbrevMatch[1])) {
      str = abbrevMatch[1] + ' - ' + str;
    }
    str = str.replace('University', 'U.');
    str = str.replace('Academy', 'A.');
    return str;
  }

  nameString(array?: { name }[]) {
    return (array || []).map((e) => e.name).join(', ');
  }
}
