<section class="dirt-section-create dirt-affiliation-create">
  <button type="button" class="dirt-btn-back btn btn-primary-outline btn-sm" (click)="goBack()">
    <span class="fa fa-arrow-left" aria-hidden="true"></span>
  </button>

  <h1 class="bounceInRight">Create Institution</h1>

  <br />

  <dirt-affiliation-form [affiliation]="affiliation" #frmAffiliation="frmAffiliation"></dirt-affiliation-form>

  <div class="dirt-duplicate-list" *ngIf="duplicates && duplicates.length > 0">
    <div class="dirt-duplicate-list-heading">The following duplicates were found:</div>
    <dirt-affiliation-suggestion-list [affiliations]="duplicates"></dirt-affiliation-suggestion-list>
    <div>It's recommended to check whether an existing institution already matches.</div>
  </div>

  <div class="pull-right">
    <button
      type="button"
      class="btn btn-secondary"
      [disabled]="isSubmitting"
      (click)="goBack()"
      *ngIf="duplicates && duplicates.length > 0"
    >
      Cancel
    </button>
    <button
      type="submit"
      class="btn btn-primary"
      [ngClass]="{ 'btn-loading': isSubmitting }"
      [disabled]="!frmAffiliation.isValid() || isSubmitting"
      (click)="onSubmit(frmAffiliation.getValue(), duplicates && duplicates.length > 0)"
      *diAcl="'affiliation.create'"
    >
      <span *ngIf="!(duplicates && duplicates.length > 0)" title="Will check for duplicates">Submit</span>
      <span *ngIf="duplicates && duplicates.length > 0" title="Create despite duplicates">Submit anyway</span>
    </button>
  </div>
</section>
