import { Component } from '@angular/core';
import { debounceTime, distinctUntilChanged, tap, switchMap, catchError } from 'rxjs/operators';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, of, zip } from 'rxjs';

import { Affiliation } from '../../../../affiliation/shared/affiliation';
import { AffiliationAPI } from '../../../../affiliation/shared/api.service';
import { AccountAffiliationModalOptions } from './affiliation.service';
import { AccountAffiliationStatus } from '../../account-affiliation';
import { AccountAffiliationAPI } from '../../account-affiliation-api.service';
import { AccountAPI } from '../../../../account/shared/account-api.service';
import { Account } from '../../../../account/shared/account';
import { OrganizationCreateRequestModalService } from '../../../../organizations/shared/create-request-modal/organization-create-request-modal.service';

@Component({
  selector: 'dirt-account-affiliation-modal',
  templateUrl: './affiliation.component.html',
  styleUrls: ['./affiliation.component.scss'],
})
export class AccountAffiliationModalComponent {
  isSearching: boolean;

  searchTerm: string;

  selectedAffiliation: Affiliation;
  selectedAffiliationConnectedAccounts: Account[] = [];
  addOrganizationButtonDisabled: boolean = false;
  addOrganizationButtonDisabledTooltip: string = '';

  options: AccountAffiliationModalOptions;

  constructor(
    public activeModal: NgbActiveModal,
    private readonly svcAffiliation: AffiliationAPI,
    private readonly svcAccount: AccountAPI,
    private readonly svcAccountAffiliation: AccountAffiliationAPI,
    private readonly svcOrganizationCreateRequestModal: OrganizationCreateRequestModalService
  ) {
    this.onSearchAffiliation = this.onSearchAffiliation.bind(this);
  }

  onSearchAffiliation(term$: Observable<string>): Observable<Affiliation[]> {
    return term$.pipe(
      debounceTime(400),
      distinctUntilChanged(),
      tap(() => (this.isSearching = true)),
      switchMap((term) => {
        if (!term) {
          return of([]);
        }

        return this.svcAffiliation
          .find(term, undefined, undefined, undefined, undefined, undefined, !!this.options?.topOnly)
          .pipe(catchError(() => of([])));
      }),
      tap(() => (this.isSearching = false))
    );
  }

  onSelect(event: any): void {
    event.preventDefault(); // prevent setting model to [object Object]

    if (
      !event.item.readyForDelivery &&
      !confirm(
        "You are adding an organization not polished yet by central organizations. The account will remain hidden from customers until central organizations' work is completed. Use anyway?"
      )
    ) {
      return;
    }

    this.selectedAffiliation = event.item;
    this.searchTerm = '';

    this.fetchAccountsLinkedWithAffiliation(this.selectedAffiliation.id);
  }

  fetchAccountsLinkedWithAffiliation(affiliationId: string) {
    this.isSearching = true;
    this.selectedAffiliationConnectedAccounts = [];

    this.svcAccountAffiliation
      .find(undefined, undefined, 0, undefined, undefined, affiliationId)
      .subscribe((accountAffiliations) => {
        if (accountAffiliations.length > 0) {
          this.addOrganizationButtonDisabledTooltip = 'Affiliation cannot be connected to multiple accounts';
          this.addOrganizationButtonDisabled = true;
          zip(
            accountAffiliations.map((accountAffiliation) => this.svcAccount.findById(accountAffiliation.accountId))
          ).subscribe((accounts) => {
            accounts.forEach((account) => this.selectedAffiliationConnectedAccounts.push(account));
            this.isSearching = false;
          });
        } else {
          this.isSearching = false;
          this.addOrganizationButtonDisabled = false;
        }
      });
  }

  onShortlist(): void {
    if (!this.selectedAffiliation) {
      return;
    }

    this.activeModal.close({
      type: AccountAffiliationStatus.SHORTLISTED,
      data: this.selectedAffiliation,
    });
  }

  onAdd(): void {
    if (!this.selectedAffiliation) {
      return;
    }

    this.activeModal.close({
      type: !this.selectedAffiliation.readyForDelivery
        ? AccountAffiliationStatus.REQUESTED
        : AccountAffiliationStatus.ADDED,
      data: this.selectedAffiliation,
    });
  }

  showAffiliationModal(): void {
    this.svcOrganizationCreateRequestModal
      .open(false, 'LFKA')
      .then((res) => {
        this.selectedAffiliation = res.affiliation;
        // TODO: Add link to organization
      })
      .catch(() => {});
  }

  isAlreadyMatched(affiliationId: string) {
    return this.options?.allMappedAffiliationIds?.includes(affiliationId);
  }
}
