import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  combineLatest,
  distinctUntilChanged,
  firstValueFrom,
  from,
  map,
  Observable,
  startWith,
  Subject,
  switchMap,
  takeUntil,
} from 'rxjs';
import { NgForm } from '@angular/forms';

import { ACL } from '../../../shared/acl/acl.service';
import { Affiliation } from '../../../affiliation/shared/affiliation';
import { Association } from '../../../association/shared/association';
import { AssociationModalService } from '../../../association/shared/components/association-modal/association-modal.service';
import { ClinicalTrialSponsor, ClinicalTrialSponsorConnection } from '../clinical-trial-sponsor';
import { ClinicalTrialSponsorAPI } from '../clinical-trial-sponsor-api.service';
import { ClinicalTrialSponsorJob } from '../constant/job.enum';
import { PersonAPI } from '../../../person/shared/api.service';
import { PersonBaseinfo } from '../../../person/shared/person-baseinfo';
import { PersonWithAffiliationModalService } from '../../../person/shared/modal/person-with-affiliation/person-with-affiliation.service';
import { Utils } from '../../../common/utils';
import { Value } from '../../../shared/services/value/value';
import { ValueAPI } from '../../../shared/services/value/value-api.service';
import { ValueType } from '../../../shared/enum/value-type.enum';
import { Organization } from '../../../organizations/shared/organization';
import { OrganizationCreateRequestModalService } from '../../../organizations/shared/create-request-modal/organization-create-request-modal.service';

@Component({
  selector: 'dirt-ct-sponsor-form',
  templateUrl: './form.component.html',
  exportAs: 'frmClinicalTrialSponsor',
})
export class ClinicalTrialSponsorFormComponent implements OnChanges, OnInit, AfterViewInit, OnDestroy {
  @Input()
  model: ClinicalTrialSponsor = new ClinicalTrialSponsor();

  @Input()
  currentJobType?: ClinicalTrialSponsorJob;

  @Output()
  validityChange: EventEmitter<'VALID' | 'INVALID'> = new EventEmitter();

  clinicalTrialSponsorTypes$: Observable<Value[]>;

  displayedSuggestions: { type: string; connection: ClinicalTrialSponsorConnection }[] = [];

  private suggestions: { type: string; connection: ClinicalTrialSponsorConnection }[] = [];

  @ViewChild('form')
  private ngForm: NgForm;

  private destroy$: Subject<boolean> = new Subject();

  private connectedEntityValidityChange$ = new Subject<boolean>();

  private connectionsStatus: 'EMPTY' | 'LOADED' = 'EMPTY';

  constructor(
    private svcAcl: ACL,
    private svcAssociationModal: AssociationModalService,
    private readonly svcOrganizationCreateRequestModal: OrganizationCreateRequestModalService,
    private svcPersonModal: PersonWithAffiliationModalService,
    private svcPerson: PersonAPI,
    private svcClinicalTrialSponsor: ClinicalTrialSponsorAPI,
    private svcValue: ValueAPI
  ) {
    this.onRequestAssociationCreation = this.onRequestAssociationCreation.bind(this);
    this.onRequestOrganizationCreation = this.onRequestOrganizationCreation.bind(this);
    this.onRequestPersonCreation = this.onRequestPersonCreation.bind(this);
  }

  ngOnChanges(): void {
    this.initMultiFields();
  }

  async ngOnInit(): Promise<void> {
    this.clinicalTrialSponsorTypes$ = this.svcValue.find(
      ValueType.ClinicalTrialSponsorType,
      Number.MAX_SAFE_INTEGER,
      0,
      '+title'
    );

    if (this.model.connection || !this.model.id || !this.isFieldEditable('connection')) {
      // No need to suggest anything
      return;
    }

    await this.updateDisplayedConnections();
  }

  ngAfterViewInit(): void {
    combineLatest([
      this.ngForm.statusChanges.pipe(startWith(null)),
      this.connectedEntityValidityChange$.pipe(startWith(null)),
    ])
      .pipe(
        takeUntil(this.destroy$),
        map(() => this.isValid()),
        distinctUntilChanged()
      )
      .subscribe((isValid) => {
        this.validityChange.emit(isValid ? 'VALID' : 'INVALID');
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(false);
    this.destroy$.complete();
  }

  getValue(): ClinicalTrialSponsor {
    const model = Object.assign({}, this.model);

    model.connectionId = model.connection?.['_id'] || model.connection?.['id'] || null;

    Utils.removeEmptyAndDuplicatedElementsFromArray(model, ['prooflinks']);

    return model;
  }

  isFieldEditable(field: string): boolean {
    const prefix = this.model.id ? 'update' : 'create';
    return this.svcAcl.hasCredential(`clinicalTrialSponsor.${prefix}.prop.${field}`, this.currentJobType);
  }

  async onTypeChanged(): Promise<void> {
    this.model.connection = null;
    this.connectedEntityValidityChange$.next(false); // No longer valid
    await this.updateDisplayedConnections();
  }

  async onConnectionSelected(connection: ClinicalTrialSponsorConnection): Promise<void> {
    this.model.connection = connection;
    this.connectedEntityValidityChange$.next(!!connection); // We are valid in case we have something
    await this.updateDisplayedConnections();
  }

  async onSuggestionSelected(type: string, connection: ClinicalTrialSponsorConnection): Promise<void> {
    this.model.type = type;
    this.model.connection = connection;
    this.connectedEntityValidityChange$.next(!!connection); // We are valid in case we have something
    await this.updateDisplayedConnections();
  }

  onRequestAssociationCreation(association?: Association): Observable<Association> {
    if (association?.id) {
      return;
    }
    return from(this.svcAssociationModal.open());
  }

  onRequestOrganizationCreation(): Observable<Affiliation & { organizationId: string }> {
    return from(this.svcOrganizationCreateRequestModal.open()).pipe(
      map((res: { organization: Organization; affiliation: Affiliation }) => {
        return { ...res.affiliation, organizationId: res.organization._id };
      })
    );
  }

  onRequestPersonCreation(person?: PersonBaseinfo): Observable<PersonBaseinfo> {
    if (person?._id) {
      return;
    }

    return from(this.svcPersonModal.open(null, true, 1, ['TRIAL_BASE'], 'TRIALS')).pipe(
      switchMap((person) => this.svcPerson.getBaseinfo([person.kolId])),
      map((res) => res[0])
    );
  }

  removeFromByIndex(from: any[], idx: number): void {
    from.splice(idx, 1);
  }

  pushItemToList(list: any[]): void {
    list.push('');
  }

  trackByIndex(index: number): number {
    return index;
  }

  private isValid(): boolean {
    const mandatoryConnection = !['OUT_OF_SCOPE', 'INDUSTRY'].includes(this.model.type); // Industry is not curated so far
    return this.ngForm.form.valid && (!mandatoryConnection || !!this.model.connection);
  }

  private initMultiFields(): void {
    const fields = ['prooflinks'];

    fields.forEach((field) => {
      if (!Array.isArray(this.model[field]) || this.model[field].length === 0 || this.model[field].every((v) => !v)) {
        this.model[field] = [''];
      }
    });
  }

  private async updateDisplayedConnections(): Promise<void> {
    if (this.connectionsStatus === 'EMPTY' && this.model.id) {
      // Need to pull the suggestions
      this.suggestions = await firstValueFrom(this.svcClinicalTrialSponsor.getConnectionSuggestions(this.model.id));
      this.connectionsStatus = 'LOADED';
    }

    if (this.model.connection || this.model.type === 'OUT_OF_SCOPE') {
      this.displayedSuggestions = [];
    } else {
      this.displayedSuggestions = !this.model.type
        ? this.suggestions
        : this.suggestions.filter((c) => c.type === this.model.type);
    }
  }
}
