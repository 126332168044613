import { Component, Input, OnChanges, SimpleChange } from '@angular/core';
import { diff, Diff } from 'deep-diff';

import { APIService } from '../../services/api/api.service';
import { JsonViewerService } from '../../modals/json-viewer/json-viewer.service';
import { AuditLogInstance, ChangeType, Change, ValueType } from './audit-log.interface';
import { Utils } from '../../../common/utils';

@Component({
  selector: 'dirt-audit-log',
  templateUrl: './audit-log.component.html',
  styleUrls: ['./audit-log.component.scss'],
})
export class AuditLogComponent implements OnChanges {
  audit: AuditLogInstance[];
  isLoading = false;

  @Input()
  id: string | string[];

  @Input()
  entityAPI: APIService;

  @Input()
  set excludedFields(value: string[]) {
    this._excludedFields = this._excludedFields.concat(value);
  }

  /**
   * It can be used for expanding specific Array properties.
   * @type {string[]}
   */
  @Input()
  explodeComparisonKeys: string[] = [];

  @Input()
  shallowCompKeys = [];

  @Input()
  diffArrayMappers: { [key: string]: (x: any) => string } = {};

  total: { count: number };
  pagingPage = 1;
  pagingLimit = 5;
  pagingSkip = 0;

  private _excludedFields = ['_version', '_id', 'id', 'updatedAt', 'createdAt', 'additionalDetails'];

  private _changesOrder: ChangeType[] = ['added', 'updated', 'deleted'];

  constructor(private svcJsonViewer: JsonViewerService) {}

  ngOnChanges(changes: { [propName: string]: SimpleChange }): void {
    if (changes['id'] && changes['id'].currentValue) {
      this.getAuditLog(this.pagingLimit, this.pagingSkip);
      this.getAuditCount();
    }
  }

  getChange(value: Diff<any, any>) {
    switch (value.kind) {
      case 'N':
        return 'added';
      case 'E':
        return 'updated';
      case 'D':
        return 'deleted';
      case 'A':
        return this.getChange(value.item);
    }
  }

  getPath(value: Diff<any, any>, doc: any): string[] {
    return Utils.compact(
      value.path.map((part, index) => {
        if (Number.isInteger(part)) {
          const docPath = value.path.slice(0, index + 1);

          if (doc) {
            const docPart = Utils.getByPath(doc, docPath);
            if (typeof docPart === 'object') {
              return Utils.findVal(docPart, 'name');
            } else {
              return null;
            }
          }
        } else {
          return Utils.upperCase(part);
        }
      })
    );
  }

  getValue(value: Diff<any, any>): string {
    switch (value.kind) {
      case 'N':
        return value.rhs;
      case 'E':
        return value.rhs;
      case 'D':
        return value.lhs;
      case 'A': {
        const result = this.getValue(value.item);
        return typeof result !== 'object' ? result : Utils.findVal(result, 'name');
      }
    }
  }

  formatPath = (path: string[]) => path.join(' > ');

  diffLog(lhs: any, rhs: any): Change[] {
    lhs = Utils.clone(lhs);
    rhs = Utils.clone(rhs);
    Utils.normalizeObject(lhs);
    Utils.normalizeObject(rhs);

    let diffData = [];

    // expanding `keys` of Array properties that are passed explicitly to the component
    // example cases: CT for `selectedFocusAreas`
    this.explodeComparisonKeys.forEach((key) => {
      if (lhs[key]) {
        lhs[key].forEach((v, i) => (lhs[key + '-' + (i + 1)] = v));
        delete lhs[key];
      }
      if (rhs[key]) {
        rhs[key].forEach((v, i) => (rhs[key + '-' + (i + 1)] = v));
        delete rhs[key];
      }
    });

    this.shallowCompKeys.forEach((key) => {
      const lhsArr = lhs[key] || [];
      const rhsArr = rhs[key] || [];

      delete lhs[key];
      delete rhs[key];

      const lhsArrMp = this.diffArrayMappers[key] ? lhsArr.map(this.diffArrayMappers[key]) : lhsArr;
      const rhsArrMp = this.diffArrayMappers[key] ? rhsArr.map(this.diffArrayMappers[key]) : rhsArr;
      const addedItems = Utils.difference(rhsArrMp, lhsArrMp);
      const deletedItems = Utils.difference(lhsArrMp, rhsArrMp);

      addedItems.forEach((v) => {
        diffData.push({
          kind: 'A',
          path: [key],
          item: {
            kind: 'N',
            rhs: v,
          },
        });
      });

      deletedItems.forEach((v) => {
        diffData.push({
          kind: 'A',
          path: [key],
          item: {
            kind: 'D',
            lhs: v,
          },
        });
      });
    });

    (diff(lhs, rhs) || [])
      .map((v) => {
        Utils.removeItem(v.path, '_meta');
        return v;
      })
      .filter((v) => !this._excludedFields.some((ex) => v.path.includes(ex)))
      .forEach((v: any) => {
        const value = v.rhs || v.lhs;
        if (Utils.isLiteralObject(value)) {
          diffData = diffData.concat(
            Utils.unwindObject(value).map((uv) => ({
              kind: v.kind,
              path: v.path.concat(uv.path),
              [v.rhs ? 'rhs' : 'lhs']: uv.value,
            }))
          );
          // Unwind a object new object in array
        } else if (v.kind === 'A' && v.item.kind === 'N' && Utils.isLiteralObject(v.item.rhs)) {
          diffData.push(v);
          diffData = diffData.concat(
            Utils.unwindObject(v.item.rhs)
              .filter((uv) => !this._excludedFields.some((ex) => uv.path.includes(ex)))
              .map((uv) => ({
                kind: v.item.kind,
                path: v.path.concat([v.index]).concat(uv.path),
                rhs: uv.value,
              }))
          );
        } else {
          diffData.push(v);
        }
      });

    return diffData
      .map((v) => ({
        change: this.getChange(v),
        path: this.formatPath(this.getPath(v, rhs)),
        value: this.getValue(v),
      }))
      .filter((v) => !(v.change === 'added' && !v.value))
      .reduce((all, current) => {
        const item = all.find((v) => current.change === v.change && current.path === v.path);
        if (!item) {
          all.push({
            change: current.change,
            path: current.path,
            value: [current.value],
          });
        } else {
          item.value.push(current.value);
        }
        return all;
      }, [])
      .map((v) => ({
        ...v,
        type: this.getValueType(v.value),
      }))
      .sort((a, b) => this._changesOrder.indexOf(a.change) - this._changesOrder.indexOf(b.change));
  }

  getAuditLog(limit, skip) {
    this.isLoading = true;

    this.entityAPI['audit'](this.id, limit, skip).subscribe(
      (logs) => {
        this.audit = logs.map((o, index) => {
          const prevLog = logs[index + 1];

          const lhs = prevLog ? prevLog.document : o.document;
          const rhs = o.document;

          o.changes = this.diffLog(lhs, rhs);
          return o;
        });
      },
      null,
      () => (this.isLoading = false)
    );
  }

  showJson(doc: any): void {
    const options = {
      title: `Audit Version: ${doc._version}`,
      content: doc,
    };

    this.svcJsonViewer.open(options);
  }

  isManuallyAssigned(log: AuditLogInstance): boolean {
    const userId = (log.user && log.user.user_id) || log.user;
    const assigneeId = log.document._meta && log.document._meta.assignee;

    return log.action === 'assigned' && userId !== assigneeId;
  }

  getValueType(values: any[]): ValueType {
    const [sample] = values;
    if (Utils.isDate(sample)) {
      return 'date';
    }

    if (typeof sample === 'string' && sample.includes('auth0')) {
      return 'user';
    }

    if (typeof sample === 'string' && Utils.isURL(sample)) {
      return 'link';
    }

    return 'string';
  }

  getPaginatedAuditLogs(page: number) {
    this.pagingPage = page;
    this.pagingSkip = (this.pagingPage - 1) * this.pagingLimit;
    this.getAuditLog(this.pagingLimit, this.pagingSkip);
  }

  getAuditCount() {
    delete this.total;
    this.entityAPI['auditCount'](this.id).subscribe((res) => (this.total = res));
  }

  hasFullPage() {
    return (this.audit?.length || 0) >= (this.pagingLimit || 0);
  }

  getEffectiveTotal() {
    // as we offload to S3, we have mongo count + more (accept empty last page)
    if (!this.hasFullPage()) {
      // we're done - prev pages + this one & that's it
      return ((this.pagingPage || 1) - 1) * (this.pagingLimit || 0) + (this.audit?.length || 0);
    } else {
      // there might always be more
      return Math.max(this.total?.count || 0, (this.pagingPage || 1) * (this.pagingLimit || 0)) + 1;
    }
  }
}
