import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  GET,
  Query,
  MediaType,
  Produces,
  Body,
  POST,
  Path,
  DELETE,
  PATCH,
} from '../../shared/services/rest/rest.service';
import { catchError, map } from 'rxjs/operators';

import { APIService } from '../../shared/services/api/api.service';
import { Affiliation } from './affiliation';
import { AffiliationAdvancedSearchDto } from './affiliation-advanced-search-dto';
import { AffiliationBaseinfo } from './affiliation-baseinfo';

@Injectable()
export class AffiliationAPI extends APIService {
  public find(
    term: string | object,
    limit?: number,
    skip?: number,
    sort?: string,
    filter?: Record<string, unknown>,
    grouped?: boolean,
    topOnly?: boolean,

    // Per KS-5757, only the list component should set curatedOnly to false
    curatedOnly: boolean = true
  ): ReturnType<AffiliationAPI['_find']> {
    if (curatedOnly) {
      filter = { ...(filter ?? {}), source: ['curated'] };
    }
    return this._find(term, limit, skip, sort, filter, grouped, topOnly);
  }

  @GET('affiliations')
  @Produces(MediaType.JSON)
  private _find(
    @Query('term') term: string | object,
    @Query('limit') limit?: number,
    @Query('skip') skip?: number,
    @Query('sort') sort?: string,
    @Query('filter') filter?: any,
    @Query('grouped') grouped?: boolean,
    @Query('topOnly') topOnly?: boolean
  ): Observable<Affiliation[]> {
    return;
  }

  @GET('affiliations/{id}')
  @Produces(MediaType.JSON)
  public findById(@Path('id') id: string): Observable<Affiliation> {
    return;
  }

  @GET('affiliations/merge/{id}')
  @Produces(MediaType.JSON)
  public findMergeById(
    @Path('id') id: string
  ): Observable<{ _id; type; manualDecision; manualDecisionDetail; minCreatedAtA; minCreatedAtB; idA; idB }> {
    return;
  }

  public count(term?: string, filter?: Record<string, unknown>, countAll?: boolean, curatedOnly: boolean = true) {
    if (curatedOnly) {
      filter = { ...(filter ?? {}), source: ['curated'] };
    }
    return this._count(term, filter, countAll);
  }

  @GET('affiliations/count')
  @Produces(MediaType.JSON)
  private _count(
    @Query('term') term?: string,
    @Query('filter') filter?: any,
    @Query('countAll') countAll?: boolean
  ): Observable<{ count: number }> {
    return;
  }

  public search(
    term: string,
    limit?: number,
    topOnly: boolean = false,
    curatedOnly: boolean = false,
    excludeUnpolished: boolean = false,
    augmentOrganization: boolean = true
  ): Observable<(Affiliation & { organizationId?: string; readyForDelivery?: boolean })[]> {
    // From now on, we want to force the return of only Organizations
    return this._search(term, limit, topOnly, curatedOnly, excludeUnpolished, augmentOrganization);
  }

  // This private method is used to force the backend optional query parameter
  // `augmentOrganization` into a required but automatically defaulted value
  // on the frontend.
  @GET('affiliations/search')
  @Produces(MediaType.JSON)
  private _search(
    @Query('term') term: string,
    @Query('limit') limit?: number,
    @Query('topOnly') topOnly?: boolean,
    @Query('curatedOnly') curatedOnly?: boolean,
    @Query('excludeUnpolished') excludeUnpolished?: boolean,
    @Query('augmentOrganization') augmentOrganization?: boolean
  ): Observable<(Affiliation & { organizationId?: string; readyForDelivery?: boolean })[]> {
    return;
  }

  @POST('affiliations/advanced-search')
  @Produces(MediaType.JSON)
  public advancedSearch(
    @Body searchDto: AffiliationAdvancedSearchDto
  ): Observable<{ items: Affiliation[]; total: number }> {
    return;
  }

  @POST('affiliations')
  @Produces(MediaType.JSON)
  public create(
    @Body affiliation: Affiliation,
    @Query('force') force: boolean
  ): Observable<{ res: Affiliation; potentialDups?: { name; department }[] }> {
    return;
  }

  @PATCH('affiliations/{id}')
  @Produces(MediaType.JSON)
  public update(@Path('id') id: string, @Body affiliation: Affiliation): Observable<Affiliation> {
    return;
  }

  @POST('affiliations/{id}/duplicateSuspect')
  @Produces(MediaType.JSON)
  public duplicateSuspect(
    @Path('id') id: string,
    @Body orig: { id: string }
  ): Observable<{ ok: boolean; msg?: string }> {
    return;
  }

  @DELETE('affiliations/{id}')
  @Produces(MediaType.JSON)
  public deleteById(
    @Path('id') id: string,
    @Query('requestConfirmed') requestConfirmed?: boolean
  ): Observable<{ connectionCount: number } | null> {
    return;
  }

  @POST('affiliations/baseinfo')
  @Produces(MediaType.JSON)
  public getBaseinfo(
    @Body ids: string[],
    @Query('includeOrganizationIds') includeOrganizationIds?: boolean
  ): Observable<AffiliationBaseinfo[]> {
    return;
  }

  /**
   * Find all the audit logs of a given model id
   * @param id
   */
  @GET('affiliations/{id}/audit')
  @Produces(MediaType.JSON)
  public audit(@Path('id') id: string, @Query('limit') limit?: number, @Query('skip') skip?: number): Observable<any> {
    return;
  }

  /**
   * Find count of audit logs
   * @param id
   */
  @GET('affiliations/{id}/auditcount')
  @Produces(MediaType.JSON)
  public auditCount(@Path('id') id: string): Observable<any> {
    return;
  }

  /**
   * Delete the affiliation and mark it as merged with another affiliation
   *
   * @method merge
   * @param {String} (loser)id
   * @param {String} winnerId
   * @returns {Observable}
   */
  @POST('affiliations/{id}/merge/{winnerId}')
  @Produces(MediaType.JSON)
  public merge(
    @Path('id') /*loser*/ id: string,
    @Path('winnerId') winnerId: string
  ): Observable<{
    ok: boolean;
    winner: Affiliation;
    pairs?: { exist: { id; name; department }; merge: { id; name; department }; merged: boolean }[];
    msg?: string;
  }> {
    return;
  }

  /**
   * Submit a merge decision (and possibly do
   *
   * @method merge
   * @param {String} (loser)id
   * @param {String} winnerId
   * @returns {Observable}
   */
  @POST('affiliations/{id}/submit-merge/{winnerId}')
  @Produces(MediaType.JSON)
  public submitMerge(
    @Query('findingId') findingId: string,
    @Query('decision') decision: string,
    @Path('id') /*loser*/ id: string,
    @Path('winnerId') winnerId: string
  ): Observable<{
    ok: boolean;
    winner?: Affiliation;
    pairs?: { exist: { id; name; department }; merge: { id; name; department }; merged: boolean }[];
    msg?: string;
  }> {
    return;
  }

  @GET('affiliations/position')
  @Produces(MediaType.JSON)
  public searchPos(@Query('term') term: string): Observable<{ position: string }> {
    return;
  }

  @GET('affiliations/next')
  @Produces(MediaType.JSON)
  public findNext(): Observable<Affiliation> {
    return;
  }

  @GET('affiliations/next-merge')
  @Produces(MediaType.JSON)
  public findNextMerge(): Observable<{ _id; idA; idB } | null> {
    return;
  }

  public startBulkListUpload(file: File, secondEyes: string): Observable<string> {
    return this.doBulkUpload(file, secondEyes, 'affiliations/bulk-list');
  }

  @POST('affiliations/check-exact-duplicate')
  @Produces(MediaType.JSON)
  public checkExactDuplicate(
    @Body affiliation: Partial<Affiliation> & { id; name; department?; source?; sourceLfka? }
  ): Observable<{ duplicateId: string | null }> {
    return;
  }

  @PATCH('affiliations/{id}/submit')
  @Produces(MediaType.JSON)
  public submit(@Path('id') id: string, @Body affiliation: Affiliation): Observable<Affiliation> {
    return;
  }

  @POST('affiliations/{id}/maintenance-request')
  @Produces(MediaType.JSON)
  public maintenanceRequest(@Path('id') id: string, @Body additionalData: any): Observable<{ ok }> {
    return;
  }

  /**
   * It downloads a file containing affiliations data  with optional filters and search term.
   * @param [filter] - An optional object that contains filter criteria for the affiliations to be
   * downloaded. It is encoded as a JSON string and then URL encoded before being added to the download
   * URL.
   * @param {string} [name] - search for specific affiliation with this name
   * @returns an affiliations file as binary.
   */
  public downloadAffiliationsFile(filter?: { [key: string]: any }, name?: string): Observable<any> {
    const params = new URLSearchParams();
    filter && params.set('filter', JSON.stringify(filter));
    name && params.set('name', name);

    const paramString = params.toString();
    const url = `${this.getBaseUrl()}affiliations/export${paramString ? `?${paramString}` : ''}`;

    return this.http.get(url, { responseType: 'blob' }).pipe(catchError((err) => this.handleError(err)));
  }

  /**
   * Upload the file and get a bulk upload ID
   *
   * @param file
   * @param secondEyes
   */
  private doBulkUpload(file: File, secondEyes: string, endpoint: string): Observable<string> {
    // (actually do this ourselves!)
    const formData: FormData = new FormData();
    formData.set('xlsx', file); // multipart name must match server
    formData.set('secondEyes', secondEyes);

    return this.http
      .post(this.getBaseUrl() + endpoint, formData)
      .pipe(catchError((err) => this.handleError(err)))
      .pipe(map((obj: any) => obj.bulkTrackingId));
  }
}
