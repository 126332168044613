import { Component, Input, OnInit } from '@angular/core';
import { concatMap, of, tap } from 'rxjs';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { AddressEntity } from '../address-entity';
import { Organization } from '../organization';
import { OrganizationAffiliationMappingBaseInfo, OrganizationAPI } from '../api.service';
import { OrganizationSubTree } from '../types/sub-tree';

type OrganizationId = string;

export type OrganizationSelectModalOptions = {
  /** When OPTIONAL, allow selection but don't mandate it, when required, force selection */
  mappingSelection: 'DISABLED' | 'OPTIONAL' | 'REQUIRED';
  /** When OPTIONAL, allow selection but don't mandate it, when required, force selection */
  addressSelection: 'DISABLED' | 'OPTIONAL' | 'REQUIRED';
};

@Component({
  selector: 'dirt-organization-select-modal',
  styleUrls: ['./select-modal.component.scss'],
  templateUrl: 'select-modal.component.html',
})
export class OrganizationSelectModal implements OnInit {
  @Input()
  selectedId: string;

  @Input()
  options: OrganizationSelectModalOptions = { mappingSelection: 'DISABLED', addressSelection: 'DISABLED' };

  root: OrganizationSubTree;

  // One organization can have multiple addresses and be multiple old organizations
  // So the curator has to pick what the want in this situation.
  selectedOrganization?: Organization;
  selectedAddress?: AddressEntity;
  selectedAffiliationId?: string;

  organizationInfo: Record<OrganizationId, Organization> = {};

  selectedOrganizationMappings: OrganizationAffiliationMappingBaseInfo[];
  selectedOrganizationAddresses: AddressEntity[];

  isLoading = true;

  get canSelectMapping(): boolean {
    return this.options.mappingSelection === 'OPTIONAL' || this.options.mappingSelection === 'REQUIRED';
  }

  get requireMapping(): boolean {
    return this.options.mappingSelection === 'REQUIRED';
  }

  get canSelectAddress(): boolean {
    return this.options.addressSelection === 'OPTIONAL' || this.options.addressSelection === 'REQUIRED';
  }

  get requireAddress(): boolean {
    return this.options.addressSelection === 'REQUIRED';
  }

  constructor(public activeModal: NgbActiveModal, private svcOrganization: OrganizationAPI) {}

  ngOnInit(): void {
    this.svcOrganization.getOrganizationDisplayTree(this.selectedId).subscribe((root) => {
      this.root = root;

      // Just need to flatten our tree into a map
      const flatten = (leaf) => {
        this.organizationInfo[leaf._id] = leaf;
        if (leaf.children?.length) {
          leaf.children.forEach((child) => flatten(child));
        }
      };
      flatten(root);

      this.onSelectedIdChange(this.selectedId);
    });
  }

  onSelectedIdChange(id: string): void {
    this.selectedOrganization = undefined;
    this.selectedAddress = undefined;
    this.selectedAffiliationId = undefined;
    this.selectedOrganizationMappings = [];

    if (!id) {
      return;
    }

    this.isLoading = true;
    this.svcOrganization
      .findById(id)
      .pipe(
        tap((org) => (this.selectedOrganization = org)),
        concatMap(() =>
          this.svcOrganization
            .getOrganizationMatchedAffiliationMappings({ organizationIds: [id] })
            .pipe(tap((mappings) => (this.selectedOrganizationMappings = mappings)))
        ),
        concatMap(() => {
          if (!this.selectedOrganization.addresses?.length || !this.selectedOrganizationMappings?.length) {
            return of(null);
          }

          const addressIds = this.selectedOrganization.addresses.map((a) => a.id);
          return this.svcOrganization.findAddressByIds(addressIds.join(','));
        }),
        tap((addresses) => (this.selectedOrganizationAddresses = addresses))
      )
      .subscribe(() => {
        // We can preselect if there is only one choice - saves a few clicks
        if (this.selectedOrganizationMappings?.length === 1) {
          this.selectedAffiliationId = this.selectedOrganizationMappings[0].affiliationId;
        }
        if (this.selectedOrganizationAddresses?.length === 1) {
          this.selectedAddress = this.selectedOrganizationAddresses[0];
        }

        this.isLoading = false;
      });
  }

  onSelect(): void {
    if (this.requireMapping && !this.selectedOrganizationMappings?.length) {
      return alert('Organization is not connected to any (old) institution');
    }

    this.activeModal.close({
      organization: this.selectedOrganization,
      address: this.selectedAddress,
      affiliationId: this.selectedAffiliationId,
    });
  }
}
