import { Component, OnInit, OnDestroy, ViewChild, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { firstValueFrom, Subject, take, takeUntil } from 'rxjs';

import { Event, REQUIRES_COMPILATION_TYPES } from '../shared/event';
import { EventAPI } from '../shared/api.service';
import { EventStatus } from '../shared/enum/status.enum';
import { EventFormComponent } from '../shared/form/form.component';
import { ValueAPI } from '../../shared/services/value/value-api.service';
import { ValueType } from '../../shared/enum/value-type.enum';
import { EventSeriesAPI } from '../../event-series/shared/api.service';

type SubmitAction = 'SAVE' | 'SAVE_AND_BACK' | 'SAVE_AND_NEW';

@Component({
  selector: 'dirt-event-create',
  templateUrl: 'create.component.html',
  styleUrls: ['create.component.scss'],
})
export class EventCreateComponent implements OnInit, OnDestroy {
  event: Event = new Event();
  seriesName: string = null;
  isSubmitting = false;

  @ViewChild('frmEvent', { static: false })
  private frmEvent: EventFormComponent;

  private inScopeCountries: string[] = [];

  private destroy$: Subject<boolean> = new Subject();

  private seriesId?: string;

  private parentEvent?: Event;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private svcEvent: EventAPI,
    private svcValue: ValueAPI,
    private changeDetector: ChangeDetectorRef,
    private svcSeries: EventSeriesAPI
  ) {}

  ngOnInit(): void {
    this.route.params.pipe(takeUntil(this.destroy$)).subscribe(async (params) => {
      this.event.parent = params['parentId'];
      if (this.event.parent) {
        this.parentEvent = await firstValueFrom(this.svcEvent.findById(this.event.parent));
        this.event.sessionDate = new Date(
          Date.UTC(
            this.parentEvent.startDate.year,
            this.parentEvent.startDate.month - 1,
            this.parentEvent.startDate.day
          )
        );
      }
    });

    this.route.queryParams.pipe(take(1)).subscribe(async (params) => {
      this.changeDetector.detach(); // we'll do a change detection once and for all

      this.seriesId = params['seriesId'];

      if (params['from']) {
        // Prefill event details from another event
        await this.cloneEvent(params['from']);
      } else if (params['seriesId']) {
        // Prefill series information - from event has priority
        const series = await firstValueFrom(this.svcSeries.findById(params['seriesId']));
        if (series) {
          this.event.series.push({ id: series.id, name: series.name });
          this.event.name = series.name;
          this.event.type = series.type;
          this.event.requiresCompilation = REQUIRES_COMPILATION_TYPES.includes(series.type);
          this.event.projectNames = series.projectNames;
          this.event.category = series.category;
          this.event.webSource = (series.webSources || [])[0];
          this.event.additionalWebSources = (series.webSources || []).slice(1);
          this.event.ownerProduct = series.ownerProduct;
          this.seriesName = series.name;
        }
      }

      if (params['currentJobType'] && !this.event.projectNames?.includes(params['currentJobType'])) {
        // Keep track of what created the event
        this.event.projectNames = [...(this.event.projectNames || []), params['currentJobType']];
      }

      // Keep that at the bottom after you've affected all the values!
      this.changeDetector.reattach();
      this.changeDetector.detectChanges();
    });

    this.svcValue.find(ValueType.InScopeCountry, Number.MAX_SAFE_INTEGER).subscribe((values) => {
      this.inScopeCountries = values.map((v) => v.code as string);
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(false);
    this.destroy$.complete();
  }

  onSubmit(event: Event, action: SubmitAction): void {
    let problems: string[] = [];
    if (!event.parent && event.address?.countryCode && !this.inScopeCountries.includes(event.address.countryCode)) {
      problems.push('Selected country is out of scope.');
    }

    let message: string;
    if (problems.length > 0) {
      message = `The following issues were found: \n - ${problems.join('\n - ')}\n\n`;
      message += 'Do you really want to submit this entry?';

      // confirm the submit action
      if (!window.confirm(message)) {
        return;
      }
    }

    if (!event.parent) {
      event._meta = { status: EventStatus.READY_FOR_COMPILATION };
      event['identified'] = true;
    }

    this.createEvent(event, action);
  }

  onNoInfo(event: Event): void {
    event._meta = { status: EventStatus.NO_INFO };
    this.createEvent(event, 'SAVE_AND_BACK');
  }

  goBack(): void {
    const link = this.event.parent ? ['/event/detail', this.event.parent] : ['/event'];
    this.router.navigate(link);
  }

  private createEvent(event: Event, action: SubmitAction): void {
    this.isSubmitting = true;

    if (!event.parent && !event.requiresCompilation) {
      event._meta = { ...event._meta, status: EventStatus.DONE };
    }

    this.svcEvent.create(event).subscribe({
      next: (res) => {
        this.event.id = res._id; // we'll fetch the rest on the detail page
        switch (action) {
          case 'SAVE': // save and show details
            this.goToDetailView();
            break;
          case 'SAVE_AND_NEW': // save and reset form
            if (this.parentEvent?.startDate) {
              this.frmEvent.resetForm(this.parentEvent);
            } else {
              this.frmEvent.resetForm();
            }
            break;
          default: // save and go back to previous main view
            if (this.seriesId) {
              this.router.navigate(['/event-series/detail', this.seriesId]); // Go back to the series details
              return;
            }

            this.goBack();
            break;
        }
      },
      error: () => (this.isSubmitting = false),
      complete: () => (this.isSubmitting = false),
    });
  }

  private goToDetailView(): void {
    this.router.navigate(['/event/detail', this.event.id]);
  }

  private async cloneEvent(fromId: string): Promise<void> {
    const fromEvent = await firstValueFrom(this.svcEvent.findById(fromId));
    if (!fromEvent) {
      return;
    }

    this.event.name = fromEvent.name;
    this.event.nameTranslated = fromEvent.nameTranslated;
    this.event.originalName = fromEvent.originalName;
    this.event.originalLanguage = fromEvent.originalLanguage;
    this.event.type = fromEvent.type;
    this.event.requiresCompilation = fromEvent.requiresCompilation;
    this.event.projectNames = fromEvent.projectNames;
    this.event.category = fromEvent.category;
    this.event.organizers = fromEvent.organizers;
    this.event.reach = fromEvent.reach;
    this.event.priority = fromEvent.priority;
    this.event.isVirtual = fromEvent.isVirtual;
    this.event.address = fromEvent.address;
    // do NOT copy hashtags this.event.hashtags = fromEvent.hashtags;
    this.event.series = fromEvent.series;
    this.event.ownerProduct = fromEvent.ownerProduct;
  }
}
