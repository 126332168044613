<form #frmEvent="ngForm" novalidate>
  <div [diMarkerWrapper]="model" [defaultFixed]="true" class="clearfix">
    <!-- Name -->
    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2" for="name">Name</label>
      <div class="col-sm-8 col-md-10">
        <div class="input-group">
          <ng-container *ngIf="isFieldEditable('name'); then nameEditable; else nameUneditable"></ng-container>
          <ng-template #nameEditable>
            <ng-container *ngIf="isFieldEditable('name')"
              ><input
                type="text"
                id="name"
                class="form-control"
                name="name"
                [ngModel]="model.name"
                (ngModelChange)="model.name = longDash($event)"
                dirtPasteNormalizer
                autofocus
                required
                *diMarker="{ name: 'name', entity: model.parent ? 'session' : 'event', area: 'ALL' }"
            /></ng-container>
          </ng-template>
          <ng-template #nameUneditable>
            <ng-container *ngIf="!isFieldEditable('name')"
              ><input
                type="text"
                id="name"
                class="form-control"
                name="name"
                [ngModel]="model.name"
                (ngModelChange)="model.name = longDash($event)"
                readonly
                *diMarker="{ name: 'name', entity: model.parent ? 'session' : 'event' }"
            /></ng-container>
          </ng-template>

          <div class="input-group-append" *ngIf="!model.parent">
            <div class="input-group-text" placement="left" container="body" ngbTooltip="Name Translated">
              <input
                type="checkbox"
                name="isNameTranslated"
                [(ngModel)]="model.nameTranslated"
                [disabled]="!isFieldEditable('nameTranslated')"
              />
            </div>
          </div>
        </div>
        <div class="dept-error" *ngIf="seriesName && model.name === seriesName">
          The name is exactly the series name - make sure you adjust to this individual event!
        </div>
      </div>
    </div>

    <!-- Original Name -->
    <div class="form-group row" *ngIf="!model.parent">
      <label class="col-form-label col-sm-4 col-md-2" for="originalName">Original Name</label>
      <div class="col-sm-8 col-md-10">
        <!-- Keep name: 'nameSecondary' in diMarker! -->
        <input
          type="text"
          id="originalName"
          class="form-control"
          name="originalName"
          [ngModel]="model.originalName"
          (ngModelChange)="model.originalName = longDash($event)"
          [readonly]="!isFieldEditable('originalName')"
          *diMarker="{ name: 'nameSecondary', entity: 'event', area: 'ID' }"
        />
      </div>
    </div>

    <!-- Original Language -->
    <div class="form-group row" *ngIf="model.id && (model.originalLanguage || model.originalName)">
      <label class="col-form-label col-sm-4 col-md-2" for="originalLanguage">Original Language</label>
      <div class="col-sm-8 col-md-10">
        <select
          id="originalLanguage"
          class="form-control custom-select"
          name="originalLanguage"
          [(ngModel)]="model.originalLanguage"
          [disabled]="!isFieldEditable('originalLanguage')"
        >
          <option *ngFor="let language of languages$ | async" [value]="language.code">{{ language.title }}</option>
        </select>
      </div>
    </div>

    <!-- Type -->
    <div class="form-group row" *ngIf="!model.parent">
      <label class="col-form-label col-sm-4 col-md-2" for="type">Type</label>
      <div class="col-sm-8 col-md-10">
        <select
          id="type"
          class="form-control custom-select"
          name="type"
          [(ngModel)]="model.type"
          (ngModelChange)="onTypeChange($event)"
          [disabled]="!isFieldEditable('type')"
          required
          *diMarker="{ name: 'type', entity: model.parent ? 'session' : 'event', area: 'ID' }"
        >
          <option *ngFor="let type of types" [value]="type.code" [disabled]="type.disabled">
            {{ type.title }}
          </option>
        </select>
      </div>
    </div>

    <!-- needs compilation (default based on type) -->
    <div class="form-group row" *ngIf="!model.parent">
      <label class="col-form-label col-sm-4 col-md-2">Needs compilation</label>
      <div class="col-sm-8 col-md-10 pt-1">
        <ui-switch
          name="requiresCompilation"
          [(ngModel)]="model.requiresCompilation"
          size="small"
          color="#6d5cae"
          [disabled]="!isFieldEditable('requiresCompilation') || !isEventManager()"
        >
        </ui-switch>
      </div>
    </div>

    <div class="form-group row" *ngIf="!model.parent">
      <label class="col-form-label col-sm-4 col-md-2">Projects</label>
      <div class="col-md-2 di-project-names">
        <span
          *diMarker="{ name: 'projectNames', entity: model.parent ? 'session' : 'event', leftAlign: true, area: 'ID' }"
        >
          <dirt-multiselect-dropdown
            name="projectNames"
            [ngClass]="{ disabled: !isFieldEditable('projectNames') }"
            [options]="projects"
            [settings]="projectsSettings"
            [(ngModel)]="model.projectNames"
            required
          >
          </dirt-multiselect-dropdown>
        </span>
      </div>
    </div>

    <!-- Category -->
    <div class="form-group row" *ngIf="!model.parent">
      <label class="col-form-label col-sm-4 col-md-2" for="category">Category</label>
      <div class="col-sm-8 col-md-10">
        <select
          id="category"
          class="form-control custom-select"
          name="category"
          [(ngModel)]="model.category"
          [disabled]="!isFieldEditable('category')"
          *diMarker="{ name: 'category', entity: model.parent ? 'session' : 'event', area: 'ID' }"
          required
        >
          <option *ngFor="let category of categories" [value]="category.code">
            {{ category.title }}
          </option>
        </select>
      </div>
    </div>

    <!-- Organizer -->
    <div class="form-group row" *ngIf="!model.parent">
      <label class="col-form-label col-sm-4 col-md-2">Organizers</label>
      <div class="col-sm-8 col-md-10">
        <ul>
          <li *ngFor="let o of model.organizers; let i = index" [ngClass]="{ 'suggest-only': o.suggestOnly }">
            {{ organizerTitle(o) }}
            <small
              ><a *ngIf="isFieldEditable('organizers') && o.suggestOnly" class="alink" (click)="o.suggestOnly = false"
                ><i class="fa fa-check"></i> Suggest only - accept</a
              ></small
            >
            <small
              ><a
                *ngIf="canViewDetails('affiliation') && 'AFFILIATION' === o.type"
                [routerLink]="['/affiliation/detail', o.affiliationId]"
                rel="noopener"
                target="_blank"
                >Open organization</a
              ><a
                *ngIf="canViewDetails('organization') && 'ORGANIZATION' === o.type"
                [routerLink]="['/organization/detail', o.organizationId]"
                rel="noopener"
                target="_blank"
                >Open organization</a
              >
              <a
                *ngIf="canViewDetails('association') && 'ASSOCIATION' === o.type"
                [routerLink]="['/association/detail', o.associationId]"
                rel="noopener"
                target="_blank"
                >Open association</a
              >
              <a
                *ngIf="canViewDetails('account') && 'ACCOUNT' === o.type"
                [routerLink]="['/account/detail', o.accountId]"
                rel="noopener"
                target="_blank"
                >Open account</a
              >
              <a
                *ngIf="canViewDetails('committee') && 'COMMITTEE' === o.type"
                [routerLink]="['/committee/detail', o.committeeId]"
                rel="noopener"
                target="_blank"
                >Open committee</a
              ></small
            >
            <i *ngIf="isFieldEditable('organizers')" class="fa fa-trash-o alink" (click)="onRemoveOrganizer(i)"></i>
          </li>
          <li *ngIf="!model.organizers?.length" style="color: red">(none yet)</li>
        </ul>
        <div *ngIf="isFieldEditable('organizers') && !hasNoOrganizer()">
          Add another:
          <label
            ><input type="radio" name="addOrganizerType" [(ngModel)]="addOrganizerType" value="AFFILIATION" />
            Organization</label
          >
          <label
            ><input type="radio" name="addOrganizerType" [(ngModel)]="addOrganizerType" value="ASSOCIATION" />
            Association</label
          >
          <label
            ><input type="radio" name="addOrganizerType" [(ngModel)]="addOrganizerType" value="ACCOUNT" />
            Account</label
          >
          <label
            ><input type="radio" name="addOrganizerType" [(ngModel)]="addOrganizerType" value="COMMITTEE" />
            Committee</label
          >
          <label
            ><input type="radio" name="addOrganizerType" [(ngModel)]="addOrganizerType" value="NO_ORGANIZER" /> No
            organizer</label
          >
        </div>
        <div *ngIf="isFieldEditable('organizers') && addOrganizerType === 'AFFILIATION'">
          <dirt-affiliation-single-select
            [wide]="true"
            [curatedOnly]="true"
            [creationRequestHandler]="svcAcl.hasCredential('organization.request') && onRequestOrganizationCreation"
            [augmentOrganization]="true"
            (affiliationSelected)="onOrganizerAdded('AFFILIATION', $event)"
          ></dirt-affiliation-single-select>
        </div>
        <div *ngIf="isFieldEditable('organizers') && addOrganizerType === 'ASSOCIATION'">
          <dirt-association-single-select
            [wide]="true"
            [rootOnly]="true"
            (associationSelected)="onOrganizerAdded('ASSOCIATION', $event)"
          ></dirt-association-single-select>
        </div>
        <div *ngIf="isFieldEditable('organizers') && addOrganizerType === 'ACCOUNT'">
          <dirt-account-single-select
            [wide]="true"
            (accountSelected)="onOrganizerAdded('ACCOUNT', $event)"
          ></dirt-account-single-select>
        </div>
        <div *ngIf="isFieldEditable('organizers') && addOrganizerType === 'COMMITTEE'">
          <dirt-committee-single-select
            [wide]="true"
            (committeeSelected)="onOrganizerAdded('COMMITTEE', $event)"
          ></dirt-committee-single-select>
        </div>
        <div *ngIf="isFieldEditable('organizers') && addOrganizerType === 'NO_ORGANIZER' && !hasNoOrganizer()">
          <button (click)="onOrganizerAdded('NO_ORGANIZER', {})" [disabled]="hasRealOrganizer()">
            Event has no organizer
          </button>
        </div>
      </div>
    </div>

    <hr [hidden]="model.parent" />

    <!-- Series -->
    <div class="form-group row" *ngIf="!model.parent">
      <label class="col-form-label col-sm-4 col-md-2">Series</label>
      <div class="col-sm-8 col-md-10">
        <div class="input-group mb-2 mb-sm-0" *ngIf="isFieldEditable('series')">
          <div class="input-group-prepend">
            <div class="input-group-text">
              <i class="fa fa-search" aria-hidden="true" [hidden]="isLoadingSeries"></i>
              <i class="fa fa-refresh fa-spin" [hidden]="!isLoadingSeries"></i>
            </div>
          </div>
          <input
            type="text"
            class="form-control ng-trim-ignore"
            name="series"
            placeholder="Search series..."
            [ngbTypeahead]="searchSeries"
            [inputFormatter]="formatSeriesInput"
            [resultFormatter]="formatSeriesOutput"
            (selectItem)="onSeriesAdd($event)"
            *diMarker="{ name: 'series', entity: model.parent ? 'session' : 'event', area: 'ID' }"
          />
        </div>

        <div class="di-series-list">
          <span class="badge badge-info" [title]="series.name" *ngFor="let series of model.series">
            <a [routerLink]="['/event-series/detail', series.id]" target="_blank">
              <i class="fa fa-external-link"></i>
            </a>
            {{ series.name }}
            <span
              class="btn-close"
              aria-hidden="true"
              (click)="onSeriesRemove(series)"
              *diAcl="'event.update.prop.series'"
              >&times;
            </span>
          </span>
          <span style="color: red" *ngIf="!model.series?.length">(none yet)</span>
        </div>
      </div>
    </div>

    <hr [hidden]="model.parent" />

    <!-- Locality -->
    <div class="form-group row" *ngIf="!model.parent">
      <label class="col-form-label col-sm-4 col-md-2" for="reach">Locality</label>
      <div class="col-sm-8 col-md-10">
        <select
          id="reach"
          class="form-control custom-select"
          name="reach"
          [(ngModel)]="model.reach"
          [disabled]="!isFieldEditable('reach')"
          *diMarker="{ name: 'locality', entity: model.parent ? 'session' : 'event', area: 'ID' }"
          required
        >
          <option *ngFor="let reach of reaches" [value]="reach | uppercase">
            {{ reach }}
          </option>
        </select>
      </div>
    </div>

    <!-- Web Source -->
    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2" for="webSource">Web Source</label>
      <div class="col-sm-8 col-md-10">
        <dirt-link
          [(url)]="model.webSource"
          id="webSource"
          name="webSource"
          [form]="frmEvent"
          [nonCompliantDomainType]="nonCompliantDomainType"
          [disabled]="!isFieldEditable('webSource')"
          [required]="true"
          *diMarker="{
            name: 'webSource',
            entity: model.parent ? 'session' : 'event',
            area: model.parent ? 'BD' : 'ID'
          }"
        >
          <small [hidden]="!model.parent || model.webSource" style="position: absolute; right: 0; bottom: -20px">
            <a (click)="onUseParentWebSource()" class="" style="cursor: pointer">Use parent Web Source</a>
          </small>
          <button
            type="button"
            class="btn btn-secondary"
            (click)="model.additionalWebSources.push('')"
            *ngIf="!model.additionalWebSources.length && isFieldEditable('webSource')"
          >
            <i class="fa fa-plus-circle"></i>
          </button>
        </dirt-link>

        <ng-template [diAcl]="'event.create.unable'" [diAclAnd]="!model.id && !model.parent">
          <small class="form-text text-muted">
            If no web source is specified, the Event's status is set to 'No Info'.
          </small>
        </ng-template>
      </div>
    </div>
    <div
      class="form-group row"
      *ngFor="let affLink of model.additionalWebSources; let idx = index; trackBy: trackByIndex"
    >
      <label class="col-form-label col-sm-4 col-md-2">Websource</label>
      <div class="col-sm-8 col-md-10">
        <dirt-link
          [(url)]="model.additionalWebSources[idx]"
          name="additionalWebSources-{{ idx }}"
          [form]="ngForm"
          [disabled]="!isFieldEditable('webSource')"
          [nonCompliantDomainType]="nonCompliantDomainType"
        >
          <button
            type="button"
            class="btn btn-secondary"
            (click)="model.additionalWebSources.push('')"
            *ngIf="idx === model.additionalWebSources.length - 1 && isFieldEditable('webSource')"
          >
            <i class="fa fa-plus-circle"></i>
          </button>

          <button
            type="button"
            class="btn btn-secondary"
            (click)="model.additionalWebSources.splice(idx, 1)"
            *ngIf="isFieldEditable('webSource')"
          >
            <i class="fa fa-minus-circle"></i>
          </button>
        </dirt-link>
      </div>
    </div>

    <!-- has agenda -->
    <div class="form-group row" *ngIf="!model.parent">
      <label class="col-form-label col-sm-4 col-md-2">Has Agenda</label>
      <div class="col-sm-8 col-md-10 pt-1">
        <div class="form-check form-check-inline">
          <input
            class="form-check-input"
            type="radio"
            id="hasAgendaTrue"
            name="hasAgenda"
            [value]="true"
            [(ngModel)]="model.hasAgenda"
            [disabled]="!isFieldEditable('hasAgenda')"
            [required]="isFieldEditable('hasAgenda')"
          />
          <label class="form-check-label" for="hasAgendaTrue">Yes</label>
        </div>
        <div class="form-check form-check-inline">
          <input
            class="form-check-input"
            type="radio"
            id="hasAgendaFalse"
            name="hasAgenda"
            [value]="false"
            [(ngModel)]="model.hasAgenda"
            [disabled]="!isFieldEditable('hasAgenda')"
            [required]="isFieldEditable('hasAgenda')"
          />
          <label class="form-check-label" for="hasAgendaFalse">No</label>
        </div>
      </div>
    </div>

    <hr [hidden]="model.parent" />

    <!-- Virtual Event -->
    <div class="form-group row" *ngIf="!model.parent">
      <label class="col-form-label col-sm-4 col-md-2" for="isVirtual">Virtual Event</label>
      <div class="col-sm-4 col-md-2">
        <select
          id="isVirtual"
          class="form-control custom-select"
          name="isVirtual"
          [ngModel]="model.isVirtual"
          (ngModelChange)="model.isVirtual = 'true' === $event"
          [disabled]="!isFieldEditable('isVirtual')"
          required
        >
          <option value="true">Yes</option>
          <option value="false">No</option>
        </select>
      </div>
    </div>

    <!-- Logo URL -->
    <div class="form-group row" *ngIf="!model.parent">
      <label class="col-form-label col-sm-4 col-md-2" for="logo">Logo URL</label>

      <div class="col-sm-8 col-md-10">
        <span *diMarker="{ name: 'logo', entity: model.parent ? 'session' : 'event', area: 'ALL' }">
          <input
            type="url"
            id="logo"
            class="form-control"
            name="logo"
            placeholder="e.g: https://veeva.com"
            [(ngModel)]="model.logo"
            [readonly]="!isFieldEditable('logo')"
            (change)="handleLogoPreviewURLChange(model.logo)"
            *diInputErrorMsg="{
              text: 'Provided URL does not resolve to a valid image or none of image sizes exceeds 200px',
              show: !isLogoPreviewLoading && hasLogoPreviewError
            }"
          />
        </span>
      </div>
    </div>

    <!-- Original Logo Preview -->
    <div class="form-group row" *ngIf="!model.parent">
      <label class="col-form-label col-sm-4 col-md-2">Original Logo Preview</label>

      <div class="col-sm-8 col-md-10">
        <div
          class="event-logo-preview di-thumbnail-container"
          [ngClass]="{
            'load-error': model.logo && !isLogoPreviewLoading && hasLogoPreviewError
          }"
        >
          <img
            #logoPreview
            class="img-thumbnail"
            [ngClass]="{ 'is-loading': isLogoPreviewLoading }"
            src="assets/blank-profile.png"
            (loadstart)="isLogoPreviewLoading = true"
            (load)="isLogoPreviewLoading = false"
            (error)="onLogoPreviewLoadFailure()"
          />

          <dirt-spinner [show]="isLogoPreviewLoading"></dirt-spinner>
        </div>
      </div>
    </div>

    <!-- Address -->
    <div class="row" *ngIf="!model.parent">
      <label class="col-form-label col-sm-4 col-md-2">Address</label>
      <div class="col-sm-8 col-md-10">
        <span *diMarker="{ name: 'address', entity: model.parent ? 'session' : 'event', area: 'ID' }">
          <dirt-address
            [address]="model.address"
            [stateSelector]="false"
            [disabled]="!isFieldEditable('address')"
            [internalSearchConfig]="internalSearchConfig"
            [mandatoryCity]="!!model.isVirtual === false && isFieldEditable('address')"
            [mandatoryCountry]="!!model.isVirtual === false && isFieldEditable('address')"
            [countrySource]="'event-association-country'"
            *ngIf="!model.parent"
          >
          </dirt-address>
        </span>
      </div>
    </div>

    <!-- Phone -->
    <dirt-input-phone
      [model]="model.phone"
      [form]="ngForm"
      [disabled]="!isFieldEditable('phone')"
      *ngIf="!model.parent"
    ></dirt-input-phone>

    <!-- Email -->
    <div class="form-group row" *ngIf="!model.parent">
      <label class="col-form-label col-sm-4 col-md-2" for="email">Email</label>
      <div class="col-sm-8 col-md-10">
        <div class="input-group">
          <input
            type="email"
            class="form-control"
            id="email"
            name="email"
            [ngModel]="model.email | decapitalize"
            (ngModelChange)="model.email = $event"
            [disabled]="!isFieldEditable('email')"
            validateEmail
          />
        </div>
      </div>
    </div>

    <hr [hidden]="model.parent" />

    <!-- Start Date -->
    <div class="form-group row" *ngIf="!model.parent">
      <label class="col-form-label col-sm-4 col-md-2">Start Date</label>
      <div class="col-sm-8 col-md-10">
        <div
          class="input-group"
          *diMarker="{ name: 'startDate', entity: model.parent ? 'session' : 'event', area: 'ID' }"
        >
          <input
            class="form-control"
            name="startDate"
            readonly
            [(ngModel)]="model.startDate"
            ngbDatepicker
            #dp="ngbDatepicker"
            [required]="!model.parent"
          />

          <div class="input-group-append">
            <button
              type="button"
              class="btn btn-secondary"
              (click)="dp.toggle()"
              [hidden]="!isFieldEditable('startDate')"
            >
              <i class="fa fa-calendar" aria-hidden="true"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- End Date -->
    <div class="form-group row" *ngIf="!model.parent">
      <label class="col-form-label col-sm-4 col-md-2">End Date</label>
      <div class="col-sm-8 col-md-10">
        <div
          class="input-group"
          *diMarker="{ name: 'endDate', entity: model.parent ? 'session' : 'event', area: 'ID' }"
        >
          <input
            class="form-control"
            name="endDate"
            readonly
            [(ngModel)]="model.endDate"
            ngbDatepicker
            #dpEnd="ngbDatepicker"
            [required]="!model.parent && !model.id"
          />

          <div class="input-group-append">
            <button
              type="button"
              class="btn btn-secondary"
              (click)="dpEnd.toggle()"
              [hidden]="!isFieldEditable('endDate')"
            >
              <i class="fa fa-calendar" aria-hidden="true"></i>
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Sponsor -->
    <div class="form-group row" *ngIf="model.parent">
      <label class="col-form-label col-sm-4 col-md-2">Sponsor</label>
      <div class="col-sm-8 col-md-10">
        <dirt-multiselect-dropdown
          name="sponsors"
          [options]="sponsorsFiltered"
          [settings]="sponsorsSettings"
          [(ngModel)]="model.sponsors"
          (lazyLoad)="filterSponsors($event)"
          *diMarker="{ name: 'sponsor', entity: model.parent ? 'session' : '', area: 'BD' }"
        >
        </dirt-multiselect-dropdown>
      </div>
    </div>

    <!-- Session Date -->
    <div class="form-group row" *ngIf="model.parent">
      <label class="col-form-label col-sm-4 col-md-2">Date of Session</label>
      <div class="col-sm-8 col-md-10">
        <dirt-date-input
          name="sessionDate"
          [(value)]="model.sessionDate"
          [form]="ngForm"
          *diMarker="{ name: 'sessionDate', entity: 'session', area: 'BD' }"
        >
        </dirt-date-input>
      </div>
    </div>

    <!-- Session Time -->
    <div class="form-group row" *ngIf="false && model.parent">
      <label class="col-form-label col-sm-4 col-md-2">Time of Session</label>
      <div class="col-sm-5">
        <dirt-time-input
          name="sessionTime"
          [(value)]="model.sessionTime"
          [form]="ngForm"
          *diMarker="{ name: 'sessionTime', entity: 'session', area: 'BD' }"
        >
        </dirt-time-input>
      </div>
    </div>

    <!-- CME Credit status -->
    <div class="form-group row" *ngIf="!model.parent">
      <label class="col-form-label col-sm-4 col-md-2" for="cmeCreditStatus">Offers CME option</label>
      <div class="col-sm-8 col-md-10">
        <select
          id="cmeCreditStatus"
          class="form-control custom-select"
          name="cmeCreditStatus"
          [(ngModel)]="obtainCMECredits().status"
          [disabled]="!isFieldEditable('cmeCreditStatus')"
          [required]="isFieldEditable('cmeCreditStatus') && 'EVENT_CURATION' === currentJobType"
          *diMarker="{ name: 'cmeCreditStatus', entity: 'event', area: 'BD' }"
        >
          <option value="NO">No</option>
          <option value="YES_NOT_RELEASED">Yes - number of CME credits not yet released</option>
          <option value="YES_RELEASED">Yes - number of CME credits available</option>
        </select>
      </div>
    </div>

    <!-- Number of CME credits -->
    <div class="form-group row" *ngIf="!model.parent">
      <label class="col-form-label col-sm-4 col-md-2" for="numberCMECredits">Number of CME Credits</label>
      <div class="col-sm-8 col-md-10">
        <input
          type="number"
          id="numberCMECredits"
          class="form-control"
          name="numberCMECredits"
          [(ngModel)]="obtainCMECredits().numberCMECredits"
          (ngModelChange)="onNumberOfCMECreditsChange($event)"
          [disabled]="!isFieldEditable('numberCMECredits') || 'YES_RELEASED' !== obtainCMECredits().status"
          [required]="isFieldEditable('numberCMECredits') && 'YES_RELEASED' === obtainCMECredits().status"
          *diMarker="{ name: 'numberCMECredits', entity: 'event', area: 'BD' }"
        />
      </div>
    </div>

    <!-- Hashtag -->
    <div class="form-group row" *ngIf="!model.parent">
      <label class="col-form-label col-sm-4 col-md-2">Hashtag</label>
      <div class="col-md-4">
        <span *diMarker="{ name: 'hashtags', entity: model.parent ? 'session' : 'event', area: 'BD' }">
          <input
            type="twitter"
            id="hashtag"
            class="form-control"
            style="width: 200px; display: inline; margin-right: 10px"
            name="hashtag"
            [(ngModel)]="hashtag"
            [disabled]="!isFieldEditable('hashtag')"
            twitterHashtagValidator
          />

          <button
            type="submit"
            [disabled]="!isFieldEditable('hashtag')"
            class="btn btn-primary"
            style="vertical-align: top"
            (click)="AddHashTag()"
          >
            <span>Add</span>
          </button>
        </span>
      </div>
    </div>

    <div class="form-group row di-series-list" *ngIf="!model.parent">
      <div class="col-form-label col-sm-4 col-md-2"></div>
      <div class="col-md-6">
        <span class="badge badge-info" *ngFor="let hash of model.hashtags; let i = index" value="hash">
          {{ hash }}
          <span class="btn-close" aria-hidden="true" *ngIf="isFieldEditable('hashtag')" (click)="RemoveHashTag(i)"
            >&times;
          </span>
        </span>
      </div>
    </div>

    <div class="form-group row" *ngIf="!model.parent">
      <label class="col-form-label col-sm-4 col-md-2" for="ownerProduct">Owner product</label>

      <div class="col-sm-8 col-md-10 input-group">
        <select
          id="ownerProduct"
          class="form-control custom-select"
          name="ownerProduct"
          [(ngModel)]="model.ownerProduct"
          [required]="!model.id"
          [disabled]="model.id && !isFieldEditable('ownerProduct')"
        >
          <option *ngFor="let product of products" [value]="product.value">
            {{ product.value }}
          </option>
        </select>
      </div>
    </div>
  </div>
</form>
