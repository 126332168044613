<span>
  <label *ngIf="allowOutsideRoot"
    ><input type="checkbox" [checked]="outsideRoot" (input)="changeOutsideRoot()" /> Search outside this HCO<br
  /></label>
  <input
    #srch
    type="search"
    class="di-search form-control ng-trim-ignore ngb-highlight"
    [ngClass]="{ loading: isSearching }"
    [ngbTypeahead]="searchAutoComplete"
    [focusFirst]="false"
    [resultTemplate]="searchResultTemplate"
    (selectItem)="onSelectItem($event)"
    (keyup.escape)="organizationChange.emit(null)"
    [(ngModel)]="searchTerm"
    [placeholder]="
      onlyRoot
        ? 'Search root HCOs by name or ID'
        : outsideRoot
        ? 'Globally Search HCOs by name or ID'
        : 'Search HCOs within Health System by name or ID'
    "
    [disabled]="disabled"
  />
</span>

<!-- SearchAutoComplete Result Template-->
<ng-template #searchResultTemplate let-r="result" let-t="term">
  <div class="di-sac-name ngb-highlight">
    <span class="ngb-highlight">{{ r.fullName || r.name }}</span>
  </div>
</ng-template>
