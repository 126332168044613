<div class="modal-header">
  <h5 class="modal-title">Organization maintenance request</h5>
</div>
<div class="modal-body">
  <form #jobForm="ngForm" novalidate>
    <!-- Customer Request -->
    <div class="form-group">
      <label class="col-form-label">For customer request</label>
      <div>
        <ui-switch
          size="small"
          name="customerRequest"
          [(ngModel)]="newJobDesc.customerRequest"
          [disabled]="!!isCustomerRequest"
        ></ui-switch>
      </div>
    </div>

    <!-- Comment -->
    <div class="form-group">
      <label class="col-form-label" for="comment">Comment <small>like 'change originalName to ...'</small></label>

      <textarea
        type="text"
        id="comment"
        name="comment"
        class="form-control"
        [(ngModel)]="newJobDesc.comment"
        [required]="!canUpdateAffiliation && 'AFFILIATION_MERGE' !== newJobDesc.type"
      ></textarea>
    </div>

    <!-- Link -->
    <div class="form-group">
      <label class="col-form-label" for="link">Link</label>
      <dirt-link [(url)]="newJobDesc.link" name="link" [form]="jobForm" [required]="!canUpdateAffiliation"> </dirt-link>
    </div>
  </form>
</div>

<div class="modal-footer">
  <button
    type="button"
    class="btn btn-secondary"
    [disabled]="isSubmitting"
    (click)="onCancelClick(); $event.stopPropagation()"
  >
    <span>Cancel</span>
  </button>

  <button
    type="submit"
    class="btn btn-primary"
    [ngClass]="{ 'btn-loading': isSubmitting }"
    [disabled]="!jobForm.valid || isSubmitting"
    (click)="onSubmitClick(); $event.stopPropagation()"
  >
    <span>Send</span>
  </button>
</div>
