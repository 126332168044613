import { AfterViewInit, Component, Input, OnChanges, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm, NgModel } from '@angular/forms';

import { Value } from '../../services/value/value';
import { ValueAPI } from '../../services/value/value-api.service';
import { ValueType } from '../../enum/value-type.enum';

@Component({
  selector: 'dirt-input-phone',
  templateUrl: 'input-phone.component.html',
  styleUrls: ['./input-phone.component.scss'],
})
export class InputPhoneComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @Input()
  model: { countryCallingCode: string; number: string; extension?: string };

  @Input()
  name?: string;

  @Input()
  disabled?: boolean;

  @Input()
  required?: boolean;

  @Input()
  allowedCountries?: string[] = [];

  @Input()
  form: NgForm;

  @Input()
  extensionInput?: NgModel;

  @ViewChild('extensionInputRef', { static: false })
  private extensionInputRef: NgModel;

  @ViewChild('callingCodeInput', { static: false })
  private callingCodeInput: NgModel;

  @ViewChild('numberInput', { static: false })
  private numberInput: NgModel;

  hasPhoneNumberError: boolean;
  hasPhoneNumberExtensionError: boolean;

  hasCallingCodeError: boolean;

  countryCallingCodes: Value[] = [];

  private phoneNumberValidationRegex = /^\d{0,12}$/;
  private phoneNumberExtensionValidationRegex = /^\d{0,10}$/;

  private viewInitialized = false;

  constructor(private readonly svcValue: ValueAPI) {}

  ngOnChanges(): void {
    this.required = this.disabled ? false : this.required; // prevent field from being used for validation if disabled
    if (this.viewInitialized) {
      this.onPhoneNumberChange(); // validate number
    }
  }

  ngOnInit(): void {
    this.svcValue.find(ValueType.CountryCallingCodes, Number.MAX_SAFE_INTEGER).subscribe((countryCallingCodes) => {
      this.countryCallingCodes = (countryCallingCodes || []).sort((a, b) => a.title.localeCompare(b.title));
    });
  }

  /**
   * Register control in parent form so that the input validity is checked when
   * checking form validity.
   */
  ngAfterViewInit(): void {
    this.form.addControl(this.callingCodeInput);
    this.form.addControl(this.numberInput);

    this.viewInitialized = true;

    // ensures that no errors are thrown if the property is undefined.
    // If `this.extensionInput` is defined, it will be used as the reference
    // to the `NgModel` directive, otherwise `this.extensionInputRef` will be used.
    this.extensionInput = this.extensionInput || this.extensionInputRef;

    setTimeout(() => {
      this.onPhoneNumberChange(); // validate number
    });
  }

  /**
   * Form could outlive control so unregister control on destroy.
   */
  ngOnDestroy(): void {
    this.viewInitialized = false;

    this.form.removeControl(this.callingCodeInput);
    this.form.removeControl(this.numberInput);
  }

  onPhoneNumberChange(): void {
    this.hasPhoneNumberError = !this.phoneNumberValidationRegex.test(this.model.number || '');

    if (this.hasPhoneNumberError) {
      this.numberInput.control.setErrors({ invalid: true });
    } else {
      this.numberInput.control.setErrors(null);

      this.onCountryCallingCodeChange(); // validate country calling code field
    }
  }

  onPhoneExtensionChange(): void {
    this.hasPhoneNumberExtensionError = !this.phoneNumberExtensionValidationRegex.test(this.model.extension || '');

    if (this.hasPhoneNumberExtensionError) {
      this.extensionInput.control.setErrors({ invalid: true });
      return;
    }

    this.extensionInput.control.setErrors(null);
  }

  onCountryCallingCodeChange(): void {
    if (!!this.model.number?.trim() && !this.model.countryCallingCode) {
      // value should be set when we have a number
      this.callingCodeInput.control.setErrors({ required: true });
    } else {
      this.callingCodeInput.control.setErrors(null);
    }
    this.validateCallingCode();
  }

  validateCallingCode(): void {
    if (!this.allowedCountries.length || !this.model.countryCallingCode) {
      return;
    }
    const code = (this.model.countryCallingCode.split(':') || [])[0];
    this.hasCallingCodeError = !this.allowedCountries.includes(code);

    if (this.hasCallingCodeError) {
      this.callingCodeInput.control.setErrors({ invalid: true });
    } else {
      this.callingCodeInput.control.setErrors(null);
    }
  }
}
