import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';
import { ContributionModule } from '../contribution/contribution.module';
import { ContributorModule } from '../contributor/contributor.module';
import { CommentModule } from '../comment/comment.module';
import { EventComponent } from './event.component';
import { EventListComponent } from './list/list.component';
import { EventDetailComponent } from './detail/detail.component';
import { EventCreateComponent } from './create/create.component';
import { EventFormComponent } from './shared/form/form.component';
import { EventFilterComponent } from './shared/filter/filter.component';
import { BulkUpdateModule } from '../common/bulk-update/bulk-update.module';
import { eventRouting } from './event.routes';
import { EventAPI } from './shared/api.service';
import { BulkListModule } from '../common/bulk-list/bulk-list.module';
import { BulkModule } from '../common/bulk/bulk.module';
import { EventNextHashtagComponent } from './next-hashtag/next-hashtag.component';
import { OcrHelperModule } from '../ocr-helper/ocr-helper.module';
import { AffiliationModule } from '../affiliation/affiliation.module';
import { AssociationModule } from '../association/association.module';
import { AccountModule } from '../account/account.module';
import { CommitteeModule } from '../committee/committee.module';
import { JobsModule } from '../jobs/jobs.module';
import { EventAdvancedSearchComponent } from './shared/components/advanced-search/advanced-search';
import { OrganizationModule } from '../organizations/organization.module';

@NgModule({
  imports: [
    SharedModule,
    ContributionModule,
    ContributorModule,
    CommentModule,
    eventRouting,
    BulkUpdateModule,
    BulkListModule,
    BulkModule,
    OcrHelperModule,
    JobsModule,
    AffiliationModule,
    AssociationModule,
    AccountModule,
    CommitteeModule,
    OrganizationModule,
  ],
  declarations: [
    EventComponent,
    EventListComponent,
    EventDetailComponent,
    EventCreateComponent,
    EventFormComponent,
    EventFilterComponent,
    EventNextHashtagComponent,
    EventAdvancedSearchComponent,
  ],
  exports: [EventNextHashtagComponent],
  providers: [EventAPI],
})
export class EventModule {}
