import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationComponent, ConfirmationComponentConfiguration } from './confirmation.component';
import { firstValueFrom } from 'rxjs';

@Injectable()
export class ConfirmationService {
  constructor(private svcModal: NgbModal) {}

  open(configuration: ConfirmationComponentConfiguration): Promise<boolean | null> {
    const ref = this.svcModal.open(ConfirmationComponent);
    ref.componentInstance.configuration = configuration;

    return Promise.race([
      // If the modal is dismissed for any reason, return null
      firstValueFrom<null>(ref.dismissed).then(() => null),

      // Otherwise return the users choice.
      firstValueFrom<boolean>(ref.closed),
    ]);
  }
}
