<section class="dirt-section-detail" style="padding: 2rem; width: 100vw; height: 100vh; overflow-y: auto">
  <div class="site-search">
    <input
      type="search"
      class="di-search form-control ng-trim-ignore"
      placeholder="Search"
      [formControl]="searchCtrl"
    />
  </div>

  <br />

  <ng-container *ngIf="displayedSiteGroups.length > 0; else empty">
    <div class="site-container" *ngFor="let group of displayedSiteGroups">
      <p class="font-weight-bold">
        {{ group.name }}
      </p>

      <ul class="site-container__site" *ngFor="let site of group.sites">
        <li>
          <p>
            <span style="font-weight: bold">Institution Department:</span>
            <a href="javascript:void" (click)="onOpenAffiliation(site._id)">{{ site.displayName }}</a
            ><br />
            <span style="font-weight: bold">Mapped HCO:</span>
            <a href="javascript:void" (click)="onOpenOrganization(site.organizationId)" *ngIf="site.organizationId">{{
              site.organizationName
            }}</a
            ><br />
            <strong>[{{ site.type }}]</strong>

            &nbsp;

            <i
              class="fa fa-copy"
              style="cursor: pointer"
              (click)="onCopyID(site._id)"
              ngbTooltip="Copied"
              [openDelay]="1000"
              [closeDelay]="2000"
            >
            </i>
          </p>

          <ng-container *ngIf="site.investigators?.length > 0">
            <ul>
              <li *ngFor="let investigator of site.investigators">
                <a href="javascript:void" style="color: darkorange" (click)="onOpenKol(investigator.kolId)">{{
                  investigator.srcName || investigator.kolId
                }}</a
                >: {{ investigator.displayRoles }}
              </li>
            </ul>
          </ng-container>
        </li>
      </ul>
    </div>
  </ng-container>

  <!-- Empty Message -->
  <ng-template #empty>
    <p class="dirt-no-results">No Result</p>
  </ng-template>
</section>
