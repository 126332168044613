import { AfterViewInit, Component, Input, OnDestroy, ViewChild } from '@angular/core';
import { distinctUntilChanged, Observable, Subject, take, takeUntil, tap } from 'rxjs';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgForm } from '@angular/forms';

import { AddressEntity } from '../address-entity';
import { Organization } from '../organization';
import { OrganizationAPI, OrganizationSearchResultDTO, OrgDuplicate } from '../api.service';
import { Value } from '../../../shared/services/value/value';
import { ValueAPI } from '../../../shared/services/value/value-api.service';
import { ValueType } from '../../../shared/enum/value-type.enum';
import { AddressEntityCreateModalService } from '../../../address-entity/create-modal/create-modal.service';

@Component({
  selector: 'dirt-organization-create-request-modal',
  templateUrl: 'organization-create-request-modal.component.html',
})
export class OrganizationCreateRequestModalComponent implements AfterViewInit, OnDestroy {
  @Input()
  isCustomerRequest = false;

  /**
   * Sometime we already know the owner product and can skip asking the curator to provide the information.
   */
  @Input()
  defaultOwnerProduct?: string;

  organization: Pick<
    Organization,
    'name' | 'websource' | 'parents' | 'addresses' | 'requestOwnerProduct' | 'usageInfo'
  > = new Organization();

  products$: Observable<Value[]>;

  isSubmitting = false;
  isValid = false;

  /**
   * When exact-duplicate, we're 100% sure that we're attempting to create a duplicate and this should be rejected.
   * For fuzzy-duplicates, it's up to the curator to determine if the organization is a duplicate.
   */
  state?: 'exact-duplicate' | 'fuzzy-duplicate';
  duplicates?: OrgDuplicate[];

  addressDetails?: AddressEntity;
  selectedParent?: OrganizationSearchResultDTO;

  @ViewChild(NgForm)
  private form: NgForm;

  private destroy$ = new Subject<boolean>();

  private wndw: Window = window;

  constructor(
    private activeModal: NgbActiveModal,
    private readonly svcOrganization: OrganizationAPI,
    private readonly svcValue: ValueAPI,
    private readonly svcAddressEntityCreateModal: AddressEntityCreateModalService
  ) {
    this.organization.usageInfo = {};
    this.organization.parents = [];

    this.products$ = this.svcValue.find(ValueType.Product, Number.MAX_SAFE_INTEGER, 0, '+order');
  }

  ngAfterViewInit(): void {
    // Reset duplicates when the form content is changed
    this.form.valueChanges.pipe(takeUntil(this.destroy$), distinctUntilChanged()).subscribe(() => {
      this.resetDuplicates();
    });

    if (this.isCustomerRequest) {
      this.organization.usageInfo.customerRequest = true;
    }

    if (this.defaultOwnerProduct) {
      this.organization.requestOwnerProduct = this.defaultOwnerProduct;
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(false);
    this.destroy$.complete();
  }

  onSubmit(force = false): void {
    if (this.isSubmitting || this.state === 'exact-duplicate') {
      return;
    }

    // In case something is present in the field, we'll have [null]
    this.organization.parents = this.organization.parents.filter((p) => !!p);

    this.isSubmitting = true;
    this.svcOrganization
      .create(this.organization as Organization, force)
      .pipe(tap(() => (this.isSubmitting = false)))
      .subscribe({
        next: (result) => {
          if (result.type === 'success') {
            return this.activeModal.close({
              organization: result.res,
              affiliation: result.affiliation,
            });
          }
          this.state = result.type;
          this.duplicates = result.duplicates;
          this.isSubmitting = false;
        },
        error: () => {
          this.isSubmitting = false;
        },
      });
  }

  onCancel(): void {
    this.activeModal.dismiss();
  }

  onParentSelected(parent: OrganizationSearchResultDTO | null): void {
    if (!parent) {
      this.selectedParent = null;
      this.organization.parents = [];
      return;
    }

    this.selectedParent = parent;
    this.organization.parents[0] = parent as any;
    this.resetDuplicates();
  }

  onAddressSelected(address: AddressEntity): void {
    this.addressDetails = address;
    this.organization.addresses = [{ addressId: address._id }];
    this.resetDuplicates();
  }

  onRemoveAddress(): void {
    if (!this.wndw.confirm('Remove address ?')) {
      return;
    }
    this.addressDetails = null;
    this.organization.addresses = [];
    this.resetDuplicates();
  }

  async onOpenCreateAddressModal() {
    const enteredAddress = await this.svcAddressEntityCreateModal.open();
    this.svcOrganization
      .createAddress(enteredAddress)
      .pipe(take(1))
      .subscribe((res) => {
        this.onAddressSelected(res.res);
      });
  }

  /**
   * We require no address when:
   *  - the requested organization has a parent, since it can then inherit from that parent
   *  - the requested organization has no parent but an address set itself
   */
  isAddressRequired(): boolean {
    if (this.organization.parents?.length > 0 && this.organization.parents[0] && this.selectedParent.address) {
      return false;
    }
    if (this.addressDetails) {
      return false;
    }
    return true;
  }

  private resetDuplicates(): void {
    this.state = null;
    this.duplicates = null;
  }
}
