export enum ValueType {
  Project = 'project',
  PersonProject = 'person-project',
  Area = 'area',
  TherapeuticArea = 'therapeutic-area',
  Category = 'category',
  Country = 'country',
  Specialty = 'specialty',
  OriginalSpeciality = 'original-specialty',
  Segment = 'segment',
  NonCompliantDomains = 'non-compliant-domain',
  NonCompliantEventsAssociationsDomains = 'non-compliant-events-associations-domain',
  NonCompliantGuidelinesDomains = 'non-compliant-guidelines-publications-domain',
  NonCompliantAccountsDomains = 'non-compliant-accounts-domain',
  NonCompliantDomainsActivities = 'non-compliant-activities-domain',
  Degree = 'degree',
  AffiliationPosition = 'affiliation-position',
  AffiliationOriginalPosition = 'affiliation-original-position',
  Sponsor = 'sponsor',
  FieldErrorType = 'field-error-type',
  CountryState = 'country-state',
  PersonProfession = 'person-profession',
  PersonSource = 'person-source',
  PersonState = 'person-state',
  PersonOptoutState = 'person-optout-state',
  PersonSuffix = 'person-suffix',
  AffiliationType = 'affiliation-type',
  AssociationType = 'association-type',
  EventType = 'event-type',
  PersonTherapeuticArea = 'person-therapeutic-area',
  ClientConfig = 'client-config',
  SentimentTag = 'sentiment-tag',
  Product = 'product',
  TrialEndpoint = 'trial-endpoint',
  TrialEndpointMeasure = 'trial-endpoint-measure',
  TrialTimeframeUnit = 'trial-timeframe-unit',
  TrialCritEntity = 'trial-crit-entity',
  TrialRefUnit = 'trial-ref-unit',
  TrialRefEvent = 'trial-ref-event',
  TrialRole = 'trial-role',
  TrialArmType = 'trial-arm-type',
  TrialInterventionType = 'trial-intervention-type',
  TrialSiteAlmostReason = 'trial-site-almost-reason',
  TrialSiteUnableReason = 'trial-site-unable-reason',
  TrialSiteDirectReason = 'trial-site-direct-reason',
  TrialSiteInsufficientInfoReason = 'trial-site-insufficient-information-reason',
  TrialSiteNotInScopeReason = 'trial-site-not-in-scope-reason',
  Currencies = 'currencies',
  CountryCallingCodes = 'country-calling-code',
  PersonPhoneType = 'person-phone-type',
  PersonJobType = 'person-job-type',
  EventAssociationCountry = 'event-association-country',
  AccountTypes = 'account-type',
  AccountSubTypes = 'account-sub-type',
  AccountEconomicStatus = 'account-economic-status',
  AccountOwnershipStatus = 'account-ownership-status',
  AccountHealthPlanNetworkTypes = 'account-health-plan-network-type',
  AccountHeatherPayerTypes = 'account-health-payer-type',
  Language = 'language',
  EUCountries = 'eu-countries',
  CommitteeTherapeuticArea = 'committee-therapeutic-area',
  CommitteePersonPosition = 'committee-person-position',
  CommitteeTopic = 'committee-topic',
  DocumentCategory = 'document-category',
  DocumentType = 'document-type',
  DocumentFormat = 'document-format',
  InScopeCountry = 'in-scope-country',
  RegionalRestrict = 'regional-restrict',
  StudyTrack = 'study-track',
  AccountTeachingStatus = 'account-teaching-status',
  OrganizationType = 'organization-type',
  OrganizationJobType = 'organization-job-type',
  ClinicalTrialSponsorType = 'ct-sponsor-type',
  CommitteeFormat = 'committee-format',
  CommitteePersonProbability = 'committee-person-probability',
  CommitteeConnectionProbability = 'committee-connection-probability',
  CommitteeType = 'committee-type',
  CommitteeStatus = 'committee-status',
  EventJobType = 'event-job-type',
  DocumentPersonPosition = 'document-person-position',
  AccountPersonJobTitle = 'account-person-job-title',
}
