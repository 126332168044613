import { NgModule } from '@angular/core';
import { NgSelectModule } from '@ng-select/ng-select';

import { SharedModule } from '../shared/shared.module';
import { CommentModule } from '../comment/comment.module';

import { AffiliationModule } from '../affiliation/affiliation.module';
import { ProfileModule } from '../profile/profile.module';
import { StickyFilterModule } from '../common/sticky-filter/sticky-filter.module';

import { PersonComponent } from './person.component';
import { PersonListComponent } from './list/list.component';
import { PersonDetailComponent } from './detail/detail.component';
import { PersonNextComponent } from './next/next.component';
import { PersonCreateComponent } from './create/create.component';
import { PersonFormComponent } from './shared/form/form.component';
import { PersonFilterComponent } from './shared/filter/filter.component';
import { PersonMergeComponent } from './shared/modal/merge/merge.component';
import { PersonDuplicateTakeoverComponent } from './shared/modal/duplicate/duplicate.component';

import { personRouting } from './person.routes';
import { PersonAPI } from './shared/api.service';
import { PersonMergeService } from './shared/modal/merge/merge.service';
import { PersonDuplicateTakeoverService } from './shared/modal/duplicate/duplicate.service';
import { BulkListModule } from '../common/bulk-list/bulk-list.module';
import { QcNextComponent } from './qc-next/qc-next.component';
import { BulkUpdateModule } from '../common/bulk-update/bulk-update.module';
import { PersonIdNextComponent } from './id-next/id-next.component';
import { IdQcNextComponent } from './id-qc-next/id-qc-next.component';
import { BulkModule } from '../common/bulk/bulk.module';
import { BdCopyComponent } from './bd-copy/bd-copy.component';
import { JobsModule } from '../jobs/jobs.module';
import { PersonSingleSelectComponent } from './shared/components/person-single-select/person-single-select.component';
import { PersonWithAffiliationModalComponent } from './shared/modal/person-with-affiliation/person-with-affiliation.component';
import { PersonWithAffiliationModalService } from './shared/modal/person-with-affiliation/person-with-affiliation.service';
import { PersonAdvancedSearchComponent } from './shared/components/advanced-search/advanced-search';
import { CommitteeModule } from '../committee/committee.module';
import { TimeTrackModule } from '../common/timetrack/time-track.module';
import { OrganizationModule } from '../organizations/organization.module';
import { UnmappedPersonAffiliationsModalService } from './shared/modal/unmapped-person-affiliations/unmapped-person-affiliations.service';

@NgModule({
  imports: [
    SharedModule,
    CommentModule,
    AffiliationModule,
    ProfileModule,
    StickyFilterModule,
    BulkListModule,
    BulkUpdateModule,
    personRouting,
    BulkModule,
    JobsModule,
    NgSelectModule,
    CommitteeModule,
    TimeTrackModule,
    OrganizationModule,
  ],
  declarations: [
    PersonComponent,
    PersonListComponent,
    PersonDetailComponent,
    PersonNextComponent,
    PersonIdNextComponent,
    PersonCreateComponent,
    PersonFormComponent,
    PersonFilterComponent,
    PersonMergeComponent,
    PersonDuplicateTakeoverComponent,
    QcNextComponent,
    IdQcNextComponent,
    BdCopyComponent,
    PersonSingleSelectComponent,
    PersonWithAffiliationModalComponent,
    PersonAdvancedSearchComponent,
  ],
  exports: [
    PersonNextComponent,
    PersonIdNextComponent,
    QcNextComponent,
    IdQcNextComponent,
    PersonSingleSelectComponent,
  ],
  providers: [
    PersonAPI,
    PersonMergeService,
    PersonWithAffiliationModalService,
    PersonDuplicateTakeoverService,
    UnmappedPersonAffiliationsModalService,
  ],
})
export class PersonModule {}
