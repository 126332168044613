import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, switchMap, take, tap } from 'rxjs/operators';

import { AffiliationAPI } from '../../api.service';
import { Affiliation } from '../../affiliation';

/**
 * This component only exists to facilitate searching affiliations directly.
 * A common use is to allow mapping organizations to affiliations.
 *
 * @deprecated
 * @TODO - Any updates made to this component should be mirrored in the updated
 *   non-temporary version if applicable. Once affiliations have been
 *   completely removed from the system, this component will serve no purpose.
 */
@Component({
  selector: 'dirt-affiliation-single-select-temp',
  templateUrl: 'affiliation-single-select-temp.html',
  styleUrls: ['affiliation-single-select-temp.scss'],
})
export class AffiliationSingleSelectTempComponent {
  @Input()
  affiliation: Affiliation | string;

  @Output() // (can [(affiliation)]
  affiliationSelected: EventEmitter<Affiliation> = new EventEmitter();

  @Input()
  wide: boolean = false;

  @Input()
  inline = false;

  @Input()
  curatedOnly: boolean = false;

  @Input()
  excludeUnpolished: boolean = true;

  @Input()
  augmentOrganization: boolean = false;

  @Input()
  topOnly = false;

  @Input()
  disabled = false;

  @Input()
  required = false;

  @Input()
  creationRequestHandler?: (affiliation?: Affiliation) => Observable<Affiliation>;

  isSearching = false;

  searchAffiliations = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      tap(() => (this.isSearching = true)),
      switchMap((term) => {
        if (!term) {
          return of([]);
        }

        return this.svcAffiliation
          .search(term as any, 100, this.topOnly, this.curatedOnly, this.excludeUnpolished, this.augmentOrganization)
          .pipe(
            take(1),
            catchError(() => {
              return of([]);
            })
          );
      }),
      tap(() => (this.isSearching = false))
    );

  constructor(private readonly svcAffiliation: AffiliationAPI) {}

  onChangeAffiliation(affiliation) {
    this.affiliationSelected.emit(affiliation);

    if (!this.inline) {
      setTimeout(() => (this.affiliation = null)); // (next apply)
    } else {
      this.affiliation = affiliation;
    }
  }

  onChangeAffiliationInline(value: Affiliation | string): void {
    if (!value || !value?.toString()?.trim()) {
      this.affiliationSelected.emit(null); // value cleared
    }
  }

  onRequestAffiliation(affiliation: Affiliation): void {
    if (!this.creationRequestHandler) {
      return;
    }

    this.creationRequestHandler(affiliation)
      .pipe(take(1))
      .subscribe((affiliation) => {
        this.onChangeAffiliation(affiliation);
      });
  }

  formatTitle(affiliation: Affiliation): string {
    return [affiliation.name, affiliation.department].filter((a) => !!a).join(' - ');
  }
}
