import type { Organization } from '../../organizations/shared/organization';
import type { Affiliation } from '../../affiliation/shared/affiliation';
import type { Association } from '../../association/shared/association';
import type { Account } from '../../account/shared/account';
import type { Committee } from '../../committee/shared/committee';

export enum EventOrganizerType {
  AFFILIATION = 'AFFILIATION',
  ORGANIZATION = 'ORGANIZATION',
  ASSOCIATION = 'ASSOCIATION',
  ACCOUNT = 'ACCOUNT',
  COMMITTEE = 'COMMITTEE',
  NO_ORGANIZER = 'NO_ORGANIZER',
}

export class Event {
  public id: string;
  public address: any = {};
  public category: number;
  public createdAt: string;
  public name: string;
  public nameTranslated: boolean;
  public originalName: string;
  public parent: any;
  public priority: number;
  public published: boolean;
  public reach: string;
  public readyForDelivery: boolean;
  public reviewedAt: Date;
  public draftReviewedAt: Date;
  public type: string;
  public requiresCompilation: boolean;
  public series: { id: string; name: string }[] = [];
  public sponsors: string[] = [];
  public startDate: any;
  public endDate: any;
  public updatedAt: string;
  public verified: boolean;
  public verificationSkipped?: boolean;
  public verifiedBy?: string;
  public verificationSkippedBy?: string;
  public claimverificationSkippedAt?: Date;
  public webSource: string;
  public additionalWebSources?: string[];
  public hasAgenda?: boolean;
  public projectNames: string[];
  public hashtags: string[];
  qc: any;
  public _meta: {
    assignee?: string;
    lastAssignee?: string;
    maintenance?: boolean;
    status: string;
    claimedUntil?: Date;
    previousStatus?: string;
    workflow?: string;
    jobId?: string;
  };
  public _version: number;
  public reviewed?: boolean;
  public isVirtual?: boolean;
  public lastHashtagCheck?: {
    at: Date;
    by: string;
  };
  public organizers?: EventOrganizer[];
  public originalLanguage?: string;
  public sessionDate?: Date;
  public sessionTime?: {
    hour?: number;
    minute?: number;
  } = {};
  ownerProduct?: string; // only parents

  logo?: string;

  constructor() {}
}

type EventOrganizerBase = {
  type: EventOrganizerType;
  suggestOnly?: boolean;
};

type EventOrganizerAffiliation = EventOrganizerBase & {
  type: EventOrganizerType.AFFILIATION;
  affiliationId: string;
  affiliation?: Partial<Affiliation>;
};

type EventOrganizerOrganization = EventOrganizerBase & {
  // TODO: Revert to type ORGANIZATION once affiliation compatability is removed.
  type: EventOrganizerType.AFFILIATION;
  affiliationId: string;
  organizationId: string;
  organization?: Partial<Organization>;
};

type EventOrganizerAssociation = EventOrganizerBase & {
  type: EventOrganizerType.ASSOCIATION;
  associationId: string;
  association?: Partial<Association>;
};

type EventOrganizerAccount = EventOrganizerBase & {
  type: EventOrganizerType.ACCOUNT;
  accountId: string;
  account?: Partial<Account>;
};

type EventOrganizerCommittee = EventOrganizerBase & {
  type: EventOrganizerType.COMMITTEE;
  committeeId: string;
  committee?: Partial<Committee>;
};

type EventOrganizerNone = EventOrganizerBase & {
  type: EventOrganizerType.NO_ORGANIZER;
};

export type EventOrganizer =
  | EventOrganizerAffiliation
  | EventOrganizerOrganization
  | EventOrganizerAssociation
  | EventOrganizerAccount
  | EventOrganizerCommittee
  | EventOrganizerNone;

export const REQUIRES_COMPILATION_TYPES = ['CONGRESS', 'WORKSHOP'];

export type CME_STATUS = 'NO' | 'YES_NOT_RELEASED' | 'YES_RELEASED';
