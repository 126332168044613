import { catchError, debounceTime, distinctUntilChanged, Observable, of, switchMap, take, tap } from 'rxjs';
import { Component, EventEmitter, Input, Output } from '@angular/core';

import { Organization } from '../organization';
import { OrganizationAPI } from '../api.service';
import { OrganizationSelectModalService } from '../select-modal/select-modal.service';

@Component({
  selector: 'dirt-organization-single-select',
  templateUrl: 'organization-single-select.component.html',
  styleUrls: ['./organization-single-select.component.scss'],
})
export class OrganizationSingleSelectComponent {
  @Input()
  organization?: Organization;

  @Output()
  organizationSelected = new EventEmitter<Organization>();

  /** If true, will include transformed organizations in search results */
  @Input()
  includeTransformed = false;

  /** If true, will include non DONE organizations in search results */
  @Input()
  includeUnpolished = false;

  @Input()
  disabled = false;

  @Input()
  required = false;

  @Input()
  clearAfterSelect = false;

  @Input()
  creationRequestHandler?: (organization?: Organization) => Observable<Organization>;

  isSearching = false;

  searchOrganizations = (text$: Observable<string>) => {
    return text$.pipe(
      // this is a hack, so clicking the "x" button in the input field, which sets the input to an empty string, causes the listener to be notified
      // of the input field being reset.
      tap((v) => {
        if (v === '') {
          this.onChangeOrganizationInline(null);
        }
      }),
      debounceTime(500),
      distinctUntilChanged(),
      tap(() => (this.isSearching = true)),
      switchMap((term) => {
        if (!term) {
          return of([]);
        }

        return this.svcOrganization.search(term, 100, this.includeTransformed, this.includeUnpolished).pipe(
          take(1),
          catchError(() => {
            return of([]);
          })
        );
      }),
      tap(() => (this.isSearching = false))
    );
  };

  constructor(
    private readonly svcOrganization: OrganizationAPI,
    private readonly svcOrganizationTreeModal: OrganizationSelectModalService
  ) {}

  onChangeOrganization(organization: Organization): void {
    this.organizationSelected.emit(organization);

    if (this.clearAfterSelect) {
      setTimeout(() => (this.organization = null)); // (next apply)
    } else {
      this.organization = organization;
    }
  }

  onChangeOrganizationInline(value: Organization | string): void {
    if (!value || !value?.toString()?.trim()) {
      this.organizationSelected.emit(null); // value cleared
    }
  }

  onRequestOrganization(organization: Organization): void {
    if (!this.creationRequestHandler) {
      return;
    }

    this.creationRequestHandler(organization)
      .pipe(take(1))
      .subscribe((organization) => {
        this.onChangeOrganization(organization);
      });
  }

  async onOpenTree(event: MouseEvent = null, organizationId: string): Promise<void> {
    if (event) {
      event.stopPropagation();
    }

    try {
      const res = await this.svcOrganizationTreeModal.open(organizationId);

      this.onChangeOrganization(res.organization);
    } catch (error) {
      // The modal was closed
      return;
    }
  }

  formatTitle(organization: Organization): string {
    return organization.fullName;
  }
}
