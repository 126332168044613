import { Component, Input, OnInit, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { TitleCasePipe } from '@angular/common';
import { NgForm } from '@angular/forms';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

import { User } from '../user';
import { Roles } from '../../../shared/acl/roles';
import { Auth } from '../../../shared/services/auth/auth.service';
import { UserGroupAPI } from '../../../user-group/shared/api.service';
import { UserGroup } from '../../../user-group/shared/user-group';
import { ValueType } from '../../../shared/enum/value-type.enum';
import { ValueAPI } from '../../../shared/services/value/value-api.service';
import { environment } from '../../../../environments/environment';
import { IMultiSelectSettings, IMultiSelectOption } from '../../../shared/components/multiselect-dropdown/types';
import { ProductLabelPipe } from '../../../shared/pipes';

@Component({
  selector: 'dirt-user-form',
  templateUrl: 'form.component.html',
  styleUrls: ['form.component.scss'],
  exportAs: 'frmUser',
  providers: [TitleCasePipe],
})
export class UserFormComponent implements OnInit, OnChanges {
  @Input('user')
  model: User = new User();

  @ViewChild(NgForm, { static: true })
  ngForm: NgForm;

  userGroups: UserGroup[] = [];

  roles: IMultiSelectOption[] = [];
  rolesSettings: IMultiSelectSettings = {
    buttonClasses: 'btn btn-sm btn-secondary',
    enableSearch: true,
    dynamicTitleMaxItems: 3,
  };

  projects = []; // person, profile
  personProjects = []; // association, events
  spokenLanguages: { id: string | number; name: string }[] = [];
  spokenLanguagesSettings: IMultiSelectSettings = {
    buttonClasses: 'btn btn-sm btn-secondary',
    enableSearch: true,
    dynamicTitleMaxItems: 5,
  };

  profileCategories = [
    {
      id: 'CUBE_US',
      name: 'Oncology US',
      disabled: false,
    },
    {
      id: 'LFTA',
      name: this.productLabelPipe.transform('LFTA'),
      disabled: false,
    },
    {
      id: 'LFKA',
      name: this.productLabelPipe.transform('LFKA'),
      disabled: false,
    },
    {
      id: 'OTHER',
      name: 'Other',
      disabled: false,
    },
  ];
  workflowQcPools = [
    {
      id: 'apacPool',
      name: 'APAC I&II&V',
    },
    {
      id: 'row',
      name: 'ROW',
    },
  ];

  profileCategoriesSettings: IMultiSelectSettings = {
    buttonClasses: 'btn btn-sm btn-secondary',
    enableSearch: true,
    dynamicTitleMaxItems: 3,
  };
  projectsSettings: IMultiSelectSettings = {
    // event, assoc, etc.
    buttonClasses: 'btn btn-sm btn-secondary',
    enableSearch: true,
    dynamicTitleMaxItems: 3,
  };

  searchRoleConfig = {
    personIdentifier: [Roles.PersonCreator],
    personCompilerIdent: [Roles.PersonCompilerIdent],
    personCompiler: [Roles.PersonCompiler],
    profileCompiler: [Roles.ProfileCompiler],
    eventCompiler: [Roles.EventCompiler],
    associationCompiler: [Roles.AssociationCompiler],
    eventCreator: [Roles.EventCreator],
    associationCreator: [Roles.AssociationCreator],
    guidelineCreator: [Roles.GuidelineCreator],
  };

  personCompilers: any = [];
  personIdentifiers: any = [];
  associationCompilers: any = [];
  eventCreators: any = [];
  associationCreators: any = [];
  guidelineCreators: any = [];

  constructor(
    private readonly svcAuth: Auth,
    private readonly svcUserGroup: UserGroupAPI,
    private readonly titleCasePipe: TitleCasePipe,
    private svcValue: ValueAPI,
    private productLabelPipe: ProductLabelPipe
  ) {}

  ngOnInit() {
    this.getUserGroups();
    this.normalizeModel();
    this.normalizeRoles();
    this.loadProjects();
    this.loadPersonProjects();
    this.loadLanguages();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['model']) {
      this.normalizeValidTillDate();
    }
  }

  getUserGroups() {
    this.svcUserGroup.find(Number.MAX_SAFE_INTEGER, '+name').subscribe((resp) => (this.userGroups = resp));
  }

  isValid(): boolean {
    return this.ngForm.form.valid;
  }

  /**
   * Detects whether the form is in create or edit mode based on id existence
   * @returns {boolean}
   */
  isNewRecord(): boolean {
    return !this.model.user_id;
  }

  getValue(): User {
    if (this.model.app_metadata.additionalInfo && this.model.app_metadata.additionalInfo.underperfValidTill) {
      this.model.app_metadata.additionalInfo.underperfValidTill = this.ngbStructToDate(
        this.model.app_metadata.additionalInfo.underperfValidTill
      );
    }
    return Object.assign({}, this.model); // TODO: no clone, return domain object (for all such forms); use ngOnChanges+change handlers for helper variables
  }

  hasRoleOf(role: string): boolean {
    const regex = new RegExp(`^${role}.*`);
    return (this.model.app_metadata.roles || []).some((r) => regex.test(r));
  }

  doesNeedRegion(): boolean {
    const rolesNotNeedingRegion = [
      Roles.PersonIDReviewer,
      Roles.AssociationIDReviewer,
      Roles.AssociationReviewer,
      Roles.EventIDReviewer,
      Roles.EventReviewer,
      Roles.EventHashtagCompiler,
      Roles.ViewLfka /*not by itself, person-x might need*/,
      Roles.ViewAutomate /*not by itself, person-x might need*/,
      Roles.JobCreator,
      Roles.ReadyForJobs /* not by itself */,
      ...Object.values(Roles).filter((r) => r.startsWith('CT_')),
    ];
    const roleDoesNeedRegion = (role) =>
      !rolesNotNeedingRegion.includes(role) &&
      !role.startsWith('VIDEO_') &&
      (!role.startsWith('GUIDELINE_') || role === Roles.GuidelineCompiler) &&
      !role.startsWith('SENTIMENT_') &&
      !role.startsWith('IMAGE_') &&
      !role.startsWith('CT_') &&
      !role.startsWith('MED_INS_');
    return (this.model.app_metadata.roles || []).filter(roleDoesNeedRegion).length > 0;
  }

  /** Only EventCompiler & AssociationCompiler need languages as of now */
  showSpokenLanguages(): boolean {
    const rolesSupportingLanguages: Roles[] = [Roles.EventCompiler, Roles.AssociationCompiler];
    return (this.model.app_metadata.roles || []).filter((r: Roles) => rolesSupportingLanguages.includes(r)).length > 0;
  }

  onRolesChange(roles: Roles[]): void {
    if (!roles.includes(Roles.ProfileCompiler)) {
      delete this.model.app_metadata.profileProjectNames;
      delete this.model.app_metadata.profileCategories;
    }
    if (!roles.includes(Roles.PersonCompiler)) {
      delete this.model.app_metadata.projectNames;
    }
    if (!roles.includes(Roles.EventCompiler)) {
      delete this.model.app_metadata.additionalInfo.eventProjects;
    }
    if (!roles.includes(Roles.AssociationCompiler)) {
      delete this.model.app_metadata.additionalInfo.associationProjects;
    }
    if (!roles.includes(Roles.GuidelineCompiler)) {
      delete this.model.app_metadata.additionalInfo.guidelineProjects;
    }
    if (!roles.includes(Roles.PersonReviewer)) {
      delete this.model.app_metadata.personCompilers;
    }
    if (!roles.includes(Roles.PersonIDReviewer)) {
      delete this.model.app_metadata.additionalInfo.personIdentifiers;
    }
    if (!roles.includes(Roles.EventModerator)) {
      delete this.model.app_metadata.eventCompilers;
    }
    if (!roles.includes(Roles.AssociationModerator)) {
      delete this.model.app_metadata.associationCompilers;
    }
    if (!roles.includes(Roles.EventIDReviewer)) {
      delete this.model.app_metadata.additionalInfo.eventCreators;
    }
    if (!roles.includes(Roles.AssociationIDReviewer)) {
      delete this.model.app_metadata.additionalInfo.associationCreators;
    }
    if (!roles.includes(Roles.GuidelineIDReviewer)) {
      delete this.model.app_metadata.additionalInfo.guidelineCreators;
    }
    if (!roles.includes(Roles.AssociationCreator)) {
      delete this.model.app_metadata.additionalInfo.utcMaintainer;
      delete this.model.app_metadata.additionalInfo.draftMaintainer;
    }
    if (!this.doesNeedRegion()) {
      delete this.model.app_metadata.groups;
    }
  }

  onUnderperformerChange(underperformer: boolean): void {
    if (underperformer && this.model.app_metadata.additionalInfo) {
      const validTill = new Date();
      validTill.setDate(validTill.getDate() + 14);
      this.model.app_metadata.additionalInfo.underperfValidTill = this.dateToNgbStruct(validTill);
    }
  }

  private normalizeModel(): void {
    if (!this.model.app_metadata) {
      this.model.app_metadata = { roles: [], additionalInfo: {} };
    }

    if (!this.model.user_metadata) {
      this.model.user_metadata = { firstName: '', lastName: '' };
    }

    // existing users
    if (!this.model.app_metadata.additionalInfo) {
      this.model.app_metadata = {
        ...this.model.app_metadata,
        additionalInfo: {},
      };
    }
  }

  private normalizeRoles(): void {
    this.svcAuth.getProfile().subscribe((userProfile) => {
      const { roles } = userProfile.app_metadata;
      const hasSameModule = (item: string) => roles.some((ur) => item.split('_')[0] === ur.split('_')[0]);

      const specialRoles = [
        Roles.ViewAutomate,
        Roles.ViewLfka,
        Roles.ReadyForJobs,
        Roles.EUCompliant,
        Roles.IsMy,
        Roles.IsMedTech,
      ];
      if ((environment as any).trainingEnabled) {
        specialRoles.push(Roles.Trainer);
      }

      Object.keys(Roles).forEach((k) => {
        // In case the user is not admin (i.e manager), only show relevant users
        if (
          roles.includes(Roles.Admin) ||
          (hasSameModule(Roles[k]) && Roles[k] !== Roles.DomainAdmin) /* Only for DG */ ||
          specialRoles.includes(Roles[k])
        ) {
          this.roles.push({
            id: Roles[k],
            name: this.titleCasePipe
              .transform(Roles[k].replace(/_/g, ' '))
              .replace('So', 'SO')
              .replace('Lfka', 'LFKA')
              .replace('Compiler Ident', 'Compiler-Identifier')
              .replace(/^Is My$/, 'Is Malaysia restrict'),
          });
        }
      });
    });
  }

  loadProjects() {
    this.svcValue
      .find(ValueType.Project, Number.MAX_SAFE_INTEGER, 0, '+title')
      .toPromise()
      .then((data) => {
        this.projects = data.map((o) => ({
          id: o.code,
          name: o.title,
          disabled: false,
        }));
      });
  }
  loadPersonProjects() {
    this.svcValue
      .find(ValueType.PersonProject, Number.MAX_SAFE_INTEGER, 0, '+title')
      .toPromise()
      .then((data) => {
        this.personProjects = data.map((o) => ({
          id: o.code,
          name: o.title,
          disabled: false,
        }));
      });
  }
  loadLanguages() {
    this.svcValue.find(ValueType.Language, Number.MAX_SAFE_INTEGER, 0, '+title').subscribe((data) => {
      this.spokenLanguages = data.map((v) => ({
        id: v.code,
        name: v.title,
      }));
    });
  }

  onSelectedUsersChange(field: string, auth0Users: any[]) {
    if (
      ![
        'personIdentifiers',
        'personCompilers',
        'profileCompilers',
        'associationCompilers',
        'eventCompilers',
        'eventCreators',
        'associationCreators',
        'guidelineCreators',
      ].includes(field)
    ) {
      return;
    }
    if (['personIdentifiers', 'eventCreators', 'associationCreators', 'guidelineCreators'].includes(field)) {
      if (!this.model.app_metadata.additionalInfo) {
        this.model.app_metadata.additionalInfo = {} as any;
      }
      this.model.app_metadata.additionalInfo[field] = auth0Users.map((item) => item.user_id);
    } else {
      this.model.app_metadata[field] = auth0Users.map((item) => item.user_id);
    }
  }

  filterPersonIdentifiers(user: User) {
    return !user.app_metadata.additionalInfo?.personIDQCReviewer;
  }

  filterPersonCompilers(user: User) {
    return !user.app_metadata.personQCReviewer;
  }

  filterAssociationCompilers(user: User) {
    return !user.app_metadata.assoicationQCReviewer;
  }

  filterEventCreators(user: User) {
    return !user.app_metadata.additionalInfo?.eventIdReviewer;
  }

  filterAssociationCreators(user: User) {
    return !user.app_metadata.additionalInfo?.associationIdReviewer;
  }

  filterGuidelineCreators(user: User) {
    return !user.app_metadata.additionalInfo?.guidelineIdReviewer;
  }

  filterEventCompilers(user: User) {
    return !user.app_metadata.eventQCReviewer;
  }

  formatPersonProjects(projects) {
    return (projects || '')
      .map((_p) => (this.personProjects.filter((_pp) => _pp.id === _p)[0] || {}).name || _p)
      .join(', ');
  }

  checkCubeUs(): boolean {
    const usGroup: UserGroup = this.userGroups.filter((u) => u.name === 'US')[0];
    if (
      ((this.model.app_metadata || {}).profileCategories || []).indexOf('CUBE_US') >= 0 &&
      ((this.model.app_metadata || {}).groups || []).indexOf(usGroup.id) < 0
    ) {
      alert('Need to include Region "US" when choosing category "Oncology US"');
      return false;
    }
    return true;
  }

  doesNeedUnderperformer() {
    const rolesNeedingUnderPerformer = [
      'PERSON_COMPILER',
      'PROFILE_COMPILER',
      'ASSOCIATION_COMPILER',
      'EVENT_COMPILER',
      'GUIDELINE_COMPILER',
      'CT_COMPILER',
      'CT_TIMING_COMPILER',
      'CT_PEOPLE_COMPILER',
      'CT_SITE_COMPILER',
      'MED_INS_COMPILER',
    ];
    return !!rolesNeedingUnderPerformer.find((role) => this.hasRoleOf(role));
  }

  private dateToNgbStruct(date: Date): NgbDateStruct {
    if (!date) {
      return;
    }
    const formattedDate = new Date(date);
    return {
      year: formattedDate.getFullYear(),
      month: formattedDate.getMonth() + 1,
      day: formattedDate.getDate(),
    };
  }

  private ngbStructToDate(date: NgbDateStruct): string {
    if (!date) {
      return;
    }

    const dt = Date.UTC(date.year, date.month - 1, date.day);
    return new Date(dt).toISOString();
  }

  private normalizeValidTillDate() {
    if (
      this.model.app_metadata &&
      this.model.app_metadata.additionalInfo &&
      this.model.app_metadata.additionalInfo.underperfValidTill
    ) {
      this.model.app_metadata.additionalInfo.underperfValidTill = this.dateToNgbStruct(
        this.model.app_metadata.additionalInfo.underperfValidTill
      );
    }
  }

  addPrefRow() {
    if (!this.model.app_metadata.additionalInfo) {
      this.model.app_metadata.additionalInfo = {} as any;
    }
    if (!this.model.app_metadata.additionalInfo.regionPreferences) {
      this.model.app_metadata.additionalInfo.regionPreferences = [] as any;
    }
    this.model.app_metadata.additionalInfo.regionPreferences.push({ groups: [] });
  }
  removePref(idx) {
    this.model.app_metadata.additionalInfo.regionPreferences.splice(idx, 1);
  }
}
