import { NgModule, Type } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxPaginationModule } from 'ngx-pagination';
import { UiSwitchModule } from 'ngx-ui-switch';
import { PortalModule } from '@angular/cdk/portal';

import { AclModule } from '../acl/acl.module';
import { DirtDirectivesModule } from '../directives';
import { DirtPipesModule } from '../pipes';
import { DirtValidatorsModule } from '../validators';

import { AuditLogComponent } from './audit-log/audit-log.component';
import { AdminHeaderComponent } from './header/header.component';
import { UsernameComponent } from './username/username.component';
import { LogoutComponent } from './logout/logout.component';
import { YearpickerComponent } from './yearpicker/yearpicker.component';
import { StatusComponent } from './status/status.component';
import { AddressComponent } from './address/address.component';
import { AvatarComponent } from './avatar/avatar.component';

export { AuditLogComponent } from './audit-log/audit-log.component';
export { AdminHeaderComponent } from './header/header.component';
export { UsernameComponent } from './username/username.component';
export { LogoutComponent } from './logout/logout.component';
export { YearpickerComponent } from './yearpicker/yearpicker.component';
export { StatusComponent } from './status/status.component';
export { AddressComponent } from './address/address.component';
export { AvatarComponent } from './avatar/avatar.component';

import { CountDownTimerComponent } from './count-down-timer/count-down-timer.component';
import { MaintenanceModeComponent } from './maintenance-mode/maintenance-mode.component';
import { VerifiedIconComponent } from './verified-icon/verified-icon.component';
import { FiltersBarComponent } from './filters-bar/filters-bar.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { ProjectNameComponent } from './project-name/project-name.component';
import { InputErrorMsgModule } from '../input-error-msg/input-error-msg.module';
import { MultiUserSelectComponent } from './multi-user-select/multi-user-select.component';
import { AffiliationFormComponent } from './affiliation-form/form.component';
import { AffiliationSuggestionListComponent } from './affiliation-suggestion-list/affiliation-suggestion-list.component';
import { PersonAffiliationModalComponent } from './affiliation-modal/modal.component';
import { PersonAffiliationsComponent } from './affiliations/affiliations.component';
import { PersonAffiliationsLfkaComponent } from './affiliations-lfka/affiliations-lfka.component';
import { EntityMarkerContainerComponent } from './marker/container.component';
import { TrainingMarkerComponent } from './training-marker/training-marker.component';
import { SaveTrainingReportComponent } from './save-training-report/save-training-report.component';
import { PaginationComponent } from './pagination/pagination.component';
import { FiltersComponent } from './filters/filters.component';
import { InlineEditComponent } from './inline-edit/inline-edit.component';
import { DateRangeComponent } from './date-range/date-range.component';
import { DirtNgxDropdownMultiselectComponent } from './multiselect-dropdown/multiselect-dropdown.component';
import { DirtMultiSelectSearchFilter } from './multiselect-dropdown/search-filter.pipe';
import { DirtAutofocusDirective } from './multiselect-dropdown/autofocus.directive';
import { DirtOffClickDirective } from './multiselect-dropdown/off-click.directive';
import { ImageFormComponent } from './image-form/image-form.component';
import { PersonFormInlineComponent } from './person-form-inline/person-form-inline.component';
import { VideoLinkedPeopleComponent } from './video-linked-people/video-linked-people.component';
import { ValueSelectTypeahead } from './value-select-typeahead/value-select-typeahead';
import { TrimValueAccessorDirectiveModule } from '../vendor/ng-trim-value-accessor/module';
import { ButtonDropdownComponent } from './button-dropdown/button-dropdown.component';
import { LinkComponent } from './link/link.component';
import { InputPhoneComponent } from './input-phone/input-phone.component';
import { SameValueValidator } from './image-form/same-value.validator';
import { JobHistoryComponent } from './job-history/job-history.component';
import { InputDateComponent } from './input-date/input-date.component';
import { ErrorModalComponent } from './error-modal/error-modal.component';
import { PersonAffiliationsEducationComponent } from './affiliations-education/affiliations-education.component';
import { InputTimeComponent } from './input-time/input-time.component';
import { SideBarComponent } from './side-bar/side-bar.component';
import { SingleUserSelectComponent } from './single-user-select/single-user-select.component';
import { PersonAffiliationsClinicalComponent } from './affiliations-clinical/affiliations-clinical.component';
import { OrganizationSingleSelectComponent } from './single-select/organization-single-select.component';
import { OrganizationSingleSelectTempComponent } from './single-select-temp/organization-single-select-temp.component';

export const DIRT_COMPONENTS: Type<any>[] = [
  AuditLogComponent,
  AdminHeaderComponent,
  UsernameComponent,
  LogoutComponent,
  YearpickerComponent,
  StatusComponent,
  AddressComponent,
  AvatarComponent,
  CountDownTimerComponent,
  MaintenanceModeComponent,
  VerifiedIconComponent,
  FiltersBarComponent,
  SpinnerComponent,
  ProjectNameComponent,
  TrainingMarkerComponent,
  MultiUserSelectComponent,
  SingleUserSelectComponent,
  AffiliationFormComponent,
  AffiliationSuggestionListComponent,
  PersonAffiliationModalComponent,
  PersonAffiliationsComponent,
  EntityMarkerContainerComponent,
  SaveTrainingReportComponent,
  PersonAffiliationsLfkaComponent,
  PaginationComponent,
  FiltersComponent,
  InlineEditComponent,
  DateRangeComponent,
  DirtNgxDropdownMultiselectComponent,
  DirtMultiSelectSearchFilter,
  DirtAutofocusDirective,
  DirtOffClickDirective,
  ImageFormComponent,
  PersonFormInlineComponent,
  VideoLinkedPeopleComponent,
  ValueSelectTypeahead,
  ButtonDropdownComponent,
  LinkComponent,
  InputPhoneComponent,
  SameValueValidator,
  JobHistoryComponent,
  InputDateComponent,
  ErrorModalComponent,
  PersonAffiliationsEducationComponent,
  PersonAffiliationsClinicalComponent,
  InputTimeComponent,
  SideBarComponent,
  OrganizationSingleSelectComponent,
  OrganizationSingleSelectTempComponent,
];

/**
 * Internal module used for sharing components
 */
@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    NgbModule,
    AclModule,
    FormsModule,
    ReactiveFormsModule,
    TrimValueAccessorDirectiveModule,
    DirtPipesModule,
    DirtValidatorsModule,
    DirtDirectivesModule,
    InputErrorMsgModule,
    NgSelectModule,
    NgxPaginationModule,
    UiSwitchModule,
    PortalModule,
  ],
  declarations: DIRT_COMPONENTS,
  exports: DIRT_COMPONENTS,
})
export class DirtComponentsModule {}
