import { Roles } from './roles';

const DEF = {};

DEF[Roles.Admin] = [
  //'admin.dashboard',
  'affiliation.list',
  'affiliation.detail',
  'affiliation.create',
  'affiliation.update',
  'affiliation.create.prop.name',
  'affiliation.create.prop.department',
  'affiliation.create.prop.type',
  'affiliation.create.prop.originalName',
  'affiliation.create.prop.originalDepartment',
  'affiliation.create.prop.address',
  'affiliation.create.prop.webSource',
  'affiliation.update.prop.name',
  'affiliation.update.prop.department',
  'affiliation.update.prop.type',
  'affiliation.update.prop.originalName',
  'affiliation.update.prop.originalDepartment',
  'affiliation.update.prop.address',
  'affiliation.update.prop.verified',
  'affiliation.update.prop.webSource',
  'affiliation.update.prop.originalLanguage',
  'affiliation.update.prop.meta.status',
  'affiliation.delete',
  'affiliation.audit.list',
  'affiliation.lfka',
  'affiliation.all',
  'affiliation.merge',
  'affiliation.bulkList',
  'affiliation.dashboards',
  'affiliation.job-history.list',
  'affiliation.export',
  'association.list',
  'association.bulkList',
  'association.bulkSearch',
  'association.search',
  'association.create',
  'association.create.prop.*',
  'association.update',
  'association.update.prop.*',
  'association.delete',
  'association.comment.list',
  'association.comment.create',
  'association.audit.list',
  'association.list.bulkUpdate',
  'association.dashboards',
  'clinicalTrialProfile.list',
  'clinicalTrialProfile.update',
  'clinicalTrialProfile.updatePeople',
  'clinicalTrialProfile.updateSites',
  'clinicalTrialProfile.bulkList',
  'clinicalTrialProfile.qc',
  'clinicalTrialProfile.audit.list',
  'clinicalTrialProfile.comment.create',
  'clinicalTrialProfile.comment.list',
  'contribution.list',
  'contribution.create',
  'contribution.update',
  'contribution.delete',
  'contribution.qc',
  'contributor.list',
  'contributor.bulkList',
  'event.list',
  'event.bulkList',
  'event.bulkSearch',
  'event.create',
  'event.create.prop.*',
  'event.update',
  'event.updateAnyway',
  'event.update.prop.*',
  'event.delete',
  'event.qc',
  'event.comment.list',
  'event.comment.create',
  'event.audit.list',
  'event.list.bulkUpdate',
  'event.dashboards',
  'event.job-history.list',
  'eventSeries.list',
  'eventSeries.create',
  'eventSeries.create.prop.*',
  'eventSeries.update',
  'eventSeries.update.prop.*',
  'eventSeries.delete',
  'eventSeries.bulkList',
  'eventSeries.comment.list',
  'eventSeries.comment.create',
  'eventSeries.audit.list',
  'eventSeries.job-history.list',
  'medical-insights-profile.audit.list',
  'medical-insights-profile.bulk-list',
  'medical-insights-profile.detail',
  'medical-insights-profile.info.list',
  'medical-insights-profile.job-history.list',
  'medical-insights-profile.update',
  'medical-insights-profile.update.prop.meta.status',
  'medical-insights-profile.qc',
  'membership.list',
  'membership.create',
  'membership.update',
  'membership.delete',
  'person.list',
  'person.list.bulkUpdate',
  'person.bulkList',
  'person.export',
  'person.create',
  'person.create.prop.*',
  'person.create.prop.social.instagram',
  'person.create.prop.social.tiktok',
  'person.create.mandatory.prop.cvLinks',
  // not enforced 'person.create.mandatory.prop.nameLinks',
  // not enforced 'person.create.mandatory.prop.positionLinks',
  // not enforced 'person.create.mandatory.prop.statusLinks',
  // not enforced 'person.create.mandatory.prop.emailLinks',
  'person.merge',
  'person.update',
  'person.updateAnyway', // allow saving despite remaining form validation issues
  'person.updateOptOut', // allow updating opt-out KOLs
  'person.update.prop.*',
  'person.update.prop.social.instagram',
  'person.update.prop.social.tiktok',
  'person.update.mandatory.prop.cvLinks',
  // not enforced 'person.update.mandatory.prop.nameLinks',
  // not enforced 'person.update.mandatory.prop.positionLinks',
  // not enforced 'person.update.mandatory.prop.statusLinks',
  // not enforced 'person.update.mandatory.prop.emailLinks',
  'person.delete',
  'person.comment.list',
  'person.comment.create',
  'person.tag.list',
  'person.audit.list',
  'person.checkFindings.list',
  'person.profile.summary',
  'person.dashboards',
  'person.request-image-maintenance',
  'person.committee.list',
  'person.job-history.list',
  'person.compliance.delete',
  'profile.list',
  'profile.bulkList',
  'profile.list.bulkUpdate',
  'profile.list.bulkUpdate.videos',
  'profile.update',
  'profile.update.prop.*',
  'profile.comment.list',
  'profile.comment.create',
  'profile.comment.update',
  'profile.comment.delete',
  'profile.audit.list',
  'profile.directLink',
  'profile.videoDirectLink',
  'profile.tags',
  'profile.subsection.list.viewEmpty',
  'profile.qc',
  'profile.ml',
  'profile.origin',
  'profile-clinical-trial.bulkList',
  'profile.dashboards',
  'profile-job.bulkList',
  'profile.export',
  'report.profile',
  'report.baseData',
  'report.ident',
  'report.bdident',
  'report.qc',
  'report.queues',
  'report.queue.profile',
  'report.queue.baseData',
  'report.queue.bdident',
  'report.queue.idbdqc',
  'sentiment.list',
  'sentiment.bulkList',
  'sentiment.submit',
  'sentiment.dashboards',
  'session.list',
  'session.create',
  'session.create.prop.*',
  'session.update',
  'session.update.prop.*',
  'session.delete',
  'session.qc',
  'subassociation.list',
  'subassociation.create',
  'subassociation.create.prop.*',
  'subassociation.delete',
  'subassociation.update',
  'subassociation.update.prop.*',
  'user.list',
  'user.create',
  'user.detail',
  'user.update',
  'user.bulkList',
  'userPool.detail',
  'userPool.list',
  'userPool.create',
  'userPool.update',
  'memo.create',
  'memo.update',
  'profile.videos',
  'profile.videos.delete',
  'memo.delete',
  'memo.bulkList',
  'guideline.list',
  'guideline.bulkList',
  'guideline.create',
  'guideline.create.author',
  'guideline.create.prop.*',
  'guideline.update',
  'guideline.update.author',
  'guideline.update.prop.*',
  'guideline.delete',
  'guideline.delete.author',
  'guideline.comment.list',
  'guideline.comment.create',
  'guideline.audit.list',
  'guideline.list.bulkUpdate',
  'guideline.dashboards',
  'projectTags.list',
  'projectTags.create.personProject',
  'projectTags.create.project',
  'projectTags.edit.personProject',
  'projectTags.edit.project',
  'projectTags.update.technical',
  'projectTags.delete.personProject',
  'projectTags.delete.project',
  'account.list',
  'account.bulkList',
  'account.create',
  'account.create.prop.*',
  'account.update',
  'account.update.prop.*',
  'account.delete',
  'account.reparent',
  'account.audit.list',
  'account.saveAnyway',
  'account.comment.list',
  'account.comment.create',
  'account.profile',
  'account.export',
  'account.job-history.list',
  'division.list',
  'division.create',
  'division.create.prop.*',
  'division.update',
  'division.update.prop.*',
  'division.delete',
  'account.affiliation.list',
  'account.affiliation.update',
  'account.affiliation.create',
  'account.affiliation.delete',
  'account.affiliation.audit.list',
  'account-financial-information.list',
  'account-financial-information.create',
  'account-financial-information.create.prop.*',
  'account-financial-information.update',
  'account-financial-information.update.prop.*',
  'account-financial-information.delete',
  'account-medical-information.list',
  'account-medical-information.create',
  'account-medical-information.create.prop.*',
  'account-medical-information.update',
  'account-medical-information.update.prop.*',
  'account-medical-information.delete',
  'account-medical-information.bulk-list',
  'account.affiliation-suspect.list',
  'account.affiliation-suspect.update',
  'account-payer-information.list',
  'account-payer-information.create',
  'account-payer-information.create.prop.*',
  'account-payer-information.update',
  'account-payer-information.update.prop.*',
  'account-payer-information.delete',
  'account-person.list',
  'account-person.create',
  'account-person.delete',
  'account-person.update',
  'job.create',
  'job.list',
  'job.update',
  'job.export',
  'job.skip',
  'job.submit',
  'domain.list',
  'domain.bulkList',
  'domain.bulkListCompliantCountries',
  'domain.create',
  'domain.create.prop.*',
  'domain.update',
  'domain.saveAnyway',
  'domain.update.prop.*',
  'domain.delete',
  'domain.audit.list',
  'domain.comment.list',
  'domain.comment.create',
  'committee.list',
  'committee.create',
  'committee.create.prop.*',
  'committee.update',
  'committee.update.prop.*',
  'committee.delete',
  'committee.audit.list',
  'committee.bulkList',
  'committee.saveAnyway',
  'committee.export',
  'committee.comment.list',
  'committee.comment.create',
  'committee.person.list',
  'committee.person.create',
  'committee.person.delete',
  'committee.person.update',
  'committee.job-history.list',
  'document.list',
  'document.create',
  'document.create.prop.*',
  'document.update',
  'document.update.prop.*',
  'document.delete',
  'document.audit.list',
  'document.saveAnyway',
  'document.export',
  'document.comment.list',
  'document.comment.create',
  'document.job-history.list',
  'document.bulkList',
  'document.person.list',
  'document.person.create',
  'document.person.delete',
  'document.person.update',
  'organization.bulk-list',
  'organization.list',
  'organization.detail',
  'organization.create',
  'organization.move',
  'organization.merge',
  'organization.split',
  'organization.delete',
  'organization.removeDeletionRequest',
  'organization.update',
  'organization.update.prop.*',
  'organization.request',
  'organization.audit.list',
  'organization.comment.list',
  'organization.comment.create',
  'organization.job-history.list',
  'organization.qc', // (can also remove after testing)
  'organization.affiliation.list',
  'organization.affiliation.update',
  'organization.affiliation.create',
  'sponsor.list',
  'sponsor.create',
  'sponsor.detail',
  'sponsor.update',
  'sponsor.delete',
  'clinicalTrialSponsor.list',
  'clinicalTrialSponsor.update',
  'clinicalTrialSponsor.update.prop.*',
  'clinicalTrialSponsor.audit.list',
  'clinicalTrialSponsor.comment.list',
  'clinicalTrialSponsor.comment.create',
  'affiliation.affiliationsClinical',
];

DEF[Roles.AssociationManager] = [
  //'admin.dashboard',
  'association.list',
  'association.bulkList',
  'association.bulkSearch',
  'association.search',
  'association.create',
  'association.create.prop.*',
  'association.update',
  'association.update.prop.*',
  'association.delete',
  'association.comment.list',
  'association.comment.create',
  'association.audit.list',
  'association.dashboards',
  'subassociation.list',
  'subassociation.create',
  'subassociation.create.prop.*',
  'subassociation.delete',
  'subassociation.update',
  'subassociation.update.prop.*',
  'association.list.bulkUpdate',
  'membership.list',
  'membership.create',
  'membership.update',
  'membership.delete',
  'report.profile',
  'report.baseData',
  'report.ident',
  'report.bdident',
  'user.list',
  'user.create',
  'user.detail',
  'user.update',
  'user.bulkList',
  'userPool.detail',
  'userPool.list',
  'userPool.create',
  'userPool.update',
  'projectTags.list',
  'projectTags.create.project',
  'projectTags.edit.project',
  'projectTags.delete.project',
];

DEF[Roles.AssociationModerator] = [
  //'admin.dashboard',
  'association.list',
  'association.bulkList',
  'association.bulkSearch',
  'association.search',
  'association.create',
  'association.create.prop.*',
  'association.update',
  'association.update.prop.*',
  'association.delete',
  'association.comment.list',
  'association.comment.create',
  'association.audit.list',
  'association.list.bulkUpdate',
  'association.dashboards',
  'subassociation.list',
  'subassociation.create',
  'subassociation.create.prop.*',
  'subassociation.delete',
  'subassociation.update',
  'subassociation.update.prop.*',
  'membership.list',
  'membership.create',
  'membership.update',
  'membership.delete',
  'report.profile',
];

DEF[Roles.AssociationCreator] = [
  'association.list',
  'association.create',
  'association.create.unable',
  'association.create.prop.address',
  'association.create.prop.areas',
  'association.create.prop.category',
  'association.create.prop.memberWebSource',
  'association.create.prop.name',
  'association.create.prop.nameTranslated',
  'association.create.prop.originalName',
  'association.create.prop.reach',
  'association.create.prop.scope',
  'association.create.prop.therapeuticAreas',
  'association.create.prop.type',
  'association.create.prop.webSource',
  'association.create.prop.projectNames',
  'association.create.prop.ownerProduct',
  'association.update',
  'association.update.prop.address',
  'association.update.prop.areas',
  'association.update.prop.category',
  'association.update.prop.memberWebSource',
  'association.update.prop.name',
  'association.update.prop.nameTranslated',
  'association.update.prop.originalName',
  'association.update.prop.reach',
  'association.update.prop.scope',
  'association.update.prop.therapeuticAreas',
  'association.update.prop.type',
  'association.update.prop.webSource',
  'association.update.prop.meta.status',
  'association.update.prop.phone',
  'association.update.prop.phoneLink',
  'association.update.prop.email',
  'association.update.prop.description',
  'association.update.prop.youtubeChannelUrl',
  'association.update.prop.linkedInProfileUrl',
  'association.comment.list',
  'association.comment.create',
  'association.search',
];

DEF[Roles.AssociationCompiler] = [
  'association.next',
  'association.update.submit',
  'association.update.unable',
  'association.update.prop.address',
  'association.update.prop.memberWebSource',
  'association.update.prop.originalName',
  'association.update.prop.webSource',
  'association.comment.list',
  'association.comment.create',
  'association.update.prop.phone',
  'association.update.prop.phoneLink',
  'association.update.prop.email',
  'association.update.prop.description',
  'association.update.prop.youtubeChannelUrl',
  'association.update.prop.linkedInProfileUrl',
  'subassociation.list',
  'subassociation.create',
  'subassociation.create.prop.memberWebSource',
  'subassociation.create.prop.name',
  'subassociation.create.prop.nameTranslated',
  'subassociation.create.prop.originalName',
  'subassociation.create.prop.webSource',
  'subassociation.create.prop.sponsors',
  'subassociation.update',
  'subassociation.update.prop.memberWebSource',
  'subassociation.update.prop.name',
  'subassociation.update.prop.nameTranslated',
  'subassociation.update.prop.originalName',
  'subassociation.update.prop.obsolete',
  'subassociation.update.prop.webSource',
  'subassociation.update.prop.sponsors',
  'subassociation.delete',
  'membership.list',
  'membership.create',
  'membership.update',
  'membership.delete',
];

DEF[Roles.AssociationViewer] = [
  'association.list',
  'association.search',
  'association.audit.list',
  'subassociation.list',
  'membership.list',
];

DEF[Roles.AssociationReviewer] = [
  'association.bulkList',
  'association.search',
  'association.create',
  'association.delete',
  'association.create.prop.*',
  'association.update',
  'association.update.skip',
  'association.update.prop.*',
  'association.comment.list',
  'association.comment.create',
  'association.audit.list',
  'association.list.bulkUpdate',
  'association.qc',
  'subassociation.list',
  'subassociation.create',
  'subassociation.create.prop.*',
  'subassociation.update',
  'subassociation.update.prop.*',
  'subassociation.qc',
  'membership.list',
  'membership.create',
  'membership.update',
  'membership.delete',
  'membership.qc',
  'report.profile',
];

DEF[Roles.AssociationIDReviewer] = [
  'association.bulkList',
  'association.search',
  'association.create',
  'association.create.prop.*',
  'association.update',
  'association.update.prop.*',
  'association.comment.list',
  'association.comment.create',
  'association.audit.list',
  'association.list.bulkUpdate',
  'association.idqc',
  'subassociation.list',
  'subassociation.create',
  'subassociation.create.prop.*',
  'subassociation.update',
  'subassociation.update.prop.*',
  'subassociation.idqc',
  'membership.list',
  'membership.create',
  'membership.update',
  'membership.idqc',
  'report.profile',
];

DEF[Roles.EventManager] = [
  //'admin.dashboard',
  'event.list',
  'event.bulkList',
  'event.bulkSearch',
  'event.create',
  'event.create.prop.*',
  'event.update',
  'event.updateAnyway',
  'event.update.prop.*',
  'event.delete',
  'event.comment.list',
  'event.comment.create',
  'event.audit.list',
  'event.list.bulkUpdate',
  'event.dashboards',
  'event.job-history.list',
  'eventSeries.list',
  'eventSeries.create',
  'eventSeries.create.prop.*',
  'eventSeries.update',
  'eventSeries.update.prop.*',
  'eventSeries.delete',
  'eventSeries.bulkList',
  'eventSeries.comment.list',
  'eventSeries.comment.create',
  'eventSeries.audit.list',
  'eventSeries.job-history.list',
  'session.list',
  'session.create',
  'session.create.prop.*',
  'session.update',
  'session.update.prop.*',
  'session.delete',
  'contribution.list',
  'contribution.create',
  'contribution.update',
  'contribution.delete',
  'contributor.list',
  'contributor.bulkList',
  'organization.request',
  'report.profile',
  'report.baseData',
  'report.ident',
  'report.bdident',
  'user.list',
  'user.create',
  'user.detail',
  'user.update',
  'user.bulkList',
  'userPool.detail',
  'userPool.list',
  'userPool.create',
  'userPool.update',
  'projectTags.list',
  'projectTags.create.project',
  'projectTags.edit.project',
  'projectTags.delete.project',
  'job.list',
  'job.update',
  'job.export',
];

DEF[Roles.EventModerator] = [
  //'admin.dashboard',
  'contribution.list',
  'contribution.create',
  'contribution.update',
  'contribution.delete',
  'contributor.list',
  'contributor.bulkList',
  'event.list',
  'event.bulkList',
  'event.bulkSearch',
  'event.list.bulkUpdate',
  'event.create',
  'event.create.prop.*',
  'event.update',
  'event.updateAnyway',
  'event.update.prop.*',
  'event.delete',
  'event.comment.list',
  'event.comment.create',
  'event.audit.list',
  'event.dashboards',
  'event.job-history.list',
  'eventSeries.list',
  'eventSeries.create',
  'eventSeries.create.prop.*',
  'eventSeries.update',
  'eventSeries.update.prop.*',
  'eventSeries.delete',
  'eventSeries.bulkList',
  'eventSeries.comment.list',
  'eventSeries.comment.create',
  'eventSeries.audit.list',
  'eventSeries.job-history.list',
  'organization.request',
  'report.profile',
  'report.baseData',
  'report.ident',
  'report.bdident',
  'session.list',
  'session.create',
  'session.create.prop.*',
  'session.update',
  'session.update.prop.*',
  'session.delete',
];

DEF[Roles.EventReviewer] = [
  'contribution.list',
  'contribution.create',
  'contribution.update',
  'contribution.qc',
  'contribution.delete',
  'contributor.list',
  'event.create',
  'event.create.prop.*',
  'event.update',
  'event.updateAnyway',
  'event.update.prop.*',
  'event.delete',
  'event.comment.list',
  'event.comment.create',
  'event.audit.list',
  'event.job-history.list',
  'eventSeries.list',
  'eventSeries.create',
  'eventSeries.create.prop.*',
  'eventSeries.update',
  'eventSeries.update.prop.*',
  'eventSeries.comment.list',
  'eventSeries.comment.create',
  'eventSeries.audit.list',
  'eventSeries.job-history.list',
  'event.qc',
  'organization.request',
  'session.list',
  'session.create',
  'session.create.prop.*',
  'session.update',
  'session.update.prop.*',
  'session.qc',
  'session.delete',
];

DEF[Roles.EventIDReviewer] = [
  'contribution.list',
  'contribution.create',
  'contribution.update',
  'contribution.idqc',
  'contributor.list',
  'contributor.bulkList',
  'event.update',
  'event.updateAnyway',
  'event.update.prop.*',
  'event.delete',
  'event.comment.list',
  'event.comment.create',
  'event.audit.list',
  'event.job-history.list',
  'eventSeries.list',
  'eventSeries.create',
  'eventSeries.create.prop.*',
  'eventSeries.update',
  'eventSeries.update.prop.*',
  'eventSeries.comment.list',
  'eventSeries.comment.create',
  'event.idqc',
  'organization.request',
  'session.list',
  'session.create',
  'session.create.prop.*',
  'session.update',
  'session.update.prop.*',
  'session.idqc',
];

DEF[Roles.EventCreator] = [
  'event.list',
  'event.create',
  'event.create.unable',
  'event.create.prop.address',
  'event.create.prop.areas',
  'event.create.prop.category',
  'event.create.prop.name',
  'event.create.prop.nameTranslated',
  'event.create.prop.originalName',
  'event.create.prop.organizers',
  'event.create.prop.priority',
  'event.create.prop.reach',
  'event.create.prop.scope',
  'event.create.prop.series',
  'event.create.prop.startDate',
  'event.create.prop.endDate',
  'event.create.prop.therapeuticAreas',
  'event.create.prop.type',
  'event.create.prop.requiresCompilation',
  'event.create.prop.webSource',
  'event.create.prop.projectNames',
  'event.create.prop.hashtag',
  'event.create.prop.isVirtual',
  'event.create.prop.ownerProduct',
  'event.update',
  'event.update.prop.address',
  'event.update.prop.areas',
  'event.update.prop.category',
  'event.update.prop.name',
  'event.update.prop.nameTranslated',
  'event.update.prop.originalName',
  'event.update.prop.organizers',
  'event.update.prop.priority',
  'event.update.prop.reach',
  'event.update.prop.scope',
  'event.update.prop.series',
  'event.update.prop.startDate',
  'event.update.prop.endDate',
  'event.update.prop.therapeuticAreas',
  'event.update.prop.type',
  'event.update.prop.requiresCompilation',
  'event.update.prop.webSource',
  'event.update.prop.hasAgenda',
  'event.update.prop.hashtag',
  'event.update.prop.isVirtual',
  'event.update.prop.meta.status',
  'event.comment.list',
  'event.comment.create',
  'eventSeries.list',
  'eventSeries.create',
  'eventSeries.create.prop.*',
  'eventSeries.update',
  'eventSeries.update.prop.*',
  'eventSeries.delete',
  'eventSeries.comment.list',
  'eventSeries.comment.create',
  'organization.request',
];

DEF[Roles.EventCompiler] = [
  'event.update.prop.reviewed',
  'event.update.prop.cmeCreditStatus',
  'event.update.prop.numberCMECredits',
  'event.comment.create',
  'event.comment.list',
  'session.create',
  'session.create.prop.*',
  'session.delete',
  'session.list',
  'session.update',
  'session.update.prop.*',
  'contribution.create',
  'contribution.delete',
  'contribution.list',
  'contribution.update',
];

DEF[Roles.EventViewer] = [
  'event.list',
  'event.audit.list',
  'eventSeries.list',
  'session.list',
  'contribution.list',
  'contributor.list',
];

DEF[Roles.EventHashtagCompiler] = [
  'event.next.hashtag',
  'event.update.submit',
  'event.update.prop.hashtag',
  'event.comment.create',
  'event.comment.list',
];

DEF[Roles.PersonManager] = [
  //'admin.dashboard',
  'affiliation.list',
  'affiliation.detail',
  'affiliation.request',
  'affiliation.audit.list',
  'affiliation.job-history.list',
  'affiliation.lfka',
  'affiliation.affiliationsClinical',
  'affiliation.all',
  'organization.request',
  'person.list',
  'person.list.bulkUpdate',
  'person.bulkList',
  'person.export',
  'person.create',
  'person.create.prop.*',
  'person.create.prop.social.instagram',
  'person.create.prop.social.tiktok',
  'person.create.mandatory.prop.cvLinks',
  // not enforced 'person.create.mandatory.prop.nameLinks',
  // not enforced 'person.create.mandatory.prop.positionLinks',
  // not enforced 'person.create.mandatory.prop.statusLinks',
  // not enforced 'person.create.mandatory.prop.emailLinks',
  'person.create.mandatory.prop.externalIds.npi',
  'person.merge',
  'person.update',
  'person.updateAnyway', // allow saving despite remaining form validation issues
  'person.updateOptOut', // allow updating opt-out KOLs
  'person.update.prop.affiliations',
  'person.update.prop.affiliations.remove',
  'person.update.prop.affiliations.inactive',
  'person.update.prop.affiliations.position',
  'person.update.prop.affiliationsLfka',
  'person.update.prop.alternativeNames',
  'person.update.prop.countryWorkflow',
  'person.update.prop.cvLinks',
  'person.update.prop.nameLinks',
  'person.update.prop.positionLinks',
  'person.update.prop.statusLinks',
  'person.update.prop.emailLinks',
  'person.update.prop.degrees',
  'person.update.prop.emails',
  'person.update.prop.externalIds.npi',
  'person.update.prop.externalIds.sln',
  'person.update.prop.faxes',
  'person.update.prop.firstName',
  'person.update.prop.idverified',
  'person.update.prop.lastName',
  'person.update.prop.meta.status',
  'person.update.prop.middleName',
  'person.update.prop.nicknames',
  'person.update.prop.optOutInquiry',
  'person.update.prop.KAMOptOutInquiry',
  'person.update.prop.originalFirstName',
  'person.update.prop.originalFullName',
  'person.update.prop.originalLastName',
  'person.update.prop.originalMiddleName',
  'person.update.prop.ownerProduct',
  'person.update.prop.phone',
  'person.update.prop.phoneLink',
  'person.update.prop.prefixes',
  'person.update.prop.priority',
  'person.update.prop.professionalFocus',
  'person.update.prop.segments',
  'person.update.prop.social.twitter', // (for completeness; why would we not, as manager)
  'person.update.prop.social.instagram',
  'person.update.prop.social.tiktok',
  'person.update.prop.specialties',
  'person.update.prop.source',
  'person.update.prop.status',
  'person.update.prop.optoutStatus',
  'person.update.prop.suffix',
  'person.update.prop.talkingPoints',
  'person.update.prop.verified',
  'person.update.mandatory.prop.cvLinks',
  // not enforced 'person.update.mandatory.prop.nameLinks',
  // not enforced 'person.update.mandatory.prop.positionLinks',
  // not enforced 'person.update.mandatory.prop.statusLinks',
  // not enforced 'person.update.mandatory.prop.emailLinks',
  'person.update.prop.externalIds.openData',
  'person.update.prop.deliveryProjects',
  'person.update.prop.technicalProjects',
  'person.update.prop.affiliationsLinks',
  'person.update.prop.degreeLinks',
  'person.update.prop.specialtyLinks',
  'person.update.prop.linkedinUrl',
  'person.update.prop.profession',
  'person.update.prop.tags',
  'person.delete',
  'person.comment.list',
  'person.comment.create',
  'person.tag.list',
  'person.audit.list',
  'person.checkFindings.list',
  'person.dashboards',
  'person.committee.list',
  'person.job-history.list',
  'person.compliance.delete',
  'report.profile',
  'report.baseData',
  'report.ident',
  'report.bdident',
  'report.qc',
  'report.queues',
  'report.queue.baseData',
  'report.queue.bdident',
  'report.queue.idbdqc',
  'user.list',
  'user.create',
  'user.detail',
  'user.update',
  'user.bulkList',
  'userPool.detail',
  'userPool.list',
  'userPool.create',
  'userPool.update',
  'memo.create',
  'memo.update',
  'memo.delete',
  'memo.bulkList',
  'projectTags.list',
  'projectTags.create.personProject',
  'projectTags.edit.personProject',
  'projectTags.update.technical',
  'projectTags.delete.personProject',
  'job.list',
  'job.update',
  'job.export',
  'committee.person.list',
];

DEF[Roles.PersonModerator] = [
  //'admin.dashboard',
  'affiliation.list',
  'affiliation.detail',
  'affiliation.request',
  'affiliation.audit.list',
  'affiliation.job-history.list',
  'affiliation.lfka',
  'affiliation.all',
  'organization.request',
  'person.list',
  'person.bulkList',
  'person.export',
  'person.create',
  'person.create.prop.*',
  'person.create.prop.social.instagram',
  'person.create.prop.social.tiktok',
  'person.create.mandatory.prop.cvLinks',
  // not enforced 'person.create.mandatory.prop.nameLinks',
  // not enforced 'person.create.mandatory.prop.positionLinks',
  // not enforced 'person.create.mandatory.prop.statusLinks',
  // not enforced 'person.create.mandatory.prop.emailLinks',
  'person.create.mandatory.prop.externalIds.npi',
  'person.merge',
  'person.update',
  'person.updateAnyway', // allow saving despite remaining form validation issues
  'person.update.prop.affiliations',
  'person.update.prop.affiliations.remove',
  'person.update.prop.affiliations.inactive',
  'person.update.prop.affiliations.position',
  'person.update.prop.affiliationsLfka',
  'person.update.prop.alternativeNames',
  'person.update.prop.countryWorkflow',
  'person.update.prop.cvLinks',
  'person.update.prop.nameLinks',
  'person.update.prop.positionLinks',
  'person.update.prop.statusLinks',
  'person.update.prop.emailLinks',
  'person.update.prop.degrees',
  'person.update.prop.emails',
  'person.update.prop.externalIds.npi',
  'person.update.prop.externalIds.sln',
  'person.update.prop.faxes',
  'person.update.prop.firstName',
  'person.update.prop.lastName',
  'person.update.prop.meta.status',
  'person.update.prop.middleName',
  'person.update.prop.nicknames',
  'person.update.prop.optOutInquiry',
  'person.update.prop.KAMOptOutInquiry',
  'person.update.prop.originalFirstName',
  'person.update.prop.originalFullName',
  'person.update.prop.originalLastName',
  'person.update.prop.originalMiddleName',
  'person.update.prop.ownerProduct',
  'person.update.prop.phone',
  'person.update.prop.phoneLink',
  'person.update.prop.prefixes',
  'person.update.prop.priority',
  'person.update.prop.professionalFocus',
  'person.update.prop.segments',
  'person.update.prop.social.twitter', // (for completeness; why would we not, as moderator)
  'person.update.prop.social.instagram',
  'person.update.prop.social.tiktok',
  'person.update.prop.specialties',
  'person.update.prop.source',
  'person.update.prop.status',
  'person.update.prop.optoutStatus',
  'person.update.prop.suffix',
  'person.update.prop.talkingPoints',
  'person.update.prop.deliveryProjects',
  'person.update.prop.technicalProjects',
  'person.update.mandatory.prop.cvLinks',
  // not enforced 'person.update.mandatory.prop.nameLinks',
  // not enforced 'person.update.mandatory.prop.positionLinks',
  // not enforced 'person.update.mandatory.prop.statusLinks',
  // not enforced 'person.update.mandatory.prop.emailLinks',
  'person.update.prop.externalIds.openData',
  'person.update.prop.affiliationsLinks',
  'person.update.prop.degreeLinks',
  'person.update.prop.specialtyLinks',
  'person.update.prop.linkedinUrl',
  'person.update.prop.profession',
  'person.update.prop.tags',
  'person.delete',
  'person.comment.list',
  'person.comment.create',
  'person.tag.list',
  'person.audit.list',
  'person.checkFindings.list',
  'person.dashboards',
  'person.profile.summary',
  'person.request-image-maintenance',
  'person.committee.list',
  'person.job-history.list',
  'report.profile',
  'report.baseData',
  'report.ident',
  'report.bdident',
  'report.queues',
  'report.queue.baseData',
  'report.queue.bdident',
  'report.queue.idbdqc',
  'committee.person.list',
];

DEF[Roles.PersonModifier] = [
  'affiliation.list',
  'affiliation.detail',
  'affiliation.request',
  'affiliation.audit.list',
  'affiliation.job-history.list',
  'organization.request',
  'person.list',
  'person.create',
  'person.create.prop.*',
  'person.create.mandatory.prop.cvLinks',
  // not enforced 'person.create.mandatory.prop.nameLinks',
  // not enforced 'person.create.mandatory.prop.positionLinks',
  // not enforced 'person.create.mandatory.prop.statusLinks',
  // not enforced 'person.create.mandatory.prop.emailLinks',
  'person.create.mandatory.prop.externalIds.npi',
  'person.merge',
  'person.update',
  'person.updateAnyway', // allow saving despite remaining form validation issues
  'person.update.prop.affiliations',
  'person.update.prop.affiliations.removeRequest',
  'person.update.prop.affiliations.inactive',
  'person.update.prop.affiliations.position',
  'person.update.prop.alternativeNames',
  'person.update.prop.countryWorkflow',
  'person.update.prop.cvLinks',
  'person.update.prop.nameLinks',
  'person.update.prop.positionLinks',
  'person.update.prop.statusLinks',
  'person.update.prop.emailLinks',
  'person.update.prop.degrees',
  'person.update.prop.emails',
  'person.update.prop.externalIds.npi',
  'person.update.prop.externalIds.sln',
  'person.update.prop.faxes',
  'person.update.prop.firstName',
  'person.update.prop.lastName',
  'person.update.prop.meta.status',
  'person.update.prop.middleName',
  'person.update.prop.nicknames',
  'person.update.prop.optOutInquiry',
  'person.update.prop.KAMOptOutInquiry',
  'person.update.prop.originalFirstName',
  'person.update.prop.originalFullName',
  'person.update.prop.originalLastName',
  'person.update.prop.originalMiddleName',
  'person.update.prop.ownerProduct',
  'person.update.prop.phone',
  'person.update.prop.phoneLink',
  'person.update.prop.prefixes',
  'person.update.prop.priority',
  'person.update.prop.professionalFocus',
  'person.update.prop.segments',
  'person.update.prop.specialties',
  'person.update.prop.source',
  'person.update.prop.status',
  'person.update.prop.optoutStatus',
  'person.update.prop.suffix',
  'person.update.prop.talkingPoints',
  'person.update.prop.deliveryProjects',
  'person.update.prop.technicalProjects',
  'person.update.mandatory.prop.cvLinks',
  // not enforced 'person.update.mandatory.prop.nameLinks',
  // not enforced 'person.update.mandatory.prop.positionLinks',
  // not enforced 'person.update.mandatory.prop.statusLinks',
  // not enforced 'person.update.mandatory.prop.emailLinks',
  'person.update.prop.externalIds.openData',
  'person.update.prop.affiliationsLinks',
  'person.update.prop.degreeLinks',
  'person.update.prop.specialtyLinks',
  'person.update.prop.linkedinUrl',
  'person.update.prop.profession',
  'person.update.prop.tags',
  'person.delete',
  'person.comment.list',
  'person.comment.create',
  'person.tag.list',
  'person.audit.list',
  'person.checkFindings.list',
  'person.committee.list',
  'committee.person.list',
];

DEF[Roles.PersonReviewer] = [
  'affiliation.list',
  'affiliation.detail',
  'affiliation.request',
  'affiliation.markDuplicateSuspect',
  'affiliation.audit.list',
  'affiliation.job-history.list',
  'organization.request',
  'person.qc.next',
  'person.qc.findbyid',
  'person.create',
  'person.create.prop.*',
  'person.create.mandatory.prop.cvLinks',
  // not enforced 'person.create.mandatory.prop.nameLinks',
  // not enforced 'person.create.mandatory.prop.positionLinks',
  // not enforced 'person.create.mandatory.prop.statusLinks',
  // not enforced 'person.create.mandatory.prop.emailLinks',
  'person.merge',
  'person.update',
  'person.update.prop.affiliations',
  'person.update.prop.affiliationsEducation',
  'person.update.prop.affiliations.remove',
  'person.update.prop.affiliations.inactive',
  'person.update.prop.affiliations.position',
  'person.update.prop.alternativeNames',
  'person.update.prop.countryWorkflow',
  'person.update.prop.cvLinks',
  'person.update.prop.nameLinks',
  'person.update.prop.positionLinks',
  'person.update.prop.statusLinks',
  'person.update.prop.emailLinks',
  'person.update.prop.degrees',
  'person.update.prop.emails',
  'person.update.prop.externalIds.sln',
  'person.update.prop.faxes',
  'person.update.prop.firstName',
  'person.update.prop.lastName',
  'person.update.prop.meta.status',
  'person.update.prop.middleName',
  'person.update.prop.nicknames',
  'person.update.prop.optOutInquiry',
  'person.update.prop.originalFirstName',
  'person.update.prop.originalFullName',
  'person.update.prop.originalLastName',
  'person.update.prop.originalMiddleName',
  'person.update.prop.ownerProduct',
  'person.update.prop.phone',
  'person.update.prop.phoneLink',
  'person.update.prop.prefixes',
  'person.update.prop.priority',
  'person.update.prop.professionalFocus',
  'person.update.prop.segments',
  'person.update.prop.specialties',
  'person.update.prop.source',
  'person.update.prop.status',
  'person.update.prop.suffix',
  'person.update.prop.talkingPoints',
  'person.update.prop.verified',
  'person.update.mandatory.prop.cvLinks',
  // not enforced 'person.update.mandatory.prop.nameLinks',
  // not enforced 'person.update.mandatory.prop.positionLinks',
  // not enforced 'person.update.mandatory.prop.statusLinks',
  // not enforced 'person.update.mandatory.prop.emailLinks',
  'person.update.prop.externalIds.openData',
  'person.update.prop.affiliationsLinks',
  'person.update.prop.degreeLinks',
  'person.update.prop.specialtyLinks',
  'person.update.prop.deliveryProjects',
  'person.update.prop.technicalProjects',
  'person.update.prop.profession',
  'person.update.prop.tags',
  'person.update.prop.linkedinUrl',
  'person.update.skip',
  'person.delete',
  'person.qc',
  'person.comment.list',
  'person.comment.create',
  'person.tag.list',
  'person.audit.list',
  'person.checkFindings.list',
  'person.committee.list',
  'person.job-history.list',
  'committee.person.list',
  'job.create', // e.g. add another dedup job when needed
];

DEF[Roles.PersonIDReviewer] = [
  'affiliation.list',
  'affiliation.detail',
  'affiliation.request',
  'affiliation.audit.list',
  'affiliation.job-history.list',
  'organization.request',
  'identifyqc',
  'person.identifyqc',
  'person.idqc.next',
  'person.list',
  'person.create',
  'person.create.prop.*',
  'person.create.mandatory.prop.cvLinks',
  // not enforced 'person.create.mandatory.prop.nameLinks',
  // not enforced 'person.create.mandatory.prop.positionLinks',
  // not enforced 'person.create.mandatory.prop.statusLinks',
  // not enforced 'person.create.mandatory.prop.emailLinks',
  'person.create.mandatory.prop.externalIds.npi',
  'person.merge',
  'person.update',
  'person.update.prop.affiliations',
  'person.update.prop.affiliations.removeRequest',
  'person.update.prop.affiliations.inactive',
  'person.update.prop.affiliations.position',
  'person.update.prop.alternativeNames',
  'person.update.prop.countryWorkflow',
  'person.update.prop.cvLinks',
  'person.update.prop.nameLinks',
  'person.update.prop.positionLinks',
  'person.update.prop.statusLinks',
  'person.update.prop.emailLinks',
  'person.update.prop.degrees',
  'person.update.prop.emails',
  'person.update.prop.externalIds.npi', // as creator has it, too
  'person.update.prop.externalIds.sln',
  'person.update.prop.faxes',
  'person.update.prop.firstName',
  'person.update.prop.lastName',
  'person.update.prop.meta.status',
  'person.update.prop.middleName',
  'person.update.prop.nicknames',
  'person.update.prop.optOutInquiry',
  'person.update.prop.originalFirstName',
  'person.update.prop.originalFullName',
  'person.update.prop.originalLastName',
  'person.update.prop.originalMiddleName',
  'person.update.prop.ownerProduct',
  'person.update.prop.phone',
  'person.update.prop.phoneLink',
  'person.update.prop.prefixes',
  'person.update.prop.priority',
  'person.update.prop.professionalFocus',
  'person.update.prop.segments',
  'person.update.prop.specialties',
  'person.update.prop.source',
  'person.update.prop.status',
  'person.update.prop.suffix',
  'person.update.prop.talkingPoints',
  'person.update.prop.idverified',
  'person.update.mandatory.prop.cvLinks',
  // not enforced 'person.update.mandatory.prop.nameLinks',
  // not enforced 'person.update.mandatory.prop.positionLinks',
  // not enforced 'person.update.mandatory.prop.statusLinks',
  // not enforced 'person.update.mandatory.prop.emailLinks',
  'person.update.prop.externalIds.openData',
  'person.update.prop.affiliationsLinks',
  'person.update.prop.degreeLinks',
  'person.update.prop.specialtyLinks',
  'person.update.prop.deliveryProjects',
  'person.update.prop.technicalProjects',
  'person.update.prop.profession',
  'person.update.prop.tags',
  'person.update.skipid',
  'person.delete',
  'person.idqc',
  'person.comment.list',
  'person.comment.create',
  'person.tag.list',
  'person.audit.list',
  'person.checkFindings.list',
  'person.profile.summary',
  'person.committee.list',
  'person.job-history.list',
  'committee.person.list',
  'job.create', // e.g. add another dedup job when needed
];

DEF[Roles.PersonIDBDReviewer] = [
  'affiliation.list',
  'affiliation.detail',
  'affiliation.request',
  'affiliation.audit.list',
  'affiliation.job-history.list',
  'organization.request',
  'identifyqc',
  'identifyqc.compileident',
  'person.identifyqc',
  'person.identifyqc.compileident',
  'person.idbdqc.next',
  'person.list',
  'person.create',
  'person.create.prop.*',
  'person.create.mandatory.prop.cvLinks',
  // not enforced 'person.create.mandatory.prop.nameLinks',
  // not enforced 'person.create.mandatory.prop.positionLinks',
  // not enforced 'person.create.mandatory.prop.statusLinks',
  // not enforced 'person.create.mandatory.prop.emailLinks',
  'person.create.mandatory.prop.externalIds.npi',
  'person.merge',
  'person.update',
  'person.update.prop.affiliations',
  'person.update.prop.affiliations.removeRequest',
  'person.update.prop.affiliations.inactive',
  'person.update.prop.affiliations.position',
  'person.update.prop.alternativeNames',
  'person.update.prop.countryWorkflow',
  'person.update.prop.cvLinks',
  'person.update.prop.nameLinks',
  'person.update.prop.positionLinks',
  'person.update.prop.statusLinks',
  'person.update.prop.emailLinks',
  'person.update.prop.degrees',
  'person.update.prop.emails',
  'person.update.prop.externalIds.npi', // as creator has it, too
  'person.update.prop.externalIds.sln',
  'person.update.prop.faxes',
  'person.update.prop.firstName',
  'person.update.prop.lastName',
  'person.update.prop.meta.status',
  'person.update.prop.middleName',
  'person.update.prop.nicknames',
  'person.update.prop.optOutInquiry',
  'person.update.prop.originalFirstName',
  'person.update.prop.originalFullName',
  'person.update.prop.originalLastName',
  'person.update.prop.originalMiddleName',
  'person.update.prop.ownerProduct',
  'person.update.prop.phone',
  'person.update.prop.phoneLink',
  'person.update.prop.prefixes',
  'person.update.prop.priority',
  'person.update.prop.professionalFocus',
  'person.update.prop.segments',
  'person.update.prop.specialties',
  'person.update.prop.source',
  'person.update.prop.status',
  'person.update.prop.suffix',
  'person.update.prop.talkingPoints',
  'person.update.prop.verified',
  'person.update.mandatory.prop.cvLinks',
  // not enforced 'person.update.mandatory.prop.nameLinks',
  // not enforced 'person.update.mandatory.prop.positionLinks',
  // not enforced 'person.update.mandatory.prop.statusLinks',
  // not enforced 'person.update.mandatory.prop.emailLinks',
  'person.update.prop.externalIds.openData',
  'person.update.prop.affiliationsLinks',
  'person.update.prop.degreeLinks',
  'person.update.prop.specialtyLinks',
  'person.update.prop.deliveryProjects',
  'person.update.prop.technicalProjects',
  'person.update.prop.profession',
  'person.update.prop.tags',
  'person.update.skip',
  'person.update.skipidbd',
  'person.delete',
  'person.idbdqc',
  'person.comment.list',
  'person.comment.create',
  'person.tag.list',
  'person.audit.list',
  'person.checkFindings.list',
  'person.profile.summary',
  'person.committee.list',
  'person.job-history.list',
  'committee.person.list',
  'job.create', // e.g. add another dedup job when needed
];

DEF[Roles.PersonSOReviewer] = [
  'affiliation.list',
  'affiliation.detail',
  'affiliation.request',
  'affiliation.audit.list',
  'affiliation.job-history.list',
  'affiliation.markDuplicateSuspect',
  'organization.request',
  'person.list',
  'person.create',
  'person.create.prop.*',
  'person.create.mandatory.prop.cvLinks',
  // not enforced 'person.create.mandatory.prop.nameLinks',
  // not enforced 'person.create.mandatory.prop.positionLinks',
  // not enforced 'person.create.mandatory.prop.statusLinks',
  // not enforced 'person.create.mandatory.prop.emailLinks',
  'person.merge',
  'person.update',
  'person.update.prop.affiliations',
  'person.update.prop.affiliationsEducation',
  'person.update.prop.affiliations.remove',
  'person.update.prop.affiliations.inactive',
  'person.update.prop.affiliations.position',
  'person.update.prop.alternativeNames',
  'person.update.prop.countryWorkflow',
  'person.update.prop.cvLinks',
  'person.update.prop.nameLinks',
  'person.update.prop.positionLinks',
  'person.update.prop.statusLinks',
  'person.update.prop.emailLinks',
  'person.update.prop.degrees',
  'person.update.prop.emails',
  'person.update.prop.externalIds.sln',
  'person.update.prop.faxes',
  'person.update.prop.firstName',
  'person.update.prop.lastName',
  'person.update.prop.meta.status',
  'person.update.prop.middleName',
  'person.update.prop.nicknames',
  'person.update.prop.optOutInquiry',
  'person.update.prop.originalFirstName',
  'person.update.prop.originalFullName',
  'person.update.prop.originalLastName',
  'person.update.prop.originalMiddleName',
  'person.update.prop.ownerProduct',
  'person.update.prop.phone',
  'person.update.prop.phoneLink',
  'person.update.prop.prefixes',
  'person.update.prop.priority',
  'person.update.prop.professionalFocus',
  'person.update.prop.segments',
  'person.update.prop.specialties',
  'person.update.prop.source',
  'person.update.prop.status',
  'person.update.prop.suffix',
  'person.update.prop.talkingPoints',
  'person.update.prop.verified',
  'person.update.mandatory.prop.cvLinks',
  // not enforced 'person.update.mandatory.prop.nameLinks',
  // not enforced 'person.update.mandatory.prop.positionLinks',
  // not enforced 'person.update.mandatory.prop.statusLinks',
  // not enforced 'person.update.mandatory.prop.emailLinks',
  'person.update.prop.externalIds.openData',
  'person.update.prop.affiliationsLinks',
  'person.update.prop.degreeLinks',
  'person.update.prop.specialtyLinks',
  'person.update.prop.deliveryProjects',
  'person.update.prop.technicalProjects',
  'person.update.prop.profession',
  'person.update.prop.tags',
  'person.delete',
  'person.qc',
  'person.qc.checked',
  'person.comment.list',
  'person.comment.create',
  'person.tag.list',
  'person.audit.list',
  'person.checkFindings.list',
  'person.committee.list',
  'person.job-history.list',
  'committee.person.list',
];

DEF[Roles.PersonCreator] = [
  'affiliation.detail',
  'affiliation.request',
  'affiliation.job-history.list',
  'organization.request',
  'identify',
  'person.identify',
  'person.identify.reserve',
  'person.list',
  'person.create',
  'person.create.prop.firstName',
  'person.create.prop.middleName',
  'person.create.prop.lastName',
  'person.create.prop.nicknames',
  'person.create.prop.countryWorkflow',
  'person.create.prop.prefixes',
  'person.create.prop.suffix',
  'person.create.prop.externalIds.npi',
  'person.create.prop.externalIds.sln',
  'person.create.prop.affiliations',
  'person.create.prop.degrees',
  'person.create.prop.professionalFocus',
  'person.create.prop.segments',
  'person.create.prop.specialties',
  'person.create.prop.status',
  'person.create.prop.cvLinks',
  'person.create.prop.nameLinks',
  'person.create.prop.positionLinks',
  'person.create.prop.statusLinks',
  'person.create.prop.emailLinks',
  'person.create.prop.originalFirstName',
  'person.create.prop.originalMiddleName',
  'person.create.prop.originalLastName',
  'person.create.prop.originalFullName',
  'person.create.prop.alternativeNames',
  'person.create.prop.emails',
  'person.create.prop.faxes',
  'person.create.prop.source',
  'person.create.prop.optOutInquiry',
  'person.create.prop.deliveryProjects',
  'person.create.prop.technicalProjects',
  'person.create.prop.affiliationsLinks',
  'person.create.prop.degreeLinks',
  'person.create.prop.specialtyLinks',
  'person.create.prop.profession',
  'person.create.prop.tags',
  'person.create.mandatory.prop.cvLinks',
  // not enforced 'person.create.mandatory.prop.nameLinks',
  // not enforced 'person.create.mandatory.prop.positionLinks',
  // not enforced 'person.create.mandatory.prop.statusLinks',
  // not enforced 'person.create.mandatory.prop.emailLinks',
  'person.create.mandatory.prop.externalIds.npi',
  'person.update.prop.firstName',
  'person.update.prop.middleName',
  'person.update.prop.lastName',
  'person.update.prop.nicknames',
  'person.update.prop.countryWorkflow',
  'person.update.prop.prefixes',
  'person.update.prop.suffix',
  'person.update.prop.externalIds.npi',
  'person.update.prop.externalIds.sln',
  'person.update.prop.affiliations',
  'person.update.prop.affiliations.inactive',
  'person.update.prop.degrees',
  'person.update.prop.professionalFocus',
  'person.update.prop.segments',
  'person.update.prop.specialties',
  'person.update.prop.status',
  'person.update.prop.cvLinks',
  'person.update.prop.nameLinks',
  'person.update.prop.positionLinks',
  'person.update.prop.statusLinks',
  'person.update.prop.emailLinks',
  'person.update.prop.originalFirstName',
  'person.update.prop.originalMiddleName',
  'person.update.prop.originalLastName',
  'person.update.prop.originalFullName',
  'person.update.prop.alternativeNames',
  'person.update.prop.emails',
  'person.update.prop.faxes',
  'person.update.prop.affiliationsLinks',
  'person.update.prop.degreeLinks',
  'person.update.prop.specialtyLinks',
  'person.update.prop.deliveryProjects',
  'person.update.prop.technicalProjects',
  'person.update.prop.profession',
  'person.update.prop.meta.statusLimited', // can't set to done by themselves but can set id-out et al
  'person.update.prop.tags',
  'person.comment.list',
  'person.audit.list',
  'person.profile.summary',
  'person.update.draftIdent',
  'person.update.submitIdent',
  'person.update.saveLimited', // when id-out et al
  'person.tag.list',
  'person.committee.list',
  'committee.person.list',
];

DEF[Roles.PersonContributor] = [
  'affiliation.detail',
  'affiliation.request',
  'affiliation.job-history.list',
  'organization.request',
  'person.list',
  'person.tag.list',
  'person.audit.list',
  'person.comment.list',
  'person.comment.create',
  'person.create',
  'person.create.prop.firstName',
  'person.create.prop.middleName',
  'person.create.prop.lastName',
  'person.create.prop.nicknames',
  'person.create.prop.prefixes',
  'person.create.prop.suffix',
  'person.create.prop.externalIds.sln',
  'person.create.prop.affiliations',
  'person.create.prop.degrees',
  'person.create.prop.professionalFocus',
  'person.create.prop.segments',
  'person.create.prop.specialties',
  'person.create.prop.status',
  'person.create.prop.cvLinks',
  'person.create.prop.nameLinks',
  'person.create.prop.positionLinks',
  'person.create.prop.statusLinks',
  'person.create.prop.emailLinks',
  'person.create.prop.originalFirstName',
  'person.create.prop.originalMiddleName',
  'person.create.prop.originalLastName',
  'person.create.prop.originalFullName',
  'person.create.prop.alternativeNames',
  'person.create.prop.emails',
  'person.create.prop.faxes',
  'person.create.prop.source',
  'person.create.prop.optOutInquiry',
  'person.create.prop.profession',
  'person.create.prop.tags',
  'person.committee.list',
  'committee.person.list',
];

DEF[Roles.PersonCompiler] = [
  'affiliation.list',
  'affiliation.detail',
  'affiliation.request',
  'affiliation.job-history.list',
  'organization.request',
  'person.next',
  'person.tag.list',
  'person.update.prop.firstName',
  'person.update.prop.middleName',
  'person.update.prop.lastName',
  'person.update.prop.nicknames',
  'person.update.prop.prefixes',
  'person.update.prop.suffix',
  'person.update.prop.externalIds.sln',
  'person.update.prop.affiliations',
  'person.update.prop.affiliations.removeRequest',
  'person.update.prop.affiliations.inactive',
  'person.update.prop.affiliations.position',
  'person.update.prop.degrees',
  'person.update.prop.professionalFocus',
  'person.update.prop.segments',
  'person.update.prop.specialties',
  'person.update.prop.status',
  'person.update.prop.cvLinks',
  'person.update.prop.nameLinks',
  'person.update.prop.positionLinks',
  'person.update.prop.statusLinks',
  'person.update.prop.emailLinks',
  'person.update.prop.originalFirstName',
  'person.update.prop.originalMiddleName',
  'person.update.prop.originalLastName',
  'person.update.prop.originalFullName',
  'person.update.prop.alternativeNames',
  'person.update.prop.emails',
  'person.update.prop.faxes',
  'person.update.prop.affiliationsLinks',
  'person.update.prop.degreeLinks',
  'person.update.prop.specialtyLinks',
  'person.update.prop.deliveryProjects',
  'person.update.prop.technicalProjects',
  'person.update.prop.profession',
  'person.update.mandatory.prop.affiliations',
  'person.update.mandatory.prop.specialties',
  'person.update.mandatory.prop.cvLinks',
  // not enforced 'person.update.mandatory.prop.nameLinks',
  // not enforced 'person.update.mandatory.prop.positionLinks',
  // not enforced 'person.update.mandatory.prop.statusLinks',
  // not enforced 'person.update.mandatory.prop.emailLinks',
  'person.update.unable',
  'person.update.draft',
  'person.update.submit',
  'person.comment.list',
  'person.comment.create',
  'person.committee.list',
  'committee.person.list',
];

DEF[Roles.PersonCompilerIdent] = [
  'affiliation.list',
  'affiliation.detail',
  'affiliation.request',
  'affiliation.job-history.list',
  'organization.request',
  'identify',
  'person.identify',
  'person.identify.compile',
  'person.identify.reserve',
  'person.list',
  'person.tag.list',
  'person.create',
  'person.create.prop.firstName',
  'person.create.prop.middleName',
  'person.create.prop.lastName',
  'person.create.prop.nicknames',
  // not - we expect full compile 'person.create.prop.countryWorkflow',
  'person.create.prop.prefixes',
  'person.create.prop.suffix',
  'person.create.prop.externalIds.npi',
  'person.create.prop.externalIds.sln',
  'person.create.prop.affiliations',
  'person.create.prop.degrees',
  'person.create.prop.professionalFocus',
  // not for this role 'person.create.prop.segments',
  'person.create.prop.specialties',
  'person.create.prop.status',
  'person.create.prop.cvLinks',
  'person.create.prop.nameLinks',
  'person.create.prop.positionLinks',
  'person.create.prop.statusLinks',
  'person.create.prop.emailLinks',
  'person.create.prop.originalFirstName',
  'person.create.prop.originalMiddleName',
  'person.create.prop.originalLastName',
  'person.create.prop.originalFullName',
  'person.create.prop.alternativeNames',
  'person.create.prop.emails',
  'person.create.prop.faxes',
  'person.create.prop.source', // (so far: also just create, not update)
  'person.create.prop.optOutInquiry', // (so far: also just create, not update)
  'person.create.prop.deliveryProjects',
  'person.create.prop.technicalProjects',
  'person.create.prop.affiliationsLinks',
  'person.create.prop.degreeLinks',
  'person.create.prop.specialtyLinks',
  'person.create.prop.profession',
  'person.create.prop.tags',
  'person.create.mandatory.prop.affiliations',
  'person.create.mandatory.prop.specialties',
  'person.create.mandatory.prop.cvLinks',
  // not enforced 'person.create.mandatory.prop.nameLinks',
  // not enforced 'person.create.mandatory.prop.positionLinks',
  // not enforced 'person.create.mandatory.prop.statusLinks',
  // not enforced 'person.create.mandatory.prop.emailLinks',
  'person.create.mandatory.prop.externalIds.npi',
  'person.update.prop.firstName',
  'person.update.prop.middleName',
  'person.update.prop.lastName',
  'person.update.prop.nicknames',
  // not - we excpect full compile 'person.update.prop.countryWorkflow',
  'person.update.prop.prefixes',
  'person.update.prop.suffix',
  'person.update.prop.externalIds.npi',
  'person.update.prop.externalIds.sln',
  'person.update.prop.affiliations',
  'person.update.prop.affiliations.removeRequest',
  'person.update.prop.affiliations.inactive',
  'person.update.prop.affiliations.position',
  'person.update.prop.degrees',
  'person.update.prop.professionalFocus',
  // not for this role 'person.update.prop.segments',
  'person.update.prop.specialties',
  'person.update.prop.status',
  'person.update.prop.cvLinks',
  'person.update.prop.nameLinks',
  'person.update.prop.positionLinks',
  'person.update.prop.statusLinks',
  'person.update.prop.emailLinks',
  'person.update.prop.originalFirstName',
  'person.update.prop.originalMiddleName',
  'person.update.prop.originalLastName',
  'person.update.prop.originalFullName',
  'person.update.prop.alternativeNames',
  'person.update.prop.emails',
  'person.update.prop.faxes',
  'person.update.prop.affiliationsLinks',
  'person.update.prop.degreeLinks',
  'person.update.prop.specialtyLinks',
  'person.update.prop.deliveryProjects',
  'person.update.prop.technicalProjects',
  'person.update.prop.profession',
  'person.update.mandatory.prop.affiliations',
  'person.update.mandatory.prop.specialties',
  'person.update.mandatory.prop.cvLinks',
  // not enforced 'person.update.mandatory.prop.nameLinks',
  // not enforced 'person.update.mandatory.prop.positionLinks',
  // not enforced 'person.update.mandatory.prop.statusLinks',
  // not enforced 'person.update.mandatory.prop.emailLinks',
  'person.update.prop.meta.statusLimited', // can't set to done by themselves but can set id-out et al
  'person.update.prop.tags',
  'person.update.unable',
  'person.update.draft',
  'person.update.submit',
  'person.update.saveLimited', // when id-out et al
  'person.audit.list',
  'person.comment.list',
  'person.comment.create',
  'person.profile.summary',
  'person.committee.list',
  'committee.person.list',
];

DEF[Roles.PersonMerger] = [
  'person.merge',
  // special permissions to go all the way with merges
  'person.update.prop.externalIds.npi',
  'person.update.optional.prop.externalIds.npi',
  'person.update.prop.externalIds.openData',
  'person.update.prop.social.twitter',
  'person.update.prop.social.instagram', // have it in (don't block; ditto next)
  'person.update.prop.social.tiktok',
  'person.update.prop.image',
  'person.committee.list',
  'committee.person.list',
];

DEF[Roles.PersonViewer] = [
  'affiliation.list',
  'affiliation.detail',
  'affiliation.affiliationsClinical',
  'person.list',
  'person.tag.list',
  'person.audit.list',
  'person.committee.list',
  'committee.person.list',
];

DEF[Roles.PersonClinicalAffiliationCompiler] = [
  'affiliation.affiliationsClinical',
  'person.update.prop.affiliationsClinical',
  'organization.request',
  'person.update.prop.affiliationsClinicalLinks',
  'person.comment.list',
  'person.comment.create',
  'person.update.prop.affiliations.remove',
  'organization.detail',
];

DEF[Roles.ProfileManager] = [
  //'admin.dashboard',
  'affiliation.list',
  'affiliation.detail',
  'affiliation.lfka',
  'affiliation.all',
  'person.list',
  'person.list.bulkUpdate',
  'person.bulkList',
  'person.export',
  'person.create',
  'person.create.prop.*',
  'person.create.mandatory.prop.cvLinks',
  // not enforced 'person.create.mandatory.prop.nameLinks',
  // not enforced 'person.create.mandatory.prop.positionLinks',
  // not enforced 'person.create.mandatory.prop.statusLinks',
  'person.create.mandatory.prop.externalIds.npi',
  // not enforced 'person.create.mandatory.prop.emailLinks',
  'person.merge',
  'person.update',
  'person.updateAnyway', // allow saving despite remaining form validation issues
  'person.updateOptOut', // allow updating opt-out KOLs
  'person.update.prop.affiliations',
  'person.update.prop.affiliations.inactive',
  'person.update.prop.affiliations.position',
  'person.update.prop.alternativeNames',
  'person.update.prop.countryWorkflow',
  'person.update.prop.cvLinks',
  'person.update.prop.nameLinks',
  'person.update.prop.positionLinks',
  'person.update.prop.statusLinks',
  'person.update.prop.emailLinks',
  'person.update.prop.degrees',
  'person.update.prop.emails',
  'person.update.prop.externalIds.npi',
  'person.update.prop.externalIds.sln',
  'person.update.prop.faxes',
  'person.update.prop.firstName',
  'person.update.prop.idverified',
  'person.update.prop.lastName',
  'person.update.prop.meta.status',
  'person.update.prop.middleName',
  'person.update.prop.nicknames',
  'person.update.prop.optOutInquiry',
  'person.update.prop.KAMOptOutInquiry',
  'person.update.prop.originalFirstName',
  'person.update.prop.originalFullName',
  'person.update.prop.originalLastName',
  'person.update.prop.originalMiddleName',
  'person.update.prop.phone',
  'person.update.prop.phoneLink',
  'person.update.prop.prefixes',
  'person.update.prop.priority',
  'person.update.prop.professionalFocus',
  'person.update.prop.segments',
  'person.update.prop.specialties',
  'person.update.prop.source',
  'person.update.prop.status',
  'person.update.prop.optoutStatus',
  'person.update.prop.suffix',
  'person.update.prop.talkingPoints',
  'person.update.prop.verified',
  'person.update.mandatory.prop.cvLinks',
  // not enforced 'person.update.mandatory.prop.nameLinks',
  // not enforced 'person.update.mandatory.prop.positionLinks',
  // not enforced 'person.update.mandatory.prop.statusLinks',
  // not enforced 'person.update.mandatory.prop.emailLinks',
  'person.update.prop.externalIds.openData',
  'person.update.prop.deliveryProjects',
  'person.update.prop.technicalProjects',
  'person.update.prop.affiliationsLinks',
  'person.update.prop.degreeLinks',
  'person.update.prop.specialtyLinks',
  'person.update.prop.linkedinUrl',
  'person.update.prop.profession',
  'person.update.prop.tags',
  'person.delete',
  'person.comment.list',
  'person.comment.create',
  'person.tag.list',
  'person.audit.list',
  'person.checkFindings.list',
  'person.dashboards',
  'person.profile.summary',
  'person.request-image-maintenance',
  'person.update.prop.deliveryProjects',
  'person.update.prop.technicalProjects',
  'person.committee.list',
  'profile.list',
  'profile.bulkList',
  'profile.list.bulkUpdate',
  'profile.update',
  'profile.update.prop.*',
  'profile.comment.list',
  'profile.comment.create',
  'profile.comment.update',
  'profile.comment.delete',
  'profile.audit.list',
  'profile.directLink',
  'profile.tags',
  'profile.subsection.list.viewEmpty',
  'profile.ml',
  'profile.origin',
  'profile-clinical-trial.bulkList',
  'profile.dashboards',
  'profile.videos',
  'profile-job.bulkList',
  'profile.export',
  'report.profile',
  'report.qc',
  'report.queues',
  'report.queue.profile',
  'user.list',
  'user.create',
  'user.detail',
  'user.update',
  'user.bulkList',
  'userPool.detail',
  'userPool.list',
  'userPool.create',
  'userPool.update',
  'memo.create',
  'memo.update',
  'memo.delete',
  'memo.bulkList',
  'projectTags.list',
  'projectTags.create.personProject',
  'projectTags.edit.personProject',
  'projectTags.update.technical',
  'projectTags.delete.personProject',
  'job.list',
  'job.update',
  'job.export',
  'committee.person.list',
];

DEF[Roles.ProfileModerator] = [
  //'admin.dashboard',
  'affiliation.list',
  'affiliation.detail',
  'affiliation.lfka',
  'affiliation.all',
  'person.list',
  'person.bulkList',
  'person.export',
  'person.create',
  'person.create.prop.*',
  'person.create.mandatory.prop.cvLinks',
  // not enforced 'person.create.mandatory.prop.nameLinks',
  // not enforced 'person.create.mandatory.prop.positionLinks',
  // not enforced 'person.create.mandatory.prop.statusLinks',
  // not enforced 'person.create.mandatory.prop.emailLinks',
  'person.create.mandatory.prop.externalIds.npi',
  'person.merge',
  'person.update',
  'person.updateAnyway', // allow saving despite remaining form validation issues
  'person.update.prop.affiliations',
  'person.update.prop.affiliations.inactive',
  'person.update.prop.affiliations.position',
  'person.update.prop.alternativeNames',
  'person.update.prop.countryWorkflow',
  'person.update.prop.cvLinks',
  'person.update.prop.nameLinks',
  'person.update.prop.positionLinks',
  'person.update.prop.statusLinks',
  'person.update.prop.emailLinks',
  'person.update.prop.degrees',
  'person.update.prop.emails',
  'person.update.prop.externalIds.npi',
  'person.update.prop.externalIds.sln',
  'person.update.prop.faxes',
  'person.update.prop.firstName',
  'person.update.prop.lastName',
  'person.update.prop.meta.status',
  'person.update.prop.middleName',
  'person.update.prop.nicknames',
  'person.update.prop.optOutInquiry',
  'person.update.prop.KAMOptOutInquiry',
  'person.update.prop.originalFirstName',
  'person.update.prop.originalFullName',
  'person.update.prop.originalLastName',
  'person.update.prop.originalMiddleName',
  'person.update.prop.phone',
  'person.update.prop.phoneLink',
  'person.update.prop.prefixes',
  'person.update.prop.priority',
  'person.update.prop.professionalFocus',
  'person.update.prop.segments',
  'person.update.prop.specialties',
  'person.update.prop.source',
  'person.update.prop.status',
  'person.update.prop.optoutStatus',
  'person.update.prop.suffix',
  'person.update.prop.talkingPoints',
  'person.update.prop.deliveryProjects',
  'person.update.prop.technicalProjects',
  'person.update.mandatory.prop.cvLinks',
  // not enforced 'person.update.mandatory.prop.nameLinks',
  // not enforced 'person.update.mandatory.prop.positionLinks',
  // not enforced 'person.update.mandatory.prop.statusLinks',
  // not enforced 'person.update.mandatory.prop.emailLinks',
  'person.update.prop.externalIds.openData',
  'person.update.prop.affiliationsLinks',
  'person.update.prop.degreeLinks',
  'person.update.prop.specialtyLinks',
  'person.update.prop.linkedinUrl',
  'person.update.prop.profession',
  'person.update.prop.tags',
  'person.delete',
  'person.comment.list',
  'person.comment.create',
  'person.tag.list',
  'person.audit.list',
  'person.checkFindings.list',
  'person.dashboards',
  'person.profile.summary',
  'person.request-image-maintenance',
  'person.update.prop.deliveryProjects',
  'person.update.prop.technicalProjects',
  'person.committee.list',
  'profile.dashboards',
  'profile.list',
  'profile.update',
  'profile.update.prop.*',
  'profile.comment.list',
  'profile.comment.create',
  'profile.comment.update',
  'profile.comment.delete',
  'profile.audit.list',
  'profile.directLink',
  'profile.tags',
  'profile.subsection.list.viewEmpty',
  'profile.ml',
  'profile.origin',
  'profile-clinical-trial.bulkList',
  'report.profile',
  'report.queues',
  'report.queue.profile',
  'committee.person.list',
];

DEF[Roles.ProfileModifier] = [
  'person.profile.summary',
  'person.update.prop.deliveryProjects',
  'person.update.prop.technicalProjects',
  'person.checkFindings.list',
  'affiliation.create.prop.name',
  'affiliation.create.prop.department',
  'affiliation.create.prop.type',
  'affiliation.create.prop.originalName',
  'affiliation.create.prop.originalDepartment',
  'affiliation.create.prop.address',
  'affiliation.create.prop.webSource',
  'profile.list',
  'profile.update',
  'profile.update.prop.*',
  'profile.comment.list',
  'profile.comment.create',
  'profile.comment.update',
  'profile.comment.delete',
  'profile.audit.list',
  'profile.directLink',
  'profile.tags',
  'profile.subsection.list.viewEmpty',
  'profile.ml',
  'profile.origin',
  'person.committee.list',
  'committee.person.list',
];

DEF[Roles.ProfileReviewer] = [
  'affiliation.markDuplicateSuspect',
  'affiliation.all',
  'profile.update',
  'profile.update.prop.*',
  'profile.comment.list',
  'profile.comment.create',
  'profile.comment.update',
  'profile.comment.delete',
  'profile.audit.list',
  'profile.qc',
  'profile.qc.next',
  'profile.update.skip',
  'person.checkFindings.list',
  'person.request-image-maintenance',
  'person.committee.list',
  'committee.person.list',
];

DEF[Roles.ProfileSOReviewer] = [
  'profile.update',
  'profile.update.prop.*',
  'profile.comment.list',
  'profile.comment.create',
  'profile.qc',
  'profile.qc.extended', // Can add specific error types
  'profile.audit.list',
  ...DEF[Roles.EventViewer],
  ...DEF[Roles.AssociationViewer],
  'profile.subsection.list.viewEmpty',
];

DEF[Roles.ProfileCompiler] = [
  'affiliation.detail',
  'affiliation.job-history.list',
  'person.comment.list',
  'person.comment.create',
  'person.checkFindings.list',
  'person.request-image-maintenance',
  'person.committee.list',
  'person.tag.list',
  'profile.next',
  'profile.update.prop.manual.match',
  'profile.update.unable',
  'profile.update.submit',
  'profile.comment.list',
  'profile.comment.create',
  'profile.comment.update',
  'profile.comment.delete',
  'committee.person.list',
];

DEF[Roles.ProfileViewer] = [
  'person.profile.summary',
  'person.committee.list',
  'profile.list',
  'profile.origin',
  'profile.comment.list',
  'committee.person.list',
  'profile.ml',
  'profile.origin',
];

DEF[Roles.ProfileCompilerGrant] = [
  'person.comment.list',
  'person.comment.create',
  'person.tag.list',
  'person.committee.list',
  'profile.update.prop.manual.match',
  'profile.comment.list',
  'profile.comment.create',
  'profile.comment.update',
  'profile.comment.delete',
  'committee.person.list',
];

DEF[Roles.ProfileCompilerPodcast] = [
  'profile.subsection.list.viewEmpty', // can at least get rid of it
  'profile.comment.list',
  'profile.comment.create',
  'profile.comment.update',
  'profile.comment.delete',
  'profile.podcasts',
  'profile.update.prop.manual.match',
  'person.tag.list',
  'job.next',
];

DEF[Roles.ProfileReviewerPodcast] = [
  'profile.subsection.list.viewEmpty', // can at least get rid of it
  'profile.list',
  'profile.comment.list',
  'profile.comment.create',
  'profile.comment.update',
  'profile.comment.delete',
  'profile.audit.list',
  'profile.qc',
  'profile.podcasts',
  'profile.update.prop.manual.match',
  'person.tag.list',
  'job.next',
];

DEF[Roles.SentimentManager] = [
  'sentiment.list',
  'sentiment.bulkList',
  'sentiment.submit',
  'sentiment.dashboards',
  'user.list',
  'user.create',
  'user.detail',
  'user.update',
  'user.bulkList',
  'userPool.detail',
  'userPool.list',
  'userPool.create',
  'userPool.update',
];

DEF[Roles.SentimentCompiler] = ['sentiment.next', 'sentiment.submit'];

DEF[Roles.SentimentViewer] = ['sentiment.list'];

DEF[Roles.GuidelineManager] = [
  'guideline.list',
  'guideline.bulkList',
  'guideline.create',
  'guideline.create.author',
  'guideline.create.prop.*',
  'guideline.update',
  'guideline.update.author',
  'guideline.update.prop.*',
  'guideline.delete',
  'guideline.delete.author',
  'guideline.comment.list',
  'guideline.comment.create',
  'guideline.audit.list',
  'guideline.list.bulkUpdate',
  'guideline.dashboards',
  'user.list',
  'user.create',
  'user.detail',
  'user.update',
  'user.bulkList',
  'userPool.detail',
  'userPool.list',
  'userPool.create',
  'userPool.update',
  'projectTags.list',
  'projectTags.create.project',
  'projectTags.edit.project',
  'projectTags.delete.project',
];

DEF[Roles.GuidelineModerator] = [
  'guideline.list',
  'guideline.bulkList',
  'guideline.create',
  'guideline.create.author',
  'guideline.create.prop.*',
  'guideline.update',
  'guideline.update.author',
  'guideline.update.prop.*',
  'guideline.delete',
  'guideline.delete.author',
  'guideline.comment.list',
  'guideline.comment.create',
  'guideline.audit.list',
  'guideline.list.bulkUpdate',
  'guideline.dashboards',
];

DEF[Roles.GuidelineReviewer] = [
  'guideline.create',
  'guideline.create.author',
  'guideline.delete.author',
  'guideline.create.prop.*',
  'guideline.update',
  'guideline.update.skip',
  'guideline.update.author',
  'guideline.update.prop.*',
  'guideline.comment.list',
  'guideline.comment.create',
  'guideline.audit.list',
  'guideline.qc',
];

DEF[Roles.GuidelineIDReviewer] = [
  'guideline.create',
  'guideline.create.author',
  'guideline.create.prop.*',
  'guideline.update',
  'guideline.update.author',
  'guideline.update.prop.*',
  'guideline.delete',
  'guideline.comment.list',
  'guideline.comment.create',
  'guideline.audit.list',
  'guideline.idqc',
];

DEF[Roles.GuidelineCreator] = [
  'guideline.list',
  'guideline.create',
  'guideline.create.prop.title',
  'guideline.create.prop.titleTranslated',
  'guideline.create.prop.originalTitle',
  'guideline.create.prop.externalIds.pmid',
  'guideline.create.prop.externalIds.doi',
  'guideline.create.prop.journal.title',
  'guideline.create.prop.journal.volume',
  'guideline.create.prop.journal.country',
  'guideline.create.prop.journal.issue',
  'guideline.create.prop.publicationDate',
  'guideline.create.prop.abstract',
  'guideline.create.prop.fullContent',
  'guideline.create.prop.projectNames',
  'guideline.create.prop.category',
  'guideline.create.prop.reach',
  'guideline.create.prop.fullTextLink',
  'guideline.create.prop.webSource',
  'guideline.create.prop.keywords',
  'guideline.create.prop.associations',
  'guideline.create.prop.ownerProduct',
  'guideline.update',
  'guideline.update.prop.title',
  'guideline.update.prop.originalTitle',
  'guideline.update.prop.externalIds.pmid',
  'guideline.update.prop.externalIds.doi',
  'guideline.update.prop.journal.title',
  'guideline.update.prop.journal.volume',
  'guideline.update.prop.journal.country',
  'guideline.update.prop.journal.issue',
  'guideline.update.prop.publicationDate',
  'guideline.update.prop.abstract',
  'guideline.update.prop.fullContent',
  'guideline.update.prop.projectNames',
  'guideline.update.prop.category',
  'guideline.update.prop.reach',
  'guideline.update.prop.fullTextLink',
  'guideline.update.prop.webSource',
  'guideline.update.prop.keywords',
  'guideline.update.prop.associations',
  'guideline.update.prop.meta.status',
  'guideline.comment.list',
  'guideline.comment.create',
];

DEF[Roles.GuidelineCompiler] = [
  'guideline.next',
  'guideline.update.submit',
  'guideline.update.unable',
  'guideline.comment.list',
  'guideline.comment.create',
  'guideline.create.prop.associations',
  'guideline.create.author',
  'guideline.create.prop.author.*',
  'guideline.update',
  'guideline.update.prop.originalTitle',
  'guideline.update.prop.fullTextLink',
  'guideline.update.prop.associations',
  'guideline.update.prop.keywords',
  'guideline.update.author',
  'guideline.update.prop.author.*',
  'guideline.delete.author',
];

DEF[Roles.GuidelineViewer] = ['guideline.list', 'guideline.audit.list'];

DEF[Roles.Trainer] = [
  'profile.list',
  'profile.comment.list',
  'profile.comment.create',
  'profile.comment.update',
  'profile.comment.delete',
  'profile.audit.list',
  'profile.training',
  'profile.update.prop.meta.status',
  'person.update.prop.meta.status',
  'person.list',
  'person.committee.list',
  'committee.person.list',
];

DEF[Roles.ViewLfka] = ['affiliation.lfka', 'person.update.prop.affiliationsLfka'];

DEF[Roles.ReadyForJobs] = ['job.next', 'job.submit'];

DEF[Roles.JobCreator] = ['job.create'];

DEF[Roles.JobViewer] = ['job.list', 'job.full-list', 'value.list', 'user-pool.list', 'bulk.list-status'];

DEF[Roles.VideoCompiler] = [
  'profile.video.next',
  'profile.subsection.list.viewEmpty',
  'profile.videoDirectLink',
  'profile.videos',
  'profile.videos.submit',
  'profile.videos.delete',
];

DEF[Roles.VideoChannelCompiler] = [
  'profile.videoDirectLink',
  'profile.videos',
  'profile.videos.submit',
  'person.create',
];

DEF[Roles.VideoReviewer] = [
  'profile.list',
  'profile.subsection.list.viewEmpty',
  'profile.videoDirectLink',
  'profile.videos',
  'profile.videos.delete',
];

DEF[Roles.VideoManager] = [
  'profile.list',
  'profile.subsection.list.viewEmpty',
  'profile.videoDirectLink',
  'profile.videos',
  'profile.videos.delete',
  'profile-video.bulkList',
  'user.list',
  'user.create',
  'user.detail',
  'user.update',
  'user.bulkList',
  'userPool.detail',
  'userPool.list',
  'userPool.create',
  'userPool.update',
  'job.list',
  'job.update',
  'job.export',
];

DEF[Roles.AffiliationManager] = [
  'affiliation.detail',
  'affiliation.lfka',
  'affiliation.list',
  'affiliation.create',
  'affiliation.update',
  'affiliation.create.prop.name',
  'affiliation.create.prop.department',
  'affiliation.create.prop.type',
  'affiliation.create.prop.originalName',
  'affiliation.create.prop.originalDepartment',
  'affiliation.create.prop.address',
  'affiliation.create.prop.webSource',
  'affiliation.update.prop.name',
  'affiliation.update.prop.department',
  'affiliation.update.prop.type',
  'affiliation.update.prop.originalName',
  'affiliation.update.prop.originalDepartment',
  'affiliation.update.prop.address',
  'affiliation.update.prop.verified',
  'affiliation.update.prop.webSource',
  'affiliation.update.prop.originalLanguage',
  'affiliation.update.prop.meta.status',
  'affiliation.delete',
  'affiliation.merge',
  'affiliation.audit.list',
  'affiliation.bulkList',
  'affiliation.dashboards',
  'affiliation.job-history.list',
  'affiliation.export',
  'person.listMembers',
  'user.list',
  'user.create',
  'user.detail',
  'user.update',
  'user.bulkList',
  'userPool.detail',
  'userPool.list',
  'userPool.create',
  'userPool.update',
  'job.list',
  'job.update',
  'job.export',
];

DEF[Roles.AffiliationMaintainer] = [
  'affiliation.detail',
  'affiliation.lfka',
  'affiliation.next',
  'affiliation.mergeLimited',
  'affiliation.delete',
  'affiliation.submit',
  'affiliation.update.prop.name',
  'affiliation.update.prop.department',
  'affiliation.update.prop.type',
  'affiliation.update.prop.originalName',
  'affiliation.update.prop.originalDepartment',
  'affiliation.update.prop.address',
  'affiliation.update.prop.webSource',
  'affiliation.update.prop.originalLanguage',
  'affiliation.job-history.list',
  'person.listMembers',
];

DEF[Roles.AffiliationCompiler] = [
  'affiliation.detail',
  'affiliation.lfka',
  'affiliation.list',
  'affiliation.audit.list',
  'affiliation.create',
  'affiliation.merge',
  'affiliation.update',
  'affiliation.delete',
  'affiliation.create.prop.name',
  'affiliation.create.prop.department',
  'affiliation.create.prop.type',
  'affiliation.create.prop.originalName',
  'affiliation.create.prop.originalDepartment',
  'affiliation.create.prop.address',
  'affiliation.create.prop.webSource',
  'affiliation.update.prop.name',
  'affiliation.update.prop.department',
  'affiliation.update.prop.type',
  'affiliation.update.prop.originalName',
  'affiliation.update.prop.originalDepartment',
  'affiliation.update.prop.address',
  'affiliation.update.prop.webSource',
  'affiliation.update.prop.originalLanguage',
  'affiliation.job-history.list',
  'person.listMembers',
];

DEF[Roles.AffiliationMerger] = [
  'affiliation.detail',
  'affiliation.lfka',
  'affiliation.merge',
  'affiliation.merge.next',
  'affiliation.delete',
  'affiliation.update',
  'affiliation.submit-merge',
  'affiliation.update.prop.name',
  'affiliation.update.prop.department',
  'affiliation.update.prop.type',
  'affiliation.update.prop.originalName',
  'affiliation.update.prop.originalDepartment',
  'affiliation.update.prop.address',
  'affiliation.update.prop.webSource',
  'affiliation.update.prop.originalLanguage',
  'affiliation.job-history.list',
  'person.listMembers',
];

DEF[Roles.AffiliationViewer] = [
  'affiliation.detail',
  'affiliation.list',
  'affiliation.audit.list',
  'affiliation.job-history.list',
  'person.listMembers',
];

DEF[Roles.OrganizationManager] = [
  'organization.bulk-list',
  'organization.list',
  'organization.detail',
  'organization.create',
  'organization.move',
  'organization.merge',
  'organization.split',
  'organization.delete',
  'organization.removeDeletionRequest',
  'organization.update',
  'organization.update.prop.*',
  'organization.request',
  'organization.audit.list',
  'organization.comment.list',
  'organization.comment.create',
  'organization.job-history.list',
  'organization.affiliation.list',
  'organization.affiliation.update',
  'organization.affiliation.create',
  'user.list',
  'user.create',
  'user.detail',
  'user.update',
  'user.bulkList',
  'userPool.detail',
  'userPool.list',
  'userPool.create',
  'userPool.update',
  'job.list',
  'job.update',
  'job.export',
  'job.create',
];

DEF[Roles.OrganizationViewer] = [
  'organization.list',
  'organization.detail',
  'organization.audit.list',
  'organization.comment.list',
];

DEF[Roles.OrganizationMerger] = ['organization.list', 'organization.detail', 'organization.merge'];

DEF[Roles.OrganizationSplitter] = ['organization.list', 'organization.detail', 'organization.split'];

DEF[Roles.OrganizationCompiler] = [
  'organization.list',
  'organization.detail',
  'organization.create',
  'organization.audit.list',
  'organization.comment.list',
  'organization.comment.create',
  'organization.move',
  'organization.update',
  'organization.update.prop.*',
  'organization.request',
];

DEF[Roles.OrganizationStructureCompiler] = [
  'organization.detail',
  'organization.create',
  'organization.audit.list',
  'organization.comment.list',
  'organization.comment.create',
  'organization.move',
  'organization.update',
  'organization.update.prop.*', // all but readonly
  'organization.update.readonly.prop.type', // type is only curatable by type compiler role
  'organization.request',
  'organization.delete',
];

DEF[Roles.OrganizationMappingCompiler] = [
  'organization.detail',
  'organization.audit.list',
  'organization.comment.list',
  'organization.comment.create',
  'organization.affiliation.list',
  'organization.affiliation.update',
  'organization.affiliation.create',
  'affiliation.list',
  'affiliation.detail',
];

DEF[Roles.OrganizationTypeCompiler] = [
  'organization.detail',
  'organization.audit.list',
  'organization.comment.list',
  'organization.comment.create',
  'organization.update',
  'organization.update.prop.type', // only that one
  'organization.request',
];

DEF[Roles.OrganizationReviewer] = [
  'organization.list',
  'organization.detail',
  'organization.create',
  'organization.audit.list',
  'organization.comment.list',
  'organization.comment.create',
  'organization.move',
  'organization.update',
  'organization.update.prop.*',
  'organization.request',
  'organization.qc',
  'organization.affiliation.list',
  'affiliation.list',
];

DEF[Roles.OrganizationMappingReviewer] = [
  'organization.list',
  'organization.detail',
  'organization.audit.list',
  'organization.comment.list',
  'organization.comment.create',
  'organization.qc',
  'organization.affiliation.list',
  'organization.affiliation.update',
  'organization.affiliation.create',
  'affiliation.list',
  'affiliation.detail',
];

DEF[Roles.AccountManager] = [
  'account.affiliation.create',
  'account.affiliation.delete',
  'account.affiliation.update',
  'account.affiliation.list',
  'account.affiliation.audit.list',
  'account.affiliation-suspect.list',
  'account.affiliation-suspect.update',
  'account-person.list',
  'account-person.update',
  'account-person.create',
  'account-person.delete',
  'account-person.update.prop.*',
  'account.audit.list',
  'account.comment.create',
  'account.comment.list',
  'account.create.prop.*',
  'account.create',
  'account.delete',
  'account.export',
  'account.reparent',
  'account.list',
  'account.bulkList',
  'account.update.prop.*',
  'account.update',
  'account.saveAnyway',
  'account.profile',
  'account.job-history.list',
  'account-financial-information.list',
  'account-financial-information.create',
  'account-financial-information.create.prop.*',
  'account-financial-information.update',
  'account-financial-information.update.prop.*',
  'account-financial-information.delete',
  'account-medical-information.list',
  'account-medical-information.create',
  'account-medical-information.create.prop.*',
  'account-medical-information.update',
  'account-medical-information.update.prop.*',
  'account-medical-information.delete',
  'account-medical-infirmation.bulk-list',
  'account-payer-information.list',
  'account-payer-information.create',
  'account-payer-information.create.prop.*',
  'account-payer-information.update',
  'account-payer-information.update.prop.*',
  'account-payer-information.delete',
  'affiliation.audit.list',
  'affiliation.job-history.list',
  'affiliation.detail',
  'affiliation.request',
  'affiliation.lfka',
  'affiliation.list',
  'organization.request',
  'division.create.prop.*',
  'division.create',
  'division.delete',
  'division.list',
  'division.update.prop.*',
  'division.update',
  'person.audit.list',
  'person.list',
  'person.update.prop.affiliations', // so that they can add & delete
  'person.update',
  'person.listMembers',
  'user.list',
  'user.create',
  'user.detail',
  'user.update',
  'user.bulkList',
  'userPool.detail',
  'userPool.list',
  'userPool.create',
  'userPool.update',
  'job.create',
  'job.list',
  'job.update',
  'job.export',
];

DEF[Roles.AccountCreator] = [
  'account.list',
  'account.create',
  'account.create.prop.*',
  'division.list',
  'division.create',
  'division.create.prop.*',
  'division.delete',
  'division.update',
  'division.update.prop.name',
  'division.update.prop.alternativeNames',
  'division.update.prop.acronym',
  'division.update.prop.logo',
  'division.update.prop.website',
  'division.update.prop.phone.countryCallingCode',
  'division.update.prop.phone.number',
  'division.update.prop.address',
  'division.update.prop.type',
  'division.update.prop.subtype',
  'division.update.prop.websources',
];

DEF[Roles.AccountCompiler] = [
  'person.listMembers',
  'account.update',
  'account.affiliation.list',
  'account.comment.list',
  'account.comment.create',
  'account.profile',
  'account.update.prop.name',
  'account.update.prop.originalName',
  'account.update.prop.alternativeNames',
  'account.update.prop.acronym',
  'account.update.prop.logo',
  'account.update.prop.website',
  'account.update.prop.phone.countryCallingCode',
  'account.update.prop.phone.number',
  'account.update.prop.address',
  'account.update.prop.type',
  'account.update.prop.subtype',
  'account.update.prop.economicStatus',
  'account.update.prop.ownershipStatus',
  'account.update.prop.internationalOperations',
  'account.update.prop.summary',
  'account.update.prop.summaryLinks',
  'account.update.prop.employeeCount',
  'account.update.prop.annualRevenue',
  'account.update.prop.lastQuarterRevenue',
  'account.update.prop.netResult',
  'account.update.prop.netQuarterResult',
  'account.update.prop.reportingCurrency',
  'account.update.prop.marketShare',
  'account.update.prop.developmentNotes',
  'account.update.prop.developmentNotesLinks',
  'account.update.prop.payerMix',
  'account.update.prop.isGPOMember',
  'account.update.prop.GPOName',
  'account.update.prop.ownHealthInsurance',
  'account.update.prop.healthPlanName',
  'account.update.prop.healthPlanNetworkType',
  'account.update.prop.healthPlanMembersCount',
  'account.update.prop.pharmacyBenefitManager',
  'account.update.prop.financialLinks',
  'account.update.prop.targetPatientPopulationSize',
  'account.update.prop.affiliatedHospitalsCount',
  'account.update.prop.affiliatedOutpatientSitesCount',
  'account.update.prop.bedsCount',
  'account.update.prop.physiciansCount',
  'account.update.prop.inpatientsCount',
  'account.update.prop.outpatientsCount',
  'account.update.prop.totalPatientsCount',
  'account.update.prop.medicalServices',
  'account.update.prop.academicPartners',
  'account.update.prop.specialtyCenters',
  'account.update.prop.medicalLinks',
  'account.update.prop.healthPlanProductCategories',
  'account.update.prop.metricsDataLinks',
  'account.update.prop.GPOLink',
  'account.update.prop.isNCCNMember',
  'account.update.prop.websources',
  'account.update.prop.departmentNames',
  'account.update.prop.departmentNamesLinks',
  'division.list',
  'division.update',
  'division.update.prop.name',
  'division.update.prop.alternativeNames',
  'division.update.prop.acronym',
  'division.update.prop.logo',
  'division.update.prop.website',
  'division.update.prop.phone.countryCallingCode',
  'division.update.prop.phone.number',
  'division.update.prop.address',
  'division.update.prop.type',
  'division.update.prop.subtype',
  'division.update.prop.economicStatus',
  'division.update.prop.ownershipStatus',
  'division.update.prop.internationalOperations',
  'division.update.prop.summary',
  'division.update.prop.summaryLinks',
  'division.update.prop.employeeCount',
  'division.update.prop.annualRevenue',
  'division.update.prop.lastQuarterRevenue',
  'division.update.prop.netResult',
  'division.update.prop.netQuarterResult',
  'division.update.prop.reportingCurrency',
  'division.update.prop.marketShare',
  'division.update.prop.developmentNotes',
  'division.update.prop.developmentNotesLinks',
  'division.update.prop.payerMix',
  'division.update.prop.isGPOMember',
  'division.update.prop.GPOName',
  'division.update.prop.ownHealthInsurance',
  'division.update.prop.healthPlanName',
  'division.update.prop.healthPlanNetworkType',
  'division.update.prop.healthPlanMembersCount',
  'division.update.prop.pharmacyBenefitManager',
  'division.update.prop.financialLinks',
  'division.update.prop.targetPatientPopulationSize',
  'division.update.prop.affiliatedHospitalsCount',
  'division.update.prop.affiliatedOutpatientSitesCount',
  'division.update.prop.bedsCount',
  'division.update.prop.physiciansCount',
  'division.update.prop.inpatientsCount',
  'division.update.prop.outpatientsCount',
  'division.update.prop.totalPatientsCount',
  'division.update.prop.medicalServices',
  'division.update.prop.academicPartners',
  'division.update.prop.specialtyCenters',
  'division.update.prop.medicalLinks',
  'division.update.prop.healthPlanProductCategories',
  'division.update.prop.metricsDataLinks',
  'division.update.prop.GPOLink',
  'division.update.prop.isNCCNMember',
  'division.update.prop.websources',
  'division.update.prop.departmentNames',
  'division.update.prop.departmentNamesLinks',
  'account-financial-information.list',
  'account-financial-information.create',
  'account-financial-information.create.prop.*',
  'account-financial-information.update',
  'account-financial-information.update.prop.*',
  'account-financial-information.delete',
  'account-medical-information.list',
  'account-medical-information.create',
  'account-medical-information.create.prop.*',
  'account-medical-information.update',
  'account-medical-information.update.prop.*',
  'account-medical-information.delete',
  'account-payer-information.list',
  'account-payer-information.create',
  'account-payer-information.create.prop.*',
  'account-payer-information.update',
  'account-payer-information.update.prop.*',
  'account-payer-information.delete',
  'account-person.list',
  'account.update.prop.social.twitter',
  'account.update.prop.teachingStatus',
  'division.update.prop.teachingStatus',
  'division.update.prop.social.twitter',
];

DEF[Roles.AccountViewer] = [
  'account.affiliation.list',
  'account.affiliation.audit.list',
  'account.affiliation-suspect.list',
  'account.audit.list',
  'account.comment.list',
  'account.list',
  'account.detail',
  'account.job-history.list',
  'account-financial-information.list',
  'account-financial-information.detail',
  'account-medical-information.detail',
  'account-medical-information.list',
  'account-payer-information.list',
  'account-payer-information.detail',
  'account-person.list',
  'accounts.comment.list',
  'affiliation.audit.list',
  'affiliation.detail',
  'affiliation.list',
  'division.list',
  'value.list',
];

DEF[Roles.AccountAffiliationCompiler] = [
  'account.update',
  'account.comment.list',
  'account.comment.create',
  'account.affiliation.list',
  'account.affiliation.update',
  'account.affiliation-suspect.list',
  'account.affiliation-suspect.update',
  'account.affiliation.create',
  'account.affiliation.delete',
  'account-person.create',
  'division.list',
  'affiliation.detail',
  'affiliation.request',
  'affiliation.job-history.list',
  'affiliation.lfka',
  'organization.request',
  'person.listMembers',
];

DEF[Roles.AccountJTKPCompiler] = [
  'account.detail',
  'account.update',
  'account.comment.list',
  'account.comment.create',
  'account-person.list',
  'account-person.update',
  'account-person.create',
  'account-person.delete',
  'account-person.update.prop.*',
  'account.list',
  'division.list',
  'job.next',
  'job.create',
];

DEF[Roles.AccountJTKPReviewer] = [
  'account.detail',
  'account.update',
  'account.comment.list',
  'account.comment.create',
  'account-person.list',
  'account-person.update',
  'account-person.create',
  'account-person.delete',
  'account-person.update.prop.*',
  'account-person.qc',
  'account.list',
  'division.list',
  'job.next',
  'job.create',
];

DEF[Roles.ImageManager] = [
  'affiliation.all',
  'person.update',
  'person.update.prop.image',
  'person.list',
  'person.tag.list',
  'person.audit.list',
  'person.checkFindings.list',
  'person.job-history.list',
  'user.list',
  'user.create',
  'user.detail',
  'user.update',
  'user.bulkList',
  'userPool.detail',
  'userPool.list',
  'userPool.create',
  'userPool.update',
  'job.create',
  'job.list',
  'job.update',
  'job.export',
];

DEF[Roles.ImageCompiler] = ['affiliation.all', 'person.update', 'person.checkFindings.list', 'job.next'];

DEF[Roles.ImageMaintainer] = ['affiliation.all', 'person.update', 'person.checkFindings.list', 'job.next'];

DEF[Roles.ImageReviewer] = [
  'affiliation.all',
  'person.update',
  'person.update.prop.image',
  'person.imageqc',
  'person.checkFindings.list',
  'person.job-history.list',
  'job.next',
];

DEF[Roles.CtManager] = [
  'affiliation.list',
  'affiliation.detail',
  'affiliation.request',
  'affiliation.job-history.list',
  'organization.request',
  'person.create',
  'person.create.prop.*',
  'clinicalTrialProfile.list',
  'clinicalTrialProfile.update',
  'clinicalTrialProfile.updateAnyway',
  'clinicalTrialProfile.updatePeople',
  'clinicalTrialProfile.updateSites',
  'clinicalTrialProfile.bulkList',
  'clinicalTrialProfile.audit.list',
  'clinicalTrialProfile.comment.create',
  'clinicalTrialProfile.comment.list',
  'user.list',
  'user.create',
  'user.detail',
  'user.update',
  'user.bulkList',
  'userPool.detail',
  'userPool.list',
  'userPool.create',
  'userPool.update',
  'job.create',
  'job.list',
  'job.update',
  'job.export',
  'job.skip',
  'clinicalTrialSponsor.list',
  'clinicalTrialSponsor.update',
  'clinicalTrialSponsor.update.prop.*',
  'clinicalTrialSponsor.audit.list',
  'clinicalTrialSponsor.comment.list',
  'clinicalTrialSponsor.comment.create',
];

DEF[Roles.CtModerator] = [
  'affiliation.list',
  'affiliation.detail',
  'affiliation.request',
  'affiliation.job-history.list',
  'organization.request',
  'person.create',
  'person.create.prop.*',
  'clinicalTrialProfile.list',
  'clinicalTrialProfile.update',
  'clinicalTrialProfile.updateAnyway',
  'clinicalTrialProfile.updatePeople',
  'clinicalTrialProfile.updateSites',
  'clinicalTrialProfile.bulkList',
  'clinicalTrialProfile.audit.list',
  'clinicalTrialProfile.comment.create',
  'clinicalTrialProfile.comment.list',
  'job.create',
  'job.list',
  'job.update',
  'job.export',
  'job.next',
  'job.skip',
  'clinicalTrialSponsor.list',
  'clinicalTrialSponsor.update',
  'clinicalTrialSponsor.update.prop.*',
  'clinicalTrialSponsor.audit.list',
  'clinicalTrialSponsor.comment.list',
  'clinicalTrialSponsor.comment.create',
];

DEF[Roles.CtCompiler] = [
  'affiliation.list',
  'affiliation.detail',
  'affiliation.request',
  'affiliation.job-history.list',
  'organization.request',
  'person.create',
  'person.create.prop.*',
  'person.update',
  // have a limited set to do small corrections
  'person.update.prop.firstName',
  'person.update.prop.middleName',
  'person.update.prop.lastName',
  'person.update.prop.nicknames',
  'person.update.prop.countryWorkflow',
  'person.update.prop.prefixes',
  'person.update.prop.suffix',
  'person.update.prop.externalIds.npi',
  'person.update.optional.prop.externalIds.npi',
  'person.update.prop.externalIds.sln',
  'person.update.prop.affiliations',
  'person.update.prop.affiliations.position',
  'person.update.prop.cvLinks',
  'person.update.prop.originalFirstName',
  'person.update.prop.originalMiddleName',
  'person.update.prop.originalLastName',
  'person.update.prop.originalFullName',
  'person.update.prop.alternativeNames',
  'person.update.prop.emails',
  'person.update.prop.technicalProjects',
  'clinicalTrialProfile.update',
  'clinicalTrialProfile.updatePeople',
  'clinicalTrialProfile.updateSites',
  'clinicalTrialProfile.comment.create',
  'clinicalTrialProfile.comment.list',
  'job.next',
];

DEF[Roles.CtTimingCompiler] = [
  'affiliation.list',
  'clinicalTrialProfile.update',
  'clinicalTrialProfile.comment.create',
  'clinicalTrialProfile.comment.list',
  'job.next',
];

DEF[Roles.CtPeopleCompiler] = [
  'affiliation.list',
  'affiliation.detail',
  'affiliation.request',
  'affiliation.job-history.list',
  'organization.request',
  'person.create',
  'person.create.prop.*',
  'person.update',
  // have a limited set to do small corrections
  'person.update.prop.firstName',
  'person.update.prop.middleName',
  'person.update.prop.lastName',
  'person.update.prop.nicknames',
  'person.update.prop.countryWorkflow',
  'person.update.prop.prefixes',
  'person.update.prop.suffix',
  'person.update.prop.externalIds.npi',
  'person.update.optional.prop.externalIds.npi',
  'person.update.prop.externalIds.sln',
  'person.update.prop.affiliations',
  'person.update.prop.affiliations.position',
  'person.update.prop.cvLinks',
  'person.update.prop.originalFirstName',
  'person.update.prop.originalMiddleName',
  'person.update.prop.originalLastName',
  'person.update.prop.originalFullName',
  'person.update.prop.alternativeNames',
  'person.update.prop.emails',
  'person.update.prop.technicalProjects',
  'clinicalTrialProfile.update',
  'clinicalTrialProfile.updatePeople',
  'clinicalTrialProfile.comment.create',
  'clinicalTrialProfile.comment.list',
  'job.next',
];

DEF[Roles.CtSiteCompiler] = [
  'affiliation.list',
  'affiliation.detail',
  'affiliation.request',
  'affiliation.job-history.list',
  'organization.request',
  'clinicalTrialProfile.update',
  'clinicalTrialProfile.updateSites',
  'clinicalTrialProfile.comment.create',
  'clinicalTrialProfile.comment.list',
  'job.next',
];

DEF[Roles.CtSponsorCompiler] = [
  'clinicalTrialSponsor.list',
  'clinicalTrialSponsor.comment.list',
  'clinicalTrialSponsor.comment.create',
  'affiliation.list',
  'affiliation.detail',
  'affiliation.job-history.list',
  'association.list',
  'association.detail',
  'person.list',
  'person.detail',
  'job.next',
];

DEF[Roles.CtReviewer] = [
  'affiliation.list',
  'affiliation.request',
  'affiliation.job-history.list',
  'organization.request',
  'person.create',
  'person.create.prop.*',
  'person.update',
  // have a limited set to do small corrections
  'person.update.prop.firstName',
  'person.update.prop.middleName',
  'person.update.prop.lastName',
  'person.update.prop.nicknames',
  'person.update.prop.countryWorkflow',
  'person.update.prop.prefixes',
  'person.update.prop.suffix',
  'person.update.prop.externalIds.npi',
  'person.update.optional.prop.externalIds.npi',
  'person.update.prop.externalIds.sln',
  'person.update.prop.affiliations',
  'person.update.prop.affiliations.position',
  'person.update.prop.cvLinks',
  'person.update.prop.originalFirstName',
  'person.update.prop.originalMiddleName',
  'person.update.prop.originalLastName',
  'person.update.prop.originalFullName',
  'person.update.prop.alternativeNames',
  'person.update.prop.emails',
  'person.update.prop.technicalProjects',
  'person.audit.list',
  'person.qc',
  'clinicalTrialProfile.update',
  'clinicalTrialProfile.updatePeople',
  'clinicalTrialProfile.updateSites',
  'clinicalTrialProfile.audit.list',
  'clinicalTrialProfile.comment.create',
  'clinicalTrialProfile.comment.list',
  'clinicalTrialProfile.qc',
  'job.next',
  'job.skip',
];

DEF[Roles.CtSponsorReviewer] = [
  'clinicalTrialSponsor.list',
  'clinicalTrialSponsor.audit.list',
  'clinicalTrialSponsor.qc',
  'clinicalTrialSponsor.comment.list',
  'clinicalTrialSponsor.comment.create',
  'affiliation.list',
  'affiliation.detail',
  'affiliation.job-history.list',
  'association.list',
  'association.detail',
  'person.list',
  'person.detail',
  'job.next',
];

DEF[Roles.CtViewer] = [
  'clinicalTrialProfile.list',
  'clinicalTrialProfile.audit.list',
  'clinicalTrialProfile.comment.list',
  'clinicalTrialProfile.viewSites',
  'clinicalTrialProfile.viewPeople',
  'clinicalTrialSponsor.list',
  'clinicalTrialSponsor.audit.list',
  'clinicalTrialSponsor.comment.list',
];

DEF[Roles.DomainAdmin] = [
  'domain.list',
  'domain.bulkList',
  'domain.bulkListCompliantCountries',
  'domain.create',
  'domain.create.prop.*',
  'domain.update',
  'domain.saveAnyway',
  'domain.update.prop.*',
  'domain.delete',
  'domain.audit.list',
  'domain.comment.list',
  'domain.comment.create',
  'user.list',
  'user.create',
  'user.detail',
  'user.update',
  'user.bulkList',
  'userPool.detail',
  'userPool.list',
  'userPool.create',
  'userPool.update',
  'job.create',
  'job.list',
  'job.update',
  'job.export',
  'affiliation.detail',
];

DEF[Roles.DomainManager] = [
  'domain.list',
  'domain.bulkList',
  'domain.create',
  'domain.create.prop.*',
  'domain.update',
  'domain.saveAnyway',
  'domain.update.prop.*',
  'domain.delete',
  'domain.audit.list',
  'domain.comment.list',
  'domain.comment.create',
  'user.list',
  'user.create',
  'user.detail',
  'user.update',
  'user.bulkList',
  'userPool.detail',
  'userPool.list',
  'userPool.create',
  'userPool.update',
  'job.create',
  'job.list',
  'job.update',
  'job.export',
  'affiliation.detail',
];

DEF[Roles.DomainCompiler] = [
  'domain.detail',
  'domain.audit.list',
  'domain.comment.list',
  'domain.comment.create',
  'association.detail',
  'affiliation.detail',
  'job.next',
];

DEF[Roles.DomainReviewer] = [
  'domain.qc',
  'domain.detail',
  'domain.update',
  'domain.update.prop.*',
  'domain.audit.list',
  'domain.comment.list',
  'domain.comment.create',
  'association.detail',
  'affiliation.detail',
  'job.next',
];

DEF[Roles.DomainViewer] = [
  'domain.list',
  'domain.detail',
  'domain.audit.list',
  'domain.comment.list',
  'association.detail',
  'affiliation.detail',
];

DEF[Roles.CommitteeManager] = [
  'committee.list',
  'committee.create',
  'committee.create.prop.*',
  'committee.update',
  'committee.update.prop.*',
  'committee.delete',
  'committee.audit.list',
  'committee.bulkList',
  'committee.saveAnyway',
  'committee.export',
  'committee.comment.list',
  'committee.comment.create',
  'committee.person.list',
  'committee.person.create',
  'committee.person.delete',
  'committee.person.update',
  'committee.job-history.list',
  'user.list',
  'user.create',
  'user.detail',
  'user.update',
  'user.bulkList',
  'userPool.detail',
  'userPool.list',
  'userPool.create',
  'userPool.update',
  'job.create',
  'job.list',
  'job.update',
  'job.export',
  'person.committee.list',
];

DEF[Roles.CommitteeCompiler] = [
  'committee.list',
  'committee.create',
  'committee.create.prop.*',
  'committee.update',
  'committee.update.prop.name',
  'committee.update.prop.originalName',
  'committee.update.prop.websources',
  'committee.update.prop.website',
  'committee.update.prop.country',
  'committee.update.prop.format',
  'committee.update.prop.type',
  'committee.update.prop.topic',
  'committee.update.prop.therapeuticAreas',
  'committee.update.prop.status',
  'committee.update.prop.statusWebSource',
  'committee.update.prop.connections',
  'committee.comment.list',
  'committee.comment.create',
  'committee.person.list',
  'job.next',
  'person.committee.list',
];

DEF[Roles.CommitteeKPCompiler] = [
  'committee.list',
  'committee.update',
  'committee.comment.list',
  'committee.comment.create',
  'committee.person.list',
  'committee.person.create',
  'committee.person.delete',
  'committee.person.update',
  'job.next',
  'person.committee.list',
];

DEF[Roles.CommitteeViewer] = [
  'committee.list',
  'committee.comment.list',
  'committee.person.list',
  'person.committee.list',
];

DEF[Roles.CommitteePrimaryResearchCompiler] = [
  'committee.list',
  'committee.create',
  'committee.create.prop.*',
  'committee.update',
  'committee.update.prop.primaryResearch',
  'committee.update.prop.name',
  'committee.update.prop.originalName',
  'committee.update.prop.websources',
  'committee.update.prop.website',
  'committee.update.prop.country',
  'committee.update.prop.format',
  'committee.update.prop.type',
  'committee.update.prop.topic',
  'committee.update.prop.therapeuticAreas',
  'committee.update.prop.status',
  'committee.update.prop.statusWebSource',
  'committee.update.prop.connections',
  'committee.comment.list',
  'committee.comment.create',
  'committee.person.list',
  'committee.person.update',
  'committee.person.create',
  'committee.person.delete',
  'person.committee.list',
];

DEF[Roles.DocumentManager] = [
  'document.list',
  'document.create',
  'document.create.prop.*',
  'document.update',
  'document.update.prop.*',
  'document.delete',
  'document.audit.list',
  'document.saveAnyway',
  'document.export',
  'document.comment.list',
  'document.comment.create',
  'document.job-history.list',
  'document.bulkList',
  'document.person.list',
  'document.person.create',
  'document.person.delete',
  'document.person.update',
  'user.list',
  'user.create',
  'user.detail',
  'user.update',
  'user.bulkList',
  'userPool.detail',
  'userPool.list',
  'userPool.create',
  'userPool.update',
  'job.create',
  'job.list',
  'job.update',
  'job.export',
];

DEF[Roles.DocumentCompiler] = [
  'document.list',
  'document.create',
  'document.create.prop.*',
  'document.update',
  'document.update.prop.title',
  'document.update.prop.type',
  'document.update.prop.country',
  'document.update.prop.url',
  'document.update.prop.format',
  'document.update.prop.documentVersion',
  'document.update.prop.previousVersion',
  'document.update.prop.sourceUrl',
  'document.update.prop.publisher',
  'document.update.prop.publishedDate',
  'document.update.prop.effectiveStartDate',
  'document.update.prop.effectiveEndDate',
  'document.update.prop.plannedReviewDate',
  'document.update.prop.connectionType',
  'document.update.prop.connections',
  'document.update.prop.status',
  'document.comment.list',
  'document.comment.create',
  'job.next',
];

DEF[Roles.DocumentKPCompiler] = [
  'document.list',
  'document.update',
  'document.comment.list',
  'document.comment.create',
  'document.person.list',
  'document.person.create',
  'document.person.delete',
  'document.person.update',
  'job.next',
];

DEF[Roles.DocumentViewer] = ['document.list', 'document.comment.list'];

DEF[Roles.MedInsManager] = [
  'user.list',
  'user.create',
  'user.detail',
  'user.update',
  'user.bulkList',
  'userPool.detail',
  'userPool.list',
  'userPool.create',
  'userPool.update',
  'job.create',
  'job.list',
  'job.update',
  'medical-insights-profile.bulk-list',
  'medical-insights-profile.detail',
  'medical-insights-profile.update',
  'medical-insights-profile.audit.list',
  'medical-insights-profile.info.list',
  'medical-insights-profile.job-history.list',
  'medical-insights-profile.update.prop.meta.status',
];

DEF[Roles.MedInsCompiler] = ['medical-insights-profile.detail', 'job.next'];

DEF[Roles.MedInsTrainingManager] = ['medical-insights-profile.training'];

DEF[Roles.MedInsReviewer] = [
  'medical-insights-profile.detail',
  'medical-insights-profile.update',
  'medical-insights-profile.audit.list',
  'medical-insights-profile.info.list',
  'medical-insights-profile.job-history.list',
  'medical-insights-profile.qc',
];

DEF[Roles.Viewer] = [
  'affiliation.list',
  'affiliation.detail',
  'person.list',
  'person.tag.list',
  'person.audit.list',
  'person.committee.list',
  'event.list',
  'event.audit.list',
  'eventSeries.list',
  'session.list',
  'contribution.list',
  'contributor.list',
  'association.list',
  'association.search',
  'association.audit.list',
  'subassociation.list',
  'membership.list',
  'profile.list',
  'profile.origin',
  'report.profile',
  'committee.person.list',

  // Global Viewer role should inherit from other viewer roles
  ...DEF[Roles.CtViewer],
  ...DEF[Roles.JobViewer],
  ...DEF[Roles.EventViewer],
  ...DEF[Roles.DomainViewer],
  ...DEF[Roles.PersonViewer],
  ...DEF[Roles.AccountViewer],
  ...DEF[Roles.ProfileViewer],
  ...DEF[Roles.DocumentViewer],
  ...DEF[Roles.CommitteeViewer],
  ...DEF[Roles.GuidelineViewer],
  ...DEF[Roles.SentimentViewer],
  ...DEF[Roles.AffiliationViewer],
  ...DEF[Roles.AssociationViewer],
  ...DEF[Roles.OrganizationViewer],
];

DEF[Roles.SponsorManager] = ['sponsor.list', 'sponsor.create', 'sponsor.detail', 'sponsor.update', 'sponsor.delete'];

export const PERMISSIONS = DEF;
