import { Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { from, Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { NgbDateStruct, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router';

import { ACL } from '../../../shared/acl/acl.service';
import { Event, REQUIRES_COMPILATION_TYPES } from '../event';
import { EventAPI } from '../api.service';
import { EventSeries } from '../../../event-series/shared/event-series';
import { EventSeriesAPI } from '../../../event-series/shared/api.service';
import { AddressComponent } from '../../../shared/components';

import { ValueType } from '../../../shared/enum/value-type.enum';
import { ValueAPI } from '../../../shared/services/value/value-api.service';
import { Value } from '../../../shared/services/value/value';
import { AssociationAPI } from 'app/association/shared/association-api.service';
import { AddressInternalSearchConfig } from 'app/shared/components/address/internal-search-config';
import { Utils } from '../../../common/utils';
import { IMultiSelectOption, IMultiSelectSettings } from '../../../shared/components/multiselect-dropdown/types';
import { AccountAPI } from '../../../account/shared/account-api.service';
import { CommitteeAPI } from '../../../committee/shared/committee-api.service';
import { SponsorAPI } from '../../../sponsors/shared/api.service';
import { ImageAPI } from '../../../shared/services/image/image.service';
import { Roles } from '../../../shared/acl/roles';
import { Organization } from '../../../organizations/shared/organization';
import { Affiliation } from '../../../affiliation/shared/affiliation';
import { OrganizationCreateRequestModalService } from '../../../organizations/shared/create-request-modal/organization-create-request-modal.service';

const CLS_MULTISELECT_BTN = 'btn btn-sm btn-secondary';

@Component({
  selector: 'dirt-event-form',
  templateUrl: 'form.component.html',
  styleUrls: ['form.component.scss'],
  exportAs: 'frmEvent',
})
export class EventFormComponent implements OnInit, OnChanges, OnDestroy {
  types: Value[] = [];
  reaches: string[] = ['National', 'International', 'Regional', 'Local'];

  categories: Value[] = [];
  products: Value[];

  // Therapeutic Areas
  therapeuticAreas: IMultiSelectOption[] = [];
  therapeuticAreaSettings: IMultiSelectSettings = {
    buttonClasses: CLS_MULTISELECT_BTN,
    enableSearch: false,
    dynamicTitleMaxItems: 5,
  };

  // Area Tags
  areas: IMultiSelectOption[] = [];
  areaSettings: IMultiSelectSettings = {
    buttonClasses: CLS_MULTISELECT_BTN,
    enableSearch: true,
    dynamicTitleMaxItems: 5,
  };

  // Sponsors
  sponsors: (IMultiSelectOption & { nameLower: string })[] = [];
  sponsorsFiltered: (IMultiSelectOption & { nameLower: string })[] = [];
  sponsorsSettings: IMultiSelectSettings = {
    buttonClasses: CLS_MULTISELECT_BTN,
    enableSearch: true,
    searchRenderLimit: 100,
    isLazyLoad: true,
    isLazyLoadWithSelected: true,
    dynamicTitleMaxItems: 5,
  };

  projects = [];
  projectsSettings: IMultiSelectSettings = {
    buttonClasses: CLS_MULTISELECT_BTN,
    checkedStyle: 'fontawesome',
    enableSearch: true,
    dynamicTitleMaxItems: 5,
  };

  addOrganizerType: 'AFFILIATION' | 'ASSOCIATION' | 'ACCOUNT' | 'COMMITTEE' = null;

  wndw: Window = window;

  @Input('event')
  model: Event = new Event();

  @Input()
  seriesName?: string = null;

  @Input('hashtag')
  hashtag: any;

  @Input()
  currentJobType: string | null = null;

  @ViewChild(NgForm, { static: true })
  ngForm: NgForm;

  @ViewChild(AddressComponent, { static: false })
  addressComponent: AddressComponent;

  @ViewChild('logoPreview', { static: false })
  logoPreview: ElementRef;

  searchSeries: any;
  isLoadingSeries = false;
  internalSearchConfig: AddressInternalSearchConfig;
  nonCompliantDomains: string[] = [];
  longDash = Utils.longDash;
  twitterFieldName = 'twitterHash';

  languages$: Observable<Value[]>;

  nonCompliantDomainType: string = ValueType.NonCompliantDomainsActivities;

  private destroy$: Subject<boolean> = new Subject();

  isLogoPreviewLoading = true;
  hasLogoPreviewError = false;

  constructor(
    private svcAcl: ACL,
    private svcEvent: EventAPI,
    private svcSeries: EventSeriesAPI,
    private svcValue: ValueAPI,
    private svcAssociation: AssociationAPI,
    private activatedRoute: ActivatedRoute,
    private svcAccount: AccountAPI,
    private svcCommittee: CommitteeAPI,
    private svcSponsor: SponsorAPI,
    private svcImage: ImageAPI,
    private svcOrganizationCreateRequestModal: OrganizationCreateRequestModalService
  ) {
    this.onRequestOrganizationCreation = this.onRequestOrganizationCreation.bind(this);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['model']) {
      this.normalizeStartEndDate();
    }

    if (changes.model && changes.model.currentValue) {
      this.model.phone = Object.keys(this.model.phone || {}).length > 0 ? this.model.phone : ({} as any);
      this.model.additionalWebSources = this.model.additionalWebSources || [];
    }
  }

  ngOnInit(): void {
    this.loadTherapeuticalAreas();
    this.loadSponsors();
    this.loadAreas();
    this.loadProjects();
    this.loadCategories();
    this.loadProducts();
    this.loadTypes();
    this.internalSearchConfig = {
      label: 'Association',
      searchPlaceHolder: 'Search associations...',
      onSearch: this.onSearch.bind(this),
    };

    this.activatedRoute.queryParams.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      const prepopulateType = params['type'];
      const prepopulateId = params['id'];
      if (prepopulateType && prepopulateId) {
        this.prepopulateOrganizer(prepopulateType, prepopulateId);
      }
    });

    this.searchSeries = (text$: Observable<string>) =>
      text$.pipe(
        debounceTime(600),
        distinctUntilChanged(),
        tap(() => (this.isLoadingSeries = true)),
        switchMap((term) => this.svcSeries.find(term as any, 10).pipe(catchError(() => of([])))),
        map((result: EventSeries[]) => {
          const seriesIds = this.model.series.map((s) => s.id);
          return result.filter((item) => !seriesIds.includes(item.id));
        }),
        tap(() => (this.isLoadingSeries = false))
      );
    if (!this.model.hashtags) {
      this.model.hashtags = [];
    }

    this.languages$ = this.svcValue.find(ValueType.Language, Number.MAX_SAFE_INTEGER, 0, '+title');
  }

  ngAfterViewInit(): void {
    if (!this.model.logo) {
      return;
    }
    this.handleLogoPreviewURLChange(this.model.logo);
  }

  ngOnDestroy(): void {
    this.destroy$.next(false);
    this.destroy$.complete();
  }

  onSearch(term: string) {
    return this.svcAssociation
      .search(term, undefined, undefined, undefined, 10)
      .toPromise()
      .catch(() => []);
  }

  isValid(): boolean {
    let isValid = this.ngForm.form.valid;

    if (this.addressComponent) {
      isValid = isValid && this.addressComponent.isValid();
    }

    if (!this.model.parent) {
      isValid = isValid && !!this.model.organizers?.length;
      isValid = isValid && !!this.model.series?.length;
    }

    return isValid;
  }

  getValue(): Event {
    const model = Object.assign({}, this.model); // TODO: no clone, return domain object (for all such forms); use ngOnChanges+change handlers for helper variables

    if (this.addressComponent) {
      model.address = this.addressComponent.getAddress();
    }

    model.startDate = this.ngbStructToDate(model.startDate);
    model.endDate = this.ngbStructToDate(model.endDate);

    return model;
  }

  isFieldEditable(field: string, checkQc?: boolean): boolean {
    const module = this.model.parent ? 'session' : 'event';
    const prefix = this.model.id ? 'update' : 'create';
    const baseRes = this.svcAcl.hasCredential(`${module}.${prefix}.prop.${field}`, this.currentJobType);
    if (checkQc && (this.model.qc || {})[field]) {
      // if we have QC, we need to be able to correct (i.e. create permissions), otherwise it makes no sense
      return baseRes || this.svcAcl.hasCredential(`${module}.create.prop.${field}`, this.currentJobType);
    } else {
      return baseRes;
    }
  }

  canViewDetails(ofWhat): boolean {
    return this.svcAcl.hasCredential(ofWhat + '.list');
  }

  onSeriesAdd(selected: NgbTypeaheadSelectItemEvent): void {
    this.model.series.push(selected.item);
  }

  onSeriesRemove(item: EventSeries): void {
    const idx = this.model.series.findIndex((series) => series.id === item.id);

    if (idx > -1) {
      this.model.series.splice(idx, 1);
    }
  }

  formatSeriesInput(val: any): string {
    return '';
  }

  formatSeriesOutput(val: any): string {
    return val.name;
  }

  private loadTherapeuticalAreas(): void {
    this.svcValue
      .find(ValueType.TherapeuticArea, Number.MAX_SAFE_INTEGER)
      .toPromise()
      .then((data) => {
        this.therapeuticAreas = data.map((o) => ({
          id: o.code,
          name: o.title,
          disabled: false,
        }));
      });
  }

  private loadAreas(): void {
    this.svcValue
      .find(ValueType.Area, Number.MAX_SAFE_INTEGER, 0, '+title')
      .toPromise()
      .then((data) => {
        this.areas = data.map((o) => ({
          id: o.code,
          name: o.title,
          disabled: false,
        }));
      });
  }

  private loadSponsors(): void {
    this.svcSponsor.getSponsors().subscribe((data) => {
      this.sponsors = data.map((o) => ({
        id: o.companyId,
        name: o.normalizedName,
        disabled: !!o.disabled,
        nameLower: o.normalizedName?.toLowerCase(),
      }));
      return this.filterSponsors({ filter: null }); // init display
    });
  }

  filterSponsors({ filter }) {
    let selectedSponsors = [];
    if (this.model.sponsors?.length) {
      selectedSponsors = this.model.sponsors.map(
        (s) => this.sponsors.find((sp) => sp.id === s) || { id: s, name: s, nameLower: s, disabled: true }
      );
    }
    if (!(filter?.length >= 3)) {
      this.sponsorsFiltered = selectedSponsors;
    } else {
      const filterLower = filter.toLowerCase();
      this.sponsorsFiltered = [
        ...selectedSponsors,
        ...this.sponsors.filter((s) => s.nameLower.indexOf(filterLower) >= 0).slice(0, 100),
      ];
    }
  }

  private normalizeStartEndDate() {
    this.model.startDate = this.dateToNgbStruct(this.model.startDate);
    this.model.endDate = this.dateToNgbStruct(this.model.endDate);
  }

  private dateToNgbStruct(date: any): NgbDateStruct {
    if (!date) {
      return;
    }
    // const dt = new Date(date);
    return {
      year: date.year,
      month: date.month,
      day: date.day,
    };
  }

  private ngbStructToDate(date: NgbDateStruct): string {
    if (!date) {
      return;
    }

    const dt = Date.UTC(date.year, date.month - 1, date.day);
    return new Date(dt).toISOString();
  }

  private sortByName(array: IMultiSelectOption[]): void {
    array.sort((a, b) => a.name.localeCompare(b.name, 'en'));
  }

  loadProjects() {
    this.svcValue
      .find(ValueType.Project, Number.MAX_SAFE_INTEGER, 0, '+title')
      .toPromise()
      .then((data) => {
        this.projects = data.map((o) => ({
          id: o.code,
          name: o.title,
          disabled: o.disabled,
        }));
      });
  }

  loadCategories() {
    this.svcValue
      .find(ValueType.Category, Number.MAX_SAFE_INTEGER, 0, '+title')
      .toPromise()
      .then((data) => {
        this.categories = data;
      });
  }

  loadProducts() {
    this.svcValue.find(ValueType.Product, Number.MAX_SAFE_INTEGER, 0).subscribe((data) => {
      this.products = [
        ...data.filter((d) => 'LFTA' === d.value), // make sure it's first
        ...data.filter((d) => 'LFTA' !== d.value),
      ];
    });
  }

  loadTypes() {
    this.svcValue
      .find(ValueType.EventType, Number.MAX_SAFE_INTEGER)
      .toPromise()
      .then((data) => {
        this.types = data;
      });
  }

  AddHashTag(): void {
    if (
      this.hashtag &&
      this.hashtag !== '' &&
      !this.hashtag.includes('#') &&
      !this.model.hashtags.includes(this.hashtag)
    ) {
      this.model.hashtags.push(this.hashtag);
      this.hashtag = '';
    }
  }

  RemoveHashTag(index: number): void {
    this.model.hashtags.splice(index, 1);
  }

  async onUseParentWebSource() {
    if (!this.model.parent) {
      return;
    }
    const fullEvent: Event = await this.svcEvent.findById(this.model.parent).toPromise();
    if (fullEvent && fullEvent.webSource) {
      this.model.webSource = fullEvent.webSource;
    }
  }

  onRequestOrganizationCreation(affiliation?: Affiliation): Observable<Affiliation> {
    if (affiliation?.id) {
      return;
    }

    return from(this.svcOrganizationCreateRequestModal.open(undefined, this.model.ownerProduct)).pipe(
      map((res: { organization: Organization; affiliation: Affiliation & { organizationId?: string } }) => {
        res.affiliation.organizationId = res.organization._id;
        return res.affiliation;
      })
    );
  }

  onOrganizerAdded(type: 'AFFILIATION' | 'ASSOCIATION' | 'ACCOUNT' | 'COMMITTEE' | 'NO_ORGANIZER', target) {
    if (!this.model.organizers) {
      this.model.organizers = [];
    }
    const id = target?.id || target?._id; // (however we get results)
    if ('AFFILIATION' === type) {
      if (this.model.organizers.find((org) => org.affiliationId === id)) {
        this.wndw.alert('Already in list');
        return;
      }
      const organizer = { type, affiliationId: id, affiliation: target } as any;
      if (target.organizationId) {
        organizer.organizationId = target.organizationId;
      }
      this.model.organizers.push(organizer);
    } else if ('ASSOCIATION' === type) {
      if (this.model.organizers.find((org) => org.associationId === id)) {
        this.wndw.alert('Already in list');
        return;
      }
      this.model.organizers.push({
        type,
        associationId: id,
        association: target,
      });
    } else if ('ACCOUNT' === type) {
      if (this.model.organizers.find((org) => org.accountId === id)) {
        this.wndw.alert('Already in list');
        return;
      }
      this.model.organizers.push({
        type,
        accountId: id,
        account: target,
      });
    } else if ('COMMITTEE' === type) {
      if (this.model.organizers.find((org) => org.committeeId === id)) {
        this.wndw.alert('Already in list');
        return;
      }
      this.model.organizers.push({
        type,
        committeeId: id,
        committee: target,
      });
    } else if ('NO_ORGANIZER' === type) {
      this.model.organizers.push({
        type,
      });
    }
  }
  onRemoveOrganizer(index: number) {
    if (!this.wndw.confirm('Sure to delete organizer "' + this.organizerTitle(this.model.organizers[index]) + '"?')) {
      return;
    }
    this.model.organizers = this.model.organizers.filter((o, i) => i !== index);
  }

  organizerTitle(o) {
    if ('AFFILIATION' === o.type) {
      return o.affiliation
        ? o.affiliation.name + (o.affiliation.department ? ' - ' + o.affiliation.department : '')
        : 'Affiliation ' + o.affiliationId;
    } else if ('ORGANIZATION' === o.type) {
      return o.organization ? o.organization.fullName : 'Organization ' + o.organizationId;
    } else if ('ASSOCIATION' === o.type) {
      return o.association ? o.association.name : 'Association ' + o.associationId;
    } else if ('ACCOUNT' === o.type) {
      return o.account ? o.account.name : 'Account ' + o.accountId;
    } else if ('COMMITTEE' === o.type) {
      return o.committee ? o.committee.name : 'Committee ' + o.committeeId;
    } else if ('NO_ORGANIZER' === o.type) {
      return 'No organizer';
    } else {
      console.error('Wrong organizer type ' + o.type);
      return '?';
    }
  }
  hasNoOrganizer(): boolean {
    return !!this.model.organizers?.find((o) => 'NO_ORGANIZER' === o.type);
  }
  hasRealOrganizer(): boolean {
    return !!this.model.organizers?.find((o) => 'NO_ORGANIZER' !== o.type);
  }

  resetForm(parentEvent?: Event): void {
    const prevEvent = JSON.parse(JSON.stringify(this.model));

    this.model = new Event();

    this.model.projectNames = prevEvent.projectNames; // keep the previous projects
    this.model.parent = prevEvent.parent;
    if (prevEvent?.sessionDate) {
      this.model.sessionDate = new Date(prevEvent.sessionDate.split('T')[0]);
    } else if (parentEvent) {
      this.model.sessionDate = new Date(
        Date.UTC(parentEvent.startDate.year, parentEvent.startDate.month - 1, parentEvent.startDate.day)
      );
    }
  }

  obtainCMECredits() {
    if (!this.model.cmeCredits) {
      this.model.cmeCredits = {};
    }
    return this.model.cmeCredits;
  }

  onNumberOfCMECreditsChange(value: string) {
    if (!value) {
      return;
    }
    this.obtainCMECredits().status = 'YES_RELEASED'; // autoset in case it wasn't set already
  }

  trackByIndex(index: number): number {
    return index;
  }

  onTypeChange(newType) {
    this.model.requiresCompilation = REQUIRES_COMPILATION_TYPES.includes(newType);
  }

  private prepopulateOrganizer(prepopulateType: 'account' | 'committee', prepopulateId: string) {
    if (prepopulateType === 'account') {
      this.svcAccount.findById(prepopulateId).subscribe((data) => {
        this.onOrganizerAdded('ACCOUNT', data);
      });
    } else {
      this.svcCommittee.findById(prepopulateId).subscribe((data) => {
        this.onOrganizerAdded('COMMITTEE', data);
      });
    }
  }

  onLogoPreviewLoadFailure(): void {
    this.hasLogoPreviewError = true;
    this.isLogoPreviewLoading = false;
    this.logoPreview.nativeElement.src = 'assets/blank-profile.png';
  }

  handleLogoPreviewURLChange(url: string): void {
    if (!url) {
      // reset image preview
      this.isLogoPreviewLoading = false;
      this.hasLogoPreviewError = false;
      this.logoPreview.nativeElement.src = 'assets/blank-profile.png';
      return;
    }

    this.svcImage.getPreview(url).subscribe({
      next: (data) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          // Check image size
          const image = new Image();
          image.onload = () => {
            if (image.width < 200 && image.height < 200) {
              this.onLogoPreviewLoadFailure();
              return;
            }

            this.hasLogoPreviewError = false;
            this.isLogoPreviewLoading = true;
            this.logoPreview.nativeElement.src = reader.result as string;
          };

          image.onerror = () => {
            this.onLogoPreviewLoadFailure();
          };

          image.src = reader.result as string;
        };

        reader.readAsDataURL(data);
      },
      error: () => {
        this.onLogoPreviewLoadFailure();
      },
    });
  }

  isEventManager() {
    return this.svcAcl.hasRole(Roles.Admin) || this.svcAcl.hasRole(Roles.EventManager);
  }
}
