import { Component, EventEmitter, Input, Output } from '@angular/core';
import { InternalDuplicatesResponse } from '../api.service';
import { Job } from '../../../jobs/shared/job';

export type ExpandedEvent = { id: string; expanded: boolean };
export type SelectedEvent = { id: string; selected: boolean };

export type OrganizationTreeLeaf = {
  id: string;
  title: string;
  url?: string;
  /** Marks the root of the organization component tree */
  isRoot?: boolean;
  isUtc?: boolean;
  isDraft?: boolean;
  isMarkedForLater?: boolean;
  /** is not from same root org */
  isAlien?: boolean;
  parents?: string[];
  childrenInProgress?: string[];
  childrenMarkedForLater?: string[];
  isMarkedForDeletion?: boolean;
};

@Component({
  selector: 'dirt-organization-tree-part',
  templateUrl: 'tree-part.component.html',
  styleUrls: ['tree-part.component.scss'],
})
export class OrganizationTreePartComponent {
  @Input()
  leaf: OrganizationTreeLeaf;

  @Input()
  /** ID of the parent leaf in the tree */
  parentId?: string;

  @Input()
  childrenMap: { [parentId: string]: OrganizationTreeLeaf[] };
  @Input()
  expandedMap: { [id: string]: boolean };
  @Input()
  internalDuplicatesMap: InternalDuplicatesResponse['duplicates'];
  @Input()
  colorMap: Record<string, string>;
  @Input()
  multiSelectMap: { [id: string]: boolean };
  @Input()
  singleSelectHolder: { id: string }; // (can change the ID on the fly)
  @Input()
  currentJob?: Job = null;

  @Output()
  toggleExpanded: EventEmitter<ExpandedEvent> = new EventEmitter(); // when expanded, load children
  @Output()
  toggleMultiselect: EventEmitter<SelectedEvent> = new EventEmitter();
  @Output()
  changeSingleSelect: EventEmitter<{ id: string }> = new EventEmitter();
  @Output()
  jumpToOrg: EventEmitter<{ id: string }> = new EventEmitter();

  doToggleExpanded() {
    if (!this.expandedMap) {
      return console.warn('No expandedMap');
    }
    this.expandedMap[this.leaf.id] = !this.expandedMap[this.leaf.id];
    this.toggleExpanded.emit({ id: this.leaf.id, expanded: this.expandedMap[this.leaf.id] });
  }
  doToggleMultiselect() {
    if (!this.multiSelectMap) {
      return console.warn('No multiSelectMap');
    }
    this.multiSelectMap[this.leaf.id] = !this.multiSelectMap[this.leaf.id];
    this.toggleMultiselect.emit({ id: this.leaf.id, selected: this.multiSelectMap[this.leaf.id] });
  }
  doSingleSelect() {
    if (!this.singleSelectHolder) {
      return console.warn('No singleSelectHolder');
    }
    this.singleSelectHolder.id = this.leaf.id;
    this.changeSingleSelect.emit(this.singleSelectHolder);
  }

  childrenEntries(sort: boolean = true): OrganizationTreeLeaf[] {
    if (!this.expandedMap[this.leaf.id]) {
      return [];
    }
    if (!this.childrenMap) {
      console.warn('No childrenMap');
    }
    const res = this.childrenMap?.[this.leaf.id] || [];
    if (sort) {
      res.sort((c1, c2) => (c1.title || '').localeCompare(c2.title || ''));
    }
    return res;
  }

  tracker(index: number, item: { id: string }): string {
    // don't redo subtrees when we can help it
    return item.id;
  }

  shortDisplayName(longDisplayName: string): string {
    // match colors again
    if (longDisplayName && longDisplayName.indexOf(' (') > 0) {
      return longDisplayName.substring(0, longDisplayName.indexOf(' ('));
    } else {
      return longDisplayName;
    }
  }

  getLeafHint(): string | void {
    if (this.leaf.isAlien) {
      return 'Organization primary parent is not part of the tree';
    }

    if (this.isInTreeAlien()) {
      return 'Organization primary parent is located elsewhere in the tree';
    }
  }

  isInTreeAlien(): boolean {
    return this.parentId && this.leaf.parents?.[0] !== this.parentId;
  }
}
