import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { firstValueFrom, of } from 'rxjs';
import { tap, concatMap, take } from 'rxjs/operators';

import { ACL } from '../../shared/acl/acl.service';
import { Auth } from '../../shared/services/auth/auth.service';
import { Committee } from '../shared/committee';
import { CommitteeAPI } from '../shared/committee-api.service';
import { CommitteeMetaStatus } from '../shared/constant/meta-status.enum';
import { Job } from '../../jobs/shared/job';
import { JobsAPI } from '../../jobs/shared/api.service';
import { User } from '../../user/shared/user';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'dirt-committee-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss'],
})
export class CommitteeDetailComponent implements OnInit {
  id: string;

  committee: Committee;

  isLoading = true;

  isSubmitting = false;

  isSavingStatus = false;

  isFormValid: boolean;

  statuses = CommitteeMetaStatus;

  assigneeStatuses = [];

  disabledStatuses = [];

  isPriorityEditable: boolean;

  canSaveAnyway: boolean;

  canSubmitNonJob: boolean;

  canSubmitJob: boolean;

  canCreateComments: boolean;

  hasJobForCurrentUser: boolean;

  currentJob: Job;

  private currentUser: User;

  private wndw: Window = window; // allow for testing

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private svcAcl: ACL,
    private svcAuth: Auth,
    private svcJob: JobsAPI,
    public svcCommittee: CommitteeAPI, // used in template too
    private titleService: Title
  ) {}

  ngOnInit(): void {
    this.canSaveAnyway = this.svcAcl.hasCredential('committee.saveAnyway'); // allow saving despite remaining form validation issues
    this.canSubmitNonJob = this.svcAcl.hasCredential('committee.update');
    this.canSubmitJob = this.svcAcl.hasCredential('job.submit');
    this.isPriorityEditable = this.svcAcl.hasCredential('committee.update.prop.priority');
    this.canCreateComments = this.svcAcl.hasCredential('committee.comment.create');

    this.route.params
      .pipe(
        take(1),
        tap(() => {
          this.isLoading = true;

          // Make sure we cannot submit something we shouldn't under the pretense that we are working a job
          this.currentJob = null;
          this.hasJobForCurrentUser = false;
        }),
        concatMap((params) => {
          window.scrollTo(0, 0);

          this.id = params['id'];

          return this.svcCommittee.findById(this.id).pipe(
            tap((committee) => {
              this.committee = committee;
              this.titleService.setTitle(`cLean | Committee | ${this.committee.name}`);
            })
          );
        }),
        concatMap(() => {
          if (this.currentUser) {
            // We already know current user, don't waste time fetching again
            return of(this.currentUser);
          }

          return this.svcAuth.getProfile().pipe(tap((user) => (this.currentUser = user)));
        }),
        concatMap(() => {
          // Only get job details if user is the one working it
          if (!(this.committee._meta?.jobId && this.committee._meta?.assignee === this.currentUser.user_id)) {
            return of(null);
          }

          return this.svcJob.findById(this.committee._meta.jobId).pipe(
            tap((job) => {
              if (job) {
                this.currentJob = job;
                this.hasJobForCurrentUser =
                  this.svcAcl.hasCredential('job.submit') &&
                  this.committee._meta.jobId &&
                  this.currentUser.user_id === this.committee._meta.assignee;
                if (!this.isFormRelevant()) {
                  this.isFormValid = true;
                }
              }
            })
          );
        }),
        tap(() => (this.isLoading = false))
      )
      .subscribe();
  }

  goBack(): void {
    const link = ['/committee'];
    this.router.navigate(link);
  }

  onSave(committee: Committee): void {
    this.updateCommittee(committee);
  }

  onStatusChange(_meta: any): void {
    if (!this.svcAcl.hasCredential('committee.update.prop.meta.status')) {
      return;
    }

    this.isSavingStatus = true;

    this.svcCommittee
      .update(this.committee._id, { _meta })
      .pipe(tap(() => (this.isSavingStatus = false)))
      .subscribe((committee) => {
        Object.assign(this.committee, committee);
      });
  }

  onFormValidityChange(status: string): void {
    setTimeout(() => (this.isFormValid = !this.isFormRelevant() || status === 'VALID'));
  }

  onSetPriority(committee: Committee, priority: number): void {
    if (!priority || (committee.priority && committee.priority === priority)) {
      return;
    }

    this.svcCommittee.update(committee._id, { priority } as Committee).subscribe((committee) => {
      Object.assign(this.committee, committee);
    });
  }

  onJobUtc(): void {
    if (!this.hasJobForCurrentUser) {
      return;
    }

    let comment = '';
    while (comment.length < 3) {
      comment = this.wndw.prompt('Enter a comment for Unable to Compile...');
      if (null === comment) {
        return;
      }
    }

    this.isSubmitting = true;

    this.svcJob
      .setUtc(this.currentJob._id, comment)
      .pipe(tap(() => (this.isSubmitting = false)))
      .subscribe(() => {
        this.goBack();
      });
  }

  async onJobDraft(): Promise<void> {
    if (!this.hasJobForCurrentUser) {
      return;
    }

    this.isSubmitting = true;

    this.svcCommittee
      .update(this.committee._id, this.committee)
      .pipe(
        concatMap(() => this.svcJob.setDraft(this.currentJob._id)),
        tap(() => (this.isSubmitting = false))
      )
      .subscribe(() => {
        this.goBack();
      });
  }

  onSubmitJob(committee: Committee): void {
    if (!this.hasJobForCurrentUser || (!this.isFormValid && !this.canSaveAnyway)) {
      return;
    }
    if (
      !this.isFormValid &&
      this.canSaveAnyway &&
      !this.wndw.confirm('There are validation errors left. Sure you want to submit?')
    ) {
      return;
    }

    this.isSubmitting = true;

    this.svcCommittee
      .submitJob(committee)
      .pipe(tap(() => (this.isSubmitting = false)))
      .subscribe(() => {
        this.goBack();
      });
  }

  onCopyID(id: string): void {
    navigator.clipboard.writeText(id);
  }

  isClaimedByOtherUser(): boolean {
    // actual valid use of call from template to handle expiration
    const hasValidClaim = this.committee._meta.claimedUntil && new Date(this.committee._meta.claimedUntil) > new Date();
    const isClaimedByUser = this.committee._meta.assignee && this.committee._meta.assignee !== this.currentUser.user_id;

    return hasValidClaim && isClaimedByUser;
  }

  isFormRelevant(): boolean {
    return !this.currentJob?.type?.startsWith('COMMITTEE_MAPPING'); // we only care about KOLs; TODO: make this part of job permissions def
  }

  private updateCommittee(committee: Committee): void {
    if (!this.canSubmitNonJob || (!this.isFormValid && !this.canSaveAnyway)) {
      return;
    }
    if (
      !this.isFormValid &&
      this.canSaveAnyway &&
      !this.wndw.confirm('There are validation errors left. Sure you want to submit?')
    ) {
      return;
    }

    this.isSubmitting = true;

    this.svcCommittee
      .update(this.id, committee)
      .pipe(tap(() => (this.isSubmitting = false)))
      .subscribe(() => {
        this.goBack();
      });
  }
}
