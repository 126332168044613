import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

import { OrganizationAffiliation } from '../shared/organization-affiliation';
import { ACL } from '../../shared/acl/acl.service';
import { AffiliationBaseinfo } from '../../affiliation/shared/affiliation-baseinfo';
import { Organization } from '../../organizations/shared/organization';

@Component({
  selector: 'dirt-organization-affiliation-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class OrganizationAffiliationListComponent implements OnInit {
  @Input()
  organization: Organization;
  @Input()
  allMappings: OrganizationAffiliation[] = [];
  @Input()
  allAffiliations: Record<string, AffiliationBaseinfo>;

  @Output()
  matchChanged: EventEmitter<OrganizationAffiliation> = new EventEmitter();
  @Output()
  addAffiliationMapping: EventEmitter<void> = new EventEmitter();

  isLoading: boolean;
  canEditAffiliation = false;

  constructor(private readonly svcAcl: ACL) {}

  ngOnInit(): void {
    this.canEditAffiliation = this.svcAcl.hasCredential('organization.affiliation.update');
  }

  /** just avoid useless rendering if we can */
  trackById(index: number, aff: OrganizationAffiliation): string {
    return aff._id;
  }
}
