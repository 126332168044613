import { Component, Output, EventEmitter, Input, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Observable, of } from 'rxjs';
import { take } from 'rxjs/operators';
import { catchError, debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';

import { OrganizationAPI } from '../../api.service';
import { AddressEntity } from '../../address-entity';

@Component({
  selector: 'dirt-address-lookup',
  templateUrl: 'address-lookup.component.html',
  styleUrls: ['address-lookup.component.scss'],
})
export class AddressLookupComponent implements OnInit {
  @Input()
  disabled: boolean = false;

  @Input()
  preferredOrgId?: string = undefined;

  @Input()
  transformed?: boolean;

  @Input()
  required = false;

  @Output()
  addressChange: EventEmitter<AddressEntity> = new EventEmitter();

  @ViewChild('srch')
  searchBox: ElementRef;

  isSearching = false;
  searchTerm: string;

  searchAutoComplete = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      tap(() => (this.isSearching = true)),
      switchMap((term) =>
        this.svcOrganization.findAddress(term, this.preferredOrgId, 25, undefined, undefined).pipe(
          take(1),
          catchError(() => of([]))
        )
      ),
      tap(() => (this.isSearching = false))
    );

  constructor(private svcOrganization: OrganizationAPI) {}

  ngOnInit() {
    setTimeout(() => this.searchBox?.nativeElement?.focus());
  }
}
