import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  catchError,
  concatMap,
  debounceTime,
  distinctUntilChanged,
  firstValueFrom,
  map,
  Observable,
  of,
  Subject,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgModel } from '@angular/forms';

import { AffiliationAPI } from '../../affiliation/shared/api.service';
import { Document } from '../shared/document';
import { DocumentPerson } from '../shared/person';
import { DocumentPersonAPI } from '../shared/document-person-api.service';
import { Person } from '../../person/shared/person';
import { PersonAPI } from '../../person/shared/api.service';
import { PersonStatus } from '../../person/shared/constant/status.enum';
import { Utils } from '../../common/utils';
import { Value } from '../../shared/services/value/value';
import { ValueAPI } from '../../shared/services/value/value-api.service';
import { ValueType } from '../../shared/enum/value-type.enum';
import { UnmappedPersonAffiliationsModalService } from '../../person/shared/modal/unmapped-person-affiliations/unmapped-person-affiliations.service';

@Component({
  selector: 'dirt-document-person-modal',
  templateUrl: 'person-modal.component.html',
  styleUrls: ['person-modal.component.scss'],
})
export class DocumentPersonModalComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input()
  document: Document;

  @Input()
  selectedDocumentPerson: DocumentPerson;

  @Input()
  mode: 'ADD' | 'VIEW' | 'EDIT';

  isSaving: boolean;

  isSearching: boolean;

  isFormValid: boolean;

  showPersonForm: boolean;

  positions: Value[] = [];

  searchTerm: string;

  isEdit: boolean;

  createDisabledFields = ['cvLinks'];

  editDisabledFields = [
    'firstName',
    'middleName',
    'lastName',
    'source',
    'suffix',
    'countryWorkflow',
    'source',
    'cvLinks',
  ];

  disablePersonForm = false;

  @ViewChild('frmAdditional', { static: true })
  private frmAdditional: NgModel;

  private baseFormStatus: string;

  private destroy$: Subject<boolean> = new Subject();

  constructor(
    public activeModal: NgbActiveModal,
    private svcPerson: PersonAPI,
    private svcDocumentPerson: DocumentPersonAPI,
    private svcAffiliation: AffiliationAPI,
    private svcValue: ValueAPI,
    private svcUPAModal: UnmappedPersonAffiliationsModalService
  ) {
    this.onSearchPeople = this.onSearchPeople.bind(this);
  }

  ngOnInit() {
    this.isEdit = this.mode === 'EDIT';
    this.disablePersonForm = this.mode === 'VIEW';

    this.svcValue.find(ValueType.DocumentPersonPosition, Number.MAX_SAFE_INTEGER).subscribe((data) => {
      this.positions = data;
    });
  }

  ngAfterViewInit(): void {
    this.frmAdditional.statusChanges.pipe(takeUntil(this.destroy$)).subscribe(() => this.onFormValidityChange());
  }

  ngOnDestroy(): void {
    this.destroy$.next(false);
    this.destroy$.complete();
  }

  onSearchPeople(term$: Observable<string>): Observable<Person[]> {
    return term$.pipe(
      debounceTime(400),
      distinctUntilChanged(),
      tap(() => (this.isSearching = true)),
      switchMap((term: string) => {
        if (!term) {
          return of([]);
        }

        return this.svcPerson
          .find(term, 10, 0, null, { 'affiliations.countryCode': [this.document.country] })
          .pipe(catchError(() => of([])));
      }),
      switchMap((people) => {
        if (people.length === 0) {
          return of([]);
        }

        const personToAffiliation = new Map();
        people.forEach((p) => {
          const primaryAffiliationLfka = p.affiliationsLfka?.find((a) => !!a.positions.find((p) => p.primary));
          if (primaryAffiliationLfka) {
            personToAffiliation.set(p, primaryAffiliationLfka.id);
          } else {
            const primaryAffiliation = p.affiliations?.find((a) => a.primary);
            if (primaryAffiliation) {
              personToAffiliation.set(p, primaryAffiliation.id);
            }
          }
        });

        const ids = Array.from(personToAffiliation.values());
        return this.svcAffiliation.find(null, ids.length, 0, null, { _id: ids }).pipe(
          map((affiliations) => {
            personToAffiliation.forEach((affId, p) => {
              const affiliation = affiliations.find((a) => a.id === affId);
              p.affiliationName = [affiliation?.name, affiliation?.department].filter((v) => !!v).join(' - ');
            });

            return people;
          })
        );
      }),
      tap(() => (this.isSearching = false))
    );
  }

  onTogglePersonForm(): void {
    this.showPersonForm = true;

    this.selectedDocumentPerson = {
      documentId: this.document._id,
      sourceUrls: [''],
      person: new Person(),
    } as any;

    this.selectedDocumentPerson.person.cvLinks = [''];
  }

  onSelectPerson(event: any): void {
    event.preventDefault(); // prevent setting model to [object Object]

    this.selectedDocumentPerson = {
      documentId: this.document._id,
      kolId: event.item.kolId,
      sourceUrls: [''],
      person: event.item,
    } as any;

    this.searchTerm = '';
    this.showPersonForm = false;
  }

  async onAddPerson(): Promise<void> {
    if (!this.selectedDocumentPerson || this.showPersonForm || this.isSaving || this.isSearching) {
      return;
    }

    const person = (
      await firstValueFrom(
        this.svcDocumentPerson.find(this.document._id, 1, 0, null, { kolId: this.selectedDocumentPerson.person.kolId })
      )
    )[0];
    if (person) {
      return alert('person is already attached to document');
    }

    this.isSaving = true;

    const { unmappedAffiliations } = await firstValueFrom(
      this.svcPerson.checkAffiliations(this.selectedDocumentPerson.person)
    );
    if (unmappedAffiliations.length > 0) {
      const continueAnyways = await this.svcUPAModal.open(unmappedAffiliations);
      if (continueAnyways !== true) {
        this.isSaving = false;
        return;
      }
    }

    // Send for KPP curation
    if (
      !(this.selectedDocumentPerson?.person.affiliationsLfka?.length > 0) &&
      !this.selectedDocumentPerson?.person.projectNames?.includes('LFKA_RF_BD')
    ) {
      this.selectedDocumentPerson.person.projectNames = [
        ...(this.selectedDocumentPerson.person.projectNames || []),
        'LFKA_RF_BD',
      ];
      await firstValueFrom(
        this.svcPerson.update(this.selectedDocumentPerson.person.id, {
          projectNames: this.selectedDocumentPerson.person.projectNames,
        })
      );
    }

    this.svcDocumentPerson.create(this.selectedDocumentPerson).subscribe(() => {
      this.activeModal.close();
    });
  }

  async onEditPerson(): Promise<void> {
    if (!this.selectedDocumentPerson || this.showPersonForm || this.isSaving || this.isSearching) {
      return;
    }

    this.isSaving = true;

    const { unmappedAffiliations } = await firstValueFrom(
      this.svcPerson.checkAffiliations(this.selectedDocumentPerson.person)
    );
    if (unmappedAffiliations.length > 0) {
      const continueAnyways = await this.svcUPAModal.open(unmappedAffiliations);
      if (continueAnyways !== true) {
        this.isSaving = false;
        return;
      }
    }

    await firstValueFrom(
      this.svcPerson.update(this.selectedDocumentPerson.person.id, this.selectedDocumentPerson.person)
    );

    Utils.removeEmptyAndDuplicatedElementsFromArray(this.selectedDocumentPerson, ['sourceUrls']);

    this.svcDocumentPerson.update(this.selectedDocumentPerson.id, this.selectedDocumentPerson).subscribe(() => {
      this.activeModal.close();
    });
  }

  async onRequestPerson(): Promise<void> {
    if (!this.isFormValid || !this.showPersonForm || this.isSaving || this.isSearching) {
      return;
    }

    this.selectedDocumentPerson.person._meta = { status: PersonStatus.ON_HOLD };

    // Send for KPP curation
    if (!this.selectedDocumentPerson?.person.projectNames?.includes('LFKA_RF_BD')) {
      this.selectedDocumentPerson.person.projectNames = [
        ...(this.selectedDocumentPerson.person.projectNames || []),
        'LFKA_RF_BD',
      ];
    }

    this.selectedDocumentPerson.person.cvLinks = [this.selectedDocumentPerson.sourceUrls[0]]; // Master CV link in document is used as master cv link in KP

    this.isSaving = true;

    const { unmappedAffiliations } = await firstValueFrom(
      this.svcPerson.checkAffiliations(this.selectedDocumentPerson.person)
    );
    if (unmappedAffiliations.length > 0) {
      const continueAnyways = await this.svcUPAModal.open(unmappedAffiliations);
      if (continueAnyways !== true) {
        this.isSaving = false;
        return;
      }
    }

    this.svcPerson
      .create(this.selectedDocumentPerson.person)
      .pipe(
        concatMap((person) => {
          this.selectedDocumentPerson.kolId = person.kolId;
          this.selectedDocumentPerson.person = person;

          Utils.removeEmptyAndDuplicatedElementsFromArray(this.selectedDocumentPerson, ['sourceUrls']);

          return this.svcDocumentPerson.create(this.selectedDocumentPerson);
        })
      )
      .subscribe(() => {
        this.activeModal.close();
      });
  }

  onFormValidityChange(status?: string): void {
    if (status) {
      this.baseFormStatus = status;
    }

    this.isFormValid = this.baseFormStatus === 'VALID' && this.frmAdditional.valid;
  }

  removeFromByIndex(from: any[], idx: number): void {
    from.splice(idx, 1);
  }

  pushItemToList(list: any[]): void {
    list.push('');
  }

  trackByIndex(index: number): number {
    return index;
  }
}
