<dirt-organization-lookup
  [rootOrganizationId]="root?._id"
  [allowOutsideRoot]="false"
  [minimumCharactersBeforeSearch]="3"
  (organizationChange)="onSelectLookupItem($event)"
></dirt-organization-lookup>

<div class="tree-simple">
  <ng-template [ngTemplateOutlet]="treeLeaf" [ngTemplateOutletContext]="{ leaf: root }"></ng-template>
</div>

<ng-template #treeLeaf let-leaf="leaf">
  <div class="tree-row" [ngClass]="{ alien: leaf.isAlien, selected: selectedId === leaf._id }">
    <div class="tree-row-label">
      <div>
        <span
          tabindex="0"
          (click)="onToggleExpanded(leaf)"
          (keyup.enter)="doToggleExpanded(leaf)"
          class="toggle fa {{ expandedIds.has(leaf._id) ? 'fa-minus' : 'fa-plus' }}"
        ></span>

        <span class="alink" [ngClass]="{ selected: selectedId === leaf._id }" (click)="onSelect(leaf)">
          {{ leaf.name }}
        </span>
      </div>

      <div class="status-container">
        <a *ngIf="leaf.websource" class="url" [href]="leaf.websource" rel="noopener noreferrer" target="_blank">
          <i class="fa fa-globe"></i>
        </a>
      </div>
    </div>

    <!-- Render children when leaf is expanded -->
    <ng-container *ngIf="expandedIds.has(leaf._id) && leaf.children?.length > 0">
      <ng-container *ngFor="let child of leaf.children; trackBy: trackById">
        <ng-template [ngTemplateOutlet]="treeLeaf" [ngTemplateOutletContext]="{ leaf: child }"></ng-template>
      </ng-container>
    </ng-container>
  </div>
</ng-template>
