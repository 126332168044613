import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OrganizationMaintenanceModalComponent } from './organization-maintenance-request-modal.component';

@Injectable()
export class OrganizationMaintenanceRequestModalService {
  constructor(private svcModal: NgbModal) {}

  open(organizationId: string, isCustomerRequest?: boolean, requestOwnerProduct?: string): Promise<{ ok: boolean }> {
    const ref = this.svcModal.open(OrganizationMaintenanceModalComponent);
    ref.componentInstance.organizationId = organizationId;
    ref.componentInstance.isCustomerRequest = !!isCustomerRequest;
    ref.componentInstance.requestOwnerProduct = requestOwnerProduct;
    return ref.result;
  }
}
