import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { Affiliation } from '../../../affiliation/shared/affiliation';
import { Organization } from '../organization';
import { OrganizationCreateRequestModalComponent } from './organization-create-request-modal.component';

@Injectable()
export class OrganizationCreateRequestModalService {
  constructor(private svcModal: NgbModal) {}

  open(
    customerRequest?: boolean,
    requestOwnerProduct?: string
  ): Promise<{ organization: Organization; affiliation: Affiliation }> {
    const ref = this.svcModal.open(OrganizationCreateRequestModalComponent, { size: 'xl', backdrop: 'static' });

    // Might be able to prefill some information
    ref.componentInstance.isCustomerRequest = customerRequest;
    ref.componentInstance.defaultOwnerProduct = requestOwnerProduct;

    return ref.result;
  }
}
