<section class="dirt-section-list dirt-account-affiliations-list">
  <header class="row justify-content-between">
    <div class="col-sm-4">
      <h1>
        <span>{{ total.count | number }}&nbsp;</span>
        <span>Institutions</span>
      </h1>
    </div>

    <div class="col-sm-4">
      <input
        type="search"
        id="affiliations-searchbar"
        class="di-search form-control ng-trim-ignore"
        placeholder="Search..."
        [formControl]="searchCtrl"
      />
    </div>

    <div class="col-sm-4">
      <div class="pull-right">
        <!-- Add button -->
        <button class="btn btn-success btn-sm" (click)="addAffiliation()" *diAcl="'account.affiliation.create'">
          <i class="fa fa-plus" aria-hidden="true"></i>&nbsp;
          <span *ngIf="!parentId">Add Institution</span>
        </button>
      </div>
    </div>
  </header>

  <!-- Pagination -->
  <dirt-pagination
    class="dirt-pagination"
    [id]="'dirt-account-affiliations'"
    [pagingSize]="accountAffiliations?.length || 0"
    [pagingSkip]="pagingSkip"
    [pagingLimit]="pagingLimit"
    [pagingTotal]="total?.count || 0"
    [hidden]="pagingLimit >= total?.count"
    (pageChange)="getPage($event)"
  >
  </dirt-pagination>

  <div [hidden]="isLoading">
    <table class="table table-sm table-hover" *ngIf="accountAffiliations?.length; else empty">
      <thead>
        <tr>
          <th class="col-badge">&nbsp;</th>
          <th class="col-name">Name</th>
          <th class="col-department">Department</th>
          <th class="col-type">Type</th>
          <th class="col-state">State</th>
          <th class="col-country">Country</th>
          <th class="col-status">Status</th>
          <th class="col-lastUpdate">Updated</th>
          <th class="col-isHQ">HQ</th>
          <th class="col-isFlagship">Flagship</th>
          <th class="col-isSpCenter">Specialty Center</th>
          <th class="col-isExcludedFromDelivery">Excluded from Delivery</th>
          <th class="col-actions">&nbsp;</th>
        </tr>
      </thead>

      <tbody>
        <ng-container
          *ngFor="
            let grouped of accountAffiliations
              | paginate
                : {
                    id: 'dirt-account-affiliations',
                    itemsPerPage: pagingLimit,
                    currentPage: pagingPage,
                    totalItems: total?.count
                  };
            trackBy: trackById
          "
        >
          <ng-container *ngFor="let row of grouped.departments">
            <tr class="dirt-account-affiliations-row">
              <td class="col-badge">
                <span
                  title="Not yet worked by central HCO team"
                  class="badge badge-warning"
                  *ngIf="!row.affiliation?.readyForDelivery"
                  >Unpolished</span
                >
              </td>
              <td class="col-name" [ngClass]="{ 'parent-affiliation': !row.affiliation?.department }">
                <a
                  class="dirt-affiliation-name"
                  [ngClass]="{ 'excluded-from-delivery': row.isExcludedFromDelivery }"
                  [title]="row.affiliation?.name | dash"
                  [routerLink]="['/affiliation/detail', row.affiliation?.id]"
                  target="_blank"
                  >{{ row.affiliation?.name }}</a
                >
              </td>
              <td class="col-department" [ngClass]="{ 'excluded-from-delivery': row.isExcludedFromDelivery }">
                {{ row.affiliation?.department }}
              </td>
              <td class="col-type">{{ row.affiliation?.type | capitalize }}</td>
              <td class="col-country">{{ row.affiliation?.address?.state }}</td>
              <td class="col-country">{{ row.affiliation?.address?.country }}</td>
              <td class="col-status">{{ row.status | capitalize }}</td>
              <td class="col-lastUpdate" [title]="row.updatedAt || row.createdAt || null">
                {{ row.updatedAt || row.createdAt || null | date : 'dd.MM.y' }}
              </td>
              <td class="col-isHQ">
                <input
                  type="checkbox"
                  [disabled]="
                    row.affiliation?.department ||
                    (hasHQ && hqAffiliationId !== row.affiliation.id) ||
                    !canEditAffiliation ||
                    (isAffiliationAlreadyHQ[row.affiliation.id] &&
                      isAffiliationAlreadyHQ[row.affiliation.id].length > 0 &&
                      !hasHQ &&
                      !row.isHQ)
                  "
                  [checked]="row.isHQ"
                  [(ngModel)]="row.isHQ"
                  (change)="onAffiliationUpdate(row.id, 'isHQ', $event, row.affiliation.id)"
                />
              </td>
              <td class="col-isFlagship">
                <input
                  type="checkbox"
                  [checked]="row.isFlagship"
                  [(ngModel)]="row.isFlagship"
                  (change)="onAffiliationUpdate(row.id, 'isFlagship', $event)"
                  [disabled]="!canEditAffiliation"
                />
              </td>
              <td class="col-isSpCenter">
                <input
                  type="checkbox"
                  [checked]="row.isSpecialityCenter"
                  [(ngModel)]="row.isSpecialityCenter"
                  (change)="onAffiliationUpdate(row.id, 'isSpecialityCenter', $event)"
                  [disabled]="!canEditAffiliation"
                />
              </td>
              <td class="col-isExcludedFromDelivery">
                <input
                  type="checkbox"
                  [checked]="row.isExcludedFromDelivery"
                  [(ngModel)]="row.isExcludedFromDelivery"
                  (change)="onAffiliationUpdate(row.id, 'isExcludedFromDelivery', $event)"
                  [disabled]="!canEditAffiliation || !canEditIsExcludedFromDelivery || !row.affiliation?.department"
                />
              </td>
              <td class="col-actions">
                <!-- Audits -->
                <span class="action-btn action-audit" title="Open audit logs" (click)="onRequestAuditLogs([row.id])">
                  <i class="fa fa-eye" aria-hidden="true"></i>
                </span>

                <!-- Delete -->
                <ng-template [diAcl]="'account.affiliation.delete'" [diAclAnd]="!row.affiliation.department">
                  <span
                    class="action-btn action-delete"
                    title="Remove link between institution and Account"
                    (click)="onDelete(row.id)"
                  >
                    <i class="fa fa-trash-o" aria-hidden="true"></i>
                  </span>
                </ng-template>
              </td>
            </tr>
          </ng-container>
        </ng-container>
      </tbody>
    </table>

    <!-- Empty Message -->
    <ng-template #empty>
      <p class="dirt-no-results">No institutions</p>
    </ng-template>
  </div>

  <!-- Loading Message -->
  <p class="dirt-loading" *ngIf="isLoading">
    <i class="fa fa-refresh fa-spin fa-2x" aria-hidden="true"></i>
    <span class="sr-only">Loading...</span>
  </p>
</section>
