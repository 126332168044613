import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgxJsonViewerModule } from 'ngx-json-viewer';

import { JsonViewerComponent } from './json-viewer/json-viewer.component';
import { JsonViewerService } from './json-viewer/json-viewer.service';
import { ResultDialogComponent } from './result-dialog/result-dialog.component';
import { ResultDialogService } from './result-dialog/result-dialog.service';
import { ConfirmationComponent } from './confirmation/confirmation.component';
import { ConfirmationService } from './confirmation/confirmation.service';

/**
 * Internal module used for sharing components
 */
@NgModule({
  imports: [BrowserModule, CommonModule, NgxJsonViewerModule],
  declarations: [JsonViewerComponent, ResultDialogComponent, ConfirmationComponent],
  exports: [JsonViewerComponent, ResultDialogComponent, ConfirmationComponent],
  providers: [JsonViewerService, ResultDialogService, ConfirmationService],
})
export class DirtModalsModule {}
