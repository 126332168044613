import { Component } from '@angular/core';

@Component({
  selector: 'dirt-affiliation',
  template: `
    <dirt-admin-header></dirt-admin-header>
    <div class="container-fluid">
      <section class="dirt-navbar-sub">
        <div class="alert alert-danger text-center" role="alert">
          <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
          The Institutions module has been deprecated. Please work within the Organizations module.
          <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
        </div>

        <router-outlet></router-outlet>
      </section>
    </div>
  `,
})
export class AffiliationComponent {}
