<ng-container *ngIf="affiliation">
  <section class="dirt-section-detail dirt-affiliation-detail" *ngIf="affiliation">
    <div class="row">
      <div class="col-md-8">
        <button type="button" class="dirt-btn-back btn btn-primary-outline btn-sm" (click)="goBack()">
          <span class="fa fa-arrow-left" aria-hidden="true"></span>
        </button>
        <div class="dirt-btn-ct">
          <div class="pull-left">
            <h1
              class="bounceInRight"
              *ngIf="affiliation.readyForDelivery || affiliation.source_type === 'ORGANIZATION'"
            >
              Organization Detail
            </h1>
            <h1
              class="bounceInRight"
              *ngIf="!affiliation.readyForDelivery && affiliation.source_type !== 'ORGANIZATION'"
            >
              Organization - request to create
            </h1>
          </div>
          <div class="pull-right">
            <button type="button" class="btn btn-outline-primary" (click)="onCopyID()">Copy ID</button>
          </div>
        </div>
        <br />
      </div>
    </div>
    <div class="row">
      <div class="col-md-8">
        <dirt-affiliation-form [affiliation]="affiliation" #frmAffiliation="frmAffiliation"></dirt-affiliation-form>

        <div class="dirt-duplicate-list" *ngIf="!!duplicateAffiliation">
          <div class="dirt-duplicate-list-heading">A duplicate was found with same name or department:</div>
          <dirt-affiliation-suggestion-list
            [affiliations]="[duplicateAffiliation]"
            [selectLabel]="'Copy ID'"
            (onSelectedItem)="onCopyID(duplicateId)"
          ></dirt-affiliation-suggestion-list>
        </div>

        <!-- Buttons -->
        <div class="dirt-btn-ct">
          <div class="pull-left">
            <ng-container
              *ngIf="affiliation._meta?.status !== 'PENDING_REMOVAL' && affiliation.source_type !== 'ORGANIZATION'"
            >
              <!-- Merge -->
              <button
                type="button"
                class="btn btn-outline-warning"
                (click)="onMerge()"
                [disabled]="isSaving || isSubmitting || isUpdating || isDeleting"
                *ngIf="hasMergePermission()"
              >
                {{ isCreatingAffiliation() ? 'Already in' : 'Merge' }}
              </button>

              <!-- Delete -->
              <button
                type="button"
                class="btn btn-outline-danger"
                [ngClass]="{ 'btn-loading': isDeleting }"
                [disabled]="isSaving || isSubmitting || isUpdating || isDeleting"
                (click)="onDelete()"
                *ngIf="hasDeletePermission()"
              >
                Delete
              </button>
            </ng-container>

            <!-- Unable to Process for job -->
            <button
              type="button"
              *ngIf="hasJobForCurrentUser()"
              class="btn btn-outline-danger"
              [disabled]="isSaving || isSubmitting || isUpdating || isDeleting"
              (click)="onJobUtc()"
            >
              Unable to Compile!
            </button>
          </div>

          <div
            class="pull-right"
            *ngIf="affiliation._meta?.status !== 'PENDING_REMOVAL' && affiliation.source_type !== 'ORGANIZATION'"
          >
            <span *ngIf="isUpdating">Updating... </span>
            <!-- Save -->
            <button
              type="submit"
              class="btn btn-primary"
              [ngClass]="{ 'btn-loading': isSaving }"
              [disabled]="!frmAffiliation.isValid() || isSaving || isSubmitting || isUpdating || isDeleting"
              (click)="onSave(frmAffiliation.getValue())"
              *ngIf="hasPermissionInclLfka('affiliation.update') && !hasJobForCurrentUser()"
            >
              <span>Save</span>
            </button>

            <!-- Submit -->
            <button
              type="submit"
              class="btn btn-primary"
              [ngClass]="{ 'btn-loading': isSubmitting }"
              [disabled]="!frmAffiliation.isValid() || isSaving || isSubmitting || isUpdating || isDeleting"
              (click)="onSubmit(frmAffiliation.getValue())"
              *ngIf="hasPermissionInclLfka('affiliation.submit') && !hasJobForCurrentUser()"
            >
              <span>Submit</span>
            </button>

            <ng-container *ngIf="canSubmitJob() && hasJobForCurrentUser()">
              <!-- Save as Draft for job -->
              <button
                type="button"
                class="btn btn-secondary"
                [ngClass]="{ 'btn-loading': isSubmitting }"
                [disabled]="isSaving || isSubmitting || isUpdating || isDeleting"
                (click)="onJobDraft(frmAffiliation.getValue())"
              >
                Save as draft
              </button>

              <!-- Submit job -->
              <button
                type="submit"
                class="btn btn-primary"
                [ngClass]="{ 'btn-loading': isSubmitting }"
                [disabled]="!frmAffiliation.isValid() || isSaving || isSubmitting || isUpdating || isDeleting"
                (click)="onSubmit(frmAffiliation.getValue())"
              >
                Submit job
              </button>
            </ng-container>
          </div>
        </div>
      </div>

      <div class="col-md-4">
        <ul ngbNav #nav="ngbNav" class="nav-tabs">
          <!-- Information -->
          <li ngbNavItem>
            <a ngbNavLink>Information</a>
            <ng-template ngbNavContent>
              <dl class="row">
                <dt class="col-sm-4" [hidden]="!isClaimedByOtherUser"></dt>
                <dd class="col-sm-8" [hidden]="!isClaimedByOtherUser">
                  <span title="s/o else started working recently" class="badge badge-info">Claimed</span>
                </dd>

                <dt class="col-sm-4" [hidden]="affiliation.readyForDelivery"></dt>
                <dd class="col-sm-8" [hidden]="affiliation.readyForDelivery">
                  <span title="Not yet worked by central organizations team" class="badge badge-warning"
                    >Unpolished</span
                  >
                </dd>

                <dt class="col-sm-4" [hidden]="!affiliation.hasSite">Site Status</dt>
                <dd class="col-sm-8" [hidden]="!affiliation.hasSite">
                  <span class="badge badge-info">Known</span>
                </dd>

                <dt class="col-sm-4" [hidden]="!affiliation._meta?.workflow">Workflow</dt>
                <dd class="col-sm-8" [hidden]="!affiliation._meta?.workflow">
                  <span class="badge badge-info">{{ affiliation._meta?.workflow }}</span>
                </dd>

                <dt class="col-sm-4" [hidden]="!affiliation.sourceLfka && affiliation.source !== 'OPEN_DATA'"></dt>
                <dd class="col-sm-8" [hidden]="!affiliation.sourceLfka && affiliation.source !== 'OPEN_DATA'">
                  <span [hidden]="!affiliation.sourceLfka" title="created by / for LFKA" class="badge badge-info"
                    >LFKA</span
                  >
                  <span
                    [hidden]="affiliation.source !== 'OPEN_DATA'"
                    title="automated organization"
                    class="badge badge-info"
                    >Auto</span
                  >
                </dd>

                <ng-container *ngIf="hasJobForCurrentUser()">
                  <dt class="col-sm-4">Job</dt>
                  <dd class="col-sm-8">
                    {{ affiliationJob?.type + (affiliationJob?.hint ? ' - ' + affiliationJob.hint : '') }}
                  </dd>

                  <dt class="col-sm-4" *ngIf="affiliationJob.additionalData?.dupId">Dup (actual) ID</dt>
                  <dd class="col-sm-8" *ngIf="affiliationJob.additionalData?.dupId">
                    <a target="_blank" [routerLink]="['/affiliation/detail', affiliationJob.additionalData.dupId]">{{
                      affiliationJob.additionalData.dupId
                    }}</a>
                  </dd>

                  <dt class="col-sm-4" *ngIf="affiliationJob.additionalData?.requestOwnerProduct">Req by</dt>
                  <dd class="col-sm-8" *ngIf="affiliationJob.additionalData?.requestOwnerProduct">
                    {{ affiliationJob.additionalData.requestOwnerProduct }}
                  </dd>

                  <dt class="col-sm-4" *ngIf="affiliationJob.additionalData?.comment">Comment</dt>
                  <dd class="col-sm-8" *ngIf="affiliationJob.additionalData?.comment">
                    {{ affiliationJob.additionalData.comment }}
                  </dd>

                  <dt class="col-sm-4" *ngIf="affiliationJob.additionalData?.link">Link</dt>
                  <dd class="col-sm-8" *ngIf="affiliationJob.additionalData?.link">
                    <a [href]="affiliationJob.additionalData.link" target="_blank" rel="noreferrer noopener">{{
                      affiliationJob.additionalData.link
                    }}</a>
                  </dd>
                </ng-container>

                <ng-template [diAcl]="'affiliation.create || affiliation.request'">
                  <ng-container *ngIf="affiliation.readyForDelivery">
                    <dt class="col-sm-4"></dt>
                    <dd class="col-sm-8">
                      <a href="javascript:void(0)" (click)="onCreateRequest()">{{
                        hasJobForCurrentUser() ? 'Create another maintenance request' : 'Request Maintenance'
                      }}</a>
                    </dd>
                  </ng-container>
                </ng-template>

                <dt class="col-sm-4">Verified</dt>
                <dd class="col-sm-8">
                  <ng-template
                    [diAcl]="'affiliation.update.prop.verified'"
                    [diAclAnd]="affiliation.source_type !== 'ORGANIZATION'"
                  >
                    <span>
                      <ui-switch
                        [checked]="!!affiliation.verified"
                        (change)="onVerifiedChange($event)"
                        size="small"
                      ></ui-switch>
                      <i class="fa fa-pulse fa-spinner di-va-super" [hidden]="!isSavingVerified"></i>
                    </span>
                  </ng-template>

                  <ng-template
                    [diAcl]="'!affiliation.update.prop.verified'"
                    [diAclOr]="affiliation.source_type === 'ORGANIZATION'"
                  >
                    <span>
                      <i
                        class="fa"
                        [ngClass]="{ 'fa-check': affiliation.verified, 'fa-times': !affiliation.verified }"
                        aria-hidden="true"
                      ></i>
                    </span>
                  </ng-template>
                </dd>

                <dt class="col-sm-4">Duplicate Suspect</dt>
                <dd class="col-sm-8">
                  <span>
                    <ui-switch
                      [checked]="!!affiliation.duplicateSuspect"
                      (change)="onDuplicateSuspectChange($event)"
                      size="small"
                      [disabled]="!hasPermissionInclLfka('affiliation.update') || isSavingDuplicateSuspect"
                    ></ui-switch>
                    <i class="fa fa-pulse fa-spinner di-va-super" [hidden]="!isSavingDuplicateSuspect"></i>
                  </span>
                </dd>

                <dt class="col-sm-4 di-separator">Delivery Ready</dt>
                <dd class="col-sm-8 di-separator">
                  <i
                    class="fa"
                    [ngClass]="{ 'fa-check': affiliation.readyForDelivery, 'fa-times': !affiliation.readyForDelivery }"
                    aria-hidden="true"
                  ></i>
                </dd>

                <dt class="col-sm-4 di-separator">Created</dt>
                <dd class="col-sm-8 di-separator">{{ affiliation.createdAt | date : 'medium' }}</dd>

                <dt class="col-sm-4 text-truncate">Updated</dt>
                <dd class="col-sm-8">{{ affiliation.updatedAt | date : 'medium' }}&nbsp;</dd>

                <dt class="col-sm-4 text-truncate">Status</dt>
                <dd class="col-sm-8">
                  <ng-template
                    [diAcl]="'!affiliation.update.prop.meta.status'"
                    [diAclOr]="affiliation.source_type === 'ORGANIZATION'"
                  >
                    <span>{{ affiliation._meta?.status | capitalize }}</span>
                  </ng-template>

                  <ng-template
                    [diAcl]="'affiliation.update.prop.meta.status'"
                    [diAclAnd]="affiliation.source_type !== 'ORGANIZATION'"
                  >
                    <dirt-status
                      [statuses]="statuses"
                      [assigneeStatuses]="assigneeStatuses"
                      [disabledStatuses]="disabledStatuses"
                      [_meta]="affiliation._meta || {}"
                      (onStatusSelected)="onStatusChange($event)"
                    >
                    </dirt-status>
                  </ng-template>
                  <i class="fa fa-circle-o-notch fa-spin" [hidden]="!isSavingStatus"></i>
                </dd>

                <dt class="col-sm-4">Version</dt>
                <dd class="col-sm-8">{{ affiliation._version || 0 }}</dd>
              </dl>
            </ng-template>
          </li>

          <!-- Audit Log -->
          <li ngbNavItem *diAcl="'affiliation.audit.list'">
            <a ngbNavLink>Audit Log</a>
            <ng-template ngbNavContent>
              <dirt-audit-log [id]="id" [entityAPI]="svcAffiliation"></dirt-audit-log>
            </ng-template>
          </li>

          <!-- Job History -->
          <li ngbNavItem *diAcl="'affiliation.job-history.list'">
            <a ngbNavLink>Job History</a>
            <ng-template ngbNavContent>
              <dirt-job-history [entityId]="id"></dirt-job-history>
            </ng-template>
          </li>
        </ul>

        <div [ngbNavOutlet]="nav" class="dirt-section-meta dirt-affiliation-meta"></div>
      </div>
    </div>

    <br />

    <dirt-affiliation-person-list
      *diAcl="'person.list || person.listMembers'"
      [affiliation]="affiliation"
    ></dirt-affiliation-person-list>
  </section>
</ng-container>
