export class OrganizationAddress {
  _id?: string;

  /**
   * @deprecated The old way to refer to an address-entity
   */
  id?: string;

  addressId: string;

  floor?: string;

  suite?: string;

  websource?: string;

  inheritedFrom?: string;

  isInherited?: boolean;
}

export class Organization {
  _id: string;

  _meta: {
    status: string;
    jobId: string;
    claimedUntil: string; // Date
    assignee: string; // user-id
  };

  parents?: string[];

  childrenInProgress?: string[];

  root?: string; // (including root itself; populate from parents[0])

  name: string;

  alternativeNames?: string[];

  isRoot: boolean;

  originalName?: string;

  originalLanguage?: string; // if empty: assume english

  fullName: string; // concat primary up the chain

  addresses?: OrganizationAddress[];

  acronym?: string;

  type?: string; // from values collection

  websource?: string;

  retired?: boolean;

  originalId?: string; // ID we had in the spreadsheet to identify again

  priority?: number;

  preferredQc?: string; // user-id of who should get correctness and completeness jobs

  transformed?: boolean = false;

  readyForDelivery?: boolean = false;

  /**
   * A boolean describing if the entity was created by the Data Science team.
   */
  automated?: boolean;

  /**
   * A marker that curators can set to highlight a given org during curation for whatever reason they like.
   * If the health system has any orgs with such a marker, job submissions are blocked or will fail.
   */
  markedForLater: boolean = false;

  /**
   * A list of org ids of child orgs with `markedForLater = true` that the root org carries.
   */
  childrenMarkedForLater: string[] = [];

  isMarkedForDeletion?: boolean = false;
  markedForDeletion?: {
    at?: Date;
    by?: string;
  };

  createdAt: Date;
  updatedAt: Date;
  _version: number;

  /** Which product requested the organization first */
  requestOwnerProduct?: string;

  /**
   * When requesting a new organization, capture some more data for central curation.
   * Those metadata are only saved in the resulting job, not in the organization.
   */
  usageInfo?: {
    customerRequest?: boolean;
    comment?: string;
  };

  hasSite?: boolean;
}
