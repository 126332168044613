import { OrganizationSingleSelectComponent } from '../single-select/organization-single-select.component';
import { catchError, Observable, of, switchMap, take, tap, map } from 'rxjs';
import { OrganizationAPI, OrganizationSearchResultDTO } from '../../../organizations/shared/api.service';
import { Component, EventEmitter, Output } from '@angular/core';
import { Organization } from '../../../organizations/shared/organization';
import { OrganizationSelectModalService } from '../../../organizations/shared/select-modal/select-modal.service';

export type AugmentedOrganizationSearchResultDTO = OrganizationSearchResultDTO & {
  affiliationId: string;
};

/**
 * A temporary component that extends OrganizationSingleSelectComponent.
 * Intended to be used while transitioning away from affiliations fully into
 * institutions. Behaves exactly the same, except an extra query is performed
 * to obtain and append mapped affiliation ids.
 *
 * @see {OrganizationSingleSelectComponent}
 * @todo Remove me!
 */
@Component({
  selector: 'dirt-organization-single-select-temp',
  templateUrl: '../single-select/organization-single-select.component.html',
  styleUrls: ['../single-select/organization-single-select.component.scss'],
})
export class OrganizationSingleSelectTempComponent extends OrganizationSingleSelectComponent {
  // TODO: Annoyingly, the types are incorrect here.  The base component claims to return a full Organization,
  //  but really the type should be OrganizationSearchResultDTO.
  @Output()
  organizationSelected = new EventEmitter<Organization | null>();

  constructor(
    protected readonly svcOrganization: OrganizationAPI,
    protected readonly svcOrganizationTreeModal: OrganizationSelectModalService
  ) {
    super(svcOrganization, svcOrganizationTreeModal);
    this.searchOrganizations = this.searchOrganizations.bind(this);
  }

  searchOrganizations(
    ...args: Parameters<OrganizationSingleSelectComponent['searchOrganizations']>
  ): Observable<AugmentedOrganizationSearchResultDTO[]> {
    return super.searchOrganizations(...args).pipe(
      tap(() => {
        this.isSearching = true;
      }),
      switchMap((results) => {
        const organizationIds = results.map(({ _id }) => _id);

        if (organizationIds.length === 0) {
          return of([] as AugmentedOrganizationSearchResultDTO[]);
        }

        return this.svcOrganization.getOrganizationMatchedAffiliationMappings({ organizationIds }).pipe(
          map((mappings) => {
            return results.reduce<AugmentedOrganizationSearchResultDTO[]>((accumulation, nextResult) => {
              const mapping = mappings.find((m) => m.organizationId === nextResult._id);

              if (mapping) {
                accumulation.push({
                  ...nextResult,
                  affiliationId: mapping.affiliationId,
                });
              }

              return accumulation;
            }, []);
          })
        );
      }),
      catchError(() => of([] as AugmentedOrganizationSearchResultDTO[])),
      tap(() => {
        this.isSearching = false;
      })
    );
  }

  async onOpenTree(event: MouseEvent = null, organizationId: string): Promise<void> {
    if (event) {
      event.stopPropagation();
    }

    try {
      const { organization, affiliationId } = await this.svcOrganizationTreeModal.open(organizationId);
      const augmentedOrganization = { ...organization, affiliationId };
      this.organization = augmentedOrganization;
      this.onChangeOrganization(augmentedOrganization);
    } catch (error) {
      // The modal was closed
      return;
    }
  }
}
