<div class="modal-header">
  <h4 class="modal-title">Create Address</h4>

  <button type="button" class="close" aria-label="Close" (click)="onCancel()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body row">
  <div class="col-12">
    <form #form="ngForm" novalidate>
      <!-- Address -->
      <div class="form-group row">
        <label class="col-form-label col-sm-4 col-md-2">Address</label>
        <div class="col-sm-8 col-md-10">
          <dirt-address
            #addressForm
            [(address)]="address"
            [additional]="true"
            [withHouseNo]="true"
            [lenientState]="true"
            [mandatory]="true"
          >
          </dirt-address>
        </div>
      </div>

      <!-- Websource -->
      <div class="form-group row">
        <label class="col-form-label col-sm-4 col-md-2">Websource</label>
        <div class="col-sm-8 col-md-10">
          <dirt-link [(url)]="address.websource" name="websource" [form]="form"></dirt-link>
        </div>
      </div>
    </form>
  </div>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-secondary" [disabled]="isSubmitting" (click)="onCancel()">Cancel</button>

  <button
    type="submit"
    class="btn btn-primary"
    [ngClass]="{ 'btn-loading': isSubmitting }"
    [disabled]="!addressForm.isValid() || !form.valid || isSubmitting"
    (click)="onCreate()"
  >
    Create
  </button>
</div>
