import { Component } from '@angular/core';

@Component({
  selector: 'dirt-organization',
  template: `
    <dirt-admin-header></dirt-admin-header>
    <div class="container-fluid">
      <section class="dirt-navbar-sub">
        <router-outlet></router-outlet>
      </section>
    </div>
  `,
})
export class OrganizationComponent {}
