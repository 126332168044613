import { Component, Injector, OnInit, ViewChild, ElementRef, HostListener, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer, SafeUrl, Title } from '@angular/platform-browser';
import { of, Observable, firstValueFrom } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { get } from 'lodash';

import { ACL } from '../../shared/acl/acl.service';
import { Profile } from '../shared/profile';
import { ProfileAPI } from '../shared/api.service';
import { Person } from '../../person/shared/person';
import { ProfilePaymentAPI } from '../subsection/payment/shared/api.service';
import { ProfileMembershipAPI } from '../subsection/membership/shared/api.service';
import { ProfileGuidelineAPI } from '../subsection/guideline/shared/api.service';
import { ProfileContributionAPI } from '../subsection/contribution/shared/api.service';
import { ProfileClinicalTrialAPI } from '../subsection/clinical-trial/shared/api.service';
import { ProfilePublicationAPI } from '../subsection/publication/shared/api.service';
import { ProfileStatus } from '../shared/constant/status.enum';
import { ProfileSection, ProfileSectionServices } from '../shared/constant/section.enum';
import { ProfileTag } from '../shared/constant/profile-tag.enum';
import { Roles } from '../../shared/acl/roles';
import { ProfileMatchManual } from '../shared/constant/match-manual.enum';
import { ValueType } from '../../shared/enum/value-type.enum';
import { ValueAPI } from '../../shared/services/value/value-api.service';
import { Value } from '../../shared/services/value/value';
import { PersonAPI } from '../../person/shared/api.service';
import { Auth } from '../../shared/services/auth/auth.service';
import { sortAffiliations } from '../../person/shared/person-affiliation-util';
import { deleteEmptyKeyFields, setOnSaveValues } from '../../person/shared/person-util';
import { UserGroupAPI } from '../../user-group/shared/api.service';
import { UserGroup } from '../../user-group/shared/user-group';
import { TimeReportComponent } from '../../common/timetrack/components/time-report-component/time-report.component';
import { TrainingReportDelegate } from '../shared/training-report-delegate';
import { CommentAPI } from '../../comment/shared/api.service';
import { ProfilePersonFormComponent } from '../person-form/form.component';
import { Utils } from '../../common/utils';
import { PersonAffiliation } from '../../person/shared/person-affiliation';
import { PersonAffiliationLfka } from '../../person/shared/person-affiliation-lfka';
import { ProfilePaymentNonusAPI } from '../subsection/payment-nonus/shared/api.service';
import { ProfileWorkstream } from '../shared/constant/workstream.enum';
import { ProfileGrantAPI } from '../subsection/grant/shared/api.service';
import { JobsAPI } from '../../jobs/shared/api.service';
import { Job } from '../../jobs/shared/job';
import { ProfilePodcastAPI } from '../subsection/podcast/shared/api.service';
import { Source } from '../../video/shared/video';
import { ProfileVideoAPI } from '../subsection/video/shared/api.service';
import { UnmappedPersonAffiliationsModalService } from '../../person/shared/modal/unmapped-person-affiliations/unmapped-person-affiliations.service';

@Component({
  selector: 'dirt-profile-detail',
  templateUrl: 'detail.component.html',
  styleUrls: ['detail.component.scss'],
})
export class ProfileDetailComponent implements OnInit, AfterViewInit {
  profile: Profile;

  isSavingStatus = false;
  isSavingVerified: boolean;
  isModeQA = false;
  isModeML = false; // (also show ML)
  isLoadingRegions: boolean;
  isSubmittingPerson: boolean = false;
  isLoadingTrainingReport: boolean = false;

  profileStatuses = ProfileStatus;
  profileStatusesAssignable = [ProfileStatus.COMPILATION_IN_PROGRESS];

  activeSection: string | null;
  profileSections = ProfileSection;
  selectorSections = ProfileSectionServices;

  specialtyList: Value[] = [];
  autoTags: string[] = [];
  regionLocationList = [];
  degreesList: Value[] = [];

  reviewCounts: { [activity: string]: number } = {};
  reviewCountsMatch: { [activity: string]: number } = {};

  profileVideoIds: { source: Source; vendorId: string }[] | null = null;

  userGroupListForApac: UserGroup[] = [];

  excludedAuditLogFields = [
    'clinical-trial',
    'contributions',
    'guidelines',
    'memberships',
    'payments',
    'payments-nonus',
    'publications',
  ];

  unpolishedDps = 0;
  mandatoryUnpolishedDps = 0;
  subentitiesUnpolishedDps;

  youtubeAlreadyWatchedBookmarkletUrl: SafeUrl | null = null;

  @ViewChild('commentList') commentList;
  isModalManual = false;

  @ViewChild('timeReportComponent')
  timeReportComponent: TimeReportComponent;

  @ViewChild('freezeHeaderSection', { static: false }) freezeHeaderSection: ElementRef;

  @ViewChild('frmProfilePerson', { static: false }) frmProfilePerson: ProfilePersonFormComponent;

  freeze: boolean = false;
  elementPosition: any;

  myUserId: string;
  loadingProfilePerson: boolean = false;
  profilePerson: Person = null;
  showPersonData: boolean = false;
  originalPrimaryAddress: any = null;
  basePerson: Person = new Person();
  baseProfile: Profile = new Profile();
  trainingReport: {
    compilerId?: string;
    comment?: string;
    reportDetails?: any;
  };
  lftaPrimaryAffiliation: PersonAffiliation;
  lfkaPrimaryPositionAffilition: PersonAffiliationLfka;

  private formOpenedOnce: boolean = false;

  /** store a copy of the DB value and validity when form is closed */
  private lastFormValue: Person;
  disablePersonForm: boolean;

  isSavingMatches: boolean;

  isProfileCompiler: boolean;

  LFKA_TAGS: string[] = [];
  profileJob: Job;
  isSubmittingJob: boolean;

  products: string[];
  private projectNames: Value[];
  getProductColor = Utils.getProductColor;

  private euCountries: string[];

  isRequestingImageMaintenance = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private injector: Injector,
    private svcAcl: ACL,
    private svcAuth: Auth,
    private svcPerson: PersonAPI,
    private svcProfile: ProfileAPI,
    private svcProfileVideo: ProfileVideoAPI,
    private svcModal: NgbModal,
    private svcProfilePayment: ProfilePaymentAPI,
    private svcProfilePaymentNonus: ProfilePaymentNonusAPI,
    private svcProfileMembership: ProfileMembershipAPI,
    private svcProfileGuideline: ProfileGuidelineAPI,
    private svcProfileContribution: ProfileContributionAPI,
    private svcProfileClinicalTrial: ProfileClinicalTrialAPI,
    private svcProfilePublication: ProfilePublicationAPI,
    private svcProfileGrant: ProfileGrantAPI,
    private svcProfilePodcast: ProfilePodcastAPI,
    private svcValue: ValueAPI,
    private svcUserGroup: UserGroupAPI,
    private svcComment: CommentAPI,
    private svcJob: JobsAPI,
    private sanitizer: DomSanitizer,
    private titleService: Title,
    private svcUPAModal: UnmappedPersonAffiliationsModalService
  ) {}

  ngOnInit() {
    this.isProfileCompiler = this.svcAcl.hasRole(Roles.ProfileCompiler);

    this.loadProfile();

    this.svcValue
      .find(ValueType.Specialty, Number.MAX_SAFE_INTEGER, 0, '+title')
      .toPromise()
      .then((data) => {
        this.specialtyList = data;
      });

    this.svcUserGroup
      .find()
      .toPromise()
      .then((data) => {
        this.userGroupListForApac = data.filter((cn) => cn.name === 'APAC I' || cn.name === 'APAC II');
      });

    this.svcValue
      .find(ValueType.PersonProject, Number.MAX_SAFE_INTEGER, 0, undefined, { product: 'LFKA' })
      .toPromise()
      .then((data) => {
        this.LFKA_TAGS = data.map((item) => item.code as string);
      });

    this.svcValue
      .find(ValueType.Degree, Number.MAX_SAFE_INTEGER, 0)
      .toPromise()
      .then((data) => {
        this.degreesList = data;
      });

    this.svcValue
      .find(ValueType.EUCountries, 1)
      .pipe(take(1))
      .subscribe((_euc) => (this.euCountries = (_euc[0] || {}).value || []));

    this.disablePersonForm =
      this.svcAcl.hasRole(Roles.PersonCompilerIdent) ||
      (this.svcAcl.hasRole(Roles.PersonViewer) && this.svcAcl.hasRole(Roles.PersonCompiler)); // same as person form as such

    this.initRegion();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.freezeHeaderSection) {
        this.elementPosition = this.freezeHeaderSection.nativeElement.offsetTop;
      } else {
        this.ngAfterViewInit(); // try again later
      }
    }, 3000);
  }

  getValueTitle(values: Value[], code: string) {
    return (values.find((o) => o.code === code) || { title: '' }).title;
  }

  onProfileChanged(changed: { id }[]) {
    this.setUnpolishedDp();
    this.timeReportComponent.trackSubmittedChange((changed || []).map((_c) => _c.id).filter((_id) => !!_id));
  }

  onStatusChanged(_meta: Profile['_meta']): void {
    this.isSavingStatus = true;

    this.svcProfile.update(this.profile.id, <Profile>{ _meta }).subscribe(
      (resp: Profile) => this.getMergedResponse(this.profile, resp),
      null,
      () => (this.isSavingStatus = false)
    );
  }

  async setQAEnabled(enabled: boolean) {
    this.isModeQA = enabled;
    return await this.setAvailableSections();
  }

  setMLEnabled(enabled: boolean) {
    this.isModeML = enabled;
    this.setUnpolishedDp();
  }

  onUnableToCompile(): void {
    if (!window.confirm('Are you sure?')) {
      return;
    }

    const val = <Profile>({
      published: true,
      _meta: { ...this.profile._meta, status: ProfileStatus.UNABLE_TO_COMPILE },
    } as unknown);

    this.svcProfile.update(this.profile.id, val).subscribe(() => this.goBack());
  }

  skipVerification(): void {
    if (!window.confirm('Are you sure?')) {
      return;
    }

    const val = <Profile>{
      verificationSkipped: true,
    };

    this.svcProfile.update(this.profile.id, val).subscribe(() => this.goBackToHomePage());
  }

  doOpenModal(content) {
    this.isModalManual = true;
    this.svcModal.open(content);
  }

  notIfManual(fct) {
    if (!this.isModalManual) {
      fct();
    }
  }

  onModalKeyPress(e: KeyboardEvent) {
    e.stopPropagation();
  }

  canCreateComment(): boolean {
    return this.svcAcl.hasCredential('profile.comment.create');
  }

  canUpdateComment(): boolean {
    return this.svcAcl.hasCredential('profile.comment.update');
  }

  canDeleteComment(): boolean {
    return this.svcAcl.hasCredential('profile.comment.delete');
  }

  onVerifiedChange(verified: boolean): void {
    this.isSavingVerified = true;

    const profile = <Profile>({ verified } as unknown);

    this.svcProfile.update(this.profile.id, profile).subscribe(
      (resp: Profile) => {
        this.getMergedResponse(this.profile, resp);
        Object.assign(this.profile, { verified });
      },
      null,
      () => (this.isSavingVerified = false)
    );
  }

  hasQcPermission(): boolean {
    return this.svcAcl.hasCredential('profile.qc');
  }

  private loadProfile(hideComments?: boolean): void {
    this.route.params
      .pipe(
        map((params) => params['id']),
        switchMap((id) => this.svcProfile.findById(id)),
        switchMap((profile) => this.svcComment.count(profile.id).pipe(map(({ count }) => ({ profile, count }))))
      )
      .subscribe(async ({ profile, count }) => {
        if (profile.baseProfileId && profile._meta.status === 'DONE') {
          await this.loadBaseProfile(profile.baseProfileId);
          const hasReport = !!profile.trainingEvaluationReport;
          if (this.hasTrainingPermission()) {
            if (hasReport) {
              // inform that we are re evaluating everytime
              alert('This training profile has an evaluation report already - re evaluting the report');
            }
            this.isLoadingTrainingReport = true;
            await this.getDPTrainingReport(profile.id, profile.trainingEvaluationReport);
          } else if (profile.trainingEvaluationReport) {
            this.trainingReport = profile.trainingEvaluationReport;
          }
        }
        this.profile = profile;
        this.titleService.setTitle(
          `cLean | Profile | ${this.profile.person.firstName + ' ' + this.profile.person.lastName}`
        );

        const user = await firstValueFrom(this.svcAuth.getProfile());
        this.myUserId = user.user_id;

        // Only get job details if user is the one working it
        if (profile._meta?.jobId && profile._meta?.assignee === this.myUserId) {
          this.svcJob
            .findById(profile._meta.jobId)
            .pipe(take(1))
            .subscribe((j) => {
              this.profileJob = j;
            });
        }

        this.svcValue
          .find(ValueType.PersonProject, Number.MAX_SAFE_INTEGER)
          .toPromise()
          .then((data) => {
            this.projectNames = data;
            this.autoTags = data.filter((item) => item.autoTag).map((item) => item.code as string);
            this.products = this.getKOLProducts();
          });

        this.loadAffiliation();

        // (now we can go ahead)
        await this.setAvailableSections();
        await this.setUnpolishedDp();

        if (count === 0) {
          // do not show the modal if there is no comment
          return;
        }

        if (
          (this.svcAcl.hasRole(Roles.ProfileCompiler) || this.svcAcl.hasRole(Roles.ProfileReviewer)) &&
          !hideComments
        ) {
          this.svcModal.open(this.commentList);
        }
      });
  }

  // display only, doesnt react to change
  loadAffiliation(): void {
    this.svcPerson.findById(this.profile.person.id).subscribe((person) => {
      this.lftaPrimaryAffiliation = (person.affiliations || []).find((aff) => aff.primary === true);
      this.lfkaPrimaryPositionAffilition = (person.affiliationsLfka || []).find((aff) =>
        aff.positions?.some((pos) => pos.primary === true)
      );
    });
  }

  private async setUnpolishedDp() {
    const filter: any = {
      'match.manual': {
        filterType: 'set',
        values: [ProfileMatchManual.NONE],
      },
    };

    if (!this.isModeML) {
      filter.mlExclude = false; // mlAnyway will come regardless (see API)
    }

    const sectionNames = Object.keys(this.selectorSections);
    const promises = [];
    const promisesTypes = [];

    for (let i = 0; i < sectionNames.length; ++i) {
      const selSection = this.selectorSections[sectionNames[i]];
      const sectionRoles = selSection.roles;
      const hasPermission =
        sectionRoles && sectionRoles && sectionRoles.find((secRole) => this.svcAcl.hasRole(secRole));

      if (!hasPermission) {
        continue;
      }

      if (selSection.toggle && !localStorage.getItem(selSection.toggle)) {
        // also exclude feature toggles
        continue;
      }

      switch (selSection.id) {
        case 'publications':
          promises.push(this.svcProfilePublication.count(this.profile /*new way*/, filter).toPromise());
          promisesTypes.push(ProfileSection.PUBLICATIONS);
          break;
        case 'events':
          promises.push(this.svcProfileContribution.count(this.profile /*new way*/, filter).toPromise());
          promisesTypes.push(ProfileSection.EVENTS);
          break;
        case 'clinical-trials':
          promises.push(this.svcProfileClinicalTrial.count(this.profile /*new way*/, filter).toPromise());
          promisesTypes.push(ProfileSection.CLINICAL_TRIALS);
          break;
        case 'payments':
          promises.push(this.svcProfilePayment.count(this.profile.id, filter).toPromise());
          promisesTypes.push(ProfileSection.PAYMENTS_US);
          break;
        case 'payments-nonus':
          promises.push(this.svcProfilePaymentNonus.count(this.profile.id, filter).toPromise());
          promisesTypes.push(ProfileSection.PAYMENTS_NONUS);
          break;
        case 'associations':
          promises.push(this.svcProfileMembership.count(this.profile /*new way*/, filter).toPromise());
          promisesTypes.push(ProfileSection.ASSOCIATIONS);
          break;
        case 'guidelines':
          promises.push(this.svcProfileGuideline.count(this.profile /*new way*/, filter).toPromise());
          promisesTypes.push(ProfileSection.GUIDELINES);
          break;
        case 'grants':
          promises.push(this.svcProfileGrant.count(this.profile.id, filter).toPromise());
          promisesTypes.push(ProfileSection.GRANTS);
          break;
        case 'podcasts':
          promises.push(firstValueFrom(this.svcProfilePodcast.count(this.profile /*new way*/, filter)));
          promisesTypes.push(ProfileSection.PODCASTS);
          break;
      }
    }
    if (!promises.length) {
      return;
    }

    const unpolishedDpCounts = await Promise.all(promises); // unpolishedDpCounts has same indexes as promises

    const unpolishedByType = (t) => {
      const ind = promisesTypes.indexOf(t);
      if (ind < 0) {
        return { count: 0 };
      }
      return unpolishedDpCounts[ind];
    };

    this.subentitiesUnpolishedDps = {
      // TODO: just go over all types we have?
      [ProfileSection.ASSOCIATIONS]: unpolishedByType(ProfileSection.ASSOCIATIONS).count,
      [ProfileSection.CLINICAL_TRIALS]: unpolishedByType(ProfileSection.CLINICAL_TRIALS).count,
      [ProfileSection.EVENTS]: unpolishedByType(ProfileSection.EVENTS).count,
      [ProfileSection.GUIDELINES]: unpolishedByType(ProfileSection.GUIDELINES).count,
      [ProfileSection.PAYMENTS_US]: unpolishedByType(ProfileSection.PAYMENTS_US).count,
      [ProfileSection.PAYMENTS_NONUS]: unpolishedByType(ProfileSection.PAYMENTS_NONUS).count,
      [ProfileSection.PUBLICATIONS]: unpolishedByType(ProfileSection.PUBLICATIONS).count,
      [ProfileSection.GRANTS]: unpolishedByType(ProfileSection.GRANTS).count,
      [ProfileSection.PODCASTS]: unpolishedByType(ProfileSection.PODCASTS).count,
    };
    this.unpolishedDps = unpolishedDpCounts.reduce((sum, o) => sum + (o.count || 0), 0);
    // server-side validation so far xcludes e.g. grants and podcasts - so we do as well (get in sync with subentity.ts on the server)
    this.mandatoryUnpolishedDps = [
      ProfileSection.ASSOCIATIONS,
      ProfileSection.CLINICAL_TRIALS,
      ProfileSection.EVENTS,
      ProfileSection.GUIDELINES,
      ProfileSection.PAYMENTS_US,
      ProfileSection.PAYMENTS_NONUS,
      ProfileSection.PUBLICATIONS,
    ]
      .map((type) => unpolishedByType(type))
      .reduce((sum, o) => sum + (o.count || 0), 0);
  }

  private async setAvailableSections() {
    const canViewEmptySection = this.svcAcl.hasCredential('profile.subsection.list.viewEmpty');
    const sectionNames = Object.keys(this.selectorSections);
    let filter: any = null;

    if (this.isModeQA) {
      filter = { lastPolished: true };
    }

    for (let i = 0; i < sectionNames.length; ++i) {
      const selSection = this.selectorSections[sectionNames[i]];

      if (!selSection) {
        continue;
      }

      const sectionRoles = selSection.roles;
      const hasPermission = sectionRoles?.find((secRole) => this.svcAcl.hasRole(secRole));

      if (!hasPermission) {
        selSection.enabled = false;
        continue;
      }

      if (selSection.toggle && !localStorage.getItem(selSection.toggle)) {
        // also exclude feature toggles
        selSection.enabled = false;
        continue;
      }

      if (canViewEmptySection) {
        selSection.enabled = true;
      } else if (selSection.service) {
        // call sub-section count
        const result = await this.injector
          .get(selSection.service)
          .count(selSection.newCount ? this.profile : this.profile.id, filter)
          .toPromise();

        selSection.enabled = result.count > 0;
      }

      // set the first enabled section as active
      if (selSection.enabled && !this.activeSection) {
        this.activeSection = sectionNames[i];
      }
    }

    // fallback to Publications
    if (!this.activeSection) {
      this.activeSection = this.profileSections.PUBLICATIONS;
    }

    this.onSectionChange(this.activeSection as any);
  }

  isTagsEditable(): boolean {
    return this.svcAcl.hasCredential('profile.tags');
  }

  onProfileTagsChange(tags: ProfileTag[]) {
    const data = <Profile>{ tags: tags };
    this.svcProfile
      .update(this.profile.id, data)
      .subscribe((resp: Profile) => this.getMergedResponse(this.profile, resp));
  }

  private getMergedResponse(local: Profile, remove: Profile) {
    local.updatedAt = remove.updatedAt;
    local._version = remove._version;
    local._meta = remove._meta;
  }

  private goBack() {
    this.router.navigate(['/profile/list']);
  }

  onAffiliationClick() {
    this.svcPerson
      .findById(this.profile.person.id)
      .toPromise()
      .then((person) => {
        const searchURL =
          this.checkForApacCountryCode(person) === 'APAC I'
            ? `https://www.baidu.com/s?wd=${person.originalFullName ? person.originalFullName : person.lastName}`
            : this.checkForApacCountryCode(person) === 'APAC II'
            ? `https://www.google.com/search?q=${person.originalFullName ? person.originalFullName : person.lastName}`
            : `https://www.google.com/search?q=${person.firstName} ${person.lastName}`;
        person.cvLinks.forEach((link) => {
          window.open(link);
        });
        (person.specialties || []).forEach((code) => {
          const specialty = this.getValueTitle(this.specialtyList, code);
          window.open(`${searchURL}  ${specialty}`);
        });
      });
  }

  openMaps(address: any) {
    this.svcPerson
      .findById(this.profile.person.id)
      .toPromise()
      .then((person) => {
        let mapUrl =
          this.checkForApacCountryCode(person) === 'APAC I'
            ? `https://api.map.baidu.com/geocoder?address=${address.formatted}&output=html`
            : `https://www.google.com/maps/search/?api=1&query=${address.formatted}`;
        window.open(mapUrl);
      });
  }

  checkForApacCountryCode(person: Person): String {
    if (person) {
      let Cd;
      if (person.affiliations) {
        Cd = person.affiliations.find((aff) => aff.primary === true);
        if (Cd) Cd = Cd.address ? Cd.address.countryCode : '';
        else Cd = '';
      } else if (person.countryWorkflow) Cd = person.countryWorkflow;
      else Cd = '';
      return this.userGroupListForApac.find((cn) => cn.countries.includes(Cd))
        ? this.userGroupListForApac.find((cn) => cn.countries.includes(Cd)).name
        : 'false';
    }
    return 'false';
  }

  async onFinishReview() {
    if (!this.hasQcPermission() || this.isSavingVerified) {
      return;
    }

    this.isSavingVerified = true;

    const profile = <Profile>({ verified: true } as unknown);

    this.svcProfile.update(this.profile.id, profile).subscribe(
      async (resp: Profile) => {
        this.getMergedResponse(this.profile, resp);
        Object.assign(this.profile, profile);
        // (we did automatically set the person verified - no more!)
        this.goBackToHomePage();
      },
      null,
      () => (this.isSavingVerified = false)
    );
  }

  isAutoProject(profile: Profile) {
    if (!profile.person || !profile.person.projectNames) {
      return;
    }
    return profile.person.projectNames.some((item) => this.autoTags.indexOf(item) > -1);
  }

  isClaimedByOtherUser(): boolean {
    return (
      this.profile._meta &&
      this.profile._meta.claimedUntil &&
      new Date(this.profile._meta.claimedUntil) > new Date() &&
      this.profile._meta.assignee &&
      this.profile._meta.assignee !== this.myUserId
    );
  }

  togglePersonDataView() {
    if (this.loadingProfilePerson || this.isSubmittingPerson) {
      return;
    }

    if (this.showPersonData) {
      // we close without doing base data
      this.hidePersonData();
      return; // (anyhow don't continue)
    } else {
      this.lastFormValue = null;
    }

    if (this.lastFormValue) {
      this.profilePerson = this.lastFormValue;
      sortAffiliations(this.profilePerson);
      this.originalPrimaryAddress = this.getPrimaryAddress();
      this.showPersonData = !this.showPersonData;
    } else {
      // load from DB
      this.loadingProfilePerson = true;
      this.svcPerson
        .findById(this.profile.person.id)
        .toPromise()
        .then((person) => {
          this.profilePerson = person;
          sortAffiliations(this.profilePerson);
          this.originalPrimaryAddress = this.getPrimaryAddress();
          this.showPersonData = !this.showPersonData;
          this.loadingProfilePerson = false;
        });
    }
  }

  hidePersonData(cancel = false) {
    if (cancel) {
      this.lastFormValue = null;
    } else {
      // store a copy of the form value
      this.lastFormValue = this.frmProfilePerson.getValue();
    }

    this.profilePerson = null;
    this.originalPrimaryAddress = null;
    this.showPersonData = false;
  }

  onPersonSave(person: Person, partial?: boolean): void {
    setOnSaveValues(person);
    this.updatePerson(person, partial);
  }

  async updatePerson(person: Person, partial?: boolean): Promise<void> {
    let isDeceased = person.status === 'DECEASED';
    if (isDeceased && !this.confirmDelete()) {
      return;
    }
    this.isSubmittingPerson = true;
    deleteEmptyKeyFields(person);
    sortAffiliations(person);

    const { unmappedAffiliations } = await firstValueFrom(this.svcPerson.checkAffiliations(person));
    if (unmappedAffiliations.length > 0) {
      const continueAnyways = await this.svcUPAModal.open(unmappedAffiliations);
      if (continueAnyways !== true) {
        this.isSavingVerified = false;
        return;
      }
    }

    const observable = partial ? this.svcPerson.update(this.profilePerson.id, person) : this.svcPerson.upsert(person);

    observable.subscribe(
      () => {
        this.isSubmittingPerson = false;
        this.hidePersonData(true);
        if (isDeceased) {
          this.goBackToHomePage();
        } else {
          this.loadProfile(true);
        }
      },
      () => (this.isSubmittingPerson = false)
    );
  }

  isUserManager(): boolean {
    return this.svcAcl.hasRole(Roles.Admin) || this.svcAcl.hasRole(Roles.ProfileManager);
  }

  initRegion(): void {
    this.isLoadingRegions = true;

    this.svcUserGroup.find(Number.MAX_SAFE_INTEGER).subscribe(
      (resp) => (this.regionLocationList = resp.map((u) => [u.name, u.countries])),
      null,
      () => (this.isLoadingRegions = false)
    );
  }

  private getPrimaryAddress() {
    const primaryAffiliation = this.profilePerson.affiliations.find((a) => a.primary);

    if (primaryAffiliation) {
      // return a copy not a reference
      return Object.assign({}, primaryAffiliation.address); // TODO: no clone, return domain object (for all such forms); use ngOnChanges+change handlers for helper variables
    }
  }

  isUserProfileCompiler() {
    return this.svcAcl.hasRole(Roles.ProfileCompiler);
  }

  navigateToBaseData() {
    if (!this.isUserProfileCompiler()) {
      this.router.navigate([`/person/detail/${this.profile.person.id}`]);
    }
  }

  confirmDelete(): boolean {
    let message = 'Profile will be deleted as Deceased. Do you want to continue?';
    return window.confirm(message);
  }

  @HostListener('window:scroll', ['$event'])
  handleScroll(event) {
    const scrlEvt = event.srcElement.scrollTop ? event.srcElement : event.srcElement.scrollingElement;
    const windowScroll = scrlEvt ? scrlEvt.scrollTop - scrlEvt.offsetTop : 0;
    windowScroll >= this.elementPosition ? (this.freeze = true) : (this.freeze = false);
  }

  async loadBaseProfile(baseProfileId) {
    if (!baseProfileId) {
      return;
    }

    this.baseProfile = await this.svcProfile.findById(baseProfileId).toPromise();
    this.basePerson = await this.svcPerson.findById(this.baseProfile.person.id).toPromise();
    sortAffiliations(this.basePerson);
  }

  hasBDErrorReport() {
    return !!(
      (this.profile && this.profile.person.trainingEvaluationReport) ||
      (this.profilePerson && this.profilePerson.trainingEvaluationReport) ||
      {}
    ).reportDetails;
  }
  getPersonTrainingErrorRate() {
    const report = (
      (this.profilePerson && this.profilePerson.trainingEvaluationReport) ||
      (this.profile && this.profile.person.trainingEvaluationReport) ||
      {}
    ).reportDetails;
    if (!report) return 'n/a';
    return Utils.getPersonOverallErrorRate(report);
  }

  getDPTrainingReport(profileId: string, currentReport) {
    this.svcProfile.trainingReport(profileId).subscribe(
      (report) => {
        const syncedReport = {};
        if (!currentReport) {
          this.trainingReport = {
            reportDetails: report,
          };
        } else {
          Object.keys(report).forEach((subentity) => {
            const subentityReport = report[subentity].map((item) => {
              const oldReportItem = currentReport.reportDetails[subentity].find((cRepItem) => cRepItem.id === item.id);
              if (oldReportItem && oldReportItem.markAsCorrect) {
                return {
                  ...item,
                  markAsCorrect: oldReportItem.markAsCorrect,
                };
              }
              return { ...item };
            });
            syncedReport[subentity] = subentityReport;
          });
          this.trainingReport = {
            comment: currentReport.comment,
            reportDetails: syncedReport,
          };
        }
        this.isLoadingTrainingReport = false;
      },
      (err) => (this.isLoadingTrainingReport = false),
      () => {}
    );
  }

  getReport(subEntity: string, id: string) {
    if (
      this.trainingReport &&
      this.trainingReport.reportDetails &&
      this.trainingReport.reportDetails[subEntity] &&
      this.trainingReport.reportDetails[subEntity].length
    ) {
      return this.trainingReport.reportDetails[subEntity].find((report) => report.id === id);
    }
  }

  updateTrainingReport(subEntity: string, id: string, markAsCorrect?: boolean): void {
    const report = this.trainingReport.reportDetails[subEntity];
    let updatedItem;
    const updatedReport = report.map((item) => {
      if (item.id !== id) {
        return item;
      }
      updatedItem = {
        ...item,
        markAsCorrect,
      };
      return updatedItem;
    });
    this.trainingReport.reportDetails[subEntity] = updatedReport;
    return updatedItem;
  }

  hasTrainingReport() {
    return !!this.trainingReport;
  }

  hasTrainingPermission(): boolean {
    return this.svcAcl.hasCredential('profile.training');
  }

  isTrainingProfile() {
    return this.profile && this.profile.training;
  }

  hasTrainingFeaturesEnabled() {
    return this.hasTrainingPermission() && this.isTrainingProfile() && this.hasTrainingReport();
  }

  showBDTrainingReport() {
    const bdCompilerId = get(this.profile, 'person.trainingEvaluationReport.compilerId');
    const comment = get(this.profile, 'person.trainingEvaluationReport.comment');
    return this.myUserId === bdCompilerId && !!comment;
  }

  showProfileDPTrainingReport() {
    const compilerId = get(this.profile, 'trainingEvaluationReport.compilerId');
    const comment = get(this.profile, 'trainingEvaluationReport.comment');
    return this.myUserId === compilerId && !!comment;
  }

  getProfileTrainingReportDelegates(subEntity: string): TrainingReportDelegate {
    return {
      report: this.trainingReport && this.trainingReport.reportDetails,
      comment: this.trainingReport && this.trainingReport.comment,
      saveReport: this.saveProfileReport.bind(this),
      getReportItem: this.getReport.bind(this, subEntity),
      updateReportItem: this.updateTrainingReport.bind(this, subEntity),
      hasTrainingFeaturesEnabled: this.hasTrainingFeaturesEnabled.bind(this),
      showProfileDPTrainingReport: this.showProfileDPTrainingReport.bind(this),
    };
  }

  saveProfileReport(comment: string): void {
    const profile = <Profile>({
      trainingEvaluationReport: {
        compilerId: this.profile.polishedBy,
        comment,
        reportDetails: this.trainingReport.reportDetails,
      },
    } as unknown);

    this.svcProfile.update(this.profile.id, profile).subscribe(
      (resp: Profile) => {},
      null,
      () => {}
    );
  }

  savePersonReport(comment: string) {
    const reviewBy = this.profilePerson.review && this.profilePerson.review.by;
    const compilerId = typeof reviewBy === 'string' ? reviewBy : (reviewBy || {}).user_id;
    const report = {
      comment,
      compilerId,
      reportDetails: this.profilePerson.trainingEvaluationReport.reportDetails,
    };
    const person = <Person>({ trainingEvaluationReport: report } as unknown);
    this.profilePerson.trainingEvaluationReport.comment = comment;
    this.onPersonSave(person, true);
  }

  getErrorRateForSubEntity(subEntityKey: string) {
    const keyMap = {
      ASSOCIATIONS: 'memberships',
      EVENTS: 'contributions',
      CLINICAL_TRIALS: 'clinical-trial',
      GUIDELINES: 'guidelines',
      PAYMENTS_US: 'payments',
      PAYMENTS_NONUS: 'payments-nonus',
      PUBLICATIONS: 'publications',
    };
    const report =
      this.trainingReport &&
      this.trainingReport.reportDetails &&
      this.trainingReport.reportDetails[keyMap[subEntityKey]];
    if (!report) {
      return 'n/a';
    }
    const errors = report.filter((item) => !item.isCorrect && !item.markAsCorrect);
    return `${((errors.length / Math.max(report.length, 1)) * 100).toFixed(2)}%`;
  }

  getOverallErrorRateForDPs() {
    const reportDetails = this.trainingReport && this.trainingReport.reportDetails && this.trainingReport.reportDetails;
    if (!reportDetails) {
      return 'n/a';
    }
    let errors = 0;
    let totalDps = 0;

    Object.keys(reportDetails).forEach((key) => {
      const eachReport = reportDetails[key];
      if (!eachReport) {
        return;
      }
      const eachErrors = eachReport.filter((item) => !item.isCorrect && !item.markAsCorrect);
      errors += eachErrors.length;
      totalDps += eachReport.length;
    });

    return `${((errors / Math.max(totalDps, 1)) * 100).toFixed(2)}%`;
  }

  getBreakdownErrorRate(path) {
    const report = (
      (this.profilePerson && this.profilePerson.trainingEvaluationReport) ||
      (this.profile && this.profile.person.trainingEvaluationReport) ||
      {}
    ).reportDetails;
    return Utils.getFieldLevelErrorRate(path, report);
  }

  getNameErrorRate() {
    const report = (
      (this.profilePerson && this.profilePerson.trainingEvaluationReport) ||
      (this.profile && this.profile.person.trainingEvaluationReport) ||
      {}
    ).reportDetails;
    if (!report) return 'n/a';
    return Utils.getNameErrorRates(report);
  }

  onProfileNameClick() {
    this.isUserProfileCompiler() ? this.onAffiliationClick() : window.open(`/person/detail/${this.profile.person.id}`);
  }

  private goBackToHomePage(): void {
    if (this.canSubmitJob() && this.hasJobForCurrentUser()) {
      this.router.navigate(['/next']);
    } else {
      this.router.navigate(['/']);
    }
  }

  onCopyID(copyValID: any): void {
    navigator.clipboard.writeText(copyValID);
  }

  onSubmitVideos() {
    let message = 'Do you really want to submit this profile?';
    message += '\nNote: By submitting this profile, you will not be having any access to it anymore!';

    if (!window.confirm(message)) {
      return;
    }
    this.svcProfile.submitVideos(this.profile.id).subscribe(() => this.goBackToHomePage());
  }

  checkIfCubeProject(): boolean {
    return !!this.profile?.person?.projectNames?.includes('CUBE');
  }
  checkIfKAMProject() {
    // (only show KA-organizations at all when we're at least a little bit KA)
    return !!this.profile?.person?.projectNames?.find((p) => this.LFKA_TAGS.includes(p));
  }

  setIsSavingMatches(isSaving: boolean) {
    this.isSavingMatches = isSaving;
  }

  onRequestImageMaintenance(): void {
    if (this.isRequestingImageMaintenance) {
      return;
    }

    this.isRequestingImageMaintenance = true;
    this.svcPerson
      .requestImageMaintenance(this.profile.person.kolId)
      .pipe(tap(() => (this.isRequestingImageMaintenance = false)))
      .subscribe({
        complete: () => alert('Successfully requested image maintenance'),
        error: () => alert('Failed to request image maintenance'),
      });
  }

  onSubmitJob() {
    let message = 'Do you really want to submit this profile?';
    message += '\nNote: By submitting this profile, you will not be having any access to it anymore!';

    if (!window.confirm(message)) {
      return;
    }

    this.validateJobSubmission().subscribe(({ valid, message }) => {
      if (!valid) {
        return alert(message);
      }
      this.isSubmittingJob = true;
      this.svcProfile
        .submitJob({ ...this.profile, reviewCounts: this.reviewCounts, reviewCountsMatch: this.reviewCountsMatch })
        .subscribe(() => {
          this.isSubmittingJob = false;
          this.goBackToHomePage();
        });
    });
  }

  onJobDraft(): void {
    this.svcJob.setDraft(this.profileJob._id).subscribe(() => {
      this.isSubmittingJob = false;
      this.goBackToHomePage();
    });
  }

  canSubmitJob() {
    return this.svcAcl.hasCredential('job.submit');
  }

  hasJobForCurrentUser(): boolean {
    return this.profileJob && this.myUserId && this.profile?._meta?.assignee === this.myUserId;
  }

  isNotVideoJob(): boolean {
    return this.profileJob.type !== 'video';
  }

  validateJobSubmission() {
    let obs: Observable<{ valid: boolean; message?: string }>;
    if (this.profileJob.type === 'PROFILE_NIH_GRANTS_CURATION') {
      obs = this.svcProfile.grantsStatus(this.profile.id, this.profile.viewMode).pipe(
        map((status) => ({
          valid: status.totalUnpolished === 0,
          ...(status.totalUnpolished > 0 && {
            message: `The profile is not completely polished yet. Please finalize remaining ${status.count} nih-grants matches`,
          }),
        }))
      );
    } else {
      obs = of({ valid: true });
    }
    return obs;
  }

  private getKOLProducts(): string[] {
    if (!this.profile) {
      return [];
    }
    return this.profile.person.projectNames?.reduce((acc, p) => {
      const product = this.projectNames?.find((v) => v?.code === p)?.product;
      if (!product) {
        return acc;
      }
      if (!acc.includes(product)) {
        acc.push(product);
      }
      return acc;
    }, []);
  }

  canSubmitVideos() {
    return (
      this.profile._meta.workstream === ProfileWorkstream.Videos &&
      this.profile._meta.assignee &&
      this.profile._meta.assignee === this.myUserId
    );
  }

  requiresEu() {
    return (this.euCountries || []).includes(
      this.profile.person?.affiliation?.address?.countryCode || this.profile?.person?.affiliation?.countryCode || null
    );
  }

  handleVideoListDataLoaded(profileVideos: { source: Source; vendorId: string }[]) {
    const youtubeIds = profileVideos
      .filter((e) => e && e.source === Source.YOUTUBE && e.vendorId)
      .map((e) => e.vendorId);
    this.youtubeAlreadyWatchedBookmarkletUrl = this.sanitizer.bypassSecurityTrustUrl(
      `javascript:(function markAlreadyWatchedThumbnails(alreadyWatchedVideoIds) {
        function hide() {
          alreadyWatchedVideoIds.forEach((id) => {
            const container = document.querySelector("ytd-thumbnail a[href*='"+id+"']")?.closest('ytd-video-renderer');
            if (container) {
              container.style.opacity = "0.1";
            }
          });
        }
        hide();
        function listener(event){
          if((event.target.tagName || "").toLowerCase()=='ytd-item-section-renderer'){
            setTimeout(function(){hide();}, 2000);
          }
        }
        document.getElementById('contents').removeEventListener('DOMNodeInserted',listener);
        document.getElementById('contents').addEventListener('DOMNodeInserted',listener);
      })(${JSON.stringify(youtubeIds)})
    `
    );
  }

  onSectionChange(sectionKey: ProfileSection) {
    this.activeSection = sectionKey;

    if (this.activeSection === ProfileSection.VIDEOS) {
      this.svcProfileVideo.fetchProfileVideoIds(this.profile.id, Source.YOUTUBE).subscribe((res) => {
        this.handleVideoListDataLoaded(res.content);
      });
    }
  }

  storeReviewCounts(activity: string, counts: { total: number; matches: number }) {
    this.reviewCounts[activity] = counts.total;
    this.reviewCountsMatch[activity] = counts.matches;
  }
}
