import { Injectable } from '@angular/core';
import { Observable, catchError, map, take } from 'rxjs';

import {
  GET,
  Query,
  MediaType,
  Produces,
  Body,
  POST,
  Path,
  PATCH,
  DELETE,
  PUT,
} from '../../shared/services/rest/rest.service';
import { APIService } from '../../shared/services/api/api.service';
import { Organization } from './organization';
import { AddressEntity } from './address-entity';
import { Comment } from '../../comment/shared/comment';
import { OrganizationSubTree } from './types/sub-tree';
import { OrganizationAffiliation } from '../../organization-affiliation/shared/organization-affiliation';
import { Affiliation } from '../../affiliation/shared/affiliation';

export type OrgDuplicate = Pick<Organization, '_id' | 'name'> & { parents: string[] };

export type CreateResponse =
  | { type: 'success'; res: Organization; affiliation: Affiliation }
  | { type: 'exact-duplicate'; duplicates: OrgDuplicate[] }
  | { type: 'fuzzy-duplicate'; duplicates: OrgDuplicate[] };

export type DeleteResponse = { type: 'success' } | { type: 'markedForDeletion' } | { type: 'alien'; id: string };

export interface InternalDuplicatesResponse {
  duplicates: Record<
    string,
    {
      count: number;
      duplicates: { _id: string }[];
    }
  >;
}

export type OrganizationAffiliationMappingBaseInfo = {
  organizationId: string;
  affiliationId: string;
  addressId?: string;
  affiliationName?: string;
  affiliationDepartment?: string;
};

export type OrganizationSearchResultDTO = {
  _id: string;
  name: string;
  fullName: string;
  readyForDelivery?: boolean;
  _meta: { status: string };
  address?: string;
};

type GetOrganizationDisplayTreeRaw = {
  _id: string;
  name: string;
  type: string;
  parents: string[];
  root: string;
  isRoot: boolean;
  websource?: string;
  isAlien?: boolean;
  children?: GetOrganizationDisplayTreeRaw[];
  _meta: { status: string };
  markedForLater?: boolean;
  childrenInProgress?: string[];
  childrenMarkedForLater?: string[];
  isMarkedForDeletion?: boolean;
};

@Injectable()
export class OrganizationAPI extends APIService {
  @GET('organizations/{id}')
  @Produces(MediaType.JSON)
  public findById(@Path('id') id: string): Observable<Organization> {
    return;
  }

  @POST('organizations/list-by-ids')
  @Produces(MediaType.JSON)
  public findByIds(@Body body: { ids: string[] }): Observable<Organization[]> {
    return;
  }

  @GET('organizations')
  @Produces(MediaType.JSON)
  public find(
    @Query('root') root: boolean,
    @Query('term') term?: string,
    @Query('limit') limit?: number,
    @Query('skip') skip?: number,
    @Query('sort') sort?: string,
    @Query('filter') filter?: any
  ): Observable<(Organization & { addressesMapped: AddressEntity[] })[]> {
    return;
  }

  @GET('organizations')
  @Produces(MediaType.JSON)
  public findForParent(
    @Query('parentId') parentId: string,
    @Query('term') term?: string,
    @Query('limit') limit?: number,
    @Query('skip') skip?: number,
    @Query('sort') sort?: string,
    @Query('filter') filter?: any
  ): Observable<(Organization & { addressesMapped: AddressEntity[] })[]> {
    return;
  }

  @GET('organizations')
  @Produces(MediaType.JSON)
  public findForRoot(
    @Query('rootId') rootId: string,
    @Query('term') term?: string,
    @Query('limit') limit?: number,
    @Query('skip') skip?: number,
    @Query('sort') sort?: string,
    @Query('filter') filter?: any
  ): Observable<(Organization & { addressesMapped: AddressEntity[] })[]> {
    return;
  }

  @GET('organizations/all-comments')
  @Produces(MediaType.JSON)
  public findAllComments(@Query('rootId') rootId: string): Observable<Comment[]> {
    return;
  }

  @GET('organizations/addresses')
  @Produces(MediaType.JSON)
  public findAddress(
    @Query('term') term?: string,
    @Query('preferredOrgId') preferredOrgId?: string,
    @Query('limit') limit?: number,
    @Query('skip') skip?: number,
    @Query('sort') sort?: string,
    @Query('transformed') transformed?: boolean
  ): Observable<AddressEntity[]> {
    return;
  }

  @GET('organizations/addresses')
  @Produces(MediaType.JSON)
  public findAddressByIds(
    @Query('ids') ids: string,
    @Query('limit') limit?: number,
    @Query('skip') skip?: number,
    @Query('sort') sort?: string,
    @Query('transformed') transformed?: boolean
  ): Observable<AddressEntity[]> {
    return;
  }

  @GET('organizations/count')
  @Produces(MediaType.JSON)
  public count(
    @Query('root') root?: boolean,
    @Query('parentId') parentId?: string,
    @Query('term') term?: string,
    @Query('filter') filter?: any
  ): Observable<{ count: number }> {
    return;
  }

  @GET('organizations/count')
  @Produces(MediaType.JSON)
  public countForRoot(@Query('rootId') rootId?: string, @Query('term') term?: string): Observable<{ count: number }> {
    return;
  }

  @POST('organizations')
  @Produces(MediaType.JSON)
  public create(
    @Body organization: Organization & { noAddress?: boolean },
    @Query('force') force: boolean
  ): Observable<CreateResponse> {
    return;
  }

  @POST('organizations/addresses')
  @Produces(MediaType.JSON)
  public createAddress(@Body address: AddressEntity): Observable<{ res: AddressEntity }> {
    return;
  }

  @PATCH('organizations/{id}')
  @Produces(MediaType.JSON)
  public update(
    @Path('id') id: string,
    @Body organization: Organization | Partial<Organization>,
    @Query('qcSessionId') qcSessionId?: string
  ): Observable<{ res: Organization }> {
    return;
  }

  @PATCH('organizations/addresses/{id}')
  @Produces(MediaType.JSON)
  public updateAddress(
    @Path('id') id: string,
    @Body address: AddressEntity | Partial<AddressEntity>
  ): Observable<{ res: AddressEntity }> {
    return;
  }

  @PUT('organizations/move')
  @Produces(MediaType.JSON)
  public move(@Body body: { newParent: string; idsToMove: string[]; moveChildren: boolean }): Observable<void> {
    return;
  }

  @POST('organizations/submit-job')
  @Produces(MediaType.JSON)
  public submitJob(
    @Body body: { organization: Organization; affiliationMappings?: OrganizationAffiliation[] }
  ): Observable<{ res: Organization }> {
    return;
  }

  /**
   * Find all the audit logs of a given model id
   * @param id
   */
  @GET('organizations/{id}/audit')
  @Produces(MediaType.JSON)
  public audit(@Path('id') id: string, @Query('limit') limit?: number, @Query('skip') skip?: number): Observable<any> {
    return;
  }

  /**
   * Find count of audit logs
   * @param id
   */
  @GET('organizations/{id}/auditcount')
  @Produces(MediaType.JSON)
  public auditCount(@Path('id') id: string): Observable<any> {
    return;
  }

  /**
   * Find count of audit logs
   * @param id
   */
  @GET('organizations/{id}/internalDuplicates')
  @Produces(MediaType.JSON)
  public getInternalDuplicates(@Path('id') id: string): Observable<InternalDuplicatesResponse> {
    return;
  }

  /**
   * Upload the file and get a bulk upload ID
   *
   * @param file
   * @param secondEyes
   */
  public startBulkListUpload(file: File, secondEyes: string): Observable<string> {
    // (actually do this ourselves!)
    const formData: FormData = new FormData();
    formData.set('xlsx', file); // multipart name must match server
    formData.set('secondEyes', secondEyes);
    return this.http
      .post(this.getBaseUrl() + 'organizations/bulk-list', formData)
      .pipe(catchError((err) => this.handleError(err)))
      .pipe(map((obj) => (<any>obj).bulkTrackingId));
  }

  @PUT('organizations/ready-child')
  public readyChild(@Body body: { orgId: string }): Observable<void> {
    return;
  }

  @PUT('organizations/parent-to-child')
  public parentToChild(@Body body: { orgId: string; newParentOrgId: string }): Observable<void> {
    return;
  }

  @PUT('organizations/merge')
  public mergeOrganizations(@Body body: { loserId: string; winnerId: string; mergeChildren: boolean }): Observable<{
    migratedAdditionalParents: { loserId: string; winnerId: string; additionalParentId: string }[];
  }> {
    return;
  }

  @PUT('organizations/split')
  public split(
    @Body body: { splitOrganizationIds: string[]; targetOrganizationId: string; includeChildren: boolean }
  ): Observable<void> {
    return;
  }

  @DELETE('organizations/{id}')
  @Produces(MediaType.JSON)
  public delete(@Path('id') id: string, @Query('mode') mode?: string): Observable<DeleteResponse> {
    return;
  }

  @POST('organization-affiliation-mappings/list')
  @Produces(MediaType.JSON)
  public getOrganizationAffiliationMappings(
    @Body payload: { organizationIds: string[]; status?: 'MATCH' | 'MISMATCH' | 'UNPOLISHED' }
  ): Observable<OrganizationAffiliation[]> {
    return;
  }

  @POST('organization-affiliation-mappings/matched')
  @Produces(MediaType.JSON)
  public getOrganizationMatchedAffiliationMappings(
    @Body payload: { organizationIds: string[] },
    @Query('includeTransformed') includeTransformed = false
  ): Observable<OrganizationAffiliationMappingBaseInfo[]> {
    return;
  }

  @PUT('organizations/utc')
  @Produces(MediaType.JSON)
  public setOrganizationUtcStatus(@Body payload: { orgId: string; utcValue: boolean }): Observable<void> {
    return;
  }

  public getOrganizationDisplayTree(
    ...params: Parameters<typeof this._getOrganizationDisplayTree>
  ): Observable<OrganizationSubTree> {
    return this._getOrganizationDisplayTree(...params).pipe(
      take(1),
      map((rawTreeData) => {
        const formatRawTreeData = (rawData: GetOrganizationDisplayTreeRaw): OrganizationSubTree => ({
          _id: rawData._id,
          name: rawData.name,
          websource: rawData.websource,
          children: rawData.children ? rawData.children.map((c) => formatRawTreeData(c)) : [],
          isUtc: rawData._meta.status === 'UNABLE_TO_COMPILE',
          isDraft: rawData._meta?.status === 'IN_PROGRESS',
          isMarkedForLater: rawData.markedForLater,
          isRoot: rawData.isRoot,
          isAlien: rawData.isAlien,
          parents: rawData.parents,
          childrenInProgress: rawData.childrenInProgress,
          childrenMarkedForLater: rawData.childrenMarkedForLater,
          isMarkedForDeletion: rawData.isMarkedForDeletion,
          type: rawData.type,
        });

        return formatRawTreeData(rawTreeData);
      })
    );
  }

  @GET('organizations/{id}/display-tree')
  private _getOrganizationDisplayTree(@Path('id') id: string): Observable<GetOrganizationDisplayTreeRaw> {
    return;
  }

  @POST('organizations/advanced-search')
  @Produces(MediaType.JSON)
  advancedSearch(@Body advancedSearchSpec: any): Observable<{
    items: (Organization & { addressesMapped: AddressEntity[] })[];
    total: number;
  }> {
    return;
  }

  @POST('organizations/{id}/maintenance-request')
  @Produces(MediaType.JSON)
  maintenanceRequest(@Path('id') organizationId: string, @Body newJobDesc: any): Observable<void> {
    return;
  }

  @DELETE('organizations/{id}/deletion-request')
  removeDeletionRequest(@Path('id') orgId: string): Observable<void> {
    return;
  }

  @GET('organizations/search')
  public search(
    @Query('term') term: string,
    @Query('limit') limit?: number,
    @Query('includeTransformed') includeTransformed?: boolean,
    @Query('includeUnpolished') includeUnpolished?: boolean
  ): Observable<OrganizationSearchResultDTO[]> {
    return;
  }
}
