import { combineLatest, from, Observable, Subject } from 'rxjs';
import { Input, ViewChild, OnDestroy, Component, Output, EventEmitter, AfterViewInit, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { takeUntil, distinctUntilChanged, map, startWith, debounceTime } from 'rxjs/operators';

import { AccountAPI } from '../../../account/shared/account-api.service';
import { ACL } from '../../../shared/acl/acl.service';
import { Committee } from '../committee';
import { CommitteeConnection } from '../connection';
import { CommitteeConnectionsComponent } from '../connection/connections.component';
import { IMultiSelectOption, IMultiSelectSettings } from '../../../shared/components/multiselect-dropdown/types';
import { Utils } from '../../../common/utils';
import { Value } from '../../../shared/services/value/value';
import { ValueAPI } from '../../../shared/services/value/value-api.service';
import { ValueType } from '../../../shared/enum/value-type.enum';

const CLS_MULTISELECT_BTN = 'btn btn-sm btn-secondary';

@Component({
  selector: 'dirt-committee-form',
  templateUrl: './form.component.html',
  exportAs: 'frmCommittee',
})
export class CommitteeFormComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input('committee')
  model: Committee = new Committee();

  @Output()
  validityChange: EventEmitter<'VALID' | 'INVALID'> = new EventEmitter();

  longDash = Utils.longDash;

  isSearching: boolean;

  countries$: Observable<Value[]>;

  therapeuticAreas$: Observable<IMultiSelectOption[]>;

  topics$: Observable<Value[]>;

  therapeuticAreasSettings: IMultiSelectSettings = {
    buttonClasses: CLS_MULTISELECT_BTN,
    checkedStyle: 'fontawesome',
    enableSearch: true,
    dynamicTitleMaxItems: 5,
  };

  committeeFormats$: Observable<Value[]>;
  committeeTypes$: Observable<Value[]>;
  committeeStatuses$: Observable<Value[]>;

  accountConnections: CommitteeConnection[] = [];

  @ViewChild('ngForm')
  private ngForm: NgForm;

  @ViewChild('accountConnectionsComponent')
  private accountConnectionsComponent: CommitteeConnectionsComponent;

  private destroy$: Subject<boolean> = new Subject();

  private statusChange$: Subject<string> = new Subject();

  constructor(private svcAcl: ACL, private svcValue: ValueAPI, private svcAccount: AccountAPI) {
    this.onSearchAccountConnection = this.onSearchAccountConnection.bind(this);
  }

  ngOnInit(): void {
    this.countries$ = this.svcValue.find(ValueType.Country, Number.MAX_SAFE_INTEGER, 0, '+title');
    this.topics$ = this.svcValue.find(ValueType.CommitteeTopic, Number.MAX_SAFE_INTEGER, 0, '+title');
    this.therapeuticAreas$ = this.svcValue
      .find(ValueType.CommitteeTherapeuticArea, Number.MAX_SAFE_INTEGER, 0, '+title')
      .pipe(map((values) => values.map((v) => ({ id: v.code, name: v.title }))));
    this.committeeFormats$ = this.svcValue.find(ValueType.CommitteeFormat, Number.MAX_SAFE_INTEGER, 0, '+title');
    this.committeeTypes$ = this.svcValue.find(ValueType.CommitteeType, Number.MAX_SAFE_INTEGER, 0, '+title');
    this.committeeStatuses$ = this.svcValue.find(ValueType.CommitteeStatus, Number.MAX_SAFE_INTEGER, 0, '+title');

    if (this.model.connections?.length) {
      this.accountConnections = this.model.connections.filter((c) => c.type === 'ACCOUNT');
    }

    if (this.accountConnections.length) {
      this.svcAccount
        .find(null, this.accountConnections.length, 0, null, {
          _id: this.accountConnections.map((c) => c.connectionId),
        })
        .subscribe((accounts) => {
          this.accountConnections.forEach((c) => {
            const account = accounts.find((a) => a.id === c.connectionId);
            if (account) {
              c.title = account.name;
              c.readyForDelivery = account.readyForDelivery; // for all levels (in sync with other changes)
            } else {
              console.warn('Account ' + c.connectionId + ' not found');
            }
          });
        });
    }

    this.initMultiFields();
  }

  ngAfterViewInit(): void {
    combineLatest([
      this.ngForm.statusChanges.pipe(startWith(null)),
      this.accountConnectionsComponent.validityChange.pipe(startWith('VALID')),
      this.statusChange$.pipe(startWith(null)),
    ])
      .pipe(
        takeUntil(this.destroy$),
        debounceTime(0), // ensure that the model has already been updated
        map(([_, accountConnectionValidity]) => {
          let isValid = this.isValid();

          switch (this.model.type) {
            case 'REGULAR':
              isValid = isValid && this.accountConnections.length === 1; // should have only one of one type, not more
              break;
            case 'JOINT':
              isValid = isValid && this.accountConnections.length > 1; // Should have two or more connection
              break;
            default:
              isValid = false;
              break;
          }

          return (isValid && accountConnectionValidity === 'VALID') || this.model.status === 'INACTIVE';
        }),
        distinctUntilChanged()
      )
      .subscribe((isValid) => {
        this.validityChange.emit(isValid ? 'VALID' : 'INVALID');
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(false);
    this.destroy$.complete();
  }

  getValue(): Committee {
    const connections = [];
    if (this.model.type !== 'EXTERNAL') {
      connections.push(...this.accountConnections);
    }

    this.model.connections = connections;

    Utils.removeEmptyAndDuplicatedElementsFromArray(this.model, ['connections', 'websources']);

    return this.model;
  }

  isFieldEditable(field: string): boolean {
    const prefix = this.model.id ? 'update' : 'create';
    return this.svcAcl.hasCredential(`committee.${prefix}.prop.${field}`);
  }

  onSearchAccountConnection(term: string): Observable<CommitteeConnection[]> {
    return this.svcAccount.find(term, 5, 0, null, { 'address.countryCode': this.model.country }).pipe(
      map((accounts) => {
        return accounts.map((account) => ({
          probability: null,
          type: 'ACCOUNT',
          connectionId: account.id,
          title: account.name,
        }));
      })
    );
  }

  onStatusChange(): void {
    this.statusChange$.next(this.model.status);
  }

  removeFromByIndex(from: any[], idx: number): void {
    from.splice(idx, 1);
  }

  pushItemToList(list: any[]): void {
    list.push('');
  }

  trackByIndex(index: number): number {
    return index;
  }

  private isValid(): boolean {
    return this.ngForm.form.valid;
  }

  private initMultiFields(): void {
    const fields = ['websources'];

    fields.forEach((field) => {
      if (!Array.isArray(this.model[field]) || this.model[field].length === 0 || this.model[field].every((v) => !v)) {
        this.model[field] = [''];
      }
    });

    if (
      !Array.isArray(this.model.connections) ||
      this.model.connections.length === 0 ||
      this.model.connections.every((v) => !v)
    ) {
      this.model.connections = [];
    }
  }
}
