import { Injectable } from '@angular/core';
import { ConfirmationService } from '../../../../shared/modals/confirmation/confirmation.service';
import { ConfirmationComponentConfiguration } from 'app/shared/modals/confirmation/confirmation.component';
import { PersonAffiliation } from '../../person-affiliation';
import { PersonAffiliationLfka } from '../../person-affiliation-lfka';
import { PersonAffiliationEducation } from '../../person-affiliation-education';

@Injectable()
export class UnmappedPersonAffiliationsModalService {
  constructor(private svcModal: ConfirmationService) {}

  open(
    unmappedAffiliations: (PersonAffiliation | PersonAffiliationLfka | PersonAffiliationEducation)[]
  ): Promise<boolean | null> {
    const unmappedAffiliationNames = unmappedAffiliations.map((u) => u.name).join(' ');
    const configuration: ConfirmationComponentConfiguration = {
      title: 'Warning - Unmapped Affiliations',
      content: `One or more of attached affiliations is an unmapped institution. It is required to replace it with an Organization.
Unmapped Affiliations: ${unmappedAffiliationNames}`,
      cancelText: 'Back to edit',
      confirmText: 'Continue anyway',
    };
    return this.svcModal.open(configuration);
  }
}
