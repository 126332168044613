<div class="tree-part" [id]="'tree-' + leaf.id">
  <div
    class="self-line"
    [ngClass]="{
      'alien-entry': leaf.isAlien,
      'in-tree-alien-entry': isInTreeAlien(),
      'highlight-row': leaf.id === singleSelectHolder?.id,
      'marked-for-deletion': leaf.isMarkedForDeletion
    }"
    [title]="getLeafHint()"
  >
    <span
      tabindex="0"
      (click)="doToggleExpanded()"
      (keyup.enter)="doToggleExpanded()"
      class="toggle fa"
      [ngClass]="{
        expanded: expandedMap[leaf.id],
        'fa-minus': expandedMap[leaf.id],
        collapsed: !expandedMap[leaf.id],
        'fa-plus': !expandedMap[leaf.id]
      }"
    ></span>
    <span
      (click)="doSingleSelect()"
      class="alink leaf-title"
      [ngClass]="{ 'single-select': leaf.id === singleSelectHolder?.id }"
      [ngStyle]="{
        color: colorMap[shortDisplayName(leaf.title)] ?? (leaf.isDraft ? 'orange' : leaf.isUtc ? 'red' : 'black')
      }"
    >
      {{ leaf.title }}
    </span>
    <a
      class="float-right url"
      [ngClass]="{ hidden: !leaf.url }"
      [href]="leaf.url || '#'"
      rel="noopener noreferrer"
      target="_blank"
      ><span class="fa fa-globe"></span
    ></a>
    <label class="float-right checkbox"
      ><input type="checkbox" [checked]="!!multiSelectMap[leaf.id]" (input)="doToggleMultiselect()"
    /></label>
    <i class="fa fa-exclamation float-right" [ngClass]="{ hidden: !leaf.isMarkedForLater || !currentJob }"></i>
    <i class="fa fa-cross float-right" [ngStyle]="{ color: leaf.isUtc ? 'red' : 'black' }"></i>
    <i
      *ngIf="leaf.isMarkedForDeletion"
      title="This organization was marked for deletion"
      class="fa fa-chain-broken float-right"
      [ngStyle]="{ marginTop: '2px' }"
    ></i>
    <!-- An indicator showing the total number of Draft child organizations. Clicking on the indicator jumps to the next Draft child org for curation. -->
    <span
      *ngIf="leaf.isRoot && leaf.childrenInProgress?.length > 0"
      class="float-right badge badge-info badge-btn"
      (click)="jumpToOrg.emit({ id: leaf.childrenInProgress[0] })"
    >
      {{ (leaf.childrenInProgress?.length || 0) + (leaf.childrenInProgress.length === 1 ? ' Draft' : ' Drafts') }}
    </span>
    <!-- An indicator showing the remaining marked organizations that a curator left for later. Clicking on the indicator jumps to the next marked child org. -->
    <span
      *ngIf="currentJob && leaf.isRoot && leaf.childrenMarkedForLater?.length > 0"
      class="float-right badge badge-danger badge-btn"
      (click)="jumpToOrg.emit({ id: leaf.childrenMarkedForLater?.[0] })"
    >
      {{
        (leaf.childrenMarkedForLater?.length || 0) + (leaf.childrenMarkedForLater?.length === 1 ? ' Mark' : ' Marks')
      }}
    </span>
  </div>
  <div class="child-line" *ngFor="let c of childrenEntries(); trackBy: tracker">
    <dirt-organization-tree-part
      [leaf]="c"
      [parentId]="leaf.id"
      [childrenMap]="childrenMap"
      [expandedMap]="expandedMap"
      [internalDuplicatesMap]="internalDuplicatesMap"
      [colorMap]="colorMap"
      [multiSelectMap]="multiSelectMap"
      [singleSelectHolder]="singleSelectHolder"
      [currentJob]="currentJob"
      (toggleExpanded)="toggleExpanded.emit($event)"
      (toggleMultiselect)="toggleMultiselect.emit($event)"
      (changeSingleSelect)="changeSingleSelect.emit($event)"
    ></dirt-organization-tree-part>
  </div>
</div>
