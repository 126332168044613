<form id="organization-advanced-search-dialog" novalidate>
  <div class="form-group row">
    <label class="col-form-label col-sm-4 col-md-2" for="searchOrganizationName" title="HCO Name">HCO Name</label>
    <div class="col-sm-7 col-md-9">
      <input
        type="text"
        id="searchOrganizationName"
        name="searchOrganizationName"
        class="form-control"
        [(ngModel)]="model.filter.organizationName"
        placeholder="HCO Name"
      />
    </div>
  </div>
  <div class="form-group row">
    <label class="col-form-label col-sm-4 col-md-2" for="OrganizationId" title="HCO ID">HCO ID</label>
    <div class="col-sm-7 col-md-9">
      <input
        type="text"
        id="OrganizationId"
        name="OrganizationId"
        class="form-control"
        [(ngModel)]="model.filter.organizationId"
        placeholder="HCO ID"
      />
    </div>
  </div>
  <div class="form-group row">
    <label class="col-form-label col-sm-4 col-md-2" for="searchStreet" title="Street">Street</label>
    <div class="col-sm-7 col-md-9">
      <input
        type="text"
        id="searchStreet"
        name="searchStreet"
        class="form-control"
        [(ngModel)]="model.filter.address.name"
        placeholder="Street"
      />
    </div>
  </div>
  <div class="form-group row">
    <label class="col-form-label col-sm-4 col-md-2" for="searchHouseNo" title="House Number">House Number</label>
    <div class="col-sm-7 col-md-9">
      <input
        type="text"
        id="searchHouseNo"
        name="searchHouseNo"
        class="form-control"
        [(ngModel)]="model.filter.address.houseNo"
        placeholder="House Number"
      />
    </div>
  </div>
  <div class="form-group row">
    <label class="col-form-label col-sm-4 col-md-2" for="searchZip" title="ZIP">ZIP</label>
    <div class="col-sm-7 col-md-9">
      <input
        type="text"
        id="searchZip"
        name="searchZip"
        class="form-control"
        [(ngModel)]="model.filter.address.zip"
        placeholder="ZIP"
      />
    </div>
  </div>
  <div class="form-group row">
    <label class="col-form-label col-sm-4 col-md-2" for="searchCity" title="City">City</label>
    <div class="col-sm-7 col-md-9">
      <input
        type="text"
        id="searchCity"
        name="searchCity"
        class="form-control"
        [(ngModel)]="model.filter.address.city"
        placeholder="City"
      />
    </div>
  </div>
  <div class="form-group row">
    <label class="col-form-label col-sm-4 col-md-2" for="searchCountry">Country</label>
    <div class="col-sm-7 col-md-9">
      <select
        class="form-control custom-select"
        id="searchCountry"
        name="searchCountry"
        [(ngModel)]="model.filter.address.countryCode"
        (change)="onCountryChange($event)"
      >
        <option value="">(all)</option>
        <option *ngFor="let c of countries" [value]="c.code">{{ c.title }}</option>
      </select>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-form-label col-sm-4 col-md-2">State</label>
    <div class="col-sm-7 col-md-9">
      <dirt-multiselect-dropdown
        name="searchState"
        [disabled]="!model.filter.address.countryCode"
        [options]="states"
        [settings]="statesSettings"
        [ngModel]="model.filter.address.state"
        (ngModelChange)="model.filter.address.state=$event[0] || null"
      >
      </dirt-multiselect-dropdown>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-form-label col-sm-4 col-md-2">Websource</label>
    <div class="col-sm-7 col-md-9">
      <dirt-link [(url)]="model.filter.websource" name="website"></dirt-link>
    </div>
  </div>
</form>
