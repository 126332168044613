import { ComponentRef, Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';

import { EntityMarkerContainerComponent } from '../../components/marker/container.component';
import { PersonMarkerWrapperDirective } from '..';

export type MarkerArea =
  | 'BD'
  | 'ID'
  | 'ALL'
  | 'IMAGE'
  | 'KPP'
  | 'VID_QC'
  | 'EDUCATION'
  | 'WORK'
  | 'BASE_DATA'
  | 'LINKEDIN'
  | 'SO'
  | 'MAPPING'
  | 'MI'
  | 'CT_AFFILIATION_QC';
export const STD_JOB_AREAS = [
  'KPP',
  'VID_QC',
  'EDUCATION',
  'WORK',
  'BASE_DATA',
  'LINKEDIN',
  'SO',
  'MAPPING',
  'MI',
  'CT_AFFILIATION_QC',
]; // unified handling
export type MarkerEntity =
  | 'person'
  | 'event'
  | 'session'
  | 'contribution'
  | 'association'
  | 'subassociation'
  | 'membership'
  | 'guideline'
  | 'clinicalTrialProfile'
  | 'domain'
  | 'clinicalTrialSponsor'
  | 'organization'
  | 'account-person'
  | 'noop'; // noop to conditionally show anything

export interface MarkerConfig {
  name: string;
  area?: MarkerArea;
  entity?: MarkerEntity;
  leftAlign?: boolean;
  lfkaOnlyProject?: boolean; // Shouldn't had been introduced in the first place but too late...
}

@Directive({
  selector: '[diMarker]',
})
export class EntityMarkerDirective implements OnInit {
  @Input()
  set diMarker(conf: MarkerConfig) {
    if (!conf || typeof conf !== 'object') {
      throw new Error('Unknown type for fieldName for marker directive for ' + conf);
    }

    this.fieldName = conf.name;
    this.area = conf.area || 'BD';
    this.entity = conf.entity || 'person';
    this.leftAlign = !!conf.leftAlign;
    this.lfkaOnlyProject = !!conf.lfkaOnlyProject;

    // we may have changes to the entity after init
    if (
      this.containerComponent &&
      this.containerComponent.instance &&
      (this.containerComponent.instance.entity !== this.entity || this.containerComponent.instance.area !== this.area)
    ) {
      this.containerComponent.instance.updateEntity(this.entity, this.area);
    }
  }

  private fieldName: string;
  private area: MarkerArea;
  private entity: MarkerEntity;
  private leftAlign: boolean;
  private lfkaOnlyProject: boolean;

  private containerComponent: ComponentRef<EntityMarkerContainerComponent>;

  constructor(
    private readonly templateRef: TemplateRef<any>,
    private readonly viewContainerRef: ViewContainerRef,
    private readonly markerWrapper: PersonMarkerWrapperDirective
  ) {}

  ngOnInit() {
    this.containerComponent = this.viewContainerRef.createComponent(EntityMarkerContainerComponent);

    this.containerComponent.instance.template = this.templateRef;
    this.containerComponent.instance.fieldName = this.fieldName;
    this.containerComponent.instance.area = this.area;
    this.containerComponent.instance.entity = this.entity;
    this.containerComponent.instance.leftAlign = this.leftAlign;
    this.containerComponent.instance.lfkaOnlyProject = this.lfkaOnlyProject;
    this.containerComponent.instance.defaultFixed = this.markerWrapper.defaultFixed;
  }
}
