export enum Roles {
  Admin = 'ADMIN',
  Viewer = 'VIEWER',
  Trainer = 'TRAINER',
  ViewAutomate = 'VIEW_AUTOMATE',
  ViewLfka = 'VIEW_LFKA',
  ReadyForJobs = 'READY_FOR_JOBS',
  JobCreator = 'JOB_CREATOR',
  JobViewer = 'JOB_VIEWER',
  EUCompliant = 'EU_COMPLIANT',
  IsMy = 'IS_MY', // Malaysian restrictions

  AssociationManager = 'ASSOCIATION_MANAGER',
  AssociationModerator = 'ASSOCIATION_MODERATOR',
  AssociationCreator = 'ASSOCIATION_CREATOR',
  AssociationCompiler = 'ASSOCIATION_COMPILER',
  AssociationViewer = 'ASSOCIATION_VIEWER',
  AssociationIDReviewer = 'ASSOCIATION_ID_REVIEWER',
  AssociationReviewer = 'ASSOCIATION_REVIEWER',

  EventManager = 'EVENT_MANAGER',
  EventModerator = 'EVENT_MODERATOR',
  EventCreator = 'EVENT_CREATOR',
  EventCompiler = 'EVENT_COMPILER',
  EventViewer = 'EVENT_VIEWER',
  EventHashtagCompiler = 'EVENT_HASHTAG_COMPILER',
  EventIDReviewer = 'EVENT_ID_REVIEWER',
  EventReviewer = 'EVENT_REVIEWER',

  PersonManager = 'PERSON_MANAGER',
  PersonModerator = 'PERSON_MODERATOR',
  PersonModifier = 'PERSON_MODIFIER',
  PersonReviewer = 'PERSON_REVIEWER', // (checking person compilers' work)
  PersonIDReviewer = 'PERSON_ID_REVIEWER', // identify = able to check person creators' work
  PersonIDBDReviewer = 'PERSON_ID_BD_REVIEWER', // able to check person ident compilers' work (can be ID out and submitted)
  PersonSOReviewer = 'PERSON_SO_REVIEWER', // signoff = able to check any BD record regardless of region or assigned quota.
  PersonCreator = 'PERSON_CREATOR',
  PersonContributor = 'PERSON_CONTRIBUTOR',
  PersonCompiler = 'PERSON_COMPILER',
  PersonCompilerIdent = 'PERSON_COMPILER_IDENT', // compiler-identifier
  PersonMerger = 'PERSON_MERGER', // in addition to compiler(ident) et al
  PersonViewer = 'PERSON_VIEWER',
  PersonClinicalAffiliationCompiler = 'PERSON_CLINICAL_AFFILIATION_COMPILER',

  ProfileManager = 'PROFILE_MANAGER',
  ProfileModerator = 'PROFILE_MODERATOR',
  ProfileModifier = 'PROFILE_MODIFIER',
  ProfileReviewer = 'PROFILE_REVIEWER',
  ProfileCompiler = 'PROFILE_COMPILER',
  ProfileViewer = 'PROFILE_VIEWER',
  ProfileCompilerGrant = 'PROFILE_GRANT_COMPILER',
  ProfileSOReviewer = 'PROFILE_SO_REVIEWER',

  GuidelineManager = 'GUIDELINE_MANAGER',
  GuidelineModerator = 'GUIDELINE_MODERATOR',
  GuidelineCreator = 'GUIDELINE_CREATOR',
  GuidelineCompiler = 'GUIDELINE_COMPILER',
  GuidelineViewer = 'GUIDELINE_VIEWER',
  GuidelineIDReviewer = 'GUIDELINE_ID_REVIEWER',
  GuidelineReviewer = 'GUIDELINE_REVIEWER',

  VideoManager = 'VIDEO_MANAGER',
  VideoCompiler = 'VIDEO_COMPILER',
  VideoReviewer = 'VIDEO_REVIEWER',
  VideoChannelCompiler = 'VIDEO_CHANNEL_COMPILER',

  SentimentManager = 'SENTIMENT_MANAGER',
  SentimentCompiler = 'SENTIMENT_COMPILER',
  SentimentViewer = 'SENTIMENT_VIEWER',

  AffiliationManager = 'AFFILIATION_MANAGER',
  AffiliationMerger = 'AFFILIATION_MERGER',
  AffiliationCreator = 'AFFILIATION_CREATOR',
  AffiliationCompiler = 'AFFILIATION_COMPILER', // central organizations
  AffiliationViewer = 'AFFILIATION_VIEWER',

  OrganizationCompiler = 'ORGANIZATION_COMPILER',
  OrganizationStructureCompiler = 'ORGANIZATION_STRUCTURE_COMPILER',
  OrganizationTypeCompiler = 'ORGANIZATION_TYPE_COMPILER',
  OrganizationReviewer = 'ORGANIZATION_REVIEWER',
  OrganizationViewer = 'ORGANIZATION_VIEWER',
  OrganizationManager = 'ORGANIZATION_MANAGER',
  OrganizationMerger = 'ORGANIZATION_MERGER',
  OrganizationSplitter = 'ORGANIZATION_SPLITTER',
  OrganizationMappingCompiler = 'ORGANIZATION_MAPPING_COMPILER',
  OrganizationMappingReviewer = 'ORGANIZATION_MAPPING_REVIEWER',
  AddressMassApi = 'ADDRESS_MASS_API',

  AccountManager = 'ACCOUNT_MANAGER',
  AccountModerator = 'ACCOUNT_MODERATOR',
  AccountCreator = 'ACCOUNT_CREATOR',
  AccountCompiler = 'ACCOUNT_COMPILER',
  AccountAffiliationCompiler = 'ACCOUNT_AFFILIATION_COMPILER',
  AccountViewer = 'ACCOUNT_VIEWER',

  AccountJTKPCompiler = 'ACCOUNT_JT_KP_COMPILER',
  AccountJTKPReviewer = 'ACCOUNT_JT_KP_REVIEWER',

  // Person
  ImageManager = 'IMAGE_MANAGER',
  ImageCompiler = 'IMAGE_COMPILER',
  ImageMaintainer = 'IMAGE_MAINTAINER',
  ImageReviewer = 'IMAGE_REVIEWER',

  CtManager = 'CT_MANAGER',
  CtModerator = 'CT_MODERATOR',
  CtCompiler = 'CT_COMPILER',
  CtTimingCompiler = 'CT_TIMING_COMPILER',
  CtPeopleCompiler = 'CT_PEOPLE_COMPILER',
  CtSponsorCompiler = 'CT_SPONSOR_COMPILER',
  CtSiteCompiler = 'CT_SITE_COMPILER',
  CtReviewer = 'CT_REVIEWER',
  CtSponsorReviewer = 'CT_SPONSOR_REVIEWER',
  CtViewer = 'CT_VIEWER',

  DomainAdmin = 'DOMAIN_ADMIN', // = Manager but can lock domains even for managers
  DomainManager = 'DOMAIN_MANAGER',
  DomainCompiler = 'DOMAIN_COMPILER',
  DomainReviewer = 'DOMAIN_REVIEWER',
  DomainViewer = 'DOMAIN_VIEWER',

  CommitteeManager = 'COMMITTEE_MANAGER',
  CommitteeModerator = 'COMMITTEE_MODERATOR',
  CommitteeCompiler = 'COMMITTEE_COMPILER',
  CommitteeKPCompiler = 'COMMITTEE_KP_COMPILER',
  CommitteeViewer = 'COMMITTEE_VIEWER',
  CommitteePrimaryResearchCompiler = 'COMMITTEE_PRIMARY_RESEARCH_COMPILER',

  DocumentManager = 'DOCUMENT_MANAGER',
  DocumentModerator = 'DOCUMENT_MODERATOR',
  DocumentCompiler = 'DOCUMENT_COMPILER',
  DocumentKPCompiler = 'DOCUMENT_KP_COMPILER',
  DocumentViewer = 'DOCUMENT_VIEWER',

  IsMedTech = 'IS_MEDTECH',

  SponsorManager = 'SPONSOR_MANAGER',
}
