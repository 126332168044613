<div class="modal-header">
  <h4 class="modal-title">{{ canCreateAffiliation ? 'New HCO' : 'Request HCO' }}</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('dismiss')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body row">
  <div class="col-8">
    <dirt-affiliation-form
      #frmAffiliation="frmAffiliation"
      [affiliation]="sourceItem"
      [disableNameField]="disableNameField"
      [currentJobType]="currentJobType"
      [isAffiliationRequest]="!canCreateAffiliation"
      [isCustomerRequest]="isCustomerRequest"
      [requestOwnerProduct]="requestOwnerProduct"
      (onChange)="onAffiliationChange($event)"
    >
    </dirt-affiliation-form>
  </div>

  <div class="col-4">
    <div class="dirt-duplicate-list" *ngIf="duplicates && duplicates.length > 0">
      <div class="dirt-duplicate-list-heading">The following duplicates were found:</div>
      <dirt-affiliation-suggestion-list
        [affiliations]="duplicates"
        (onSelectedItem)="onSelectItem($event)"
      ></dirt-affiliation-suggestion-list>
      <div>It's recommended to check whether an existing HCO already matches.</div>
    </div>
    <div class="dirt-duplicate-list" *ngIf="suggestedItems && suggestedItems.length > 0">
      <hr *ngIf="duplicates && duplicates.length > 0" />
      <div class="dirt-duplicate-list-heading">The following suggestions were found:</div>
      <dirt-affiliation-suggestion-list
        [affiliations]="suggestedItems"
        (onSelectedItem)="onSelectItem($event)"
      ></dirt-affiliation-suggestion-list>
    </div>
  </div>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-secondary" [disabled]="isSubmitting" (click)="activeModal.dismiss('dismiss')">
    Cancel
  </button>
  <button
    type="submit"
    class="btn btn-primary"
    [ngClass]="{ 'btn-loading': isSubmitting }"
    [disabled]="!frmAffiliation.isValid() || isSubmitting"
    (click)="onSubmit(frmAffiliation.getValue(), duplicates && duplicates.length > 0)"
    *ngIf="canSubmit()"
  >
    <span *ngIf="!(duplicates && duplicates.length > 0)" title="Will check for duplicates">Create & Attach</span>
    <span *ngIf="duplicates && duplicates.length > 0" title="Create despite duplicates">Create & Attach anyway</span>
  </button>
</div>
