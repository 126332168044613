<section class="di-person-affiliations">
  <div class="card">
    <div class="card-header">Affiliations ({{ 'LFKA' | productLabel }})</div>

    <div class="card-body">
      <dirt-organization-single-select-temp
        [clearAfterSelect]="true"
        [includeUnpolished]="true"
        *ngIf="!disabled"
        [required]="isFieldMandatory('affiliations')"
        [creationRequestHandler]="showAffiliationModal"
        (organizationSelected)="onSelect($event)"
      ></dirt-organization-single-select-temp>

      <form class="no-validate" novalidate>
        <div *ngFor="let afg of this.groupedAffiliations || []; let gidx = index" class="di-affiliation-part">
          <div class="card mt-3">
            <div class="card-header di-affiliation-header" (click)="toggleExpanded(afg.name)">
              <div class="d-inline-block">
                <i
                  class="fa fa-lg"
                  [ngClass]="{ 'fa-angle-right': !isExpanded(afg.name), 'fa-angle-down': isExpanded(afg.name) }"
                  aria-hidden="true"
                ></i>

                <span class="font-weight-bold pl-1 pr-2">{{ expanded.has(afg.name) ? '' : afg.name }}</span>

                <!-- Unpolished Badge -->
                <div class="d-inline-block ml-2" *ngIf="!isExpanded(afg.name) && hasAfgGroupUnpolished(afg)">
                  <div class="badge badge-warning" title="Not yet worked by central HCO team">Unpolished</div>
                </div>

                <!-- Inactive Badge -->
                <div class="d-inline-block ml-2" *ngIf="!isExpanded(afg.name) && hasAfgGroupOnlyInactivePositions(afg)">
                  <div class="badge badge-secondary">Inactive</div>
                </div>
              </div>

              <div
                class="error-message-wrapper"
                *ngIf="afg.affiliations?.length && isOutOfScopeCountry(afg.affiliations[0])"
              >
                <span ngbTooltip="People must never be actively affiliated with an out-of-scope country HCO.">
                  <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                </span>
              </div>
            </div>

            <div class="card-body di-aff-body" [ngbCollapse]="!isExpanded(afg.name)">
              <h5>{{ afg.name }}</h5>
              <h6 class="dirt-aff-original-department" *ngIf="afg.originalName">{{ afg.originalName }}</h6>

              <ng-template [ngIf]="afg.affiliations">
                <div
                  class="dirt-aff-container mb-3"
                  *ngFor="let af of afg.affiliations; trackBy: trackById; let idx = index"
                >
                  <ng-template
                    *ngTemplateOutlet="
                      affiliationBody;
                      context: { affiliation: af, parentAf: afg, idx: idx, trackBy: trackBy, gidx: gidx, indx: 0 }
                    "
                  ></ng-template>
                </div>
              </ng-template>

              <ng-template [ngIf]="!afg.affiliations">
                <div *ngFor="let af of afg.inactiveAffiliations; trackBy: trackById; let idx = index">
                  <div class="di-affiliation-header">
                    <div class="d-inline-block">
                      <span class="font-weight-bold pl-1 pr-2">{{ af.name }}</span>
                      <h6 class="dirt-aff-original-department" *ngIf="af.originalName">{{ af.originalName }}</h6>
                    </div>
                  </div>

                  <br />

                  <div
                    class="dirt-aff-container md-12"
                    *ngFor="let afi of af.inactiveAffiliations; trackBy: trackById; let indx = index"
                  >
                    <ng-container
                      *ngTemplateOutlet="
                        affiliationBody;
                        context: { affiliation: afi, parentAf: afg, idx: idx, trackBy: trackBy, gidx: gidx, indx: indx }
                      "
                    ></ng-container>
                  </div>
                </div>
              </ng-template>
            </div>
          </div>
        </div>

        <ng-template
          #affiliationBody
          let-af="affiliation"
          let-afg="parentAf"
          let-idx="idx"
          let-trackBy="trackBy"
          let-gidx="gidx"
          let-indx="indx"
        >
          <div [diMarkerWrapper]="af">
            <div
              class="dirt-aff-container__wrapper"
              *diMarker="{
                name: 'affiliationsLfka',
                area: reviewDisabled ? '--' : currentQcArea || 'BD',
                lfkaOnlyProject: lfkaOnlyProject
              }"
            >
              <div
                [class]="'dirt-cart-actions' + (af.department || afg.isInactiveAfg ? ' dirt-cart-actions-indent' : '')"
              >
                <div class="d-inline-block ml-2">
                  <dirt-verified-icon
                    class="di-affiliation-verified"
                    color="success"
                    ngbTooltip="Verified"
                    placement="top"
                    *ngIf="af.verified"
                  ></dirt-verified-icon>
                </div>

                <!-- Remove Button -->
                <div
                  class="d-inline-block ml-2 di-remove-btn"
                  style="cursor: pointer"
                  title="Remove"
                  *ngIf="canRemoveItem(af)"
                  (click)="removeAffiliation($event, af.id)"
                >
                  <i class="fa fa-times" aria-hidden="true"></i>
                </div>

                <!-- Type -->
                <div class="d-inline-block ml-2">
                  <div class="badge badge-dark">{{ af.type | capitalize }}</div>
                </div>

                <!-- Unpolished Badge -->
                <div class="d-inline-block ml-2" *ngIf="!af.readyForDelivery">
                  <div class="badge badge-warning" title="Not yet worked by central HCO team">Unpolished</div>
                </div>
              </div>

              <div class="dirt-cart-content">
                <!-- Name & Department -->
                <div class="d-flex justify-content-between align-items-baseline">
                  <div class="card-title" title="Name">
                    <ng-container *ngIf="!af.organization; else organizationName">
                      <a
                        [routerLink]="['/affiliation/detail', af.id]"
                        rel="noopener"
                        target="_blank"
                        *diAcl="'affiliation.detail'"
                        >{{ af.department || af.name }}</a
                      >
                      <span *diAcl="'!affiliation.detail'">
                        {{ af.department || af.name }}
                        <span
                          *ngIf="af.sourceLfka"
                          title="created by / for LFKA"
                          class="badge badge-info"
                          style="font-size: 10px"
                          >LFKA</span
                        >
                      </span>
                    </ng-container>

                    <ng-template #organizationName>
                      <a
                        [routerLink]="
                          af.organization?.root
                            ? ['/organization/detail', af.organization.root, af.organization._id]
                            : ['/organization/detail', af.organization._id]
                        "
                        rel="noopener"
                        target="_blank"
                        *diAcl="'organization.detail'"
                      >
                        {{ af.organization.fullName || af.organization.name }}
                      </a>
                      <span *diAcl="'!organization.detail'">
                        {{ af.organization.fullName || af.organization.name }}
                        <span
                          *ngIf="af.sourceLfka"
                          title="created by / for LFKA"
                          class="badge badge-info"
                          style="font-size: 10px"
                          >LFKA</span
                        >
                      </span>
                    </ng-template>

                    <i
                      class="fa fa-copy"
                      style="cursor: pointer"
                      (click)="onCopyAffiliationName(af)"
                      ngbTooltip="Copied"
                      [openDelay]="1000"
                      [closeDelay]="2000"
                      *diAcl="'person.qc || person.idqc || person.idbdqc'"
                    ></i>
                  </div>

                  <a
                    href="javascript:void(0)"
                    class="small pull-right pt-1 pr-2"
                    (click)="reportDuplicate(af)"
                    *diAcl="'affiliation.markDuplicateSuspect'"
                  >
                    Report Duplicate
                  </a>

                  <ng-template [diAcl]="'affiliation.create || affiliation.request || organization.request'">
                    <a
                      href="javascript:void(0)"
                      class="small pull-right pt-1 pr-2"
                      (click)="maintenanceRequestHandler(af, 'LFKA')"
                      *ngIf="!!maintenanceRequestHandler && af.readyForDelivery"
                    >
                      Request Maintenance
                    </a>
                  </ng-template>
                </div>

                <input
                  type="checkbox"
                  class="pull-right"
                  title="Affiliation checked"
                  [(ngModel)]="af.checked"
                  [ngModelOptions]="{ standalone: true }"
                  *diAcl="'person.qc || person.idqc || person.idbdqc'"
                />

                <div class="d-flex justify-content-between align-items-baseline ml-3">
                  <!-- Address -->
                  <ng-container *ngIf="!af.organizationAddress; else organizationAddress">
                    <div>
                      <address class="di-address" *ngIf="af.address?.countryCode">
                        <span *ngIf="af.address?.name || af.address?.street">
                          <span>{{ af.address.name || af.address.street }}</span>
                          <span>{{ af.address.additionalInfo }}</span>
                          <br />
                        </span>
                        <span *ngIf="af.address?.city">{{ af.address.city }},</span>
                        <span *ngIf="af.address?.state">{{ af.address.state }},</span>
                        <span>{{ getCountryTitle(af.address?.countryCode) }}</span>
                        <span *ngIf="af.address?.zip">{{ af.address.zip }}</span>
                      </address>
                    </div>

                    <!--Original Address -->
                    <div
                      *ngIf="
                        af.address?.originalStreet || af.address?.originalAdditionalInfo || af.address?.originalCity
                      "
                    >
                      <address class="di-address" *ngIf="af.address?.countryCode">
                        <span>
                          <span>{{ af.address.originalStreet }}</span>
                          <span>{{ af.address.originalAdditionalInfo }}</span>
                          <br />
                        </span>
                        <span *ngIf="af.address?.originalCity">{{ af.address.originalCity }}</span>
                      </address>
                    </div>
                  </ng-container>

                  <ng-template #organizationAddress>
                    <!-- Address -->
                    <div>
                      <address class="di-address">
                        <span *ngIf="af.organizationAddress?.name">
                          <span>{{ af.organizationAddress?.name }}</span>
                          <span>{{ af.organizationAddress?.additionalInfo }}</span>
                          <br />
                        </span>
                        <span *ngIf="af.organizationAddress?.city">{{ af.organizationAddress.city }},</span>
                        <span *ngIf="af.organizationAddress?.state">{{ af.organizationAddress.state }},</span>
                        <span>{{ getCountryTitle(af.organizationAddress?.countryCode) }}</span>
                        <span *ngIf="af.organizationAddress?.zip">{{ af.organizationAddress.zip }}</span>
                      </address>
                    </div>

                    <!--Original Address -->
                    <div
                      *ngIf="
                        af.organizationAddress.originalName ||
                        af.organizationAddress.originalAdditionalInfo ||
                        af.organizationAddress.originalCity
                      "
                    >
                      <address class="di-address" *ngIf="af.organizationAddress?.countryCode">
                        <span>
                          <span>{{ af.organizationAddress.originalStreet }}</span>
                          <span>{{ af.organizationAddress.originalAdditionalInfo }}</span>
                          <br />
                        </span>
                        <span *ngIf="af.organizationAddress?.originalCity">{{ af.address.originalCity }}</span>
                      </address>
                    </div>
                  </ng-template>
                </div>

                <!-- Position -->
                <div *ngFor="let pos of af.positions || []; let pidx = index">
                  <div
                    [ngClass]="{ 'di-drag-target': true, dragover: isPosOverTarget(af, pidx) }"
                    [hidden]="!isPosTargetVisible(af, pidx)"
                    (dragover)="dragOverPos($event, pidx)"
                    (dragleave)="dragLeavePos($event, pidx)"
                    (drop)="dropPos($event, pidx)"
                  ></div>
                  <div [diMarkerWrapper]="pos">
                    <div
                      class="form-row ml-3 mt-1 dirt-position-line"
                      [draggable]="isPosDragged(af, pidx)"
                      (dragend)="stopDragPos($event)"
                    >
                      <div class="form-group col-md-4">
                        <label [for]="'position_' + gidx + idx + '_' + pidx" *ngIf="pidx < 1">Position</label>
                        <input
                          type="text"
                          [name]="'position-' + gidx + idx + '-' + pidx"
                          [(ngModel)]="pos.position"
                          disabled
                          class="form-control"
                        />
                      </div>

                      <div class="form-group col-md-6">
                        <label [for]="'orgPosition_' + gidx + idx + '_' + pidx" *ngIf="pidx < 1"
                          >Original Position</label
                        >
                        <input
                          *diMarker="{
                            name: 'position',
                            area: reviewDisabled ? '--' : currentQcArea || 'BD',
                            lfkaOnlyProject: lfkaOnlyProject
                          }"
                          type="text"
                          [id]="'orgPosition_' + idx + '_' + pidx"
                          [class]="highlightFields()"
                          [name]="'originalPosition-' + gidx + idx + '-' + pidx"
                          [(ngModel)]="pos.originalPosition"
                          [disabled]="disabled"
                          (keyup)="onPositionKeyUp(pos)"
                        />
                      </div>

                      <div class="form-group col-md-2 dirt-position-actions">
                        <!-- Remove Button -->
                        <div
                          class="d-inline-block ml-2 di-remove-btn"
                          style="cursor: pointer"
                          title="Remove"
                          (click)="removePosition($event, af, pidx)"
                        >
                          <i class="fa fa-times" aria-hidden="true"></i>
                        </div>

                        <!-- Inactive Badge -->
                        <div class="d-inline-block ml-2">
                          <div
                            class="badge badge-secondary"
                            *ngIf="pos.inactive"
                            style="cursor: pointer"
                            (click)="setPosInactive($event, af, pos)"
                            title="Currently inactive - click to make active again"
                          >
                            I
                          </div>
                          <div class="di-inactive-btn" title="Make inactive" *ngIf="canSetInactive()">
                            <i
                              class="fa fa-ban"
                              *ngIf="!pos.inactive"
                              (click)="setPosInactive($event, af, pos)"
                              aria-hidden="true"
                            ></i>
                          </div>
                        </div>

                        <div
                          class="di-drag-handle"
                          (mousedown)="startDragPos($event, af, pidx)"
                          *ngIf="!pos.inactive"
                          title="start moving this position"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  *ngIf="(af.positions || []).length > 0"
                  [ngClass]="{ 'di-drag-target': true, dragover: isPosOverTarget(af, af.positions.length) }"
                  [hidden]="!isPosTargetVisible(af, af.positions.length)"
                  (dragover)="dragOverPos($event, af.positions.length)"
                  (dragleave)="dragLeavePos($event, af.positions.length)"
                  (drop)="dropPos($event, af.positions.length)"
                ></div>
                <div class="form-row ml-3 mt-1">
                  <i style="cursor: pointer" class="fa fa-plus" (click)="addPosition(af)"></i>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </form>

      <p class="text-center mt-1" *ngIf="!affiliations.length">No HCOs Attached</p>
    </div>
  </div>
</section>
