<section class="di-person-affiliations">
  <div class="card">
    <div class="card-header" *ngIf="isProfileCompiler">Affiliations ({{ 'LFTA' | productLabel }})</div>
    <div class="card-header" *ngIf="!isProfileCompiler">Affiliations</div>

    <div class="card-body">
      <dirt-organization-single-select-temp
        [clearAfterSelect]="true"
        [includeUnpolished]="true"
        *ngIf="!disabled"
        [required]="isRequired()"
        [creationRequestHandler]="showAffiliationModal"
        (organizationSelected)="onSelect($event)"
      ></dirt-organization-single-select-temp>

      <form #frmAffiliation="ngForm" class="no-validate" novalidate>
        <div *ngFor="let afg of groupedAffiliations; let gidx = index">
          <div class="card mt-3">
            <div class="card-header di-affiliation-header" (click)="toggleExpanded(afg.name)">
              <div class="d-inline-block">
                <i
                  class="fa fa-lg"
                  [ngClass]="{ 'fa-angle-right': !isExpanded(afg.name), 'fa-angle-down': isExpanded(afg.name) }"
                  aria-hidden="true"
                ></i>

                <span class="font-weight-bold pl-1 pr-2">{{ expanded.has(afg.name) ? '' : afg.name }}</span>

                <!-- Primary Badge -->
                <div class="d-inline-block ml-2" *ngIf="!isExpanded(afg.name) && hasPrimaryAffiliation(afg)">
                  <div class="badge badge-primary">Primary</div>
                </div>

                <!-- Primary Candidate Badge -->
                <div class="d-inline-block ml-2" *ngIf="!isExpanded(afg.name) && hasPrimaryCandidateAffiliation(afg)">
                  <div class="badge badge-primary">Primary Candidate</div>
                </div>

                <!-- Unpolished Badge -->
                <div class="d-inline-block ml-2" *ngIf="!isExpanded(afg.name) && hasUnpolishedAffiliation(afg)">
                  <div class="badge badge-warning" title="Not yet worked by central organizations team">Unpolished</div>
                </div>

                <!-- Inactive Badge -->
                <div
                  class="d-inline-block ml-2"
                  *ngIf="
                    !isExpanded(afg.name) &&
                    !hasPrimaryAffiliation(afg) &&
                    !hasPrimaryCandidateAffiliation(afg) &&
                    afg.isInactiveAfg
                  "
                >
                  <div class="badge badge-secondary">Inactive</div>
                </div>
              </div>

              <div
                class="error-message-wrapper"
                *ngIf="afg.affiliations?.length && isOutOfScopeCountry(afg.affiliations[0])"
              >
                <span ngbTooltip="People must never be actively affiliated with an out-of-scope country organization.">
                  <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                </span>
              </div>
            </div>

            <div class="card-body di-aff-body" [ngbCollapse]="!isExpanded(afg.name)">
              <h5>{{ afg.name }}</h5>
              <h6 class="dirt-aff-original-department" *ngIf="afg.originalName">{{ afg.originalName }}</h6>

              <ng-template [ngIf]="afg.affiliations">
                <div
                  class="dirt-aff-container mb-3"
                  *ngFor="let af of afg.affiliations; trackBy: trackById; let idx = index"
                >
                  <ng-template
                    *ngTemplateOutlet="
                      affiliationBody;
                      context: { affiliation: af, parentAf: afg, idx: idx, trackBy: trackBy, gidx: gidx, indx: 0 }
                    "
                  ></ng-template>
                </div>
              </ng-template>

              <ng-template [ngIf]="!afg.affiliations">
                <div *ngFor="let af of afg.inactiveAffiliations; trackBy: trackById; let idx = index">
                  <div class="di-affiliation-header">
                    <div class="d-inline-block">
                      <span class="font-weight-bold pl-1 pr-2">{{ af.name }}</span>
                      <h6 class="dirt-aff-original-department" *ngIf="af.originalName">{{ af.originalName }}</h6>
                    </div>
                  </div>

                  <br />

                  <div
                    class="dirt-aff-container md-12"
                    *ngFor="let afi of af.affiliations; trackBy: trackById; let indx = index"
                  >
                    <ng-container
                      *ngTemplateOutlet="
                        affiliationBody;
                        context: { affiliation: afi, parentAf: afg, idx: idx, trackBy: trackBy, gidx: gidx, indx: indx }
                      "
                    ></ng-container>
                  </div>
                </div>
              </ng-template>
            </div>
          </div>
        </div>

        <ng-template
          #affiliationBody
          let-af="affiliation"
          let-afg="parentAf"
          let-idx="idx"
          let-trackBy="trackBy"
          let-gidx="gidx"
          let-indx="indx"
        >
          <div [diMarkerWrapper]="af">
            <div
              class="dirt-aff-container__wrapper"
              *diMarker="{ name: 'affiliations', area: currentQcArea, lfkaOnlyProject: lfkaOnlyProject }"
            >
              <div
                [class]="'dirt-cart-actions' + (af.department || afg.isInactiveAfg ? ' dirt-cart-actions-indent' : '')"
              >
                <div class="d-inline-block ml-2" title="Department" *ngIf="af.department">
                  <i class="fa fa-university" aria-hidden="true"></i>
                </div>

                <div class="d-inline-block ml-2">
                  <dirt-verified-icon
                    class="di-affiliation-verified"
                    color="success"
                    ngbTooltip="Verified"
                    placement="top"
                    *ngIf="af.verified"
                  ></dirt-verified-icon>
                </div>

                <!-- Primary Badge -->
                <div class="d-inline-block ml-2">
                  <div class="badge badge-primary" *ngIf="af.primary">Primary</div>
                  <div
                    class="badge badge-primary"
                    *ngIf="af.primaryCandidate"
                    [ngStyle]="{ cursor: 'pointer' }"
                    (click)="unsetPrimaryCandidate(af.id)"
                  >
                    Primary Candidate
                  </div>
                  <div class="di-primary-btn" title="Make primary">
                    <i
                      class="fa fa-address-card-o"
                      [ngStyle]="{ cursor: hasAnyLastKnown() ? 'initial' : 'pointer' }"
                      *ngIf="!af.primary && af.readyForDelivery"
                      (click)="setPrimary($event, af.id)"
                      aria-hidden="true"
                    ></i>
                  </div>
                </div>

                <!-- Last known Badge -->
                <div class="d-inline-block ml-2" *ngIf="af.readyForDelivery">
                  <div
                    class="badge badge-last-known"
                    style="cursor: pointer"
                    *ngIf="af.lastKnown"
                    (click)="unsetLastKnown($event, af.id)"
                  >
                    Last known
                  </div>
                  <div class="di-last-known-btn" title="Make last known">
                    <i
                      class="fa fa-question-circle-o"
                      style="cursor: pointer"
                      *ngIf="!af.lastKnown"
                      (click)="setLastKnown($event, af.id)"
                      aria-hidden="true"
                    ></i>
                  </div>
                </div>

                <!-- Last known candidate Badge -->
                <div class="d-inline-block ml-2" *ngIf="!af.readyForDelivery">
                  <div
                    class="badge badge-last-known"
                    style="cursor: pointer"
                    *ngIf="af.lastKnownCandidate"
                    (click)="unsetLastKnown($event, af.id)"
                  >
                    Last known Candidate
                  </div>
                  <div class="di-last-known-btn" title="Make last known candidate">
                    <i
                      class="fa fa-question-circle-o"
                      style="cursor: pointer"
                      *ngIf="!af.lastKnownCandidate"
                      (click)="setLastKnownCandidate(af.id)"
                      aria-hidden="true"
                    ></i>
                  </div>
                </div>

                <!-- Remove Button -->
                <div
                  class="d-inline-block ml-2 di-remove-btn"
                  style="cursor: pointer"
                  title="Remove"
                  *ngIf="canRemoveItem(af)"
                  (click)="removeAffiliation($event, af.id)"
                >
                  <i class="fa fa-times" aria-hidden="true"></i>
                </div>

                <!-- Inactive Badge -->
                <div
                  class="d-inline-block ml-2"
                  *ngIf="!(af.primary || af.lastKnown || af.primaryCandidate || af.lastKnownCandidate)"
                >
                  <div
                    class="badge badge-secondary"
                    [ngStyle]="{ cursor: hasAnyLastKnown() ? 'initial' : 'pointer' }"
                    *ngIf="af.inactive"
                    (click)="setInactive($event, af.id)"
                  >
                    Inactive
                  </div>
                  <div class="di-inactive-btn" title="Make inactive" *ngIf="canSetInactive()">
                    <i
                      class="fa fa-ban"
                      [ngStyle]="{ cursor: hasAnyLastKnown() ? 'initial' : 'pointer' }"
                      *ngIf="!af.inactive"
                      (click)="setInactive($event, af.id)"
                      aria-hidden="true"
                    ></i>
                  </div>
                </div>

                <!-- Unpolished Badge -->
                <div class="d-inline-block ml-2" *ngIf="!af.readyForDelivery">
                  <div class="badge badge-warning" title="Not yet worked by central organizations team">Unpolished</div>
                </div>

                <!-- Unpolished Badge -->
                <div class="d-inline-block ml-2" *ngIf="af.hasSite">
                  <div class="badge badge-info">Site</div>
                </div>

                <!-- Type -->
                <div class="d-inline-block ml-2">
                  <div class="badge badge-dark">{{ af.type | capitalize }}</div>
                </div>
              </div>

              <div class="dirt-cart-content">
                <!-- Name & Department -->
                <div class="d-flex justify-content-between align-items-baseline">
                  <div class="card-title" title="Name">
                    <ng-container *ngIf="!af.organization; else organizationName">
                      <a
                        [routerLink]="['/affiliation/detail', af.id]"
                        rel="noopener"
                        target="_blank"
                        *diAcl="'affiliation.detail'"
                        >{{ af.department || af.name }}</a
                      >
                      <span *diAcl="'!affiliation.detail'">{{ af.department || af.name }}</span>
                    </ng-container>

                    <ng-template #organizationName>
                      <a
                        [routerLink]="
                          af.organization?.root
                            ? ['/organization/detail', af.organization.root, af.organization._id]
                            : ['/organization/detail', af.organization._id]
                        "
                        rel="noopener"
                        target="_blank"
                        *diAcl="'organization.detail'"
                      >
                        {{ af.organization.fullName || af.organization.name }}
                      </a>
                      <span *diAcl="'!organization.detail'">{{
                        af.organization.fullName || af.organization.name
                      }}</span>
                    </ng-template>

                    <i
                      class="fa fa-copy"
                      style="cursor: pointer"
                      (click)="onCopyAffiliationName(af)"
                      ngbTooltip="Copied"
                      [openDelay]="1000"
                      [closeDelay]="2000"
                      *diAcl="'person.qc || person.idqc || person.idbdqc'"
                    ></i>
                  </div>

                  <a
                    href="javascript:void(0)"
                    class="small pull-right pt-1 pr-2"
                    (click)="reportDuplicate(af)"
                    *diAcl="'affiliation.markDuplicateSuspect'"
                  >
                    Report Duplicate
                  </a>

                  <ng-template [diAcl]="'affiliation.create || affiliation.request || organization.request'">
                    <a
                      href="javascript:void(0)"
                      class="small pull-right pt-1 pr-2"
                      (click)="maintenanceRequestHandler(af, requestOwnerProduct)"
                      *ngIf="!!maintenanceRequestHandler && af.readyForDelivery"
                    >
                      Request Maintenance
                    </a>
                  </ng-template>
                </div>

                <input
                  type="checkbox"
                  class="pull-right"
                  title="Affiliation checked"
                  [(ngModel)]="af.checked"
                  [ngModelOptions]="{ standalone: true }"
                  *diAcl="'person.qc || person.idqc || person.idbdqc'"
                />

                <!-- Original Name & Department -->
                <div
                  class="d-flex justify-content-between align-items-baseline ml-3 font-italic"
                  *ngIf="af.originalDepartment"
                >
                  <div class="card-subtitle" title="Original Department">{{ af.originalDepartment }}</div>
                </div>

                <div class="d-flex justify-content-between align-items-baseline ml-3">
                  <!-- Address -->
                  <ng-container *ngIf="!af.organizationAddress; else organizationAddress">
                    <div>
                      <address class="di-address" *ngIf="af.address?.countryCode">
                        <span *ngIf="af.address?.name || af.address?.street">
                          <span>{{ af.address.name || af.address.street }}</span>
                          <span>{{ af.address.additionalInfo }}</span>
                          <br />
                        </span>
                        <span *ngIf="af.address?.city">{{ af.address.city }},</span>
                        <span *ngIf="af.address?.state">{{ af.address.state }},</span>
                        <span>{{ getCountryTitle(af.address?.countryCode) }}</span>
                        <span *ngIf="af.address?.zip">{{ af.address.zip }}</span>
                      </address>
                    </div>

                    <!--Original Address -->
                    <div
                      *ngIf="
                        af.address?.originalStreet || af.address?.originalAdditionalInfo || af.address?.originalCity
                      "
                    >
                      <address class="di-address" *ngIf="af.address?.countryCode">
                        <span>
                          <span>{{ af.address.originalStreet }}</span>
                          <span>{{ af.address.originalAdditionalInfo }}</span>
                          <br />
                        </span>
                        <span *ngIf="af.address?.originalCity">{{ af.address.originalCity }}</span>
                      </address>
                    </div>
                  </ng-container>

                  <ng-template #organizationAddress>
                    <!-- Address -->
                    <div>
                      <address class="di-address">
                        <span *ngIf="af.organizationAddress?.name">
                          <span>{{ af.organizationAddress?.name }}</span>
                          <span>{{ af.organizationAddress?.additionalInfo }}</span>
                          <br />
                        </span>
                        <span *ngIf="af.organizationAddress?.city">{{ af.organizationAddress.city }},</span>
                        <span *ngIf="af.organizationAddress?.state">{{ af.organizationAddress.state }},</span>
                        <span>{{ getCountryTitle(af.organizationAddress?.countryCode) }}</span>
                        <span *ngIf="af.organizationAddress?.zip">{{ af.organizationAddress.zip }}</span>
                      </address>
                    </div>

                    <!--Original Address -->
                    <div
                      *ngIf="
                        af.organizationAddress.originalName ||
                        af.organizationAddress.originalAdditionalInfo ||
                        af.organizationAddress.originalCity
                      "
                    >
                      <address class="di-address" *ngIf="af.organizationAddress?.countryCode">
                        <span>
                          <span>{{ af.organizationAddress.originalStreet }}</span>
                          <span>{{ af.organizationAddress.originalAdditionalInfo }}</span>
                          <br />
                        </span>
                        <span *ngIf="af.organizationAddress?.originalCity">{{ af.address.originalCity }}</span>
                      </address>
                    </div>
                  </ng-template>
                </div>

                <!-- Position -->
                <details
                  [open]="isPositionDetailOpen(afg, af)"
                  (toggle)="setPositionDetailOpen(af, $event.target.open)"
                >
                  <summary>
                    {{
                      (getPositionTitle(af.position) || af.position || 'No position selected') +
                        (af.originalPosition ? ' (' + af.originalPosition + ')' : '')
                    }}
                  </summary>
                  <div class="form-row ml-3 mt-1">
                    <div class="form-group col-md-4">
                      <label [for]="'position_' + gidx + '_' + idx + '_' + indx">Position</label>
                      <input
                        type="text"
                        [name]="'position-' + gidx + '-' + idx + '-' + indx"
                        [(ngModel)]="af.position"
                        disabled
                        class="form-control"
                      />
                    </div>

                    <div class="form-group col-md-8">
                      <label [for]="'orgPosition_' + gidx + '_' + idx + '_' + indx">Original Position</label>
                      <input
                        type="text"
                        [id]="'orgPosition_' + gidx + '_' + idx + '_' + indx"
                        [class]="highlightFields()"
                        [name]="'originalPosition-' + gidx + '-' + idx + '-' + indx"
                        [(ngModel)]="af.originalPosition"
                        [disabled]="disabled"
                        (focusout)="trimInput(af)"
                        (keyup)="onOrgPositionKeyUp(af)"
                        *diMarker="{ name: 'position', area: currentQcArea, lfkaOnlyProject: lfkaOnlyProject }"
                      />
                    </div>
                  </div>
                </details>
              </div>
            </div>
          </div>
        </ng-template>
      </form>

      <p class="text-center mt-1" *ngIf="!affiliations.length">No Affiliations Attached</p>
    </div>
  </div>
</section>

<ng-template #takeoverStrategyModal let-close="close">
  <div class="modal-header">
    <h4 class="modal-title pull-left">Takeover Strategy</h4>
  </div>

  <div class="modal-body">
    <div>
      <p>
        Please select the takeover strategy that will be applied once requested affiliation is polished by central
        organizations team.
      </p>

      <div>
        <input
          type="radio"
          name="takeover-strategy"
          id="requested-becomes-primary"
          [(ngModel)]="takeoverStrategy"
          [value]="affiliationTakeoverStrategy.REQUESTED_BECOMES_PRIMARY"
        />
        <label for="requested-becomes-primary">Requested becomes primary and previous primary will be disabled</label>
      </div>

      <div>
        <input
          type="radio"
          name="takeover-strategy"
          id="requested-becomes-primary-no-disable"
          [(ngModel)]="takeoverStrategy"
          [value]="affiliationTakeoverStrategy.REQUESTED_BECOMES_PRIMARY_NO_DISABLE"
        />
        <label for="requested-becomes-primary-no-disable"
          >Requested becomes primary and previous primary becomes Secondary</label
        >
      </div>

      <div>
        <input
          type="radio"
          name="takeover-strategy"
          id="requested-becomes-secondary"
          [(ngModel)]="takeoverStrategy"
          [value]="affiliationTakeoverStrategy.REQUESTED_BECOMES_SECONDARY"
        />
        <label for="requested-becomes-secondary">Requested becomes secondary</label>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <div class="pull-right">
      <button type="button" class="btn btn-secondary btn-sm" (click)="close()">Save</button>
    </div>
  </div>
</ng-template>
