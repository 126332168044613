<section class="dirt-section-list dirt-profile-grid">
  <header class="row mb-2">
    <!-- Left -->
    <div class="col-auto mr-auto">
      <h5 class="d-inline-block mr-2">Selected ({{ getSelection().length }} of {{ total }}{{ hasMore ? '+' : '' }})</h5>

      <!-- Direct Link Button -->
      <ng-container *ngIf="sectionId !== 'video'">
        <button
          class="btn btn-sm btn-outline-success align-baseline mr-3"
          (click)="onDirectLink()"
          *diAcl="'profile.directLink'"
        >
          <i class="fa fa-plus" aria-hidden="true"></i>
        </button>
      </ng-container>
      <!-- Direct Link Button Videos -->
      <ng-container *ngIf="sectionId === 'video'">
        <button
          class="btn btn-sm btn-outline-success align-baseline mr-3"
          (click)="onDirectLink()"
          *diAcl="'profile.videoDirectLink'"
        >
          <i class="fa fa-plus" aria-hidden="true"></i>
        </button>
        <!-- No videos -->
        <ng-container class="col-auto">
          <ui-switch
            [checked]="noVideos"
            (change)="onNoVideoFoundToggleChange($event)"
            [disabled]="total > 0 || !hasPermissionDirectLink()"
            size="small"
          ></ui-switch>
          <span>No Video Found</span>
        </ng-container>
      </ng-container>
      <!-- Match / Mismatch Buttons -->
      <ng-container *ngIf="sectionId !== 'video' && getSelection().length">
        <span *diAcl="'profile.update.prop.manual.match'">
          <button class="btn btn-sm btn-outline-primary align-baseline" (click)="toggleMatch(true)">
            <i class="fa fa-thumbs-up" aria-hidden="true"></i> Match
          </button>
          <button class="btn btn-sm btn-outline-primary align-baseline" (click)="toggleMatch(false)">
            <i class="fa fa-thumbs-down" aria-hidden="true"></i> Mismatch
          </button>
        </span>
      </ng-container>
      <!-- Edit / Delete Buttons -->
      <ng-container *ngIf="sectionId === 'video' && getSelection().length">
        <span *diAcl="'profile.videos'">
          <button
            *diAcl="'profile.videoDirectLink'"
            [style.visibility]="sectionId === 'video' && getSelection().length === 1 ? 'visible' : 'hidden'"
            class="btn btn-sm btn-outline-primary align-baseline"
            (click)="editVideo(true)"
          >
            <i class="fa fa-edit" aria-hidden="true"></i> Edit
          </button>
          <button
            *diAcl="'profile.videos.delete'"
            class="btn btn-sm btn-outline-primary align-baseline"
            (click)="deleteVideo(false)"
          >
            <i class="fa fa-trash" aria-hidden="true"></i> Delete
          </button>
        </span>
      </ng-container>
    </div>

    <!-- Right -->
    <div class="col-auto">
      <div
        *ngIf="trainingReportDelegates && trainingReportDelegates.hasTrainingFeaturesEnabled()"
        class="btn-group"
        role="group"
      >
        <dirt-save-training-report
          [report]="trainingReportDelegates.report"
          [comment]="trainingReportDelegates.comment"
          [saveReport]="trainingReportDelegates.saveReport"
        ></dirt-save-training-report>
      </div>
      <ng-container *ngIf="sectionId !== 'video'">
        <div class="btn-group" role="group" *diAcl="'profile.ml'">
          <button class="btn btn-sm btn-outline-primary" [ngClass]="{ active: isML }" (click)="setMLEnabled(true)">
            ML
          </button>
          <button class="btn btn-sm btn-outline-primary" [hidden]="!isML" (click)="setMLEnabled(false)">
            <i class="fa fa-times" aria-hidden="true"></i>
          </button>
        </div>
      </ng-container>
      <ng-container *ngIf="sectionId !== 'video'">
        <div class="btn-group" role="group" *diAcl="'profile.origin'">
          <button
            class="btn btn-sm btn-outline-primary"
            [ngClass]="{ active: isOrigin }"
            (click)="setOriginsEnabled(true)"
          >
            Origins
          </button>
          <button class="btn btn-sm btn-outline-primary" [hidden]="!isOrigin" (click)="setOriginsEnabled(false)">
            <i class="fa fa-times" aria-hidden="true"></i>
          </button>
        </div>
      </ng-container>
      <div *ngIf="sectionId !== 'video'" class="btn-group" role="group">
        <button
          *diAcl="'profile.qc'"
          class="btn btn-sm btn-outline-primary"
          [ngClass]="{ active: isQA }"
          (click)="setQAEnabled(true)"
        >
          QA
        </button>
        <button
          *diAcl="'profile.qc'"
          class="btn btn-sm btn-outline-primary"
          [hidden]="!isQA"
          (click)="setQAEnabled(false)"
        >
          <i class="fa fa-times" aria-hidden="true"></i>
        </button>
      </div>

      <div *ngIf="sectionId !== 'video'" class="btn-group" role="group">
        <button
          class="btn btn-sm btn-outline-primary"
          [ngClass]="{ active: isUnpolishedFilterActive() }"
          (click)="toggleUnpolished(true)"
        >
          Show unpolished
        </button>
        <button
          class="btn btn-sm btn-outline-primary"
          [hidden]="!isUnpolishedFilterActive()"
          (click)="toggleUnpolished()"
        >
          <i class="fa fa-times" aria-hidden="true"></i>
        </button>
      </div>

      <button class="btn btn-sm btn-outline-danger" [disabled]="!isFilterSet()" (click)="resetFilter()">
        Reset Filter
      </button>
    </div>
  </header>

  <!-- Grid -->
  <ag-grid-angular
    class="ag-grid ag-theme-fresh"
    rowSelection="multiple"
    rowModelType="serverSide"
    [animateRows]="true"
    [defaultColDef]="defaultColDef"
    [enableColResize]="true"
    [enableFilter]="true"
    [getContextMenuItems]="getContextMenuItems"
    [getRowNodeId]="getRowNodeId"
    [getRowClass]="getRowClass"
    [paginationPageSize]="pagingSize"
    [serverSideDatasource]="agEnterpriseDataSource"
    [frameworkComponents]="frameworkComponents"
    [toolPanelSuppressSideButtons]="true"
    [enableSorting]="true"
    (sortChanged)="sortAlphabetically()"
    (gridReady)="onGridReady($event)"
    (filterChanged)="onFilterChange()"
  ></ag-grid-angular>

  <!-- Saving indicator -->
  <div class="alert alert-warning" [ngClass]="{ hidden: !isSaving }">
    <i class="fa fa-circle-o-notch fa-spin fa-fw"></i>
    <span>Saving...</span>
  </div>
</section>
