<section class="dirt-section-list dirt-organization-list">
  <header class="row">
    <div class="col-sm-4">
      <h1>
        <span *ngIf="total">{{ total.count | number }}</span> Organizations <small>new</small>
      </h1>
    </div>
    <div class="col-sm-4" *ngIf="!advancedSearchSpec">
      <input
        type="search"
        class="di-search form-control ng-trim-ignore"
        [formControl]="searchCtrl"
        [(ngModel)]="searchTerm"
        placeholder="Search HCOs by name, id, address..."
      />
      <small><a class="alink" (click)="openAdvancedSearch()">Advanced search</a></small>
    </div>
    <div class="col-sm-4" *ngIf="advancedSearchSpec">
      <small
        ><a class="alink" (click)="openAdvancedSearch()">Edit search</a>
        &nbsp;-&nbsp;
        <a class="alink" (click)="clearAdvancedSearch()">Clear search</a></small
      >
    </div>
    <dialog class="advanced-search-dialog" #advancedSearchDialog (keyup.enter)="runAdvancedSearch()">
      <dirt-organization-advanced-search
        *ngIf="advancedSearchSpec"
        [model]="advancedSearchSpec"
      ></dirt-organization-advanced-search>
      <hr />
      <div class="pull-right">
        <button class="btn" (click)="advancedSearchDialogElement.nativeElement.close()">Cancel</button>
        <button class="btn" (click)="clearAdvancedSearch()">Clear</button>
        <button class="btn btn-primary" [disabled]="isAdvancedSearchDisabled()" (click)="runAdvancedSearch()">
          GO
        </button>
      </div>
    </dialog>
    <div class="col-sm-4">
      <div class="pull-right">
        <dirt-filters [filters]="filters" (onFilter)="onFilter($event)"></dirt-filters>
        <dirt-button-dropdown [title]="'Bulk'" [icon]="'fa-pencil'" *diAcl="'organization.bulk-list'">
          <button
            class="list-group-item list-group-item-action"
            [title]="bulkListDelegate.specialHint || ''"
            (click)="onOpenBulkListDialog()"
          >
            <i class="fa fa-th-list" aria-hidden="true"></i>
            Bulk Import
          </button>

          <button
            class="list-group-item list-group-item-action"
            [title]="jobBulkListDelegate.specialHint || ''"
            (click)="onOpenBulkListJobDialog()"
          >
            <i class="fa fa-th-list" aria-hidden="true"></i>
            Bulk Import Job
          </button>
        </dirt-button-dropdown>

        <!-- Create Button -->
        <button
          class="btn btn-success btn-sm"
          (click)="onOpenCreateOrganizationDialog()"
          *diAcl="'organization.create'"
        >
          <i class="fa fa-plus" aria-hidden="true"></i>
          &nbsp; Create Organization
        </button>
      </div>
    </div>
  </header>

  <!-- Pagination -->
  <dirt-pagination
    class="dirt-pagination"
    [id]="'dirt-organization-list'"
    [pagingSize]="organizations?.length || 0"
    [pagingSkip]="pagingSkip"
    [pagingLimit]="pagingLimit"
    [pagingTotal]="total?.count || 0"
    [hidden]="isLoading || pagingLimit >= total?.count"
    (pageChange)="getPage($event)"
  >
  </dirt-pagination>

  <!-- Table -->
  <div *ngIf="organizations" [hidden]="isLoading">
    <table class="table table-sm table-hover" [hidden]="!organizations.length">
      <thead>
        <tr>
          <th class="col-name">Name</th>
          <th class="col-type">Type</th>
          <th class="col-status">Status</th>
          <th class="col-websource">Websource</th>
          <th class="col-priority">Priority</th>
          <th class="col-country">Country</th>
          <th class="col-actions">&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        <ng-container
          *ngFor="
            let organization of organizations
              | paginate
                : {
                    id: 'dirt-organization-list',
                    itemsPerPage: pagingLimit,
                    currentPage: pagingPage,
                    totalItems: total?.count
                  }
          "
        >
          <tr class="dirt-organization-row">
            <td class="col-name">
              <a [routerLink]="['/organization/detail', organization._id]">{{ organization.name }}</a>
              <span class="pull-right action-buttons">
                <button
                  *diAcl="'organization.move'"
                  class="btn btn-secondary btn-sm"
                  (click)="onOpenMoveChildToParentDialog(organization)"
                >
                  Move
                </button>
                <button
                  *diAcl="'organization.merge'"
                  class="btn btn-secondary btn-sm"
                  (click)="onOpenHealthSystemMergeDialog(organization)"
                >
                  Merge
                </button>
              </span>
            </td>
            <td class="col-type">
              {{ organization.type }}
            </td>
            <td class="col-status">
              {{ organization._meta.status }}
            </td>
            <td class="col-websource">
              <a href="{{ organization.websource }}" target="_blank" rel="noopener noreferrer">
                <i class="fa fa-external-link" aria-hidden="true"></i>
                {{ organization.websource }}
              </a>
            </td>
            <td class="col-priority">
              <ngb-rating
                [(rate)]="organization.priority"
                (rateChange)="setPriority(organization, $event)"
                [readonly]="!isPriorityEditable()"
                (click)="$event.stopPropagation()"
                max="5"
              >
                <ng-template let-fill="fill" let-index="index">
                  <span class="star" [class.filled]="fill === 100" [class.bad]="index < 3">&#9733;</span>
                </ng-template>
              </ngb-rating>
            </td>
            <td class="col-country">
              {{ orgIdToCountryListMap.get(organization._id) ?? '' }}
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>

    <!-- Empty Message -->
    <p class="dirt-no-results" *ngIf="!organizations.length">No Organizations</p>
  </div>

  <!-- Pagination -->
  <dirt-pagination
    class="dirt-pagination"
    [id]="'dirt-organization-list'"
    [pagingSize]="organizations?.length || 0"
    [pagingSkip]="pagingSkip"
    [pagingLimit]="pagingLimit"
    [pagingTotal]="total?.count || 0"
    [hidden]="isLoading || pagingLimit >= total?.count"
    (pageChange)="getPage($event)"
  >
  </dirt-pagination>

  <!-- Loading Message -->
  <p class="dirt-loading" *ngIf="isLoading">
    <i class="fa fa-refresh fa-spin fa-2x" aria-hidden="true"></i>
    <span class="sr-only">Loading...</span>
  </p>
</section>

<!-- Modal: move to parent -->
<ng-template #moveToNewParentModal let-d="dismiss" let-c="close">
  <div class="dirt-content-wrapper" tabindex="0">
    <div class="modal-header">
      <h5 class="modal-title">
        Move <b>{{ this.orgToMove.name }}</b> to new Parent Organization
      </h5>
      <button type="button" class="close" aria-label="Close" (click)="d()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div *ngIf="!newParentOrg">
        <p>
          Please select an existing health system or create a new one to move <b>{{ this.orgToMove.name }}</b> into.
        </p>
        <!-- Global Organization Search -->
        <dirt-organization-lookup
          [allowOutsideRoot]="false"
          [outsideRoot]="true"
          [onlyRoot]="true"
          [minimumCharactersBeforeSearch]="3"
          (organizationChange)="newParentOrg = $event"
          [ignoreOrgIds]="[orgToMove._id]"
        ></dirt-organization-lookup>
        <hr />
        <!-- Create Button -->
        <button
          class="btn btn-success btn-sm"
          (click)="onOpenCreateOrganizationDuringMoveToParentDialog()"
          *diAcl="'organization.create'"
        >
          <i class="fa fa-plus" aria-hidden="true"></i>
          &nbsp; Create Organization
        </button>
      </div>
      <div *ngIf="newParentOrg">
        <p>You are about to move</p>
        <p style="text-align: center">
          <a href="/organization/detail/{{ orgToMove.root }}" target="_blank">{{ orgToMove.name }}</a>
        </p>
        <p>into</p>
        <p style="text-align: center">
          <a href="/organization/detail/{{ newParentOrg.root }}" target="_blank">{{ newParentOrg.name }}</a>
        </p>
      </div>
      <div class="pull-right" style="margin: 12px 0">
        <button class="btn btn-sm btn-secondary" (click)="d()">Cancel</button>
        <button class="btn btn-sm btn-primary" [disabled]="!newParentOrg" (click)="onMoveChildToParent()">Move</button>
      </div>
    </div>
  </div>
</ng-template>

<!-- Modal: merge healthsystems -->
<ng-template #healthSystemMergeModal let-d="dismiss" let-c="close">
  <div class="dirt-content-wrapper" tabindex="0">
    <div class="modal-header">
      <h5 class="modal-title">
        Merge <b>{{ this.healthSystemMergeLoser.name }}</b> into other Healthsystem
      </h5>
      <button
        type="button"
        class="close"
        aria-label="Close"
        [disabled]="healthSystemMergeState === 'pending'"
        (click)="onHealthSystemMergeCancel()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div *ngIf="!healthSystemMergeWinner && healthSystemMergeState !== 'done'">
        <p>
          Please select an existing health system to merge <b>{{ this.healthSystemMergeLoser.name }}</b> into.
        </p>
        <!-- Global Organization Search -->
        <dirt-organization-lookup
          [allowOutsideRoot]="false"
          [outsideRoot]="true"
          [onlyRoot]="true"
          [minimumCharactersBeforeSearch]="3"
          (organizationChange)="healthSystemMergeWinner = $event"
          [ignoreOrgIds]="[healthSystemMergeLoser._id]"
        ></dirt-organization-lookup>
      </div>
      <div *ngIf="healthSystemMergeWinner">
        <p *ngIf="healthSystemMergeState !== 'done'">You are about to merge</p>
        <p *ngIf="healthSystemMergeState === 'done'">Successfully merged</p>
        <p style="text-align: center">
          <a href="/organization/detail/{{ healthSystemMergeLoser.root }}" target="_blank">{{
            healthSystemMergeLoser.name
          }}</a>
        </p>
        <p>into</p>
        <p style="text-align: center">
          <a href="/organization/detail/{{ healthSystemMergeWinner.root }}" target="_blank">{{
            healthSystemMergeWinner.name
          }}</a>
        </p>
      </div>
      <div class="merge-modal-spinner" *ngIf="healthSystemMergeState === 'pending'">
        <i class="fa fa-spinner fa-pulse"></i>
      </div>
      <div class="pull-right" style="margin: 12px 0">
        <button
          class="btn btn-sm btn-secondary"
          [disabled]="healthSystemMergeState === 'pending'"
          (click)="onHealthSystemMergeCancel()"
        >
          Cancel
        </button>
        <button
          class="btn btn-sm btn-primary"
          *ngIf="healthSystemMergeState !== 'done'"
          [disabled]="!healthSystemMergeWinner || healthSystemMergeState === 'pending'"
          (click)="onMergeHealthSystem()"
        >
          Merge
        </button>
        <button
          class="btn btn-sm btn-primary"
          *ngIf="healthSystemMergeState === 'done'"
          (click)="onHealthSystemMergeCancel()"
        >
          Close
        </button>
      </div>
    </div>
  </div>
</ng-template>
