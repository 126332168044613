import { catchError } from 'rxjs/operators';
import { EMPTY, Observable, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { environment } from '../../../../environments/environment';
import { ErrorModalComponent } from '../../components/error-modal/error-modal.component';
import { RESTClient, DefaultHeaders, BaseUrl } from '../rest/rest.service';
import { recordErrorIntoRUM } from '../observability/cloudwatch-rum.service';
import { TOKEN_NAME } from '../../services/auth/auth.constants';

const REDIRECT_NAME = 'redirectUrl';

@Injectable()
@BaseUrl(environment.apiEndpoint)
@DefaultHeaders({
  Accept: 'application/json',
  'Content-Type': 'application/json',
})
export class APIService extends RESTClient {
  constructor(protected http: HttpClient, private svcModal: NgbModal, private readonly jwtHelper: JwtHelperService) {
    super(http);
  }

  conflictInterceptor(error): string {
    if (error.verbatim) {
      return error.message;
    }
    return /DUPLICATE_ERROR/.test(error.message) ? 'Duplicate entry!' : 'Outdated record! Refresh and try again.';
  }
  /**
   * Response Interceptor
   *
   * @method responseInterceptor
   * @param {HttpResponse} resp - response object
   * @returns {HttpResponse} resp - transformed response object
   */
  protected responseInterceptor(resp: Observable<HttpResponse<any>>): Observable<HttpResponse<any>> {
    return resp.pipe(catchError((err) => this.handleError(err)));
  }

  handleError(resp: HttpErrorResponse): Observable<HttpResponse<any>> {
    const { error } = resp;
    let message = '';

    switch (resp.status) {
      // Bad Request
      case 400:
        message = `[${error.error}] ${error.message}`;
        break;

      // Unauthorized
      case 401:
        this.handleUnauthorized();
        message = `[${error.error}] ${error.message}`;
        break;

      // Forbidden
      case 403:
        message = error.verbatim ? error.message : 'Forbidden!';
        break;

      // Not Found
      case 404:
        message = 'Not Found!';
        break;

      // Conflict
      case 409:
        message = this.conflictInterceptor(error);
        break;

      // Precondition Failed
      case 412:
        // Do nothing and let the controller handle
        break;

      // Unprocessable Entity
      case 422:
        message = `[${error.error}] ${error.message}`; // propagate the real error
        break;

      // Internal Server Error
      case 500:
        message = error.verbatim ? error.message : 'Internal Server Error!';
        break;

      // Service Unavailable
      case 503:
        message = 'The server did not respond in a timely fashion!';
        break;

      // Other
      default:
        message = `Error Code: ${resp.status}. Message: ${resp.message || error.message}`;
    }

    if (message) {
      const ref = this.svcModal.open(ErrorModalComponent);

      ref.componentInstance.requestID = resp.headers?.get('X-Request-ID');
      ref.componentInstance.errorMessage = message;
      ref.componentInstance.errorTechnical = resp.message || error.message;
      ref.componentInstance.apiService = this; // auto-injection fails due to circular dependency

      const details = this.getErrorDetails(
        ref.componentInstance.requestID,
        new Date().toISOString(),
        message,
        resp.message || error.message
      );

      message = `[cLean ERROR] ${resp.error.name}: ${message} - From stack trace: ${resp.error.stack}, with extra details: ${details}.`;
    }

    if (error && environment.enableRUM) {
      recordErrorIntoRUM(error);
    }

    return throwError(() => resp);
  }

  getErrorDetails(requestID: string, errorTime: string, errorMessage: string, errorTechnical: string): string {
    let user = 'unknown';
    try {
      const token = this.jwtHelper.decodeToken(localStorage.getItem(TOKEN_NAME));
      user = token['https://veeva.io/user_id'] || token.user_id; // still support the old way
    } catch (_e) {} // make sure we SHOW

    const details = [
      `Request ID: ${requestID}`,
      `Timestamp (UTC): ${errorTime}`,
      `User ID: ${user}`,
      `Error message: ${errorMessage}`,
      `Technical error: ${errorTechnical}`,
    ];

    return details.join('\n');
  }

  private handleUnauthorized(): Observable<HttpResponse<any>> {
    // invalidate the token - remove authToken from storage
    localStorage.removeItem(TOKEN_NAME);

    // TODO: inject router and find the current url
    const url = window.location.pathname;
    if (url && url.length > 0) {
      localStorage.setItem(REDIRECT_NAME, url);
    } else {
      localStorage.removeItem(REDIRECT_NAME);
    }

    // TODO: inject router and call `router.navigate`
    window.location.href = '/login';

    return EMPTY;
  }
}
