<form #frmPerson="ngForm" novalidate>
  <div class="clearfix" [diMarkerWrapper]="model" [dirtTrainingEvaluationMarkerWrapper]="basePerson">
    <div class="row">
      <div class="col-md-10">
        <!-- Tags -->
        <div class="form-group row">
          <div class="col-sm-12">
            <div class="mb-3" *diAcl="'person.tag.list'">
              <tag-input
                name="tags"
                [(ngModel)]="model.tags"
                [onlyFromAutocomplete]="true"
                [placeholder]="isFieldEditable('tags') ? 'Add tag' : ''"
                [disable]="!isFieldEditable('tags')"
              >
                <tag-input-dropdown [autocompleteObservable]="searchTags()" [focusFirstElement]="true">
                </tag-input-dropdown>
              </tag-input>
            </div>
          </div>
        </div>
      </div>
      <!-- Avatar -->
      <div class="col-md-2 d-flex align-items-center justify-content-center" *ngIf="model.image?.local_url">
        <dirt-avatar
          [readonly]="true"
          [local_url]="model.image?.local_url_nocache || model.image?.local_url"
          [loading]="isTogglingPhotoApproval"
          (onSetPhotoApproval)="setPhotoApproval($event)"
          (onTogglePhotoApproval)="togglePhotoApproval()"
        >
        </dirt-avatar>
      </div>
    </div>

    <hr />

    <!-- Name -->
    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2">Name</label>
      <div class="col-sm-8 col-md-10">
        <div class="row">
          <!-- First Name -->
          <div class="col-md-3">
            <label class="sr-only" for="firstName">First Name</label>
            <span *dirtTrainingEvaluationMarker="'firstName'"></span>
            <input
              type="text"
              id="firstName"
              name="firstName"
              [(ngModel)]="model.firstName"
              placeholder="First Name"
              [disabled]="!isFieldEditable('firstName')"
              [class]="highlightFields()"
              dirtPasteNormalizer
              autofocus
              required
              *diMarker="{ name: 'firstName', area: reviewMarkerArea, lfkaOnlyProject: isOnlyKAMProject() }"
              validateNameWithoutDot
              (blur)="onNameBlur()"
              maxlength="{{ maxNameLength }}"
              pattern="{{ namePattern }}"
            />
          </div>
          <!-- Middle Name -->
          <div class="col-md-3">
            <label class="sr-only" for="middleName">Middle Name</label>
            <span *dirtTrainingEvaluationMarker="'middleName'"></span>
            <input
              type="text"
              id="middleName"
              [class]="highlightFields()"
              name="middleName"
              [(ngModel)]="model.middleName"
              dirtPasteNormalizer
              placeholder="Middle Name"
              [disabled]="!isFieldEditable('middleName')"
              *diMarker="{ name: 'middleName', area: reviewMarkerArea, lfkaOnlyProject: isOnlyKAMProject() }"
              validateNameWithoutDot
              maxlength="{{ maxNameLength }}"
              pattern="{{ namePattern }}"
            />
          </div>
          <!-- Last Name -->
          <div class="col-md-3">
            <label class="sr-only" for="lastName">Last Name</label>
            <span *dirtTrainingEvaluationMarker="'lastName'"></span>
            <input
              type="text"
              id="lastName"
              [class]="highlightFields()"
              name="lastName"
              [(ngModel)]="model.lastName"
              placeholder="Last Name"
              [disabled]="!isFieldEditable('lastName')"
              dirtPasteNormalizer
              required
              *diMarker="{ name: 'lastName', area: reviewMarkerArea, lfkaOnlyProject: isOnlyKAMProject() }"
              validateName
              (blur)="onNameBlur()"
              maxlength="{{ maxNameLength }}"
              pattern="{{ namePattern }}"
            />
          </div>

          <div class="col-md-1">
            <!-- Copy Name Parts -->
            <i class="fa fa-copy" style="cursor: pointer" (click)="onCopyName()" ngbTooltip="Copy Name"></i>

            <!-- Search Person -->
            <i
              class="fa fa-search"
              *diAcl="'person.list'"
              style="cursor: pointer"
              (click)="onSearchPerson()"
              ngbTooltip="Search Person"
            ></i>
          </div>

          <!-- Suffix -->
          <div class="col-md-2">
            <label class="sr-only" for="suffix">Suffix</label>
            <span *dirtTrainingEvaluationMarker="'suffix'"></span>
            <select
              id="suffix"
              name="suffix"
              [class]="highlightFields('custom-select')"
              [(ngModel)]="model.suffix"
              [disabled]="!isFieldEditable('suffix')"
              *diMarker="{ name: 'suffix', area: reviewMarkerArea, lfkaOnlyProject: isOnlyKAMProject() }"
            >
              <option [ngValue]="undefined">&mdash; Suffix &mdash;</option>
              <option *ngFor="let suffix of suffixes" [value]="suffix.code">
                {{ suffix.title }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>

    <!-- Nicknames -->
    <div *diMarker="{ name: 'nicknames', area: reviewMarkerArea, lfkaOnlyProject: isOnlyKAMProject() }">
      <div class="form-group row" *ngFor="let nickname of model.nicknames; let idx = index; trackBy: trackByIndex">
        <label class="col-form-label col-sm-4 col-md-2" for="nickname-{{ idx }}">Nickname</label>
        <div class="col-sm-8 col-md-10">
          <div class="input-group">
            <input
              type="text"
              id="nickname-{{ idx }}"
              [class]="highlightFields()"
              name="nickname-{{ idx }}"
              [(ngModel)]="model.nicknames[idx]"
              [disabled]="!isFieldEditable('nicknames')"
              dirtPasteNormalizer
            />
            <div *ngIf="idx == model.nicknames.length - 1">
              <span *dirtTrainingEvaluationMarker="'nicknames'"></span>
            </div>
            <div class="input-group-append">
              <!-- Button: Add -->
              <button
                type="button"
                class="btn btn-secondary"
                (click)="pushItemToList(model.nicknames)"
                *ngIf="idx == model.nicknames.length - 1 && isFieldEditable('nicknames')"
              >
                <i class="fa fa-plus-circle"></i>
              </button>

              <!-- Button: Remove -->
              <button
                type="button"
                class="btn btn-secondary"
                (click)="removeFromByIndex(model.nicknames, idx) && isFieldEditable('nicknames')"
                *ngIf="idx > 0 && isFieldEditable('nicknames')"
              >
                <i class="fa fa-minus-circle"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="isCountryWorkflowEnabled()">
      <hr />

      <!-- Country (Workflow) -->
      <div class="form-group row">
        <label class="col-form-label col-sm-4 col-md-2" for="countryWorkflow">Country (Workflow)</label>
        <span *dirtTrainingEvaluationMarker="'countryWorkflow'"></span>
        <div class="col-sm-8 col-md-10">
          <select
            class="form-control custom-select"
            id="countryWorkflow"
            name="countryWorkflow"
            [disabled]="!isFieldEditable('countryWorkflow')"
            [(ngModel)]="model.countryWorkflow"
            *diMarker="{
              name: 'countryWorkflow',
              area: reviewMarkerArea || 'ALL',
              lfkaOnlyProject: isOnlyKAMProject()
            }"
            [required]="isCountryMandatory()"
          >
            <option *ngFor="let country of countries$ | async" [value]="country.code">
              {{ country.title }}
            </option>
          </select>
        </div>
      </div>
    </div>

    <div *ngIf="isIdentifying()">
      <hr />

      <!-- Country (override for identification) -->
      <div class="form-group row">
        <label
          class="col-form-label col-sm-4 col-md-2"
          for="countryOverride"
          title="specify another country to route identification - this country will be used, not the primary affiliation"
          >Country (Override)</label
        >
        <div class="col-sm-8 col-md-10">
          <select
            class="form-control custom-select"
            id="countryOverride"
            name="countryOverride"
            [(ngModel)]="model.countryOverride"
            [disabled]="!isFieldEditable('countryOverride')"
          >
            <option [value]="null">-</option>
            <option *ngFor="let country of countries$ | async" [value]="country.code">
              {{ country.title }}
            </option>
          </select>
        </div>
      </div>
    </div>

    <hr />

    <!-- Original Names -->
    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2">Original Name</label>
      <div class="col-sm-8 col-md-10">
        <div class="row">
          <!-- First Name -->
          <div class="col-sm-4">
            <label class="sr-only" for="orgFirstName">Original First Name</label>
            <span *dirtTrainingEvaluationMarker="'originalFirstName'"></span>
            <input
              type="text"
              id="orgFirstName"
              [class]="highlightFields()"
              name="orgFirstName"
              [(ngModel)]="model.originalFirstName"
              placeholder="Original First Name"
              [disabled]="!isFieldEditable('originalFirstName')"
              *diMarker="{ name: 'originalFirstName', area: reviewMarkerArea, lfkaOnlyProject: isOnlyKAMProject() }"
              dirtPasteNormalizer
              validateName
              maxlength="{{ maxNameLength }}"
              pattern="{{ namePattern }}"
            />
          </div>
          <!-- Middle Name -->
          <div class="col-sm-4">
            <label class="sr-only" for="orgMiddleName">Original Middle Name</label>
            <span *dirtTrainingEvaluationMarker="'originalMiddleName'"></span>
            <input
              type="text"
              id="orgMiddleName"
              [class]="highlightFields()"
              name="orgMiddleName"
              [(ngModel)]="model.originalMiddleName"
              placeholder="Original Middle Name"
              [disabled]="!isFieldEditable('originalMiddleName')"
              *diMarker="{ name: 'originalMiddleName', area: reviewMarkerArea, lfkaOnlyProject: isOnlyKAMProject() }"
              dirtPasteNormalizer
              validateName
              maxlength="{{ maxNameLength }}"
              pattern="{{ namePattern }}"
            />
          </div>
          <!-- Last Name -->
          <div class="col-sm-4">
            <label class="sr-only" for="orgLastName">Original Last Name</label>
            <span *dirtTrainingEvaluationMarker="'originalLastName'"></span>
            <input
              type="text"
              id="orgLastName"
              [class]="highlightFields()"
              name="orgLastName"
              [(ngModel)]="model.originalLastName"
              placeholder="Original Last Name"
              [disabled]="!isFieldEditable('originalLastName')"
              *diMarker="{ name: 'originalLastName', area: reviewMarkerArea, lfkaOnlyProject: isOnlyKAMProject() }"
              dirtPasteNormalizer
              validateName
              maxlength="{{ maxNameLength }}"
              pattern="{{ namePattern }}"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- Full Name -->
    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2" for="orgFullName">Original Full Name</label>
      <div class="col-sm-7 col-md-9">
        <span *dirtTrainingEvaluationMarker="'originalFullName'"></span>
        <input
          type="text"
          id="orgFullName"
          [class]="highlightFields()"
          name="originalFullName"
          [(ngModel)]="model.originalFullName"
          [disabled]="!isFieldEditable('originalFullName')"
          *diMarker="{ name: 'originalFullName', area: reviewMarkerArea, lfkaOnlyProject: isOnlyKAMProject() }"
          dirtPasteNormalizer
          validateName
          maxlength="{{ maxFullNameLength }}"
          pattern="{{ namePattern }}"
        />
      </div>
      <div class="col-md-1">
        <!-- Copy original full name -->
        <i
          class="fa fa-copy"
          style="cursor: pointer"
          (click)="onCopyOriginalFullName()"
          ngbTooltip="Copy original full name"
        ></i>
      </div>
    </div>

    <!-- Original Language -->
    <div class="form-group row" *ngIf="model.id && (model.originalLanguage || model.originalFullName)">
      <label class="col-form-label col-sm-4 col-md-2" for="originalLanguage">Org. Language</label>
      <div class="col-sm-8 col-md-10">
        <select
          id="originalLanguage"
          class="form-control custom-select"
          name="originalLanguage"
          [(ngModel)]="model.originalLanguage"
          [disabled]="!isFieldEditable('originalLanguage')"
        >
          <option *ngFor="let language of languages$ | async" [value]="language.code">{{ language.title }}</option>
        </select>
      </div>
    </div>

    <!-- Alternative Names -->
    <div *diMarker="{ name: 'alternativeNames', area: reviewMarkerArea, lfkaOnlyProject: isOnlyKAMProject() }">
      <div
        class="form-group row"
        *ngFor="let altName of model.alternativeNames; let idx = index; trackBy: trackByIndex"
      >
        <label class="col-form-label col-sm-4 col-md-2" for="altName-{{ idx }}">Alt. Name</label>
        <div class="col-sm-8 col-md-10">
          <div class="input-group">
            <input
              type="text"
              id="altName-{{ idx }}"
              [class]="highlightFields()"
              name="altName-{{ idx }}"
              [(ngModel)]="model.alternativeNames[idx]"
              [disabled]="!isFieldEditable('alternativeNames')"
              dirtPasteNormalizer
              validateName
              maxlength="{{ maxFullNameLength }}"
              pattern="{{ namePattern }}"
            />
            <div *ngIf="idx == model.alternativeNames.length - 1">
              <span *dirtTrainingEvaluationMarker="'alternativeNames'"></span>
            </div>
            <div class="input-group-append">
              <!-- Button: Add -->
              <button
                type="button"
                class="btn btn-secondary"
                (click)="pushItemToList(model.alternativeNames)"
                *ngIf="idx == model.alternativeNames.length - 1 && isFieldEditable('alternativeNames')"
              >
                <i class="fa fa-plus-circle"></i>
              </button>
              <!-- Button: Remove -->
              <button
                type="button"
                class="btn btn-secondary"
                (click)="removeFromByIndex(model.alternativeNames, idx) && isFieldEditable('alternativeNames')"
                *ngIf="idx > 0"
              >
                <i class="fa fa-minus-circle"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <hr />

    <!-- Name Link -->
    <div *diMarker="{ name: 'nameLinks', area: reviewMarkerArea, lfkaOnlyProject: isOnlyKAMProject() }">
      <div class="form-group row" *ngFor="let nameLink of model.nameLinks; let idx = index; trackBy: trackByIndex">
        <label class="col-form-label col-sm-4 col-md-2 text-primary">Name Link</label>
        <div class="col-sm-8 col-md-10">
          <div class="input-group">
            <!-- FIXME: Highlight fields: [class]="highlightFields('dirt-name-links')" -->
            <dirt-link
              [(url)]="model.nameLinks[idx]"
              name="nameLinks-{{ idx }}"
              [form]="ngForm"
              [disabled]="!isFieldEditable('nameLinks')"
            >
              <button
                type="button"
                class="btn btn-secondary"
                (click)="pushItemToList(model.nameLinks)"
                *ngIf="idx == model.nameLinks.length - 1 && isFieldEditable('nameLinks') && model.nameLinks.length < 3"
              >
                <i class="fa fa-plus-circle"></i>
              </button>

              <button
                type="button"
                class="btn btn-secondary"
                (click)="removeFromByIndex(model.nameLinks, idx)"
                *ngIf="idx > 0 && isFieldEditable('nameLinks')"
              >
                <i class="fa fa-minus-circle"></i>
              </button>
            </dirt-link>

            <div *ngIf="idx == model.nameLinks.length - 1">
              <span *dirtTrainingEvaluationMarker="'nameLinks'"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="checkFindings['nameLinks']?.length > 0" class="info">
      Non-compliant links were removed automatically: {{ checkFindings['nameLinks'].join(', ') }}
    </div>

    <hr />

    <!-- CV Link -->
    <div *diMarker="{ name: 'cvLinks', area: reviewMarkerArea || 'ALL', lfkaOnlyProject: isOnlyKAMProject() }">
      <div class="form-group row" *ngFor="let cvLink of model.cvLinks; let idx = index; trackBy: trackByIndex">
        <label class="col-form-label col-sm-4 col-md-2 text-primary"
          ><span *ngIf="idx === 0">Master </span>CV Link</label
        >
        <div class="col-sm-8 col-md-10">
          <div class="input-group">
            <!-- FIXME: Highlight fields: [class]="highlightFields('dirt-cv-links')" -->
            <dirt-link
              [(url)]="model.cvLinks[idx]"
              name="cvLinks-{{ idx }}"
              [form]="ngForm"
              [disabled]="!isCVLinkEditable(idx)"
              [required]="!initialOpenDataId && isCVLinkMandatory()"
              (urlChange)="onCVLinksBlur()"
            >
              <button
                type="button"
                class="btn btn-secondary"
                (click)="pushItemToList(model.cvLinks)"
                *ngIf="idx == model.cvLinks.length - 1 && isFieldEditable('cvLinks') && model.cvLinks.length < 3"
              >
                <i class="fa fa-plus-circle"></i>
              </button>

              <button
                type="button"
                class="btn btn-secondary"
                (click)="removeFromByIndex(model.cvLinks, idx)"
                *ngIf="idx > 0 && isFieldEditable('cvLinks')"
              >
                <i class="fa fa-minus-circle"></i>
              </button>
            </dirt-link>

            <div *ngIf="idx == model.cvLinks.length - 1"><span *dirtTrainingEvaluationMarker="'cvLinks'"></span></div>
          </div>

          <div
            class="info"
            *ngIf="idx === 0 && model.originalMasterCVLink && model.cvLinks[idx] !== model.originalMasterCVLink"
          >
            Current master CV link different from original master CV link: {{ model.originalMasterCVLink }}
          </div>
        </div>
      </div>

      <div class="form-group row potential-duplicates" *ngIf="duplicatesByCVLink.length > 0">
        <label class="col-form-label col-sm-4 col-md-2">Possible duplicate of:</label>
        <div class="col-sm-8 col-md-10">
          <ul>
            <li *ngFor="let dup of duplicatesByCVLink">
              {{ dup.firstName }} {{ dup.lastName }} (Person ID: {{ dup.kolId }} - Status: {{ dup._meta.status }}) -
              <a [routerLink]="['/person/detail', dup.id]" rel="noopener" target="_blank">Open Person</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div *ngIf="checkFindings['cvLinks']?.length > 0" class="info">
      Non-compliant links were removed automatically: {{ checkFindings['cvLinks'].join(', ') }}
    </div>

    <hr />

    <!-- LinkedInUrl -->
    <div class="form-group row" #linkedin>
      <label class="col-form-label col-sm-4 col-md-2 text-primary" for="linkedinUrl">LinkedIn URL</label>
      <div class="col-sm-8 col-md-10">
        <div *diMarker="{ name: 'linkedinUrl', area: reviewMarkerArea }">
          <div class="input-group">
            <input
              type="text"
              id="linkedinUrl"
              class="form-control"
              name="linkedinUrl"
              [ngModel]="model.social?.linkedinUrl"
              (ngModelChange)="onLinkedInUrlChange($event)"
              [disabled]="!isFieldEditable('linkedinUrl') || !model.id || model.linkedInNotFound"
              validateDomain="linkedin.com"
              validateURL
            />

            <div class="input-group-append">
              <button
                type="button"
                class="btn btn-secondary"
                (click)="gotoCvLink(model.social?.linkedinUrl)"
                *ngIf="model.social?.linkedinUrl"
              >
                <i class="fa fa-external-link dirt-link-pointer" aria-hidden="true"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2">LinkedIn not found</label>
      <div class="col-sm-8 col-md-10 pt-1">
        <ui-switch
          size="small"
          color="#6d5cae"
          [checked]="model.linkedInNotFound === true"
          [disabled]="!isFieldEditable('linkedinUrl') || !model.id || isLinkedInNotFoundDisabled()"
          (change)="onLinkedInNotFoundChange($event)"
        >
        </ui-switch>
      </div>
    </div>

    <div *ngIf="isFieldEditable('externalIds.openData')">
      <hr />

      <!-- Opendata -->
      <div class="form-group row">
        <label class="col-form-label col-sm-4 col-md-2" for="openData">Opendata ID</label>
        <div class="col-sm-8 col-md-10">
          <span *dirtTrainingEvaluationMarker="'openData'"></span>
          <input
            type="text"
            id="openData"
            class="form-control"
            name="openData"
            (keydown)="onKeydown($event, model.externalIds.openData, openDataLength)"
            pattern="^\d{18}$"
            maxlength="{{ openDataLength }}"
            [(ngModel)]="model.externalIds.openData"
            (ngModelChange)="onOpenDataModelChange(model.externalIds.openData)"
            [required]="isOpenDataMandatory()"
            [disabled]="model.openDataStatus === 'Rejected' || model.openDataNotFound === true"
            *diMarker="{ name: 'externalIds_openData', area: reviewMarkerArea, lfkaOnlyProject: isOnlyKAMProject() }"
            title="Opendata ID should be made of {{ openDataLength }} digits!"
          />
        </div>
      </div>
    </div>
    <div *ngIf="isFieldEditable('externalIds.openData')">
      <div class="form-group row">
        <label class="col-form-label col-sm-4 col-md-2">Opendata not found</label>
        <div class="col-sm-8 col-md-10 pt-1">
          <ui-switch
            [checked]="model.openDataNotFound === true"
            size="small"
            (change)="onOpenDataNotFoundCheckedChange($event)"
            [disabled]="!!model.externalIds.openData || isOpenDataRejectedChecked()"
            color="#6d5cae"
          >
          </ui-switch>
        </div>
      </div>
    </div>
    <div *ngIf="isFieldEditable('externalIds.openData') && detail && !this.initialOpenDataId">
      <div class="form-group row">
        <label class="col-form-label col-sm-4 col-md-2">Opendata Rejected</label>
        <div class="col-sm-8 col-md-10 pt-1">
          <ui-switch
            [checked]="isOpenDataRejectedChecked()"
            (change)="onOpenDataRejectedCheckedChange($event)"
            size="small"
            color="#6d5cae"
          >
          </ui-switch>
        </div>
      </div>
    </div>

    <div *ngIf="isFieldEditable('externalIds.npi')">
      <hr />
      <!-- NPI -->
      <div class="form-group row">
        <label class="col-form-label col-sm-4 col-md-2" for="openData">NPI</label>
        <div class="col-sm-8 col-md-10">
          <input
            type="text"
            id="npi"
            class="form-control"
            name="npi"
            (keydown)="onKeydown($event, model.externalIds.npi, npiLength)"
            pattern="^\d{10}$"
            maxlength="{{ npiLength }}"
            [(ngModel)]="model.externalIds.npi"
            (ngModelChange)="onNpiModelChange(model.externalIds.npi)"
            [required]="isNpiMandatory()"
            *diMarker="{ name: 'externalIds_npi', area: reviewMarkerArea, lfkaOnlyProject: isOnlyKAMProject() }"
          />
        </div>
      </div>
    </div>

    <div *ngIf="isFieldEditable('externalIds.npi')">
      <div class="form-group row">
        <label class="col-form-label col-sm-4 col-md-2">NPI not found</label>
        <div class="col-sm-8 col-md-10 pt-1">
          <ui-switch
            [checked]="model.npiNotFound === true"
            size="small"
            (change)="onNpiNotFoundCheckedChange($event)"
            [disabled]="!!model.externalIds.npi"
            color="#6d5cae"
          >
          </ui-switch>
        </div>
      </div>
    </div>

    <hr />

    <!-- Affiliations List -->
    <div class="form-group">
      <span *dirtTrainingEvaluationMarker="'affiliations'"></span>

      <!-- ALWAYS show standard affiliations. They are the one that really matters -->
      <dirt-affiliations
        [affiliations]="model.affiliations"
        [disabled]="!isFieldEditable('affiliations')"
        [lfkaOnlyProject]="isOnlyKAMProject()"
        (primaryChanged)="onAnyAffiliationChanges()"
        [mandatoryBecauseOfStatus]="isMandatoryBecauseOfStatus()"
        [currentJobType]="currentJobType"
        *ngIf="isMoreThanKAMProject() || model.affiliations?.length > 0"
        [createRequestHandler]="affiliationCreateRequestHandler"
        [maintenanceRequestHandler]="affiliationMaintenanceRequestHandler"
        [currentQcArea]="reviewMarkerArea"
        [requestOwnerProduct]="model.ownerProduct || 'LFTA'"
      >
      </dirt-affiliations>

      <br />

      <!-- Show KAM affiliations when we have LFKA tags or some affiliations are present -->
      <dirt-affiliations-lfka
        [affiliations]="model.affiliationsLfka"
        [disabled]="!isFieldEditable('affiliationsLfka')"
        [lfkaOnlyProject]="isOnlyKAMProject()"
        [mandatoryBecauseOfStatus]="isMandatoryBecauseOfStatus()"
        [currentJobType]="currentJobType"
        *ngIf="isKAMProject() || model.affiliationsLfka?.length > 0"
        [createRequestHandler]="affiliationCreateRequestHandler"
        [maintenanceRequestHandler]="affiliationMaintenanceRequestHandler"
        [currentQcArea]="reviewMarkerArea"
      >
      </dirt-affiliations-lfka>

      <dirt-affiliations-clinical
        *ngIf="hasClinicalAffiliationsPermissions()"
        [affiliations]="model.affiliationsClinical ?? []"
        [disabled]="!isFieldEditable('affiliationsClinical')"
        [mandatoryBecauseOfStatus]="isMandatoryBecauseOfStatus()"
        [currentJobType]="currentJobType"
        [createRequestHandler]="affiliationCreateRequestHandler"
        [maintenanceRequestHandler]="affiliationMaintenanceRequestHandler"
        [baseAffiliations]="model.affiliations"
        [currentQcArea]="reviewMarkerArea"
      >
      </dirt-affiliations-clinical>

      <br />

      <dirt-affiliations-education
        [affiliations]="model.affiliationsEducation"
        [disabled]="!isFieldEditable('affiliationsEducation')"
        [currentJobType]="currentJobType"
        [createRequestHandler]="affiliationCreateRequestHandler"
        [maintenanceRequestHandler]="affiliationMaintenanceRequestHandler"
        [currentQcArea]="reviewMarkerArea"
      >
      </dirt-affiliations-education>
    </div>

    <div class="form-group row potential-duplicates" *ngIf="duplicatesByAffiliationAndName.length > 0">
      <label class="col-form-label col-sm-4 col-md-2">Possible duplicate of:</label>
      <div class="col-sm-8 col-md-10">
        <ul>
          <li *ngFor="let dup of duplicatesByAffiliationAndName">
            {{ dup.firstName }} {{ dup.lastName }} (Person ID: {{ dup.kolId }} - Status: {{ dup._meta.status }}) -
            <a [routerLink]="['/person/detail', dup.id]" rel="noopener" target="_blank">Open Person</a>
          </li>
        </ul>
      </div>
    </div>

    <!-- Affiliations Link -->
    <div *diMarker="{ name: 'affiliationsLinks', area: reviewMarkerArea, lfkaOnlyProject: isOnlyKAMProject() }">
      <div
        class="form-group row"
        *ngFor="let affLink of model.affiliationsLinks; let idx = index; trackBy: trackByIndex"
      >
        <label class="col-form-label col-sm-4 col-md-2 text-primary">Affiliation Link</label>
        <div class="col-sm-8 col-md-10">
          <div class="input-group">
            <!-- FIXME: Highlight fields: [class]="highlightFields('dirt-cv-links')" -->
            <dirt-link
              [(url)]="model.affiliationsLinks[idx]"
              name="affiliationsLinks-{{ idx }}"
              [form]="ngForm"
              [disabled]="!isFieldEditable('affiliationsLinks')"
              [required]="!initialOpenDataId && isFieldMandatory('affiliationsLinks')"
            >
              <button
                type="button"
                class="btn btn-secondary"
                (click)="pushItemToList(model.affiliationsLinks)"
                *ngIf="
                  idx == model.affiliationsLinks.length - 1 &&
                  isFieldEditable('affiliationsLinks') &&
                  shouldAddMoreLink(model.affiliationsLinks)
                "
              >
                <i class="fa fa-plus-circle"></i>
              </button>

              <button
                type="button"
                class="btn btn-secondary"
                (click)="removeFromByIndex(model.affiliationsLinks, idx)"
                *ngIf="idx > 0 && isFieldEditable('affiliationsLinks')"
              >
                <i class="fa fa-minus-circle"></i>
              </button>
            </dirt-link>

            <div *ngIf="idx == model.affiliationsLinks.length - 1">
              <span *dirtTrainingEvaluationMarker="'affiliationsLinks'"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="checkFindings['affiliationsLinks']?.length > 0" class="info">
      Non-compliant links were removed automatically: {{ checkFindings['affiliationsLinks'].join(', ') }}
    </div>

    <!-- Affiliations Clinical Link -->
    <ng-container *ngIf="hasClinicalAffiliationsPermissions()">
      <div *diMarker="{ name: 'affiliationsClinicalLinks', area: reviewMarkerArea }">
        <div
          class="form-group row"
          *ngFor="let affLink of model.affiliationsClinicalLinks; let idx = index; trackBy: trackByIndex"
        >
          <label class="col-form-label col-sm-4 col-md-2 text-primary">Clinical Affiliation Link</label>
          <div class="col-sm-8 col-md-10">
            <div class="input-group">
              <dirt-link
                [(url)]="model.affiliationsClinicalLinks[idx]"
                name="affiliationsClinicalLinks-{{ idx }}"
                [form]="ngForm"
                [disabled]="!isFieldEditable('affiliationsClinicalLinks')"
                [required]="isFieldMandatory('affiliationsClinicalLinks')"
              >
                <button
                  type="button"
                  class="btn btn-secondary"
                  (click)="pushItemToList(model.affiliationsClinicalLinks)"
                  *ngIf="
                    idx == model.affiliationsClinicalLinks.length - 1 &&
                    isFieldEditable('affiliationsClinicalLinks') &&
                    shouldAddMoreLink(model.affiliationsClinicalLinks)
                  "
                >
                  <i class="fa fa-plus-circle"></i>
                </button>

                <button
                  type="button"
                  class="btn btn-secondary"
                  (click)="removeFromByIndex(model.affiliationsClinicalLinks, idx)"
                  *ngIf="idx > 0 && isFieldEditable('affiliationsClinicalLinks')"
                >
                  <i class="fa fa-minus-circle"></i>
                </button>
              </dirt-link>

              <div *ngIf="idx == model.affiliationsClinicalLinks.length - 1">
                <span *dirtTrainingEvaluationMarker="'affiliationsClinicalLinks'"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <div *ngIf="checkFindings['affiliationsClinicalLinks']?.length > 0" class="info">
      Non-compliant links were removed automatically: {{ checkFindings['affiliationsClinicalLinks'].join(', ') }}
    </div>

    <!-- Position Link -->

    <hr />

    <div>
      <div
        class="form-group row"
        *ngFor="let positionLink of model.positionLinks; let idx = index; trackBy: trackByIndex"
      >
        <label class="col-form-label col-sm-4 col-md-2 text-primary">Position Link</label>
        <div class="col-sm-8 col-md-10">
          <div class="input-group" *diMarker="{ name: 'positionLinks', area: reviewMarkerArea }">
            <!-- FIXME: Highlight fields: [class]="highlightFields('dirt-position-links')" -->
            <dirt-link
              [(url)]="model.positionLinks[idx]"
              name="positionLinks-{{ idx }}"
              [form]="ngForm"
              [disabled]="!isFieldEditable('positionLinks')"
            >
              <button
                type="button"
                class="btn btn-secondary"
                (click)="pushItemToList(model.positionLinks)"
                *ngIf="
                  idx == model.positionLinks.length - 1 &&
                  isFieldEditable('positionLinks') &&
                  shouldAddMoreLink(model.positionLinks)
                "
              >
                <i class="fa fa-plus-circle"></i>
              </button>

              <button
                type="button"
                class="btn btn-secondary"
                (click)="removeFromByIndex(model.positionLinks, idx)"
                *ngIf="idx > 0 && isFieldEditable('positionLinks')"
              >
                <i class="fa fa-minus-circle"></i>
              </button>
            </dirt-link>

            <div *ngIf="idx == model.positionLinks.length - 1">
              <span *dirtTrainingEvaluationMarker="'positionLinks'"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="checkFindings['positionLinks']?.length > 0" class="info">
      Non-compliant links were removed automatically: {{ checkFindings['positionLinks'].join(', ') }}
    </div>

    <hr />

    <!-- Degrees -->
    <div [hidden]="!model" class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2">Degrees</label>
      <div class="col-sm-8 col-md-10">
        <span *dirtTrainingEvaluationMarker="'degrees'"></span>
        <dirt-multiselect-dropdown
          name="degrees"
          class="di-degrees"
          [ngClass]="{ disabled: !isFieldEditable('degrees') }"
          [options]="degrees"
          [settings]="degreesSettings"
          [(ngModel)]="model.degrees"
          *diMarker="{ name: 'degrees', area: reviewMarkerArea, lfkaOnlyProject: isOnlyKAMProject() }"
          (ngModelChange)="onDegreeChange(model.degrees)"
          [searchFunction]="degreeSearchFunc"
        >
        </dirt-multiselect-dropdown>
      </div>
    </div>

    <!-- Degree Link -->
    <div *diMarker="{ name: 'degreeLinks', area: reviewMarkerArea, lfkaOnlyProject: isOnlyKAMProject() }">
      <div class="form-group row" *ngFor="let degreeLink of model.degreeLinks; let idx = index; trackBy: trackByIndex">
        <label class="col-form-label col-sm-4 col-md-2 text-primary">Degree Link</label>
        <div class="col-sm-8 col-md-10">
          <div class="input-group">
            <dirt-link
              [(url)]="model.degreeLinks[idx]"
              name="degreeLinks-{{ idx }}"
              [form]="ngForm"
              [disabled]="!isFieldEditable('degreeLinks')"
              [required]="!initialOpenDataId && isFieldMandatory('degreeLinks')"
            >
              <button
                type="button"
                class="btn btn-secondary"
                (click)="pushItemToList(model.degreeLinks)"
                *ngIf="
                  idx == model.degreeLinks.length - 1 &&
                  isFieldEditable('degreeLinks') &&
                  shouldAddMoreLink(model.degreeLinks)
                "
              >
                <i class="fa fa-plus-circle"></i>
              </button>

              <button
                type="button"
                class="btn btn-secondary"
                (click)="removeFromByIndex(model.degreeLinks, idx)"
                *ngIf="idx > 0 && isFieldEditable('degreeLinks')"
              >
                <i class="fa fa-minus-circle"></i>
              </button>
            </dirt-link>

            <div *ngIf="idx == model.degreeLinks.length - 1">
              <span *dirtTrainingEvaluationMarker="'degreeLinks'"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="checkFindings['degreeLinks']?.length > 0" class="info">
      Non-compliant links were removed automatically: {{ checkFindings['degreeLinks'].join(', ') }}
    </div>

    <!-- Specialties -->
    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2">Specialties</label>
      <div class="col-sm-8 col-md-10">
        <span *dirtTrainingEvaluationMarker="'specialties'"></span>
        <dirt-multiselect-dropdown
          name="specialties"
          [ngClass]="{ disabled: !isFieldEditable('specialties') }"
          [options]="specialties"
          [settings]="specialtiesSettings"
          [(ngModel)]="model.specialties"
          [required]="isSpecialtiesMandatory()"
          (removed)="onSpecialtyRemoved($event)"
          *diMarker="{ name: 'specialties', area: reviewMarkerArea, lfkaOnlyProject: isOnlyKAMProject() }"
        >
        </dirt-multiselect-dropdown>

        <small class="info" *ngIf="model.specialties?.length >= SPECIALTIES_LIMIT">
          Cannot add more than {{ SPECIALTIES_LIMIT }} specialties to expert.
        </small>
      </div>
    </div>

    <!-- Original Specialties -->
    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2">Original Specialties</label>
      <div class="col-sm-8 col-md-10">
        <ng-select
          name="originalSpecialties"
          [items]="originalSpecialties"
          [virtualScroll]="true"
          bindValue="id"
          placeholder="Select Original Specialty"
          [(ngModel)]="model.originalSpecialties"
          [multiple]="true"
          [maxSelectedItems]="SPECIALTIES_LIMIT"
          [hideSelected]="true"
          [disabled]="!isFieldEditable('specialties')"
          (add)="onOriginalSpecialtyAdded($event)"
          (remove)="onOriginalSpecialtyRemoved($event)"
        >
        </ng-select>

        <small class="info" *ngIf="model.originalSpecialties?.length >= SPECIALTIES_LIMIT">
          Cannot add more than {{ SPECIALTIES_LIMIT }} specialties to expert.
        </small>
      </div>
    </div>

    <!-- Specialty Link -->
    <div *diMarker="{ name: 'specialtyLinks', area: reviewMarkerArea, lfkaOnlyProject: isOnlyKAMProject() }">
      <div
        class="form-group row"
        *ngFor="let specialtyLink of model.specialtyLinks; let idx = index; trackBy: trackByIndex"
      >
        <label class="col-form-label col-sm-4 col-md-2 text-primary">Specialty Link</label>
        <div class="col-sm-8 col-md-10">
          <div class="input-group">
            <dirt-link
              [(url)]="model.specialtyLinks[idx]"
              name="specialtyLinks-{{ idx }}"
              [form]="ngForm"
              [disabled]="!isFieldEditable('specialtyLinks')"
              [required]="!initialOpenDataId && isFieldMandatory('specialtyLinks')"
            >
              <button
                type="button"
                class="btn btn-secondary"
                (click)="pushItemToList(model.specialtyLinks)"
                *ngIf="
                  idx == model.specialtyLinks.length - 1 &&
                  isFieldEditable('specialtyLinks') &&
                  shouldAddMoreLink(model.specialtyLinks)
                "
              >
                <i class="fa fa-plus-circle"></i>
              </button>

              <button
                type="button"
                class="btn btn-secondary"
                (click)="removeFromByIndex(model.specialtyLinks, idx)"
                *ngIf="idx > 0 && isFieldEditable('specialtyLinks')"
              >
                <i class="fa fa-minus-circle"></i>
              </button>
            </dirt-link>

            <div *ngIf="idx == model.specialtyLinks.length - 1">
              <span *dirtTrainingEvaluationMarker="'specialtyLinks'"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="checkFindings['specialtyLinks']?.length > 0" class="info">
      Non-compliant links were removed automatically: {{ checkFindings['specialtyLinks'].join(', ') }}
    </div>

    <hr />

    <!-- Profession -->
    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2" for="profession">Expert Type</label>
      <div class="col-sm-8 col-md-10">
        <span *dirtTrainingEvaluationMarker="'profession'"></span>
        <select
          class="form-control custom-select"
          id="profession"
          name="profession"
          [(ngModel)]="model.profession"
          [validateList]="professionsCodes"
          [disabled]="!isFieldEditable('profession')"
          *diMarker="{ name: 'profession', area: reviewMarkerArea, lfkaOnlyProject: isOnlyKAMProject() }"
        >
          <option [ngValue]="undefined">&mdash; Select &mdash;</option>
          <option *ngFor="let pro of professions" [value]="pro.code">{{ pro.title }}</option>
        </select>
      </div>
    </div>

    <hr />

    <!-- Sources -->
    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2" for="source">Source</label>
      <div class="col-sm-8 col-md-10">
        <select
          class="form-control custom-select"
          id="source"
          name="source"
          [(ngModel)]="model.source"
          [disabled]="model.id || !isFieldEditable('source')"
          required
        >
          <option *ngFor="let source of sources" [value]="source.value" [disabled]="source.disabled">
            {{ source.value | capitalize }}
          </option>
        </select>
      </div>
    </div>

    <!-- States -->
    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2" for="state">Status</label>
      <div class="col-sm-8 col-md-10">
        <span *dirtTrainingEvaluationMarker="'status'"></span>
        <select
          class="form-control custom-select"
          [class]="highlightFields()"
          id="state"
          name="state"
          [(ngModel)]="model.status"
          [disabled]="!isFieldEditable('status') || isStateDisabled(model.status)"
          *diMarker="{ name: 'status', area: reviewMarkerArea, lfkaOnlyProject: isOnlyKAMProject() }"
        >
          <option [ngValue]="undefined">&mdash; Status &mdash;</option>
          <option *ngFor="let state of states" [value]="state.value" [disabled]="isStateDisabled(state.value)">
            {{ state.value | capitalize }}
          </option>
        </select>
      </div>
    </div>

    <!-- opt out status -->
    <div class="form-group row" *ngIf="!isNewRecord()">
      <label class="col-form-label col-sm-4 col-md-2">Optout Status</label>
      <div class="col-sm-8 col-md-10">
        <ui-switch
          *diMarker="{ name: 'optoutStates', area: reviewMarkerArea, lfkaOnlyProject: isOnlyKAMProject() }"
          [checked]="model.optoutStatus && model.optoutStatus.includes('OPTOUT_FOR_TA')"
          (change)="onOptoutToggle($event)"
          [disabled]="!isFieldEditable('optoutStatus')"
          size="small"
        >
        </ui-switch>
      </div>
    </div>

    <hr />
    <!-- Status Link -->
    <div>
      <div class="form-group row" *ngFor="let statusLink of model.statusLinks; let idx = index; trackBy: trackByIndex">
        <label class="col-form-label col-sm-4 col-md-2 text-primary">Status Link</label>
        <div class="col-sm-8 col-md-10">
          <div class="input-group">
            <!-- FIXME: Highlight fields: [class]="highlightFields('dirt-status-links')" -->
            <dirt-link
              [(url)]="model.statusLinks[idx]"
              name="statusLinks-{{ idx }}"
              [form]="ngForm"
              [disabled]="!isFieldEditable('statusLinks')"
            >
              <button
                type="button"
                class="btn btn-secondary"
                (click)="pushItemToList(model.statusLinks)"
                *ngIf="
                  idx == model.statusLinks.length - 1 && isFieldEditable('statusLinks') && model.statusLinks.length < 3
                "
              >
                <i class="fa fa-plus-circle"></i>
              </button>

              <button
                type="button"
                class="btn btn-secondary"
                (click)="removeFromByIndex(model.statusLinks, idx)"
                *ngIf="idx > 0 && isFieldEditable('statusLinks')"
              >
                <i class="fa fa-minus-circle"></i>
              </button>
            </dirt-link>

            <div *ngIf="idx == model.statusLinks.length - 1">
              <span *dirtTrainingEvaluationMarker="'statusLinks'"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="checkFindings['statusLinks']?.length > 0" class="info">
      Non-compliant links were removed automatically: {{ checkFindings['statusLinks'].join(', ') }}
    </div>

    <hr />

    <!-- Segments -->
    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2">Segments</label>
      <div class="col-sm-8 col-md-10">
        <span *dirtTrainingEvaluationMarker="'segments'"></span>
        <dirt-multiselect-dropdown
          name="segments"
          [ngClass]="{ disabled: !isFieldEditable('segments') }"
          [options]="segments"
          [settings]="segmentsSettings"
          [(ngModel)]="model.segments"
          *diMarker="{ name: 'segments', area: reviewMarkerArea, lfkaOnlyProject: isOnlyKAMProject() }"
        >
        </dirt-multiselect-dropdown>
      </div>
    </div>

    <hr />

    <!-- Emails -->
    <div *diMarker="{ name: 'emails', area: reviewMarkerArea, lfkaOnlyProject: isOnlyKAMProject() }">
      <div class="form-group row">
        <label class="col-form-label col-sm-4 col-md-2" for="email">Email</label>
        <div class="col-sm-8 col-md-10">
          <span *dirtTrainingEvaluationMarker="'emails'"></span>
          <div class="input-group">
            <input
              type="email"
              id="email"
              [class]="highlightFields()"
              name="email"
              [ngModel]="model.emails[0] | decapitalize"
              (ngModelChange)="model.emails[0] = $event"
              (blur)="onEmailsBlur()"
              [disabled]="!isFieldEditable('emails')"
              validateEmail
            />
          </div>
        </div>
      </div>

      <div class="form-group row potential-duplicates" *ngIf="duplicatesByEmail.length > 0">
        <label class="col-form-label col-sm-4 col-md-2">Possible duplicate of:</label>
        <div class="col-sm-8 col-md-10">
          <ul>
            <li *ngFor="let dup of duplicatesByEmail">
              {{ dup.firstName }} {{ dup.lastName }} (Person ID: {{ dup.kolId }} - Status: {{ dup._meta.status }}) -
              <a [routerLink]="['/person/detail', dup.id]" rel="noopener" target="_blank">Open Person</a>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!-- Email Link -->
    <div *diMarker="{ name: 'emailLinks', area: reviewMarkerArea, lfkaOnlyProject: isOnlyKAMProject() }">
      <div class="form-group row" *ngFor="let emailLink of model.emailLinks; let idx = index; trackBy: trackByIndex">
        <label class="col-form-label col-sm-4 col-md-2 text-primary">Email Link</label>
        <div class="col-sm-8 col-md-10">
          <!-- FIXME: Highlight fields: [class]="highlightFields('dirt-cv-links')" -->
          <dirt-link
            [(url)]="model.emailLinks[idx]"
            name="emailLinks-{{ idx }}"
            [form]="ngForm"
            [disabled]="!isFieldEditable('emailLinks')"
          >
            <button
              type="button"
              class="btn btn-secondary"
              (click)="pushItemToList(model.emailLinks)"
              *ngIf="idx == model.emailLinks.length - 1 && isFieldEditable('emailLinks') && model.emailLinks.length < 3"
            >
              <i class="fa fa-plus-circle"></i>
            </button>

            <button
              type="button"
              class="btn btn-secondary"
              (click)="removeFromByIndex(model.emailLinks, idx)"
              *ngIf="idx > 0 && isFieldEditable('emailLinks')"
            >
              <i class="fa fa-minus-circle"></i>
            </button>
          </dirt-link>
        </div>
      </div>
    </div>
    <div *ngIf="checkFindings['emailLinks']?.length > 0" class="info">
      Non-compliant links were removed automatically: {{ checkFindings['emailLinks'].join(', ') }}
    </div>

    <hr />

    <!-- Phone -->
    <dirt-input-phone
      [model]="model.phone"
      [extensionInput]="true"
      [disabled]="!isFieldEditable('phone')"
      [form]="ngForm"
      *diMarker="{ name: 'phoneNumber', area: reviewMarkerArea }"
    >
    </dirt-input-phone>

    <!-- Phone Type -->
    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2">Phone Type</label>
      <div class="col-sm-8 col-md-10">
        <select
          class="form-control custom-select"
          name="phone-type"
          [(ngModel)]="model.phone.type"
          [disabled]="!isFieldEditable('phone')"
          [required]="isFieldEditable('phone') && !!model.phone.number"
          *diMarker="{ name: 'phoneType', area: reviewMarkerArea }"
        >
          <option [ngValue]="undefined">&mdash; Type &mdash;</option>
          <option *ngFor="let type of phoneTypes" [value]="type.code">
            {{ type.title }}
          </option>
        </select>
      </div>
    </div>

    <!-- Phone Link -->
    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2 text-primary">Phone Link</label>
      <div class="col-sm-8 col-md-10">
        <dirt-link
          *diMarker="{ name: 'phoneLink', area: reviewMarkerArea }"
          [(url)]="model.phoneLink"
          name="phoneLink"
          [form]="ngForm"
          [disabled]="!isFieldEditable('phoneLink')"
        ></dirt-link>
      </div>
    </div>

    <hr />

    <dirt-image-form
      #imageForm
      [(model)]="personLogoValue"
      [reviewArea]="reviewMarkerArea"
      [disableImageControls]="!isFieldEditable('image')"
      [disableTwitterHandleControls]="!isFieldEditable('social.twitter')"
      [imageValidationConfig]="imageValidationConfig"
      [jobType]="currentJobType"
      (validityChange)="onFormImageValidityChange($event)"
    >
    </dirt-image-form>

    <hr />

    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2" for="instagramHandle">Instagram handle</label>

      <div class="col-sm-8 col-md-10 input-group">
        <input
          type="text"
          id="instagramHandle"
          class="form-control"
          name="instagramHandle"
          [(ngModel)]="obtainSocial('instagram').username"
          [disabled]="!!obtainSocial('instagram').notFound || !isFieldEditable('social.instagram', true)"
          pattern="^[A-Za-z0-9_\.]{1,30}$"
        />
      </div>
    </div>
    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2">Instagram not found</label>
      <div class="col-sm-8 col-md-10 pt-1">
        <ui-switch
          size="small"
          color="#6d5cae"
          name="isInstagramHandleNotFound"
          [(ngModel)]="!!obtainSocial('instagram').notFound"
          (ngModelChange)="onSocialNotFoundChange('instagram', $event)"
          [disabled]="!isFieldEditable('social.instagram', true)"
        >
        </ui-switch>
      </div>
    </div>

    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2" for="tiktokHandle">TikTok handle</label>

      <div class="col-sm-8 col-md-10 input-group">
        <input
          type="text"
          id="tiktokHandle"
          class="form-control"
          name="tiktokHandle"
          [(ngModel)]="obtainSocial('tiktok').username"
          [disabled]="!!obtainSocial('tiktok').notFound || !isFieldEditable('social.tiktok', true)"
          pattern="^[A-Za-z0-9_\.]{1,24}$"
        />
      </div>
    </div>
    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2">TikTok not found</label>
      <div class="col-sm-8 col-md-10 pt-1">
        <ui-switch
          size="small"
          color="#6d5cae"
          name="isTiktokHandleNotFound"
          [(ngModel)]="!!obtainSocial('tiktok').notFound"
          (ngModelChange)="onSocialNotFoundChange('tiktok', $event)"
          [disabled]="!isFieldEditable('social.tiktok', true)"
        >
        </ui-switch>
      </div>
    </div>

    <hr />

    <!-- Talking Points -->
    <div *diMarker="{ name: 'talkingpoints', area: reviewMarkerArea, lfkaOnlyProject: isOnlyKAMProject() }">
      <div
        class="form-group row"
        *ngFor="let talkingPoint of model.talkingPoints; let idx = index; trackBy: trackByIndex"
      >
        <label class="col-form-label col-sm-4 col-md-2" for="talkingpoint-{{ idx }}">Talking Points</label>
        <div class="col-sm-8 col-md-10">
          <div class="input-group">
            <input
              type="text"
              id="talkingpoint-{{ idx }}"
              class="form-control"
              name="talkingpoint-{{ idx }}"
              [(ngModel)]="model.talkingPoints[idx]"
              [disabled]="!isFieldEditable('talkingPoints')"
            />
            <div class="input-group-append">
              <!-- Button: Add -->
              <button
                type="button"
                class="btn btn-secondary"
                (click)="pushItemToList(model.talkingPoints)"
                *ngIf="idx == model.talkingPoints.length - 1 && isFieldEditable('talkingPoints')"
              >
                <i class="fa fa-plus-circle"></i>
              </button>

              <!-- Button: Remove -->
              <button
                type="button"
                class="btn btn-secondary"
                (click)="removeFromByIndex(model.talkingPoints, idx) && isFieldEditable('talkingPoints')"
                *ngIf="idx > 0 && isFieldEditable('talkingPoints')"
              >
                <i class="fa fa-minus-circle"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <hr />

    <!-- Opt-out -->
    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2">Opt-out Inquiry</label>
      <div class="col-sm-8 col-md-10">
        <textarea
          class="form-control"
          name="optOutInquiry"
          [(ngModel)]="model.optOutInquiry"
          [disabled]="!isFieldEditable('optOutInquiry')"
        >
        </textarea>
      </div>
    </div>

    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2" for="ownerProduct">Owner product</label>

      <div class="col-sm-8 col-md-10 input-group">
        <select
          id="ownerProduct"
          class="form-control custom-select"
          name="ownerProduct"
          [(ngModel)]="model.ownerProduct"
          [required]="!model.id"
          [disabled]="model.id && !isFieldEditable('ownerProduct')"
        >
          <option *ngFor="let product of products" [value]="product.value">
            {{ product.value }}
          </option>
        </select>
      </div>
    </div>

    <div class="form-group row" *ngIf="isNewRecord()">
      <label class="col-form-label col-sm-4 col-md-2">Delivery Projects</label>
      <div class="col-md-2">
        <span *dirtTrainingEvaluationMarker="'status'"></span>
        <dirt-multiselect-dropdown
          name="projects"
          [ngClass]="{ disabled: !isFieldEditable('projectNames') }"
          [options]="projectsDelivery"
          [settings]="projectsSettings"
          [required]="true"
          [ngModel]="projectNamesDelivery"
          (ngModelChange)="onProjectTagChangeForDelivery($event)"
        >
        </dirt-multiselect-dropdown>
      </div>
    </div>

    <div class="form-group row" *ngIf="isNewRecord()">
      <label class="col-form-label col-sm-4 col-md-2">Technical Projects</label>
      <div class="col-md-2">
        <span *dirtTrainingEvaluationMarker="'status'"></span>
        <dirt-multiselect-dropdown
          name="projects"
          [ngClass]="{ disabled: !isFieldEditable('projectNames') }"
          [options]="projectsTechnical"
          [settings]="projectsSettings"
          [required]="true"
          [ngModel]="projectNamesTechnical"
          (ngModelChange)="onProjectTagChangeForTechnical($event)"
        >
        </dirt-multiselect-dropdown>
      </div>
    </div>
  </div>
</form>
