import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { PortalModule } from '@angular/cdk/portal';

import { SharedModule } from '../shared/shared.module';
import { ClinicalTrialModule } from '../clinical-trial/clinical-trial.module';
import { PersonModule } from '../person/person.module';
import { ProfileModule } from '../profile/profile.module';
import { AffiliationModule } from '../affiliation/affiliation.module';
import { ClinicalTrialProfileComponent } from './clinical-trial-profile.component';
import { ClinicalTrialProfileDetailComponent } from './detail/detail.component';
import { ClinicalTrialProfileAPI } from './shared/clinical-trial-profile-api.service';
import { ctProfileRouting } from './clinical-trial-profile.route';
import { ValueNumericInput } from './shared/components/value-numeric-input/value-numeric-input';
import { SiteSingleSelectComponent } from './shared/components/site-single-select/site-single-select';
import { ValueDateInputComponent } from './shared/components/value-date-input/value-date-input';
import { ValueModule } from '../value/value.module';
import { ClinicalTrialProfileExtListComponent } from './list/list.component';
import { CommentModule } from '../comment/comment.module';
import { TextboxFilterComponent } from './shared/filters/textbox-filter/textbox-filter.component';
import { InvestigatorSingleSelectComponent } from './shared/components/investigator-single-select/investigator-single-select.component';
import { ClinicalTrialProfileInvestigatorsSitesListComponent } from './shared/components/investigators-sites-list/investigators-sites-list.component';
import { OrganizationModule } from '../organizations/organization.module';

@NgModule({
  imports: [
    SharedModule,
    ClinicalTrialModule,
    PersonModule,
    ProfileModule,
    AffiliationModule,
    ValueModule,
    CommentModule,
    ctProfileRouting,
    FormsModule,
    PortalModule,
    OrganizationModule,
  ],
  declarations: [
    ClinicalTrialProfileComponent,
    ClinicalTrialProfileDetailComponent,
    ClinicalTrialProfileExtListComponent,
    SiteSingleSelectComponent,
    ValueDateInputComponent,
    ValueNumericInput,
    TextboxFilterComponent,
    InvestigatorSingleSelectComponent,
    ClinicalTrialProfileInvestigatorsSitesListComponent,
  ],
  providers: [ClinicalTrialProfileAPI],
  exports: [SiteSingleSelectComponent],
})
export class ClinicalTrialProfileModule {}
