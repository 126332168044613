import { Affiliation } from '../../affiliation/shared/affiliation';

export enum AccountAffiliationStatus {
  ADDED = 'ADDED',
  SHORTLISTED = 'SHORTLISTED',
  REQUESTED = 'REQUESTED',
  MATCHED = 'MATCHED',
}

export class AccountAffiliation {
  id: string;
  accountId: string;
  affiliation: Affiliation;
  status?: AccountAffiliationStatus;
  isHQ?: boolean;
  isFlagship?: boolean;
  isSpecialityCenter?: boolean;
  isExcludedFromDelivery?: boolean;
}
