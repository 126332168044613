import { Component, EventEmitter, Output } from '@angular/core';

import { JobsAPI } from '../shared/api.service';
import { Job } from '../shared/job';
import { BulkDelegate } from '../../common/bulk/bulk.delegate';
import { BulkType } from '../../common/bulk/shared/bulk-types';
import { Observable } from 'rxjs';
import { BULK_MODAL_OPTIONS, BulkModalComponent } from '../../common/bulk/component/bulk-modal/bulk-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'dirt-jobs-next-create',
  templateUrl: 'next-create.component.html',
  styleUrls: ['next-create.component.scss'],
})
export class JobsNextCreateComponent {
  // TODO: deprecate in favor of generic jobs upload

  @Output()
  notifyReload: EventEmitter<void> = new EventEmitter();

  personJobBulkListDelegate: BulkDelegate = {
    title: 'Bulk Import Person Job',
    type: BulkType.LIST,
    specificStartFct: this.handlePersonBulkListJobUpload.bind(this),
    specificNotifyReloadFct: () => this.notifyReload.emit(),
  };

  clinicalTrialProfileJobBulkListDelegate: BulkDelegate = {
    title: 'Bulk Import CT Profile Job',
    type: BulkType.LIST,
    specificStartFct: this.handleClinicalTrialProfileBulkListJobUpload.bind(this),
    specificNotifyReloadFct: () => this.notifyReload.emit(),
  };

  newOrganizationJobBulkListDelegate: BulkDelegate = {
    title: 'Bulk Import HCO Job',
    type: BulkType.LIST,
    specificStartFct: this.handleNewOrganizationBulkListJobUpload.bind(this),
    specificNotifyReloadFct: () => this.notifyReload.emit(),
  };

  constructor(private svcJob: JobsAPI, private svcModal: NgbModal) {}

  onOpenPersonBulkListJobDialog(): void {
    const modal = this.svcModal.open(BulkModalComponent, BULK_MODAL_OPTIONS);
    modal.componentInstance.delegate = this.personJobBulkListDelegate;
  }

  onOpenClinicalTrialProfileBulkListJobDialog(): void {
    const modal = this.svcModal.open(BulkModalComponent, BULK_MODAL_OPTIONS);
    modal.componentInstance.delegate = this.clinicalTrialProfileJobBulkListDelegate;
  }

  onOpenNewOrganizationBulkListJobDialog(): void {
    const modal = this.svcModal.open(BulkModalComponent, BULK_MODAL_OPTIONS);
    modal.componentInstance.delegate = this.newOrganizationJobBulkListDelegate;
  }

  private handlePersonBulkListJobUpload(file: File, secondEyes: string, opts: any): Observable<string> {
    return this.svcJob.startBulkListPersonJobUpload(file, secondEyes);
  }

  private handleNewOrganizationBulkListJobUpload(file: File, secondEyes: string, opts: any): Observable<string> {
    return this.svcJob.startBulkListOrganizationJobUpload(file, secondEyes);
  }

  private handleClinicalTrialProfileBulkListJobUpload(file: File, secondEyes: string, opts: any): Observable<string> {
    return this.svcJob.startBulkListClinicalTrialProfileJobUpload(file, secondEyes);
  }
}
