export class Event {
  public id: string;
  public address: any = {};
  public category: number;
  public createdAt: string;
  public name: string;
  public nameTranslated: boolean;
  public originalName: string;
  public parent: any;
  public priority: number;
  public published: boolean;
  public reach: string;
  public readyForDelivery: boolean;
  public reviewedAt: Date;
  public draftReviewedAt: Date;
  public type: string;
  public requiresCompilation: boolean;
  public series: { id: string; name: string }[] = [];
  public sponsors: string[] = [];
  public startDate: any;
  public endDate: any;
  public updatedAt: string;
  public verified: boolean;
  public verificationSkipped?: boolean;
  public verifiedBy?: string;
  public verificationSkippedBy?: string;
  public claimverificationSkippedAt?: Date;
  public webSource: string;
  public additionalWebSources?: string[];
  public hasAgenda?: boolean;
  public projectNames: string[];
  public hashtags: string[];
  qc: any;
  public _meta: {
    assignee?: string;
    lastAssignee?: string;
    maintenance?: boolean;
    status: string;
    claimedUntil?: Date;
    previousStatus?: string;
    workflow?: string;
    jobId?: string;
  };
  public _version: number;
  public reviewed?: boolean;
  public isVirtual?: boolean;
  public lastHashtagCheck?: {
    at: Date;
    by: string;
  };
  public organizers?: {
    type: 'AFFILIATION' | 'ASSOCIATION' | 'ACCOUNT' | 'COMMITTEE' | 'NO_ORGANIZER';
    affiliationId?: string;
    affiliation?: any; // for convenience
    organizationId?: string; // to link an affiliation back to an HCO
    associationId?: string;
    association?: any; // for convenience
    accountId?: string;
    account?: any;
    committeeId?: string;
    committee?: any;
    suggestOnly?: boolean;
  }[];
  public originalLanguage?: string;
  public sessionDate?: Date;
  public sessionTime?: {
    hour?: number;
    minute?: number;
  } = {};
  ownerProduct?: string; // only parents

  logo?: string;

  constructor() {}
}

export const REQUIRES_COMPILATION_TYPES = ['CONGRESS', 'WORKSHOP'];

export type CME_STATUS = 'NO' | 'YES_NOT_RELEASED' | 'YES_RELEASED';
