<section class="dirt-section-detail dirt-person-detail" *ngIf="person">
  <div class="row">
    <div class="col-md-8">
      <div class="dirt-trainer-comment" *ngIf="showBDTrainingReport()">
        <div class="dirt-label text-left">Trainer Comment:</div>
        <span>{{ person?.trainingEvaluationReport?.comment }}</span>
      </div>
      <span class="float-right" *ngIf="person.id && !person.basePersonId && !person.baseProfileId">
        <dirt-bd-copy *diAcl="'profile.training'" [personId]="person.id"> </dirt-bd-copy>
      </span>
      <br />
      <button type="button" class="dirt-btn-back btn btn-primary-outline btn-sm" (click)="goBack()">
        <span class="fa fa-arrow-left" aria-hidden="true"></span>
      </button>

      <h1 class="bounceInRight">Person Detail</h1>

      <dirt-person-form
        [person]="person"
        #frmPerson="frmPerson"
        [detail]="true"
        [currentJobType]="getJobTypeForCurrentUser()"
        [basePerson]="basePerson"
        [readonly]="disableForm"
        [checkFindings]="checkFindings"
        [affiliationCreateRequestHandler]="onRequestAffiliationCreation"
        [affiliationMaintenanceRequestHandler]="onRequestAffiliationMaintenance"
      >
      </dirt-person-form>

      <!-- Buttons -->
      <div class="dirt-btn-ct" *ngIf="!isObjectionCommentShown">
        <!-- Left -->
        <div class="pull-left" *ngIf="hasJobForCurrentUser()">
          <!-- Unable to Process for job (Need to be displayed even when form is disabled so that job curators are not stuck!) -->
          <button type="button" class="btn btn-outline-danger" (click)="onJobUtc()">Unable to Compile!</button>
        </div>

        <div class="pull-left" *ngIf="!hasJobForCurrentUser() && !disableForm">
          <!-- Merge -->
          <ng-template [diAcl]="'person.merge'">
            <button type="button" class="btn btn-outline-warning" (click)="onMerge()">Merge</button>
          </ng-template>

          <!-- Unable to Process -->
          <button
            type="button"
            class="btn btn-outline-danger"
            (click)="onObjection('UNABLE_TO_COMPILE')"
            *diAcl="'person.update.unable'"
          >
            Unable to Compile!
          </button>

          <!-- Skip verification -->
          <button
            type="button"
            class="btn btn-outline-danger"
            (click)="skipVerification()"
            *diAcl="'person.update.skip || person.update.skipid || person.update.skipidbd'"
          >
            Skip profile
          </button>
        </div>

        <!-- Right -->
        <div class="pull-right" *ngIf="!disableForm">
          <span *ngIf="isUpdating">Updating... </span>
          <!-- Save as Draft -->
          <button
            type="button"
            class="btn btn-secondary"
            (click)="onSaveDraft(frmPerson.getValue())"
            [disabled]="
              isSubmitting ||
              isUpdating ||
              isSavingStatus ||
              'DECEASED' === person.status ||
              'ID_OUT' === person._meta?.status ||
              'DUPLICATE' === person._meta?.status ||
              'ON_HOLD' === person._meta?.status
            "
            *ngIf="canSaveDraft() && !hasJobForCurrentUser()"
          >
            Save as draft
          </button>

          <!-- Save -->
          <button
            type="submit"
            class="btn btn-primary"
            [ngClass]="{ 'btn-loading': isSubmitting }"
            [disabled]="!(frmPerson.isValid() || canSaveAnyway()) || isSubmitting || isUpdating || isSavingStatus"
            [title]="frmPerson.invalidFields ? frmPerson.invalidFields().join(', ') : ''"
            (click)="onSave(frmPerson.getValue())"
            *ngIf="canSave() && !hasJobForCurrentUser()"
          >
            <span>{{
              frmPerson.hasDuplicates() || (!frmPerson.isValid() && canSaveAnyway()) ? 'Save Anyway' : 'Save'
            }}</span>
          </button>

          <!-- Submit -->
          <button
            type="submit"
            class="btn btn-primary"
            [ngClass]="{ 'btn-loading': isSubmitting }"
            [disabled]="!frmPerson.isValid() || isSubmitting || isUpdating || isSavingStatus"
            [title]="frmPerson.invalidFields ? frmPerson.invalidFields().join(', ') : ''"
            (click)="onSubmit(frmPerson.getValue())"
            *ngIf="canSubmitForReal() && !hasJobForCurrentUser()"
          >
            <span>{{ frmPerson.hasDuplicates() ? 'Submit Anyway' : 'Submit' }}</span>
          </button>

          <!-- Submit identification -->
          <button
            type="submit"
            class="btn btn-primary"
            [ngClass]="{ 'btn-loading': isSubmitting }"
            [disabled]="!frmPerson.isValid() || isSubmitting || isUpdating || isSavingStatus"
            [title]="frmPerson.invalidFields ? frmPerson.invalidFields().join(', ') : ''"
            (click)="onSubmitIdent(frmPerson.getValue())"
            *ngIf="canSubmitIdent() && !hasJobForCurrentUser()"
          >
            <span>{{ frmPerson.hasDuplicates() ? 'Submit Anyway' : 'Submit' }}</span>
          </button>

          <ng-container *ngIf="canSubmitJob() && hasJobForCurrentUser()">
            <!-- Save as Draft for job -->
            <button
              type="button"
              class="btn btn-secondary"
              (click)="onJobDraft(frmPerson.getValue())"
              [disabled]="isSubmitting || isUpdating || isSavingStatus"
            >
              {{ getJobTypeForCurrentUser()?.endsWith('_QC') ? 'Skip/Save as draft' : 'Save as draft' }}
            </button>

            <!-- Submit job -->
            <button
              type="submit"
              class="btn btn-primary"
              [ngClass]="{ 'btn-loading': isSubmitting }"
              [disabled]="
                !(frmPerson.isValid() || canSubmitJobAnyway()) || isSubmitting || isUpdating || isSavingStatus
              "
              [title]="frmPerson.invalidFields ? frmPerson.invalidFields().join(', ') : ''"
              (click)="onSubmitJob(frmPerson.getValue())"
            >
              <span>{{ !frmPerson.isValid() && canSubmitJobAnyway() ? 'Submit job anyway' : 'Submit job' }}</span>
            </button>
          </ng-container>

          <!-- Save training report -->
          <dirt-save-training-report
            *ngIf="hasTrainingFeaturesEnabled() && !!basePerson"
            [comment]="(person.trainingEvaluationReport || {}).comment"
            [report]="(person.trainingEvaluationReport || {}).reportDetails"
            [saveReport]="savePersonReport.bind(this)"
          ></dirt-save-training-report>
        </div>
      </div>

      <hr [hidden]="!isObjectionCommentShown" />

      <!-- Comment Form -->
      <dirt-comment-create
        [ownerId]="person && person.id ? person.id : id"
        [showBtnSubmit]="true"
        [showBtnCancel]="true"
        [btnSubmitCaption]="objectionStatus | capitalize"
        (onSubmitted)="onObjectionSubmit()"
        (onCancelled)="onObjectionCancel()"
        *ngIf="isObjectionCommentShown && !disableForm"
      >
      </dirt-comment-create>
    </div>

    <div class="col-md-4">
      <ul ngbNav #nav="ngbNav" class="nav-tabs">
        <!-- Information -->
        <li ngbNavItem>
          <a ngbNavLink>Information</a>
          <ng-template ngbNavContent>
            <dl class="row" [diMarkerWrapper]="person">
              <dt class="col-sm-5" [hidden]="!isAutoProject(person)"></dt>
              <dd class="col-sm-7" [hidden]="!isAutoProject(person)"><span class="badge badge-info">Auto</span></dd>

              <dt class="col-sm-5" [hidden]="!person.newAffiliationCreated"></dt>
              <dd class="col-sm-7" [hidden]="!person.newAffiliationCreated">
                <span class="badge badge-warning">New Affiliation Created</span>
              </dd>

              <dt class="col-sm-5" [hidden]="!isClaimedByOtherUser()"></dt>
              <dd class="col-sm-7" [hidden]="!isClaimedByOtherUser()">
                <span title="s/o else started working recently" class="badge badge-info">Claimed</span>
              </dd>

              <dt class="col-sm-5" [hidden]="!isDraft()"></dt>
              <dd class="col-sm-7" [hidden]="!isDraft()"><span class="badge badge-info">Draft</span></dd>

              <dt class="col-sm-5" [hidden]="!products?.length"></dt>
              <dd class="col-sm-7" [hidden]="!products?.length">
                <span
                  ngbTooltip="Delivery product"
                  *ngFor="let product of products"
                  class="badge product-badge"
                  [style.color]="getProductColor(product)"
                  [style.border]="'1px solid ' + getProductColor(product)"
                  >{{ product | uppercase | productLabel }}</span
                >
              </dd>

              <dt class="col-sm-5" [hidden]="!person.projectNames?.includes('CUBE')"></dt>
              <dd class="col-sm-7" [hidden]="!person.projectNames?.includes('CUBE')">
                <span class="badge product-badge badge-oncology">Oncology</span>
              </dd>

              <dt class="col-sm-5">Person ID</dt>
              <dd class="col-sm-7">
                <b>{{ person.kolId }}</b
                ><i
                  class="fa fa-copy"
                  style="cursor: pointer"
                  (click)="onCopyID(person.kolId)"
                  ngbTooltip="Copied"
                  [openDelay]="1000"
                  [closeDelay]="2000"
                ></i>
              </dd>

              <dt class="col-sm-5" style="white-space: nowrap">Owner Product</dt>
              <dd class="col-sm-7">{{ person.ownerProduct || '-' }}</dd>

              <dt class="col-sm-5">Country</dt>
              <dd class="col-sm-7">{{ getCountry() }}</dd>

              <dt class="col-sm-5" [hidden]="!person.externalIds?.openData && person.openDataStatus !== 'Rejected'">
                OpenData ID
              </dt>
              <dd class="col-sm-7" [hidden]="!person.externalIds?.openData && person.openDataStatus !== 'Rejected'">
                <span *ngIf="!!person.openDataStatus" class="badge badge-info">{{ person.openDataStatus }}</span>
                {{ person.externalIds?.openData ? person.externalIds?.openData : 'Rejected by VOD' }}
                <i
                  class="fa fa-copy"
                  style="cursor: pointer"
                  (click)="onCopyID(person.externalIds?.openData ? person.externalIds?.openData : 'Rejected by VOD')"
                  ngbTooltip="Copied"
                  [openDelay]="1000"
                  [closeDelay]="2000"
                ></i>
              </dd>

              <dt class="col-sm-5">NPI</dt>
              <dd class="col-sm-7">
                <div
                  *diMarker="{ name: 'externalIds_npi', area: reviewMarkerArea, lfkaOnlyProject: isOnlyKAMProject() }"
                >
                  <ng-container *ngIf="person.externalIds?.npi; else na">
                    {{ person.externalIds?.npi
                    }}<i
                      class="fa fa-copy"
                      style="cursor: pointer"
                      (click)="onCopyID(person.externalIds?.npi)"
                      ngbTooltip="Copied"
                      [openDelay]="1000"
                      [closeDelay]="2000"
                    ></i>
                  </ng-container>

                  <ng-template #na> N/A </ng-template>
                </div>
              </dd>

              <dt class="col-sm-5" [hidden]="!person.externalIds?.authorId">Author ID</dt>
              <dd class="col-sm-7" [hidden]="!person.externalIds?.authorId">{{ person.externalIds?.authorId }}</dd>

              <dt class="col-sm-5 di-separator" *ngIf="hasJobForCurrentUser()">Job</dt>
              <dd
                class="col-sm-7 di-separator"
                *ngIf="hasJobForCurrentUser()"
                [innerText]="personJob?.type + (personJob?.hint ? ' - ' + personJob.hint : '')"
              ></dd>

              <ng-container *ngIf="hasJobForCurrentUser() && personJob?.type === 'PERSON_ORGANIZATION_DISCONNECTION'">
                <dt class="col-sm-5 di-separator">Job Goal</dt>
                <dd class="col-sm-7 di-separator">
                  <span>Disconnect this person from the following Organizations:</span>
                  <ul>
                    <li *ngFor="let item of personJob?.additionalData?.jobTargets | keyvalue">
                      <a
                        href="/organization/detail/{{ item.value?._id }}"
                        target="_blank"
                        title="{{ item.value?.fullName }}"
                        >{{ item.value?.name }}</a
                      >
                    </li>
                  </ul>
                </dd>
              </ng-container>

              <ng-template [diAcl]="'job.create'" [diAclAnd]="person.id">
                <dt class="col-sm-5"></dt>
                <dd class="col-sm-7">
                  <a href="javascript:void(0)" (click)="onJobCreate()">Create Job</a>
                </dd>
              </ng-template>

              <dt class="col-sm-5 di-separator">ID-QC</dt>
              <dd class="col-sm-7 di-separator">
                <span>
                  <i
                    class="fa"
                    [ngClass]="{ 'fa-check': person.idverified, 'fa-times': !person.idverified }"
                    aria-hidden="true"
                  ></i>
                </span>
              </dd>

              <dt class="col-sm-5">QC</dt>
              <dd class="col-sm-7">
                <span>
                  <i
                    class="fa"
                    [ngClass]="{ 'fa-check': person.verified, 'fa-times': !person.verified }"
                    aria-hidden="true"
                  ></i>
                </span>
              </dd>

              <ng-container *ngIf="hasUnpolishedAffiliations || hasUnpolishedLFKAAffiliations">
                <dt class="col-sm-5" title="Central organizations did not yet check all organizations">
                  Unpolished affiliations
                </dt>
                <dd class="col-sm-7">
                  <span *ngIf="hasUnpolishedAffiliations">{{ 'LFTA' | productLabel }}</span>
                  <span *ngIf="hasUnpolishedLFKAAffiliations">{{ 'LFKA' | productLabel }}</span>
                </dd>
              </ng-container>

              <dt class="col-sm-5">Delivery Ready {{ 'LFTA' | productLabel }}</dt>
              <dd class="col-sm-7">
                <i
                  class="fa"
                  [ngClass]="{ 'fa-check': person.readyForDeliveryLFTA, 'fa-times': !person.readyForDeliveryLFTA }"
                  aria-hidden="true"
                ></i>
              </dd>

              <ng-container
                *ngIf="person.projectNames.includes('LFKA_RF_BD') || person.projectNames.includes('KAM_ALL')"
              >
                <dt class="col-sm-5">Delivery Ready LFKA</dt>
                <dd class="col-sm-7">
                  <i
                    class="fa"
                    [ngClass]="{ 'fa-check': person.readyForDeliveryLFKA, 'fa-times': !person.readyForDeliveryLFKA }"
                    aria-hidden="true"
                  ></i>
                </dd>
              </ng-container>

              <dt class="col-sm-5 di-separator">Created</dt>
              <dd class="col-sm-7 di-separator">{{ person.createdAt | date : 'medium' }}</dd>

              <dt class="col-sm-5 text-truncate">Modified</dt>
              <dd class="col-sm-7">{{ person.updatedAt | date : 'medium' }}</dd>

              <dt class="col-sm-5 text-truncate" *ngIf="person?.affiliationsClinicalReviewedAt">
                Ct Affiliations Reviewed
              </dt>
              <dd class="col-sm-7" *ngIf="person?.affiliationsClinicalReviewedAt">
                {{ person.affiliationsClinicalReviewedAt | date : 'medium' }}
              </dd>

              <dt class="col-sm-5 text-truncate" *ngIf="person.identify?.at">Identified at</dt>
              <dd class="col-sm-7" *ngIf="person.identify?.at">{{ person.identify.at | date : 'medium' }}</dd>

              <dt class="col-sm-5 text-truncate" *ngIf="person.identify?.by">Identified by</dt>
              <dd class="col-sm-7 font-italic" *ngIf="person.identify?.at">
                <dirt-username
                  [user]="
                    identifyUser && identifyUser.user_id === person.identify.by ? identifyUser : person.identify.by
                  "
                ></dirt-username>
              </dd>

              <dt class="col-sm-5 text-truncate" *ngIf="person.review?.at">Compiled at</dt>
              <dd class="col-sm-7" *ngIf="person.review?.at">{{ person.review.at | date : 'medium' }}</dd>

              <dt class="col-sm-5 text-truncate" *ngIf="person.review?.by">Compiled by</dt>
              <dd class="col-sm-7 font-italic" *ngIf="person.review?.at">
                <dirt-username
                  [user]="compileUser && compileUser.user_id === person.review.by ? compileUser : person.review.by"
                ></dirt-username>
              </dd>

              <dt class="col-sm-5 text-truncate">Status</dt>
              <dd class="col-sm-7">
                <div *diMarker="{ name: 'meta_status', area: reviewMarkerArea, lfkaOnlyProject: isOnlyKAMProject() }">
                  <!-- Full status -->
                  <ng-container *ngIf="canChangeStatus(); else limitedStatusFallback">
                    <dirt-status
                      [statuses]="statuses"
                      [assigneeStatuses]="assigneeStatuses"
                      [keepAssignee]="hasJobForCurrentUser()"
                      [disabledStatuses]="canChangeManagerOnlyStatus() ? [] : managerOnlyStatuses"
                      [_meta]="person._meta"
                      [showClaimedWarning]="isClaimedByOtherUser()"
                      [requiresEu]="requiresEu()"
                      (onStatusSelected)="onChangeStatus($event)"
                    >
                    </dirt-status>
                  </ng-container>

                  <ng-template #limitedStatusFallback>
                    <!-- Limited status -->
                    <ng-container *ngIf="canChangeLimitedStatus(); else statusFallback">
                      <dirt-status
                        [statuses]="statuses"
                        [assigneeStatuses]="assigneeStatuses"
                        [keepAssignee]="hasJobForCurrentUser()"
                        [disabledStatuses]="
                          hasJobForCurrentUser() && limitedDisabledStatusesByJob[personJob.type]
                            ? limitedDisabledStatusesByJob[personJob.type]
                            : hasRestrictedStatusOptions()
                            ? limitedDisabledStatusesEx
                            : limitedDisabledStatuses
                        "
                        [commentStatuses]="limitedCommentStatuses"
                        [_meta]="person._meta"
                        [requiresEu]="requiresEu()"
                        (onStatusSelected)="onChangeStatus($event)"
                        (onCommentCreated)="onCreateComment($event)"
                        [showStatusIdOutReasons]="true"
                        [showClaimedWarning]="isClaimedByOtherUser()"
                      >
                      </dirt-status>
                    </ng-container>

                    <!-- Always fallback to readonly -->
                    <ng-template #statusFallback>
                      <span>{{ person._meta?.status | capitalize }}</span>
                    </ng-template>
                  </ng-template>

                  <i class="fa fa-circle-o-notch fa-spin" [hidden]="!isSavingStatus"></i>
                </div>
              </dd>

              <dt
                class="col-sm-5"
                [title]="
                  person.manualProjectNames && person.manualProjectNames.length > 0
                    ? 'Prev: ' + person.manualProjectNames.join(', ')
                    : ''
                "
              >
                Delivery Project
              </dt>
              <dd class="col-sm-7">
                <div
                  *diMarker="{
                    name: 'deliveryProjectNames',
                    area: reviewMarkerArea,
                    lfkaOnlyProject: isOnlyKAMProject()
                  }"
                >
                  <dirt-project-name
                    [activeSet]="person.projectNames"
                    [limitToSet]="hasRestrictedProjectOptions() ? [] : null"
                    [type]="ValueType.PersonProject"
                    [projectTag]="'delivery'"
                    [disabled]="!canEditDeliveryProjects || disableForm"
                    (onProjectSelectionChanged)="onProjectSelectionChanged($event)"
                  >
                  </dirt-project-name>
                </div>
              </dd>

              <dt
                class="col-sm-5"
                [title]="
                  person.manualProjectNames && person.manualProjectNames.length > 0
                    ? 'Prev: ' + person.manualProjectNames.join(', ')
                    : ''
                "
              >
                Technical Project
              </dt>
              <dd class="col-sm-7">
                <div
                  *diMarker="{
                    name: 'technicalProjectNames',
                    area: reviewMarkerArea,
                    lfkaOnlyProject: isOnlyKAMProject()
                  }"
                >
                  <dirt-project-name
                    [activeSet]="person.projectNames"
                    [limitToSet]="null"
                    [type]="ValueType.PersonProject"
                    [projectTag]="'technical'"
                    [disabled]="!canEditTechnicalProjects || disableForm"
                    (onProjectSelectionChanged)="onProjectSelectionChanged($event)"
                  >
                  </dirt-project-name>
                </div>
              </dd>

              <dt class="col-sm-5">Version</dt>
              <dd class="col-sm-7">{{ person._version || 0 }}</dd>
            </dl>
          </ng-template>
        </li>

        <!-- Comments -->
        <li ngbNavItem *diAcl="'person.comment.list'">
          <a ngbNavLink>Comments</a>
          <ng-template ngbNavContent>
            <dirt-comment-list
              [ownerId]="person && person.id ? person.id : id"
              [enableCreate]="canCreateComment()"
            ></dirt-comment-list>
          </ng-template>
        </li>

        <!-- Audit Log -->
        <li ngbNavItem *diAcl="'person.audit.list'">
          <a ngbNavLink>Audit Log</a>
          <ng-template ngbNavContent>
            <dirt-audit-log
              [id]="person && person.id ? person.id : id"
              [entityAPI]="svcPerson"
              [shallowCompKeys]="auditLogShallowCompKeys"
            ></dirt-audit-log>
          </ng-template>
        </li>

        <!-- Profile -->
        <li ngbNavItem *diAcl="'person.profile.summary'">
          <a ngbNavLink>Profile</a>
          <ng-template ngbNavContent>
            <dirt-profile-summary [personId]="person && person.id ? person.id : id"></dirt-profile-summary>
          </ng-template>
        </li>

        <!-- Job History -->
        <li ngbNavItem *diAcl="'person.job-history.list'">
          <a ngbNavLink>Job History</a>
          <ng-template ngbNavContent>
            <dirt-job-history [entityId]="person && person.id ? person.id : id"></dirt-job-history>
          </ng-template>
        </li>

        <!-- Compliance -->
        <li *ngIf="person && (person.compliance || []).length > 0" ngbNavItem>
          <a ngbNavLink>Compliance</a>
          <ng-template ngbNavContent>
            <div *ngFor="let compliance of person.compliance; let idx = index">
              <hr *ngIf="idx > 0" />
              <dl class="row">
                <dt class="col-sm-5">Notification Date</dt>
                <dd class="col-sm-7">
                  {{ compliance.noticeSendDate | date : 'mediumDate' }}
                  <span
                    *diAcl="'person.compliance.delete'"
                    class="pull-right action-btn action-delete"
                    title="Delete Compliance Record"
                    (click)="onDeleteComplianceRecord(compliance._id)"
                  >
                    <i class="fa fa-trash-o" aria-hidden="true"></i>
                  </span>
                </dd>

                <dt class="col-sm-5">Batch ID</dt>
                <dd class="col-sm-7 text-break">{{ compliance.batchId }}</dd>

                <dt class="col-sm-5">Notice Channel</dt>
                <dd class="col-sm-7">{{ compliance.noticeChannel }}</dd>

                <dt class="col-sm-5">Region</dt>
                <dd class="col-sm-7">{{ compliance.region }}</dd>

                <dt class="col-sm-5">Country</dt>
                <dd class="col-sm-7">{{ compliance.countryCode }}</dd>

                <dt class="col-sm-5" [hidden]="!compliance.state">State</dt>
                <dd class="col-sm-7" [hidden]="!compliance.state">{{ compliance.state }}</dd>

                <dt class="col-sm-5">Informed About</dt>
                <dd class="col-sm-7">
                  <span *ngFor="let item of compliance.dataCategories || []" class="badge">{{ item }}</span>
                </dd>
              </dl>
            </div>
          </ng-template>
        </li>
      </ul>

      <div [ngbNavOutlet]="nav" class="dirt-section-meta dirt-person-meta"></div>

      <br />

      <ng-container *ngIf="hasTrainingFeaturesEnabled() && hasBDErrorReport()">
        <ul ngbNav #trainingNav="ngbNav" class="nav-tabs">
          <li ngbNavItem>
            <a ngbNavLink>Error report</a>
            <ng-template ngbNavContent>
              <dl class="row">
                <dt class="col-sm-5">Overall Error Rate</dt>
                <dd class="col-sm-7">
                  <span>{{ getPersonTrainingErrorRate() }}</span>
                </dd>
                <hr />

                <dt class="col-sm-5">Name</dt>
                <dd class="col-sm-7">
                  <span>{{ getNameErrorRate() }}</span>
                </dd>

                <dt class="col-sm-5">CV Links</dt>
                <dd class="col-sm-7">
                  <span>{{ getBreakdownErrorRate('cvLinks.fieldErrorRate.errorRate') }}</span>
                </dd>

                <dt class="col-sm-5">Affiliations</dt>
                <dd class="col-sm-7">
                  <span>{{ getBreakdownErrorRate('affiliations.fieldErrorRate.errorRate') }}</span>
                </dd>

                <dt class="col-sm-5">Job title</dt>
                <dd class="col-sm-7">
                  <span>{{ getBreakdownErrorRate('affiliations.fieldErrorRate.positionErrorRate') }}</span>
                </dd>

                <dt class="col-sm-5">Degrees</dt>
                <dd class="col-sm-7">
                  <span>{{ getBreakdownErrorRate('degrees.fieldErrorRate.errorRate') }}</span>
                </dd>

                <dt class="col-sm-5">Speciality</dt>
                <dd class="col-sm-7">
                  <span>{{ getBreakdownErrorRate('specialities.fieldErrorRate.errorRate') }}</span>
                </dd>

                <dt class="col-sm-5">Status</dt>
                <dd class="col-sm-7">
                  <span>{{ getBreakdownErrorRate('status.fieldErrorRate.errorRate') }}</span>
                </dd>

                <dt class="col-sm-5">Expert type</dt>
                <dd class="col-sm-7">
                  <span>{{ getBreakdownErrorRate('profession.fieldErrorRate.errorRate') }}</span>
                </dd>

                <dt class="col-sm-5">Email</dt>
                <dd class="col-sm-7">
                  <span>{{ getBreakdownErrorRate('emails.fieldErrorRate.errorRate') }}</span>
                </dd>
              </dl>
            </ng-template>
          </li>
        </ul>

        <div [ngbNavOutlet]="trainingNav" class="dirt-section-meta dirt-person-meta"></div>
      </ng-container>
    </div>

    <div class="col-md-12" *diAcl="'person.committee.list'">
      <div class="card-header toggle-section" (click)="toggleCollapseSettings('committees')">
        <div class="d-inline-block">
          <i
            class="fa fa-lg"
            [ngClass]="{
              'fa-angle-right': isCollapsed.get('committees'),
              'fa-angle-down': !isCollapsed.get('committees')
            }"
            aria-hidden="true"
          ></i>
          <span class="font-weight-bold pl-1 pr-2">Committees</span>
        </div>
      </div>

      <div [ngbCollapse]="isCollapsed.get('committees')">
        <dirt-committee-restricted-list [initialFilter]="{ kolId: person.kolId }"></dirt-committee-restricted-list>
      </div>
    </div>
  </div>

  <dirt-time-report-component
    [major]="'person'"
    [id]="person.id"
    [minor]="'BASE_DATA'"
    [state]="person._meta?.status || null"
  ></dirt-time-report-component>
</section>

<!-- Modal: Comments -->
<ng-template #commentList let-d="dismiss">
  <div class="dirt-content-wrapper" tabindex="0" (keydown)="onModalKeyPress($event)">
    <div class="modal-header">
      <h5 class="modal-title">Comments</h5>
      <button type="button" class="close" aria-label="Close" (click)="d()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <dirt-comment-list
        [ownerId]="person.id"
        [enableCreate]="canCreateComment()"
        (onAutoCloseFunc)="d()"
      ></dirt-comment-list>
    </div>
  </div>
</ng-template>

<!-- Modal: Check Findings -->
<ng-template #findingsList let-d="dismiss">
  <div class="dirt-content-wrapper" tabindex="0" (keydown)="onModalKeyPress($event)">
    <div class="modal-header">
      <h5 class="modal-title">We removed non-compliant links</h5>
      <button type="button" class="close" aria-label="Close" (click)="d()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <ul>
        <li *ngFor="let k of checkKeys" style="word-break: break-all">
          <strong>{{ k }}: </strong> {{ checkFindings[k].join(', ') }}
        </li>
      </ul>
    </div>
  </div>
</ng-template>

<!-- Modal: Duplicate alert -->
<ng-template #duplicateAlertDialog let-close="close">
  <div class="modal-header">
    <h4 class="modal-title pull-left">Person Already Exists!</h4>
    <button type="button" class="close" aria-label="Close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div *ngIf="duplicatePersonData" class="modal-body">
    <div class="row ml-1">
      Person ID: {{ duplicatePersonData.kolId }} &nbsp;
      <a
        *ngIf="duplicatePersonData.id"
        [routerLink]="['/person/detail', duplicatePersonData.id]"
        rel="noopener"
        target="_blank"
      >
        Open profile
      </a>
    </div>
  </div>

  <div class="modal-footer">
    <div class="pull-right">
      <button type="button" class="btn btn-secondary btn-sm" (click)="close()">Close</button>
    </div>
  </div>
</ng-template>
