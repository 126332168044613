import { cloneDeep } from 'lodash';
import { Component, Inject, OnInit } from '@angular/core';
import { distinctUntilChanged, Subject, takeUntil } from 'rxjs';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';

import { AffiliationBaseinfo } from '../../../../affiliation/shared/affiliation-baseinfo';
import { ClinicalTrialProfilePersonMapping } from '../../profile-person-mapping';
import { Value } from '../../../../shared/services/value/value';

type ClinicalTrialProfileSiteDetailsWithName = ClinicalTrialProfileSiteDetails & { displayName: string };

export const CT_PROFILE_INVESTIGATOR_SITES_INJECTION_TOKEN = 'ct-profile-investigator-sites-data';

export type ClinicalTrialProfileSiteName = string;
export type ClinicalTrialProfileSiteDetails = AffiliationBaseinfo & {
  investigators?: ClinicalTrialProfilePersonMapping[];
};

export interface ClinicalTrialProfileInvestigatorsSitesListData {
  sitesGroups: Record<ClinicalTrialProfileSiteName, ClinicalTrialProfileSiteDetailsWithName[]>;
  roles: Value[];
}

@Component({
  selector: 'dirt-ct-profile-investigators-sites-list',
  templateUrl: 'investigators-sites-list.component.html',
})
export class ClinicalTrialProfileInvestigatorsSitesListComponent implements OnInit {
  displayedSiteGroups: {
    name: ClinicalTrialProfileSiteName;
    sites: ClinicalTrialProfileSiteDetailsWithName[];
  }[] = [];

  searchCtrl = new FormControl('');

  private sitesGroups: {
    name: ClinicalTrialProfileSiteName;
    sites: ClinicalTrialProfileSiteDetailsWithName[];
  }[] = [];

  private roles: Value[];

  private destroy$ = new Subject<boolean>();

  constructor(
    private router: Router,
    @Inject(CT_PROFILE_INVESTIGATOR_SITES_INJECTION_TOKEN) private data: ClinicalTrialProfileInvestigatorsSitesListData
  ) {
    this.roles = data.roles;
    this.processSitesGroups(data);
  }

  private processSitesGroups(data: ClinicalTrialProfileInvestigatorsSitesListData) {
    Object.entries(data.sitesGroups).forEach(([name, sites]) => {
      sites.forEach((site) => {
        site.displayName = this.getDisplayName(site);

        site.investigators?.forEach((investigator: ClinicalTrialProfilePersonMapping & { displayRoles: string[] }) => {
          if (!Array.isArray(investigator.displayRoles)) {
            investigator.displayRoles = [];
          }

          investigator.roles.forEach((r) => {
            const displayRole = this.roles.find((v) => v.code === r.role)?.value;
            investigator.displayRoles.push(displayRole || r.role);
          });

          // Deduplicate the roles so that we don't have multiple occurrences of the same role
          investigator.displayRoles = Array.from(new Set(investigator.displayRoles));
        });
      });

      this.sitesGroups.push({
        name,
        sites,
      });
    });

    this.displayedSiteGroups = cloneDeep(this.sitesGroups);
  }

  ngOnInit(): void {
    this.searchCtrl.valueChanges.pipe(takeUntil(this.destroy$), distinctUntilChanged()).subscribe((term) => {
      if (!term) {
        this.displayedSiteGroups = cloneDeep(this.sitesGroups);
      }

      this.displayedSiteGroups = cloneDeep(this.sitesGroups).filter((group) => {
        const termLower = term.toLowerCase();
        const sites = group.sites.filter(
          (site) => site.displayName.toLowerCase().includes(termLower) || site.name.toLowerCase().includes(termLower)
        );
        if (sites.length === 0) {
          return false;
        }

        // Also filter the sites visually
        group.sites = sites;

        return true;
      });
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(false);
    this.destroy$.complete();
  }

  onOpenKol(kolId: string): void {
    const url = this.router.serializeUrl(this.router.createUrlTree(['/person/detail', kolId]));
    window.open(url, '_blank');
  }

  onOpenAffiliation(id: string): void {
    const url = this.router.serializeUrl(this.router.createUrlTree(['/affiliation/detail', id]));
    window.open(url, '_blank');
  }

  onOpenOrganization(id: string): void {
    const url = this.router.serializeUrl(this.router.createUrlTree(['/organization/detail', id]));
    window.open(url, '_blank');
  }

  onCopyID(id: string): void {
    document.addEventListener(
      'click',
      () => {
        navigator.clipboard
          .writeText(id)
          .then(() => console.log('Copied to clipboard'))
          .catch((err) => console.error('Failed to copy to clipboard:', err));
      },
      { once: true }
    );
  }

  private getDisplayName(site: ClinicalTrialProfileSiteDetails): string {
    let name = site.department || site.name;

    if (site.address) {
      name +=
        ' (' +
        [site.address.street, site.address.city, site.address.zip, site.address.country].filter((p) => !!p).join(', ') +
        ')';
    }

    return name;
  }
}
