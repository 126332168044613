import { Component, EventEmitter, Input, Output } from '@angular/core';
import { firstValueFrom, Observable, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, switchMap, take, tap } from 'rxjs/operators';

import { AffiliationAPI } from '../../api.service';
import { Affiliation } from '../../affiliation';
import { OrganizationAPI, OrganizationSearchResultDTO } from '../../../../organizations/shared/api.service';
import { OrganizationAffiliationAPI } from '../../../../organization-affiliation/shared/organization-affiliation-api.service';

type AugmentedAffiliation = Affiliation & { organizationId: string };

/**
 * @TODO: An older version of this component exists to facilitate searching
 *   affiliations which has since been deprecated. However, as long as the
 *   `-tmp` version of this component exists, it should be kept in sync as
 *   close as possible.
 */
@Component({
  selector: 'dirt-affiliation-single-select',
  templateUrl: 'affiliation-single-select.html',
  styleUrls: ['affiliation-single-select.scss'],
})
export class AffiliationSingleSelectComponent {
  @Input()
  affiliation: Affiliation | string;

  @Output() // (can [(affiliation)]
  affiliationSelected: EventEmitter<AugmentedAffiliation | null> = new EventEmitter();

  @Input()
  wide: boolean = false;

  @Input()
  inline = false;

  @Input()
  curatedOnly: boolean = false;

  @Input()
  excludeUnpolished: boolean = true;

  @Input()
  disabled = false;

  @Input()
  required = false;

  @Input()
  creationRequestHandler?: () => Observable<AugmentedAffiliation>;

  isSearching = false;

  searchOrganizations = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      tap(() => (this.isSearching = true)),
      switchMap((term) => {
        if (!term) {
          return of([]);
        }

        return this.svcOrganization.search(term, 100, this.curatedOnly, !this.excludeUnpolished).pipe(
          take(1),
          catchError(() => {
            return of([]);
          })
        );
      }),
      tap(() => (this.isSearching = false))
    );

  constructor(
    private readonly svcAffiliation: AffiliationAPI,
    private readonly svcOrganization: OrganizationAPI,
    private readonly svcOrganizationMapping: OrganizationAffiliationAPI
  ) {}

  async onChangeOrganization({ _id: organizationId }: OrganizationSearchResultDTO) {
    const mappings = await firstValueFrom(this.svcOrganizationMapping.find({ organizationIds: [organizationId] }));
    if (mappings.length <= 0) {
      console.error('Unable to find an affiliation mapping. (orgId=${selection._id})');
      return;
    }
    const mapping = mappings[0];

    const affiliation = await firstValueFrom(this.svcAffiliation.findById(mapping.affiliationId));
    this.selectAffiliation({ ...affiliation, organizationId });
  }

  private selectAffiliation(affiliation: AugmentedAffiliation) {
    this.affiliationSelected.emit(affiliation);

    if (!this.inline) {
      setTimeout(() => (this.affiliation = null)); // (next apply)
    } else {
      this.affiliation = affiliation;
    }
  }

  onChangeAffiliationInline(value: Affiliation | string): void {
    if (!value || !value?.toString()?.trim()) {
      this.affiliationSelected.emit(null); // value cleared
    }
  }

  onRequestAffiliation(): void {
    if (!this.creationRequestHandler) {
      return;
    }

    this.creationRequestHandler()
      .pipe(take(1))
      .subscribe((affiliation) => {
        this.selectAffiliation(affiliation);
      });
  }

  formatTitle(affiliation: Affiliation): string {
    return [affiliation.name, affiliation.department].filter((a) => !!a).join(' - ');
  }
}
