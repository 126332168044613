import { NgModule } from '@angular/core';

import { AddressEntityCreateModalComponent } from './create-modal/create-modal.component';
import { AddressEntityCreateModalService } from './create-modal/create-modal.service';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  imports: [SharedModule],
  declarations: [AddressEntityCreateModalComponent],
  providers: [AddressEntityCreateModalService],
  exports: [],
})
export class AddressEntityModule {}
