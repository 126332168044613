import { Component } from '@angular/core';
import { debounceTime, distinctUntilChanged, tap, switchMap, catchError } from 'rxjs/operators';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, of } from 'rxjs';

import { Profile } from '../../../../profile/shared/profile';
import { Value } from '../../../../shared/services/value/value';
import { ValueAPI } from '../../../../shared/services/value/value-api.service';
import { ValueType } from '../../../../shared/enum/value-type.enum';
import { Person } from '../../../../person/shared/person';
import { Job } from '../../../../jobs/shared/job';
import { PersonAPI } from '../../../../person/shared/api.service';

export enum LinkedEntityType {
  ExistingPerson,
  NewPerson,
}

@Component({
  selector: 'dirt-video-profile-modal',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class VideoProfileModalComponent {
  job: Job;

  isSearching: boolean;

  showPersonForm: boolean;

  isFormValid: boolean;

  searchTerm: string;

  selectedProfile: Profile;

  newPerson: Person;

  specialtyList: Value[] = [];

  disabledFields = ['source', 'projectsDelivery'];

  selectedPerson: Person;

  constructor(private svcValue: ValueAPI, public activeModal: NgbActiveModal, private readonly svcPerson: PersonAPI) {
    this.onSearchPerson = this.onSearchPerson.bind(this);
    this.svcValue
      .find(ValueType.Specialty, Number.MAX_SAFE_INTEGER, 0, '+title')
      .toPromise()
      .then((data) => {
        this.specialtyList = data;
      });
  }

  onSearchPerson(term$: Observable<string>): Observable<Person[]> {
    return term$.pipe(
      debounceTime(400),
      distinctUntilChanged(),
      tap(() => (this.isSearching = true)),
      switchMap((term) => {
        if (!term) {
          return of([]);
        }
        return this.svcPerson.search(term).pipe(catchError(() => of([])));
      }),
      tap(() => (this.isSearching = false))
    );
  }

  onSelect(event: any): void {
    event.preventDefault(); // prevent setting model to [object Object]
    this.svcPerson.findById(event.item._id, true).subscribe((response: Person) => (this.selectedPerson = response));
    this.searchTerm = '';
  }

  onAdd(): void {
    if (this.selectedPerson) {
      this.activeModal.close({
        type: LinkedEntityType.ExistingPerson,
        data: this.selectedPerson,
      });
    }

    if (this.newPerson) {
      this.activeModal.close({
        type: LinkedEntityType.NewPerson,
        data: this.newPerson,
      });
    }
  }

  togglePersonCreationForm(): void {
    this.showPersonForm = !this.showPersonForm;
    this.newPerson = new Person();
    this.newPerson.cvLinks = [this.job.extEntityId];
    this.newPerson.source = 'VIDEO';
    this.newPerson.projectNames = ['VIDEO_CHANNEL_IDENTI'];
    this.selectedProfile = null;
    this.newPerson.countryWorkflow = this.job.additionalData.country;
  }

  getValueTitle(values: Value[], code: string) {
    return (values.find((o) => o.code === code) || { title: '' }).title;
  }

  onFormValidityChange(status: string): void {
    this.isFormValid = status === 'VALID';
  }

  onPersonNameClick(personId) {
    window.open(`/person/detail/${personId}`);
  }

  onResClick(evnt: MouseEvent, personId: string) {
    if (evnt.ctrlKey || evnt.metaKey) {
      evnt.stopPropagation();
      evnt.preventDefault();
      this.onPersonNameClick(personId);
    }
  }
}
