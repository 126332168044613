import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { tap, Subject, catchError } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';

import { AccountAffiliation } from '../shared/account-affiliation';
import { AccountAffiliationModalService } from '../shared/modal/affiliation/affiliation.service';
import { AccountAffiliationAPI } from '../shared/account-affiliation-api.service';
import { ACL } from '../../shared/acl/acl.service';
import { SideBarService } from '../../shared/components/side-bar/side-bar.service';
import { AuditLogComponent } from '../../shared/components';

@Component({
  selector: 'dirt-account-affiliation-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class AccountAffiliationListComponent implements OnInit {
  @Input()
  accountId: string;

  @Output()
  affiliationRemoved: EventEmitter<AccountAffiliation> = new EventEmitter();

  @Output()
  selectHQ: EventEmitter<void> = new EventEmitter();

  accountAffiliations: AccountAffiliation[];

  isLoading: boolean;
  isSubmitting: boolean;

  // Pagination settings
  total = { count: 0 };
  pagingPage = 1;
  pagingLimit = 5;
  pagingSkip = 0;

  searchCtrl: FormControl = new FormControl('');

  private searchTerm: string;

  private destroy$: Subject<boolean> = new Subject();

  hasHQ = false;
  hqAffiliationId = '';
  allMappedAffiliationIds: string[];

  canEditAffiliation = false;
  canEditIsExcludedFromDelivery = false;

  isAffiliationAlreadyHQ = {};

  constructor(
    private readonly svcAccountAffiliation: AccountAffiliationAPI,
    private readonly svcAccountAffiliationModal: AccountAffiliationModalService,
    private readonly svcAcl: ACL,
    private readonly svcSideBar: SideBarService
  ) {}

  ngOnInit(): void {
    this.canEditAffiliation = this.svcAcl.hasCredential('account.affiliation.update');
    this.canEditIsExcludedFromDelivery = this.svcAcl.hasCredential('account.affiliation.updateIsExcludedFromDelivery');

    this.doLoad();

    this.searchCtrl.valueChanges
      .pipe(takeUntil(this.destroy$), debounceTime(400), distinctUntilChanged())
      .subscribe((value) => {
        if (typeof value === 'undefined') {
          return;
        }
        this.searchTerm = value;
        this.doLoad();
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(false);
    this.destroy$.complete();
  }

  /** just avoid useless rendering if we can */
  trackById(index: number, accountAffiliation: AccountAffiliation): string {
    return accountAffiliation.id;
  }

  getPage(page: number): void {
    this.pagingPage = page;
    this.pagingSkip = (this.pagingPage - 1) * this.pagingLimit;
    this.getAccountAffiliations();
  }

  onDelete(id: string): void {
    if (!this.svcAcl.hasCredential('account.affiliation.delete')) {
      return;
    }
    if (
      !window.confirm(
        'Do you want to remove this entry(and all department affiliation)? This will NOT delete the affiliation.'
      )
    ) {
      return;
    }

    this.svcAccountAffiliation.deleteById(id).subscribe(() => {
      this.affiliationRemoved.emit();
      this.getAccountAffiliations();
      this.getAccountAffiliationsCount();
    });
  }

  doLoad(): void {
    this.resetPagination();
    this.getHQAffiliation();
    this.getAllMappedAffiliationIds();
  }

  private resetPagination(): void {
    this.pagingPage = 1;
    this.pagingSkip = 0;
  }

  private getHQAffiliation() {
    this.svcAccountAffiliation
      .find(this.searchTerm, 1, 0, undefined, this.accountId, undefined, { isHQ: true })
      .subscribe((hqAffiliation) => {
        this.hasHQ = (hqAffiliation || []).length > 0;
        this.hqAffiliationId = hqAffiliation[0]?.affiliation.id;
        this.getAccountAffiliationsCount();
        this.getAccountAffiliations();
      });
  }

  private getAccountAffiliations(): void {
    this.isLoading = true;

    this.svcAccountAffiliation
      .find(this.searchTerm, this.pagingLimit, this.pagingSkip, undefined, this.accountId, undefined, undefined, true)
      .pipe(tap(() => (this.isLoading = false)))
      .subscribe((accountAffiliationInformaiton) => {
        this.accountAffiliations = accountAffiliationInformaiton;
      });
  }

  private getAccountAffiliationsCount(): void {
    this.svcAccountAffiliation.count(this.accountId, this.searchTerm, true).subscribe((count) => {
      this.total = count;
    });
  }

  addAffiliation(): void {
    this.svcAccountAffiliationModal
      .open({ topOnly: true, allMappedAffiliationIds: this.allMappedAffiliationIds })
      .then((result) => {
        const status = { status: result.type };

        this.svcAccountAffiliation
          .create({ accountId: this.accountId, affiliationId: result.data.id, status: status.status })
          .pipe(
            tap(() => (this.isSubmitting = false)),
            catchError((err) => {
              this.isSubmitting = false;
              throw err;
            })
          )
          .subscribe(() => {
            this.doLoad();
          });
      })
      .catch(() => {});
  }

  private updateAccountAffiliation({ id, ...updates }: Partial<AccountAffiliation>): void {
    this.svcAccountAffiliation
      .update(id, updates)
      .pipe(
        tap(() => (this.isSubmitting = false)),
        catchError((err) => {
          this.isSubmitting = false;
          throw err;
        })
      )
      .subscribe((updatedAccountAffiliation) => {
        if (Object.keys(updates).includes('isHQ')) {
          this.doLoad();

          if (updates.isHQ) {
            this.selectHQ.emit();
          }
        } else {
          this.accountAffiliations.splice(
            this.accountAffiliations.findIndex((a) => a.id === updatedAccountAffiliation.id),
            updatedAccountAffiliation
          );
        }
      });
  }

  async onAffiliationUpdate(accountAffiliationId: string, key: keyof AccountAffiliation, event, affiliationId: string) {
    if (!this.canEditAffiliation) {
      return;
    }
    if (key === 'isHQ' && event.target.checked === false && !window.confirm('Do you want to remove isHQ?')) {
      return;
    }
    if (key === 'isHQ' && event.target.checked === true) {
      this.svcAccountAffiliation.canBeHQ(affiliationId).subscribe((canBe) => {
        if (!canBe) {
          for (const accountAffiliation of this.accountAffiliations) {
            for (const dept of accountAffiliation['departments']) {
              if (dept['affiliation']['_id'] === affiliationId) {
                dept['isHQ'] = false;
              }
            }
          }
          alert('This organization is already an HQ for another one');
        }
        if (canBe) {
          this.updateAccountAffiliation({ id: accountAffiliationId, [key]: event.target.checked });
        }
      });
      return;
    }

    this.updateAccountAffiliation({ id: accountAffiliationId, [key]: event.target.checked });
  }

  hasHQAffiliation() {
    return this.hasHQ;
  }

  getAllMappedAffiliationIds() {
    this.svcAccountAffiliation.findAffiliationIds(this.accountId).subscribe((allIds) => {
      this.allMappedAffiliationIds = allIds;
      if (allIds && allIds.length > 0) {
        this.svcAccountAffiliation.isHQ(allIds).subscribe((hqAffiliation: { affiliation: string; isHQ: boolean }[]) => {
          hqAffiliation.map((item) => (this.isAffiliationAlreadyHQ[item.affiliation] = item.isHQ));
        });
      }
    });
  }

  onRequestAuditLogs(ids: string[]): void {
    const componentRef = this.svcSideBar.open<AuditLogComponent>(AuditLogComponent);
    componentRef.instance.id = ids;
    componentRef.instance.entityAPI = this.svcAccountAffiliation;
    componentRef.instance.ngOnChanges({
      id: { previousValue: null, currentValue: ids, isFirstChange: () => true, firstChange: true },
    }); // Need to be invoked manually
  }
}
