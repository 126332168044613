<span [ngClass]="{wide: wide}">
  <div class="input-group">
    <div class="search-wrapper">
      <input
        type="search"
        class="di-search form-control ng-trim-ignore"
        [ngClass]="{ loading: isSearching }"
        [ngbTypeahead]="searchOrganizations"
        [focusFirst]="false"
        [resultTemplate]="searchResultTemplate"
        (selectItem)="onChangeOrganization($event.item)"
        [(ngModel)]="affiliation"
        (ngModelChange)="onChangeAffiliationInline($event)"
        [inputFormatter]="inline ? formatTitle : null"
        [editable]="!inline"
        [disabled]="!!disabled"
        [required]="!!required"
        [placeholder]="'Search HCOs by name, id, address...'"
      />

      <i class="fa fa-spinner fa-pulse" *ngIf="isSearching"></i>
    </div>

    <div class="input-group-append" *ngIf="inline">
      <a
        class="btn btn-secondary"
        href="/affiliation/detail/{{ affiliation._id }}"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="Open institution in a new tab"
        *ngIf="affiliation?._id"
      >
        <i class="fa fa-external-link dirt-link-pointer" aria-hidden="true"></i>
      </a>

      <!-- Can add more buttons to the group if needed -->
      <ng-content></ng-content>
    </div>
  </div>

  <ng-container *ngIf="!!creationRequestHandler">
    <a href="javascript:void(0)" class="small pull-right pt-1 pr-2" (click)="onRequestAffiliation()"> Request HCO </a>

    <br />
  </ng-container>
</span>

<!-- SearchAutoComplete Result Template-->
<ng-template #searchResultTemplate let-r="result" let-t="term">
  <ng-container>
    <div class="di-sac-affiliation pull-left">
      <div class="di-sac-name">{{ r?.name }}</div>
      <div class="di-sac-department">{{ r?.fullName || '&mdash;' }}</div>
      <div class="di-sac-address">{{ r?.address || '&mdash;' }}</div>
    </div>
    <div class="di-sac-status pull-right">
      <span class="badge badge-warning" title="Not yet worked by central HCO team" *ngIf="r._meta?.status !== 'DONE'">
        Unpolished
      </span>
    </div>
  </ng-container>
</ng-template>
