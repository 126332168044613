<div class="modal-header" *ngIf="configuration?.title !== undefined">
  <h5 class="modal-title">
    {{ configuration.title }}
  </h5>
  <button type="button" class="close" aria-label="Close" (click)="onDismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <p *ngIf="isStringContent">{{ configuration.content }}</p>
  <ng-template #dynamic></ng-template>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="onCancel(); $event.stopPropagation()">
    <span>
      {{ configuration.cancelText ?? 'Cancel' }}
    </span>
  </button>
  <button type="submit" class="btn btn-primary" (click)="onContinueAnyway(); $event.stopPropagation()">
    <span>
      {{ configuration.confirmText ?? 'Okay' }}
    </span>
  </button>
</div>
