<div class="clearfix">
  <form #ngForm="ngForm" novalidate>
    <!-- Name -->
    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2">Standardized Name</label>
      <div class="col-sm-8 col-md-10">
        <input
          type="text"
          class="form-control"
          name="name"
          [(ngModel)]="model.name"
          (ngModelChange)="model.name = longDash($event)"
          [readonly]="!isFieldEditable('name')"
          autofocus
          required
        />
      </div>
    </div>

    <!-- Original Name -->
    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2">Original Name</label>
      <div class="col-sm-8 col-md-10">
        <input
          type="text"
          class="form-control"
          name="originalName"
          [(ngModel)]="model.originalName"
          (ngModelChange)="model.originalName = longDash($event)"
          [readonly]="!isFieldEditable('originalName')"
        />
      </div>
    </div>

    <!-- Websources -->
    <div class="form-group row" *ngFor="let _ of model.websources; let idx = index; trackBy: trackByIndex">
      <label class="col-form-label col-sm-4 col-md-2" for="websources-{{ idx }}">Websource</label>
      <div class="col-sm-8 col-md-10">
        <dirt-link
          [(url)]="model.websources[idx]"
          name="websources-{{ idx }}"
          [form]="ngForm"
          [disabled]="!isFieldEditable('websources')"
          [required]="idx === 0"
        >
          <button
            type="button"
            class="btn btn-secondary"
            (click)="pushItemToList(model.websources)"
            *ngIf="idx == model.websources.length - 1 && isFieldEditable('websources')"
          >
            <i class="fa fa-plus-circle"></i>
          </button>

          <button
            type="button"
            class="btn btn-secondary"
            (click)="removeFromByIndex(model.websources, idx)"
            *ngIf="idx > 0 && isFieldEditable('websources')"
          >
            <i class="fa fa-minus-circle"></i>
          </button>
        </dirt-link>
      </div>
    </div>

    <!-- Website URL -->
    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2">Website URL</label>
      <div class="col-sm-8 col-md-10">
        <dirt-link
          [(url)]="model.website"
          name="website"
          [form]="ngForm"
          [disabled]="!isFieldEditable('website')"
        ></dirt-link>
      </div>
    </div>

    <!-- Country -->
    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2">Country</label>
      <div class="col-md-3">
        <select
          class="form-control custom-select"
          name="country"
          [(ngModel)]="model.country"
          [disabled]="!isFieldEditable('country')"
          required
        >
          <option *ngFor="let country of countries$ | async" [value]="country.code">
            {{ country.title }}
          </option>
        </select>
      </div>
    </div>

    <!-- Format -->
    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2">Format</label>
      <div class="col-md-3">
        <select
          class="form-control custom-select"
          name="format"
          [(ngModel)]="model.format"
          [disabled]="!isFieldEditable('format')"
          required
        >
          <option *ngFor="let committeeFormat of committeeFormats$ | async" [value]="committeeFormat.code">
            {{ committeeFormat.title }}
          </option>
        </select>
      </div>
    </div>

    <!-- Type -->
    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2">Type</label>
      <div class="col-md-3">
        <select
          class="form-control custom-select"
          name="type"
          [(ngModel)]="model.type"
          [disabled]="!isFieldEditable('type')"
          required
        >
          <option *ngFor="let type of committeeTypes$ | async" [value]="type.code">
            {{ type.title }}
          </option>
        </select>
      </div>
    </div>

    <!-- Topic -->
    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2">Topic</label>
      <div class="col-md-3">
        <select
          class="form-control custom-select"
          name="topic"
          [(ngModel)]="model.topic"
          [disabled]="!isFieldEditable('topic')"
          required
        >
          <option *ngFor="let topic of topics$ | async" [value]="topic.code">
            {{ topic.title }}
          </option>
        </select>
      </div>
    </div>

    <!-- Therapeutic Areas -->
    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2">Therapeutic Areas</label>
      <div class="col-md-3">
        <dirt-multiselect-dropdown
          name="therapeuticAreas"
          [ngClass]="{ disabled: !isFieldEditable('therapeuticAreas') }"
          [options]="therapeuticAreas$ | async"
          [settings]="therapeuticAreasSettings"
          [(ngModel)]="model.therapeuticAreas"
        >
        </dirt-multiselect-dropdown>
      </div>
    </div>

    <!-- Status -->
    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2">Status</label>
      <div class="col-md-3">
        <select
          class="form-control custom-select"
          name="status"
          [(ngModel)]="model.status"
          (ngModelChange)="onStatusChange()"
          [disabled]="!isFieldEditable('status')"
          required
        >
          <option *ngFor="let status of committeeStatuses$ | async" [value]="status.code">
            {{ status.title }}
          </option>
        </select>
      </div>
    </div>

    <!-- Status Websource -->
    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2">Status Websource</label>
      <div class="col-sm-8 col-md-10">
        <dirt-link
          [(url)]="model.statusWebSource"
          name="statusWebSource"
          [form]="ngForm"
          [disabled]="!isFieldEditable('statusWebSource')"
        ></dirt-link>
      </div>
    </div>

    <!-- Account Connections -->
    <div class="form-group row" [hidden]="model.type === 'EXTERNAL'">
      <label class="col-form-label col-sm-4 col-md-2">Account Connections</label>
      <div class="col-sm-8 col-md-10">
        <dirt-committee-connections
          [connections]="accountConnections"
          [disabled]="!isFieldEditable('connections')"
          [searchFunction]="onSearchAccountConnection"
          #accountConnectionsComponent="committeeConnectionsComponent"
        >
        </dirt-committee-connections>
      </div>
    </div>
  </form>
</div>
