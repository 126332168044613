import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { EMPTY, of, throwError } from 'rxjs';
import { tap, concatMap, take, map, catchError } from 'rxjs/operators';

import { ACL } from '../../shared/acl/acl.service';
import { Auth } from '../../shared/services/auth/auth.service';
import { Document } from '../shared/document';
import { DocumentAPI } from '../shared/document-api.service';
import { DocumentMetaStatus } from '../shared/constant/meta-status.enum';
import { Job } from '../../jobs/shared/job';
import { JobsAPI } from '../../jobs/shared/api.service';
import { User } from '../../user/shared/user';
import { DocumentPersonAPI } from '../shared/document-person-api.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'dirt-document-detail',
  templateUrl: './detail.component.html',
})
export class DocumentDetailComponent implements OnInit {
  id: string;

  document: Document;

  isLoading = true;

  isSubmitting = false;

  isSavingStatus = false;

  isFormValid: boolean;

  statuses = DocumentMetaStatus;

  assigneeStatuses = [];

  disabledStatuses = [
    DocumentMetaStatus.READY_FOR_COMPILATION, // Going back does nothing
  ];

  isPriorityEditable: boolean;

  canSaveAnyway: boolean;

  canSubmitNonJob: boolean;

  canSubmitJob: boolean;

  canCreateComments: boolean;

  hasJobForCurrentUser: boolean;

  currentJob: Job;

  connectedKPsCount: number;

  private currentUser: User;

  private wndw: Window = window; // allow for testing

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private svcAcl: ACL,
    private svcAuth: Auth,
    private svcJob: JobsAPI,
    private svcDocumentPerson: DocumentPersonAPI,
    public svcDocument: DocumentAPI, // used in template too
    private titleService: Title
  ) {}

  ngOnInit(): void {
    this.canSaveAnyway = this.svcAcl.hasCredential('document.saveAnyway'); // allow saving despite remaining form validation issues
    this.canSubmitNonJob = this.svcAcl.hasCredential('document.update');
    this.canSubmitJob = this.svcAcl.hasCredential('job.submit');
    this.isPriorityEditable = this.svcAcl.hasCredential('document.update.prop.priority');
    this.canCreateComments = this.svcAcl.hasCredential('document.comment.create');

    this.route.params
      .pipe(
        take(1),
        tap(() => {
          this.isLoading = true;

          // Make sure we cannot submit something we shouldn't under the pretense that we are working a job
          this.currentJob = null;
          this.hasJobForCurrentUser = false;
        }),
        concatMap((params) => {
          window.scrollTo(0, 0);

          this.id = params['id'];

          return this.svcDocument.findById(this.id).pipe(
            tap((document) => {
              this.document = document;
              this.titleService.setTitle(`cLean | Document | ${this.document.title}`);
            })
          );
        }),
        concatMap(() => {
          if (this.currentUser) {
            // We already know current user, don't waste time fetching again
            return of(this.currentUser);
          }

          return this.svcAuth.getProfile().pipe(tap((user) => (this.currentUser = user)));
        }),
        concatMap(() => {
          // Only get job details if user is the one working it
          if (!(this.document._meta?.jobId && this.document._meta?.assignee === this.currentUser.user_id)) {
            return of(null);
          }

          return this.svcJob.findById(this.document._meta.jobId).pipe(
            tap((job) => {
              if (job) {
                this.currentJob = job;
                this.hasJobForCurrentUser =
                  this.svcAcl.hasCredential('job.submit') &&
                  this.document._meta.jobId &&
                  this.currentUser.user_id === this.document._meta.assignee;
                if (!this.isFormRelevant()) {
                  this.isFormValid = true;
                }
              }
            })
          );
        }),
        concatMap(() => this.svcDocumentPerson.count(this.id).pipe(tap((res) => (this.connectedKPsCount = res.count)))),
        tap(() => (this.isLoading = false))
      )
      .subscribe();
  }

  goBack(): void {
    const link = ['/document'];
    this.router.navigate(link);
  }

  onSave(document: Document): void {
    this.updateDocument(document);
  }

  onStatusChange(_meta: any): void {
    if (!this.svcAcl.hasCredential('document.update.prop.meta.status')) {
      return;
    }

    this.isSavingStatus = true;

    this.svcDocument
      .update(this.document._id, { _meta })
      .pipe(tap(() => (this.isSavingStatus = false)))
      .subscribe((document) => {
        Object.assign(this.document, document);
      });
  }

  onFormValidityChange(status: string): void {
    setTimeout(() => (this.isFormValid = !this.isFormRelevant() || status === 'VALID'));
  }

  onSetPriority(document: Document, priority: number): void {
    if (!priority || (document.priority && document.priority === priority)) {
      return;
    }

    this.svcDocument.update(document._id, { priority } as Document).subscribe((document) => {
      Object.assign(this.document, document);
    });
  }

  onJobUtc(): void {
    if (!this.hasJobForCurrentUser) {
      return;
    }

    let comment = '';
    while (comment.length < 3) {
      comment = this.wndw.prompt('Enter a comment for Unable to Compile...');
      if (null === comment) {
        return;
      }
    }

    this.isSubmitting = true;

    this.svcJob
      .setUtc(this.currentJob._id, comment)
      .pipe(tap(() => (this.isSubmitting = false)))
      .subscribe(() => {
        this.goBack();
      });
  }

  async onJobDraft(): Promise<void> {
    if (!this.hasJobForCurrentUser) {
      return;
    }

    this.isSubmitting = true;
    this.svcDocument
      .update(this.document._id, this.document)
      .pipe(
        map(() => true),
        catchError((error) => {
          if (error.status === 412 && error.error?.potentialDuplicate) {
            return of(confirm(`Found potential duplicate: ${error.error.potentialDuplicate}. Save anyway?`)).pipe(
              concatMap((confirmed) => {
                if (confirmed) {
                  return this.svcDocument.update(this.document._id, this.document, true);
                } else {
                  this.isSubmitting = false;
                  return EMPTY; // Do not emit anything if not confirmed. We don't want to save
                }
              })
            );
          }

          return throwError(() => error);
        }),
        concatMap((res) => {
          if (res) {
            // Execute this.svcJob.setDraft only if there was no error or if the user confirmed
            return this.svcJob.setDraft(this.currentJob._id);
          } else {
            return EMPTY; // Skip this.svcJob.setDraft if the user canceled
          }
        }),
        tap(() => (this.isSubmitting = false))
      )
      .subscribe((res) => {
        if (res) {
          // Go back if there was no error or if the user confirmed
          this.goBack();
        }
      });
  }

  onSubmitJob(document: Document): void {
    if (!this.hasJobForCurrentUser || (!this.isFormValid && !this.canSaveAnyway)) {
      return;
    }
    if (
      !this.isFormValid &&
      this.canSaveAnyway &&
      !this.wndw.confirm('There are validation errors left. Sure you want to submit?')
    ) {
      return;
    }

    this.isSubmitting = true;

    this.svcDocument
      .submitJob(document)
      .pipe(
        map(() => true),
        catchError((error) => {
          if (error.status === 412 && error.error?.potentialDuplicate) {
            return of(confirm(`Found potential duplicate: ${error.error.potentialDuplicate}. Save anyway?`)).pipe(
              concatMap((confirmed) => {
                if (confirmed) {
                  return this.svcDocument.submitJob(document, true);
                }
                return of(null);
              })
            );
          }

          return throwError(() => error);
        }),
        tap(() => (this.isSubmitting = false))
      )
      .subscribe((res) => {
        if (!res) {
          return;
        }

        this.goBack();
      });
  }

  onCopyID(id: string): void {
    navigator.clipboard.writeText(id);
  }

  isClaimedByOtherUser(): boolean {
    // actual valid use of call from template to handle expiration
    const hasValidClaim = this.document._meta.claimedUntil && new Date(this.document._meta.claimedUntil) > new Date();
    const isClaimedByUser = this.document._meta.assignee && this.document._meta.assignee !== this.currentUser.user_id;

    return hasValidClaim && isClaimedByUser;
  }

  private updateDocument(document: Document): void {
    if (!this.canSubmitNonJob || (!this.isFormValid && !this.canSaveAnyway)) {
      return;
    }
    if (
      !this.isFormValid &&
      this.canSaveAnyway &&
      !this.wndw.confirm('There are validation errors left. Sure you want to submit?')
    ) {
      return;
    }

    this.isSubmitting = true;
    this.svcDocument
      .update(this.id, document)
      .pipe(
        map(() => true),
        catchError((error) => {
          if (error.status === 412 && error.error?.potentialDuplicate) {
            return of(confirm(`Found potential duplicate: ${error.error.potentialDuplicate}. Save anyway?`)).pipe(
              concatMap((confirmed) => {
                if (confirmed) {
                  return this.svcDocument.update(this.id, document, true);
                }
                return of(null);
              })
            );
          }

          return throwError(() => error);
        }),
        tap(() => (this.isSubmitting = false))
      )
      .subscribe((res) => {
        if (!res) {
          return;
        }

        this.goBack();
      });
  }

  private isFormRelevant(): boolean {
    return !this.currentJob?.type?.startsWith('DOCUMENT_MAPPING'); // we only care about KOLs; TODO: make this part of job permissions def
  }
}
