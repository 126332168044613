<section class="dirt-section-list dirt-affiliation-list">
  <header class="row">
    <div class="col-sm-4">
      <h1>
        <span *ngIf="total">{{ total.count | number }}</span> Institutions
      </h1>
    </div>
    <div class="col-sm-4" *ngIf="!advancedSearchSpec">
      <input
        type="search"
        class="di-search form-control ng-trim-ignore"
        [formControl]="searchCtrl"
        [(ngModel)]="searchTerm"
        placeholder="Search..."
      />
      <small><a class="alink" (click)="openAdvancedSearch()">Advanced search</a></small>
    </div>
    <div class="col-sm-4" *ngIf="advancedSearchSpec">
      <small
        ><a class="alink" (click)="openAdvancedSearch()">Change search</a>
        <a class="alink" (click)="clearAdvancedSearch()">Clear search</a></small
      >
    </div>
    <dialog class="advanced-search-dialog" #advancedSearchDialog (keyup.enter)="runAdvancedSearch()">
      <dirt-affiliation-advanced-search
        *ngIf="advancedSearchSpec"
        [model]="advancedSearchSpec"
      ></dirt-affiliation-advanced-search>
      <hr />
      <div class="pull-right">
        <button class="btn" (click)="advancedSearchDialogElement.nativeElement.close()">Cancel</button>
        <button class="btn" (click)="clearAdvancedSearch()">Clear</button>
        <button class="btn btn-primary" (click)="runAdvancedSearch()">GO</button>
      </div>
    </dialog>
    <div class="col-sm-4">
      <div class="pull-right">
        <!-- Filter -->
        <dirt-filters *ngIf="!advancedSearchSpec" [filters]="filters" (onFilter)="onFilter($event)"></dirt-filters>

        <!-- Create Button -->
        <button
          class="btn btn-danger btn-sm"
          (click)="navigateTo(['/affiliation/create'])"
          *diAcl="'affiliation.create'"
          title="Use the organizations module"
          disabled
        >
          <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
          &nbsp; Create Institution
        </button>
      </div>
    </div>
  </header>

  <!-- Filter Bar -->
  <dirt-filters-bar
    [filter]="filter"
    [config]="filterBarConfig"
    (filterClear)="onFilterClear()"
    (filterValueRemove)="onFilterValueRemove($event)"
  ></dirt-filters-bar>

  <div class="pull-right">
    <!-- Export -->
    <button class="btn btn-secondary btn-sm" (click)="onExport()" *diAcl="'affiliation.export'">
      <i class="fa fa-download" aria-hidden="true"></i>&nbsp; Export List
    </button>
  </div>

  <!-- Pagination -->
  <dirt-pagination
    class="dirt-pagination"
    [id]="'dirt-affiliation-list'"
    [pagingSize]="affiliations?.length || 0"
    [pagingSkip]="pagingSkip"
    [pagingLimit]="pagingLimit"
    [pagingTotal]="total?.count || 0"
    [hidden]="pagingLimit >= total?.count"
    (pageChange)="getPage($event)"
  >
  </dirt-pagination>

  <!-- Table -->
  <div *ngIf="affiliations" [hidden]="isLoading">
    <table class="table table-sm table-hover" [hidden]="!affiliations.length" (diSort)="onSort($event)">
      <thead>
        <tr>
          <th class="col-name" [diColSorter]="'opName'">Name</th>
          <th class="col-department"></th>
          <th class="col-original-department">Original department</th>
          <th class="col-state" [diColSorter]="'address.opState'">State</th>
          <th class="col-city" [diColSorter]="'address.opCity'">City</th>
          <th class="col-street">Street</th>
          <th class="col-zip">Zip</th>
          <th class="col-country" [diColSorter]="'address.opCountry'">Country</th>
          <th class="col-created-at">Created at</th>
          <th class="col-actions">&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        <ng-container
          *ngFor="
            let affiliation of affiliations
              | paginate
                : {
                    id: 'dirt-affiliation-list',
                    itemsPerPage: pagingLimit,
                    currentPage: pagingPage,
                    totalItems: total?.count
                  }
          "
        >
          <tr class="dirt-affiliation-row">
            <td class="col-name" colspan="10">
              {{ affiliation.name }}
              <button class="btn btn-copy" (click)="onCopyID(affiliation.id, $event)">
                <i class="fa fa-copy" aria-hidden="true" [openDelay]="750" [closeDelay]="750" ngbTooltip="Copied"></i>
              </button>
            </td>
          </tr>
          <tr class="dirt-affiliation-row" *ngIf="affiliation.originalName">
            <td class="col-original-name" colspan="10">{{ affiliation.originalName }}</td>
          </tr>
          <ng-container *ngFor="let department of affiliation.departments">
            <tr class="dirt-departments-row" (click)="navigateTo(['/affiliation/detail', department.id], $event)">
              <td class="col-department" colspan="2">
                <div class="di-icons d-inline-flex justify-content-between align-items-center flex-row-reverse">
                  <ng-container [ngSwitch]="department.type">
                    <span
                      class="di-icon"
                      *ngSwitchCase="'HOSPITAL'"
                      ngbTooltip="Hospital"
                      placement="top"
                      aria-hidden="true"
                      >&#9405;</span
                    >
                    <span
                      class="di-icon"
                      *ngSwitchCase="'PRIVATE_PRACTICE'"
                      ngbTooltip="Private Practice"
                      placement="top"
                      aria-hidden="true"
                      >&#9413;</span
                    >
                    <span
                      class="di-icon"
                      *ngSwitchCase="'UNIVERSITY_RESEARCH_INST'"
                      ngbTooltip="University / Research Institution"
                      placement="top"
                      aria-hidden="true"
                      >&#9418;</span
                    >
                    <span
                      class="di-icon"
                      *ngSwitchCase="'ASSOCIATION'"
                      ngbTooltip="Association"
                      placement="top"
                      aria-hidden="true"
                      >&#9398;</span
                    >
                    <span
                      class="di-icon"
                      *ngSwitchCase="'INDUSTRY'"
                      ngbTooltip="Industry"
                      placement="top"
                      aria-hidden="true"
                      >&#9406;</span
                    >
                    <span
                      class="di-icon"
                      *ngSwitchCase="'HEALTH_PAYER'"
                      ngbTooltip="Health Payer"
                      placement="top"
                      aria-hidden="true"
                      >&#9416;</span
                    >
                    <span class="di-icon" *ngSwitchCase="'OTHER'" ngbTooltip="Other" placement="top" aria-hidden="true"
                      >&#9412;</span
                    >
                    <span *ngSwitchDefault></span>
                  </ng-container>
                  <span
                    *ngIf="department.sourceLfka"
                    ngbTooltip="created by / for LFKA"
                    class="badge badge-info"
                    style="font-size: 7px"
                    >LFKA</span
                  >
                  <span
                    *ngIf="department.source === 'OPEN_DATA'"
                    ngbTooltip="automated institution"
                    class="badge badge-info"
                    style="font-size: 7px"
                    >Auto</span
                  >
                  <span
                    *ngIf="department._meta?.status === 'PENDING_REMOVAL'"
                    ngbTooltip="Pending Removal"
                    class="badge badge-danger"
                    style="font-size: 7px"
                    >PR</span
                  >
                  <dirt-verified-icon
                    color="success"
                    *ngIf="department.verified"
                    ngbTooltip="Verified"
                    placement="top"
                    aria-hidden="true"
                  >
                  </dirt-verified-icon>
                </div>
                <span [ngClass]="{ 'dirt-primary': !department.department?.trim() }" [title]="department.department">
                  <a [routerLink]="['/affiliation/detail', department.id]">{{ department.department || 'Primary' }}</a>
                </span>
                <button class="btn btn-copy" (click)="onCopyID(department.id, $event)">
                  <i class="fa fa-copy" aria-hidden="true" [openDelay]="750" [closeDelay]="750" ngbTooltip="Copied"></i>
                </button>
                <span
                  *ngIf="!department.readyForDelivery && department._meta?.status !== 'PENDING_REMOVAL'"
                  title="Not yet worked by central institution team"
                  class="badge badge-warning"
                  >Unpolished</span
                >
                <span *ngIf="department.hasSite" class="badge badge-info">Site</span>
              </td>
              <td class="col-original-department" [title]="department.originalDepartment">
                {{ department.originalDepartment }}
              </td>
              <td class="col-state" [title]="department.address?.state">{{ department.address?.state }}</td>
              <td class="col-city" [title]="department.address?.city">{{ department.address?.city }}</td>
              <td class="col-street" [title]="department.address?.name">{{ department.address?.name }}</td>
              <td class="col-zip" [title]="department.address?.zip">{{ department.address?.zip }}</td>
              <td class="col-country" [title]="department.address?.country">
                {{ getCountryTitle(department.address?.countryCode) }}
              </td>
              <td class="col-created-at" [title]="department.opCreatedAt">
                {{ department.opCreatedAt | date : 'dd.MM.y' }}
              </td>
              <td class="col-actions">
                <!-- Delete -->
                <ng-container *ngIf="department._meta?.status !== 'PENDING_REMOVAL'">
                  <span
                    class="action-btn action-delete"
                    (click)="onDelete(department.id, $event)"
                    *diAcl="'affiliation.delete'"
                  >
                    <i class="fa fa-trash-o" aria-hidden="true"></i>
                  </span>
                </ng-container>
              </td>
            </tr>
          </ng-container>
        </ng-container>
      </tbody>
    </table>

    <!-- Empty Message -->
    <p class="dirt-no-results" *ngIf="!affiliations.length">No Institutions</p>
  </div>

  <!-- Pagination -->
  <dirt-pagination
    class="dirt-pagination"
    [id]="'dirt-affiliation-list'"
    [pagingSize]="affiliations?.length || 0"
    [pagingSkip]="pagingSkip"
    [pagingLimit]="pagingLimit"
    [pagingTotal]="total?.count || 0"
    [hidden]="isLoading || pagingLimit >= total?.count"
    (pageChange)="getPage($event)"
  >
  </dirt-pagination>

  <!-- Loading Message -->
  <p class="dirt-loading" *ngIf="isLoading">
    <i class="fa fa-refresh fa-spin fa-2x" aria-hidden="true"></i>
    <span class="sr-only">Loading...</span>
  </p>
</section>
