import { catchError, debounceTime, distinctUntilChanged, Observable, of, switchMap, take, tap } from 'rxjs';
import { Component, EventEmitter, Input, Output } from '@angular/core';

import { Organization } from '../../../organizations/shared/organization';
import { OrganizationAPI, OrganizationSearchResultDTO } from '../../../organizations/shared/api.service';
import { OrganizationSelectModalService } from '../../../organizations/shared/select-modal/select-modal.service';

@Component({
  selector: 'dirt-organization-single-select',
  templateUrl: 'organization-single-select.component.html',
  styleUrls: ['./organization-single-select.component.scss'],
})
export class OrganizationSingleSelectComponent {
  @Input()
  organization?: Organization;

  @Output()
  organizationSelected = new EventEmitter<Organization | null>();

  /** If true, will include non DONE organizations in search results */
  @Input()
  includeUnpolished = false;

  @Input()
  disabled = false;

  @Input()
  required = false;

  @Input()
  clearAfterSelect = false;

  @Input()
  creationRequestHandler?: (organization?: Organization) => Observable<Organization>;

  inputModel: string = '';

  isSearching = false;

  constructor(
    protected readonly svcOrganization: OrganizationAPI,
    protected readonly svcOrganizationTreeModal: OrganizationSelectModalService
  ) {
    this.searchOrganizations = this.searchOrganizations.bind(this);
    this.formatTitle = this.formatTitle.bind(this);
  }

  searchOrganizations(text$: Observable<string>): Observable<OrganizationSearchResultDTO[]> {
    return text$.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      tap(() => (this.isSearching = true)),
      switchMap((term) => {
        if (!term) {
          return of([]);
        }

        return this.svcOrganization.search(term, 100, undefined, this.includeUnpolished).pipe(
          take(1),
          catchError(() => {
            return of([]);
          })
        );
      }),
      tap(() => (this.isSearching = false))
    );
  }

  onChangeOrganization(organization: Organization): void {
    this.organizationSelected.emit(organization);

    if (this.clearAfterSelect) {
      setTimeout(() => (this.organization = null)); // (next apply)
    } else {
      this.organization = organization;
    }
  }

  onChangeOrganizationInline(newValue: string): void {
    if (!newValue || !newValue?.toString()?.trim()) {
      this.organizationSelected.emit(null); // value cleared
    }
  }

  onRequestOrganization(organization?: Organization): void {
    if (!this.creationRequestHandler) {
      return;
    }

    this.creationRequestHandler(organization)
      .pipe(take(1))
      .subscribe((organization) => {
        this.onChangeOrganization(organization);
      });
  }

  async onOpenTree(event: MouseEvent = null, organizationId: string): Promise<void> {
    if (event) {
      event.stopPropagation();
    }

    try {
      const res = await this.svcOrganizationTreeModal.open(organizationId);

      this.onChangeOrganization(res.organization);
    } catch (error) {
      // The modal was closed
      return;
    }
  }

  formatTitle(organization: Organization): string {
    if (this.clearAfterSelect) {
      return '';
    }

    return organization.fullName;
  }
}
