import { NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';
import { NgModule } from '@angular/core';

import { AccountModule } from '../account/account.module';
import { CommentModule } from '../comment/comment.module';
import { CommitteeAPI } from './shared/committee-api.service';
import { CommitteeComponent } from './committee.component';
import { CommitteeConnectionsComponent } from './shared/connection/connections.component';
import { CommitteeCreateComponent } from './create/create.component';
import { CommitteeDetailComponent } from './detail/detail.component';
import { CommitteeFormComponent } from './shared/form/form.component';
import { CommitteeListComponent } from './list/list.component';
import { CommitteePersonAPI } from './shared/committee-person-api.service';
import { CommitteePersonListComponent } from './person-list/person-list.component';
import { CommitteePersonModalComponent } from './person-modal/person-modal.component';
import { CommitteePersonModalService } from './person-modal/person-modal.service';
import { CommitteeRestrictedListComponent } from './shared/restricted-list/restricted-list.component';
import { committeeRouting } from './committee.routes';
import { SharedModule } from '../shared/shared.module';
import { CommitteeSingleSelectComponent } from './shared/components/committee-single-select/committee-single-select';

@NgModule({
  imports: [SharedModule, CommentModule, NgbAccordionModule, AccountModule, committeeRouting],
  declarations: [
    CommitteeComponent,
    CommitteeListComponent,
    CommitteeCreateComponent,
    CommitteeDetailComponent,
    CommitteeFormComponent,
    CommitteeConnectionsComponent,
    CommitteePersonListComponent,
    CommitteeRestrictedListComponent,
    CommitteePersonModalComponent,
    CommitteeSingleSelectComponent,
  ],
  exports: [CommitteeRestrictedListComponent, CommitteeSingleSelectComponent],
  providers: [CommitteeAPI, CommitteePersonAPI, CommitteePersonModalService],
})
export class CommitteeModule {}
