<div class="modal-header">
  <h4 class="modal-title">Request Organization</h4>

  <button type="button" class="close" aria-label="Close" (click)="onCancel()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body row">
  <div class="col-8">
    <form #form="ngForm" novalidate>
      <!-- Parent -->
      <div class="form-group row">
        <label class="col-form-label col-sm-4 col-md-2">Parent Organization</label>
        <div class="col-sm-8 col-md-10">
          <dirt-organization-single-select
            (organizationSelected)="onParentSelected($event)"
          ></dirt-organization-single-select>
        </div>
      </div>

      <!-- Name -->
      <div class="form-group row">
        <label class="col-form-label col-sm-4 col-md-2">Name</label>
        <div class="col-sm-8 col-md-10">
          <input type="text" class="form-control" name="name" [(ngModel)]="organization.name" required />
        </div>
      </div>

      <!-- Websource -->
      <div class="form-group row">
        <label class="col-form-label col-sm-4 col-md-2">Websource</label>
        <div class="col-sm-8 col-md-10">
          <dirt-link [(url)]="organization.websource" name="websource" [form]="form" [required]="true"></dirt-link>
        </div>
      </div>

      <!-- Address -->
      <div class="form-group row">
        <label class="col-form-label col-sm-4 col-md-2">Address</label>
        <div class="col-sm-8 col-md-10">
          <dirt-address-lookup
            [preferredOrgId]="organization.parents?.[0]?._id"
            (addressChange)="onAddressSelected($event)"
            *ngIf="!addressDetails; else addressCard"
            [required]="isAddressRequired()"
          ></dirt-address-lookup>

          <!-- Details -->
          <ng-template #addressCard>
            <dirt-address-tile
              [organizationAddress]="organization.addresses[0]"
              [address]="addressDetails"
              [disabled]="true"
            ></dirt-address-tile>
            <div class="small-dist">
              <a class="alink" (click)="onRemoveAddress()">Remove Address</a>
            </div>
          </ng-template>
          <a href="javascript:void(0)" (click)="onOpenCreateAddressModal()">Create Address</a>
        </div>
      </div>

      <hr />

      <!-- For customer request -->
      <div class="form-group row">
        <label class="col-form-label col-sm-4 col-md-2">For customer request</label>
        <div class="col-sm-8 col-md-10">
          <ui-switch
            size="small"
            name="customerRequest"
            [(ngModel)]="organization.usageInfo.customerRequest"
            [disabled]="!!isCustomerRequest"
          ></ui-switch>
        </div>
      </div>

      <!-- Comment -->
      <div class="form-group row">
        <label class="col-form-label col-sm-4 col-md-2">Comment</label>
        <div class="col-sm-8 col-md-10">
          <div class="input-group">
            <input
              type="text"
              class="form-control"
              name="usageInfoComment"
              [(ngModel)]="organization.usageInfo.comment"
              required
            />
          </div>
        </div>
      </div>

      <!-- Owner Product (only if not provided by default) -->
      <div class="form-group row" *ngIf="!defaultOwnerProduct">
        <label class="col-form-label col-sm-4 col-md-2">Owner product</label>
        <div class="col-sm-8 col-md-10">
          <select
            class="form-control custom-select"
            name="ownerProduct"
            [(ngModel)]="organization.requestOwnerProduct"
            required
          >
            <option *ngFor="let product of products$ | async" [value]="product.value">
              {{ product.value }}
            </option>
          </select>
        </div>
      </div>
    </form>
  </div>

  <div class="col-4">
    <div class="dirt-duplicate-list" *ngIf="duplicates?.length > 0">
      <!-- Exact duplicates -->
      <ng-container *ngIf="state === 'exact-duplicate'">
        <div class="dirt-duplicate-list-heading">The following duplicates were found:</div>
        <ul>
          <li *ngFor="let duplicate of duplicates">
            <a href="/organization/detail/{{ duplicate._id }}" target="_blank">{{ duplicate.name }}</a>
            <span *ngIf="duplicate.parents.length > 0">
              - {{ duplicate.parents.length === 1 ? 'Parent' : 'Parents' }}:
              <a
                *ngFor="let duplicateParent of duplicate.parents"
                href="/organization/detail/{{ duplicateParent }}"
                target="_blank"
                >{{ duplicateParent }}</a
              >
            </span>
          </li>
        </ul>
      </ng-container>

      <!-- Fuzzy duplicates -->
      <ng-container *ngIf="state === 'fuzzy-duplicate'">
        <div class="dirt-duplicate-list-heading">The following suggestions were found:</div>
        <ul>
          <li *ngFor="let duplicate of duplicates">
            <a href="/organization/detail/{{ duplicate._id }}" target="_blank">{{ duplicate.name }}</a>
          </li>
        </ul>
      </ng-container>
    </div>
  </div>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-secondary" [disabled]="isSubmitting" (click)="onCancel()">Cancel</button>

  <button
    type="submit"
    class="btn btn-primary"
    [ngClass]="{ 'btn-loading': isSubmitting }"
    [disabled]="!form.valid || isAddressRequired() || isSubmitting || state === 'exact-duplicate'"
    (click)="onSubmit(duplicates?.length > 0)"
  >
    <span *ngIf="duplicates?.length > 0 && state === 'exact-duplicate'" title="Please select a duplicate"
      >Please select a duplicate</span
    >
    <span *ngIf="!(duplicates?.length > 0)" title="Will check for duplicates">Create</span>
    <span *ngIf="duplicates?.length > 0 && state === 'fuzzy-duplicate'" title="Create despite duplicates"
      >Create anyway</span
    >
  </button>
</div>
