<div class="modal-header">
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body row">
  <div class="col-4 tree">
    <dirt-organization-tree-simple
      *ngIf="root"
      [root]="root"
      [selectedId]="selectedId"
      [organizationInfo]="organizationInfo"
      (selectedIdChange)="onSelectedIdChange($event)"
    ></dirt-organization-tree-simple>
  </div>

  <div class="col-8">
    <ng-container *ngIf="!isLoading; else loader">
      <ng-container *ngIf="selectedOrganization">
        <dirt-organization-detail-form
          [organization]="selectedOrganization"
          [formReadonly]="true"
          [displayExternalOrganization]="selectedOrganization.root !== root._id"
          [organizationInfo]="organizationInfo"
        >
        </dirt-organization-detail-form>

        <!-- No point in displaying the section if there is no mapping -->
        <ng-container *ngIf="selectedOrganizationMappings?.length">
          <!-- We need to display the selected organization mappings to select the correct one -->
          <div class="organization-mappings">
            <p>Connected Institutions:</p>

            <div class="mapping-row" *ngFor="let mapping of selectedOrganizationMappings">
              <label>
                <input
                  type="radio"
                  name="selected-mapping"
                  [value]="mapping.affiliationId"
                  [(ngModel)]="selectedAffiliationId"
                  *ngIf="canSelectMapping"
                />
                <a
                  [routerLink]="['/affiliation/detail', mapping.affiliationId]"
                  target="_blank"
                  rel="noopener noreferrer"
                  >{{ mapping.affiliationName
                  }}{{ mapping.affiliationDepartment ? '-' + mapping.affiliationDepartment : '' }}</a
                >
              </label>
            </div>
          </div>

          <!-- We need to display the selected organization addresses to select the correct one -->
          <div class="organization-addresses" *ngIf="selectedOrganizationAddresses?.length">
            <p>Connected Addresses:</p>

            <div class="mapping-row" *ngFor="let address of selectedOrganizationAddresses">
              <label>
                <input
                  type="radio"
                  name="selected-address"
                  [value]="address"
                  [(ngModel)]="selectedAddress"
                  *ngIf="canSelectAddress"
                />
                {{ address.formatted }}
              </label>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>

    <!-- Loading Message -->
    <ng-template #loader>
      <p class="dirt-loading">
        <i class="fa fa-refresh fa-spin fa-2x" aria-hidden="true"></i>
        <span class="sr-only">Loading...</span>
      </p>
    </ng-template>
  </div>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="activeModal.dismiss()">Return to search</button>

  <button
    type="submit"
    class="btn btn-primary"
    title="{{
      selectedOrganization?.automated
        ? 'Automated HCO cannot be selected'
        : !selectedOrganizationMappings?.length && requireMapping
        ? 'Cannot use, HCO not connected to an institution'
        : ''
    }}"
    [disabled]="
      !selectedId ||
      (requireMapping && !selectedAffiliationId) ||
      (requireAddress && !selectedAddress) ||
      selectedOrganization?.automated
    "
    (click)="onSelect()"
  >
    Select
  </button>
</div>
