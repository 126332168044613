import { Component, EventEmitter, Input, Output } from '@angular/core';
import { take } from 'rxjs/operators';

import { ClinicalTrialSite } from '../../site';
import { ClinicalTrialProfileAPI } from '../../clinical-trial-profile-api.service';
import { OrganizationCreateRequestModalService } from '../../../../organizations/shared/create-request-modal/organization-create-request-modal.service';
import type { AugmentedOrganizationSearchResultDTO } from '../../../../shared/components/single-select-temp/organization-single-select-temp.component';
import { from } from 'rxjs';

@Component({
  selector: 'dirt-site-single-select',
  templateUrl: 'site-single-select.html',
  styleUrls: ['site-single-select.scss'],
})
export class SiteSingleSelectComponent {
  @Output()
  siteSelected: EventEmitter<ClinicalTrialSite | null> = new EventEmitter();

  // // TODO: Allow an ClinicalTrialSite selection to be initialized
  // @Input()
  // site: ClinicalTrialSite;

  @Input()
  wide: boolean = false;

  @Input()
  disabled: boolean = false;

  @Input()
  existSites: { [_id: string]: ClinicalTrialSite } = {};

  constructor(
    private readonly svcOrganizationCreateRequestModal: OrganizationCreateRequestModalService,
    private readonly svcClinicalTrialProfile: ClinicalTrialProfileAPI
  ) {
    this.showAffiliationModal = this.showAffiliationModal.bind(this);
  }

  async onChangeSite(selection: AugmentedOrganizationSearchResultDTO | null) {
    if (selection === null) {
      this.siteSelected.emit(null);
      return;
    }

    return this.svcClinicalTrialProfile
      .obtainSite(selection.affiliationId)
      .pipe(take(1))
      .subscribe((ste) => {
        this.siteSelected.emit(ste);
      });
  }

  showAffiliationModal() {
    return from(
      this.svcOrganizationCreateRequestModal
        .open()
        .then((res) => {
          return { ...res.organization, affiliationId: res.affiliation.id };
        })
        .catch(() => {})
    );
  }
}
