import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { AddressEntity } from '../../organizations/shared/address-entity';

@Component({
  selector: 'dirt-address-entity-create-modal',
  templateUrl: 'create-modal.component.html',
})
export class AddressEntityCreateModalComponent {
  address: AddressEntity = {} as any;

  isSubmitting = false;

  constructor(private activeModal: NgbActiveModal) {}

  onCancel(): void {
    this.activeModal.dismiss();
  }

  onCreate(): void {
    this.activeModal.close(this.address);
  }
}
