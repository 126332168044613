import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { OrganizationBaseInfo } from '../types/base-info';
import { OrganizationSubTree } from '../types/sub-tree';

@Component({
  selector: 'dirt-organization-tree-simple',
  templateUrl: 'tree-simple.component.html',
  styleUrls: ['tree-simple.component.scss'],
})
/**
 * Stripped down version of OrganizationTreePartComponent - only for display purpose & avoid playing with ACL everywhere
 */
export class OrganizationTreeSimpleComponent implements OnInit {
  @Input()
  root: OrganizationSubTree;

  @Input()
  /** id of the current selected organization */
  selectedId?: string;

  @Input()
  /**
   * We need the tree as a map to display some more info - since the tree will not be used without the form,
   * it doesn't make sense to recompute it here
   */
  organizationInfo: Record<string, OrganizationBaseInfo> = {};

  @Output()
  selectedIdChange = new EventEmitter<string>();

  /** contains the IDs of all expanded leaves */
  expandedIds = new Set<string>();

  ngOnInit(): void {
    this.expandById(this.selectedId);
  }

  trackById(_: number, leaf: OrganizationBaseInfo): string {
    return leaf._id;
  }

  onToggleExpanded(leaf: OrganizationBaseInfo): void {
    if (this.expandedIds.has(leaf._id)) {
      this.expandedIds.delete(leaf._id);
    } else {
      this.expandedIds.add(leaf._id);
    }
  }

  onSelect(leaf: OrganizationBaseInfo): void {
    this.selectedId = leaf._id;
    this.selectedIdChange.emit(this.selectedId);
  }

  onSelectLookupItem(org: OrganizationBaseInfo): void {
    this.expandById(org._id);
    this.onSelect(org);
  }

  private expandById(id: string): void {
    const expand = (leafId: string) => {
      // We only expand above the selected ID
      if (leafId !== id) {
        this.expandedIds.add(leafId);
      }

      const leaf = this.organizationInfo[leafId];
      if (leaf.parents?.length > 0) {
        expand(leaf.parents[0]);
      }
    };
    expand(id);
  }
}
