import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

import { FormList } from '../../shared/interfaces/form-list.interface';
import { ACL } from '../../shared/acl/acl.service';
import { Person } from '../../person/shared/person';
import { PersonAffiliationsComponent } from '../../shared/components/affiliations/affiliations.component';
import { Roles } from '../../shared/acl/roles';
import { ValueAPI } from '../../shared/services/value/value-api.service';
import { ValueType } from '../../shared/enum/value-type.enum';
import { Value } from '../../shared/services/value/value';
import { Utils } from '../../common/utils';
import { PersonAffiliationsLfkaComponent } from '../../shared/components/affiliations-lfka/affiliations-lfka.component';
import { isOnlyLFKAProject } from '../../person/shared/utils';

@Component({
  selector: 'dirt-profile-person-form',
  templateUrl: 'form.component.html',
  styleUrls: ['form.component.scss'],
  exportAs: 'frmProfilePerson',
})
export class ProfilePersonFormComponent implements OnInit, OnChanges {
  @Input('person')
  model: Person = new Person();

  @Input()
  suppressMarker: boolean = true; // per se, we're readonly now

  @Input('basePerson')
  basePerson: Person = new Person();

  @Input()
  detail: boolean;

  @ViewChild(NgForm, { static: true })
  ngForm: NgForm;

  @ViewChild(PersonAffiliationsComponent)
  affiliationsComponent: FormList;

  @ViewChild(PersonAffiliationsLfkaComponent)
  affiliationsLfkaComponent: FormList;

  @Input()
  checkFindings: { [field: string]: string[] } = {};

  suffixes: Value[] = [];
  sources: Value[] = [];
  states: Value[] = [];

  LFKA_TAGS: string[] = [];

  constructor(private svcAcl: ACL, private svcValue: ValueAPI) {}

  ngOnInit() {
    this.loadStates();
    this.initializeLists();
    this.loadLfkaTags();
    this.loadSuffixes();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.model && changes.model.currentValue) {
      // ensure collections are there - be even more defensive (same is in person/form)
      const curr = changes.model.currentValue as Person;
      if (!curr.affiliations) {
        curr.affiliations = [];
      }
      if (!curr.affiliationsLfka) {
        curr.affiliationsLfka = [];
      }
    }
  }

  isValid(): boolean {
    let res = this.ngForm.form.valid;
    if (this.affiliationsComponent) {
      res = res && this.affiliationsComponent.isValid();
    }
    if (this.affiliationsLfkaComponent) {
      res = res && this.affiliationsLfkaComponent.isValid();
    }
    return res;
  }

  invalidFields(): string[] {
    return Utils.prettyErrorFields(this.ngForm.form);
  }

  isKAMProject(): boolean {
    return !!this.model?.projectNames?.find((p) => this.LFKA_TAGS.includes(p));
  }

  isMoreThanKAMProject(): boolean {
    // has any other project than KAM ones (no project = assume standard profile)
    return !!this.model?.projectNames?.find((p) => !this.LFKA_TAGS.includes(p));
  }

  isOnlyKAMProject(): boolean {
    return isOnlyLFKAProject(this.model?.projectNames, this.LFKA_TAGS);
  }

  isFieldValid(fieldName) {
    const field = this.ngForm.controls[fieldName];
    if (field) {
      return field.valid;
    }
  }

  onKeydown(e: KeyboardEvent, value: string, maxLength: number): void {
    if (/\d/.test(e.key) && value && value.length >= maxLength) {
      e.preventDefault();
    }
  }

  getValue(): Person {
    const model = Object.assign({}, this.model); // TODO: no clone, return domain object (for all such forms); use ngOnChanges+change handlers for helper variables

    if (model.tags) {
      // flatten tags
      model.tags = model.tags.map((tag) => tag['display'] || tag);
    }

    if (model.review && typeof model.review.by === 'object') {
      model.review.by = model.review.by.user_id;
    }

    if (this.affiliationsComponent) {
      const affiliationsList = this.affiliationsComponent.getListItems();
      const hasNewlyCreated = affiliationsList.find((aff) => aff.newlyCreated);
      if (hasNewlyCreated) {
        model.newAffiliationCreated = true;
      }
      model.affiliations = affiliationsList;
    }
    if (this.affiliationsLfkaComponent) {
      model.affiliationsLfka = this.affiliationsLfkaComponent.getListItems();
    }

    Utils.removeEmptyAndDuplicatedElementsFromArray(model, [
      'nicknames',
      'nameLinks',
      'alternativeNames',
      'statusLinks',
      'affiliationsLinks',
      'positionLinks',
      'cvLinks',
      'emailLinks',
    ]);

    return model;
  }

  isFieldEditable(field: string): boolean {
    return false; // for now we're readonly this.svcAcl.hasCredential(`person.update.prop.${field}`);
  }

  isFieldMandatory(field: string): boolean {
    return this.svcAcl.hasCredential(`person.update.mandatory.prop.${field}`);
  }

  highlightFields(additionalClass: string) {
    additionalClass = additionalClass ? `form-control ${additionalClass || ''}` : `form-control`;

    return this.svcAcl.hasRole(Roles.ProfileModerator) ||
      this.svcAcl.hasRole(Roles.ProfileModifier) ||
      this.svcAcl.hasRole(Roles.ProfileCompiler)
      ? `form-focus ${additionalClass}`
      : additionalClass;
  }

  get isCompiler() {
    return this.svcAcl.hasRole(Roles.PersonCompiler) || this.svcAcl.hasRole(Roles.ProfileCompiler);
  }

  isStateDisabled(state: string): boolean {
    if (!state) {
      return false;
    }

    return (
      (['RISING_EXPERT', 'NOT_RISING'].includes(state.toString()) ||
        this.model.optoutStatus.includes('OPTOUT_FOR_TA')) &&
      this.isCompiler
    );
  }

  trackByIndex(idx: number): number {
    return idx;
  }

  removeFromByIndex(from: any[], idx: number): void {
    from.splice(idx, 1);
  }

  pushItemToList(list: any[]): void {
    list.push('');
  }

  private initializeLists(): void {
    const lists = {
      alternativeNames: this.model.alternativeNames,
      cvLinks: this.model.cvLinks,
      nameLinks: this.model.nameLinks,
      statusLinks: this.model.statusLinks,
      emailLinks: this.model.emailLinks,
      degreeLinks: this.model.degreeLinks,
      specialtyLinks: this.model.specialtyLinks,
      affiliationsLinks: this.model.affiliationsLinks,
      positionLinks: this.model.positionLinks,
      emails: this.model.emails,
      faxes: this.model.faxes,
      talkingPoints: this.model.talkingPoints,
      nicknames: this.model.nicknames,
    };

    Object.entries(lists).forEach(([key, list]) => {
      if (!Array.isArray(list)) {
        this.model[key] = []; // null is a primitive hence it's passed by copy, we need to mutate the original object
        list = this.model[key];
      }

      if (!list.length) {
        list.push('');
      }
    });
  }

  loadStates() {
    this.svcValue
      .find(ValueType.PersonState, Number.MAX_SAFE_INTEGER)
      .toPromise()
      .then((data) => {
        this.states = data;
      });
  }

  isMainlyUS() {
    return (
      this.model.countryWorkflow === 'US' ||
      (this.model.affiliations || []).filter((a) => a.primary && a.address?.countryCode === 'US').length > 0
    );
  }

  gotoEmailLink(emailLink: string): void {
    window.open(emailLink, '_blank', 'noopener');
  }

  isProfileCompiler(): boolean {
    return this.svcAcl.hasRole(Roles.ProfileCompiler);
  }

  hasUSAddress() {
    const primary = this.model.affiliations?.find((af) => af.primary);
    if (primary?.address) {
      return primary?.address?.countryCode === 'US';
    }
    return this.isMainlyUS();
  }

  shouldAddMoreLink(links: string[]) {
    if (links.length < 3) {
      return true;
    }

    if (
      this.model.projectNames?.length > 0 &&
      this.model.projectNames.every((project) =>
        ['KAM_ALL', 'KAM_VIP', 'LFKA_PT_CONFIRMED', 'LFKA_PT_SUGGESTED', 'LFKA_COMMITTEE'].includes(project)
      )
    ) {
      return !this.model.projectNames.every((project) => ['KAM_ALL', 'KAM_VIP'].includes(project));
    }

    if (this.hasUSAddress()) {
      return true;
    }
  }

  loadLfkaTags() {
    this.svcValue
      .find(ValueType.PersonProject, Number.MAX_SAFE_INTEGER, 0, undefined, { product: 'LFKA' })
      .subscribe((data) => {
        this.LFKA_TAGS = data.map((item) => item.code as string);
      });
  }

  onCopyName(): void {
    const { firstName = '', middleName = '', lastName = '', suffix = '' } = this.model;
    navigator.clipboard.writeText([firstName, middleName, lastName, suffix].filter((s) => !!s).join(' '));
  }

  isCVLinkEditable(idx: number): boolean {
    let canEdit = this.isFieldEditable('cvLinks');
    if (idx === 0 && !!this.model?.cvLinks?.[0]) {
      // profile compilers cannot touch LFKA master CV link (if it's there)
      canEdit = canEdit && !(this.isOnlyKAMProject() && this.svcAcl.hasRole(Roles.ProfileCompiler));
    }
    return canEdit;
  }

  private loadSuffixes(): void {
    this.svcValue.find(ValueType.PersonSuffix, Number.MAX_SAFE_INTEGER).subscribe((data) => {
      this.suffixes = data;
    });
  }
}
