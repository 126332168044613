<nav class="navbar navbar-expand-lg navbar-light">
  <a class="navbar-brand" href="/">cLean</a>

  <ul class="navbar-nav mr-auto">
    <li class="nav-item" *diAcl="'(person.list || profile.list) && (!(person.identify || person.identifyqc))'">
      <a class="nav-link" [routerLink]="['/person']" [routerLinkActive]="['is-active']">People</a>
    </li>
    <li class="nav-item" *diAcl="'(person.list || profile.list) && (person.identify || person.identifyqc)'">
      <a class="nav-link" target="_blank" [routerLink]="['/person']" [routerLinkActive]="['is-active']">People</a>
    </li>
    <li class="nav-item" *diAcl="'organization.list'">
      <a class="nav-link" [routerLink]="['/organization']" [routerLinkActive]="['is-active']">HCOs</a>
    </li>
    <li class="nav-item" *diAcl="'association.list'">
      <a class="nav-link" [routerLink]="['/association']" [routerLinkActive]="['is-active']">Associations</a>
    </li>
    <li class="nav-item" *diAcl="'guideline.list'">
      <a
        class="nav-link"
        [routerLink]="['/guideline']"
        [ngClass]="{ 'is-active': guidelineActive.isActive || publicationGuidelineActive.isActive }"
        >Guidelines</a
      >

      <a [routerLink]="['/guideline']" routerLinkActive #guidelineActive="routerLinkActive" style="display: none"></a>
      <a
        [routerLink]="['/publication-guideline']"
        routerLinkActive
        #publicationGuidelineActive="routerLinkActive"
        style="display: none"
      ></a>
    </li>
    <li class="nav-item" *diAcl="'event.list'">
      <a class="nav-link" [routerLink]="['/event']" [routerLinkActive]="['is-active']">Events</a>
    </li>
    <li class="nav-item" *diAcl="'sentiment.list'">
      <a class="nav-link" [routerLink]="['/sentiment']" [routerLinkActive]="['is-active']">Sentiments</a>
    </li>
    <li class="nav-item" *diAcl="'account.list || committee.list || document.list'">
      <a
        class="nav-link"
        [routerLink]="['/account']"
        [ngClass]="{ 'is-active': accountActive.isActive || committeeActive.isActive || documentActive.isActive }"
        >Accounts</a
      >

      <a [routerLink]="['/account']" routerLinkActive #accountActive="routerLinkActive" style="display: none"></a>
      <a [routerLink]="['/committee']" routerLinkActive #committeeActive="routerLinkActive" style="display: none"></a>
      <a [routerLink]="['/document']" routerLinkActive #documentActive="routerLinkActive" style="display: none"></a>
    </li>
    <li class="nav-item" *diAcl="'clinicalTrialProfile.list'">
      <a class="nav-link" [routerLink]="['/ct-profile']" [routerLinkActive]="['is-active']">CT</a>
    </li>
    <li class="nav-item" *diAcl="'!clinicalTrialProfile.list && clinicalTrialSponsor.list'">
      <a class="nav-link" [routerLink]="['/ct-sponsor']" [routerLinkActive]="['is-active']">CT</a>
    </li>
    <li class="nav-item" *diAcl="'domain.list'">
      <a class="nav-link" [routerLink]="['/domain']" [routerLinkActive]="['is-active']">Domains</a>
    </li>
    <li class="nav-item" *diAcl="'job.list'">
      <a class="nav-link" [routerLink]="['/job']" [routerLinkActive]="['is-active']">Jobs</a>
    </li>
    <li class="nav-item" *diAcl="'!job.list && job.next'">
      <a class="nav-link" [routerLink]="['/next']" [routerLinkActive]="['is-active']">Jobs</a>
    </li>
    <li class="nav-item" *diAcl="'user.list'">
      <a class="nav-link" [routerLink]="['/user']" [routerLinkActive]="['is-active']">Config</a>
    </li>
  </ul>
  <ul class="navbar-nav pull-xs-right">
    <li class="nav-item">
      <dirt-logout class="nav-link"></dirt-logout>
    </li>
  </ul>
</nav>
