import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { map, switchMap, take } from 'rxjs/operators';
import { firstValueFrom, Subject } from 'rxjs';

import { JobsAPI } from '../../jobs/shared/api.service';
import { Job } from '../../jobs//shared/job';
import { UserAPI } from '../../user/shared/api.service';
import { VideoProfileModalService } from '../shared/modal/profile/profile.service';
import { Utils } from '../../common/utils';
import { ProfileVideoAPI } from '../../profile/subsection/video/shared/api.service';
import { LinkedEntityType } from '../shared/modal/profile/profile.component';
import { PersonAPI } from '../../person/shared/api.service';
import { Person } from '../../person/shared/person';
import { VideoAPI } from '../shared/video-api.service';
import { PersonStatus } from '../../person/shared/constant/status.enum';
import { UnmappedPersonAffiliationsModalService } from '../../person/shared/modal/unmapped-person-affiliations/unmapped-person-affiliations.service';

@Component({
  selector: 'dirt-video-job',
  templateUrl: 'video-job.component.html',
  styleUrls: ['video-job.component.scss'],
})
export class VideoJobComponent implements OnInit {
  id: string;
  job: Job;
  isSaving: boolean;
  videoId: string;
  reloadLinkedKOLs: Subject<boolean> = new Subject();

  personsCreated = [];

  wndw: Window = window;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private svcUser: UserAPI,
    private readonly svcJob: JobsAPI,
    private readonly svcVideoProfileModal: VideoProfileModalService,
    protected svcProfileVideo: ProfileVideoAPI,
    private readonly svcPerson: PersonAPI,
    private svcVideo: VideoAPI,
    private svcUPAModal: UnmappedPersonAffiliationsModalService
  ) {}

  ngOnInit() {
    this.initVideoJob();
  }

  updateKOLList() {
    this.reloadLinkedKOLs.next(true);
  }

  private initVideoJob() {
    this.route.params
      .pipe(
        take(1),
        map((params) => (this.id = params['id'])),
        switchMap((id) => this.svcJob.findById(id))
      )
      .subscribe((job) => {
        this.job = job;
        this.videoId = Utils.getYouTubeId(this.job.extEntityId);
      });
  }

  linkProfile(): void {
    this.svcVideoProfileModal
      .open(this.job)
      .then((result) => {
        if (result.type === LinkedEntityType.ExistingPerson) {
          this.svcProfileVideo.linkPersonVideo(result.data.id, this.getVideoEntityFromJob()).subscribe(
            () => {
              alert('Linked video to Person');
              this.updateKOLList();
            },
            () => {
              alert('Failed to Link Person');
            }
          );
        } else if (result.type === LinkedEntityType.NewPerson) {
          this.createPersonAndLinkVideo(result.data as Person);
        }
      })
      .catch(() => {});
  }

  unlinkProfile(evnt: { type: 'PERSON' | 'PROFILE'; personProfile }) {
    if ('PERSON' === evnt.type) {
      // remove video from futureSubentities.
      this.svcProfileVideo.unlinkPersonVideo(evnt.personProfile.id, this.getVideoEntityFromJob()).subscribe(
        () => {
          alert('Un-linked video from person');
          this.updateKOLList();
        },
        () => {
          alert('Failed to un-link Person');
        }
      );
    } else if ('PROFILE' === evnt.type) {
      // unlink from this profile (whoever created)
      this.svcProfileVideo.unlink(evnt.personProfile.profileId.id, [evnt.personProfile._id]).subscribe(
        () => {
          alert('Un-linked video from profile');
          this.updateKOLList();
        },
        () => {
          alert('Failed to un-link Profile');
        }
      );
    }
  }

  async onSubmit() {
    if (!this.wndw.confirm('Submit?')) {
      return;
    }
    this.svcJob.finish(this.job._id).subscribe(() => {
      this.router.navigate(['/next']);
    });
  }

  getVideoEntityFromJob() {
    const video = {
      vendorId: Utils.getYouTubeId(this.job.extEntityId),
      source: 'YOUTUBE',
      publicationDate: new Date(this.job.additionalData?.pubDate),
    };

    return video;
  }

  private async createPersonAndLinkVideo(person: Person) {
    const { unmappedAffiliations } = await firstValueFrom(this.svcPerson.checkAffiliations(person));
    if (unmappedAffiliations.length > 0) {
      const continueAnyways = await this.svcUPAModal.open(unmappedAffiliations);
      if (continueAnyways !== true) {
        return;
      }
    }

    const video = await this.svcVideo.create(this.getVideoEntityFromJob()).toPromise();
    person.futureSubentities = {
      videos: [video.vendorId],
    };
    person._meta = {
      status: PersonStatus.ON_HOLD,
    };

    person.ownerProduct = 'LFTA';

    this.svcPerson.create(person).subscribe(
      (p) => {
        this.personsCreated.push(p);
        alert('New person created');
        this.updateKOLList();
      },
      () => {
        alert('Failed to create new person');
      }
    );
  }
}
