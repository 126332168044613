import { OrganizationJob } from '../../organizations/shared/constant/job.enum';
import { MarkerArea } from '../directives/marker/marker.directive';

/**
 * Based on the current job type that a user is working, we want to determine the
 * area type that in turn determines the `field-error-type`s that are visible during
 * the review.
 */
export function getReviewMarkerArea(jobType: string): MarkerArea | null {
  if (['LFKA_KPP_CURATION_QC', 'LFKA_KPP_MAINTENANCE_QC'].includes(jobType)) {
    return 'KPP';
  }

  if (jobType === 'EDUCATION_QC') {
    return 'EDUCATION';
  }

  if (jobType === 'WORK_QC' || jobType === 'WORK_MAINTENANCE_QC') {
    return 'WORK';
  }

  if (jobType === 'BASE_DATA_QC') {
    return 'BASE_DATA';
  }

  if (jobType === 'VID_QC') {
    return 'VID_QC';
  }

  if (jobType === 'DEDUPLICATION_QC') {
    return 'ID';
  }

  if (jobType === 'PERSON_IMAGE_REVIEW') {
    return 'IMAGE';
  }

  if (jobType === 'PERSON_LINKEDIN_QC') {
    return 'LINKEDIN';
  }

  if (jobType === 'PERSON_SO_QC') {
    return 'SO';
  }

  if (jobType === OrganizationJob.MAPPING_QC) {
    return 'MAPPING';
  }

  if (jobType === 'CT_AFFILIATION_QC') {
    return 'CT_AFFILIATION_QC';
  }

  return null;
}
