import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { JobsAPI } from '../shared/api.service';
import { Job } from '../shared/job';

@Component({
  selector: 'dirt-jobs-next',
  templateUrl: 'next.component.html',
  styleUrls: ['next.component.scss'],
})
export class JobsNextComponent implements OnInit {
  drafts: (Job & { abstract })[];
  isLoadingNext = false;
  stats: any;

  constructor(private router: Router, private svcJob: JobsAPI) {}

  ngOnInit() {
    this.getDrafts();
    this.getStats();
  }

  private getDrafts() {
    this.svcJob.drafts().subscribe((q) => (this.drafts = q.current));
  }

  private getStats() {
    this.svcJob.getUserStats().subscribe((q) => {
      this.stats = q;
    });
  }

  getNext(evnt?, draft?: Job): void {
    if (evnt) {
      evnt.preventDefault();
    }

    this.isLoadingNext = true;

    this.svcJob.findNext(draft ? draft._id : undefined).subscribe(
      (job) => {
        if (!job || !job._id) {
          return alert('No job to be worked at the moment! Go and get some rest ;)');
        }

        if ('people' === job.entityType) {
          this.router.navigate(['/person/detail', job.entityId]);
        } else if ('videos' === job.entityType) {
          this.router.navigate(['/video-job/detail', job._id]);
        } else if ('profiles' === job.entityType) {
          this.router.navigate(['/profile/detail', job.entityId]);
        } else if ('clinical-trial-profiles' === job.entityType) {
          this.router.navigate(['/ct-profile/detail', job.entityId]);
        } else if ('affiliations' === job.entityType) {
          this.router.navigate(['/affiliation/detail', job.entityId]);
        } else if ('accounts' === job.entityType) {
          this.router.navigate(['/account/detail', job.entityId]);
        } else if ('committees' === job.entityType) {
          this.router.navigate(['/committee/detail', job.entityId]);
        } else if ('documents' === job.entityType) {
          this.router.navigate(['/document/detail', job.entityId]);
        } else if ('events' === job.entityType) {
          this.router.navigate(['/event/detail', job.entityId]);
        } else if ('events-series' === job.entityType) {
          this.router.navigate(['/event-series/detail', job.entityId]);
        } else if ('domains' === job.entityType) {
          this.router.navigate(['/domain/detail', job.entityId]);
        } else if ('organizations' === job.entityType) {
          this.router.navigate(['/organization/detail', job.entityId]);
        } else if ('clinical-trial-sponsors' === job.entityType) {
          this.router.navigate(['/ct-sponsor/detail', job.entityId]);
        } else {
          alert('Job for ' + job.entityType + ' not supported at this time!');
        }
      },
      () => alert('Error!'),
      () => (this.isLoadingNext = false)
    );
  }
}
