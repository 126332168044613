import { Person } from '../../person/shared/person';
import { ProfileViewMode } from './constant/view-mode.enum';
import { ProfileMatchAutomate } from './constant/match-automate.enum';
import { ProfileMatchManual } from './constant/match-manual.enum';
import { ProfileStatus } from './constant/status.enum';
import { ProfileWorkstream } from './constant/workstream.enum';
import { PersonAffiliation } from '../../person/shared/person-affiliation';

export class Profile {
  id: string;
  person: Person & { affiliation?: PersonAffiliation & { countryCode?: string } }; // depending on whether we expand it's profile.person.affiliation.countryCode or profile.person.affiliation.address.countryCode
  viewMode: ProfileViewMode;
  match: { automate: ProfileMatchAutomate; manual: ProfileMatchManual };
  polishedAt: Date;
  polishedBy: string;
  priority: number;
  published?: boolean;
  createdAt: Date;
  updatedAt: Date;
  _meta: {
    assignee?: string;
    status: ProfileStatus;
    claimedUntil?: Date;
    jobId?: string;
    workstream?: ProfileWorkstream;
  };
  tags: string[];
  unpolishedDPs: number;
  _version: number;
  verified: boolean;
  verificationSkipped: boolean;
  training?: boolean;
  baseProfileKolId?: string; // only to filter auto matches on UI - set by /profiles/detail
  baseProfileId?: string;
  trainingEvaluationReport?: {
    compilerId?: string;
    comment?: string;
    reportDetails?: any;
  };
  lastAssignmentDate?: Date;
}
