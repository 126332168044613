import {
  ApplicationRef,
  Component,
  ComponentFactoryResolver,
  HostListener,
  Injector,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { firstValueFrom, Observable, Subject } from 'rxjs';
import { debounceTime, take, takeUntil, tap } from 'rxjs/operators';
import { cloneDeep, groupBy, isNil } from 'lodash';
import { ActivatedRoute, Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ComponentPortal, DomPortalOutlet } from '@angular/cdk/portal';

import { ClinicalTrialProfile } from '../shared/clinical-trial-profile';
import { ClinicalTrial } from '../../clinical-trial/shared/clinical-trial';
import { ClinicalTrialProfileAPI } from '../shared/clinical-trial-profile-api.service';
import { ClinicalTrialAPI } from '../../clinical-trial/shared/clinical-trial-api.service';
import { PersonAPI } from '../../person/shared/api.service';
import { ProfileClinicalTrialAPI } from '../../profile/subsection/clinical-trial/shared/api.service';
import { ClinicalTrialSite } from '../shared/site';
import { PersonBaseinfo } from '../../person/shared/person-baseinfo';
import { ClinicalTrialCurationStatus } from '../shared/curation-status';
import { Value } from '../../shared/services/value/value';
import { ValueAPI } from '../../shared/services/value/value-api.service';
import { ValueType } from '../../shared/enum/value-type.enum';
import { ClinicalTrialProfileComparators } from '../shared/comparators';
import { ClinicalTrialProfilePatientCriteriaItem } from '../shared/patient-criteria-item';
import { ClinicalTrialProfileValueDate } from '../shared/value-date';
import { ValueNotInListModalService } from '../../value/shared/modal/not-in-list/not-in-list.service';
import { ClinicalTrialCriteriaCombination } from '../shared/patient-criteria-combination';
import { Job } from '../../jobs/shared/job';
import { Auth } from '../../shared/services/auth/auth.service';
import { JobsAPI } from '../../jobs/shared/api.service';
import { ACL } from '../../shared/acl/acl.service';
import { ClinicalTrialSiteMapping } from '../shared/site-mapping';
import { ClinicalTrialProfileSiteMapping } from '../shared/profile-site-mapping';
import { ClinicalTrialProfilePersonMapping } from '../shared/profile-person-mapping';
import { Roles } from '../../shared/acl/roles';
import { Utils } from '../../common/utils';
import { AffiliationAPI } from '../../affiliation/shared/api.service';
import {
  ClinicalTrialProfileInvestigatorsSitesListComponent,
  ClinicalTrialProfileInvestigatorsSitesListData,
  ClinicalTrialProfileSiteDetails,
  CT_PROFILE_INVESTIGATOR_SITES_INJECTION_TOKEN,
} from '../shared/components/investigators-sites-list/investigators-sites-list.component';

@Component({
  selector: 'dirt-ct-profile-detail',
  templateUrl: 'detail.component.html',
  styleUrls: ['detail.component.scss'],
})
export class ClinicalTrialProfileDetailComponent implements OnInit, OnDestroy {
  id: string;
  profile: ClinicalTrialProfile;
  profileJob: Job;
  mainId: string;
  baseTrial: ClinicalTrial;
  baseTrialInvestigators: { [srcText: string]: { caption: string; inv: any } } = {};
  baseTrialFacilities: { [srcText: string]: { caption: string; fac: any } } = {};

  clinicalTrialFocusAreas: string[] = [];
  visibleClinicalTrialFocusAreas: string[] = []; // used in UI
  hideClinicalTrialFocusAreas: boolean = true;

  visibleCountries: string[] = []; // used in UI
  hideCountries: boolean = true;

  visibleConditions: string[] = []; // used in UI
  hideConditions: boolean = true;

  persons: { [kolId: string]: PersonBaseinfo } = {};
  sites: { [_id: string]: ClinicalTrialSite } = {};
  existProfileCts: {
    _id: null;
    person: { kolId };
    investigator?: { position?; firstName: string; lastName: string };
    nct;
    match?: { manual?: string; automate?: string; mlExclude?: string };
  }[] = []; // (used: manual confirmed matches only)
  existCtSites: ClinicalTrialSiteMapping[] = [];
  isExiting: boolean = false;

  // (handle all date fields the same)
  trialDates: { caption: string; value: String }[] = [];

  allDates: {
    caption: string;
    field: keyof ClinicalTrialProfile;
    obj: ClinicalTrialProfileValueDate;
    belowLinks: boolean;
  }[] = [];
  get allDatesAbove() {
    return this.allDates.filter((dt) => !dt.belowLinks);
  }
  get allDatesBelow() {
    return this.allDates.filter((dt) => dt.belowLinks);
  }

  statusEnum = ClinicalTrialCurationStatus;
  combinationEnum = ClinicalTrialCriteriaCombination;
  comparators = Object.values(ClinicalTrialProfileComparators);
  isNil = isNil;
  replaceNaN = (val) => (isNaN(val) ? null : val);
  tab: 'MAIN' | /*'ENDPT' | 'CRIT' |*/ 'PPL' | 'SITES' | 'AUDIT' | 'COMMENT' = 'MAIN';
  eidAdd: boolean = false;
  eidNew: string = null;
  nameNew: string = null;
  interventionAdd: number = -1;
  personsOpened: string[] = [];

  timeframeUnits: Value[] = [];
  refUnits: Value[] = [];
  refEvents: Value[] = [];
  roles: Value[] = [];
  armTypes: Value[] = [];
  interventionTypes: Value[] = [];
  siteDirectReasons: Value[] = [];
  siteInsufficientInfoReasons: Value[] = [];
  siteNotInScopeReasons: Value[] = [];

  isLoading = true;
  isLoadingAll = true;
  isSubmitting = false;
  isRedirecting = false;

  @ViewChild('ngFormMatch')
  private siteLinkMatchForm: NgForm;
  siteLinkForm = new NgForm([], []);

  siteInvestigatorForm = new NgForm([], []);

  myUserId: string;

  ctLink: string;

  isLoadingSites = false;

  isLoadingInvestigators = false;

  protected wndw: Window = window; // allow for testing

  private hasRenderedSites = false;

  private hasRenderedInvestigators = false;

  @ViewChild('sitesContainer', { read: ViewContainerRef })
  private sitesContainer: ViewContainerRef;

  @ViewChild('siteRow', { read: TemplateRef })
  private siteRowTemplate: TemplateRef<any>;

  @ViewChild('investigatorsContainer', { read: ViewContainerRef })
  private investigatorsContainer: ViewContainerRef;

  @ViewChild('investigatorRow', { read: TemplateRef })
  private investigatorRowTemplate: TemplateRef<any>;

  bestMatchedAffiliation: { [kolId: string]: { affiliationId: string | null; affiliationName: string | null } } = {};

  numberSavedDrafts: number;

  private saveRequests$: Subject<boolean> = new Subject(); // if passed true, will display a confirmation message after saving

  private destroy$: Subject<boolean> = new Subject();

  private sitePopup: Window;

  constructor(
    public service: ClinicalTrialProfileAPI, // used in template too
    private route: ActivatedRoute,
    private router: Router,
    private personService: PersonAPI,
    private profileClinicalTrialService: ProfileClinicalTrialAPI,
    private trialService: ClinicalTrialAPI,
    private valueService: ValueAPI,
    private valueNotInListService: ValueNotInListModalService,
    private svcAuth: Auth,
    private svcAcl: ACL,
    private svcJob: JobsAPI,
    private titleService: Title,
    private affiliationService: AffiliationAPI,
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector
  ) {}

  canSeePeople() {
    return (
      this.svcAcl.hasCredential('clinicalTrialProfile.viewPeople') ||
      this.svcAcl.hasCredential('clinicalTrialProfile.updatePeople')
    );
  }
  canSeeSites() {
    return (
      this.svcAcl.hasCredential('clinicalTrialProfile.viewSites') ||
      this.svcAcl.hasCredential('clinicalTrialProfile.updateSites')
    );
  }
  canUpdatePeople() {
    return this.svcAcl.hasCredential('clinicalTrialProfile.updatePeople');
  }
  canUpdateSites() {
    return this.svcAcl.hasCredential('clinicalTrialProfile.updateSites');
  }
  canSeeAudit() {
    return this.svcAcl.hasCredential('clinicalTrialProfile.audit.list');
  }
  canSeeComment() {
    return this.svcAcl.hasCredential('clinicalTrialProfile.comment.list');
  }
  canUpdateCT(): boolean {
    return this.svcAcl.hasCredential('clinicalTrialProfile.update');
  }
  canSaveAnyway(): boolean {
    return (
      this.svcAcl.hasCredential('person.updateAnyway') || this.svcAcl.hasCredential('clinicalTrialProfile.updateAnyway')
    );
  }

  async ngOnInit(): Promise<void> {
    const user = await firstValueFrom(this.svcAuth.getProfile());
    this.myUserId = user.user_id;

    this.route.params.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      if (this.isRedirecting) {
        this.isRedirecting = false;
        return;
      }
      if (/^[0-9a-fA-F]{24}$/.test(params['id'])) {
        this.id = params['id'];
        this.loadProfile(this.id, () =>
          setTimeout(() => {
            this.switchTab();
          }, 100)
        );
      } else {
        // try obtain a profile, replace nav
        this.loadProfileObtain(params['id'], () =>
          setTimeout(() => {
            this.switchTab();
          }, 100)
        ).subscribe((_p) => {
          this.isRedirecting = true;
          this.router.navigate(['/ct-profile/detail', _p._id]);
        });
      }
    });

    this.saveRequests$
      .pipe(
        takeUntil(this.destroy$),
        debounceTime(5_000) // Could have more, so wait for 5s of inactivity (vs every 5s if setTimeout / auditTime)
      )
      .subscribe((showSaveConfirmation) => {
        if (this.isExiting) {
          // Already saving and leaving the page so no more auto saving
          return;
        }

        if (this.isSubmitting) {
          // Make sure we _never_ overlap - will try to save again later
          this.saveRequests$.next(false);
          return;
        }

        this.isSubmitting = true;

        const profile = cloneDeep(this.profile); // Don't do the job of another request accidentally
        Utils.removeEmptyAndDuplicatedElementsFromArray(profile, ['baseLinks']);

        this.service.update(profile._id, profile).subscribe({
          next: (profile) => {
            this.profile._version = profile._version;
            if (showSaveConfirmation) {
              alert('Saved successfully');
            }
          },
          error: () => alert('ERROR during save'),
          complete: () => (this.isSubmitting = false),
        });
      });
  }

  ngOnDestroy(): void {
    this.isExiting = true;

    this.destroy$.next(false);
    this.destroy$.complete();

    this.sitePopup?.close();
  }

  switchTab() {
    if (this.canUpdatePeople() && this.svcAcl.userRoles.includes(Roles.CtPeopleCompiler)) {
      this.onClickInvestigatorsTab();
    }

    if (this.canUpdateSites() && this.svcAcl.userRoles.includes(Roles.CtSiteCompiler)) {
      this.onClickSitesTab();
    }
  }

  isValid() {
    let isValid = this.siteLinkForm.valid && this.siteLinkMatchForm?.valid;
    if (this.jobTypeForCurrentTab() && 'SITES' === this.tab) {
      // we only want to validate the checkboxes when the job for sites tab
      const allSiteMappingsValid = this.profile?.siteMappings.every((siteMapping) => {
        // Skip validation if the status is 'Discard'
        if (siteMapping.status === ClinicalTrialCurationStatus.Discard) {
          return true;
        }
        const isAnyCheckboxChecked = this.isAnyCheckboxChecked(siteMapping);
        const isIndirectValid =
          !siteMapping.indirect || (siteMapping.hcpIds && siteMapping.hcpIds.some((hcpId) => !!hcpId?.trim()));
        return isAnyCheckboxChecked && isIndirectValid;
      });
      isValid = isValid && allSiteMappingsValid;
    }
    return isValid;
  }

  goBack() {
    this.router.navigate(['/ct-profile/list']);
  }

  updateVisibleClinicalTrialFocusAreas() {
    this.visibleClinicalTrialFocusAreas = this.hideClinicalTrialFocusAreas
      ? this.clinicalTrialFocusAreas.slice(0, 10)
      : this.clinicalTrialFocusAreas;
  }

  toggleUnhideFAs() {
    this.hideClinicalTrialFocusAreas = !this.hideClinicalTrialFocusAreas;
    this.updateVisibleClinicalTrialFocusAreas();
  }

  updateVisibleCountries() {
    const locations = this.hideCountries ? this.baseTrial.locations?.slice(0, 10) : this.baseTrial.locations;

    this.visibleCountries = locations?.map((i) => i.facility?.address?.country);
  }

  toggleUnhideCountries() {
    this.hideCountries = !this.hideCountries;
    this.updateVisibleCountries();
  }

  updateVisibleConditions() {
    this.visibleConditions = this.hideConditions ? this.baseTrial.conditions.slice(0, 10) : this.baseTrial.conditions;
  }

  toggleUnhideConditions() {
    this.hideConditions = !this.hideConditions;
    this.updateVisibleConditions();
  }

  getValidateFunction(field: any): (date: any) => 'Valid' | 'Invalid' | 'In Future' | 'Before 2012' {
    if (field.includes('Actual')) {
      // only for the fields `Enrollment start actual` and `Enrollment end actual`
      return this.validateActualDate.bind(this);
    }
    // `anticipated` dates
    return this.validateDate.bind(this);
  }

  private validateDate(date: any): 'Valid' | 'Is Invalid' | 'Cannot Be Before 2012' | 'Violates trial dates' {
    // Check if date has invalid format
    if (!date || isNaN(Date.parse(date))) {
      return 'Is Invalid';
    }

    // Check if date is before 2012
    const minimum2012Date = new Date(2012, 0, 1); // Jan 1, 2012
    if (date < minimum2012Date) {
      return 'Cannot Be Before 2012';
    }

    if (date < new Date(this.baseTrial.startDate) || date > new Date(this.baseTrial.completionDate)) {
      return 'Violates trial dates';
    }

    return 'Valid';
  }

  private validateActualDate(
    date: any
  ): 'Valid' | 'Is Invalid' | 'Is In Future' | 'Cannot Be Before 2012' | 'Violates trial dates' {
    if (!date) {
      return 'Is Invalid';
    }

    // Check if date is a future date
    const currentDate = new Date();
    if (date > currentDate) {
      return 'Is In Future';
    }

    return this.validateDate(date);
  }

  private async loadLists(): Promise<void> {
    // (that are not typeahead - but they're used to auto-prepopulate)
    const compByVal = (v1, v2) =>
      (v1.value?.toUpperCase() || v1.code).localeCompare(v2.value?.toUpperCase() || v2.code);
    await Promise.all([
      new Promise<void>((_resolve) =>
        this.valueService
          .find(ValueType.TrialTimeframeUnit)
          .pipe(take(1))
          .subscribe((_vals) => {
            this.timeframeUnits = _vals.sort(compByVal);
            _resolve();
          })
      ),
      new Promise<void>((_resolve) =>
        this.valueService
          .find(ValueType.TrialRefUnit)
          .pipe(take(1))
          .subscribe((_vals) => {
            this.refUnits = _vals.sort(compByVal);
            _resolve();
          })
      ),
      new Promise<void>((_resolve) =>
        this.valueService
          .find(ValueType.TrialRefEvent)
          .pipe(take(1))
          .subscribe((_vals) => {
            this.refEvents = _vals.sort(compByVal);
            _resolve();
          })
      ),
      new Promise<void>((_resolve) =>
        this.valueService
          .find(ValueType.TrialRole)
          .pipe(take(1))
          .subscribe((_vals) => {
            this.roles = _vals.sort(compByVal);
            _resolve();
          })
      ),
      new Promise<void>((_resolve) =>
        this.valueService
          .find(ValueType.TrialArmType)
          .pipe(take(1))
          .subscribe((_vals) => {
            this.armTypes = _vals.sort(compByVal);
            _resolve();
          })
      ),
      new Promise<void>((_resolve) =>
        this.valueService
          .find(ValueType.TrialInterventionType)
          .pipe(take(1))
          .subscribe((_vals) => {
            this.interventionTypes = _vals.sort(compByVal);
            _resolve();
          })
      ),
      new Promise<void>((_resolve) =>
        this.valueService
          .find(ValueType.TrialSiteDirectReason)
          .pipe(take(1))
          .subscribe((_vals) => {
            this.siteDirectReasons = _vals.sort(compByVal);
            _resolve();
          })
      ),
      new Promise<void>((_resolve) =>
        this.valueService
          .find(ValueType.TrialSiteInsufficientInfoReason)
          .pipe(take(1))
          .subscribe((_vals) => {
            this.siteInsufficientInfoReasons = _vals.sort(compByVal);
            _resolve();
          })
      ),
      new Promise<void>((_resolve) =>
        this.valueService
          .find(ValueType.TrialSiteNotInScopeReason)
          .pipe(take(1))
          .subscribe((_vals) => {
            this.siteNotInScopeReasons = _vals.sort(compByVal);
            _resolve();
          })
      ),
    ]);
  }

  private loadProfile(id, cb?: () => void) {
    this.isLoading = true;
    this.isLoadingAll = true;
    this.personsOpened = [];
    this.service
      .findById(id)
      .pipe(take(1))
      .subscribe((_p) => {
        this.loadProfileImpl(_p, cb);
      });
  }
  private loadProfileObtain(nct, cb?: () => void): Observable<any> {
    this.isLoading = true;
    this.isLoadingAll = true;
    this.personsOpened = [];
    return this.service
      .obtain(nct)
      .pipe(take(1))
      .pipe(
        tap((_p: ClinicalTrialProfile) => {
          this.id = _p._id;
          this.loadProfileImpl(_p, cb);
        })
      );
  }

  private async loadClinicalInfoByCTid() {
    const _fas = await firstValueFrom(
      this.profileClinicalTrialService.findFocusAreaByCTid(this.baseTrial.clinicalTrialId)
    );

    this.clinicalTrialFocusAreas = _fas || [];
    this.updateVisibleClinicalTrialFocusAreas();

    this.updateVisibleConditions();
    this.updateVisibleCountries();
  }

  private loadProfileImpl(_p, cb?: () => void) {
    const beforeAugmentPromises = [];

    this.profile = _p;
    this.mainId = this.profile.externalIds.filter((_eid) => _eid.isMainNct).map((_eid) => _eid.value)[0];
    this.titleService.setTitle(`cLean | CT | ${this.mainId}`);

    this.ctLink = this.mainId.toLowerCase().startsWith('jrct')
      ? 'https://jrct.niph.go.jp/en-latest-detail/'
      : 'https://clinicaltrials.gov/ct2/show/';
    this.ctLink = this.ctLink + this.mainId;

    // Only get job details if user is the one working it
    if (this.profile._meta?.jobId && this.profile._meta?.assignee === this.myUserId) {
      this.svcJob
        .findById(this.profile._meta.jobId)
        .pipe(take(1))
        .subscribe((j) => {
          this.profileJob = j;
        });
    }

    // init what we need right away
    this.profile.personMappings = this.profile.personMappings || [];
    this.profile.siteMappings = this.profile.siteMappings || [];
    this.profile.baseLinks = this.profile.baseLinks || [''];
    this.profile.endpointLinks = this.profile.endpointLinks || [];
    this.profile.arms = this.profile.arms || [];
    this.profile.interventions = this.profile.interventions || [];

    // need all lists in place
    beforeAugmentPromises.push(this.loadLists());

    // load the base trial we downloaded from clinicaltrials.gov et al
    beforeAugmentPromises.push(
      new Promise<void>((_resolve) =>
        this.trialService
          .findById(this.mainId)
          .pipe(take(1))
          .subscribe((_bt) => {
            this.baseTrial = _bt;
            this.baseTrialInvestigators = {};
            this.baseTrial.investigators?.forEach(
              (inv) =>
                (this.baseTrialInvestigators[this.generateInvestigatorIdent(inv)] = {
                  caption:
                    inv.name +
                    ', ' +
                    (inv.facility || '-') +
                    ' (' +
                    [inv.address?.city, inv.address?.state, inv.address?.postalCode, inv.address?.country]
                      .filter((p) => !!p)
                      .join(', ') +
                    '): ' +
                    (inv.role || '-'),
                  inv,
                })
            );
            this.baseTrialFacilities = {};
            const allFacilities = [
              ...(this.baseTrial.facilities || []),
              ...(this.baseTrial.locations || []).map((l) => l.facility).filter((f) => !!f),
            ];
            allFacilities.forEach(
              (fac) =>
                (this.baseTrialFacilities[this.generateSiteIdent(fac)] = {
                  caption:
                    fac.name +
                    ' (' +
                    [fac.address?.city, fac.address?.state, fac.address?.postalCode, fac.address?.country]
                      .filter((p) => !!p)
                      .join(', ') +
                    ')',
                  fac,
                })
            );
            this.isLoading = false; // have the minimum
            _resolve();
          })
      )
    );

    // load person info from profile person mappings
    const profileKolIds = this.profile.personMappings.filter((_pm) => _pm.kolId).map((_pm) => _pm.kolId);
    if (profileKolIds.length > 0) {
      beforeAugmentPromises.push(
        new Promise<void>((_resolve) =>
          this.personService
            .getBaseinfo(profileKolIds)
            .pipe(take(1))
            .subscribe((_binfos) => {
              _binfos.forEach((_bi) => (this.persons[_bi.kolId] = _bi));
              _resolve();
            })
        )
      );
    }

    // load site info from profile site mappings
    const profileSiteMappingsSiteIds = this.profile.siteMappings.filter((_sm) => _sm.site).map((_sm) => _sm.site);
    if (profileSiteMappingsSiteIds.length > 0) {
      beforeAugmentPromises.push(
        new Promise<void>((_resolve) =>
          this.service
            .findSitesByIds(profileSiteMappingsSiteIds)
            .pipe(take(1))
            .subscribe((_sites) => {
              _sites.forEach((_site) => (this.sites[_site._id] = _site));
              _resolve();
            })
        )
      );
    }

    // Load site info from profile person mappings
    const profilePersonMappingsSiteIds = this.profile.personMappings.filter((pm) => pm.site).map((pm) => pm.site);
    if (profilePersonMappingsSiteIds.length > 0) {
      beforeAugmentPromises.push(
        new Promise<void>((_resolve) =>
          this.service
            .findSitesByIds(profilePersonMappingsSiteIds)
            .pipe(take(1))
            .subscribe((_sites) => {
              _sites.forEach((_site) => (this.sites[_site._id] = _site));
              _resolve();
            })
        )
      );
    }

    // info to derive more suggestions - by checking what we got from KIP
    // a.) Profile-CT mapping (+ more persons)
    beforeAugmentPromises.push(
      new Promise<void>((_resolve) =>
        this.profileClinicalTrialService
          .findByNct(this.mainId, true)
          .pipe(take(1))
          .subscribe((_profileCts) => {
            this.existProfileCts = _profileCts;

            // Load the IDs we don't have yet
            const missingKolIds = _profileCts
              .filter((_pct) => !profileKolIds.includes(_pct.person.kolId))
              .map((_pct) => _pct.person.kolId);
            if (missingKolIds.length > 0) {
              // we also need more persons
              this.personService
                .getBaseinfo(missingKolIds)
                .pipe(take(1))
                .subscribe((_binfos) => {
                  _binfos.forEach((_bi) => (this.persons[_bi.kolId] = _bi));
                  _resolve();
                });
            } else {
              _resolve();
            }
          })
      )
    );

    // b.) CT-site mapping (+ more sites)
    beforeAugmentPromises.push(
      new Promise<void>((_resolve) =>
        this.service
          .findCtSiteMappings(this.mainId)
          .pipe(take(1))
          .subscribe((_ctSites) => {
            this.existCtSites = _ctSites; // already has the info on affiliationName/... in it
            this.existCtSites.forEach((m) => {
              m.match = m.match || ({} as any);
            });
            _resolve();
          })
      )
    );

    // now wait until waitAugment is zero (or a max time with message) and kick off augmentation
    Promise.all(beforeAugmentPromises).then(async () => {
      this.isLoadingAll = false;
      await this.augmentProfileFromBase();
      if (cb) {
        cb();
      }
    });
  }

  @HostListener('document:visibilitychange')
  onGlobalVisibility() {
    if (!this.wndw.document.hidden) {
      // coming back
      if (this.personsOpened.length > 0) {
        this.personService
          .getBaseinfo(this.personsOpened)
          .pipe(take(1))
          .subscribe((_binfos) => {
            _binfos.forEach((_bi) => (this.persons[_bi.kolId] = _bi));
          });
      }
    }
  }

  private async augmentProfileFromBase() {
    // investigator + site
    if (this.canSeePeople() && !this.isQcJob()) {
      this.processInvestigators();
      this.processInvestigatorsFromProfile();

      this.profile.personMappings = this.profile.personMappings.sort((a, b) => a.srcName?.localeCompare(b.srcName));
    }
    if (this.canSeeSites() && !this.isQcJob()) {
      this.processSites();
      this.processSiteFoundMatches();

      this.profile.siteMappings = this.profile.siteMappings.sort((a, b) => a.srcText?.localeCompare(b.srcText));
    }
    if (this.canUpdateSites() && this.isQcJob()) {
      this.processSiteFoundMatches(true);

      this.profile.siteMappings = this.profile.siteMappings.sort((a, b) => a.srcText?.localeCompare(b.srcText));
    }

    // all time values
    this.trialDates.push({ caption: 'Trial start', value: this.baseTrial.startDate });
    this.trialDates.push({ caption: 'Trial completion', value: this.baseTrial.completionDate });

    this.addAllDateValue(
      'Enrollment start actual',
      'enrollmentStartActual',
      'Anticipated' !== this.baseTrial.enrollmentStartDateType ? this.baseTrial.enrollmentStartDate : null
    );
    this.addAllDateValue(
      'Enrollment start anticipated',
      'enrollmentStartAnticipated',
      'Anticipated' === this.baseTrial.enrollmentStartDateType ? this.baseTrial.enrollmentStartDate : null
    );
    this.addAllDateValue(
      'Enrollment end actual',
      'enrollmentEndActual',
      'Anticipated' !== this.baseTrial.enrollmentEndDateType ? this.baseTrial.enrollmentEndDate : null
    );
    this.addAllDateValue(
      'Enrollment end anticipated',
      'enrollmentEndAnticipated',
      'Anticipated' === this.baseTrial.enrollmentEndDateType ? this.baseTrial.enrollmentEndDate : null
    );

    await this.loadClinicalInfoByCTid();
  }

  // many and all the same
  private addAllDateValue(caption: string, field: keyof ClinicalTrialProfile, srcValue: Date | string | null): void {
    this.profile[field] = this.fillDateValue(this.profile[field] as any, srcValue) as any;
    this.allDates.push({ caption, field, obj: this.profile[field] as any, belowLinks: false });
  }

  // (can still use sep'ly)
  private fillDateValue(
    current: ClinicalTrialProfileValueDate,
    srcValue: Date | string | null
  ): ClinicalTrialProfileValueDate {
    if (!current) {
      current = {} as any;
    }
    if (!isNil(srcValue)) {
      current.status = current.status || ClinicalTrialCurationStatus.Keep;
      const oldSrc = current.srcValue?.toString();
      const newSrc = srcValue?.toString();
      if (oldSrc !== newSrc) {
        current.todo = true;
      }
      current.srcValue = newSrc;
    }
    return current;
  }

  private processInvestigators() {
    // (just use the ID; and also store a name)
    this.baseTrial.investigators?.forEach((investigator) => {
      const srcText = this.generateInvestigatorIdent(investigator);
      const srcName = investigator.name;
      const srcRole = investigator.role;

      const exist = this.profile.personMappings.find((mapping) => mapping.srcText === srcText); // (no lazy migration - we don't have production volume yet)
      if (exist) {
        // Try to augment with role information
        const role = this.findInValuesOrNull(investigator.role, this.roles);
        if (!role || exist.roles.find((r) => r.role === role)) {
          // no role or already have it
          return;
        }

        // Add role and ask for checking
        exist.todo = true;

        const hasNullRole = exist.roles.find((r) => !r.role);
        if (hasNullRole) {
          // replace it
          hasNullRole.role = role;
        } else {
          // add new role
          exist.roles.push({ role });
        }

        return;
      }

      const cleanedName = this.getCleanedInvestigatorName(srcName || '')
        .split(' ')
        .sort()
        .join(' '); // tradeoff between how much we incorrectly group vs how much we miss by not sorting the name
      const existNameOnly = srcName
        ? this.profile.personMappings.find(
            (mapping) => this.getCleanedInvestigatorName(mapping.srcName)?.split(' ').sort().join(' ') === cleanedName
          )
        : null;
      if (existNameOnly) {
        // Try to augment with role information
        const role = this.findInValuesOrNull(investigator.role, this.roles);
        if (!role || existNameOnly.roles.find((r) => r.role === role)) {
          // no role or already have it
          return;
        }

        // Add role and ask for checking
        existNameOnly.todo = true;

        const hasNullRole = existNameOnly.roles.find((r) => !r.role);
        if (hasNullRole) {
          // replace it
          hasNullRole.role = role;
        } else {
          // add new role
          existNameOnly.roles.push({ role });
        }

        return;
      }

      // s/o new, add for check
      this.profile.personMappings.push({
        status: null,
        kolId: null,
        srcName,
        roles: [{ role: this.findInValuesOrNull(investigator.role, this.roles) }],
        srcRole,
        srcText,
        autoMappingId: null,
        todo: true,
      });
    });

    let nextManualAddNo = this.getNextManualAddNo(this.profile.personMappings);
    this.profile.personMappings.forEach((pm) => {
      // also re-open for re-check
      const foundInBase = this.baseTrial.investigators?.find((investigator) => {
        return pm.srcText === this.generateInvestigatorIdent(investigator);
      });
      if (foundInBase && (foundInBase.name !== pm.srcName || foundInBase.role !== pm.srcRole)) {
        pm.srcName = foundInBase.name;
        pm.srcRole = foundInBase.role;
        pm.todo = true; // check again
      }
      if (!pm.noLongerInSource && pm.srcText && !foundInBase) {
        pm.noLongerInSource = true;
        pm.todo = true;
      }
      if (!pm.roles) {
        pm.roles = [];
      }
      if (pm.roles.length < 1) {
        pm.roles.push({ role: null });
      }
      if (!(pm.srcText || pm.autoMappingId) && (pm.manualAddNo || 0) < 1) {
        // (lazy intro into existing)
        pm.manualAddNo = nextManualAddNo;
        nextManualAddNo++;
      }
    });
  }

  private generateInvestigatorIdent(investigator: {
    firstName;
    lastName;
    name;
    facility;
    address: { city; state; postalCode; country };
  }): string {
    return [
      investigator.firstName,
      investigator.lastName || investigator.name /*older data only has name*/,
      investigator.facility,
      investigator.address?.city,
      investigator.address?.state,
      investigator.address?.postalCode,
      investigator.address?.country,
    ]
      .map((s) => s || '-')
      .join('|');
  }

  private processInvestigatorsFromProfile() {
    // what did we get from there, can match to existing investigators - or
    this.existProfileCts.forEach((pct) => {
      const baseInfo = this.persons[pct.person?.kolId];
      if (!baseInfo) {
        return; // (relic)
      }

      const existDirect = this.profile.personMappings.find(
        (pm) => pm.kolId === baseInfo.kolId || pm.autoMappingId === pct._id
      );
      if (existDirect) {
        return; // nothing more we could possibly do
      }

      const srcName = pct.investigator.firstName + ' ' + pct.investigator.lastName;
      const cleanedFirstName = this.getCleanedInvestigatorName(
        pct.investigator.firstName?.replaceAll(' ', '\\.?').replaceAll('-', '(-|\\s)?')
      );
      const cleanedLastName = this.getCleanedInvestigatorName(
        pct.investigator.lastName?.replaceAll(' ', '\\.?').replaceAll('-', '(-|\\s)?')
      );
      const existRegex = new RegExp('^' + cleanedFirstName + '(.)? .{0,15}' + cleanedLastName + '(,.*)?$', 'i'); // cut off everything after a comma
      const existByName = this.profile.personMappings.find((mapping) =>
        this.getCleanedInvestigatorName(mapping.srcName)?.match(existRegex)
      );
      if (existByName && existByName.kolId) {
        return; // avoid even more confusion
      } else if (existByName) {
        // this one we can assign automatically
        existByName.kolId = baseInfo.kolId;
        existByName.autoMappingId = pct._id;
        existByName.todo = true;
      } else {
        // just create an entry without source
        this.profile.personMappings.push({
          status: null,
          kolId: baseInfo.kolId,
          srcName,
          roles: [{ role: this.findInValuesOrNull(null, this.roles) }], // We are not interested in roles inherited from the KP profiles
          srcRole: null,
          srcText: null,
          autoMappingId: pct._id,
          todo: true,
          notInSource: true,
        });
      }
    });
  }

  private getCleanedInvestigatorName(name: string): string {
    return name
      ?.trim()
      .replace(/(,.*)?$/i, '') // Get rid of everything after a comma (e.g. John Doe, MD -> John Doe)
      .replace(/\./g, ' ') // Get rid of dots (Pr. John Doe -> Pr John Doe)
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '') // Remove accentuated characters
      .split(' ')
      .map((part) => part.toLowerCase())
      .filter((part) => ![' ', 'dr', 'pr'].includes(part)) // Get rid of some known prefixes
      .join(' ');
  }

  private processSites() {
    // (just use the name)
    const allFacilities = [
      ...(this.baseTrial.facilities || []),
      ...(this.baseTrial.locations || []).map((l) => l.facility).filter((f) => !!f),
    ];
    const namesUpdated = {};
    this.profile.siteMappings
      .filter((mapping) => mapping.srcText?.indexOf('|') > 0)
      .forEach((mapping) => (namesUpdated[mapping.srcText.substring(0, mapping.srcText.indexOf('|'))] = true)); // (what we did before)
    allFacilities.forEach((facility) => {
      const srcText = this.generateSiteIdent(facility);
      const exist = this.profile.siteMappings.find((mapping) => mapping.srcText === srcText); // (lazy migration below as we have production volumes)
      const existNameOnly = this.profile.siteMappings.find((mapping) => mapping.srcText === facility.name);
      if (!exist && existNameOnly && !namesUpdated[facility.name]) {
        existNameOnly.srcText = srcText; // use the full ID (migrate lazily)
        namesUpdated[facility.name] = true; // 2nd time we encounter the name, we just append
      } else if (!exist) {
        this.profile.siteMappings.push({
          status: null,
          site: null,
          srcText,
          todo: true,
        });
      }
    });
    let nextManualAddNo = this.getNextManualAddNo(this.profile.siteMappings);
    this.profile.siteMappings.forEach((sm) => {
      const foundInBase = allFacilities.find((facility) => {
        return sm.srcText === this.generateSiteIdent(facility);
      });
      if (!sm.noLongerInSource && sm.srcText && !foundInBase) {
        sm.noLongerInSource = true;
        sm.todo = true;
      }
      if (!sm.srcText && (sm.manualAddNo || 0) < 1) {
        // (lazy intro into existing)
        sm.manualAddNo = nextManualAddNo;
        nextManualAddNo++;
      }
    });
  }

  private processSiteFoundMatches(onlyShow?: boolean) {
    const namesMatched = {};
    this.existCtSites.forEach((match) => {
      const srcText = this.generateSiteIdent({ name: match.facility, address: match.address });
      let exist = this.profile.siteMappings.find((mapping) => mapping.srcText === srcText);
      if (!exist && !namesMatched[match.facility]) {
        // try with only name when we can't find the facility outright
        exist = this.profile.siteMappings
          .filter((mapping) => mapping.srcText?.indexOf('|') > 1 /* not just - */)
          .find((mapping) => mapping.srcText.substring(0, mapping.srcText.indexOf('|')) === match.facility); // see generateSiteIdent below
      }
      namesMatched[match.facility] = true; // (don't try name-only several times)
      if (!exist) {
        if (!onlyShow) {
          this.profile.siteMappings.push({
            status: null,
            site: null, // (only if confirmed)
            srcText,
            todo: true,
            foundMatches: [match],
          });
        }
      } else if (!exist.foundMatches) {
        exist.foundMatches = [match];
      } else {
        exist.foundMatches.push(match);
      }
    });

    const alreadyMappedInvestigatorSites = this.profile.personMappings.filter((pm) => pm.site);
    alreadyMappedInvestigatorSites.forEach((pm) => {
      const srcTextFacility = (pm.srcText || '').split('|').slice(2).join('|'); // We get rid of firstName / lastName, rest is our facility information
      if (!srcTextFacility) {
        return;
      }

      const matchingSiteMapping = this.profile.siteMappings.find((sm) => sm.srcText === srcTextFacility);
      if (!matchingSiteMapping) {
        return;
      }

      const site = this.sites[pm.site];
      if (!site) {
        return;
      }

      if (!matchingSiteMapping.foundMatches) {
        matchingSiteMapping.foundMatches = [];
      }

      // Don't add the same suggestion multiple time
      const alreadyAdded = !!matchingSiteMapping.foundMatches.find((m) => m.affiliation === site.affiliation);
      if (alreadyAdded) {
        return;
      }

      const [
        facilityName,
        facilityAddressCity,
        facilityAddressState,
        facilityAddressPostalCode,
        facilityAddressCountry,
      ] = srcTextFacility.split('|').map((s) => ('-' === s || '' === s ? undefined : s));
      matchingSiteMapping.foundMatches.push({
        clinicalTrialId: this.baseTrial.clinicalTrialId,
        facility: facilityName, // this NEVER contains | - if we just copy an identifier, we multiply sites
        address: {
          city: facilityAddressCity,
          state: facilityAddressState,
          postalCode: facilityAddressPostalCode,
          country: facilityAddressCountry,
        },
        affiliation: site.affiliation,
        affiliationName: site.affiliationName,
        affiliationDepartment: site.affiliationDepartment,
        affiliationAddress: site.affiliationAddress,
        fromInvestigators: true,
        kolId: pm.kolId, // We'll need that later to pull departments of the affiliation that are attached to the KOL
        match: {
          automate: 'NONE',
          manual: 'NONE',
        },
      } as any);
    });
  }

  generateSiteIdent(facility: { name; address?: { city; state; postalCode; country } }): string {
    return [
      facility.name,
      facility.address?.city,
      facility.address?.state,
      facility.address?.postalCode,
      facility.address?.country,
    ]
      .map((s) => s || '-')
      .join('|');
  }

  private findInValuesOrNull(s: string, vals: Value[], todoHolder?: { todo }): string /*code*/ {
    if (isNil(s)) {
      return null;
    }
    const found: string = (vals.find((_r) => _r.code === s.toUpperCase() || _r.value.toUpperCase() === s.toUpperCase())
      ?.code || null) as string;
    if (found) {
      return found;
    } else {
      if (todoHolder) {
        todoHolder.todo = true;
      }
      return null;
    }
  }

  addExternalId() {
    if (!this.eidNew?.trim()) {
      return;
    }
    this.profile.externalIds.push({
      value: this.eidNew,
      isMainNct: false,
      status: ClinicalTrialCurationStatus.Keep,
    });
    this.eidNew = null;
    this.eidAdd = false;
  }

  valueOrSrc(value, srcValue) {
    return undefined !== value && null !== value ? value : srcValue;
  }

  needPatientCriteriaRef(item: ClinicalTrialProfilePatientCriteriaItem) {
    return item.comparator && !item.comparator.match(/^[A-Z]+$/);
  }

  removeRole(mp, n) {
    mp.roles = mp.roles.filter((_r, _n) => _n !== n);
  }

  removeName(inv, n) {
    inv.names = inv.names.filter((_n) => _n !== n);
  }
  addName(inv) {
    if (!this.nameNew?.trim()) {
      return;
    }
    if (!inv.names) {
      inv.names = [];
    }
    if (!inv.names.find((_n) => _n === this.nameNew)) {
      inv.names.push(this.nameNew);
      this.nameNew = '';
      this.interventionAdd = -1;
    }
  }

  clearMatchReasons(mapping: ClinicalTrialProfileSiteMapping) {
    if (!mapping.direct) {
      mapping.directReason = undefined;
    }
    if (!mapping.insufficientInformation) {
      mapping.insufficientInformationReason = undefined;
    }
    if (!mapping.notInScope) {
      mapping.notInScopeReason = undefined;
      mapping.notInScopeOtherReason = undefined;
    }
    if (!mapping.indirect) {
      mapping.hcpIds.length = 0;
    }
  }

  clearSite(mapping: ClinicalTrialProfileSiteMapping, shouldClearSiteMapping: any, addProof?: boolean) {
    this.clearMatchReasons(mapping);
    if (shouldClearSiteMapping) {
      mapping.site = null;
      if (addProof) {
        this.addProoflink(mapping, shouldClearSiteMapping);
      }
    }
  }

  addProoflink(mapping: ClinicalTrialProfileSiteMapping, takeAction) {
    if (takeAction) {
      if (!mapping.siteLinks) {
        mapping.siteLinks = [];
      }
      mapping.siteLinks.push('');
    }
  }

  addInvestigatorProoflink(mapping: ClinicalTrialProfilePersonMapping, takeAction) {
    if (takeAction) {
      if (!mapping.investigatorLinks) {
        mapping.investigatorLinks = [];
      }
      mapping.investigatorLinks.push('');
    }
  }

  async onMatchConfirm(mapping: ClinicalTrialProfileSiteMapping, match: ClinicalTrialSiteMapping) {
    if ('MATCH' === match.match.manual) {
      if (!mapping.site) {
        const site = this.service
          .obtainSite(match.affiliation)
          .pipe(take(1))
          .subscribe((site) => (mapping.site = site._id)); // default
      }
      mapping.foundMatches
        .filter((m) => !m.match.manual || 'NONE' === m.match.manual)
        .forEach((m) => (m.match.manual = 'MISMATCH')); // others are out, per se
    }
  }

  async handleNotInList(obj: any, prop: string, type: string) {
    const newVal = await this.valueNotInListService.open(type as ValueType, false);
    if (!newVal) {
      return;
    }
    obj[prop] = newVal.code;
  }

  onSave() {
    if (this.isExiting || this.isSubmitting) {
      // make sure we _never_ overlap
      return;
    }

    if (!this.confirmTodo()) {
      // native confirm is completely synchronous
      return;
    }

    this.saveRequests$.next(true); // Just a save w/out leaving, so make sure we don't overlap with another save
  }

  canSubmitJob(): boolean {
    return this.hasJobForCurrentUser() && this.svcAcl.hasCredential('clinicalTrialProfile.update');
  }

  onSubmitJob() {
    if (this.isExiting || this.isSubmitting) {
      // make sure we _never_ overlap
      return;
    }

    if (!this.confirmTodo(true)) {
      // native confirm is completely synchronous
      return;
    }
    if (!confirm('Submit now - you cannot go back')) {
      // (ditto)
      return;
    }

    this.isSubmitting = true;
    this.isExiting = true;

    this.service
      .update(this.profile._id, this.profile, true)
      .pipe(take(1))
      .subscribe(
        (res: ClinicalTrialProfile) => {
          this.profile._version = res._version;
          this.profile._meta = null;
          this.router.navigate(['/next']);
        },
        () => {
          alert('ERROR during save');
          this.isExiting = false;
        },
        () => {
          this.isSubmitting = false;
        }
      );
  }

  private confirmTodo(forceAll?: boolean): boolean {
    if (this.isQcJob()) {
      return true;
    }
    let openTodosAt = [];
    const datesTodo = this.allDates.filter((dt) => dt.obj.todo).map((dt) => dt.caption);
    if (
      //this.profile.enrolmentActual?.todo ||
      //this.profile.enrolmentAnticipated?.todo ||
      datesTodo.length > 0
      //this.profile.endpoints?.filter(ep => ep.todo).length > 0 ||
      //this.profile.patientCriteria?.filter(pc => pc.todo).length > 0 ||
      //this.profile.arms?.filter(arm => arm.todo).length > 0 ||
      //this.profile.interventions?.filter(inv => inv.todo).length > 0
    ) {
      openTodosAt.push('Main: ' + [...datesTodo].join(', '));
    }
    if (this.canUpdatePeople()) {
      const peopleTodo = (this.profile.personMappings || [])
        .map((pm, idx) => ({ pm, idx }))
        .filter((pm) => pm.pm.todo)
        .map((pm) => pm.idx + 1);
      if (peopleTodo.length > 0) {
        openTodosAt.push('People: #' + peopleTodo.join(','));
      }
    }
    if (this.canUpdateSites()) {
      const sitesTodo = (this.profile.siteMappings || [])
        .map((sm, idx) => ({ sm, idx }))
        .filter((sm) => sm.sm.todo)
        .map((sm) => sm.idx + 1);
      if (sitesTodo.length > 0) {
        openTodosAt.push('Sites: #' + sitesTodo.join(','));
      }
    }
    // ok, do we have any TODOs?
    if (openTodosAt.length > 0) {
      if (forceAll && !this.canSaveAnyway()) {
        alert('There are todo items left in ' + openTodosAt.join(', ') + ' - cannot continue');
        return false;
      } else {
        return confirm('There are todo items left in ' + openTodosAt.join(', ') + ' - save anyway?');
      }
    } else {
      return true;
    }
  }

  hasJobForCurrentUser(): boolean {
    return this.profileJob && this.myUserId && this.profile?._meta?.assignee === this.myUserId;
  }

  canSkip(): boolean {
    return this.svcAcl.hasCredential('job.skip');
  }

  async onJobDraft(): Promise<void> {
    if (this.isExiting || this.isSubmitting) {
      // make sure we _never_ overlap
      return;
    }

    this.isSubmitting = true;
    this.isExiting = true;

    Utils.removeEmptyAndDuplicatedElementsFromArray(this.profile, ['baseLinks']);

    this.service
      .update(this.profile._id, this.profile)
      .pipe(take(1))
      .subscribe(
        (res: ClinicalTrialProfile) => {
          this.profile._version = res._version;
          this.svcJob.setDraft(this.profileJob._id).subscribe(
            () => {
              this.isSubmitting = false;
              this.router.navigate(['/next']);
            },
            () => {
              this.isSubmitting = false;
              this.isExiting = false;
            }
          );
        },
        () => {
          alert('ERROR during save');
          this.isSubmitting = false;
          this.isExiting = false;
        }
      );
  }

  onJobUtc(): void {
    let comment = '';
    while (comment.length < 3) {
      comment = prompt('Enter a comment for Unable to Compile...');
      if (null === comment) {
        return;
      }
    }

    this.isSubmitting = true;
    this.isExiting = true;

    this.svcJob.setUtc(this.profileJob._id, comment).subscribe(
      () => {
        this.isSubmitting = false;
        this.router.navigate(['/next']);
      },
      () => (this.isSubmitting = false)
    );
  }

  onJobSkip(): void {
    if (!this.wndw.confirm('Sure to skip this job?')) {
      return;
    }

    this.isSubmitting = true;
    this.isExiting = true;

    this.svcJob.setSkipped(this.profileJob._id).subscribe(
      () => {
        this.isSubmitting = false;
        this.router.navigate(['/next']);
      },
      () => (this.isSubmitting = false)
    );
  }

  determineMatchCaption(match: ClinicalTrialSiteMapping) {
    if (match.affiliationName) {
      return (
        match.affiliationName +
        ' ' +
        (match.affiliationDepartment || '') +
        (match.affiliationAddress ? ' (' + match.affiliationAddress + ')' : '')
      );
    } else {
      return match.affiliation;
    }
  }

  getNextManualAddNo(mappings: { manualAddNo?: number }[]) {
    let curr = 0;
    (mappings || []).forEach((m) => (curr = Math.max(curr, m.manualAddNo || 0)));
    return curr + 1;
  }

  isQcJob(): boolean {
    return !!this.profileJob?.qcSessionId; // we have a session (created at assign, latest) = we do some QC
  }

  getQcForProp(
    obj: ClinicalTrialProfile | ClinicalTrialProfilePersonMapping | ClinicalTrialProfileSiteMapping,
    prop: string
  ): any {
    // (fields where we add the ladybug or separate ...Reviewed/allReviewed attributes to track the "reviewed" box without getting mixed up in other QC logic)
    obj.qc = obj.qc || {};
    obj.qc[prop] = obj.qc[prop] || {};
    return obj.qc[prop];
  }
  setQcVerified(
    obj: ClinicalTrialProfile | ClinicalTrialProfilePersonMapping | ClinicalTrialProfileSiteMapping,
    prop: string,
    event: any
  ) {
    const qc = this.getQcForProp(obj, prop);
    qc.checked = event.target.checked;

    if (qc.checked) {
      // (same as checking todo)
      this.saveRequests$.next(false); // Request save
    }
  }

  private jobTypeForCurrentTab(): string {
    if ('MAIN' === this.tab) {
      return 'CT-Timing';
    } else if ('PPL' === this.tab) {
      return 'CT-People';
    } else if ('SITES' === this.tab) {
      return 'CT-Sites';
    } else {
      return null;
    }
  }

  canAdhocQc(): boolean {
    if (!this.svcAcl.hasCredential('clinicalTrialProfile.qc')) {
      // need to be QC
      return false;
    }
    if (!this.jobTypeForCurrentTab()) {
      // need to be on a tab where we know how 2 translate
      return false;
    }
    if (this.profileJob) {
      // can only create when none else is active
      return false;
    }
    if (this.profile?._meta?.assignee) {
      // someone is already working
      return false;
    }
    // ok, we can give it a spin
    return true;
  }

  async startAdhocQc() {
    if (!this.canAdhocQc()) {
      return;
    }
    if (!this.wndw.confirm('Create ad-hoc ' + this.jobTypeForCurrentTab() + ' job?')) {
      return;
    }
    // call the service - possibly we get null as "can't create / reserve job"
    const newJob = await firstValueFrom(this.service.adhocQc(this.id, this.jobTypeForCurrentTab()));
    if (!newJob) {
      // (should be very rare)
      this.wndw.alert('Cannot start QCing now - somebody might be working on it. But a job is in your queue.');
      return;
    }
    // finally: completely reload - be sure to have it all initialized & redo all containers
    this.loadProfile(this.id, () => {
      this.hasRenderedInvestigators = false;
      this.investigatorsContainer.clear();
      this.hasRenderedSites = false;
      this.sitesContainer.clear();
      if ('PPL' === this.tab) {
        this.ensureToVerify(this.profile?.personMappings);
        this.renderInvestigators();
      } else if ('SITES' === this.tab) {
        this.ensureToVerify(this.profile?.siteMappings);
        this.renderSites();
      }
    });
  }

  getPeopleVerifiedCount() {
    return this.profile?.personMappings?.filter((pm) => pm.qc?.lineVerified?.checked).length || 0;
  }
  getSiteVerifiedCount() {
    return this.profile?.siteMappings?.filter((sm) => sm.qc?.lineVerified?.checked).length || 0;
  }

  isGreyedStatus(mapping: { status: ClinicalTrialCurationStatus }) {
    return (
      ClinicalTrialCurationStatus.Discard === mapping.status ||
      ClinicalTrialCurationStatus.Discontinue === mapping.status
    );
  }

  isRecentlyAddedMatch(match) {
    try {
      return new Date(match.createdAt || 0).getTime() >= Date.now() - 24 * 60 * 60 * 1000;
    } catch (_e) {
      // like malformatted
      console.error(_e);
    }
  }

  onClickSitesTab(): void {
    this.tab = 'SITES';
    this.ensureToVerify(this.profile?.siteMappings);

    if (this.hasRenderedSites || this.isLoadingSites) {
      return;
    }

    this.renderSites();
  }

  onClickInvestigatorsTab(): void {
    this.tab = 'PPL';
    this.ensureToVerify(this.profile?.personMappings);

    if (this.hasRenderedInvestigators || this.isLoadingInvestigators) {
      return;
    }

    this.renderInvestigators();
  }

  private ensureToVerify(collection: (ClinicalTrialProfilePersonMapping | ClinicalTrialProfileSiteMapping)[]) {
    if (collection && this.isQcJob()) {
      const needed = Math.ceil(collection.length * 0.2);
      const marked = () => collection.filter((m) => !!this.getQcForProp(m, 'lineVerified').needed).length;
      let runs = 0;
      const ceiling = collection.length * 10; // avoid infinite loop
      while (marked() < needed) {
        const addMarkIdx = Math.floor(Math.random() * collection.length);
        this.getQcForProp(collection[addMarkIdx], 'lineVerified').needed = true;
        if (runs++ > ceiling) {
          break;
        }
      }
    } // else no action
  }

  private renderSites(): void {
    const RENDERED_AT_ONCE = 100;
    const INTERVAL = 10; // ms

    let currentIndex = 0;

    this.isLoadingSites = true;
    this.hasRenderedSites = true; // never render more than once

    // Filter out the sites that match discarded persons
    const discardedSites = new Set(
      this.profile.personMappings
        .filter((person) => person.status === ClinicalTrialCurationStatus.Discard && person.site)
        .map((person) => person.site)
    );

    const filteredSiteMappings = this.profile.siteMappings.filter(
      (siteMapping) => !discardedSites.has(siteMapping.site)
    );

    const interval = setInterval(() => {
      const nextIndex = currentIndex + RENDERED_AT_ONCE;

      for (let i = currentIndex; i <= nextIndex; i++) {
        if (i >= filteredSiteMappings.length) {
          clearInterval(interval);
          break;
        }

        const context = {
          mapping: filteredSiteMappings[i],
          total: filteredSiteMappings.length,
          i,
        };

        this.sitesContainer.createEmbeddedView(this.siteRowTemplate, context);
      }

      currentIndex += RENDERED_AT_ONCE;

      this.isLoadingSites = false; // hide loading once we rendered the first batch
    }, INTERVAL);
  }

  private renderInvestigators(): void {
    const RENDERED_AT_ONCE = 100;
    const INTERVAL = 10; // ms

    let currentIndex = 0;

    this.isLoadingInvestigators = true;
    this.hasRenderedInvestigators = true; // never render more than once

    const interval = setInterval(() => {
      const nextIndex = currentIndex + RENDERED_AT_ONCE;

      for (let i = currentIndex; i <= nextIndex; i++) {
        if (i >= this.profile.personMappings.length) {
          clearInterval(interval);
          break;
        }

        const context = {
          mapping: this.profile.personMappings[i],
          total: this.profile.personMappings.length,
          i,
        };

        this.investigatorsContainer.createEmbeddedView(this.investigatorRowTemplate, context);
      }

      currentIndex += RENDERED_AT_ONCE;

      this.isLoadingInvestigators = false; // hide loading once we rendered the first batch
    }, INTERVAL);
  }

  removeFromByIndex(from: any[], idx: number): void {
    from.splice(idx, 1);
  }

  pushItemToList(list: any[]): void {
    list.push('');
  }

  onSetDiscard(mapping: { todo: boolean; status: string }, checked): void {
    mapping.status = checked ? ClinicalTrialCurationStatus.Discard : ClinicalTrialCurationStatus.Keep;

    mapping.todo = !(ClinicalTrialCurationStatus.Discard === mapping.status);

    this.saveRequests$.next(false); // Request save
  }

  onSetFieldDone(parent, field, checked) {
    if (!parent[field]) {
      parent[field] = {};
    }
    parent[field].todo = !checked;
  }

  onSetDone(mapping: { todo: boolean; status: string; foundMatches? }, checked): void {
    mapping.todo = !checked;

    if (!mapping.todo) {
      mapping.status = mapping.status || ClinicalTrialCurationStatus.Keep;

      if (mapping.foundMatches) {
        mapping.foundMatches
          .filter((m) => !m.match.manual || 'NONE' === m.match.manual)
          .forEach((m) => (m.match.manual = 'MISMATCH')); // discard the rest
      }

      this.saveRequests$.next(false); // Request save
    }
  }

  onAddInvestigator(): void {
    this.profile.personMappings.push({
      status: ClinicalTrialCurationStatus.Keep,
      roles: [{ role: null }],
      manualAddNo: this.getNextManualAddNo(this.profile.personMappings),
      todo: true,
      newManualMatchByCurator: true,
    } as any);

    const context = {
      mapping: this.profile.personMappings[this.profile.personMappings.length - 1],
      total: this.profile.personMappings.length,
      i: this.profile.personMappings.length - 1,
    };

    this.investigatorsContainer.createEmbeddedView(this.investigatorRowTemplate, context); // add one more row
  }

  onAddSite(): void {
    this.profile.siteMappings.push({
      status: ClinicalTrialCurationStatus.Keep,
      manualAddNo: this.getNextManualAddNo(this.profile.siteMappings),
      todo: true,
    } as any);

    const context = {
      mapping: this.profile.siteMappings[this.profile.siteMappings.length - 1],
      total: this.profile.siteMappings.length,
      i: this.profile.siteMappings.length - 1,
    };

    this.sitesContainer.createEmbeddedView(this.siteRowTemplate, context); // add one more row
  }

  populatePersonSite(kolId: string, srcText: string) {
    const facilities = this.baseTrialInvestigators;
    const facilityNames = Object.values(facilities).map((facility) => facility.inv.facility);
    const matchingFacility = facilityNames.find((facility) => facility && srcText.includes(facility));
    if (matchingFacility?.length > 0) {
      this.service.findAffiliation(kolId, matchingFacility.replace('/', '%2F')).subscribe((affiliation) => {
        if (affiliation && affiliation.length > 0) {
          this.bestMatchedAffiliation[kolId] = affiliation[0];
        }
      });
    }
  }

  roleIsAlreadySelected(currentRole: Value, mapping: ClinicalTrialProfilePersonMapping) {
    if (!mapping || !mapping.roles) {
      return false;
    }

    return mapping.roles.some((mapRole) => {
      return mapRole.role === currentRole.code;
    });
  }
  isFromBaseDataAffiliation(match) {
    const matchingPerson = Object.values(this.persons).find((person) =>
      this.profile.personMappings.find(
        (pm) => pm.kolId === person.kolId && person.affiliationName === match.affiliationName
      )
    );

    if (matchingPerson) {
      const investigatorRole = this.profile.personMappings
        .find((pm) => pm.kolId === matchingPerson.kolId)
        ?.roles?.map((item) => item.role)
        ?.join(', ');

      return `(From Affiliations) (Role: ${investigatorRole})`;
    }
  }

  async onOpenInvestigatorsSitesList(): Promise<void> {
    const affiliationsToInvestigatorMap = new Map<string, ClinicalTrialProfilePersonMapping[]>();
    cloneDeep(this.profile.personMappings)
      .filter((pm) => pm.status === ClinicalTrialCurationStatus.Keep)
      .forEach((pm) => {
        const affiliationIds = [
          ...new Set([
            ...(this.persons[pm.kolId]?.affiliationIds || []),
            ...(this.persons[pm.kolId]?.affiliationClinicalIds || []),
          ]),
        ];
        affiliationIds.forEach((affId) => {
          affiliationsToInvestigatorMap.set(affId, [...(affiliationsToInvestigatorMap.get(affId) || []), pm]);
        });

        // Get display name, we don't always have it in the person mappings since some of them were added manually
        pm.srcName = [
          this.persons[pm.kolId]?.firstName,
          this.persons[pm.kolId]?.middleName,
          this.persons[pm.kolId]?.lastName,
        ]
          .filter((p) => !!p)
          .join(' ');
      });

    const affiliationIds = Array.from(affiliationsToInvestigatorMap.keys());
    if (!affiliationIds?.length) {
      return;
    }

    const affiliations = (await firstValueFrom(
      this.affiliationService.getBaseinfo(affiliationIds, true)
    )) as ClinicalTrialProfileSiteDetails[];
    affiliations.forEach((aff) => {
      const investigators = affiliationsToInvestigatorMap.get(aff._id);
      aff.investigators = investigators.reduce((acc, investigator) => {
        const match = acc.find((r) => r.kolId === investigator.kolId);
        if (match) {
          match.roles.push(...investigator.roles);
        } else {
          acc.push(investigator);
        }

        return acc;
      }, []);
    });

    // Reference is shared via the portal, so whatever we do here, we'll also apply here.
    // cloneDeep will break all the references for us.
    const sitesGroups = groupBy(cloneDeep(affiliations), (aff) => aff.name);

    this.sitePopup = window.open('', '_target=blank', 'width=600,height=800');

    // Load external stylesheets (replace 'styles.css' with your main stylesheet)
    const stylesheets = window.document.querySelectorAll('link[rel="stylesheet"]');
    stylesheets.forEach((stylesheet: HTMLLinkElement) => {
      const clonedLink = this.sitePopup.document.createElement('link');
      clonedLink.rel = 'stylesheet';
      clonedLink.href = stylesheet.href;
      this.sitePopup.document.head.appendChild(clonedLink);
    });

    const injectorWithCustomData = Injector.create({
      parent: this.injector,
      providers: [
        {
          provide: CT_PROFILE_INVESTIGATOR_SITES_INJECTION_TOKEN,
          useValue: {
            sitesGroups,
            roles: this.roles,
          } as ClinicalTrialProfileInvestigatorsSitesListData,
        },
      ],
    });

    const portal = new ComponentPortal(
      ClinicalTrialProfileInvestigatorsSitesListComponent,
      null,
      injectorWithCustomData
    );
    const document = this.sitePopup.document;
    const portalOutlet = new DomPortalOutlet(
      document.body,
      this.componentFactoryResolver,
      this.appRef,
      injectorWithCustomData
    );

    portalOutlet.attach(portal);
  }

  isAnyCheckboxChecked(siteMapping: ClinicalTrialProfileSiteMapping): boolean {
    return siteMapping.direct || siteMapping.indirect || siteMapping.notInScope || siteMapping.insufficientInformation;
  }

  onIndirectChange(mapping: ClinicalTrialProfileSiteMapping) {
    if (mapping.indirect && (!mapping.hcpIds || mapping.hcpIds.length === 0)) {
      mapping.hcpIds = [''];
    }
  }

  addHcpId(mapping: ClinicalTrialProfileSiteMapping) {
    mapping.hcpIds.push('');
  }

  removeHcpId(mapping: ClinicalTrialProfileSiteMapping, index: number) {
    if (mapping.hcpIds.length > 1) {
      mapping.hcpIds.splice(index, 1);
    }
  }

  validateHcpIds(mapping: ClinicalTrialProfileSiteMapping): boolean {
    return mapping.hcpIds.length > 0 && mapping.hcpIds.some((id) => id.trim() !== '');
  }

  onHcpIdChange(hcpId: string, idx: number, mapping: ClinicalTrialProfileSiteMapping) {
    if (hcpId) {
      this.personService.exist([hcpId]).subscribe((res) => {
        if (res[0].invalid) {
          alert(`HCP ID ${hcpId} doesn't exist.`);
          mapping.hcpIds[idx] = '';
        }
      });
    }
  }

  trackByIndex(index: number, item: any): any {
    return index;
  }

  selectOnlyOne(mapping: ClinicalTrialProfileSiteMapping, checkbox: string) {
    mapping.direct = checkbox === 'direct';
    mapping.indirect = checkbox === 'indirect';
    mapping.insufficientInformation = checkbox === 'insufficientInformation';
    mapping.notInScope = checkbox === 'notInScope';

    if (checkbox !== 'direct') mapping.directReason = null;
    if (checkbox !== 'indirect') mapping.hcpIds = [];
    if (checkbox !== 'insufficientInformation') mapping.insufficientInformationReason = null;
    if (checkbox !== 'notInScope') mapping.notInScopeReason = null;
  }
}
