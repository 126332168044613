import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { AddressEntity } from '../../organizations/shared/address-entity';
import { AddressEntityCreateModalComponent } from './create-modal.component';

@Injectable()
export class AddressEntityCreateModalService {
  constructor(private svcModal: NgbModal) {}

  open(): Promise<AddressEntity> {
    const ref = this.svcModal.open(AddressEntityCreateModalComponent, { size: 'xl', backdrop: 'static' });

    return ref.result;
  }
}
