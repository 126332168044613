import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { firstValueFrom, Observable, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, switchMap, take, tap } from 'rxjs/operators';

import { ClinicalTrialSite } from '../../site';
import { ClinicalTrialProfileAPI } from '../../clinical-trial-profile-api.service';
import { OrganizationCreateRequestModalService } from '../../../../organizations/shared/create-request-modal/organization-create-request-modal.service';
import { OrganizationAPI, OrganizationSearchResultDTO } from '../../../../organizations/shared/api.service';

@Component({
  selector: 'dirt-site-single-select',
  templateUrl: 'site-single-select.html',
  styleUrls: ['site-single-select.scss'],
})
export class SiteSingleSelectComponent implements OnChanges {
  @Input()
  siteId: string;
  @Output() // (can [(siteId)]
  siteIdChange: EventEmitter<string> = new EventEmitter();
  @Input()
  wide: boolean = false;
  @Input()
  disabled: boolean = false;

  isSearching = false;
  searchTerm: string;

  @Input()
  existSites: { [_id: string]: ClinicalTrialSite } = {};
  @Input()
  doLoadAdditional: boolean = false; // so main component can itself load first
  // (plus we load on demand - but avoid 70 requests upfront with above)
  addSites: { [kolId: string]: ClinicalTrialSite } = {};

  @Input()
  matchedAffiliation: { id: string } = { id: null };

  searchOrganizations = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      tap(() => (this.isSearching = true)),
      switchMap((term) =>
        this.svcOrganization.search(term as any, 100, undefined, true).pipe(
          take(1),
          catchError(() => {
            return of([]);
          })
        )
      ),
      tap(() => (this.isSearching = false))
    );

  constructor(
    private readonly svcOrganization: OrganizationAPI,
    private readonly svcOrganizationCreateRequestModal: OrganizationCreateRequestModalService,
    private readonly svcClinicalTrialProfile: ClinicalTrialProfileAPI,
    private svcChangeDetect: ChangeDetectorRef
  ) {}

  async ngOnChanges(changes: SimpleChanges) {
    if (changes.matchedAffiliation && changes.matchedAffiliation.currentValue) {
      const affiliationId = changes.matchedAffiliation.currentValue.affiliationId;
      await this.onChangeSite(affiliationId);
      this.svcChangeDetect.markForCheck();
    }
  }

  private loadAnotherSite(siteId: string) {
    if (!this.doLoadAdditional || !siteId || this.existSites[siteId]) {
      return;
    }
    if (undefined === this.addSites[siteId]) {
      this.addSites[siteId] = null; // (we're loading)
      this.svcClinicalTrialProfile
        .findSitesByIds([siteId])
        .pipe(take(1))
        .subscribe((stes) => {
          if (stes.length > 0) {
            stes.forEach((ste) => (this.addSites[ste._id] = ste));
            this.svcChangeDetect.markForCheck(); // reset placeholder
          }
        });
    } // else (incl. null: we're loading already or have already)
  }

  async onChangeSite({
    preventDefault,
    item: selection,
  }: {
    preventDefault: () => void;
    item: OrganizationSearchResultDTO;
  }) {
    preventDefault();

    const mappingResults = await firstValueFrom(
      this.svcOrganization.getOrganizationMatchedAffiliationMappings({ organizationIds: [selection._id] })
    );
    if (mappingResults.length < 0) {
      console.error(`Affiliation mapping not found for organization. (orgId=${selection._id})`);
      return;
    }
    const mapping = mappingResults[0];

    this.obtainSite(mapping.affiliationId);
  }

  private obtainSite(affiliationId: string) {
    return this.svcClinicalTrialProfile
      .obtainSite(affiliationId)
      .pipe(take(1))
      .subscribe((ste) => {
        this.addSites[ste._id] = ste;
        this.siteIdChange.emit(ste._id);
        this.searchTerm = null;
        this.svcChangeDetect.markForCheck(); // reset placeholder
      });
  }

  onClearSite() {
    this.siteIdChange.emit(null);
    setTimeout(() => (this.searchTerm = null)); // (next apply)
  }

  goToOrganization(evnt, organizationId: string): void {
    evnt.preventDefault();
    evnt.stopPropagation();
    window.open('/organization/detail/' + organizationId, '_blank', 'noopener');
  }

  determineSiteCaption(siteId: string) {
    if (!siteId) {
      return '';
    }
    const siteInfo = this.existSites[siteId] || this.addSites[siteId] || null;
    if (siteInfo) {
      return (
        siteInfo.affiliationName +
        ' ' +
        siteInfo.affiliationDepartment +
        (siteInfo.affiliationAddress ? ' (' + siteInfo.affiliationAddress + ')' : '')
      );
    } else {
      this.loadAnotherSite(siteId);
      return siteId;
    }
  }

  determineAffiliationId(siteId: string): string {
    return (this.existSites[siteId] || this.addSites[siteId])?.['affiliation'];
  }

  showAffiliationModal() {
    this.svcOrganizationCreateRequestModal
      .open()
      .then((res) => {
        if (!res.affiliation) {
          return;
        }
        return this.obtainSite(res.affiliation.id);
      })
      .catch(() => {}); // (cancel)
  }
}
