import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Organization } from '../shared/organization';
import { ActivatedRoute, Router } from '@angular/router';
import { CreateResponse, InternalDuplicatesResponse, OrganizationAPI } from '../shared/api.service';
import { map, mergeMap, switchMap, take, tap } from 'rxjs/operators';
import { groupBy } from 'lodash';

import { AddressEntity } from '../shared/address-entity';
import { ExpandedEvent, OrganizationTreeLeaf } from '../shared/tree/tree-part.component';
import { firstValueFrom, forkJoin, Observable, of } from 'rxjs';
import { OrganizationDetailFormComponent } from '../shared/form/organization-detail-form.component';
import { Value } from '../../shared/services/value/value';
import { ValueType } from '../../shared/enum/value-type.enum';
import { ValueAPI } from '../../shared/services/value/value-api.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ACL } from '../../shared/acl/acl.service';
import { JobsAPI } from '../../jobs/shared/api.service';
import { Roles } from '../../shared/acl/roles';
import { Comment } from '../../comment/shared/comment';
import { Job } from '../../jobs/shared/job';
import { Auth } from '../../shared/services/auth/auth.service';
import { OrganizationJob } from '../shared/constant/job.enum';
import { AffiliationAPI } from '../../affiliation/shared/api.service';
import {
  OrganizationAffiliation,
  OrganizationAffiliationMappingCurationStatus,
} from '../../organization-affiliation/shared/organization-affiliation';
import { CreateRootDialogComponent, MODAL_OPTIONS } from '../shared/create-root-dialog/create-root-dialog.component';
import { AffiliationBaseinfo } from '../../affiliation/shared/affiliation-baseinfo';
import { OrganizationAffiliationAPI } from '../../organization-affiliation/shared/organization-affiliation-api.service';
import { Affiliation } from '../../affiliation/shared/affiliation';
import { OrganizationAdvancedSearchDto } from '../shared/organization-advanced-search-dto';
import { OrganizationMaintenanceRequestModalService } from '../shared/maintenance-request-modal/organization-maintenance-request-modal.service';

const LOCAL_STORAGE_ADVANCED_SEARCH_ORGANIZATION_KEY = 'clean-organizations-advanced-search-local';

/** A list of colors to show common duplicates in tree view */
const DUPLICATE_COLORS = ['#1b9e77', '#d95f02', '#7570b3', '#e7298a', '#66a61e', '#e6ab02', '#a6761d', '#666666'];

const REVIEW_QUOTA = {
  [OrganizationJob.COMPLETENESS_QC]: 0.05,
};
const REVIEW_QUOTA_DEFAULT = 0.05;

/** Describes the UI state for merging two organizations */
type MergePlan = { loserId: string; winnerId: string; mergeChildren: boolean };
type MergeState =
  | { type: 'default' }
  | { type: 'loserSelected'; loserId: string }
  | { type: 'loserAndWinnerSelected'; plan: MergePlan }
  | { type: 'merging'; plan: MergePlan }
  | {
      type: 'merged';
      plan: MergePlan;
      migratedAdditionalParents: { loserId: string; winnerId: string; additionalParentId: string }[];
    };

type MoveMode = 'SELECTED_AND_DESCENDANTS' | 'SELECTED_ONLY';
type DeleteMode = 'SELECTED_AND_DESCENDANTS' | 'SELECTED_ONLY';

/** Describes the UI state for splitting organizations */
type SplitPlan = { splitOrganizationIds: string[]; targetOrganization: Organization; includeChildren: boolean };
type SplitState =
  | { type: 'default' }
  | { type: 'splitOrganizationIdsSelected'; splitOrganizationIds: string[] }
  | { type: 'targetOrganizationSelected'; plan: SplitPlan }
  | { type: 'splitting'; plan: SplitPlan }
  | { type: 'splitted'; plan: SplitPlan }
  | { type: 'error'; plan: SplitPlan };

type LineageElement = { type: 'organization'; org: Organization } | { type: 'placeholder' };

@Component({
  selector: 'dirt-organization-detail',
  templateUrl: 'detail.component.html',
  styleUrls: ['detail.component.scss'],
})
export class OrganizationDetailComponent implements OnInit {
  id: string;
  subId: string = null;
  root: Organization = null; // via route param
  /** The currently selected organization */
  currentOrganization: Organization = null; // _id = null = adding
  newOrganization: Organization = null; // when we have it, display as first tab
  openOrganizationIds: string[] = [];
  editingOrganizations: { [id: string]: Organization } = {}; // have copies
  allOrganizations: { [id: string]: Organization } = {}; // (have one master map by ID)
  allAddresses: { [id: string]: AddressEntity } = {}; // (have one master map by ID)
  // moving = changing primary parent
  idsToMove: string[] = [];
  targetType: string;
  /** Merging state for merging two orgs */
  mergeState: MergeState = { type: 'default' };
  /** Merging state for merging two orgs */
  splitState: SplitState = { type: 'default' };
  // organize the tree
  childrenMap: { [parentId: string]: OrganizationTreeLeaf[] } = {};
  expandedMap: { [id: string]: boolean } = {};
  multiSelectMap: { [id: string]: boolean } = {};
  singleSelectHolder: { id: string } = { id: null }; // (can change the ID on the fly)
  createAnother: boolean = true;
  loading: boolean = false;
  buttonsDisabled: boolean = false;
  currentJob: Job & { qcSession? } = null; // might stay null, depends
  hasQcJob: boolean = false;
  hasQuotaQcJob: boolean = false;
  checkedIds: Set<string> = new Set();
  childrenToQcCount: number = 0;
  wndw: Window = window; // test-able

  /* State for duplicates segment above the tree */
  internalDuplicates: InternalDuplicatesResponse['duplicates'] = {};
  internalDuplicatesCount: number = 0;
  showDuplicates: boolean = false;
  colorMap: Record<string, string> = {};

  /* State for showing orgs without types above the tree */
  orgsWithoutType: Set<string> = new Set();

  commentSidebarOpen = false;
  commentMode: 'single' | 'all' = 'single';
  allComments: Comment[] = null;

  types: Value[] = [];
  typesMap: { [code: string]: string } = {};

  allAffiliations: Record<string, AffiliationBaseinfo> = {};
  /** All affiliation mappings for each organizationId */
  allAffiliationMappings: Record<string, OrganizationAffiliation[]> = {};
  /** A list of orgs from the root to the `this.currentOrganization` to show a link hierarchy */
  lineage: LineageElement[];
  showAffiliationSuspects = false;
  isCreatingOrganizationAffiliationMapping = false;
  selectedAffiliationForMapping: Affiliation | null = null;
  /**
   * A set of organization ids that might have changed and should be refreshed when opened the next time.
   * This allows us to lazily load data instead of pre-fetching all updated organizations after an action
   * took place that updates multiple organizations, i.e. a parent updates, addresses & their inheritance, etc.
   * */
  dirtyOrganizations = new Set<string>();

  @ViewChild('frmOrgDetail')
  frmOrgDetail: OrganizationDetailFormComponent;

  @ViewChild('changeTypeModal')
  changeTypeModal;

  @ViewChild('readyForDeliveryInfo')
  readyForDeliveryInfo;

  @ViewChild('mergeModal')
  mergeModal;
  /** A reference to the merge confirmation modal to close it in case of an error */
  confirmMergeModalRef: NgbModalRef = null;

  @ViewChild('splitModal')
  splitModal;

  @ViewChild('addAffiliationMappingModal')
  addAffiliationMappingModal;

  @ViewChild('advancedSearchDialog', { read: ElementRef }) advancedSearchDialogElement: ElementRef;
  advancedSearchSpec: OrganizationAdvancedSearchDto;
  advancedSearchResults: (Organization & { addressesMapped: AddressEntity[] })[];

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    public readonly svcOrganization: OrganizationAPI,
    public readonly svcOrganizationAffiliation: OrganizationAffiliationAPI,
    public readonly svcAffiliation: AffiliationAPI,
    public readonly svcValue: ValueAPI,
    private readonly svcMaintenanceModal: OrganizationMaintenanceRequestModalService,
    public svcAcl: ACL,
    private svcModal: NgbModal,
    private svcJob: JobsAPI,
    private svcAuth: Auth
  ) {}

  ngOnInit() {
    this.route.params
      .pipe(
        take(1),
        map((params) => {
          this.id = params['id'];
          this.subId = params['subid'] || null;
          return this.id;
        }),
        switchMap((id) => this.svcOrganization.findById(id))
      )
      .pipe(take(1))
      .subscribe((organization: Organization) => {
        if (!organization || !organization._id) {
          return;
        }
        this.root = organization;
        this.allOrganizations[organization._id] = organization;
        // (tree is built from root - nothing needed in the map)
        // expand after we've inited everything
        if (this.subId) {
          // open the ID in question
          this.svcOrganization
            .findById(this.subId)
            .pipe(take(1))
            .subscribe((selectedChild) => {
              this.allOrganizations[selectedChild._id] = selectedChild;
              this.onJumpToOrganization({ id: selectedChild._id });
              this.tryLoadJob(selectedChild);
            });
        } else {
          // open the organization
          this.onSingleSelect({ id: organization._id });
          this.tryLoadJob(organization);

          if (organization.isRoot) {
            // other case is mainly single review, where expanding only causes confusion
            this.onToggleExpanded({ id: organization._id, expanded: true });
          } else if (organization.parents?.length) {
            // load parents + root at least (esp. for reviews)
            const orgIdsToLoad = Array.from(new Set(organization.parents.concat([organization.root])));
            this.svcOrganization
              .findByIds({ ids: orgIdsToLoad })
              .pipe(take(1))
              .subscribe((parentsAndRoot: Organization[]) => {
                parentsAndRoot.forEach((org) => (this.allOrganizations[org._id] = org));
                this.ensureAllAddressesMappingsAndInstitutions(parentsAndRoot);
                // Recalculate lineage, since we might now be able to show the root node, which we didn't know before
                this.lineage = this.getLineageForOrganization(this.allOrganizations, organization._id);
              });
          }
        }

        this.updateInternalDuplicates();
        this.tryLoadJob(organization);
      });

    this.svcValue
      .find(ValueType.OrganizationType, Number.MAX_SAFE_INTEGER)
      .pipe(take(1))
      .subscribe((values) => {
        this.types = values;
        this.types.forEach((value) => (this.typesMap[value.code] = value.title));
      });
  }

  private refreshAffiliationMappings(orgIds: string[]) {
    this.getLinkedAffiliationIds(orgIds)
      .pipe(take(1))
      .subscribe((res) => {
        this.storeMappings(res);
      });
  }

  private tryLoadJob(organization: Organization) {
    this.currentJob = null;
    this.hasQcJob = false;
    this.hasQuotaQcJob = false;
    this.checkedIds = new Set();
    this.childrenToQcCount = 0;

    if (organization._meta?.jobId) {
      this.svcJob
        .findById(organization._meta.jobId, true)
        .pipe(take(1))
        .subscribe(async (job) => {
          const currentUser = await firstValueFrom(this.svcAuth.getProfile());
          if (job._meta?.assignee === currentUser.user_id) {
            this.currentJob = job;
            this.hasQcJob = !!job.type.endsWith('_QC');

            // KS-4908: Some jobs target child orgs and never the root, but the curators are linked to the child-only view,
            // so here we redirect them to the child deep link to show tree context.
            if (
              (
                [
                  OrganizationJob.TRANSFORMATION_COMPILATION_QC,
                  OrganizationJob.MAPPING_COMPILATION,
                  OrganizationJob.MAPPING_QC,
                  OrganizationJob.TYPES_QC,
                ] as string[]
              ).includes(job.type) &&
              !organization.isRoot &&
              !this.subId
            ) {
              return this.router.navigate(['/organization/detail', organization.root, job.entityId]);
            }

            // KS-4908 If the curator is doing a `ORGANIZATION_MAPPING_COMPILATION` job, we want to open
            // affiliation suspects directly
            if (
              [OrganizationJob.MAPPING_COMPILATION, OrganizationJob.MAPPING_QC].includes(job.type as OrganizationJob)
            ) {
              this.showAffiliationSuspects = true;
            }

            if (this.hasQcJob && OrganizationJob.COMPLETENESS_QC === job.type) {
              // so far; might have more
              this.hasQuotaQcJob = true;
              // initially compute checked Ids
              Object.entries(this.currentJob.qcSession?.entities || {}).forEach(([entityId, entity]) => {
                if (!!(entity as any)?.orgVerified?.checked) {
                  // "orgVerified" is the property we put checked under (like "lineVerified" in CT)
                  this.checkedIds.add(entityId);
                }
              });
              // now we also have to know how much we might be supposed to QC (never transformed)
              this.svcOrganization
                .countForRoot(this.root._id)
                .pipe(take(1))
                .subscribe(
                  ({ count }) =>
                    (this.childrenToQcCount = Math.ceil((REVIEW_QUOTA[job.type] || REVIEW_QUOTA_DEFAULT) * count))
                ); // 5% are needed
            } else if (this.hasQcJob && OrganizationJob.CORRECTNESS_QC === job.type) {
              // open root instead of current and scroll back
              const childOrgId = organization._id;
              if (this.allOrganizations[organization.root]) {
                this.root = this.allOrganizations[organization.root];
                this.onJumpToOrganization({ id: childOrgId });
              } else {
                // (need to load)
                this.svcOrganization
                  .findById(organization.root)
                  .pipe(take(1))
                  .subscribe((rootOrg) => {
                    this.allOrganizations[rootOrg._id] = rootOrg;
                    this.root = rootOrg;
                    this.onJumpToOrganization({ id: childOrgId });
                  });
              }
            }
          }
        });
    }
  }

  async refreshOrganizations(orgIds: string[]): Promise<void> {
    const uniqueIds = Array.from(new Set(orgIds));
    const orgs = await firstValueFrom(this.svcOrganization.findByIds({ ids: uniqueIds }));

    orgs.forEach((org) => {
      // update book-keeping
      this.allOrganizations[org._id] = org;
      if (this.currentOrganization?._id === org._id) {
        this.currentOrganization = org;
      }
      if (this.root._id === org._id) {
        this.root = org;
      }
      // so that the tree is updated
      const parentId = org.parents?.[0];

      if (!parentId) {
        return;
      }

      if (!this.childrenMap[parentId]) {
        this.childrenMap[parentId] = [];
      }

      const existingChildIndex = this.childrenMap[org.parents?.[0]]?.findIndex((child) => child.id === org._id);
      if (existingChildIndex === -1) {
        // need to insert
        this.childrenMap[org.parents?.[0]].push(this.createTreeEntry(org));
      } else {
        // need to update
        this.childrenMap[org.parents?.[0]][existingChildIndex] = this.createTreeEntry(org);
      }
      // unmark the org as dirty
      this.markOrgAsClean(org._id);
    });
  }

  onToggleExpanded(evnt: ExpandedEvent) {
    this.expandedMap[evnt.id] = evnt.expanded;
    if (evnt.expanded) {
      this.expandOrganization(evnt.id);
    }
  }
  async onSingleSelect(evnt: { id }) {
    if (evnt.id) {
      this.singleSelectHolder.id = evnt.id;
      if (!this.openOrganizationIds.includes(evnt.id)) {
        this.openOrganizationIds.push(evnt.id);
      }
      // ensure addresses and parents (incl. secondaries) loaded
      this.ensureAllAddressesMappingsAndInstitutions([this.allOrganizations[evnt.id]]);

      const orgIdsToLoad: string[] = [];
      // find missing parents
      (this.allOrganizations[evnt.id].parents || [])
        .filter((parentId) => !this.allOrganizations[parentId])
        .forEach((id) => orgIdsToLoad.push(id));
      // check if we need to refresh the currently selected org
      if (this.isDirty(evnt.id)) {
        orgIdsToLoad.push(evnt.id);
      }
      await this.refreshOrganizations(orgIdsToLoad);
      // switch tab
      this.currentOrganization = this.editOrOtherOrg(evnt.id);
      this.lineage = this.getLineageForOrganization(this.allOrganizations, this.currentOrganization._id);
    }
  }

  async onExpandToElement(evnt: { id }) {
    // we can assume we have at least the item itself - now work
    // Sometimes we don't have the target node in `allOrganizations`, so by making sure we have the entry,
    // we can jump to any node at any arbitrary depth
    if (!this.allOrganizations[evnt.id]) {
      const org = await firstValueFrom(this.svcOrganization.findById(evnt.id).pipe(take(1)));
      this.allOrganizations[evnt.id] = org;
    }

    const current: Organization = this.allOrganizations[evnt.id];

    if (current.isRoot) {
      this.onToggleExpanded({ id: evnt.id, expanded: true });
      return; // we've successfully expanded it all
    }
    if (!current.parents?.[0]) {
      return console.warn('Not root and no parent - cannot help it');
    }

    // expand all parents, even non-primary parents
    // this allows us to jump to alien children in the tree whose parent does not live in `parents[0]`
    // and it avoids loading data for nodes out-of-tree/out of the current health system
    const promises = [];
    current.parents.forEach((parent) => {
      if (this.allOrganizations[parent]) {
        // expand next level up
        promises.push(this.onExpandToElement({ id: parent }));
        this.onToggleExpanded({ id: parent, expanded: true }); // make sure we have siblings as well
      } else {
        promises.push(
          firstValueFrom(this.svcOrganization.findById(parent)).then(async (organization) => {
            this.allOrganizations[organization._id] = organization;
            await this.onExpandToElement({ id: parent }); // next level up
            this.onToggleExpanded({ id: parent, expanded: true }); // make sure we have siblings as well
          })
        );
      }
    });

    return Promise.all(promises);
  }

  isDirty(orgId: string): boolean {
    return this.dirtyOrganizations.has(orgId);
  }
  markOrgAsDirty(orgId: string) {
    this.dirtyOrganizations.add(orgId);
  }
  markOrgAsClean(orgId: string) {
    this.dirtyOrganizations.delete(orgId);
  }

  async onJumpToOrganization(evnt: { id }) {
    // open both the tree and the tab
    try {
      await this.onExpandToElement({ id: evnt.id });
    } catch (e) {
      /** even on error we want to single select it */
    } finally {
      this.onSingleSelect({ id: evnt.id });
    }
  }

  onExpandAll() {
    if (!this.wndw.confirm('Really expand all - might take a bit?')) {
      return;
    }
    // load all
    this.svcOrganization
      .findForRoot(this.root._id, undefined, Number.MAX_SAFE_INTEGER, undefined, undefined)
      .pipe(take(1))
      .subscribe((organizations) => {
        organizations
          .filter(
            (child) => !this.allOrganizations[child._id] || this.allOrganizations[child._id]._version !== child._version
          ) // same version = no change = keep pot. work here
          .forEach((organization) => {
            this.allOrganizations[organization._id] = organization;
            organization.parents?.forEach((parent) => {
              this.childrenMap[parent] = [...(this.childrenMap[parent] || []), this.createTreeEntry(organization)];
            });

            // KS-5365 keep a list of orgs without types
            if (!organization.type) {
              this.orgsWithoutType.add(organization._id);
            }
          });
        organizations.forEach((organization) => (this.expandedMap[organization._id] = true));
        // ensure addresses in batches
        const uniqueAddresses = [...new Set(organizations.flatMap((o) => o.addresses || []))];
        let pos = 0;
        const step = 100;
        while (uniqueAddresses.length > pos) {
          this.ensureAllAddressesMappingsAndInstitutions(organizations.slice(pos, pos + step));
          pos += step;
        }
      });
  }

  private async expandOrganization(parentId: string): Promise<void> {
    if (!parentId) {
      // This function might be called with nullish values, since TS is not configured strictly enough,
      // causing to expand and load all addresses we have in the database
      return;
    }

    // load children, add to map and tree
    const children = await firstValueFrom(
      this.svcOrganization.findForParent(parentId, undefined, Number.MAX_SAFE_INTEGER, undefined, undefined)
    );
    children
      .filter(
        (child) => !this.allOrganizations[child._id] || this.allOrganizations[child._id]._version !== child._version
      ) // same version = no change = keep pot. work here
      .forEach((child) => (this.allOrganizations[child._id] = child));
    this.childrenMap[parentId] = children.map((child) => this.createTreeEntry(child));
    this.ensureAllAddressesMappingsAndInstitutions(children);
  }

  showAdditionalTypeInformation(): boolean {
    return this.svcAcl.hasRole(Roles.OrganizationTypeCompiler) || this.svcAcl.hasRole(Roles.OrganizationReviewer);
  }

  orgDisplayName(org: Organization) {
    if (this.showAdditionalTypeInformation()) {
      return org.name + ' (' + (this.typesMap[org.type] || org.type || '-') + ')';
    }
    return org.name;
  }

  createTreeEntry(org: Organization): OrganizationTreeLeaf {
    return {
      id: org._id,
      title: this.orgDisplayName(org),
      url: org.websource,
      isRoot: org.isRoot,
      isDraft: org._meta?.status === 'IN_PROGRESS',
      isAlien: this.isAlien(org),
      isMarkedForLater: org.markedForLater,
      isUtc: this.isUnableToCompile(org),
      parents: org.parents,
      childrenInProgress: org.childrenInProgress,
      childrenMarkedForLater: org.childrenMarkedForLater,
      isMarkedForDeletion: org.isMarkedForDeletion,
    };
  }

  /**
   * Makes sure we have loaded all address-entities & organizations-affiliations-mapping and connected institution records associated with any of the `organizations`.
   */
  private ensureAllAddressesMappingsAndInstitutions(organizations: Organization[]) {
    // load addresses we don't yet have
    let idsNotLoaded = organizations
      .flatMap((o) => o.addresses?.map((a) => a.addressId))
      .filter((aId) => aId && !this.allAddresses[aId]);
    while (idsNotLoaded.length > 0) {
      const idsSlice = idsNotLoaded.slice(0, 50);
      idsNotLoaded = idsNotLoaded.slice(50);
      this.svcOrganization
        .findAddressByIds(idsSlice.join(','), idsSlice.length, undefined, undefined)
        .pipe(take(1))
        .subscribe((addresses) => addresses.forEach((address) => (this.allAddresses[address._id] = address)));
    }

    // Load all mappings for the given organization ids and their connected institutions
    this.getLinkedAffiliationIds(organizations.map((o) => o._id)).subscribe((res) => {
      this.storeMappings(res);

      // load base info for all linked affiliations
      const affiliationIdsNotLoaded = res
        .flat()
        .map((e) => e.affiliationId)
        .filter((affiliationId) => !this.allAffiliations[affiliationId]);

      if (affiliationIdsNotLoaded.length > 0) {
        this.svcAffiliation
          .getBaseinfo(affiliationIdsNotLoaded)
          .pipe(take(1))
          .subscribe((affiliations) =>
            affiliations.forEach((affiliationBaseInfo) => {
              this.allAffiliations = { ...this.allAffiliations, [affiliationBaseInfo._id]: affiliationBaseInfo };
            })
          );
      }
    });
  }

  private storeMappings(mappings: OrganizationAffiliation[]) {
    const groupedMappings = groupBy(mappings, (mapping) => mapping.organizationId);
    for (const [key, value] of Object.entries(groupedMappings)) {
      this.allAffiliationMappings[key] = value;
    }
  }

  getCheckboxIds(): string[] {
    return Object.entries(this.multiSelectMap)
      .filter(([id, sel]) => !!sel)
      .map(([id, sel]) => id);
  }
  hasAlienChecked(): boolean {
    return !!this.getCheckboxIds().find((id) => this.isAlien(this.allOrganizations[id]));
  }
  getOneCheckboxId(): string {
    const selIds = this.getCheckboxIds();
    return 1 === selIds.length ? selIds[0] : null;
  }
  onNewFromTree() {
    const oneCheckboxId = this.getOneCheckboxId();
    if (oneCheckboxId) {
      this.currentOrganization = this.newOrganization = {
        _id: null,
        parents: [oneCheckboxId],
      } as Organization;
      this.onChooseNew();
    }
  }

  onChooseNew() {
    if (this.newOrganization) {
      this.currentOrganization = this.newOrganization;
      this.singleSelectHolder.id = null;
    }
  }
  onCloseNew() {
    const isActiveTab = !this.currentOrganization._id;
    this.newOrganization = null;
    if (isActiveTab) {
      this.currentOrganization =
        this.openOrganizationIds.length > 0 ? this.editOrOtherOrg(this.openOrganizationIds[0]) : null;
      this.singleSelectHolder.id = this.currentOrganization?._id || null;
    }
  }
  onChooseExisting(id: string) {
    this.currentOrganization = this.editOrOtherOrg(id);
    this.singleSelectHolder.id = id;
  }
  onCloseExisting(id: string, index: number) {
    if (this.editingOrganizations[id]) {
      if (!this.wndw.confirm('Discard changes?')) {
        return;
      }
      delete this.editingOrganizations[id];
    }
    const isActiveTab = this.currentOrganization?._id === id;
    this.openOrganizationIds.splice(index, 1);
    if (isActiveTab) {
      const siblingId = this.openOrganizationIds[Math.max(0, index - 1)];
      this.currentOrganization =
        this.openOrganizationIds.length > 0 ? this.editOrOtherOrg(siblingId) : this.newOrganization;
      this.singleSelectHolder.id = this.currentOrganization?._id || null;
    }
  }
  onCloseAll() {
    if (!this.wndw.confirm('Close all - discard all changes?')) {
      return;
    }
    this.newOrganization = null;
    this.openOrganizationIds = [];
    this.currentOrganization = null;
    this.editingOrganizations = {};
    this.singleSelectHolder.id = this.currentOrganization?._id || null;
  }

  isAlien(organization: Organization) {
    return (
      !!organization &&
      !organization.isRoot &&
      !!organization._id &&
      organization.root?.toString() !== this.root.root.toString() // this.root.root is safest opt - also works when displaying a sub-tree
    );
  }
  isCurrentReadOnly() {
    if (this.isOrgMarkedForDeletion(this.currentOrganization._id)) {
      return true;
    }
    // only existing when "Edit" was not clicked
    if (this.isAlien(this.currentOrganization)) {
      // special treatment for alient children (i.e. coming from diff root)
      return true;
    }
    return !!this.currentOrganization._id && !this.editingOrganizations[this.currentOrganization._id];
  }
  private editOrOtherOrg(id: string): Organization {
    return this.editingOrganizations[id] || this.allOrganizations[id];
  }
  private tempDisableButtons() {
    this.buttonsDisabled = true;
    this.wndw.setTimeout(() => (this.buttonsDisabled = false), 100); // don't accidentially click "save and close"
  }
  onEdit() {
    if (this.isAlien(this.currentOrganization)) {
      return;
    }
    this.editingOrganizations[this.currentOrganization._id] = JSON.parse(JSON.stringify(this.currentOrganization)); // deep copy
    this.currentOrganization = this.editingOrganizations[this.currentOrganization._id];
    if (this.hasQcJob) {
      // if we are in a QC job, try get existing QC as well
      (this.editingOrganizations[this.currentOrganization._id] as any).qc =
        this.currentJob.qcSession?.entities?.[this.currentOrganization._id];
      this.editingOrganizations[this.currentOrganization._id].addresses?.forEach(
        (a, i) => ((a as any).qc = this.currentJob.qcSession?.entities?.[this.currentOrganization._id + '-a' + i])
      );
    }
    this.tempDisableButtons();
  }
  onRevert() {
    if (!this.wndw.confirm('Revert changes?')) {
      return;
    }
    // load again & set readonly
    delete this.editingOrganizations[this.currentOrganization._id];
    this.tempDisableButtons();
    this.loading = true;
    this.svcOrganization
      .findById(this.currentOrganization._id)
      .pipe(take(1))
      .subscribe((organization) => {
        this.allOrganizations[organization._id] = organization;
        if (organization._id === this.root._id) {
          this.root = organization;
        }
        this.currentOrganization = organization;
        this.loading = false;
        this.ensureAllAddressesMappingsAndInstitutions([organization]);
      });
  }
  onCreate() {
    if (!this.isFormValid()) {
      return;
    }
    const parent = this.currentOrganization.parents[0];
    this.loading = true;
    this.svcOrganization
      .create(this.currentOrganization, false)
      .pipe(take(1))
      .subscribe((createRes: CreateResponse) => {
        if (this.createAnother) {
          // new org, same parent
          this.currentOrganization = this.newOrganization = {
            _id: null,
            parents: [parent],
          } as Organization;
        } else {
          this.loading = false;
          this.onCloseNew();
        }
        // in any case: add to children of our current parent and to map of all
        if (createRes.type === 'success') {
          createRes.res.parents?.forEach((parentId) => {
            this.childrenMap[parentId] = [...(this.childrenMap[parentId] || []), this.createTreeEntry(createRes.res)];
          });
          this.allOrganizations[createRes.res._id] = createRes.res;
          this.updateInternalDuplicates();
          this.refreshBottomUp([createRes.res._id]);
        } else if (createRes.type === 'exact-duplicate') {
          // show duplicates in a quick alarm window
          alert(`Child organization already exists: ${createRes.duplicates.map((d) => d._id).join(', ')}`);
        }
        this.loading = false;
      });
  }
  onSave() {
    if (!this.isFormValid()) {
      return;
    }
    this.loading = true;
    this.saveInternal().subscribe(() => {
      this.loading = false;
      // (only set non-editable)
      delete this.editingOrganizations[this.currentOrganization._id];

      // KS-5365 keep a list of orgs without types
      if (this.currentOrganization?.type) {
        this.orgsWithoutType.delete(this.currentOrganization._id);
      }

      this.tempDisableButtons();
    });
  }
  onSaveAndClose() {
    if (!this.isFormValid()) {
      return;
    }
    this.loading = true;
    this.saveInternal().subscribe(() => {
      this.loading = false;
      delete this.editingOrganizations[this.currentOrganization._id];
      // find the index of our element and close it
      const orgId = this.currentOrganization._id;
      const idx = this.openOrganizationIds.indexOf(orgId);
      this.onCloseExisting(orgId, idx);
      // (also no edit any more and still take care of buttons)
      this.tempDisableButtons();
    });
  }
  isFormValid(): boolean {
    return !!this.frmOrgDetail?.isValid();
  }
  private saveInternal(): Observable<any> {
    // so we can chain new or close
    if (!this.currentOrganization?._id) {
      throw 'Need existing organization to save';
    }
    if (this.hasQcJob) {
      // might have been deleted while QC was going on
      return this.svcOrganization
        .findById(this.currentOrganization._id)
        .pipe(take(1))
        .pipe(
          mergeMap((org: Organization) => {
            if (org) {
              // exists
              return this.saveInternalImpl();
            } else {
              alert(
                'We are sorry, but the organization you reviewed does no longer exist. We recommand refreshing as more might have changed. '
              );
              throw new Error('Review on deleted');
            }
          })
        );
    } else {
      return this.saveInternalImpl();
    }
  }
  private saveInternalImpl(): Observable<any> {
    // so we can chain new or close
    if (this.hasQuotaQcJob) {
      // document we qc-ed
      this.checkedIds.add(this.currentOrganization._id);
      (this.currentOrganization as any).qc = {
        ...((this.currentOrganization as any).qc || {}),
        orgVerified: { checked: true },
      }; // will produce a checked entry on the server
    }

    const cleanedOrganization = <Organization>{
      ...this.currentOrganization,
      alternativeNames: this.currentOrganization.alternativeNames.filter((n) => n !== '' && n != null),
    };

    return this.svcOrganization
      .update(
        this.currentOrganization._id,
        cleanedOrganization,
        this.hasQcJob ? this.currentJob.qcSessionId : undefined
      )
      .pipe(take(1))
      .pipe(
        map((updateRes) => {
          // update tree items - switch children that are us
          updateRes.res.parents?.forEach((parentId) => {
            if (this.childrenMap[parentId]) {
              this.childrenMap[parentId] = this.childrenMap[parentId].map((mapEntry) =>
                mapEntry.id === updateRes.res._id ? this.createTreeEntry(updateRes.res) : mapEntry
              );
            }
          });
          // (replace with
          this.allOrganizations[this.currentOrganization._id] = updateRes.res;
          if (updateRes.res._id === this.root._id) {
            this.root = updateRes.res;
          }
          this.currentOrganization = updateRes.res;
          // if we are in a QC job, pull the QC session again (async will do)
          if (this.hasQcJob && this.currentJob.qcSessionId) {
            this.svcJob
              .findById(this.currentJob.id, true)
              .pipe(take(1))
              .subscribe((job) => (this.currentJob.qcSession = job.qcSession));
          }

          // After the update, it might be that any descendant organizations below the updated org needs to be refreshed.
          // We can mark all of those orgs as dirty to cause a lazy reload of the Organization entity when the user opens it.
          const allDescendantIds = this.getAllSubTreeIds(updateRes.res._id);
          allDescendantIds.forEach((orgId) => {
            this.markOrgAsDirty(orgId);
          });

          return updateRes;
        })
      );
  }

  onMoveToSelected(moveMode: MoveMode = 'SELECTED_AND_DESCENDANTS') {
    // submit patch and update allOrganizations and adjust children maps
    if (this.idsToMove.length < 1) {
      throw new Error('Need IDs to move');
    }

    const newPrimaryParent = this.getOneCheckboxId();
    if (!newPrimaryParent) {
      throw new Error('Need a target');
    }

    const editing = this.idsToMove.filter((id) => this.editingOrganizations[id]);
    if (editing.length > 0) {
      return this.wndw.alert(
        'Cannot move while editing ' + editing.map((id) => this.allOrganizations[id].name).join(', ')
      );
    }
    if (this.idsToMove.includes(this.root._id)) {
      return this.wndw.alert('Cannot move the top level element');
    }
    if (this.idsToMove.includes(newPrimaryParent)) {
      return this.wndw.alert('Cannot move element under itself');
    }

    // Remove orgs that we want to move from their respective parent's childrenMap entries, so we update the tree
    this.idsToMove.forEach((id) => {
      const organization = this.allOrganizations[id];
      if (!organization) {
        return;
      }

      // Update children map to reflect that the moved ID is no longer a children of this organization
      const oldParentId = organization.parents?.[0];
      // Remove the moved child from their old parent's childrenMap entry
      if (oldParentId && this.childrenMap[oldParentId]) {
        this.childrenMap[oldParentId] = this.childrenMap[oldParentId].filter(({ id }) => id !== organization._id);
      }
      // Add the moved child to the new parent's childrenMap entry
      this.childrenMap[newPrimaryParent].push(this.createTreeEntry(organization));
    });

    this.loading = true;

    this.svcOrganization
      .move({
        idsToMove: this.idsToMove,
        newParent: newPrimaryParent,
        moveChildren: moveMode === 'SELECTED_AND_DESCENDANTS',
      })
      .pipe(tap(() => (this.loading = false)))
      .subscribe({
        complete: () => {
          this.refreshOrganizations(this.idsToMove);
          this.onMergeReset();
        },
        error: (err) => {
          this.onMergeReset();
        },
      });
  }

  async onChangeTypeOfSelect() {
    // change - submit patch and update allOrganizations
    const idsToChange = this.getCheckboxIds();
    if (idsToChange.length < 1) {
      throw 'Need IDs to change';
    }
    const editing = idsToChange.filter((id) => this.editingOrganizations[id]);
    if (editing.length > 0) {
      return this.wndw.alert(
        'Cannot change while editing ' + editing.map((id) => this.allOrganizations[id].name).join(', ')
      );
    }
    this.targetType = null;
    const modal = this.svcModal.open(this.changeTypeModal, { size: 'lg' });
    try {
      await modal.result;
    } catch (_e) {
      return; // (we just clicked cancel)
    }
    if (!this.targetType) {
      return;
    }
    this.loading = true;
    forkJoin(
      idsToChange.map((id) =>
        this.svcOrganization
          .update(id, { type: this.targetType, _version: this.allOrganizations[id]._version /*be safe*/ })
          .pipe(take(1))
      )
    ).subscribe((results: { res: Organization }[]) => {
      results.forEach((res) => (this.allOrganizations[res.res._id] = res.res)); // (we'll get the updated org)
      this.loading = false;
      if (this.currentOrganization?._id) {
        // update current, if we have one
        this.currentOrganization = this.allOrganizations[this.currentOrganization._id];
      }
      this.multiSelectMap = {}; // reset sel
      // update tree items as well
      results.forEach((res) => {
        const parentIds = res.res.parents || [];
        parentIds.forEach((p) => {
          this.childrenMap[p] = (this.childrenMap[p] || []).map((mapEntry) =>
            mapEntry.id === res.res._id ? this.createTreeEntry(res.res) : mapEntry
          ); // do the replacement IN-PLACE
        });
      });
    });
  }

  hasEditingOrganizations(): boolean {
    return Object.keys(this.editingOrganizations).length > 0;
  }

  onSubmitRootJob() {
    // QC jobs will have to be a button next to edit / save
    if (!this.currentJob || this.hasEditingOrganizations()) {
      return;
    }

    // Tell user that there are children that are unable to compile in the tree
    const numberUTCChildren = Object.values(this.allOrganizations).filter((org) => this.isUnableToCompile(org)).length;
    if (
      numberUTCChildren &&
      !this.wndw.confirm(`Are you sure? There are still ${numberUTCChildren} organizations that are unable to compile!`)
    ) {
      return;
    }

    if (this.root.childrenInProgress?.length > 0) {
      if (
        !this.wndw.confirm(
          `Are you sure? There are still ${this.root.childrenInProgress?.length} child organizations in progress!`
        )
      ) {
        return;
      }
    } else {
      if (!this.wndw.confirm('Submit the full organization and all children? - can not edit afterwards!')) {
        return;
      }
    }

    this.svcOrganization
      .submitJob({ organization: this.root })
      .pipe(take(1))
      .subscribe(() => this.router.navigate(['/next']));
  }

  onSubmitChildJob() {
    // QC jobs will have to be a button next to edit / save
    if (!this.currentJob || this.hasEditingOrganizations()) {
      return;
    }

    // The curator gets a warning when submitting a UTC child
    const org = this.allOrganizations[this.currentJob.entityId];
    if (
      org &&
      this.isUnableToCompile(org) &&
      !this.wndw.confirm(`Are you sure? This organization was set as unable to compile!`)
    ) {
      return;
    }

    if (!this.wndw.confirm('Submit the organization? - can not edit afterwards!')) {
      return;
    }

    this.svcOrganization
      .submitJob({
        organization: this.allOrganizations[this.currentJob.entityId],
        affiliationMappings: this.allAffiliationMappings[this.currentJob.entityId],
      })
      .pipe(take(1))
      .subscribe(() => this.router.navigate(['/next']));
  }

  onJobDraft() {
    if (!this.currentJob || this.hasEditingOrganizations()) {
      return;
    }
    this.svcJob.setDraft(this.currentJob._id).subscribe(() => this.router.navigate(['/next']));
  }

  onJobUtc() {
    if (!this.currentJob || this.hasEditingOrganizations()) {
      return;
    }

    let comment = '';
    while (comment.length < 3) {
      comment = prompt('Enter a comment for Unable to Compile...');
      if (null === comment) {
        return;
      }
    }

    this.svcJob.setUtc(this.currentJob._id, comment).subscribe(() => this.router.navigate(['/next']));
  }

  onJobSkip() {
    if (!this.currentJob || this.hasEditingOrganizations()) {
      return;
    }

    this.svcJob.setSkipped(this.currentJob._id).subscribe(() => this.router.navigate(['/next']));
  }

  onSetUtc() {
    if (!this.currentOrganization) {
      return;
    }

    const newUtcValue = this.currentOrganization._meta?.status === 'UNABLE_TO_COMPILE' ? false : true;
    if (
      newUtcValue &&
      !window.confirm(
        'Are you sure to set the selected organization to UNABLE_TO_COMPILE? This will delete all pending jobs for this organization.'
      )
    ) {
      return;
    }

    this.svcOrganization
      .setOrganizationUtcStatus({ orgId: this.currentOrganization._id, utcValue: newUtcValue })
      .subscribe(() => {
        this.refreshOrganizations([this.currentOrganization._id]);
      });
  }

  isUnableToCompile(org: Organization) {
    return org._meta?.status === 'UNABLE_TO_COMPILE';
  }

  async onSplitSelectSplitOrganizations() {
    const selected = this.getCheckboxIds();
    if (this.mergeState.type === 'default' && selected.length > 0) {
      this.splitState = { type: 'splitOrganizationIdsSelected', splitOrganizationIds: selected };
      this.multiSelectMap = {};

      try {
        const modal = this.svcModal.open(this.splitModal, { size: 'lg' });
        await modal.result;
        this.onSplitReset();
      } catch (_e) {
        this.onSplitReset();
        return; // (we just clicked cancel)
      }
    }
  }

  onSplitSelectTargetOrganization(event: Organization) {
    if (this.splitState.type === 'splitOrganizationIdsSelected' && event) {
      this.splitState = {
        type: 'targetOrganizationSelected',
        plan: {
          splitOrganizationIds: this.splitState.splitOrganizationIds,
          targetOrganization: event,
          includeChildren: false,
        },
      };
    }
  }

  async onOpenCreateOrganizationDialog(): Promise<void> {
    const modal = this.svcModal.open(CreateRootDialogComponent, MODAL_OPTIONS);
    // Reload once done
    const createdOrganization = await modal.result;

    if (this.splitState.type === 'splitOrganizationIdsSelected' && createdOrganization) {
      this.splitState = {
        type: 'targetOrganizationSelected',
        plan: {
          includeChildren: false,
          splitOrganizationIds: this.splitState.splitOrganizationIds,
          targetOrganization: createdOrganization,
        },
      };
    }
  }

  async onSplitConfirm(): Promise<void> {
    if (this.splitState.type !== 'targetOrganizationSelected') {
      return this.onSplitReset();
    }

    if (!this.wndw.confirm('Are you sure?')) {
      return;
    }

    this.splitState = { type: 'splitting', plan: this.splitState.plan };

    this.svcOrganization
      .split({
        splitOrganizationIds: this.splitState.plan.splitOrganizationIds,
        targetOrganizationId: this.splitState.plan.targetOrganization._id.toString(),
        includeChildren: this.splitState.plan.includeChildren,
      })
      .subscribe({
        complete: async () => {
          if (this.splitState.type === 'splitting') {
            this.splitState = { type: 'splitted', plan: this.splitState.plan };
            // Remove all splitted organizations from the current tree
            this.splitState.plan.splitOrganizationIds.forEach((removedOrgId) => {
              this.handleRemovalOfNode(this.allOrganizations[removedOrgId]);
            });
          }
        },
        error: () => {
          if (this.splitState.type === 'splitting') {
            this.splitState = { type: 'error', plan: this.splitState.plan };
          }
        },
      });
  }

  onMergeSelectLoser() {
    const selected = this.getCheckboxIds();
    if (this.mergeState.type === 'default' && selected.length === 1) {
      const loserId = selected[0];
      this.mergeState = { type: 'loserSelected', loserId };
      this.multiSelectMap = {};
    }
  }

  /**
   * One type of org merge is to merge children of the loser into children of the winner in a recursive fashion, ie.
   *  - merging a loser child into a winner child if they are similar enough and recursing their children subtrees
   *  - moving a loser child to be a child of the winner subtree
   *
   * Another type of org merge is to merge loser into winner but without the loser's children.
   * In this case, the loser's children are moved to become siblings of the winner, ie. they are moved up one level of hierarchy
   */
  async onMergeSelectWinner(mergeChildren: boolean) {
    const selected = this.getCheckboxIds();

    if (this.mergeState.type === 'loserSelected' && selected.length === 1) {
      const winnerId = selected[0];
      const loserId = this.mergeState.loserId;

      try {
        this.confirmMergeModalRef = this.svcModal.open(this.mergeModal, { size: 'lg' });
        this.mergeState = { type: 'loserAndWinnerSelected', plan: { loserId, winnerId, mergeChildren } };
        await this.confirmMergeModalRef.result;
        this.onMergeReset();
      } catch (_e) {
        this.onMergeReset();
        return; // (we just clicked cancel)
      }
    }
  }

  async onMergeConfirm() {
    if (
      this.mergeState.type === 'loserAndWinnerSelected' &&
      this.mergeState.plan.loserId !== this.mergeState.plan.winnerId
    ) {
      const loserId = this.mergeState.plan.loserId;
      const winnerId = this.mergeState.plan.winnerId;

      const payload = { loserId, winnerId, mergeChildren: this.mergeState.plan.mergeChildren };
      this.mergeState = { type: 'merging', plan: this.mergeState.plan };

      this.svcOrganization.mergeOrganizations(payload).subscribe({
        next: async (res) => {
          if (this.mergeState.type === 'merging') {
            this.mergeState = {
              type: 'merged',
              plan: this.mergeState.plan,
              migratedAdditionalParents: res.migratedAdditionalParents,
            };

            // Once merging is done, we need to update
            // - the winner org data, since it might got merged properties from the loser
            // - the winner's parent so the tree view refreshes
            // - the loser's children org data, as their parents value always changes
            const loserChildren = Object.values(this.allOrganizations)
              .filter((org) => org.parents?.[0] === loserId)
              .map((org) => org._id);
            const orgRefreshes = [
              winnerId,
              this.allOrganizations[this.mergeState.plan.loserId].parents?.[0],
              ...loserChildren,
            ].filter((id) => !!id);
            // - if children are merged, the winner's children hierarchy, since it might have gotten new children
            // - if children are not merged, the loser's parent hierarchy, since the loser children were moved up one level
            const hierarchyRefresh = [winnerId, this.allOrganizations[this.mergeState.plan.loserId].parents?.[0]];

            orgRefreshes.forEach((id) => {
              this.refreshOrganizations([id]);
              this.refreshAffiliationMappings([id]);
            });
            [...orgRefreshes, ...hierarchyRefresh]
              .filter((id) => !!id)
              .forEach((id) => {
                this.onToggleExpanded({ id, expanded: false });
                this.onToggleExpanded({ id, expanded: true });
              });
          }
        },
        error: () => {
          this.onMergeReset();
          this.confirmMergeModalRef.close();
        },
      });
    } else {
      this.onMergeReset();
    }
  }

  isMerging(): boolean {
    return this.mergeState.type !== 'default';
  }

  onMergeReset() {
    this.mergeState = { type: 'default' };
    this.idsToMove = [];
    this.multiSelectMap = {};
  }

  isSplitting(): boolean {
    return this.splitState.type !== 'default';
  }

  onSplitReset() {
    this.splitState = { type: 'default' };
    this.multiSelectMap = {};
  }

  onSetChildReady() {
    this.setChildrenReady([this.currentOrganization._id]);
  }

  onSetMultipleChildrenReady() {
    const ids = this.getCheckboxIds();
    this.setChildrenReady(ids);
  }

  private setChildrenReady(childrenIds: string[]) {
    if (childrenIds.length === 0) {
      return;
    }

    // first ready each child individually
    forkJoin(childrenIds.map((orgId) => this.svcOrganization.readyChild({ orgId }).pipe(take(1)))).subscribe(() => {
      // then do one refresh for all, avoiding unnecessary refreshes
      this.refreshBottomUp(childrenIds);
    });
  }

  /**
   * Starting from a set of nodes `startNodeIds`, we find all unique parents and update them.
   *
   * Uses the local tree data to backtrack to find all (grand-)parents, so it might be outdated, depending on the scenario,
   * but this avoids loading the whole tree top-down for when we are only interested in all parents of `startNodeIds`.
   * */
  private async refreshBottomUp(startNodeIds: string[]) {
    // For all starting nodes, find all transitive parents, which will form the of nodes to update
    const allTransitiveParentIds = startNodeIds.flatMap((id) => {
      const lineage = this.getLineageForOrganization(this.allOrganizations, id);
      return lineage.map((e) => (e.type === 'organization' ? e.org._id : null)).filter((e) => !!e);
    });
    const idsToUpdate = new Set<string>([...startNodeIds, ...allTransitiveParentIds]);

    // update all parents
    const orgs = await firstValueFrom(this.svcOrganization.findByIds({ ids: Array.from(idsToUpdate) }));
    for (const org of orgs) {
      if (!org) {
        continue;
      }

      this.allOrganizations[org._id] = org;
      if (this.root._id === org._id) {
        this.root = org;
      }
      org.parents?.forEach((p) => {
        this.childrenMap[p] = (this.childrenMap[p] || []).map((mapEntry) =>
          mapEntry.id === org._id ? this.createTreeEntry(org) : mapEntry
        ); // do the replacement IN-PLACE
      });
      if (this.currentOrganization && this.currentOrganization._id === org._id) {
        this.currentOrganization = org;
      }
      if (this.editingOrganizations[org._id] && this.editingOrganizations[org._id]._id === org._id) {
        this.editingOrganizations[org._id]._meta = org._meta; // even when editing, make sure we keep new status
      }
    }
  }

  private updateInternalDuplicates() {
    this.svcOrganization
      .getInternalDuplicates(this.id)
      .pipe(take(1))
      .subscribe((internalDuplicates: InternalDuplicatesResponse) => {
        this.internalDuplicates = internalDuplicates.duplicates;
        this.internalDuplicatesCount = Object.keys(this.internalDuplicates).length;
        this.colorMap = this.buildColorMap(this.internalDuplicates);
      });
  }

  private buildColorMap(internalDuplicates: Record<string, { count: number; duplicates: { _id: string }[] }>) {
    const colorMap: Record<string, string> = {};
    Object.keys(internalDuplicates).forEach((key, idx) => {
      colorMap[key] = DUPLICATE_COLORS[idx % DUPLICATE_COLORS.length];
    });
    return colorMap;
  }

  isRootCheckboxSelected(): boolean {
    return this.multiSelectMap[this.root._id];
  }

  toggleShowDuplicates() {
    this.showDuplicates = !this.showDuplicates;
  }

  onDelete(mode: DeleteMode = 'SELECTED_AND_DESCENDANTS') {
    const id = this.singleSelectHolder.id;
    if (!id) {
      return;
    }

    const currentlySelected = this.allOrganizations[id];
    if (
      !this.wndw.confirm(
        `Are you sure you want to delete ${currentlySelected.name} ${
          mode === 'SELECTED_AND_DESCENDANTS' ? 'and its children' : ''
        }?`
      )
    ) {
      return;
    }

    this.loading = true;
    this.svcOrganization
      .delete(id, mode)
      .pipe(take(1))
      .subscribe({
        next: (res) => {
          switch (res.type) {
            case 'success': {
              const idsToRefresh = [];
              const descendantsIds = this.getAllSubTreeIds(id);

              // once the subtree is deleted, jump back to direct parent/root
              this.handleRemovalOfNode(currentlySelected, mode);

              if (currentlySelected.parents?.[0]) {
                idsToRefresh.push(currentlySelected.parents[0]);
              }

              if (mode === 'SELECTED_ONLY') {
                idsToRefresh.push(...descendantsIds);
              }

              if (idsToRefresh.length > 0) {
                this.refreshBottomUp(idsToRefresh);
              }
              break;
            }
            case 'alien': {
              const alienName = this.allOrganizations[res.id]?.name || res.id;
              this.onJumpToOrganization({ id: res.id });
              alert(`Cannot delete organization ${currentlySelected.name} because of alien child ${alienName}`);
              break;
            }
            case 'markedForDeletion': {
              alert(
                `The organization ${currentlySelected.name} has been marked for deletion. The system will delete it when possible.`
              );
              this.refreshBottomUp([id]);
              break;
            }
          }
          this.loading = false;
        },
        error: () => {
          this.loading = false;
        },
      });
  }

  async handleRemovalOfNode(removedOrg: Organization, mode: DeleteMode = 'SELECTED_AND_DESCENDANTS') {
    // jump to the next parent
    this.onJumpToOrganization({ id: removedOrg.parents?.[0] || removedOrg.root });

    // refresh up to the parent
    await this.expandOrganization(removedOrg.parents?.[0]);

    // close open tab on removed node
    this.openOrganizationIds = this.openOrganizationIds.filter((o) => o !== removedOrg._id);

    // Also close tabs for every children of deleted organization (e.g. when the entire subtree is deleted)
    if (mode === 'SELECTED_AND_DESCENDANTS') {
      const descendantsIds = this.getAllSubTreeIds(removedOrg._id);
      this.openOrganizationIds = this.openOrganizationIds.filter((id) => !descendantsIds.includes(id));
    }

    // remove parent-child link to update tree view
    this.childrenMap[removedOrg.parents?.[0]] = this.childrenMap[removedOrg.parents?.[0]].filter(
      ({ id }) => id !== removedOrg._id
    );

    // Direct children of deleted org goes up one level
    if (mode === 'SELECTED_ONLY') {
      this.childrenMap[removedOrg.parents?.[0]].push(...this.childrenMap[removedOrg._id]);
    }

    // Clear selection state
    this.multiSelectMap = {};

    if (removedOrg.isRoot) {
      this.router.navigate(['/organization/list']);
    }
  }

  onPullAllComments() {
    if (!this.allComments) {
      this.svcOrganization
        .findAllComments(this.root._id)
        .pipe(take(1))
        .subscribe((comments) => {
          this.allComments = comments;
          this.commentMode = 'all';
        });
    } else {
      this.commentMode = 'all';
    }
  }

  onSelectLookupItem(org: Organization) {
    if (!org) {
      return;
    }
    this.onJumpToOrganization({ id: org._id });
  }

  onCopyLink(id): void {
    const baseUrl = location.href.substring(0, location.href.indexOf('/', 10));
    navigator.clipboard.writeText(
      baseUrl + '/organization/detail/' + this.root._id + (this.root._id !== id ? '/' + id : '')
    );
  }

  getLinkedAffiliationIds(organizationIds: string[]): Observable<OrganizationAffiliation[]> {
    if (!organizationIds || organizationIds.length === 0) {
      return of([]);
    }

    return this.svcOrganization.getOrganizationAffiliationMappings({ organizationIds }).pipe(take(1));
  }

  /**
   * Updates the currently worked on organization and sets the flag to mark it for later
   */
  onMarkForLater(org: Organization) {
    if (!org) {
      return;
    }

    this.svcOrganization.update(org._id, { markedForLater: !!!org.markedForLater }).subscribe((res) => {
      this.refreshOrganizations([org._id]);
      this.refreshOrganizations([this.root._id]);
    });
  }

  /**
   * Walk the a sub-tree recursively starting from startId and get the IDs of every loaded descendants.
   *
   * The basis for iteration is what the client has currently loaded so far, i.e. which sub-trees in the tree view
   * the user has opened so far.
   */
  private getAllSubTreeIds(startId: string): string[] {
    const ids = [];
    const getChildrenIds = (startId: string) => {
      const children = this.childrenMap[startId];
      if (!Array.isArray(children) || children.length === 0) {
        // Nothing to do
        return ids;
      }

      const childrenIds = children.map((c) => c.id);

      ids.push(...childrenIds);

      childrenIds.forEach((id) => getChildrenIds(id));

      return ids;
    };

    return getChildrenIds(startId);
  }

  async openAddAffiliationMappingDialog() {
    try {
      const modal = this.svcModal.open(this.addAffiliationMappingModal, { size: 'lg' });
      await modal.result;
    } catch (_e) {
      return; // (we just clicked cancel)
    }
  }

  onSelectAffiliationForNewMapping(selectedAffiliation: Affiliation) {
    this.selectedAffiliationForMapping = selectedAffiliation;
  }

  onCreateAffiliationForNewMapping() {
    if (this.selectedAffiliationForMapping) {
      this.isCreatingOrganizationAffiliationMapping = true;
      this.svcOrganizationAffiliation
        .create({
          affiliationId: this.selectedAffiliationForMapping.id,
          organizationId: this.currentOrganization._id,
          statusCurated: OrganizationAffiliationMappingCurationStatus.UNPOLISHED,
        } as OrganizationAffiliation)
        .pipe(
          take(1),
          tap(() => (this.isCreatingOrganizationAffiliationMapping = false))
        )
        .subscribe((res) => {
          this.selectedAffiliationForMapping = null;
          this.ensureAllAddressesMappingsAndInstitutions([this.currentOrganization]);
        });
    }
  }

  /**
   * Builds the lineage of specified org `orgId` starting at the root as a series of `LineageElement`.
   */
  getLineageForOrganization(allOrgs: Record<string, Organization>, orgId: string): LineageElement[] {
    const lineage: LineageElement[] = [];
    let org = allOrgs[orgId];

    if (!org) {
      return lineage;
    }

    while (org) {
      lineage.push({ type: 'organization', org: org });
      org = org && org.parents?.length > 0 && allOrgs[org.parents?.[0]];
    }

    // KS-5625: since we always load the root, we can always push the root to the lineage to show the user
    const root = this.allOrganizations[this.allOrganizations[orgId].root];
    if (root && !lineage.find((e) => e.type === 'organization' && e.org._id === root._id)) {
      lineage.push({ type: 'placeholder' });
      lineage.push({ type: 'organization', org: root });
    }

    return lineage.reverse();
  }

  onMatchChange(orgAffiliation: OrganizationAffiliation) {
    const canEditAffiliation = this.svcAcl.hasCredential('organization.affiliation.update');
    if (canEditAffiliation) {
      this.svcOrganizationAffiliation
        .update(orgAffiliation._id, { statusCurated: orgAffiliation.statusCurated })
        .subscribe((res) => {
          // update the mapping data
          const existingMappings = this.allAffiliationMappings[orgAffiliation.organizationId];
          existingMappings[existingMappings.findIndex((m) => m._id === res._id)] = res;
          this.allAffiliationMappings[orgAffiliation.organizationId] = existingMappings;
        });
    }
  }

  getAffiliationLabel(affiliationId: string): string | null {
    const aff = this.allAffiliations[affiliationId];
    return aff ? `${aff.name} ${aff.department ? ' - ' + aff.department : ''}` : null;
  }

  isJobForRootOrg(): boolean {
    return this.currentJob && this.root && this.currentJob?.entityId === this.root?.root;
  }

  openAdvancedSearch() {
    this.advancedSearchSpec = JSON.parse(
      this.wndw.localStorage.getItem(LOCAL_STORAGE_ADVANCED_SEARCH_ORGANIZATION_KEY) || '{"filter": { "address": {} }}'
    );
    this.advancedSearchDialogElement.nativeElement.show();
  }

  async runAdvancedSearch() {
    if (this.isAdvancedSearchDisabled()) {
      return;
    }
    this.advancedSearchSpec.searchWithinHealthsystem = this.root._id;
    this.advancedSearchDialogElement?.nativeElement?.close();

    // persist to localstorage
    localStorage.setItem(LOCAL_STORAGE_ADVANCED_SEARCH_ORGANIZATION_KEY, JSON.stringify(this.advancedSearchSpec));

    try {
      const res = await firstValueFrom(this.svcOrganization.advancedSearch(this.advancedSearchSpec));
      this.advancedSearchResults = res.items;
    } finally {
    }
  }

  clearAdvancedSearch() {
    this.advancedSearchDialogElement.nativeElement.close();
    this.advancedSearchSpec = null;
    this.advancedSearchResults = null;
    this.wndw.localStorage.removeItem(LOCAL_STORAGE_ADVANCED_SEARCH_ORGANIZATION_KEY);
  }

  isAdvancedSearchDisabled(): boolean {
    const isEmptyObject = (o: any) => Object.values(o || {}).filter((f) => !!f).length < 1;
    return (
      !this.advancedSearchSpec ||
      isEmptyObject(this.advancedSearchSpec.filter) ||
      (isEmptyObject(this.advancedSearchSpec.filter.address) &&
        !this.advancedSearchSpec.filter.websource &&
        !this.advancedSearchSpec.filter.organizationId &&
        !this.advancedSearchSpec.filter.organizationName)
    );
  }

  onMaintenanceRequest() {
    this.svcMaintenanceModal.open(this.currentOrganization._id);
  }

  onRemoveDeletionRequest(orgId: string) {
    if (!orgId) {
      return;
    }

    if (!confirm(`Are you sure to remove the deletion request for ${this.currentOrganization?.name}?`)) {
      return;
    }

    this.svcOrganization.removeDeletionRequest(orgId).subscribe(() => {
      this.refreshOrganizations([orgId]);
    });
  }

  isOrgMarkedForDeletion(orgId: string): boolean {
    return this.allOrganizations[orgId]?.isMarkedForDeletion;
  }

  getSingleSelectedOrganization(): Organization | null {
    return this.singleSelectHolder.id ? this.allOrganizations[this.singleSelectHolder.id] : null;
  }

  /**
   * UI Action State
   */
  isCreateNewDisabled() {
    return (
      !this.svcAcl.hasCredential('organization.create') ||
      !this.getOneCheckboxId() ||
      this.idsToMove.length > 0 ||
      this.isAlien(this.allOrganizations[this.getOneCheckboxId()]) ||
      this.isMerging() ||
      this.isSplitting() ||
      this.getCheckboxIds().some((id) => this.isOrgMarkedForDeletion(id))
    );
  }

  isCollapseAllDisabled() {
    return !this.root?.isRoot || this.isMerging() || this.isSplitting();
  }

  isExpandAllDisabled() {
    return !this.root?.isRoot || this.isMerging() || this.isSplitting();
  }

  isMoveDisabled() {
    return (
      !this.svcAcl.hasCredential('organization.move') ||
      this.getCheckboxIds().length < 1 ||
      this.hasAlienChecked() ||
      this.isMerging() ||
      this.isSplitting() ||
      this.getCheckboxIds().some((id) => this.isOrgMarkedForDeletion(id))
    );
  }

  isMoveToSelectedDisabled() {
    return (
      !this.getOneCheckboxId() ||
      this.isMerging() ||
      this.isSplitting() ||
      this.getCheckboxIds().some((id) => this.isOrgMarkedForDeletion(id))
    );
  }

  isCancelMoveDisabled() {
    return this.idsToMove.length > 0;
  }

  isChangeTypeDisabled() {
    return (
      !this.svcAcl.hasCredential('organization.update.prop.type') ||
      this.svcAcl.hasCredential('organization.update.readonly.prop.type') ||
      this.getCheckboxIds().length < 1 ||
      this.getCheckboxIds().some((id) => this.isOrgMarkedForDeletion(id)) ||
      this.hasAlienChecked() ||
      this.idsToMove.length > 0 ||
      this.isMerging() ||
      this.isSplitting()
    );
  }

  isSetReadyFormDisabled() {
    return (
      !this.svcAcl.hasCredential('organization.update') ||
      this.hasAlienChecked() ||
      this.isMerging() ||
      this.isSplitting() ||
      this.loading ||
      this.buttonsDisabled ||
      this.isAlien(this.currentOrganization) ||
      this.isOrgMarkedForDeletion(this.currentOrganization._id) ||
      this.currentOrganization?.childrenInProgress?.length > 0
    );
  }

  isSetReadyTreeControlsDisabled() {
    return (
      !this.svcAcl.hasCredential('organization.update') ||
      this.getCheckboxIds().length < 1 ||
      this.hasAlienChecked() ||
      this.idsToMove.length > 0 ||
      this.isRootCheckboxSelected() ||
      this.isMerging() ||
      this.isSplitting() ||
      this.loading ||
      this.buttonsDisabled ||
      this.isAlien(this.currentOrganization) ||
      this.getCheckboxIds().some((id) => this.isOrgMarkedForDeletion(id))
    );
  }

  isMergeDisabled() {
    return (
      this.getCheckboxIds().length !== 1 ||
      this.hasAlienChecked() ||
      this.idsToMove.length > 0 ||
      this.isRootCheckboxSelected() ||
      this.getCheckboxIds().some((id) => this.isOrgMarkedForDeletion(id))
    );
  }

  isSplitDisabled() {
    return (
      this.getCheckboxIds().length === 0 ||
      this.hasAlienChecked() ||
      this.idsToMove.length > 0 ||
      this.isRootCheckboxSelected() ||
      this.isMerging() ||
      this.isOrgMarkedForDeletion(this.currentOrganization._id) ||
      this.getCheckboxIds().some((id) => this.isOrgMarkedForDeletion(id))
    );
  }

  isMergeIntoDisabled() {
    return (
      this.getCheckboxIds().length !== 1 ||
      this.hasAlienChecked() ||
      this.idsToMove.length > 0 ||
      this.getCheckboxIds().some((id) => this.isOrgMarkedForDeletion(id))
    );
  }

  isSetUtcDisabled() {
    return (
      this.loading ||
      this.buttonsDisabled ||
      this.isAlien(this.currentOrganization) ||
      this.isOrgMarkedForDeletion(this.currentOrganization._id)
    );
  }

  isEditDisabled() {
    return (
      this.loading ||
      this.buttonsDisabled ||
      this.isAlien(this.currentOrganization) ||
      this.isOrgMarkedForDeletion(this.currentOrganization._id)
    );
  }

  isJobSubmissionDisabled() {
    return (
      // user has to exit edit mode first
      this.hasEditingOrganizations() ||
      // in a QC job with a quota, we need to check if the quota was actually reached
      (this.hasQuotaQcJob && this.checkedIds.size < this.childrenToQcCount) ||
      // in a mapping curation job, no mapping may be unpolished
      (this.currentJob &&
        [OrganizationJob.MAPPING_COMPILATION, OrganizationJob.MAPPING_QC].includes(
          this.currentJob.type as OrganizationJob
        ) &&
        this.allAffiliationMappings[this.currentJob.entityId].some(
          (oam) => oam.statusCurated === OrganizationAffiliationMappingCurationStatus.UNPOLISHED
        ))
    );
  }

  isRequestMaintenanceDisabled() {
    return this.isOrgMarkedForDeletion(this.currentOrganization._id);
  }

  /**
   * In the infobox we want to only show mappings with a curation status of `MATCH`, so that non-curators see only curated data.
   */
  getMatchedAffiliationMappings(orgId: string): OrganizationAffiliation[] {
    return (
      this.allAffiliationMappings[orgId]?.filter(
        (mapping) => mapping.statusCurated === OrganizationAffiliationMappingCurationStatus.MATCH
      ) || []
    );
  }

  onOpenReadyForDeliveryInfoDialog() {
    this.svcModal.open(this.readyForDeliveryInfo);
  }
}
