import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';
import { CommentModule } from '../comment/comment.module';
import { organizationRouting } from './organization.routes';
import { OrganizationComponent } from './organization.component';
import { OrganizationListComponent } from './list/list.component';
import { OrganizationAPI } from './shared/api.service';
import { OrganizationDetailFormComponent } from './shared/form/organization-detail-form.component';
import { OrganizationDetailComponent } from './detail/detail.component';
import { OrganizationTreePartComponent } from './shared/tree/tree-part.component';
import { OrganizationLookupComponent } from './shared/form/lookup/organization-lookup.component';
import { AddressLookupComponent } from './shared/form/lookup/address-lookup.component';
import { AddressTile } from './shared/tile/address-tile';
import { CreateRootDialogComponent } from './shared/create-root-dialog/create-root-dialog.component';
import { AffiliationModule } from '../affiliation/affiliation.module';
import { OrganizationTreeSimpleComponent } from './shared/tree-simple/tree-simple.component';
import { OrganizationSelectModal } from './shared/select-modal/select-modal.component';
import { OrganizationSelectModalService } from './shared/select-modal/select-modal.service';
import { OrganizationAffiliationModule } from '../organization-affiliation/organization-affiliation.module';
import { OrganizationAdvancedSearchComponent } from './shared/advanced-search/advanced-search';
import { OrganizationMaintenanceModalComponent } from './shared/maintenance-request-modal/organization-maintenance-request-modal.component';
import { OrganizationMaintenanceRequestModalService } from './shared/maintenance-request-modal/organization-maintenance-request-modal.service';
import { OrganizationSingleSelectComponent } from './shared/single-select/organization-single-select.component';
import { OrganizationCreateRequestModalComponent } from './shared/create-request-modal/organization-create-request-modal.component';
import { OrganizationCreateRequestModalService } from './shared/create-request-modal/organization-create-request-modal.service';
import { AddressEntityModule } from '../address-entity/address-entity.module';

@NgModule({
  imports: [
    SharedModule,
    CommentModule,
    organizationRouting,
    AffiliationModule,
    OrganizationAffiliationModule,
    AddressEntityModule,
  ],
  declarations: [
    OrganizationComponent,
    OrganizationListComponent,
    OrganizationDetailFormComponent,
    OrganizationTreePartComponent,
    OrganizationLookupComponent,
    AddressLookupComponent,
    AddressTile,
    OrganizationDetailComponent,
    CreateRootDialogComponent,
    OrganizationTreeSimpleComponent,
    OrganizationSelectModal,
    OrganizationAdvancedSearchComponent,
    OrganizationMaintenanceModalComponent,
    OrganizationSingleSelectComponent,
    OrganizationCreateRequestModalComponent,
  ],
  providers: [
    OrganizationAPI,
    OrganizationSelectModalService,
    OrganizationMaintenanceRequestModalService,
    OrganizationCreateRequestModalService,
  ],
  exports: [OrganizationSingleSelectComponent],
})
export class OrganizationModule {}
