import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgForm } from '@angular/forms';
import { tap } from 'rxjs';

import { ACL } from '../../../shared/acl/acl.service';
import { OrganizationAPI } from '../api.service';

@Component({
  selector: 'dirt-organization-maintenance-request-modal',
  templateUrl: './organization-maintenance-request-modal.component.html',
})
export class OrganizationMaintenanceModalComponent implements OnInit {
  @Input()
  organizationId: string;

  @Input()
  isCustomerRequest: boolean = false;

  @Input()
  requestOwnerProduct?: string;

  newJobDesc: any = {};

  isSubmitting: boolean = false;

  canUpdateOrganization: boolean;

  @ViewChild(NgForm)
  public jobForm: NgForm;

  constructor(
    private readonly activeModal: NgbActiveModal,
    private readonly svcOrganization: OrganizationAPI,
    private readonly svcAcl: ACL
  ) {}

  ngOnInit(): void {
    this.canUpdateOrganization = this.svcAcl.hasCredential('organization.update');
    if (this.isCustomerRequest) {
      this.newJobDesc.customerRequest = true;
    }
  }

  onCancelClick() {
    this.activeModal.close(null);
  }

  onSubmitClick() {
    if (this.isSubmitting || !this.jobForm.valid) {
      return;
    }

    this.newJobDesc.requestOwnerProduct = this.requestOwnerProduct;

    this.isSubmitting = true;
    this.svcOrganization
      .maintenanceRequest(this.organizationId, this.newJobDesc)
      .pipe(tap(() => (this.isSubmitting = false)))
      .subscribe({
        next: (p) => this.activeModal.close(p),
        error: (e) => {
          if (e instanceof HttpErrorResponse) {
            if (e.status === 422) {
              alert('Failed to create request because the organization is not polished yet.');
            } else {
              alert(`Failed to create request: ${e.message}`);
            }
          } else {
            alert('Failed to create request');
          }
          this.activeModal.close();
        },
      });
  }
}
