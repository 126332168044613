<form novalidate>
  <div class="form-group row">
    <label class="col-form-label col-sm-4 col-md-2" for="searchName" title="Institution Name">Name</label>
    <div class="col-sm-7 col-md-9">
      <input
        type="text"
        id="searchName"
        name="searchtName"
        class="form-control"
        [(ngModel)]="model.filter.name"
        placeholder="Name"
      />
    </div>
  </div>
  <div class="form-group row">
    <label class="col-form-label col-sm-4 col-md-2" for="searchOriginalName" title="Original Name">Original Name</label>
    <div class="col-sm-7 col-md-9">
      <input
        type="text"
        id="searchOriginalName"
        name="searchOriginalName"
        class="form-control"
        [(ngModel)]="model.filter.originalName"
        placeholder="Original Name"
      />
    </div>
  </div>
  <div class="form-group row">
    <label class="col-form-label col-sm-4 col-md-2" for="searchDepartment" title="Department">Department</label>
    <div class="col-sm-7 col-md-9">
      <input
        type="text"
        id="searchDepartment"
        name="searchDepartment"
        class="form-control"
        [(ngModel)]="model.filter.department"
        placeholder="Department"
      />
    </div>
  </div>
  <div class="form-group row">
    <label class="col-form-label col-sm-4 col-md-2" for="searchStreet" title="Street">Street</label>
    <div class="col-sm-7 col-md-9">
      <input
        type="text"
        id="searchStreet"
        name="searchStreet"
        class="form-control"
        [(ngModel)]="model.filter.street"
        placeholder="Street"
      />
    </div>
  </div>
  <div class="form-group row">
    <label class="col-form-label col-sm-4 col-md-2" for="searchZip" title="ZIP">ZIP</label>
    <div class="col-sm-7 col-md-9">
      <input
        type="text"
        id="searchZip"
        name="searchZip"
        class="form-control"
        [(ngModel)]="model.filter.zip"
        placeholder="ZIP"
      />
    </div>
  </div>
  <div class="form-group row">
    <label class="col-form-label col-sm-4 col-md-2" for="searchCity" title="City">City</label>
    <div class="col-sm-7 col-md-9">
      <input
        type="text"
        id="searchCity"
        name="searchCity"
        class="form-control"
        [(ngModel)]="model.filter.city"
        placeholder="City"
      />
    </div>
  </div>
  <div class="form-group row">
    <label class="col-form-label col-sm-4 col-md-2" for="searchCountry">Country</label>
    <div class="col-sm-7 col-md-9">
      <select
        class="form-control custom-select"
        id="searchCountry"
        name="searchCountry"
        [(ngModel)]="model.filter.countryCode"
        (change)="onCountryChange($event)"
      >
        <option value="">(all)</option>
        <option *ngFor="let c of countries" [value]="c.code">{{ c.title }}</option>
      </select>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-form-label col-sm-4 col-md-2">State</label>
    <div class="col-sm-7 col-md-9">
      <dirt-multiselect-dropdown
        name="searchState"
        [options]="states"
        [settings]="statesSettings"
        [ngModel]="model.filter.state"
        (ngModelChange)="model.filter.state=$event[0] || null"
      >
      </dirt-multiselect-dropdown>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-form-label col-sm-4 col-md-2">Type</label>
    <div class="col-sm-7 col-md-9">
      <dirt-multiselect-dropdown
        name="searchType"
        [options]="types"
        [settings]="typesSettings"
        [ngModel]="model.filter.type"
        (ngModelChange)="model.filter.type=$event[0] || null"
      >
      </dirt-multiselect-dropdown>
    </div>
  </div>
</form>
