import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';
import { CommentModule } from '../comment/comment.module';
import { AffiliationComponent } from './affiliation.component';
import { AffiliationListComponent } from './list/list.component';
import { AffiliationDetailComponent } from './detail/detail.component';
import { AffiliationCreateComponent } from './create/create.component';
import { AffiliationPeopleComponent } from './shared/people/people.component';
import { affiliationRouting } from './affiliation.routes';
import { AffiliationAPI } from './shared/api.service';
import { AffiliationMergeComponent } from './shared/modal/merge/merge.component';
import { AffiliationMergeService } from './shared/modal/merge/merge.service';
import { AffiliationMergeInfoComponent } from './shared/modal/merge/merge-info.component';
import { BulkListModule } from '../common/bulk-list/bulk-list.module';
import { AffiliationPersonModalComponent } from './shared/modal/person/person.component';
import { AffiliationPersonListComponent } from './person-list/person-list.component';
import { AffiliationSingleSelectComponent } from './shared/components/affiliation-single-select/affiliation-single-select';
import { AffiliationMaintenanceJobModalService } from './shared/modal/affiliation-maintenance-job/affiliation-maintenance-job.service';
import { AffiliationMaintenanceJobComponent } from './shared/modal/affiliation-maintenance-job/affiliation-maintenance-job.component';
import { AffiliationAdvancedSearchComponent } from './shared/components/advanced-search/advanced-search';
import { AffiliationSingleSelectTempComponent } from './shared/components/affiliation-single-select-temp/affiliation-single-select-temp';

@NgModule({
  imports: [SharedModule, CommentModule, BulkListModule, affiliationRouting],
  declarations: [
    AffiliationComponent,
    AffiliationListComponent,
    AffiliationDetailComponent,
    AffiliationCreateComponent,
    AffiliationPeopleComponent,
    AffiliationMergeComponent,
    AffiliationMergeInfoComponent,
    AffiliationPersonModalComponent,
    AffiliationPersonListComponent,
    AffiliationSingleSelectComponent,
    AffiliationSingleSelectTempComponent,
    AffiliationMaintenanceJobComponent,
    AffiliationAdvancedSearchComponent,
  ],
  providers: [AffiliationAPI, AffiliationMergeService, AffiliationMaintenanceJobModalService],
  exports: [AffiliationSingleSelectComponent, AffiliationSingleSelectTempComponent],
})
export class AffiliationModule {}
