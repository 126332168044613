<!-- we have a tree and a details section -->
<section class="dirt-organization-detail-main">
  <div class="row">
    <div class="col-md-4 dirt-organization-tree-section">
      <!-- Search Mask -->
      <div *ngIf="root">
        <div *ngIf="!advancedSearchSpec">
          <dirt-organization-lookup
            [rootOrganizationId]="root?._id"
            [allowOutsideRoot]="false"
            [minimumCharactersBeforeSearch]="3"
            (organizationChange)="onSelectLookupItem($event)"
          ></dirt-organization-lookup>
        </div>
        <!-- Advanced Search -->
        <div>
          <small *ngIf="!advancedSearchSpec"><a class="alink" (click)="openAdvancedSearch()">Advanced search</a></small>
          <small *ngIf="advancedSearchSpec"
            ><a class="alink" (click)="openAdvancedSearch()">Edit search</a>
            &nbsp;-&nbsp;
            <a class="alink" (click)="clearAdvancedSearch()">Clear search</a></small
          >
        </div>
        <div class="advanced-search-results" *ngIf="advancedSearchResults != null">
          <br />
          <span *ngIf="advancedSearchResults?.length === 0">No search results</span>
          <ul>
            <li *ngFor="let org of advancedSearchResults">
              <a class="alink" (click)="onJumpToOrganization({ id: org._id })">{{ org.name }}</a>
            </li>
          </ul>
        </div>
        <hr />
      </div>
      <dialog class="advanced-search-dialog" #advancedSearchDialog (keyup.enter)="runAdvancedSearch()">
        <dirt-organization-advanced-search
          *ngIf="advancedSearchSpec"
          [model]="advancedSearchSpec"
        ></dirt-organization-advanced-search>
        <hr />
        <div class="pull-right">
          <button class="btn" (click)="advancedSearchDialogElement.nativeElement.close()">Cancel</button>
          <button class="btn" (click)="clearAdvancedSearch()">Clear</button>
          <button class="btn btn-primary" [disabled]="isAdvancedSearchDisabled()" (click)="runAdvancedSearch()">
            GO
          </button>
        </div>
      </dialog>
      <!-- Duplicates Section-->
      <div
        class="dirt-organization-tree-section-duplicates"
        *ngIf="internalDuplicates && internalDuplicatesCount > 0 && root"
      >
        <h6>
          {{ internalDuplicatesCount }} duplicate groups within {{ root?.name }}
          <button class="btn btn-sm btn-secondary" (click)="toggleShowDuplicates()">
            <span>{{ showDuplicates ? 'Hide' : 'Show' }}</span>
          </button>
        </h6>
        <ul [hidden]="!showDuplicates" class="dirt-organization-tree-section-duplicates-scroll">
          <div *ngFor="let item of internalDuplicates | keyvalue">
            <li>
              <b>{{ item.value.count }}</b> duplicates for name
              <span class="font-weight-bold" [ngStyle]="{ color: colorMap[item.key], fontWeight: bold }">{{
                item.key
              }}</span>
            </li>
            <ul>
              <li *ngFor="let d of item.value.duplicates">
                <a class="duplicate-link" (click)="onJumpToOrganization({ id: d._id })">{{ d._id }}</a>
              </li>
            </ul>
          </div>
        </ul>
        <hr />
      </div>

      <!-- Organizations without type section -->
      <div
        class="dirt-organization-tree-section-duplicates"
        *ngIf="orgsWithoutType && orgsWithoutType.size > 0 && root && this.showAdditionalTypeInformation()"
      >
        <h6>{{ orgsWithoutType.size }} orgs without type within {{ root?.name }}</h6>
        <ul [hidden]="false" class="dirt-organization-tree-section-duplicates-scroll">
          <div *ngFor="let item of orgsWithoutType">
            <li>
              <a class="duplicate-link" (click)="onJumpToOrganization({ id: item })"
                >{{ allOrganizations[item].name }}
              </a>
            </li>
          </div>
        </ul>
        <hr />
      </div>

      <!-- Controls -->
      <div class="dirt-organization-tree-buttons">
        <button [disabled]="isCreateNewDisabled()" (click)="onNewFromTree()">New child</button>
        <button [disabled]="isCollapseAllDisabled()" (click)="expandedMap = {}">Collapse all</button>
        <button [disabled]="isExpandAllDisabled()" (click)="onExpandAll()">Expand all</button>
        <button
          *ngIf="!(idsToMove.length > 0)"
          [disabled]="isMoveDisabled()"
          (click)="idsToMove = getCheckboxIds(); multiSelectMap = {}"
        >
          Move
        </button>
        <button
          *ngIf="svcAcl.hasCredential('organization.move') && idsToMove.length > 0"
          [disabled]="isMoveToSelectedDisabled()"
          (click)="onMoveToSelected()"
        >
          Move to selected with children
        </button>
        <button
          *ngIf="svcAcl.hasCredential('organization.move') && idsToMove.length > 0"
          [disabled]="isMoveToSelectedDisabled()"
          (click)="onMoveToSelected('SELECTED_ONLY')"
        >
          Move to selected without children
        </button>
        <button *ngIf="isCancelMoveDisabled()" (click)="idsToMove = []">Cancel move</button>
        <button [disabled]="isChangeTypeDisabled()" (click)="onChangeTypeOfSelect()">Change type of selected</button>
        <button [disabled]="isSetReadyTreeControlsDisabled()" (click)="onSetMultipleChildrenReady()">Set Ready</button>
        <!-- Merge & Split Controls that are always visible -->
        <button
          *ngIf="svcAcl.hasCredential('organization.merge') && mergeState.type === 'default'"
          [disabled]="isMergeDisabled()"
          (click)="onMergeSelectLoser()"
        >
          Merge
        </button>
        <button
          *ngIf="svcAcl.hasCredential('organization.split') && splitState.type === 'default'"
          [disabled]="isSplitDisabled()"
          (click)="onSplitSelectSplitOrganizations()"
        >
          Split
        </button>
        <!-- Extended Merge & Split Controls -->
        <br />
        <button
          *ngIf="svcAcl.hasCredential('organization.merge') && mergeState.type === 'loserSelected'"
          [disabled]="isMergeIntoDisabled()"
          (click)="onMergeSelectWinner(true)"
        >
          Merge Into With Children
        </button>
        <button
          *ngIf="svcAcl.hasCredential('organization.merge') && mergeState.type === 'loserSelected'"
          [disabled]="isMergeIntoDisabled()"
          (click)="onMergeSelectWinner(false)"
        >
          Merge Into Without Children
        </button>
        <button
          *ngIf="svcAcl.hasCredential('organization.merge') && mergeState.type === 'loserSelected'"
          (click)="onMergeReset()"
        >
          Cancel Merge
        </button>
        <button
          *ngIf="svcAcl.hasCredential('organization.split') && splitState.type === 'splitOrganizationIdsSelected'"
          (click)="onSplitReset()"
        >
          Cancel Split
        </button>
      </div>

      <!-- Tree View-->
      <div class="dirt-organization-tree-container">
        <dirt-organization-tree-part
          *ngIf="root"
          [leaf]="createTreeEntry(root)"
          [childrenMap]="childrenMap"
          [expandedMap]="expandedMap"
          [internalDuplicatesMap]="internalDuplicates"
          [colorMap]="colorMap"
          [multiSelectMap]="multiSelectMap"
          [singleSelectHolder]="singleSelectHolder"
          [currentJob]="currentJob"
          (toggleExpanded)="onToggleExpanded($event)"
          (changeSingleSelect)="onSingleSelect($event)"
          (jumpToOrg)="onJumpToOrganization($event)"
        ></dirt-organization-tree-part>
      </div>
    </div>

    <div class="col-md-8 dirt-organization-detail-section">
      <div class="dirt-organization-job-details" *ngIf="currentJob">
        Current job: {{ currentJob.type | capitalize }} {{ currentJob.hint ? ' - ' + currentJob.hint : '' }} for
        {{ allOrganizations[currentJob.entityId]?.name || currentJob.entityId }}
        <span *ngIf="hasQuotaQcJob"> - Checked {{ checkedIds.size }} of (min) {{ childrenToQcCount }}</span>
      </div>
      <div class="dirt-organization-tabs">
        <div
          class="dirt-organization-tab"
          [ngClass]="{ active: !!currentOrganization && !currentOrganization?._id }"
          *ngIf="newOrganization"
        >
          <span class="tab-title" (click)="onChooseNew()">(Create new)</span>
          <span class="tab-close" (click)="onCloseNew()"></span>
        </div>
        <div
          class="dirt-organization-tab"
          [ngClass]="{ active: currentOrganization?._id === orgId }"
          *ngFor="let orgId of openOrganizationIds; let idx = index"
        >
          <span class="tab-title" (click)="onChooseExisting(orgId)">{{ allOrganizations[orgId].name }}</span>
          <span class="tab-close" (click)="onCloseExisting(orgId, idx)"></span>
        </div>
        <div *ngIf="!(newOrganization || openOrganizationIds.length > 0)">(no open tabs)</div>
        <div *ngIf="newOrganization || openOrganizationIds.length > 0" class="dirt-organization-tab">
          <!-- finish -->
        </div>
        <small *ngIf="newOrganization || openOrganizationIds.length > 0" (click)="onCloseAll()"
          ><a class="alink">Close all</a></small
        >
      </div>
      <div class="dirt-organization-form" *ngIf="currentOrganization">
        <dirt-organization-detail-form
          #frmOrgDetail="frmOrgDetail"
          [organization]="currentOrganization"
          [organizationInfo]="allOrganizations"
          [addressInfo]="allAddresses"
          [formReadonly]="isCurrentReadOnly()"
          [displayExternalOrganization]="isAlien(currentOrganization)"
          [expandInternalParentName]="hasQcJob"
          [showCheckedBadge]="hasQuotaQcJob && currentOrganization?._id && checkedIds.has(currentOrganization?._id)"
          [organizationLineage]="lineage"
          (organizationLinkClicked)="onJumpToOrganization($event)"
        ></dirt-organization-detail-form>

        <div class="row dirt-organization-form-actions">
          <div class="col-sm-12">
            <div class="dirt-organization-form-actions-left pull-left">
              <div *ngIf="svcAcl.hasCredential('organization.delete')">
                <button class="btn btn-sm btn-danger" [disabled]="loading || buttonsDisabled" (click)="onDelete()">
                  {{ loading ? 'Deleting...' : 'Delete with children' }}
                </button>

                <button
                  class="btn btn-sm btn-danger"
                  [disabled]="loading || buttonsDisabled"
                  (click)="onDelete('SELECTED_ONLY')"
                  *ngIf="!currentOrganization.isRoot"
                >
                  {{ loading ? 'Deleting...' : 'Delete without children' }}
                </button>
              </div>
            </div>

            <div class="dirt-organization-form-actions-right pull-right">
              <button
                type="button"
                class="btn btn-sm btn-outline-primary"
                [disabled]="loading || buttonsDisabled || isAlien(currentOrganization)"
                (click)="onCopyLink(currentOrganization?._id)"
              >
                Copy link
              </button>
              <span *ngIf="isCurrentReadOnly() && svcAcl.hasCredential('organization.update')">
                <label *ngIf="currentJob && !currentOrganization.isRoot"
                  ><input
                    type="checkbox"
                    [checked]="currentOrganization.markedForLater"
                    (click)="onMarkForLater(currentOrganization)"
                  />
                  Mark for later</label
                >
                <button
                  type="button"
                  class="btn btn-sm btn-outline-danger"
                  [disabled]="loading || buttonsDisabled || isAlien(currentOrganization)"
                  (click)="onSetUtc()"
                >
                  {{ isUnableToCompile(currentOrganization) ? 'Set Draft' : 'Set UTC' }}
                </button>
                <button
                  *ngIf="currentOrganization?._meta?.status === 'IN_PROGRESS' && !currentOrganization?.isRoot"
                  class="btn btn-sm btn-primary"
                  [disabled]="
                    loading ||
                    buttonsDisabled ||
                    isAlien(currentOrganization) ||
                    currentOrganization?.childrenInProgress?.length > 0
                  "
                  (click)="onSetChildReady()"
                >
                  <span>Set as Ready</span>
                </button>
                <button
                  class="btn btn-sm btn-secondary"
                  [disabled]="loading || buttonsDisabled || isAlien(currentOrganization)"
                  (click)="onEdit()"
                >
                  <span>{{ hasQcJob ? 'Start review' : 'Edit' }}</span>
                </button>
              </span>
              <div
                style="margin-top: 10px"
                *ngIf="
                  !isCurrentReadOnly() && !!currentOrganization?._id && svcAcl.hasCredential('organization.update')
                "
              >
                <button class="btn btn-sm btn-secondary" [disabled]="loading || buttonsDisabled" (click)="onRevert()">
                  <span>Revert</span>
                </button>
                <button
                  class="btn btn-sm btn-primary"
                  [disabled]="loading || buttonsDisabled || !isFormValid()"
                  (click)="onSave()"
                >
                  <span>Save</span>
                </button>
                <button
                  class="btn btn-sm btn-primary"
                  *ngIf="!hasQcJob"
                  [disabled]="loading || buttonsDisabled || !isFormValid()"
                  (click)="onSaveAndClose()"
                >
                  <span>Save and close</span>
                </button>
              </div>
              <div
                *ngIf="
                  !isCurrentReadOnly() &&
                  !currentOrganization?._id &&
                  svcAcl.hasCredential('organization.update') &&
                  svcAcl.hasCredential('organization.create')
                "
              >
                <label
                  ><input
                    type="checkbox"
                    [disabled]="loading || buttonsDisabled"
                    [checked]="createAnother"
                    (input)="createAnother = !createAnother"
                  />
                  Create another</label
                >
                <button
                  class="btn btn-sm btn-primary"
                  [disabled]="loading || buttonsDisabled || !isFormValid()"
                  (click)="onCreate()"
                >
                  <span>Create</span>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="row" *ngIf="currentJob">
          <div class="col-sm-12">
            <hr />
            <div class="pull-left">
              <button
                type="button"
                class="btn btn-outline-danger"
                (click)="onJobUtc()"
                [disabled]="hasEditingOrganizations()"
              >
                Unable to Compile!
              </button>
              <button
                type="button"
                class="btn btn-secondary"
                (click)="onJobSkip()"
                [disabled]="hasEditingOrganizations()"
              >
                Skip
              </button>
            </div>
            <div class="pull-right">
              <button
                type="button"
                class="btn btn-secondary"
                (click)="onJobDraft()"
                [disabled]="hasEditingOrganizations()"
              >
                Save as draft
              </button>
              <button
                class="btn btn-primary"
                [title]="
                  hasEditingOrganizations()
                    ? 'Need to save all tabs first'
                    : hasQuotaQcJob && checkedIds.size < childrenToQcCount
                    ? 'Still missing some checks before enough is reviewed'
                    : 'Submit - cannot edit afterwards'
                "
                [disabled]="isJobSubmissionDisabled()"
                (click)="isJobForRootOrg() ? onSubmitRootJob() : onSubmitChildJob()"
              >
                {{ isJobForRootOrg() ? 'Submit full org' : 'Submit org' }}
              </button>
            </div>
          </div>
        </div>

        <!-- Affiliation Mappings -->
        <div class="row" *ngIf="currentOrganization">
          <div class="col-md-12" *diAcl="'organization.affiliation.list'">
            <!-- collapse header -->
            <div class="card-header toggle-section" (click)="showAffiliationSuspects = !showAffiliationSuspects">
              <div class="d-inline-block">
                <i
                  class="fa fa-lg"
                  [ngClass]="{
                    'fa-angle-right': !showAffiliationSuspects,
                    'fa-angle-down': showAffiliationSuspects
                  }"
                  aria-hidden="true"
                ></i>
                <span class="font-weight-bold pl-1 pr-2"
                  >{{ allAffiliationMappings[currentOrganization._id]?.length }} Affiliation Mappings</span
                >
              </div>
            </div>
            <div [ngbCollapse]="!showAffiliationSuspects">
              <dirt-organization-affiliation-list
                [organization]="currentOrganization"
                [allMappings]="allAffiliationMappings[currentOrganization._id] || []"
                [allAffiliations]="allAffiliations"
                (matchChanged)="onMatchChange($event)"
                (addAffiliationMapping)="openAddAffiliationMappingDialog($event)"
              ></dirt-organization-affiliation-list>
            </div>
          </div>
        </div>

        <!-- Info Box -->
        <div class="row" *ngIf="currentOrganization">
          <div class="col-md-12">
            <ul ngbNav #nav="ngbNav" class="nav-tabs">
              <!-- Information -->
              <li ngbNavItem>
                <a ngbNavLink>Information</a>

                <ng-template ngbNavContent>
                  <dl class="row">
                    <ng-template [diAcl]="'organization.request'" *ngIf="!isRequestMaintenanceDisabled()">
                      <dt class="col-sm-4"></dt>
                      <dd class="col-sm-8">
                        <a href="javascript:void(0)" (click)="onMaintenanceRequest()">Request Maintenance</a>
                      </dd>
                    </ng-template>

                    <dt class="col-sm-4">Created</dt>
                    <dd class="col-sm-8">{{ currentOrganization?.createdAt | date : 'medium' }}</dd>

                    <dt class="col-sm-4">Updated</dt>
                    <dd class="col-sm-8">{{ currentOrganization?.updatedAt | date : 'medium' }}</dd>

                    <dt class="col-sm-4">Status</dt>
                    <dd class="col-sm-8">
                      <span>{{ currentOrganization?._meta?.status | capitalize }}</span>
                    </dd>

                    <dt class="col-sm-4">Ready For Delivery</dt>
                    <dd class="col-sm-8">
                      <span>{{ currentOrganization?.readyForDelivery ? 'Yes' : 'No' }}</span>
                      &nbsp;
                      <i
                        (click)="onOpenReadyForDeliveryInfoDialog()"
                        class="fa fa-info-circle"
                        style="cursor: pointer"
                        aria-hidden="true"
                        title=""
                      ></i>
                    </dd>

                    <dt class="col-sm-4">Version</dt>
                    <dd class="col-sm-8">{{ currentOrganization?._version || 0 }}</dd>

                    <ng-container *ngIf="getMatchedAffiliationMappings(currentOrganization._id)?.length > 0">
                      <dt class="col-sm-4">Affiliation Mappings</dt>
                      <dd class="col-sm-8">
                        <div *ngFor="let mapping of getMatchedAffiliationMappings(currentOrganization._id)">
                          <a href="/affiliation/detail/{{ mapping.affiliationId }}" target="_blank">{{
                            getAffiliationLabel(mapping.affiliationId)
                          }}</a>
                        </div>
                      </dd>
                    </ng-container>

                    <!-- Deletion Request-->
                    <ng-container *ngIf="currentOrganization?.isMarkedForDeletion">
                      <dt class="col-sm-4">Deletion Request By</dt>
                      <dd class="col-sm-8">
                        {{ currentOrganization?.markedForDeletionBy }}
                        <dirt-username [userId]="currentOrganization?.markedForDeletion?.by"></dirt-username>
                      </dd>

                      <dt class="col-sm-4">Deletion Request At</dt>
                      <dd class="col-sm-8">
                        {{ currentOrganization?.markedForDeletion?.at | date : 'medium' }}
                      </dd>
                      <ng-template [diAcl]="'organization.removeDeletionRequest'">
                        <dt class="col-sm-4"></dt>
                        <dd class="col-sm-8">
                          <a href="javascript:void(0)" (click)="onRemoveDeletionRequest(currentOrganization?._id)"
                            >Remove Deletion Request</a
                          >
                        </dd>
                      </ng-template>
                    </ng-container>
                  </dl>
                </ng-template>
              </li>

              <!-- Audit Log -->
              <li ngbNavItem *diAcl="'organization.audit.list'">
                <a ngbNavLink>Audit Log</a>
                <ng-template ngbNavContent>
                  <dirt-audit-log [id]="currentOrganization._id" [entityAPI]="svcOrganization"></dirt-audit-log>
                </ng-template>
              </li>

              <!-- Job History -->
              <li ngbNavItem *diAcl="'organization.job-history.list'">
                <a ngbNavLink>Job History</a>
                <ng-template ngbNavContent>
                  <dirt-job-history [entityId]="id"></dirt-job-history>
                </ng-template>
              </li>

              <!-- Comments -->
              <li ngbNavItem *diAcl="'organization.comment.list'">
                <a ngbNavLink>Comments</a>
                <ng-template ngbNavContent>
                  <div>
                    <label
                      ><input type="radio" [checked]="'single' === commentMode" (click)="commentMode = 'single'" />
                      current org</label
                    >
                    <label
                      ><input type="radio" [checked]="'all' === commentMode" (click)="onPullAllComments()" /> all
                      orgs</label
                    >
                  </div>
                  <div>
                    <dirt-comment-list
                      [ownerId]="currentOrganization?._id || null"
                      [alternativeList]="'all' === commentMode && !!allComments ? allComments : null"
                      [enableCreate]="!!currentOrganization?._id && 'single' === commentMode"
                      [showNavToItem]="'all' === commentMode && !!allComments"
                      (newCommentAdded)="allComments ? allComments.push($event) : null"
                      (navToItem)="onJumpToOrganization($event)"
                    ></dirt-comment-list>
                  </div>
                </ng-template>
              </li>
            </ul>

            <div [ngbNavOutlet]="nav" class="dirt-organization-meta"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- Modal: change type -->
<ng-template #changeTypeModal let-d="dismiss" let-c="close">
  <div class="dirt-content-wrapper" tabindex="0">
    <div class="modal-header">
      <h5 class="modal-title">Change type</h5>
      <button type="button" class="close" aria-label="Close" (click)="d()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div>
        <select
          id="type"
          class="form-control custom-select"
          name="type"
          [(ngModel)]="targetType"
          [ngModelOptions]="{ standalone: true }"
        >
          <option *ngFor="let type of types" [value]="type.code">
            {{ type.title }}
          </option>
        </select>
      </div>
      <div class="pull-right" style="margin: 12px 0">
        <button class="btn btn-sm btn-secondary" (click)="d()">Cancel</button>
        <button class="btn btn-sm btn-primary" [disabled]="!targetType" (click)="c()">Change type</button>
      </div>
    </div>
  </div>
</ng-template>

<!-- Modal: merge modal -->
<ng-template #mergeModal let-d="dismiss" let-c="close">
  <div class="dirt-content-wrapper" tabindex="0">
    <div class="modal-header">
      <h5 *ngIf="mergeState.type === 'loserAndWinnerSelected'" class="modal-title">Confirmation</h5>
      <h5 *ngIf="mergeState.type === 'merging'" class="modal-title">Merging</h5>
      <h5 *ngIf="mergeState.type === 'merged'" class="modal-title">Merge Report</h5>
      <button type="button" class="close" aria-label="Close" (click)="d()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div *ngIf="mergeState.type === 'loserAndWinnerSelected' && mergeState.plan.mergeChildren">
        <p>
          Are you sure to merge
          <a href="/organization/detail/{{ this.root._id.toString() }}/{{ mergeState.plan.loserId }}" target="_blank">
            {{ this.allOrganizations[mergeState.plan.loserId]?.name || mergeState.plan.loserId }}
          </a>
          into
          <a href="/organization/detail/{{ this.root._id.toString() }}/{{ mergeState.plan.winnerId }}" target="_blank">
            {{ this.allOrganizations[mergeState.plan.winnerId]?.name || mergeState.plan.winnerId }}
          </a>
          and merging their children?
        </p>
        <p>
          This will merge all of
          <a href="/organization/detail/{{ this.root._id.toString() }}/{{ mergeState.plan.loserId }}" target="_blank">
            {{ this.allOrganizations[mergeState.plan.loserId]?.name || mergeState.plan.loserId }}
          </a>
          children into
          <a href="/organization/detail/{{ this.root._id.toString() }}/{{ mergeState.plan.winnerId }}" target="_blank">
            {{ this.allOrganizations[mergeState.plan.winnerId]?.name || mergeState.plan.winnerId }}
          </a>
          and will repeat for all children down the hierarchy.
        </p>
      </div>
      <div *ngIf="mergeState.type === 'loserAndWinnerSelected' && !mergeState.plan.mergeChildren">
        <p>
          Are you sure to merge
          <a href="/organization/detail/{{ this.root._id.toString() }}/{{ mergeState.plan.loserId }}" target="_blank">
            {{ this.allOrganizations[mergeState.plan.loserId]?.name || mergeState.plan.loserId }}
          </a>
          into
          <a href="/organization/detail/{{ this.root._id.toString() }}/{{ mergeState.plan.winnerId }}" target="_blank">
            {{ this.allOrganizations[mergeState.plan.winnerId]?.name || mergeState.plan.winnerId }}
          </a>
        </p>
        <p>
          This will move all of
          <a href="/organization/detail/{{ this.root._id.toString() }}/{{ mergeState.plan.loserId }}" target="_blank">
            {{ this.allOrganizations[mergeState.plan.loserId]?.name || mergeState.plan.loserId }}
          </a>
          children one level up.
        </p>
      </div>
      <div *ngIf="mergeState.type === 'merging'">
        <p>
          <a href="/organization/detail/{{ this.root._id.toString() }}/{{ mergeState.plan.loserId }}" target="_blank">
            {{ this.allOrganizations[mergeState.plan.loserId]?.name || mergeState.plan.loserId }}
          </a>
          into
          <a href="/organization/detail/{{ this.root._id.toString() }}/{{ mergeState.plan.winnerId }}" target="_blank">
            {{ this.allOrganizations[mergeState.plan.winnerId]?.name || mergeState.plan.winnerId }}
          </a>
        </p>
      </div>
      <div *ngIf="mergeState.type === 'merged'">
        <p>
          Successfully merged
          {{ this.allOrganizations[mergeState.plan.loserId]?.name || mergeState.plan.loserId }}
          into
          <a href="/organization/detail/{{ this.root._id.toString() }}/{{ mergeState.plan.winnerId }}" target="_blank">
            {{ this.allOrganizations[mergeState.plan.winnerId]?.name || mergeState.plan.winnerId }} </a
          >!
        </p>
      </div>
      <div *ngIf="mergeState.type === 'merged' && mergeState.migratedAdditionalParents.length > 0">
        <p>We migrated some additional parent relationships:</p>
        <ul>
          <li *ngFor="let entry of mergeState.migratedAdditionalParents">
            <a href="/organization/detail/{{ entry.additionalParentId }}" target="_blank">
              {{ entry.additionalParentId }}
            </a>
            transferred from
            <b>{{ this.allOrganizations[entry.loserId].name || entry.loserId }}</b>
            to
            <a href="/organization/detail/{{ this.root._id.toString() }}/{{ entry.winnerId }}" target="_blank">
              {{ this.allOrganizations[entry.winnerId]?.name || entry.winnerId }}
            </a>
          </li>
        </ul>
      </div>
      <div style="height: 100px">
        <dirt-spinner [show]="mergeState.type === 'merging'"></dirt-spinner>
      </div>
      <div class="pull-right" style="margin: 12px 0">
        <button *ngIf="mergeState.type === 'loserAndWinnerSelected'" class="btn btn-sm btn-secondary" (click)="d()">
          Cancel
        </button>
        <button
          *ngIf="mergeState.type === 'loserAndWinnerSelected'"
          class="btn btn-sm btn-primary"
          (click)="onMergeConfirm(false)"
        >
          Confirm
        </button>
        <button *ngIf="mergeState.type === 'merged'" class="btn btn-sm btn-primary" (click)="c()">Close</button>
      </div>
    </div>
  </div>
</ng-template>

<!-- Modal: Split modal -->
<ng-template #splitModal let-d="dismiss" let-c="close">
  <div class="dirt-content-wrapper" tabindex="0">
    <div class="modal-header">
      <h5 *ngIf="splitState.type === 'splitOrganizationIdsSelected'" class="modal-title">Split Setup</h5>
      <h5 *ngIf="splitState.type === 'targetOrganizationSelected'" class="modal-title">Split Confirmation</h5>
      <h5 *ngIf="splitState.type === 'splitting'" class="modal-title">Splitting</h5>
      <h5 *ngIf="splitState.type === 'splitted'" class="modal-title">Split Report</h5>
      <button type="button" class="close" aria-label="Close" (click)="d()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div *ngIf="splitState.type === 'splitOrganizationIdsSelected'">
        <p>
          You are planning to split the following organizations out of {{ this.allOrganizations[this.root._id]?.name }}:
        </p>
        <ul>
          <li *ngFor="let item of splitState.splitOrganizationIds">
            <a href="/organization/detail/{{ this.root._id.toString() }}/{{ item }}" target="_blank">
              {{ this.allOrganizations[item]?.name || item }}
            </a>
          </li>
        </ul>

        <p>Please select an organization you would like to split the selected organizations into.</p>
        <!-- Global Organization Search -->
        <dirt-organization-lookup
          [rootOrganizationId]="root?._id"
          [allowOutsideRoot]="false"
          [outsideRoot]="true"
          [onlyRoot]="true"
          [minimumCharactersBeforeSearch]="3"
          (organizationChange)="onSplitSelectTargetOrganization($event)"
          [ignoreOrgIds]="[currentOrganization.root.toString()]"
        ></dirt-organization-lookup>
        <hr />
        <!-- Create Button -->
        <button
          class="btn btn-success btn-sm"
          (click)="onOpenCreateOrganizationDialog()"
          *diAcl="'organization.create'"
        >
          <i class="fa fa-plus" aria-hidden="true"></i>
          &nbsp; Create Organization
        </button>
      </div>
      <div *ngIf="splitState.type === 'targetOrganizationSelected'">
        <p>
          You are planning to split the following organizations out of
          <b>{{ this.allOrganizations[this.root._id]?.name }}</b
          >:
        </p>
        <ul>
          <li *ngFor="let item of splitState.plan.splitOrganizationIds">
            <a href="/organization/detail/{{ this.root._id.toString() }}/{{ item }}" target="_blank">
              {{ this.allOrganizations[item]?.name || item }}
            </a>
          </li>
        </ul>

        <p>
          The above organizations will be moved to
          <a href="/organization/detail/{{ splitState.plan.targetOrganization.root.toString() }}" target="_blank">
            {{ splitState.plan.targetOrganization.name }} </a
          >.
        </p>

        <hr />
        <label
          ><input
            type="checkbox"
            [checked]="splitState.plan.includeChildren"
            (input)="splitState.plan.includeChildren = !splitState.plan.includeChildren"
          />
          Include Children</label
        >
        <p *ngIf="splitState.plan.includeChildren">
          All children of the moved organizations will be included in the move to
          <b>{{ splitState.plan.targetOrganization.name }}</b
          >.
        </p>
        <p *ngIf="!splitState.plan.includeChildren">
          The children of the moved organizations will <b>NOT</b> be included in the move to
          <b>{{ splitState.plan.targetOrganization.name }}</b
          >.
        </p>
        <p *ngIf="!splitState.plan.includeChildren">
          Instead, they remain in the health system <b>{{ this.root.name }}</b> and are moved up one layer where the
          moved organizations were.
        </p>
      </div>
      <div *ngIf="splitState.type === 'splitting'"></div>
      <div *ngIf="splitState.type === 'splitted'">
        <p>Successfully transferred</p>
        <ul>
          <li *ngFor="let item of splitState.plan.splitOrganizationIds">
            <a
              href="/organization/detail/{{ splitState.plan.targetOrganization._id.toString() }}/{{ item }}"
              target="_blank"
            >
              {{ this.allOrganizations[item]?.name || item }}
            </a>
          </li>
        </ul>
        <p>
          to the health system
          <a href="/organization/detail/{{ splitState.plan.targetOrganization.root.toString() }}" target="_blank">
            {{ splitState.plan.targetOrganization.name }} </a
          >.
        </p>
      </div>
      <div style="height: 100px">
        <dirt-spinner [show]="splitState.type === 'splitting'"></dirt-spinner>
      </div>
      <div class="pull-right" style="margin: 12px 0">
        <button *ngIf="splitState.type === 'targetOrganizationSelected'" class="btn btn-sm btn-secondary" (click)="d()">
          Cancel
        </button>
        <button
          *ngIf="splitState.type === 'targetOrganizationSelected'"
          class="btn btn-sm btn-primary"
          (click)="onSplitConfirm()"
        >
          Confirm
        </button>
        <button *ngIf="splitState.type === 'splitted'" class="btn btn-sm btn-primary" (click)="c()">Close</button>
      </div>
    </div>
  </div>
</ng-template>

<!-- Modal: Add Affiliation Mapping modal -->
<ng-template #addAffiliationMappingModal let-d="dismiss" let-c="close">
  <div class="dirt-content-wrapper" tabindex="0">
    <div class="modal-header">
      <h2>Add Affiliation Mapping</h2>
      <button type="button" class="close" aria-label="Close" (click)="d()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <dirt-affiliation-single-select
        [wide]="true"
        [excludeUnpolished]="false"
        (affiliationSelected)="onSelectAffiliationForNewMapping($event)"
      ></dirt-affiliation-single-select>
      <div *ngIf="selectedAffiliationForMapping">
        <div>Create a new mapping for {{ selectedAffiliationForMapping.name }}?</div>
        <button class="btn btn-primary btn-sm" (click)="onCreateAffiliationForNewMapping(); d()">Create</button>
      </div>
    </div>
  </div>
</ng-template>

<!-- Modal: Show ReadyForDelivery Info -->
<ng-template #readyForDeliveryInfo let-d="dismiss" let-c="close">
  <div class="dirt-content-wrapper" tabindex="0">
    <div class="modal-header">
      <h5 class="modal-title">Ready For Delivery Status Info</h5>
      <button type="button" class="close" aria-label="Close" (click)="d()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div>
        <p>
          <b>{{ currentOrganization.name }}</b> is currently
          {{ currentOrganization.readyForDelivery ? '' : '*not*' }} ready for delivery.
        </p>
        <p>An HCO needs to fulfill the following requirements to be ready for delivery:</p>
        <ul>
          <li>has passed structure compilation</li>
          <li>has a valid address, including inherited addresses</li>
          <li>is not set to UNABLE_TO_COMPILE status</li>
        </ul>
      </div>
      <div class="pull-right" style="margin: 12px 0">
        <button class="btn btn-sm btn-secondary" (click)="d()">Close</button>
      </div>
    </div>
  </div>
</ng-template>
