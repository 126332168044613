<section
  class="dirt-section-detail dirt-profile-detail di-scroller-wrapper"
  *ngIf="profile && activeSection; else tplLoading"
  (scroll)="handleScroll($event)"
>
  <div class="d-flex flex-column h-100">
    <div class="row di-header-wrapper" #freezeHeaderSection [class.freeze]="freeze">
      <button type="button" class="dirt-btn-back btn btn-primary-outline btn-sm" (click)="goBack()">
        <span class="fa fa-arrow-left" aria-hidden="true"></span>
      </button>

      <div class="di-twitter-avatar-wrapper" *ngIf="profile?.person?.image?.local_url">
        <dirt-avatar
          [readonly]="true"
          [local_url]="profile?.person?.image?.local_url_nocache || profile?.person?.image?.local_url"
        >
        </dirt-avatar>

        <div>
          <a
            href="javascript:void(0)"
            *diAcl="'person.request-image-maintenance'"
            title="Request Image Maintenance"
            (click)="onRequestImageMaintenance()"
          >
            Image Review Needed
          </a>

          <i class="fa fa-pulse fa-spinner di-va-super" [hidden]="!isRequestingImageMaintenance"></i>
        </div>
      </div>

      <div class="di-info-wrapper">
        <!-- Header -->
        <div class="row di-info-header">
          <div class="col-auto">
            <h4 class="d-inline-block">
              <a class="di-header-name" (click)="onProfileNameClick()" rel="noopener" target="_blank">
                <span title="First name" *ngIf="profile.person.firstName">{{ profile.person.firstName }}</span>
                <span title="Middle name" *ngIf="profile.person.middleName">{{ profile.person.middleName }}</span>
                <span title="Last name" *ngIf="profile.person.lastName">{{ profile.person.lastName }}</span>
              </a>
            </h4>
            <!-- FullName-->
            <h6 class="d-inline-block ml-2 mb-2 text-muted">{{ profile.person.originalFullName }}</h6>
            <!--span *ngFor="let product of products" class="badge product-badge" [style.color]="getProductColor(product)"[style.border]="'1px solid ' + getProductColor(product)" >{{ product | uppercase | productLabel}}</span-->
            <!-- Updated At -->
            <span class="di-header-meta ml-2" title="Base Data Compiled at">
              <i class="fa fa-user" aria-hidden="true"></i> {{ profile.person.review?.at | date : 'mediumDate' }}
            </span>
          </div>
          <!-- Specialty-->
          <div class="col-md-3 di-header-specialty">
            <div>
              <span
                class="badge badge-outline-info mr-1"
                *ngFor="let sp of profile.person.specialties"
                ngbTooltip="Specialty"
                [placement]="'bottom'"
              >
                {{ getValueTitle(specialtyList, sp) }}
              </span>
              <span
                [hidden]="!isClaimedByOtherUser()"
                title="s/o else started working recently"
                class="badge badge-info"
                >Claimed</span
              >
            </div>
            <div class="degree">
              <span
                class="badge mr-1"
                *ngFor="let degree of profile.person.degrees"
                ngbTooltip="Degree"
                [placement]="'bottom'"
              >
                {{ getValueTitle(degreesList, degree) }}
              </span>
            </div>
          </div>
          <!--div *ngIf="profile.person.createdByTeam && profile.person.createdByTeam === 'LFKA'">
            <span ngbTooltip="Created By" class="badge badge-lfka">{{ 'LFKA' | productLabel}}</span>
          </div>
          <div *ngIf="profile.person.createdByTeam && profile.person.createdByTeam === 'LFTA'">
            <span ngbTooltip="Created By" class="badge badge-lfta">{{ 'LFTA' | productLabel}}</span>
          </div-->
          <div class="col maintenance-alert-container">
            <!-- nth right now -->
          </div>
          <div *ngIf="isAutoProject(profile)" class="automated-profile-badge">
            <span class="badge badge-info">Auto</span>
          </div>
          <div *ngIf="profile.person.newAffiliationCreated" class="automated-profile-badge">
            <span class="badge badge-warning">New Affiliation Created</span>
          </div>
          <div *ngIf="profile._meta.workstream" class="automated-profile-badge">
            <span ngbTooltip="Workstream" class="badge badge-info">{{ profile._meta.workstream }}</span>
          </div>
          <!-- Modified & Version -->
          <div class="col-auto di-header-meta">
            <span class="badge badge-dark di-unpolished-dps" ngbTooltip="Unpolished Data Points" [placement]="'bottom'">
              {{ unpolishedDps }}
            </span>
            <span>&bull;</span>
            <a href="javascript:void(0)" class="di-timid" (click)="doOpenModal(auditLog)" *diAcl="'profile.audit.list'">
              <i class="fa fa-code-fork" aria-hidden="true"></i> {{ profile._version }}
            </a>
            <span *diAcl="'profile.audit.list'">&bull;</span>
            <a
              href="javascript:void(0)"
              class="di-timid"
              (click)="doOpenModal(commentList)"
              *diAcl="'profile.comment.list'"
            >
              <i class="fa fa-comment-o" aria-hidden="true"></i>
            </a>
            <span *diAcl="'profile.comment.list'">&bull;</span>
            <span ngbTooltip="Profile&nbsp;Polished&nbsp;at" [placement]="'bottom'">
              <i class="fa fa-clock-o" aria-hidden="true"></i> {{ profile.polishedAt | date : 'mediumDate' }}
            </span>
          </div>
        </div>
        <div class="row di-info-middle">
          <!-- Tags -->
          <div class="col">
            <dirt-tags
              [tags]="profile.tags"
              [disabled]="!isTagsEditable()"
              (onTagsChange)="onProfileTagsChange($event)"
            ></dirt-tags>
          </div>
          <!-- <div class="col di-header-specialty">
            <span class="badge mr-1"
                  *ngFor="let degree of profile.person.degrees"
                  ngbTooltip="Degree"
                  [placement]="'bottom'">
              {{ getValueTitle(degreesList, degree) }}
            </span>
          </div> -->
          <!-- Verified -->
          <div class="col-auto di-verified" *diAcl="'profile.update.prop.verified'">
            <ui-switch
              [checked]="!!profile.verified"
              (change)="onVerifiedChange($event)"
              [disabled]="!hasQcPermission()"
              size="small"
            ></ui-switch>
            <span class="di-verified-label">Verified</span>
          </div>

          <div *diAcl="'profile.videos.submit'" class="col-auto">
            <button (click)="onSubmitVideos()" class="btn btn-sm btn-outline-success" *ngIf="canSubmitVideos()">
              <span>Submit Videos</span>
            </button>
          </div>

          <ng-container *ngIf="canSubmitJob() && hasJobForCurrentUser() && isNotVideoJob()">
            <!-- Save as Draft for job -->
            <div class="col-auto">
              <button type="button" class="btn btn-sm btn-secondary" (click)="onJobDraft()" [disabled]="isSubmitting">
                Save as draft
              </button>

              <!-- Submit job -->
              <button
                class="btn btn-sm btn-outline-success"
                [ngClass]="{ 'btn-loading': isSubmitting }"
                [disabled]="isSubmitting"
                (click)="onSubmitJob()"
              >
                <span>Submit Job</span>
              </button>
            </div>
          </ng-container>

          <ng-template [diAcl]="'profile.qc'" [diAclAnd]="!hasJobForCurrentUser()">
            <div class="col-auto">
              <button (click)="onFinishReview()" class="btn btn-sm btn-outline-success">
                <span>Finish review</span>
              </button>
            </div>
          </ng-template>

          <ng-template [diAcl]="'profile.update.skip'" [diAclAnd]="!hasJobForCurrentUser()">
            <div class="col-auto">
              <button (click)="skipVerification()" class="btn btn-sm btn-outline-danger">
                <span>Skip review</span>
              </button>
            </div>
          </ng-template>
        </div>

        <!-- Middle -->
        <div class="row di-info-footer">
          <div class="col">
            <span class="di-label text-left">CV Links</span>
            <span *ngFor="let cvLink of profile.person.cvLinks; last as isLast">
              <a [href]="cvLink" rel="noopener" target="_blank">{{ cvLink | domain }}</a>
              <span [hidden]="isLast">|</span>
            </span>
          </div>

          <div *ngIf="profile.person.social?.linkedinUrl" class="col">
            <span>LinkedIn URL:</span>
            <span>
              <a [href]="profile.person.social?.linkedinUrl" rel="noopener" target="_blank">{{
                profile.person.social?.linkedinUrl
              }}</a>
            </span>
          </div>

          <div class="col-auto">
            <!-- Status Selector -->
            <dirt-status
              [statuses]="profileStatuses"
              [assigneeStatuses]="profileStatusesAssignable"
              [_meta]="profile._meta"
              [requiresEu]="requiresEu()"
              (onStatusSelected)="onStatusChanged($event)"
              *diAcl="'profile.update.prop.meta.status'"
            >
            </dirt-status>

            <!-- Submit Button -->
            <dirt-profile-submit
              [profile]="profile"
              *diAcl="'profile.update.submit'"
              [isSaving]="isSavingMatches"
              [mandatoryUnpolishedDps]="mandatoryUnpolishedDps"
            >
            </dirt-profile-submit>

            <!-- Unable to Process -->
            <button
              type="button"
              class="btn btn-sm btn-outline-danger"
              ngbTooltip="Unable to Compile!"
              [placement]="'left'"
              (click)="onUnableToCompile()"
              [disabled]="isSavingMatches"
              [ngClass]="{ 'btn-loading': isSavingMatches }"
              *diAcl="'profile.update.unable'"
            >
              <i class="fa fa-warning"></i>
            </button>
          </div>
          <div class="col-12 di-info-footer-sidebyside">
            <div *ngIf="lftaPrimaryAffiliation">
              <div>
                <span class="di-label text-left">{{ 'LFTA' | productLabel }} Affiliation</span>
                <span class="di-bold"
                  >{{ lftaPrimaryAffiliation.name }}{{ lftaPrimaryAffiliation?.department ? ', ' : '' }}</span
                >
                <span class="di-bold" *ngIf="lftaPrimaryAffiliation?.department">{{
                  lftaPrimaryAffiliation?.department
                }}</span>
              </div>
              <div *ngIf="lftaPrimaryAffiliation.originalName">
                <span class="di-label text-left">{{ 'LFTA' | productLabel }} Original Affiliation</span>
                <span class="di-bold"
                  >{{ lftaPrimaryAffiliation.originalName
                  }}{{ lftaPrimaryAffiliation?.originalDepartment ? ', ' : '' }}</span
                >
                <span class="di-bold" *ngIf="lftaPrimaryAffiliation?.originalDepartment">{{
                  lftaPrimaryAffiliation.originalDepartment
                }}</span>
              </div>
              <div *ngIf="lftaPrimaryAffiliation.address">
                <span class="di-label text-left">{{ 'LFTA' | productLabel }} Address</span>
                <span>
                  <a
                    class="di-timid"
                    (click)="openMaps(lftaPrimaryAffiliation.address)"
                    rel="noopener"
                    target="_blank"
                    >{{ lftaPrimaryAffiliation.address?.formatted }}</a
                  >
                </span>
              </div>
            </div>
            <div *ngIf="lfkaPrimaryPositionAffilition && checkIfKAMProject()">
              <div>
                <span class="di-label text-left">{{ 'LFKA' | productLabel }} Affiliation</span>
                <span>{{ lfkaPrimaryPositionAffilition.name }}</span>
              </div>
              <div *ngIf="lfkaPrimaryPositionAffilition.originalName">
                <span class="di-label text-left">{{ 'LFKA' | productLabel }} Original Affiliation</span>
                <span>{{ lfkaPrimaryPositionAffilition.originalName }}</span>
              </div>
              <div *ngIf="lfkaPrimaryPositionAffilition.address">
                <span class="di-label text-left">{{ 'LFKA' | productLabel }} Address</span>
                <span>
                  <a
                    class="di-timid"
                    (click)="openMaps(lfkaPrimaryPositionAffilition.address)"
                    rel="noopener"
                    target="_blank"
                    >{{ lfkaPrimaryPositionAffilition.address?.formatted }}</a
                  >
                </span>
              </div>
            </div>
          </div>
          <br />
          <br />
          <div class="col-12 di-info-footer-sidebyside">
            <div>
              <div *ngIf="profile.person.kolId">
                <span class="di-label text-left">Person ID</span>
                <span class="di-bold">{{ profile.person.kolId }}</span>
                <span class="float-right" *ngIf="!profile.baseProfileId">
                  <dirt-profile-copy *diAcl="'profile.training'" [profileId]="profile.id"> </dirt-profile-copy>
                </span>
                <i
                  class="fa fa-copy"
                  style="cursor: pointer"
                  (click)="onCopyID(profile.person.kolId)"
                  ngbTooltip="Copied"
                  [openDelay]="1000"
                  [closeDelay]="2000"
                ></i>
                <span
                  class="col-2 align-self-start"
                  *ngIf="checkIfCubeProject()"
                  class="text-left di-cube-project"
                  title="Has Oncology project (and pot. others) assigned right now."
                  >Oncology</span
                >
              </div>
            </div>
            <div>
              <div *ngIf="profile.person.externalIds?.npi">
                <span class="text-left">NPI</span>
                <span>{{ profile.person.externalIds.npi }}</span>
                <i
                  class="fa fa-copy"
                  style="cursor: pointer"
                  (click)="onCopyID(profile.person.externalIds.npi)"
                  ngbTooltip="Copied"
                  [openDelay]="1000"
                  [closeDelay]="2000"
                ></i>
              </div>
            </div>
          </div>
          <div class="col-12" *ngIf="showBDTrainingReport() || showProfileDPTrainingReport()">
            <span class="di-label text-left">Trainer Comment</span>
            <ul>
              <li *ngIf="showProfileDPTrainingReport()" class="di-trainer-comment">{{ trainingReport.comment }}</li>
              <li *ngIf="showBDTrainingReport()" class="di-trainer-comment">
                {{ profile?.person?.trainingEvaluationReport?.comment }}
              </li>
            </ul>
          </div>
          <div class="col-12" *ngIf="hasTrainingFeaturesEnabled()">
            <span class="di-label text-left">Overall Error Stat</span>
            <ul>
              <li class="di-trainer-comment">DP Error Rate: {{ getOverallErrorRateForDPs() }}</li>
              <li *ngIf="hasBDErrorReport()" class="di-trainer-comment">
                BD Error Rate: {{ getPersonTrainingErrorRate() }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <br />
    <div class="d-flex justify-content-between">
      <p *ngIf="!disablePersonForm">
        <a href="javascript:void(0)" (click)="togglePersonDataView()"> Show Person Data </a>
      </p>
      <div *ngIf="showPersonData && hasTrainingFeaturesEnabled()">Error rate: {{ getPersonTrainingErrorRate() }}</div>
    </div>
    <div class="di-loading-mask" *ngIf="loadingProfilePerson">
      <div class="text-center w-100">
        <i class="fa fa-circle-o-notch fa-spin fa-fw"></i>
        <span>Loading person data...</span>
      </div>
    </div>

    <div *ngIf="showPersonData && hasBDErrorReport() && hasTrainingFeaturesEnabled()">
      <span class="di-label text-left">Error Rate In Sets</span>
      <ul>
        <li class="di-trainer-comment">Name: {{ getNameErrorRate() }}</li>
        <li class="di-trainer-comment">CV Links: {{ getBreakdownErrorRate('cvLinks.fieldErrorRate.errorRate') }}</li>
        <li class="di-trainer-comment">
          Affiliations: {{ getBreakdownErrorRate('affiliations.fieldErrorRate.errorRate') }}
        </li>
        <li class="di-trainer-comment">
          Job title: {{ getBreakdownErrorRate('affiliations.fieldErrorRate.positionErrorRate') }}
        </li>
        <li class="di-trainer-comment">Email: {{ getBreakdownErrorRate('emails.fieldErrorRate.errorRate') }}</li>
      </ul>
    </div>

    <section class="dirt-section-detail di-profile-person-details" *ngIf="showPersonData">
      <div class="row">
        <div class="col-md-12">
          <dirt-profile-person-form
            [person]="profilePerson"
            [basePerson]="basePerson"
            #frmProfilePerson="frmProfilePerson"
          ></dirt-profile-person-form>
        </div>
      </div>
    </section>

    <div *ngIf="activeSection === profileSections.VIDEOS">
      <hr />
      <details>
        <summary>HowTo: Hide already captured YouTube videos</summary>
        <div>
          Drag the following link into your bookmarks bar:
          <a [href]="youtubeAlreadyWatchedBookmarkletUrl" style="text-decoration: underline"
            >Hide Captured YouTube Videos</a
          ><br />
          <small>(Use <em>View > Always Show Bookmarks Bar</em> to get the bookmarks bar)</small><br />
          In the end, it should look similar to the red area: <br />
          <img alt="Bookmarklet in the bookmarks bar" src="/assets/bookmarklet.png" /><br />
          <strong>Tips: </strong><br />
          <ul style="list-style-type: square">
            <li>
              When looking at YouTube search results, you can click on your bookmarklet to mark the thumbnails of
              already captured videos
            </li>
            <li>You have to create a new bookmarklet for each profile you're working on</li>
          </ul>
        </div>
      </details>
      <hr />
    </div>

    <!-- Section Tabs -->
    <div class="row di-info-content mt-3" *ngIf="profile?.id && !isLoadingTrainingReport">
      <div class="col-sm-12" [ngSwitch]="activeSection">
        <!-- Section Selector -->
        <div ngbDropdown class="di-section-picker">
          <button class="btn btn-outline-primary btn-sm" ngbDropdownToggle>
            {{ selectorSections[activeSection].caption }}
            <span
              *ngIf="subentitiesUnpolishedDps && subentitiesUnpolishedDps[activeSection]"
              class="badge badge-dark di-unpolished-dps"
            >
              {{ subentitiesUnpolishedDps && subentitiesUnpolishedDps[activeSection] }}
            </span>
          </button>

          <div ngbDropdownMenu>
            <span *ngFor="let section of selectorSections | iterable">
              <button
                class="dropdown-item btn-sm"
                [ngClass]="{ active: activeSection === section.key }"
                (click)="onSectionChange(section.key)"
                *ngIf="section.value.enabled"
              >
                {{ section.value.caption }}
                <span class="text-danger" *ngIf="hasTrainingFeaturesEnabled()">
                  {{ getErrorRateForSubEntity(section.key) }}
                </span>
                <span
                  *ngIf="subentitiesUnpolishedDps && subentitiesUnpolishedDps[section.key]"
                  class="badge badge-dark di-unpolished-dps"
                >
                  {{ subentitiesUnpolishedDps && subentitiesUnpolishedDps[section.key] }}
                </span>
              </button>
            </span>
          </div>
        </div>

        <!-- Sections -->
        <dirt-profile-publication-list
          [profile]="profile"
          [id]="profile.id"
          [viewMode]="profile.viewMode"
          [modeQA]="isModeQA"
          (onProfileChanged)="onProfileChanged($event)"
          [modeML]="isModeML"
          [sectionId]="'publication'"
          (onQAChanged)="setQAEnabled($event)"
          (onMLChanged)="setMLEnabled($event)"
          [trainingReportDelegates]="getProfileTrainingReportDelegates('publications')"
          (onIsSavingChanged)="setIsSavingMatches($event)"
          (newTotalCount)="storeReviewCounts('publications', $event)"
          *ngSwitchCase="profileSections.PUBLICATIONS"
        ></dirt-profile-publication-list>
        <dirt-profile-contribution-list
          [profile]="profile"
          [id]="profile.id"
          [viewMode]="profile.viewMode"
          [modeQA]="isModeQA"
          (onProfileChanged)="onProfileChanged($event)"
          [modeML]="isModeML"
          [sectionId]="'contribution'"
          (onQAChanged)="setQAEnabled($event)"
          (onMLChanged)="setMLEnabled($event)"
          [trainingReportDelegates]="getProfileTrainingReportDelegates('contributions')"
          (onIsSavingChanged)="setIsSavingMatches($event)"
          (newTotalCount)="storeReviewCounts('contributions', $event)"
          *ngSwitchCase="profileSections.EVENTS"
        ></dirt-profile-contribution-list>
        <dirt-profile-trial-list
          [profile]="profile"
          [id]="profile.id"
          [viewMode]="profile.viewMode"
          [modeQA]="isModeQA"
          (onProfileChanged)="onProfileChanged($event)"
          [modeML]="isModeML"
          [sectionId]="'trial'"
          (onQAChanged)="setQAEnabled($event)"
          (onMLChanged)="setMLEnabled($event)"
          [trainingReportDelegates]="getProfileTrainingReportDelegates('clinical-trial')"
          (onIsSavingChanged)="setIsSavingMatches($event)"
          (newTotalCount)="storeReviewCounts('clinical-trial', $event)"
          *ngSwitchCase="profileSections.CLINICAL_TRIALS"
        ></dirt-profile-trial-list>
        <dirt-profile-membership-list
          [profile]="profile"
          [id]="profile.id"
          [viewMode]="profile.viewMode"
          [modeQA]="isModeQA"
          (onProfileChanged)="onProfileChanged($event)"
          [modeML]="isModeML"
          [sectionId]="'membership'"
          (onQAChanged)="setQAEnabled($event)"
          (onMLChanged)="setMLEnabled($event)"
          [trainingReportDelegates]="getProfileTrainingReportDelegates('memberships')"
          (onIsSavingChanged)="setIsSavingMatches($event)"
          (newTotalCount)="storeReviewCounts('memberships', $event)"
          *ngSwitchCase="profileSections.ASSOCIATIONS"
        ></dirt-profile-membership-list>
        <dirt-profile-payment-us-list
          [profile]="profile"
          [id]="profile.id"
          [viewMode]="profile.viewMode"
          [modeQA]="isModeQA"
          (onProfileChanged)="onProfileChanged($event)"
          [modeML]="isModeML"
          [sectionId]="'payment'"
          (onQAChanged)="setQAEnabled($event)"
          (onMLChanged)="setMLEnabled($event)"
          [trainingReportDelegates]="getProfileTrainingReportDelegates('payments')"
          (onIsSavingChanged)="setIsSavingMatches($event)"
          (newTotalCount)="storeReviewCounts('payments', $event)"
          *ngSwitchCase="profileSections.PAYMENTS_US"
        ></dirt-profile-payment-us-list>
        <dirt-profile-payment-nonus-list
          [profile]="profile"
          [id]="profile.id"
          [viewMode]="profile.viewMode"
          [modeQA]="isModeQA"
          (onProfileChanged)="onProfileChanged($event)"
          [modeML]="isModeML"
          [sectionId]="'payment-nonus'"
          (onQAChanged)="setQAEnabled($event)"
          (onMLChanged)="setMLEnabled($event)"
          [trainingReportDelegates]="getProfileTrainingReportDelegates('payments-nonus')"
          (onIsSavingChanged)="setIsSavingMatches($event)"
          (newTotalCount)="storeReviewCounts('payments-nonus', $event)"
          *ngSwitchCase="profileSections.PAYMENTS_NONUS"
        ></dirt-profile-payment-nonus-list>
        <dirt-profile-guideline-list
          [profile]="profile"
          [id]="profile.id"
          [viewMode]="profile.viewMode"
          [modeQA]="isModeQA"
          (onProfileChanged)="onProfileChanged($event)"
          [modeML]="isModeML"
          [sectionId]="'guideline'"
          (onQAChanged)="setQAEnabled($event)"
          (onMLChanged)="setMLEnabled($event)"
          [trainingReportDelegates]="getProfileTrainingReportDelegates('guidelines')"
          (onIsSavingChanged)="setIsSavingMatches($event)"
          (newTotalCount)="storeReviewCounts('guidelines', $event)"
          *ngSwitchCase="profileSections.GUIDELINES"
        ></dirt-profile-guideline-list>
        <dirt-profile-grant-list
          [profile]="profile"
          [id]="profile.id"
          [viewMode]="profile.viewMode"
          [modeQA]="isModeQA"
          (onProfileChanged)="onProfileChanged($event)"
          [modeML]="isModeML"
          [sectionId]="'grant'"
          (onQAChanged)="setQAEnabled($event)"
          (onMLChanged)="setMLEnabled($event)"
          [trainingReportDelegates]="getProfileTrainingReportDelegates('grants')"
          (onIsSavingChanged)="setIsSavingMatches($event)"
          (newTotalCount)="storeReviewCounts('grants', $event)"
          *ngSwitchCase="profileSections.GRANTS"
        ></dirt-profile-grant-list>
        <dirt-profile-video-list
          [profile]="profile"
          [id]="profile.id"
          [viewMode]="profile.viewMode"
          [modeQA]="isModeQA"
          (onProfileChanged)="onProfileChanged($event)"
          [modeML]="isModeML"
          [sectionId]="'video'"
          (onQAChanged)="setQAEnabled($event)"
          (onMLChanged)="setMLEnabled($event)"
          [trainingReportDelegates]="getProfileTrainingReportDelegates('videos')"
          (onIsSavingChanged)="setIsSavingMatches($event)"
          (newTotalCount)="storeReviewCounts('videos', $event)"
          *ngSwitchCase="profileSections.VIDEOS"
        ></dirt-profile-video-list>
      </div>
    </div>
  </div>
  <dirt-time-report-component
    #timeReportComponent
    [major]="'profile'"
    [id]="profile.id"
    [minor]="showPersonData ? 'BASE_DATA' : activeSection"
    [state]="(profile._meta || {}).status || null"
  ></dirt-time-report-component>
</section>

<!-- Loading Mask -->
<ng-template #tplLoading>
  <div class="di-loading-mask">
    <div class="text-center w-100">
      <i class="fa fa-circle-o-notch fa-spin fa-fw"></i>
      <span>Loading the profile...</span>
    </div>
  </div>
</ng-template>

<!-- Modal: Audit Log -->
<ng-template #auditLog let-d="dismiss">
  <div class="modal-header">
    <h5 class="modal-title">Audit Log</h5>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <dirt-audit-log
      [id]="profile.id"
      [entityAPI]="svcProfile"
      [excludedFields]="excludedAuditLogFields"
    ></dirt-audit-log>
  </div>
</ng-template>

<!-- Modal: Comments -->
<ng-template #commentList let-d="dismiss">
  <div class="dirt-content-wrapper" tabindex="0" (keydown)="onModalKeyPress($event)">
    <div class="modal-header">
      <h5 class="modal-title">Comments</h5>
      <button type="button" class="close" aria-label="Close" (click)="d()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <dirt-comment-list
        [ownerId]="profile.id"
        [peopleId]="profile.person.id"
        [enableCreate]="canCreateComment()"
        [enableUpdate]="canUpdateComment()"
        [enableDelete]="canDeleteComment()"
        (onAutoCloseFunc)="notIfManual(d)"
      ></dirt-comment-list>
    </div>
  </div>
</ng-template>
