import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { AddressEntity } from '../address-entity';
import { Organization } from '../organization';
import { OrganizationSelectModal, OrganizationSelectModalOptions } from './select-modal.component';

@Injectable()
export class OrganizationSelectModalService {
  constructor(private svcModal: NgbModal) {}

  open(
    selectedId: string,
    options?: OrganizationSelectModalOptions
  ): Promise<{ organization: Organization; affiliationId?: string; address?: AddressEntity }> {
    const ref = this.svcModal.open(OrganizationSelectModal, {
      centered: true,
      size: 'xl',
      backdrop: false,
      windowClass: 'di-tree-modal',
    });

    ref.componentInstance.selectedId = selectedId;

    // Don't overwrite the default when options are not provided
    if (options) {
      ref.componentInstance.options = options;
    }

    return ref.result;
  }
}
