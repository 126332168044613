<form #frmAffiliation="ngForm" novalidate>
  <div class="clearfix">
    <!-- Name -->
    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2" for="name">Name</label>
      <div class="col-sm-8 col-md-10">
        <input
          type="text"
          id="name"
          class="form-control extended-trim"
          name="name"
          [disabled]="
            !isFieldEditable('name') || disableNameForCompilerAndReviewer() || disableNameFieldUnconditionally
          "
          [(ngModel)]="model.name"
          (ngModelChange)="onAffiliationChange(model)"
          required
        />
      </div>
    </div>

    <!-- Department -->
    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2" for="department">Department</label>
      <div class="col-sm-8 col-md-10">
        <input
          type="text"
          id="department"
          class="form-control extended-trim"
          name="department"
          [disabled]="
            !isFieldEditable('department') ||
            (!model.department && disableDepartmentOnType()) ||
            disableDeptFieldUnconditionally
          "
          [(ngModel)]="model.department"
          (ngModelChange)="onAffiliationChange(model)"
        />
        <span class="dept-error" *ngIf="model.department && disableDepartmentOnType()"
          >No department for this type of HCO can be entered</span
        >
      </div>
    </div>

    <hr *ngIf="!isAffiliationRequest" />

    <!-- Type -->
    <div class="form-group row" *ngIf="!isAffiliationRequest">
      <label class="col-form-label col-sm-4 col-md-2" for="type">Type</label>
      <div class="col-sm-8 col-md-10">
        <select
          class="form-control custom-select"
          id="type"
          name="type"
          [disabled]="!isFieldEditable('type')"
          [(ngModel)]="model.type"
          required
        >
          <option *ngFor="let type of types" [value]="type.code" [disabled]="!!type.disabled">
            {{ type.title | capitalize }}
          </option>
        </select>
      </div>
    </div>

    <hr *ngIf="!isAffiliationRequest" />

    <!-- Original Name -->
    <div class="form-group row" *ngIf="!isAffiliationRequest">
      <label class="col-form-label col-sm-4 col-md-2" for="originalName">Orig. Name</label>
      <div class="col-sm-8 col-md-10">
        <input
          type="text"
          id="originalName"
          class="form-control"
          name="originalName"
          [disabled]="!isFieldEditable('originalName')"
          [(ngModel)]="model.originalName"
          (ngModelChange)="onAffiliationChange(model)"
        />
      </div>
    </div>

    <!-- Original Department -->
    <div class="form-group row" *ngIf="!isAffiliationRequest">
      <label class="col-form-label col-sm-4 col-md-2" for="originalDepartment">Orig. Department</label>
      <div class="col-sm-8 col-md-10">
        <input
          type="text"
          id="originalDepartment"
          class="form-control"
          name="originalDepartment"
          [disabled]="!isFieldEditable('originalDepartment')"
          [(ngModel)]="model.originalDepartment"
          (ngModelChange)="onAffiliationChange(model)"
        />
      </div>
    </div>

    <!-- Original Language -->
    <div
      class="form-group row"
      *ngIf="
        model.id && (model.originalLanguage || model.originalName || model.originalDepartment) && !isAffiliationRequest
      "
    >
      <label class="col-form-label col-sm-4 col-md-2" for="originalLanguage">Orig. Language</label>
      <div class="col-sm-8 col-md-10">
        <select
          id="originalLanguage"
          class="form-control custom-select"
          name="originalLanguage"
          [(ngModel)]="model.originalLanguage"
          [disabled]="!isFieldEditable('originalLanguage')"
        >
          <option *ngFor="let language of languages$ | async" [value]="language.code">{{ language.title }}</option>
        </select>
      </div>
    </div>

    <hr />

    <!-- Address -->
    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2">Address</label>
      <div class="col-sm-8 col-md-10">
        <dirt-address
          [address]="model.address"
          [additional]="true"
          [disableGoogle]="true"
          [mandatoryCountry]="true"
          [disabled]="!isFieldEditable('address')"
          (onChange)="onAffiliationChange(model)"
        >
        </dirt-address>
      </div>
    </div>

    <!-- Original Address -->
    <div class="form-group row" [hidden]="isAffiliationRequest">
      <label class="col-form-label col-sm-4 col-md-2">Orig Address</label>
      <div class="col-sm-8 col-md-10">
        <dirt-address
          [address]="model.originalAddr"
          [additional]="true"
          [original]="true"
          [disableGoogle]="true"
          [disabled]="!isFieldEditable('address')"
          (onChange)="onAffiliationChange(model)"
          #orgAddr
        >
        </dirt-address>
      </div>
    </div>

    <!-- Web Source -->
    <div class="form-group row" *ngIf="!isAffiliationRequest">
      <label class="col-form-label col-sm-4 col-md-2">Web Source</label>
      <div class="col-sm-8 col-md-10">
        <div class="input-group">
          <dirt-link
            [(url)]="model.webSource"
            name="webSource"
            [form]="frmAffiliation"
            [disabled]="!hasSpecialPermForWebSource('webSource') && !isFieldEditable('webSource')"
          ></dirt-link>
        </div>
      </div>
    </div>

    <!-- usage info - passed to central organization team -->
    <ng-container *ngIf="isAffiliationRequest">
      <hr />

      <div class="form-group row">
        <label class="col-form-label col-sm-4 col-md-2">For customer request</label>
        <div class="col-sm-8 col-md-10">
          <ui-switch
            size="small"
            name="ownHealthInsurance"
            [(ngModel)]="model.usageInfo.customerRequest"
            [disabled]="!!isCustomerRequest"
          ></ui-switch>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-form-label col-sm-4 col-md-2" for="usageInfoComment">Comment</label>
        <div class="col-sm-8 col-md-10">
          <div class="input-group">
            <input
              type="text"
              class="form-control"
              id="usageInfoComment"
              name="usageInfoComment"
              [(ngModel)]="model.usageInfo.comment"
              required
            />
          </div>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-form-label col-sm-4 col-md-2" for="usageInfoLink">Link</label>
        <div class="col-sm-8 col-md-10">
          <div class="input-group">
            <dirt-link [(url)]="model.usageInfo.link" name="usageInfoLink" [form]="frmAffiliation" [required]="true">
            </dirt-link>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</form>
